// See LICENSE.md file in project root directory

import axios from 'axios';

import { init as initApm } from '@elastic/apm-rum';

let uiUrl, apiUrl, environment;
const hostHas = (x) => window.location.hostname.includes(x);
if (hostHas('localhost')) {
    uiUrl = 'http://localhost:3000';
    apiUrl = 'http://localhost:8000';
    environment = 'local';
} else if (hostHas('dev.altexchange.com')) {
    uiUrl = 'https://app.dev.altexchange.com';
    apiUrl = 'https://api.dev.altexchange.com';
    environment = 'development';
} else if (hostHas('staging.altexchange.com') || hostHas('.altexchange.pages.dev')) {
    uiUrl = `https://${window.location.hostname}`;
    apiUrl = 'https://api.staging.altexchange.com';
    environment = 'staging';
} else {
    // Production
    uiUrl = 'https://app.altexchange.com';
    apiUrl = 'https://api.altexchange.com';
    environment = 'production';
}

if (environment !== 'local') {
    initApm({
        // Set required service name (allowed characters: a-z, A-Z, 0-9, -, _, and space)
        serviceName: 'altx-ui',

        // Set custom APM Server URL (default: http://localhost:8200)
        serverUrl: 'https://0668387b1b9e4ad0af5298fbceecab41.apm.us-central1.gcp.cloud.es.io:443',

        environment: environment,

        // Set service version (required for sourcemap feature)
        serviceVersion: process.env.REACT_APP_VERSION ?? '0.0.1',
    });
}

let headers = {};
// Try to load authentication credentials from local storage
try {
    headers = JSON.parse(localStorage.getItem('AUTH'));
} catch (error) {
    console.log('Could not load cached authentication token.');
    console.log(error);
}
try {
    let override = JSON.parse(localStorage.getItem('AUTH_OVERRIDE'));
    if (override) {
        headers = override;
    }
} catch (error) {
    console.log('No auth override loaded');
    console.log(error);
}

// Errors must be a map of status codes to callbacks that accept the error response data
const handleError = async (request, errors = {}, throwError = false) => {
    try {
        const res = await request();
        return res.data;
    } catch (error) {
        if (error.response && error.response.data) {
            // Supported errors for this endpoint?
            const status = String(error.response.status);
            // For now overriding 403 to go to 403 page
            if (status === '403') {
                window.location.href = '/403';
            }
            if (status === '401' && !window.location.href.includes('/login')) {
                window.location.href = '/login';
            }

            if (String(status) in errors) return errors[status](error.response.data);
            console.log('error response data:', error.response.data);
            // window.onerror(error.response.data, window.location.href);
        } else {
            // Invalid error object
            console.log('Invalid error object.');
            console.log(error);
            // window.onerror(`Invalid error object: ${error.response.data}`, window.location.href);
        }
        if (throwError) {
            throw error;
        }
    }
};

const getHeaders = () => {
    if (headers && headers['Authorization']) return headers;
    return {};
};

const reloadAuth = () => {
    try {
        let hd = JSON.parse(localStorage.getItem('AUTH'));

        let override = JSON.parse(localStorage.getItem('AUTH_OVERRIDE'));
        if (override) {
            hd = override;
        }
        headers = hd;
    } catch (error) {
        console.log('Could not load cached authentication token.');
        console.log(error);
    }
    return headers;
};

const api = {
    uiUrl,
    apiUrl,
    environment,
    getHeaders,

    clear_auth: () => {
        localStorage.removeItem('AUTH');
        localStorage.removeItem('AUTH_OVERRIDE');
        localStorage.removeItem('AUTH_OVERRIDE_PATH');
        reloadAuth();
    },

    // Set auth headers from account
    auth: (token) => {
        let val = {
            Authorization: 'Bearer ' + token,
        };

        // Save headers to local cache
        localStorage.setItem('AUTH', JSON.stringify(val));
        reloadAuth();
    },

    // Clear auth headers
    unauth: (reason = undefined) => {
        let override = JSON.parse(localStorage.getItem('AUTH_OVERRIDE'));
        let override_path = localStorage.getItem('AUTH_OVERRIDE_PATH');
        localStorage.removeItem('AUTH_OVERRIDE_PATH');
        if (override) {
            localStorage.removeItem('AUTH_OVERRIDE');
        } else {
            localStorage.removeItem('AUTH');
        }
        let tk = reloadAuth();
        if (!tk) {
            window.location.href = reason ? '/login?reason=' + reason : '/login';
        } else if (override_path) {
            window.location.href = override_path;
        } else {
            window.location.href = '/';
        }
        // clean up session storage
        sessionStorage.removeItem('USER');
        sessionStorage.removeItem('ADVISORY_GROUP');
    },

    override: (token, return_path) => {
        if (!return_path) {
            return_path = window.location.href;
        }
        let val = {
            Authorization: 'Bearer ' + token,
        };
        localStorage.setItem('AUTH_OVERRIDE', JSON.stringify(val));
        localStorage.setItem('AUTH_OVERRIDE_PATH', return_path);
        reloadAuth();
    },

    get: async (endpoint, errors = {}, source = {}, throwError = false) => {
        return await handleError(async () => await axios.get(apiUrl + endpoint, { headers: getHeaders(), cancelToken: source.token }), errors, throwError);
    },

    getBlob: async (endpoint, errors = {}, throwError = false) => {
        return await handleError(async () => await axios.get(endpoint, { responseType: 'blob' }), errors, throwError);
    },

    post: async (endpoint, data = {}, errors = {}, throwError = false) => {
        return await handleError(async () => await axios.post(apiUrl + endpoint, data, { headers: getHeaders() }), errors, throwError);
    },

    patch: async (endpoint, data = {}, errors = {}, throwError = false) => {
        return await handleError(async () => await axios.patch(apiUrl + endpoint, data, { headers: getHeaders() }), errors, throwError);
    },

    delete: async (endpoint, errors = {}, throwError = false) => {
        return await handleError(async () => await axios.delete(apiUrl + endpoint, { headers: getHeaders() }), errors, throwError);
    },
};
export default api;
