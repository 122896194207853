import React, { useEffect, useState } from 'react';
import renderInputError from '../renderInputError';
import '../../styles/enableExtegrationDrawer.css';
import _ from 'lodash';
import { setDismissableAlert } from '../../utilities/alert/setDismissableAlert';
import api from '../../api';
import KeyValueDrawerList from '../DrawerComponents/KeyValueDrawerList';

const DEFAULT_BASE_URL = 'https://id.addepar.com';

interface Props {
    group: any;
    extegration: any;
    loading: (value: number, key: string) => void;
    loaded: (key: string) => void;
    setAlert: (message: string, isError: boolean) => void;
    reloadData: () => Promise<void>;
    close: () => void;
}

const EnableExtegrationDrawer: React.FC<Props> = (props) => {
    const [name, setName] = useState('');
    interface KeyValue {
        key: string;
        value: string;
    }

    const [keyValueList, setKeyValueList] = useState<KeyValue[]>([]);
    const [showCredentials, setShowCredentials] = useState(false);
    const [errors, setErrors] = useState<{ [key: string]: string }>({});

    useEffect(() => {
        load();
    }, []);

    useEffect(() => {
        if (!_.isEqual(props.group, props.group) || !_.isEqual(props.extegration, props.extegration)) {
            load();
        }
    }, [props.group, props.extegration]);

    const load = () => {
        const extegrationName = props.extegration.name;
        setName(extegrationName);
        setShowCredentials(true);
    };

    const buildAddeparUrl = (_baseUrl: string, redirectUri: string, extegrationId: string) => {
        let oauthId = 'altexchange';
        if (redirectUri.includes('localhost') || redirectUri.includes('staging') || redirectUri.includes('dev')) {
            oauthId = 'altex_dev';
        }
        let baseUrl = `${_baseUrl}/oauth2/authorize`;
        let query = `?client_id=${oauthId}`;
        query += `&redirect_uri=${encodeURIComponent(redirectUri)}`;
        query += `&response_type=code&scope=entities_write%20groups%20positions_write%20profile%20portfolio%20transactions_write`;
        query += `&state=${extegrationId}`;
        const url = baseUrl + query;
        return url;
    };

    const checkForErrors = (name: string, keyValueList: any[], shouldSetErrors = true) => {
        let errors: { [key: string]: string } = {}; // Provide type for errors variable
        if (!name) {
            errors.name = 'Name is required';
        }

        keyValueList.forEach((kv) => {
            const reqCred = props.extegration.required_creds?.find((cred: { key: string; display_name: string }) => kv.key && [cred.key, cred.display_name].includes(kv.key));
            if (reqCred) {
                if (!kv.value) {
                    errors['kvList'] = 'All required fields must be filled out';
                }
            }
        });

        setErrors(errors);
        const hasErrors = Object.keys(errors).length > 0;
        return hasErrors;
    };

    const enableExtegration = async () => {
        const extegrationName = name;
        const extegration_type_name = props.extegration.name;

        if (checkForErrors(extegrationName, keyValueList)) {
            return;
        }

        props.loading(0, 'enableExtegrationDrawer_enableExtegration');

        const credentials = keyValueList?.reduce((acc: { [key: string]: string }, kv) => {
            const foundCred = props.extegration.required_creds?.find((cred: { key: string; display_name: string }) => [cred.key, cred.display_name].includes(kv.key));
            const key = foundCred ? foundCred.key : kv.key;
            acc[key] = kv.value;
            return acc;
        }, {});

        const reqBody = {
            extegration_type_name,
            name: extegrationName,
            credentials,
            enabled: true,
        };

        const createExtegrationRes = await api.post(`/advisorygroups/${props.group._id}/extegrations`, reqBody);
        if (!createExtegrationRes) {
            setDismissableAlert(props.setAlert, 'Error enabling extegration', true);
            props.loaded('enableExtegrationDrawer_enableExtegration');
            return;
        }

        if (props.extegration.name === 'Addepar') {
            const redirectUri = `${api.uiUrl.replace('http://', 'https://')}/oauth/addepar`;
            const extegrationId = createExtegrationRes._id;

            let baseUrl = credentials.addepar_api_endpoint || DEFAULT_BASE_URL;

            if (!baseUrl.startsWith('https://')) {
                baseUrl = 'https://' + baseUrl;
            }

            baseUrl = baseUrl.replace(/\/api\/*$/, '');
            baseUrl = baseUrl.replace(/([^:]\/)\/+/g, '$1');

            const url = buildAddeparUrl(baseUrl, redirectUri, extegrationId);

            window.open(url);
        }

        await props.reloadData();
        props.loaded('enableExtegrationDrawer_enableExtegration');
        setDismissableAlert(props.setAlert, 'Extegration enabled.');
        props.close();
    };

    const renderSubmitButton = () => (
        <>
            <div style={{ clear: 'both', height: '20px' }} />
            <button className="drawer-submit-button" onClick={enableExtegration}>
                Enable
            </button>
            {props.close && (
                <div className="addEditDocument-cancel" onClick={props.close}>
                    <span className="a">Cancel</span>
                </div>
            )}
        </>
    );

    return (
        <div className="enableExteg-drawer">
            <h1>Enable {props.extegration.name}</h1>

            <div>
                <label htmlFor="name">Extegration name</label>
                <input id="name" placeholder="Extegration name" type="text" value={name || ''} onChange={(e) => setName(e.target.value)} />
                {renderInputError(errors, 'name')}

                <KeyValueDrawerList
                    hideVisibilityIcon={true}
                    requiredCredentials={props.extegration.required_creds || []}
                    keyValueList={keyValueList}
                    setKeyValueList={setKeyValueList}
                    showCredentials={true}
                    setShowCredentials={setShowCredentials}
                    errors={errors}
                />
            </div>

            {renderSubmitButton()}
        </div>
    );
};

export { EnableExtegrationDrawer };
