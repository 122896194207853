import { FileParseResult, FileTextResult, FileTypes } from '../../../../constants/file';
import BulkUploadHandler, { BulkUploadHandlerCreateType, BulkUploadHandlerConfig } from './BulkUploadHandler';
import sharedFields, { sharedFieldEnum } from '../types/sharedFields';
import { BulkUploadColumn, BulkUploadRequestResult, BulkUploadType } from '../types/bulkUploadTypes';
import api2 from '../../../../api2';
import { capitalizeFirstEveryWord } from '../../../../utilities/format/capitalizeFirst';
import additionalEnums from '../../../../additionalEnums';

export interface TransactionUploadHandlerCreateType extends BulkUploadHandlerCreateType {
    amount?: number;
    date?: Date | string;
    type?: string;
    description?: string;
    investment?: string;
}

export interface TransactionUploadHandlerConfig extends BulkUploadHandlerConfig {
    investment?: string;
}

const getTransactionTypeMap = () => {
    const transactionTypeMap = additionalEnums.TransactionTypes.typeArr.reduce((acc: any, type: any) => {
        acc[type.transaction_type] = type.transaction_type_name;
        return acc;
    }, {}) as { [key: string]: string };
    return transactionTypeMap;
};

const getTransactionTypeField = () => {
    const transactionTypeField = sharedFieldEnum(Object.values(getTransactionTypeMap()).map((val) => capitalizeFirstEveryWord(val)));
    return transactionTypeField;
};

class TransactionUploadHandler extends BulkUploadHandler<TransactionUploadHandlerCreateType, TransactionUploadHandlerConfig> {
    // The type of the bulk upload, eg. 'transaction' or 'valuation'
    type = BulkUploadType.transaction;

    // The base columns that are required for the bulk upload
    base_columns = {
        type: {
            ...getTransactionTypeField(),
            displayName: 'Transaction Type',
            fieldName: 'type',
            isValid: (val: string) => {
                return Object.keys(getTransactionTypeMap()).includes(val) || Object.values(getTransactionTypeMap()).includes(val);
            },
            format: (val: string) => {
                return Object.entries(getTransactionTypeMap()).find(([type, typeName]) => type === val)?.[0] || '';
            },
            required: true,
        },
        amount: {
            ...sharedFields.number,
            displayName: 'Amount',
            fieldName: 'amount',
            required: true, // Indicates that this field is required.
        },
        date: {
            ...sharedFields.date,
            displayName: 'Date',
            fieldName: 'date',
            required: true, // Indicates that this field is required.
        },
        description: {
            ...sharedFields.string,
            displayName: 'Description',
            fieldName: 'description',
            required: false,
        },
        investment: {
            ...sharedFields.object_id,
            displayName: 'Investment',
            fieldName: 'investment',
        },
    };

    // The order of the columns in the CSV file
    columnOrder = Object.keys(this.base_columns) as (keyof TransactionUploadHandlerCreateType)[];

    // sort the columns based on the order in columnOrder, or the default order if not provided
    getColumns = (columnOrder: (keyof TransactionUploadHandlerCreateType)[] = this.columnOrder): { [key in keyof TransactionUploadHandlerCreateType]: BulkUploadColumn } => {
        // Sort the columns based on the given column order or the default order.
        const sortedColumns = this._sortColumns(this.base_columns, columnOrder);

        // set up special conditions based on the config
        if (this.config.investment) {
            // remove investment column if investment is provided
            delete sortedColumns.investment;
        } else if (sortedColumns.investment) {
            // set investment as required if not provided
            sortedColumns.investment.required = true;
        }

        return sortedColumns;
    };

    // check a single row of data to see if it is valid
    isDataValid = (data: { [key in keyof TransactionUploadHandlerCreateType]: string }, columnOrder?: (keyof TransactionUploadHandlerCreateType)[]) => {
        const columns = this.getColumns(columnOrder);
        const isDataValid = this._isColumnDataValid(columns, data, columnOrder);
        // any additional validation goes here
        return isDataValid;
    };

    /**
     * Parses a single line from a CSV file into an object with the correct fields.
     * Does not handle validation.
     * @param {string} line A single line from a CSV file.
     * @returns {Object} An object with the correct fields for the line.
     */
    parseSingleCsvLine = (line: string, columnOrder?: (keyof TransactionUploadHandlerCreateType)[]): TransactionUploadHandlerCreateType => {
        // if not enough commas are included, the fields will be empty strings
        const expectedColumns = this.getColumns(columnOrder);
        const parsedValues = this._parseSingleCsvLine(line, columnOrder);

        // replaces extra characters in the amount field
        if (parsedValues.amount) {
            parsedValues.amount = expectedColumns.amount?.format(parsedValues.amount).toString();
        }

        // set up special conditions based on the config and data type
        return {
            amount:
                expectedColumns.amount && expectedColumns.amount.isValid(parsedValues.amount)
                    ? expectedColumns.amount.format(parsedValues.amount)
                    : expectedColumns.amount?.defaultValue || 0,

            date: expectedColumns.date && expectedColumns.date.isValid(parsedValues.date) ? new Date(parsedValues.date).toISOString().split('T')[0] : '',

            description: expectedColumns.description && expectedColumns.description.isValid(parsedValues.description) ? parsedValues.description : '',

            type: expectedColumns.type && expectedColumns.type.isValid(parsedValues.type) ? parsedValues.type : '',

            // Only include the investment if it's provided and valid.
            ...(parsedValues.investment
                ? {
                      investment: expectedColumns.investment && expectedColumns.investment.isValid(parsedValues.investment) ? parsedValues.investment : '',
                  }
                : {}),
        };
    };

    // Parse the text file results into a FileParseResult
    parseTextFileResult = async (
        textFileResult: FileTextResult,
        columnOrder?: (keyof TransactionUploadHandlerCreateType)[]
    ): Promise<FileParseResult<TransactionUploadHandlerCreateType>> => {
        try {
            const data = textFileResult.lines.map((line) => this.parseSingleCsvLine(line, columnOrder));
            return {
                success: true,
                message: 'File parsed successfully',
                file: textFileResult.file,
                data,
            } as FileParseResult<TransactionUploadHandlerCreateType>;
        } catch (err: any) {
            return {
                success: false,
                message: `Error parsing file: ${err.message}`,
                file: textFileResult.file,
            } as FileParseResult<TransactionUploadHandlerCreateType>;
        }
    };

    // Get the notes for the bulk upload type
    getNotes = (): string[] => {
        let notes: string[] = [];
        if (this.config.investment) {
            notes = [...notes];
        }
        return notes;
    };

    // function to create the object in the database from the parsed data
    create = async (
        columnObj: { [key: string]: BulkUploadColumn },
        data: { [key: string]: any },
        columnOrder?: (keyof TransactionUploadHandlerCreateType)[]
    ): Promise<BulkUploadRequestResult> => {
        // Ensure the data is valid
        if (!this.isDataValid(data, columnOrder)) {
            return { success: false, message: 'Invalid data' };
        }

        // require investment from config or in data
        if (!this.config.investment && !data.investment) {
            return { success: false, message: 'Investment ID is required' };
        }

        // convert "type" from the display name to the transaction type database string format
        const transactionType = additionalEnums.TransactionTypes.typeArr.find(
            (type: any) => type.transaction_type_name === data.type || type.transaction_type === data.type
        )?.transaction_type;

        if (!transactionType) {
            return { success: false, message: 'Invalid transaction type' };
        }

        try {
            // Attempt to create a single transaction from the current object.
            const transaction_id = (
                await api2.client.TransactionApi.createTransaction({
                    CreateTransactionRequest: {
                        amount: data.amount,
                        type: transactionType,
                        // Ensure the date is in ISO format.
                        date: new Date(data.date).toISOString().split('T')[0],
                        investment: this.config.investment || data.investment,
                        ...(data.description && { description: data.description }),
                    },
                })
            ).data.transaction_id;

            // Return the new transaction ID.
            return {
                success: true,
                message: 'Transaction created successfully',
                id: transaction_id,
            };
        } catch (err) {
            // Log and return null in case of an error.
            console.log('error creating transaction:', err);
            return {
                success: false,
                message: `Error creating transaction: ${(err as any)?.response?.data?.message || (err as any).message}`,
            };
        }
    };

    // function to delete the object from the database
    delete = async (id: string): Promise<BulkUploadRequestResult> => {
        try {
            await api2.client.TransactionApi.deleteTransaction({
                transaction_id: id,
            });
            return {
                success: true,
                message: 'Transaction deleted successfully',
            };
        } catch (err) {
            console.log('error deleting transaction:', err);
            return {
                success: false,
                message: `Error deleting transaction: ${(err as any)?.response?.data?.message || (err as any).message}`,
            };
        }
    };
}

// getter for the TransactionUploadHandler
export const getTransactionUploadHandler: (config: TransactionUploadHandlerConfig) => TransactionUploadHandler = (config: TransactionUploadHandlerConfig) =>
    new TransactionUploadHandler(config);

export default TransactionUploadHandler;
