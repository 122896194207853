import * as openApiClientLib from './openApiClient/index';

// pass in two enums, which come from Swagger as key/value objects
// the result is a matching of values from the first one as KEYS, and values from the second one as VALUES
export const combineEnumValues = (keys: string[], values: string[]): { [key: string]: string } => {
    if (keys.length !== values.length) {
        console.log('Keys and values must be the same length');
        return {};
    }
    const combined: { [key: string]: string } = {};
    keys.forEach((key, keyIdx) => {
        combined[key] = values[keyIdx];
    });
    return combined;
};

const additionalEnums = {
    AssetManagerConnectionOutreachStatuses: {
        all_statuses: Object.values(openApiClientLib.AssetManagerConnectionOutreachStatusesAllStatusesEnum),
        incomplete_statuses: Object.values(openApiClientLib.AssetManagerConnectionOutreachStatusesIncompleteStatusesEnum),
        complete_statuses: Object.values(openApiClientLib.AssetManagerConnectionOutreachStatusesCompleteStatusesEnum),
        phrasing_map: combineEnumValues(
            Object.values(openApiClientLib.AssetManagerConnectionOutreachStatusesPhrasingMapKeysEnum),
            Object.values(openApiClientLib.AssetManagerConnectionOutreachStatusesPhrasingMapValuesEnum)
        ),
    },
    TransactionTypes: {
        typeArr: Object.values(openApiClientLib.TransactionTypesTypeArrEnum).map((t) => JSON.parse(t)),
        typeMap: combineEnumValues(
            Object.values(openApiClientLib.TransactionTypesTypeMapKeysEnum),
            Object.values(openApiClientLib.TransactionTypesTypeMapValuesEnum).map((t) => JSON.parse(t))
        ),
    },
};

export default additionalEnums;
