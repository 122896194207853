import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import Select from 'react-select';
import { faPlusCircle, faXmarkCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import api from '../../api';

import styles from '../../styles/jointTransactionDrawerComponent.module.scss';
import { setDismissableAlert } from '../../utilities/alert/setDismissableAlert';
import formatCurrency from '../../utilities/format/formatCurrency';
import { getNetDisplayEffect } from '../../utilities/netTransactionUtils';
import CurrencyInput from '../Inputs/CurrencyInput';
import { CURRENCIES } from '../../constants/currencyConstants';
import { Currency } from '../../types/Currency';
import additionalEnums from '../../additionalEnums';

interface Transaction {
    type: string;
    amount: number;
}

interface JointTransactionDrawerComponentProps {
    jointTransactionList: Transaction[];
    setJointTransactionList: (transactions: Transaction[]) => void;
    currency?: Currency;
    setAlert: (message: string, isError: boolean) => void;
}

const JointTransactionDrawerComponent: React.FC<JointTransactionDrawerComponentProps> = (props) => {
    const [transactions, setTransactions] = useState<Transaction[]>(props.jointTransactionList ?? []);
    const currency = props.currency || CURRENCIES.USD;

    useEffect(() => {
        setTransactions(props.jointTransactionList ?? []);
    }, [props.jointTransactionList]);

    const typeOptions =
        additionalEnums.TransactionTypes?.typeArr?.map((opt: { transaction_type_name: string; transaction_type: string }) => ({ label: opt.transaction_type_name, value: opt })) ??
        [];
    const netEffectDisplay = getNetDisplayEffect(transactions);

    return (
        <div className={styles.main}>
            <div className={styles.header}>
                <span>Joint Transactions</span>
                <span>{formatCurrency(netEffectDisplay, currency)}</span>
            </div>
            {transactions.map((transaction, i) => {
                const typeValue = additionalEnums.TransactionTypes?.typeArr.find((t: { transaction_type: string }) => t.transaction_type === transaction.type);
                const typeName = typeValue?.transaction_type_name;

                return (
                    <div className={styles.tran} key={i}>
                        <div className={styles.tranHeader}>
                            <span>Transaction {i + 1}</span>
                            <FontAwesomeIcon
                                title={`Delete transaction`}
                                icon={faXmarkCircle}
                                size="1x"
                                className={styles.x}
                                onClick={() => {
                                    const updatedTransactions = transactions.filter((t, idx) => idx !== i);
                                    props.setJointTransactionList(updatedTransactions);
                                }}
                            />
                        </div>
                        <div className={styles.tranBodyContainer}>
                            <div className={styles.type} title={typeName}>
                                <label>Type:</label>
                                <Select
                                    className={styles.typeSelect}
                                    value={{ label: typeName, value: typeValue }}
                                    options={typeOptions}
                                    onChange={(selectedOption) => {
                                        // get new type db string
                                        const newType = selectedOption?.value?.transaction_type;

                                        // make sure unique
                                        const otherTransactions = transactions.filter((t, idx) => idx !== i);
                                        const otherTypes = otherTransactions.map((t) => t.type);
                                        if (otherTypes.includes(newType)) {
                                            setDismissableAlert(props.setAlert, 'Cannot have duplicate transaction types', true);
                                            return;
                                        }

                                        // update parent data
                                        const updatedTransactions = [...transactions];
                                        updatedTransactions[i] = { ...transaction, type: newType };
                                        props.setJointTransactionList(updatedTransactions);
                                    }}
                                />
                            </div>

                            <div className={styles.amount}>
                                <label>Amount:</label>
                                <CurrencyInput
                                    value={transaction.amount.toString()} // Convert number to string
                                    currency={currency}
                                    onChange={(amount) => {
                                        const updatedTransactions = [...transactions];
                                        updatedTransactions[i] = { ...transaction, amount: parseFloat(amount) }; // Convert string back to number
                                        props.setJointTransactionList(updatedTransactions);
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                );
            })}

            <div className={styles.addTransaction}>
                <FontAwesomeIcon
                    title={`Add transaction`}
                    icon={faPlusCircle}
                    size="1x"
                    className={styles.x}
                    onClick={() => {
                        const updatedTransactions = transactions.concat({ type: '', amount: 0 });
                        props.setJointTransactionList(updatedTransactions);
                    }}
                />
            </div>
        </div>
    );
};

export default JointTransactionDrawerComponent;
