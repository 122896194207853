import _ from 'lodash';
import React, { useState, useEffect } from 'react';
import api from '../../api';

interface RecalculateButtonProps {
    investment: any;
    user: any;
    loading: (arg1: any, arg2: string) => Promise<void>;
    loaded: (arg: string) => Promise<void>;
    reloadData?: () => Promise<void>;
    text?: string;
    doneText?: string;
}

const RecalculateButton: React.FC<RecalculateButtonProps> = (props) => {
    const [recalculated, setRecalculated] = useState(false);
    const [confirm, setConfirm] = useState(false);

    const investmentStr = JSON.stringify(props.investment);

    // turn off recalculating when the investment changes
    useEffect(() => {
        setRecalculated(false);
    }, [investmentStr]);

    const recalculate = async () => {
        if (recalculated) {
            setRecalculated(false);
            return;
        }

        if (!confirm) {
            setConfirm(true);
            setTimeout(() => setConfirm(false), 3000);
            return;
        }
        setConfirm(false);
        if (props.investment && props.user) {
            await props.loading(null, 'recalculate-button');
            await api.post(`/users/${props.user?._id}/recalculate`, {
                run_investments: [props.investment?._id],
            });
            const _ = await props.reloadData?.();
            await props.loaded('recalculate-button');
            setRecalculated(true);
        } else {
            console.log('no investment');
        }
    };

    const text = props.text ?? 'Recalculate';
    const doneText = props.doneText ?? 'Recalculated!';

    return (
        <span id="recalculateButton" className="a pdfIgnore" onClick={recalculate}>
            {confirm ? 'Confirm?' : recalculated ? doneText : text}
        </span>
    );
};

export default RecalculateButton;
