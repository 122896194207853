import { FileParseResult, FileTextResult, FileTypes } from '../../../../constants/file';
import BulkUploadHandler, { BulkUploadHandlerCreateType, BulkUploadHandlerConfig } from './BulkUploadHandler';
import sharedFields, { isObjectId, sharedFieldEnum } from '../types/sharedFields';
import { BulkUploadColumn, BulkUploadRequestResult, BulkUploadType } from '../types/bulkUploadTypes';
import api from '../../../../api';
import validator from 'validator';
import api2 from '../../../../api2';
import { ConnectionOutreachStatus } from '../../../../openApiClient';

const getIntegrationField = (integrations: any[]) => {
    return {
        ...sharedFieldEnum(integrations.map((integration) => integration.name) || []),
        displayName: 'Integration',
        fieldName: 'integration',
        isValid: (val: string) => {
            return integrations.map((integration) => integration.name.toLowerCase()).includes(val.toLowerCase());
        },
        format: (val: string) => {
            return integrations.find((integration) => integration.name.toLowerCase() === val.toLowerCase()).name || '';
        },
        invalidMessage: 'must be a valid integration',
        required: false,
    };
};

const getOutreachStatusField = (outreachStatuses: string[]) => {
    return {
        ...sharedFieldEnum(outreachStatuses),
        displayName: 'Outreach Status',
        fieldName: 'outreach_status',
        isValid: (val: string) => outreachStatuses.map((status) => status.toLowerCase()).includes(val.toLowerCase()),
        format: (val: string) => outreachStatuses.find((status) => status.toLowerCase() === val.toLowerCase()) || '',
        required: false,
    };
};

export interface ConnectionUploadHandlerCreateType extends BulkUploadHandlerCreateType {
    name?: string;
    account?: string; // object id
    integration?: string; // object id
    asset_manager?: string;

    credentials?: any;

    use_tfa?: boolean;
    account_list?: string[]; // slash separated list of account ids
    is_enabled?: boolean;

    outreach_status?: string;
}

export interface ConnectionUploadHandlerConfig extends BulkUploadHandlerConfig {}

export interface ConnectionUploadHandlerState {
    outreachStatuses: string[];
    integrations: any[];
}

export const onLoadHandler = async (thisHandler: BulkUploadHandler<ConnectionUploadHandlerCreateType, ConnectionUploadHandlerConfig, ConnectionUploadHandlerState>) => {
    // get outreach statuses
    const outreachStatuses = ConnectionOutreachStatus;

    // get integrations, add manual to the list
    let integrations = [];
    try {
        integrations = await api2.paginateApiRoute(async (paginate_params: any) => {
            return (
                await api2.client.IntegrationApi.listIntegrations({
                    ...paginate_params,
                })
            ).data.integrations;
        });
    } catch (error) {
        console.error(error);
    }
    const manualIntegration = { _id: null, name: 'Manual (AltExchange)' };
    integrations = [manualIntegration, ...integrations];

    // set the state for the handler
    thisHandler.state = {
        outreachStatuses: Object.values(ConnectionOutreachStatus),
        integrations,
    };

    // set base_columns fields that require the state data
    thisHandler.base_columns.integration = getIntegrationField(thisHandler.state?.integrations || []);
    thisHandler.base_columns.outreach_status = getOutreachStatusField(thisHandler.state?.outreachStatuses || []);
};

class ConnectionUploadHandler extends BulkUploadHandler<ConnectionUploadHandlerCreateType, ConnectionUploadHandlerConfig, ConnectionUploadHandlerState> {
    // The type of the bulk upload, eg. 'connection'
    type = BulkUploadType.connection;

    // The base columns that are required for the bulk upload
    base_columns = {
        name: {
            ...sharedFields.string,
            displayName: 'Connection Name',
            fieldName: 'name',
            isValid: (val: string) => validator.isLength(val + '', { min: 1 }),
            invalidMessage: 'The connection name cannot be empty',
            required: true,
        },
        account: {
            ...sharedFields.object_id,
            displayName: 'Account ID',
            fieldName: 'account',
            required: true,
        },
        asset_manager: {
            ...sharedFields.object_id,
            displayName: 'Asset Manager',
            fieldName: 'asset_manager',
            required: false,
        },
        is_enabled: {
            ...sharedFields.boolean,
            displayName: 'Is Enabled',
            fieldName: 'is_enabled',
            required: false,
        },
        credentials: {
            ...sharedFields.string,
            displayName: 'Credentials',
            fieldName: 'credentials',
            isValid: (val: string) => {
                const split = val
                    .split('/')
                    .map((v) => v.trim())
                    .filter((v) => v);
                // format - "key: value / key: value / ..."
                // assure that each pair is a key value pair
                for (let i = 0; i < split.length; i++) {
                    const pair = split[i]
                        .split(':')
                        .map((v) => v.trim())
                        .filter((v) => v);
                    if (pair.length !== 2) {
                        return false;
                    }
                }
                return true;
            },
            required: false,
        },
        integration: {
            ...getIntegrationField(this.state?.integrations || []),
        },
        use_tfa: {
            ...sharedFields.boolean,
            displayName: 'Use 2FA',
            fieldName: 'use_tfa',
            required: false,
        },
        account_list: {
            ...sharedFields.string,
            displayName: 'Account List',
            fieldName: 'account_list',
            isValid: (val: string) => {
                const split = val
                    .split('/')
                    .map((v) => v.trim())
                    .filter((v) => v);
                for (let i = 0; i < split.length; i++) {
                    if (!isObjectId(split[i])) {
                        return false;
                    }
                }
                return true;
            },
            format: (val: string) => {
                return val
                    .split('/')
                    .map((v) => v.trim())
                    .filter((v) => v)
                    .join('/');
            },
            required: false,
        },
        contacts: {
            ...sharedFields.string,
            displayName: 'Contacts',
            fieldName: 'contacts',
            isValid: (val: string) => {
                const split = val
                    .split('/')
                    .map((v) => v.trim())
                    .filter((v) => v);
                for (let i = 0; i < split.length; i++) {
                    if (!isObjectId(split[i])) {
                        return false;
                    }
                }
                return true;
            },
            format: (val: string) => {
                return val
                    .split('/')
                    .map((v) => v.trim())
                    .filter((v) => v)
                    .join('/');
            },
            required: false,
        },
        ral_requests: {
            ...sharedFields.string,
            displayName: 'RAL Requests',
            fieldName: 'ral_requests',
            isValid: (val: string) => {
                const split = val
                    .split('/')
                    .map((v) => v.trim())
                    .filter((v) => v);
                for (let i = 0; i < split.length; i++) {
                    if (!isObjectId(split[i])) {
                        return false;
                    }
                }
                return true;
            },
            format: (val: string) => {
                return val
                    .split('/')
                    .map((v) => v.trim())
                    .filter((v) => v)
                    .join('/');
            },
            required: false,
        },
        outreach_status: {
            ...getOutreachStatusField(this.state?.outreachStatuses || []),
        },
    };

    // The order of the columns in the CSV file
    columnOrder = Object.keys(this.base_columns) as (keyof ConnectionUploadHandlerCreateType)[];

    // sort the columns based on the order in columnOrder, or the default order if not provided
    getColumns = (columnOrder: (keyof ConnectionUploadHandlerCreateType)[] = this.columnOrder): { [key in keyof ConnectionUploadHandlerCreateType]: BulkUploadColumn } => {
        const sortedColumns = this._sortColumns(this.base_columns, columnOrder);
        return sortedColumns;
    };

    // check a single row of data to see if it is valid
    isDataValid = (data: { [key in keyof ConnectionUploadHandlerCreateType]: string }, columnOrder?: (keyof ConnectionUploadHandlerCreateType)[]) => {
        const columns = this.getColumns(columnOrder);
        const isDataValid = this._isColumnDataValid(columns, data, columnOrder);
        return isDataValid;
    };

    /**
     * Parses a single line from a CSV file into an object with the correct fields.
     * Does not handle validation.
     * @param {string} line A single line from a CSV file.
     * @returns {Object} An object with the correct fields for the line.
     */

    parseSingleCsvLine = (line: string, columnOrder?: (keyof ConnectionUploadHandlerCreateType)[]): ConnectionUploadHandlerCreateType => {
        const expectedColumns = this.getColumns(columnOrder);
        const parsedValues = this._parseSingleCsvLine(line, columnOrder);

        try {
            return {
                name: parsedValues.name ? expectedColumns.name?.format(parsedValues.name) : '',
                account: parsedValues.account ? expectedColumns.account?.format(parsedValues.account) : '',
                asset_manager: parsedValues.asset_manager ? expectedColumns.asset_manager?.format(parsedValues.asset_manager) : '',
                integration: parsedValues.integration ? expectedColumns.integration?.format(parsedValues.integration) : '',
                credentials: parsedValues.credentials ? expectedColumns.credentials?.format(parsedValues.credentials) : '',
                use_tfa: parsedValues.use_tfa ? expectedColumns.use_tfa?.format(parsedValues.use_tfa) : '',
                account_list: parsedValues.account_list ? expectedColumns.account_list?.format(parsedValues.account_list) : '',
                contacts: parsedValues.contacts ? expectedColumns.contacts?.format(parsedValues.contacts) : '',
                ral_requests: parsedValues.ral_requests ? expectedColumns.ral_requests?.format(parsedValues.ral_requests) : '',
                is_enabled: parsedValues.is_enabled ? expectedColumns.is_enabled?.format(parsedValues.is_enabled) : '',
                outreach_status: parsedValues.outreach_status ? expectedColumns.outreach_status?.format(parsedValues.outreach_status) : '',
            };
        } catch (err) {
            console.error('Error parsing connection:', err);
            return {};
        }
    };

    // Parse the text file results into a FileParseResult
    parseTextFileResult = async (
        textFileResult: FileTextResult,
        columnOrder?: (keyof ConnectionUploadHandlerCreateType)[]
    ): Promise<FileParseResult<ConnectionUploadHandlerCreateType>> => {
        try {
            const data = textFileResult.lines.map((line) => this.parseSingleCsvLine(line, columnOrder));
            return {
                success: true,
                message: 'File parsed successfully',
                file: textFileResult.file,
                data,
            } as FileParseResult<ConnectionUploadHandlerCreateType>;
        } catch (err: any) {
            return {
                success: false,
                message: `Error parsing file: ${err.message}`,
                file: textFileResult.file,
            } as FileParseResult<ConnectionUploadHandlerCreateType>;
        }
    };

    // Get the notes for the bulk upload type
    getNotes = (): string[] => {
        let notes: string[] = [`The credentials column should be formatted as "key: value / key: value / ..."`];
        return notes;
    };

    // function to create the object in the database from the parsed data
    create = async (
        columnObj: { [key: keyof ConnectionUploadHandlerCreateType]: BulkUploadColumn },
        data: { [key: string]: any },
        columnOrder?: (keyof ConnectionUploadHandlerCreateType)[]
    ): Promise<BulkUploadRequestResult> => {
        if (!this.isDataValid(data, columnOrder)) {
            return { success: false, message: 'Invalid data' };
        }

        let credentials: Record<string, string> = {};
        if (data.credentials?.trim()) {
            const split = data.credentials
                .split('/')
                .map((v: string) => v.trim())
                .filter((v: string) => v);
            for (let i = 0; i < split.length; i++) {
                const pair = split[i]
                    .split(':')
                    .map((v: string) => v.trim())
                    .filter((v: string) => v);
                if (pair.length !== 2) {
                    return {
                        success: false,
                        message: 'Invalid credentials format',
                    };
                }
                credentials[pair[0] as string] = pair[1];
            }
        }

        let integration = null;
        if (data.integration) {
            integration = this.state?.integrations.find((integration) => integration.name.toLowerCase() === data.integration.toLowerCase())?._id;
        }

        let account_list = undefined;
        if (data.account_list) {
            account_list = data.account_list
                .split('/')
                .map((v: string) => v.trim())
                .filter((v: string) => v);
        }

        let contacts = undefined;
        if (data.contacts) {
            contacts = data.contacts
                .split('/')
                .map((v: string) => v.trim())
                .filter((v: string) => v);
        }

        let ral_requests = undefined;
        if (data.ral_requests) {
            ral_requests = data.ral_requests
                .split('/')
                .map((v: string) => v.trim())
                .filter((v: string) => v);
        }

        const createBody = {
            name: data.name,
            account: data.account,
            asset_manager: data.asset_manager || undefined,
            integration,
            credentials,
            use_tfa: [true, false].includes(data.use_tfa) ? columnObj.use_tfa.format(data.use_tfa) : undefined,
            account_list,
            contacts,
            ral_requests,
            is_enabled: [true, false].includes(data.is_enabled) ? columnObj.is_enabled.format(data.is_enabled) : undefined,
            outreach_status: data.outreach_status || undefined,
        };

        try {
            const createResult = await api.post('/connections', createBody, {}, true);

            return {
                success: true,
                message: 'Connection created successfully',
                id: createResult._id,
            };
        } catch (err) {
            console.log('error creating connection:', err);
            return {
                success: false,
                message: `Error creating connection: ${(err as any)?.response?.data?.message || (err as any).message}`,
            };
        }
    };

    // function to delete the object from the database
    delete = async (id: string): Promise<BulkUploadRequestResult> => {
        try {
            const deleteResult = await api.delete(`/connections/${id}`, {}, true);
            if (deleteResult.success === true) {
                return {
                    success: true,
                    message: 'Connection deleted successfully',
                };
            } else {
                return {
                    success: false,
                    message: 'Error deleting connection',
                };
            }
        } catch (err) {
            console.log('error deleting connection:', err);
            return {
                success: false,
                message: `Error deleting connection: ${(err as any)?.response?.data?.message || (err as any).message}`,
            };
        }
    };
}

// getter for the ConnectionUploadHandler
export const getConnectionUploadHandler: (config: ConnectionUploadHandlerConfig) => ConnectionUploadHandler = (config: ConnectionUploadHandlerConfig) =>
    new ConnectionUploadHandler(config, onLoadHandler);

export default ConnectionUploadHandler;
