import api2 from '../../api2';
import { ObjectSearchSelect } from './ObjectSearchSelect';

export const UserSearchSelect = (props) => {
    return (
        <ObjectSearchSelect
            placeholder="Filter by user"
            selected={props.searchUser}
            onChange={(userObj) => {
                props.onChange?.(userObj);
            }}
            getLabel={(obj) => {
                if (props.getLabel) {
                    return <span title={props.getLabel(obj)}>{props.getLabel(obj)}</span>;
                } else {
                    let label = obj?.name ? obj?.name : '';
                    if (obj?.email) {
                        label += ` (${obj?.email})`;
                    }
                    return label ? <div title={label}>{label}</div> : null;
                }
            }}
            defaultLabel="All Users"
            matchProperty="_id"
            width={300}
            loadOptions={async (inputValue) => {
                try {
                    const { data } = await api2.client.UserApi.listUsers({
                        search: inputValue,
                        limit: 50,
                        minimal: true,
                        access: 'investor',
                    });

                    if (!data.success) return [];

                    return data.users;
                } catch (error) {
                    console.log('UserSearchSelect Error', error.toString());
                    return [];
                }
            }}
            containerStyle={{
                zIndex: 1000,
                ...props.containerStyle,
            }}
        />
    );
};
