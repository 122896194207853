import React, { useState, useEffect } from 'react';
import moment from 'moment';
import _ from 'lodash';

import api from '../../api';
import renderInputError from '../renderInputError';
import Checkbox from '../Inputs/Checkbox';
import { setDismissableAlert } from '../../utilities/alert/setDismissableAlert';
import { UserPermissions } from '../../utilities/AdvisorVue/permissions';

import '../../styles/notifyDrawer.css';

interface NotifyDrawerProps {
    notifs: any[];
    loading: (duration?: number, key?: string) => void;
    loaded: (key?: string) => void;
    setAlert: (alert: React.ReactNode) => void;
    loadClients: () => Promise<void>;
    close: () => void;
    type: string;
    dataObj: {
        user: {
            _id: string;
            name: string;
            email: string;
        };
        _id: string;
    };
    dataObjType: string;
    detailsFieldList?: string[];
    notifications?: any[];
    noCalendarInvite?: boolean;
    errors?: { [key: string]: string };
}

const NotifyDrawer: React.FC<NotifyDrawerProps> = (props) => {
    const [sendCalendarReminder, setSendCalendarReminder] = useState(false);
    const [open, setOpen] = useState(false);

    useEffect(() => {
        // Handle componentDidUpdate logic
    }, [props.notifs]);

    const notifyUser = async () => {
        props.loading();
        const notifType = sendCalendarReminder ? 'calendar' : 'email';
        await sendNotice(notifType);
        await props.loadClients();
        props.close();
        props.loaded();
    };

    const sendNotice = async (type = 'email') => {
        let notification = {
            subject: props.type,
            type,
            receiver: props.dataObj.user._id,
            [props.dataObjType]: props.dataObj._id,
            forceSend: true,
        };
        props.loading(320, 'notify_sendNotice');
        const res = await api.post('/notifications', notification);
        props.loaded('notify_sendNotice');
        if (res?.message === 'ok') {
            setDismissableAlert(
                props.setAlert,
                <>
                    A notification email has been sent to{' '}
                    <strong style={{ color: '#fff' }}>
                        {props.dataObj.user.name} ({props.dataObj.user.email})
                    </strong>
                    .
                </>,
                false,
                10000
            );
        }
    };

    const renderTransactionDetails = (tran: any) => {
        return (
            <div className="notifyDrawer-details">
                <div className="notifyDrawer-details-header">{props.type} Details</div>
                {props.detailsFieldList?.map((item, index) => <div key={index}>{item}</div>)}
            </div>
        );
    };

    const renderIncludeCalendarInviteCheckbox = () => {
        return (
            <div className="notifyDrawer-checkboxDiv">
                <Checkbox setChecked={setSendCalendarReminder} checked={sendCalendarReminder} />
                <div>Include calendar reminder in notification</div>
            </div>
        );
    };

    const renderPreviousNotifs = (notifs: any[]) => {
        if (notifs.length === 0) return <></>;

        return (
            <div className="notifyDrawer-prev">
                <div className="notifyDrawer-prev-header" onClick={() => setOpen(!open)}>
                    <img className="notifyDrawer-prev-header-bell" src="/images/icons/blueBell.png" alt="blue bell" />
                    <div className="notifyDrawer-prev-header-text">{notifs.length} Notifications Sent</div>
                    <img className="notifyDrawer-prev-header-arrow" src={'/images/icons/' + (open ? 'down' : 'up') + 'Arrow_blue.png'} alt="arrow" />
                </div>
                {open &&
                    notifs.map((n, nIdx) => {
                        return (
                            <div className="notifyDrawer-prev-notif" key={'notifyDrawer-prev-notif' + nIdx}>
                                <div>{n.notificationType[0].toUpperCase() + n.notificationType.slice(1)}</div>
                                <div>{moment(n.timestamp).format('M-D-YYYY h:mma')}</div>
                            </div>
                        );
                    })}
            </div>
        );
    };

    const s = { sendCalendarReminder, open };
    const p = props;

    const notifs = p.notifications ?? [];

    let height = 800;

    if (s.open) {
        height += 50 * notifs.length;
    }

    return (
        <div className="notifyDrawer-drawer">
            <h1>
                Notify {p.dataObj.user.name} of {props.type}
            </h1>

            {renderPreviousNotifs(notifs)}
            {renderTransactionDetails(p.dataObj)}

            {UserPermissions().canUpdateNotifications ? (
                <>
                    {!p.noCalendarInvite && renderIncludeCalendarInviteCheckbox()}

                    <button className="drawer-submit-button" onClick={notifyUser}>
                        Notify
                    </button>
                </>
            ) : (
                <div style={{ clear: 'both', marginTop: '10px' }} />
            )}

            <div className="notifyDrawer-cancel" onClick={p.close}>
                <span className="a">Cancel</span>
            </div>

            {renderInputError(p.errors || {}, 'send')}
        </div>
    );
};

export { NotifyDrawer };
