import React from 'react';
import Checkbox from '../../../components/Inputs/Checkbox';
import { capitalizeFirst } from '../../../utilities/format/capitalizeFirst';
import { PDF_EXPORT_ELEMENT_STATUS, STATUS_COLOR_MAP } from './constants';
import { EM_DASH } from '../../../constants/constantStrings';

const PerformancePdfExportStatusDisplay = ({
    sidebarOpen,
    closeModal,
    investments,
    pdfInvestmentDetailsStatusQueue,
    totalInvestments,
    pdfPerfOverviewInclude,
    pdfPerfOverviewDone,
    pdfPerfTableInclude,
    pdfPerfTableDone,
}) => {
    const queue = pdfInvestmentDetailsStatusQueue ?? {};

    const waitingOnOverview = pdfPerfOverviewInclude && !pdfPerfOverviewDone;
    const waitingOnTable = pdfPerfTableInclude && !pdfPerfTableDone;

    let queuedInvestments = Object.values(queue).filter((status) => status !== PDF_EXPORT_ELEMENT_STATUS.DONE);

    const waitingOnInvestments = queuedInvestments.length > 0;

    const done = !waitingOnTable && !waitingOnInvestments && !waitingOnOverview;

    const renderCancelButton = () => {
        return (
            <>
                <button className="drawer-submit-button" onClick={closeModal}>
                    Close
                </button>
                <div style={{ clear: 'both', height: '20px' }} />
            </>
        );
    };

    const countString = `${
        Object.entries(queue).length > 0 ? `${Object.values(queue).filter((status) => status === PDF_EXPORT_ELEMENT_STATUS.DONE).length} / ${totalInvestments}` : EM_DASH
    }`;

    // const countString = `${Object.entries(queue).length > 0
    //     && <div style={{
    //         fontSize: '18px',
    //         fontWeight: 'bold',
    //     }}>
    //         {Object.values(queue)
    //             .filter(status => status === PDF_EXPORT_ELEMENT_STATUS.DONE)
    //             .length
    //         }
    //         &nbsp;/&nbsp;
    //         {totalInvestments}
    //     </div>}`

    return (
        <div
            style={{
                position: 'fixed',
                top: '0',
                left: sidebarOpen ? '320px' : '0',
                width: sidebarOpen ? 'calc(100% - 320px)' : '100%',
                height: '100%',
                backgroundColor: 'rgba(0,0,0,0.5)',
                zIndex: 10000,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
            }}
        >
            <div
                className="performance-page"
                style={{
                    borderRadius: '20px',
                    position: 'relative',
                    backgroundColor: 'white',
                    padding: '60px',
                    height: '50%',
                    width: '80%',
                    display: 'flex',
                    flexDirection: 'column',
                    // justifyContent: 'space-between',
                    alignItems: 'center',
                    justifyContent: 'center',
                    gap: '10px',
                }}
            >
                <div
                    style={{
                        fontSize: '30px',
                        fontWeight: 'bold',
                        // marginBottom: '20px',
                        color: 'var(--color-primary)',
                    }}
                >
                    {done ? 'Finished exporting!' : 'Exporting To PDF...'}
                </div>

                <br />

                {/* checkbox to choose whether to include the investment details table */}
                {
                    <div
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            color: 'white',
                            backgroundColor: STATUS_COLOR_MAP[PDF_EXPORT_ELEMENT_STATUS.DONE],
                            width: '200px',
                            justifyContent: 'center',
                            padding: '10px',
                            borderRadius: '10px',
                            // margin: 10,
                            backgroundColor: STATUS_COLOR_MAP[waitingOnOverview ? PDF_EXPORT_ELEMENT_STATUS.LOADING : PDF_EXPORT_ELEMENT_STATUS.DONE],
                        }}
                    >
                        Performance Overview
                    </div>
                }

                <br />

                {/* checkbox to choose whether to include the investment details table */}
                {pdfPerfTableInclude && (
                    <div
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            width: '300px',
                            justifyContent: 'center',
                            padding: '10px',
                            color: 'white',
                            // margin: 10,
                            backgroundColor: STATUS_COLOR_MAP[waitingOnTable ? PDF_EXPORT_ELEMENT_STATUS.LOADING : PDF_EXPORT_ELEMENT_STATUS.DONE],
                            borderRadius: '10px',
                        }}
                    >
                        Investment Details Performance Table
                    </div>
                )}

                <br />

                {Object.entries(queue).length > 0 && (
                    <>
                        <div
                            style={{
                                color: 'var(--color-primary)',
                                fontSize: '20px',
                                fontWeight: 'bold',
                                marginBottom: '10px',
                                display: 'flex',
                                justifyContent: 'space-between',
                                width: '80%',
                            }}
                        >
                            <div>Investment Details</div>
                            <div>{countString}</div>
                        </div>

                        <div
                            style={{
                                overflow: 'auto',
                                backgroundColor: '#f8f8f8',
                                width: '80%',
                                border: '1px solid #ddd',
                                borderRadius: '10px',
                            }}
                        >
                            {Object.entries(queue).map(([investmentId, status], i) => {
                                const investment = investments.find((inv) => inv._id === investmentId);
                                if (!investment) return null;
                                return (
                                    <div
                                        key={'investmentChoices' + i}
                                        style={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            padding: '7px 20px 7px 20px',
                                            fontSize: '16px',
                                            color: 'white',
                                            backgroundColor: STATUS_COLOR_MAP[status],
                                        }}
                                    >
                                        <div
                                            style={{
                                                backgroundColor: 'inherit',
                                                color: 'inherit',
                                            }}
                                        >
                                            {investment.name}
                                        </div>
                                    </div>
                                );
                            })}
                        </div>
                    </>
                )}

                <br />

                {/* legend */}
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        marginTop: '20px',
                    }}
                >
                    {Object.values(PDF_EXPORT_ELEMENT_STATUS).map((status, i) => {
                        return (
                            <div
                                key={'statusLegend' + i}
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    marginRight: '20px',
                                }}
                            >
                                <div
                                    style={{
                                        width: '20px',
                                        height: '20px',
                                        backgroundColor: STATUS_COLOR_MAP[status],
                                        marginRight: '10px',
                                    }}
                                ></div>
                                {capitalizeFirst(status)}
                            </div>
                        );
                    })}
                </div>

                {/* close button */}
                {done && (
                    <div
                        style={{
                            position: 'absolute',
                            top: '10px',
                            right: '10px',
                            cursor: 'pointer',
                        }}
                        onClick={closeModal}
                    >
                        <img style={{ width: '25px', height: '25px', cursor: 'pointer' }} src="/images/icons/closeBlue.png" alt="Remove Credential" title="Remove Credential" />
                    </div>
                )}

                {/* {done && renderCancelButton()} */}
            </div>
        </div>
    );
};

export default PerformancePdfExportStatusDisplay;
