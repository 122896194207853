import api2 from '../../api2';
import { InvestmentAccount, Investment, InvestmentUser } from '../../types/Investment';
import ObjectSearchSelectTs, { ObjectSearchSelectProps } from '../Dropdowns/ObjectSearchSelectTs';
import { generateStringHash } from '../../utilities/string/generateStringHash';
import { Link } from 'react-router-dom';
import routes from '../../routes';
import { UserPermissions } from '../../utilities/AdvisorVue/permissions';

type InvestmentSearchFilterProps = Omit<ObjectSearchSelectProps<Investment>, 'getLabel'> & {
    filter?: any;
    excludeDisplayFields?: (keyof Investment)[];
    showInvestmentLink?: boolean;
};

const InvestmentSearchFilter = ({
    filter = {},
    onChange,
    selected,
    isMulti = false,
    formatOptionLabel,
    width,
    defaultOptions = false,
    defaultLabel = '',
    excludeDisplayFields = [],
    label = '',
    showInvestmentLink = false,
    isDisabled,
}: InvestmentSearchFilterProps) => {
    let filterHash = generateStringHash(JSON.stringify(filter));
    return (
        <ObjectSearchSelectTs<Investment>
            isDisabled={isDisabled}
            key={filterHash}
            label={label}
            placeholder={`Select Investment${isMulti ? 's' : ''}`}
            selected={selected}
            onChange={onChange}
            getLabel={(investment: any) => investment?.name}
            matchProperty="_id"
            loadOptions={async (inputValue: string) => {
                try {
                    const investments = (
                        await api2.client.InvestmentApi.listInvestments({
                            ...filter,
                            search: inputValue,
                            populate_account: true,
                            populate_user: true,
                            limit: 50,
                        })
                    ).data.investments;

                    return investments as Investment[];
                } catch (e) {
                    console.error('Error loading investments:', e);
                    return [];
                }
            }}
            width={width ?? '100%'}
            isMulti={isMulti}
            formatOptionLabel={(option) => {
                if (formatOptionLabel) return formatOptionLabel(option);

                const investment: Investment | null = option.value;

                if (!investment) return 'All Investments';

                const excludeUser = excludeDisplayFields.includes('user');
                const investmentLink = routes.client.investmentDetails({
                    access: UserPermissions().userAccess,
                    userId: typeof investment.user === 'string' ? investment.user : investment.user?._id,
                    accountId: typeof investment.account === 'string' ? investment.account : investment.account?._id,
                    investmentId: investment._id,
                });

                return (
                    <div>
                        {!excludeUser && investment.user && <div style={{ fontSize: '12px', color: 'var(--color-light-gray)' }}>{(investment.user as InvestmentUser).name}</div>}

                        {investment.account && <div style={{ fontSize: '12px', color: 'var(--color-light-gray)' }}>{(investment.account as InvestmentAccount).name}</div>}
                        {investmentLink && showInvestmentLink ? (
                            <Link to={investmentLink} className="table-ellipses" style={{}} title={investment.name}>
                                {investment.name}
                            </Link>
                        ) : (
                            <span className="table-ellipses" title={investment.name}>
                                {investment.name}
                            </span>
                        )}
                    </div>
                );
            }}
            defaultLabel={defaultLabel || 'None'}
            defaultOptions={defaultOptions}
        />
    );
};

export default InvestmentSearchFilter;
