import React from 'react';
import '../../styles/DocumentToolbar.css';
import { ToolbarAction } from '../../pages/DocumentsPage/Toolbar';

interface FloatingButtonProps {
    onClick: () => void;
    containerStyle?: React.CSSProperties;
    buttonStyle?: React.CSSProperties;
    children: React.ReactNode;
    disabled?: boolean;
}

const FloatingButton: React.FC<FloatingButtonProps> = ({ onClick, containerStyle, buttonStyle, children, disabled }) => {
    return (
        <div
            className="ToolbarContainer"
            style={{
                userSelect: 'none',
                transform: 'none',
                bottom: 'unset',
                left: 'unset',
                right: 'unset',
                ...containerStyle,
            }}
        >
            <ToolbarAction className="toolbarTool" onClick={onClick} disabled={disabled}>
                <div
                    style={{
                        width: '100%',
                        height: '100%',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        fontSize: '1.5rem',
                        color: 'white',
                        ...buttonStyle,
                    }}
                >
                    {children}
                </div>
            </ToolbarAction>
        </div>
    );
};

export default FloatingButton;
