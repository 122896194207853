import apiV2 from '../../api2';
import { User } from '../../types/User';
import { generateStringHash } from '../../utilities/string/generateStringHash';
import ObjectSearchSelectTs, { ObjectSearchSelectProps } from '../Dropdowns/ObjectSearchSelectTs';

type UserSearchFilterProps = Omit<ObjectSearchSelectProps<User>, 'getLabel'> & {
    filter?: any;
    excludeDisplayFields?: (keyof User)[];
};

const UserSearchFilter = ({
    filter = {},
    onChange,
    selected,
    isMulti = false,
    formatOptionLabel,
    width,
    defaultOptions = false,
    defaultLabel = '',
    excludeDisplayFields = [],
    label = '',
}: UserSearchFilterProps) => {
    let filterHash = generateStringHash(JSON.stringify(filter));
    return (
        <ObjectSearchSelectTs<User>
            key={filterHash}
            label={label}
            placeholder={`Select User${isMulti ? 's' : ''}`}
            selected={selected}
            onChange={onChange}
            getLabel={(user: User) => user?.name}
            matchProperty="_id"
            loadOptions={async (inputValue: string) => {
                try {
                    const userQuery = {
                        ...filter,
                        search: inputValue,
                    };

                    const { data } = await apiV2.client.UserApi.listUsers(userQuery);

                    if (!data.success) return [];

                    return data.users as any[];
                } catch (e) {
                    console.error('Error loading users:', e);
                    return [];
                }
            }}
            width={width ?? '100%'}
            isMulti={isMulti}
            formatOptionLabel={(option) => {
                if (formatOptionLabel) return formatOptionLabel(option);
                const user: User = option.value;
                if (!user) return 'All Users';
                return (
                    <div>
                        <div style={{}}>{user.name}</div>
                        <div style={{ fontSize: '12px', color: 'var(--color-light-gray)' }}>{user.email}</div>
                    </div>
                );
            }}
            defaultLabel={defaultLabel || 'None'}
            defaultOptions={defaultOptions}
        />
    );
};

export default UserSearchFilter;
