import React, { useState } from 'react';
import DropdownObjects from '../Dropdowns/DropdownObjects';
import Checkbox from '../Inputs/Checkbox';
import DropdownSection from './DropdownSection';
import Multitoggle from '../Toggles/Multitoggle';
import BlockoutComponent from './BlockoutComponent';

import '../../styles/drawerContent.css';

interface InputField {
    title: string;
    alt?: string;
    type?: 'text' | 'password' | 'checkbox' | 'dropdown' | 'dropdown_section' | 'toggle' | 'custom';
    defaultValue?: string | boolean | { title: string; connection?: any };
    value?: string | boolean | { title: string; connection?: any };
    onChange: (value: any, prevValue?: any) => void;
    options?: { title: string }[];
    component?: React.ReactNode;
    renderOption?: (option: any) => string;
    optionField?: string;
    selection?: any;
    defaultSelection?: any;
    excludeNull?: boolean;
    disableDefaultOption?: boolean;
    placeholder?: string;
    style?: React.CSSProperties;
}

interface DrawerContentProps {
    idPrefix: string;
    inputs: InputField[];
    bottomInputs?: InputField[];
    title?: string;
    subtitle?: string;
    readOnly?: boolean;
    submitDisabled?: boolean;
    submitTitle?: string;
    onSubmit?: () => void;
    onCancel?: () => void;
    cancelTitle?: string;
    onDelete?: () => void;
    deleteTitle?: string;
}

const DrawerContent: React.FC<DrawerContentProps> = (props) => {
    const [confirmDelete, setConfirmDelete] = useState(false);

    const renderField = (input: InputField, idx: number) => {
        const idPrefix = `${props.idPrefix}_input_${idx}`;

        return (
            <React.Fragment key={idPrefix}>
                {input.type === 'custom' ? (
                    input.component
                ) : (
                    <>
                        {!['checkbox', 'dropdown_section', 'toggle'].includes(input.type ?? '') && <label>{input.title}</label>}
                        {input.type === 'dropdown_section' ? (
                            <DropdownSection
                                title={input.title}
                                options={input.options || []} // Provide a default value for options
                                renderOption={input.renderOption ?? (input.optionField ? (i: any) => i?.[input.optionField!] : (i: any) => i.title)}
                                selection={input.selection}
                                defaultSelection={input.defaultSelection}
                                select={(newSelection: any) => input.onChange(newSelection)}
                                disableDefaultOption={input.disableDefaultOption ?? true}
                                fontColor="black" // Add the fontColor property with a value
                            />
                        ) : input.type === 'dropdown' ? (
                            <DropdownObjects
                                defaultSelection={input.defaultValue as { title: string; connection?: any } | null}
                                selection={input.value as { title: string; connection?: any } | null}
                                excludeNull={input.excludeNull}
                                idPrefix={idPrefix}
                                sticky={false}
                                options={input.options ?? []}
                                select={(newlySelected: any, prevSelected: any) => {
                                    input.onChange(newlySelected, prevSelected);
                                }}
                            />
                        ) : input.type === 'checkbox' ? (
                            <div style={{ display: 'flex', gap: '8px', alignItems: 'center', marginTop: '10px', marginBottom: '10px' }}>
                                <Checkbox
                                    id={idPrefix}
                                    setChecked={input.onChange}
                                    checked={(input.defaultValue as boolean) ?? (input.value as boolean)}
                                    style={{ width: '12px', height: '12px' }}
                                />
                                <p style={{ fontSize: '14px' }}>{input.title}</p>
                            </div>
                        ) : input.type === 'toggle' ? (
                            <div style={{ display: 'flex', alignItems: 'center', marginTop: '25px', justifyContent: 'space-between' }}>
                                <p style={{ fontSize: '16px', marginRight: '5px' }}>{input.title}</p>
                                <Multitoggle
                                    keyStr={idPrefix}
                                    options={input.options ?? ['Off', 'On']}
                                    selection={(input.options ?? ['Off', 'On'])[((input.defaultValue as boolean) ?? (input.value as boolean)) ? 1 : 0]}
                                    onSelect={(val: any) => input.onChange(!!(input.options ?? ['Off', 'On']).indexOf(val))}
                                    containerStyle={{ width: '70px', height: '24px', ...input.style }}
                                />
                            </div>
                        ) : (
                            <input
                                id={idPrefix}
                                title={input.alt}
                                type={input.type ?? 'text'}
                                placeholder={input.placeholder}
                                defaultValue={input.defaultValue as string}
                                onChange={(e) => input.onChange(e.target.value)}
                                style={{ height: '28px', fontSize: '16px' }}
                            />
                        )}
                    </>
                )}
            </React.Fragment>
        );
    };

    const onDelete = async () => {
        if (confirmDelete) {
            props.onDelete?.();
        } else {
            setConfirmDelete(true);
            setTimeout(() => {
                setConfirmDelete(false);
            }, 3000);
        }
    };

    const { title, subtitle, inputs = [], bottomInputs = [], readOnly, submitDisabled, submitTitle, onSubmit, onCancel, cancelTitle, onDelete: handleDelete, deleteTitle } = props;

    return (
        <div className="fix-connection-drawer" key={`${props.idPrefix ?? ''}fix_connection_drawer`}>
            <div style={{ position: 'relative' }}>
                {readOnly && <BlockoutComponent />}
                {title && <h1>{title}</h1>}
                {subtitle && <p>{subtitle}</p>}
                <div className="inputs">{inputs.map((input, inputIdx) => renderField(input, inputIdx))}</div>
                {!readOnly && (
                    <button
                        className={`fix-connection-drawer-button ${submitDisabled ? 'disabled' : ''}`}
                        onClick={submitDisabled ? undefined : onSubmit}
                        style={{ marginTop: '30px' }}
                    >
                        {submitTitle ?? 'Submit'}
                    </button>
                )}
            </div>
            {onCancel && (
                <div style={{ marginTop: '20px' }} className="a" onClick={onCancel}>
                    {cancelTitle ?? 'Cancel'}
                </div>
            )}
            {handleDelete && (
                <button style={{ marginTop: '20px', backgroundColor: 'var(--color-dark-red)', color: 'white' }} onClick={onDelete}>
                    {confirmDelete ? `Confirm` : (deleteTitle ?? 'Delete')}
                </button>
            )}
            <div className="inputs">{bottomInputs.map((input, inputIdx) => renderField(input, inputIdx))}</div>
        </div>
    );
};

export default DrawerContent;
