import React, { useEffect, useState } from 'react';

interface ConnectionOauthSetupButtonProps {
    connection_id?: string;
    integration_name?: string;
}

const INTEGRATIONS: any = {
    carta: {
        prodUrl: 'https://login.app.carta.com/o/authorize/',
        devUrl: 'https://login.playground.carta.team/o/authorize/',
        prodOauthId: 'WE4KI3EaTxtCOYeAMhN8Y1E4rRZt2oxM6KslP0JR',
        devOauthId: 'w3fB6Kz6G7mncMuHd4OGEcB3fDolGsF0B3ibQAw3',
        scope: 'openid profile email read_portfolio_info read_portfolio_fundinvestmentdocuments read_user_info',
    },
    // Add more integrations here
};

const ConnectionOauthSetupButton: React.FC<ConnectionOauthSetupButtonProps> = ({ connection_id, integration_name }) => {
    const [oauthUrl, setOauthUrl] = useState<string | null>(null);
    const is_prod = window.location.hostname === 'app.altexchange.com';

    useEffect(() => {
        if (!connection_id || !integration_name) {
            return;
        }
        const integration = INTEGRATIONS[integration_name.toLowerCase()];
        if (!integration) {
            return;
        }

        const generateRandomState = () => {
            return Math.random().toString(36).substring(2);
        };

        const buildUrl = (integration: any, redirectUri: string, state: string) => {
            const oauthId = is_prod ? integration.prodOauthId : integration.devOauthId;
            const baseUrl = is_prod ? integration.prodUrl : integration.devUrl;
            let query = `?client_id=${oauthId}`;
            query += `&redirect_uri=${encodeURIComponent(redirectUri)}`;
            query += `&response_type=code&scope=${encodeURIComponent(integration.scope)}`;
            query += `&state=${state}`;
            return baseUrl + query;
        };

        const redirectUri = `${window.location.origin}/oauth/${integration_name.toLowerCase()}`;
        const state = generateRandomState();
        const url = buildUrl(integration, redirectUri, state);

        // Save lookup info to local storage with prefix
        const localStorageKey = `connection_oauth_setup_${state}`;
        localStorage.setItem(localStorageKey, JSON.stringify({ connection_id, integration_name }));
        setOauthUrl(url);

        const handleBeforeUnload = () => {
            localStorage.removeItem(localStorageKey);
        };

        window.addEventListener('beforeunload', handleBeforeUnload);

        return () => {
            localStorage.removeItem(localStorageKey);
            window.removeEventListener('beforeunload', handleBeforeUnload);
        };
    }, [connection_id, integration_name, is_prod]);

    if (!connection_id || !integration_name || !oauthUrl) {
        return <></>;
    }

    return (
        <a href={oauthUrl} className="oauth-setup-button">
            Setup OAuth for {integration_name}
        </a>
    );
};

export default ConnectionOauthSetupButton;
