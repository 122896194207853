import { FileParseResult, FileTextResult, FileTypes } from '../../../../constants/file';
import BulkUploadHandler, { BulkUploadHandlerCreateType, BulkUploadHandlerConfig } from './BulkUploadHandler';
import sharedFields, { sharedFieldEnum } from '../types/sharedFields';
import { BulkUploadColumn, BulkUploadRequestResult, BulkUploadType } from '../types/bulkUploadTypes';
import api2 from '../../../../api2';

const SIGNATORY_COUNT = 4;

// User ID
// Ownership type
// Entity ID
// RAL Template pick
// Entity Name
// Investor Name
// Singatory Name
// Signatory Role
// Signatory First Name
// Signatory Last name
// Signatory email
// Signatory Role 2 (optional)
// Signatory First Name 2 (optional)
// Signatory Last name 2 optional
// Signatory email 2 optional
// Signatory Role 3 (optional)
// Signatory First Name 3 (optional)
// Signatory Last name 3 optional
// Signatory email 3 optional
// Signatory Role 4 (optional)
// Signatory First Name 4 (optional)
// Signatory Last name 4 optional
// Signatory email 4 optional

export interface RalRequestUploadHandlerState {
    ralTemplates: any[];
}

export interface RalRequestUploadHandlerCreateType extends BulkUploadHandlerCreateType {
    user?: string;
    ownershipType?: string;
    ownershipId?: string;
    ralTemplate?: string;
    entityName?: string;
    investorName?: string;

    signatoryName1?: string;
    signatoryName2?: string;

    signatoryRole1?: string;
    signatoryFirstName1?: string;
    signatoryLastName1?: string;
    signatoryEmail1?: string;

    signatoryRole2?: string;
    signatoryFirstName2?: string;
    signatoryLastName2?: string;
    signatoryEmail2?: string;

    signatoryRole3?: string;
    signatoryFirstName3?: string;
    signatoryLastName3?: string;
    signatoryEmail3?: string;

    signatoryRole4?: string;
    signatoryFirstName4?: string;
    signatoryLastName4?: string;
    signatoryEmail4?: string;

    [additionalFieldName: string]: any;
}

export interface RalRequestUploadHandlerConfig extends BulkUploadHandlerConfig {}

const getSignatoryFieldSet = (num: number, required = false) => {
    return {
        [`signatoryRole${num}`]: {
            ...sharedFields.string,
            displayName: `Signatory Role ${num}`,
            fieldName: `signatoryRole${num}`,
            required,
            style: { width: '250px' },
        },
        [`signatoryFirstName${num}`]: {
            ...sharedFields.string,
            displayName: `Signatory First Name ${num}`,
            fieldName: `signatoryFirstName${num}`,
            required,
            style: { width: '250px' },
        },
        [`signatoryLastName${num}`]: {
            ...sharedFields.string,
            displayName: `Signatory Last Name ${num}`,
            fieldName: `signatoryLastName${num}`,
            required,
            style: { width: '250px' },
        },
        [`signatoryEmail${num}`]: {
            ...sharedFields.string,
            displayName: `Signatory Email ${num}`,
            fieldName: `signatoryEmail${num}`,
            required,
            style: { width: '250px' },
        },
    };
};

const ownershipTypeField = {
    ...sharedFieldEnum(['User', 'Account', 'Investment']),
    displayName: 'Ownership Type',
    fieldName: 'ownershipType',
    required: true,
    style: { width: '250px' },
};

const getRALTemplateField = (ralTemplates: any[]) => {
    return {
        ...sharedFieldEnum(ralTemplates.map((template) => template.name)),
        displayName: 'RAL Template',
        fieldName: 'ralTemplate',
        isValid: (val: string) => {
            return (
                ralTemplates.map((template) => template.name.toLowerCase()).includes(val.toLowerCase()) ||
                ralTemplates.map((template) => template._id).includes(val) ||
                ralTemplates.map((template) => template.name.toLowerCase()).includes(val.toLowerCase())
            );
        },
        format: (val: string) => {
            return (
                ralTemplates.find((template) => template.name.toLowerCase() === val.toLowerCase() || template._id === val || template.name.toLowerCase() === val.toLowerCase())
                    ?.name || ''
            );
        },
        required: true,
        style: { width: '350px' },
    };
};

const getSignatoryNameField = (num: number, required = false) => {
    return {
        ...sharedFields.string,
        displayName: `Signatory Name ${num}`,
        fieldName: `signatoryName${num}`,
        style: { width: '250px' },
        required,
    };
};

export const onLoadHandler = async (thisHandler: BulkUploadHandler<RalRequestUploadHandlerCreateType, RalRequestUploadHandlerConfig, RalRequestUploadHandlerState>) => {
    try {
        const ralTemplates = (await api2.client.RALRequestTemplateApi.listRALRequestTemplates({})).data.templates || [];

        // set the state for the handler
        thisHandler.state = {
            ralTemplates,
        };

        thisHandler.base_columns.ralTemplate = getRALTemplateField(ralTemplates);
    } catch (err) {
        console.log('error getting ral templates:', err);
    }
};

class RalRequestUploadHandler extends BulkUploadHandler<RalRequestUploadHandlerCreateType, RalRequestUploadHandlerConfig, RalRequestUploadHandlerState> {
    // The type of the bulk upload, eg. 'ral_request' or 'valuation'
    type = BulkUploadType.ral_request;

    // The base columns that are required for the bulk upload
    base_columns = {
        user: { ...sharedFields.object_id, displayName: 'User ID', fieldName: 'user' },
        ownershipType: ownershipTypeField,
        ownershipId: { ...sharedFields.object_id, displayName: 'Entity ID', fieldName: 'ownershipId' },
        ralTemplate: getRALTemplateField([]),
        entityName: { ...sharedFields.string, displayName: 'Entity Name', fieldName: 'entityName' },
        investorName: { ...sharedFields.string, displayName: 'Investor Name', fieldName: 'investorName' },

        signatoryName1: getSignatoryNameField(1, true),
        signatoryName2: getSignatoryNameField(2),

        ...getSignatoryFieldSet(1, true),
        ...getSignatoryFieldSet(2),
        ...getSignatoryFieldSet(3),
        ...getSignatoryFieldSet(4),
    };

    // The order of the columns in the CSV file
    columnOrder = Object.keys(this.base_columns) as (keyof RalRequestUploadHandlerCreateType)[];

    // sort the columns based on the order in columnOrder, or the default order if not provided
    getColumns = (columnOrder: (keyof RalRequestUploadHandlerCreateType)[] = this.columnOrder): { [key in keyof RalRequestUploadHandlerCreateType]: BulkUploadColumn } => {
        // Sort the columns based on the given column order or the default order.
        const sortedColumns = this._sortColumns(this.base_columns, columnOrder);
        return sortedColumns;
    };

    // check a single row of data to see if it is valid
    isDataValid = (data: { [key in keyof RalRequestUploadHandlerCreateType]: string }, columnOrder?: (keyof RalRequestUploadHandlerCreateType)[]) => {
        const columns = this.getColumns(columnOrder);
        const isDataValid = this._isColumnDataValid(columns, data, columnOrder);
        // any additional validation goes here
        return isDataValid;
    };

    checkForErrors = (data: { [key in keyof RalRequestUploadHandlerCreateType]: string }, columnOrder?: (keyof RalRequestUploadHandlerCreateType)[]): string[] => {
        let errors = [] as string[];

        const template = this.state?.ralTemplates.find((template) => template.name.toLowerCase() === data.ralTemplate?.toLowerCase());

        if (!template) {
            return ['Invalid RAL Template'];
        }

        const signatoryCount = template.required_signatories.length;
        // check for required signatories
        for (let i = 1; i <= signatoryCount; i++) {
            // check for signatory names (in tokens)
            if (!data[`signatoryName${i}`]) {
                errors.push(`"SignatoryName${i}" is missing`);
            }

            // check for all 4 signatory fields
            if (!data[`signatoryRole${i}`] || !data[`signatoryFirstName${i}`] || !data[`signatoryLastName${i}`] || !data[`signatoryEmail${i}`]) {
                errors.push(`Signatory ${i} is missing required fields`);
            }
        }

        return errors;
    };

    /**
     * Parses a single line from a CSV file into an object with the correct fields.
     * Does not handle validation.
     * @param {string} line A single line from a CSV file.
     * @returns {Object} An object with the correct fields for the line.
     */
    parseSingleCsvLine = (line: string, columnOrder?: (keyof RalRequestUploadHandlerCreateType)[]): RalRequestUploadHandlerCreateType => {
        // if not enough commas are included, the fields will be empty strings
        const expectedColumns = this.getColumns(columnOrder);
        const parsedValues = this._parseSingleCsvLine(line, columnOrder);

        // set up special conditions based on the config and data type
        const lineData: RalRequestUploadHandlerCreateType = {
            user: expectedColumns.user?.format(parsedValues.user),
            ownershipType: expectedColumns.ownershipType?.format(parsedValues.ownershipType),
            ownershipId: expectedColumns.ownershipId?.format(parsedValues.ownershipId),
            ralTemplate: expectedColumns.ralTemplate?.format(parsedValues.ralTemplate),
            entityName: expectedColumns.entityName?.format(parsedValues.entityName),
            investorName: expectedColumns.investorName?.format(parsedValues.investorName),
            signatoryName1: expectedColumns.signatoryName1?.format(parsedValues.signatoryName1),
            signatoryName2: expectedColumns.signatoryName2?.format(parsedValues.signatoryName2),
        };

        Array.from({ length: SIGNATORY_COUNT }, (_, i) => i + 1).forEach((num) => {
            lineData[`signatoryRole${num}`] = expectedColumns[`signatoryRole${num}`]?.format(parsedValues[`signatoryRole${num}`]);

            lineData[`signatoryFirstName${num}`] = expectedColumns[`signatoryFirstName${num}`]?.format(parsedValues[`signatoryFirstName${num}`]);

            lineData[`signatoryLastName${num}`] = expectedColumns[`signatoryLastName${num}`]?.format(parsedValues[`signatoryLastName${num}`]);

            lineData[`signatoryEmail${num}`] = expectedColumns[`signatoryEmail${num}`]?.format(parsedValues[`signatoryEmail${num}`]);
        });

        return lineData;
    };

    // Parse the text file results into a FileParseResult
    parseTextFileResult = async (
        textFileResult: FileTextResult,
        columnOrder?: (keyof RalRequestUploadHandlerCreateType)[]
    ): Promise<FileParseResult<RalRequestUploadHandlerCreateType>> => {
        try {
            const data = textFileResult.lines.map((line) => this.parseSingleCsvLine(line, columnOrder));
            return {
                success: true,
                message: 'File parsed successfully',
                file: textFileResult.file,
                data,
            } as FileParseResult<RalRequestUploadHandlerCreateType>;
        } catch (err: any) {
            return {
                success: false,
                message: `Error parsing file: ${err.message}`,
                file: textFileResult.file,
            } as FileParseResult<RalRequestUploadHandlerCreateType>;
        }
    };

    // Get the notes for the bulk upload type
    getNotes = (): string[] => {
        let notes: string[] = [];
        if (this.config.investment) {
            notes = [...notes];
        }
        return notes;
    };

    // function to create the object in the database from the parsed data
    create = async (
        columnObj: { [key: string]: BulkUploadColumn },
        data: { [key: string]: any },
        columnOrder?: (keyof RalRequestUploadHandlerCreateType)[]
    ): Promise<BulkUploadRequestResult> => {
        // Ensure the data is valid
        if (!this.isDataValid(data, columnOrder) || this.checkForErrors(data, columnOrder).length > 0) {
            return { success: false, message: 'Invalid data' };
        }

        const ralTemplate = this.state?.ralTemplates.find((template) => template.name.toLowerCase() === data.ralTemplate?.toLowerCase());

        if (!ralTemplate) {
            return { success: false, message: 'Invalid RAL Template' };
        }

        let body: any = {
            userId: data.user,
            ownership_type: data.ownershipType,
            ownership_ids: [data.ownershipId],
            ral_request_template: ralTemplate._id,
            tokens: {
                ['Entity Name']: data.entityName,
                ['Investor Name']: data.investorName,
            },
            signatories: [],
        };

        const requiredSignatoryCount = ralTemplate.required_signatories.length;

        // check for signatoryName for tokens
        for (let i = 1; i <= requiredSignatoryCount; i++) {
            if (!data[`signatoryName${i}`]) {
                return { success: false, message: `Signatory Name ${i} is required` };
            } else {
                // add signatory name to tokens
                body.tokens[`Signatory Name${i > 1 ? ` ${i}` : ''}`] = data[`signatoryName${i}`];
            }
        }

        // gather signatories
        let i = 1;
        while (true) {
            const roleRequired = i <= requiredSignatoryCount;
            const role = data[`signatoryRole${i}`];
            const first_name = data[`signatoryFirstName${i}`];
            const last_name = data[`signatoryLastName${i}`];
            const email = data[`signatoryEmail${i}`];

            // if any of the required fields are missing, break
            if (!first_name || !last_name || !email) {
                break;
            }

            // if role is required and not provided, return an error
            if (roleRequired && !role) {
                return { success: false, message: `Signatory Role ${i} is required` };
            }

            // add signatory to list
            body.signatories.push({
                role,
                first_name,
                last_name,
                email,
            });

            i++;
        }

        // ensure the correct number of signatories are provided
        if (body.signatories.length < requiredSignatoryCount) {
            return { success: false, message: `Not enough signatories provided. ${requiredSignatoryCount} required` };
        }

        try {
            // Attempt to create a single ral_request from the current object.
            const ral_request_id = (
                await api2.client.RALRequestApi.createRALRequest({
                    CreateRALRequestRequest: body,
                })
            ).data.ral_request_id;

            // Return the new ral_request ID.
            return {
                success: true,
                message: 'RalRequest created successfully',
                id: ral_request_id,
            };
        } catch (err) {
            // Log and return null in case of an error.
            console.log('error creating ral_request:', err);
            const validationError = (err as any)?.response?.data?.validation?.[0]?.message;
            return {
                success: false,
                message: `Error creating RAL Request: ${(err as any)?.response?.data?.message || (err as any).message}${validationError ? `: ${validationError}` : ''}`,
            };
        }
    };

    // function to delete the object from the database
    delete = async (id: string): Promise<BulkUploadRequestResult> => {
        try {
            await api2.client.RALRequestApi.deleteRALRequest({
                ral_request_id: id,
            });
            return {
                success: true,
                message: 'RalRequest deleted successfully',
            };
        } catch (err) {
            console.log('error deleting ral request:', err);
            return {
                success: false,
                message: `Error deleting RAL Request: ${(err as any)?.response?.data?.message || (err as any).message}`,
            };
        }
    };
}

// getter for the RalRequestUploadHandler
export const getRalRequestUploadHandler: (config: RalRequestUploadHandlerConfig) => RalRequestUploadHandler = (config: RalRequestUploadHandlerConfig) =>
    new RalRequestUploadHandler(config, onLoadHandler);

export default RalRequestUploadHandler;
