const convertOutreachStatus = (status: string, phrasing_map: { [key: string]: string }, reverse: boolean = false): string => {
    if (reverse) {
        const reversedStatus = Object.entries(phrasing_map ?? {}).find(([k, v]) => v === status)?.[0] ?? status;
        return reversedStatus;
    }
    const rephrasedStatus = phrasing_map?.[status] ?? status;
    return rephrasedStatus;
};

export default convertOutreachStatus;
