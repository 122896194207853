import React from 'react';
import additionalEnums from '../../../additionalEnums';
import api2 from '../../../api2';
import { getColorFromIndex } from '../../../utilities/AdvisorVue/getColorFromIndex';
import convertOutreachStatus from '../utilities/convertOutreachStatus';
import styles from './AssetManagerProgressWidget.module.scss';

interface AssetManagerProgressWidgetProps {
    // Define the props here
    // Example: userId: string;
}

const AssetManagerProgressWidget: React.FC<AssetManagerProgressWidgetProps> = (props) => {
    const [stats, setStats] = React.useState<any>(null);
    const outreachStatuses = additionalEnums.AssetManagerConnectionOutreachStatuses;

    const [popperInfo, setPopperInfo] = React.useState({ left: 0, top: 0, content: '' });

    const componentRef = React.useRef<HTMLDivElement>(null);

    React.useEffect(() => {
        const fetchStats = async () => {
            try {
                const stats = (await api2.client.AssetManagerPortalApi.getStats()).data.stats || [];
                stats?.forEach((stat: any) => {
                    stat.outreach_status = convertOutreachStatus(stat.outreach_status, (outreachStatuses.phrasing_map as { [key: string]: string }) ?? {});
                });

                stats.sort((a, b) => {
                    const orderA = Object.values(outreachStatuses.phrasing_map as { [key: string]: string }).indexOf(a.outreach_status as string);
                    const orderB = Object.values(outreachStatuses.phrasing_map as { [key: string]: string }).indexOf(b.outreach_status as string);
                    return orderA - orderB;
                });

                setStats(stats);
            } catch (e) {
                console.error('Error fetching stats:', e);
            }
        };
        fetchStats();
    }, []);

    const totalCount = stats?.reduce((acc: number, stat: any) => acc + stat.count, 0) || 0;
    const completedCount = stats?.find((stat: any) => stat.outreach_status.toLowerCase() === 'completed')?.count || 0;

    const renderPopper = () => {
        if (!popperInfo.content) return null;
        return (
            <div className={styles.popper} style={{ left: popperInfo.left, top: popperInfo.top }}>
                {popperInfo.content}
            </div>
        );
    };

    return (
        <div className={`widget ${styles.main}`} ref={componentRef}>
            <div className={styles.header}>
                <h1>Asset Manager Progress</h1>
                <div>
                    Completed {completedCount} / {totalCount}
                </div>
            </div>
            <div className={styles.progressBar}>
                {stats?.map((stat: any, index: number) => (
                    <div
                        key={stat._id}
                        className={styles.progressItem}
                        style={{
                            backgroundColor: getColorFromIndex(index),
                            width: `${(stat.count / totalCount) * 100}%`,
                        }}
                        onMouseMove={(e) => {
                            const componentRect = componentRef.current?.getBoundingClientRect?.();
                            if (!componentRect) return;
                            setPopperInfo({
                                left: e.clientX - componentRect.left,
                                top: e.clientY - componentRect.top + 20,
                                content: `${stat.outreach_status} (${stat.count})`,
                            });
                        }}
                        onMouseLeave={() => setPopperInfo({ left: 0, top: 0, content: '' })}
                    />
                ))}
            </div>

            <div className={styles.legend}>
                {stats?.map((stat: any, index: number) => (
                    <div key={stat._id} className={styles.legendItem} style={{ display: 'flex', alignItems: 'center' }}>
                        <div style={{ backgroundColor: getColorFromIndex(index), width: '20px', height: '20px', marginRight: '10px' }}></div>
                        <div>
                            {stat.outreach_status} ({stat.count})
                        </div>
                    </div>
                ))}
            </div>

            {renderPopper()}
        </div>
    );
};

export default AssetManagerProgressWidget;
