import React from 'react';
import Select from 'react-select';

import AsyncSelect from 'react-select/async';

import '../../styles/objectFilterSelect.css';

export const ObjectSearchSelect = (props) => {
    const [key, setKey] = React.useState(0);

    const loadOptionsTimeout = React.useRef(null);

    // const [options, setOptions] = React.useState([])

    React.useEffect(() => {
        if (props.key) {
            setKey(key + 1);
        }
    }, [props.key]);

    const defaultOptions = [
        {
            value: null,
            label: props.defaultLabel || 'All',
        },
    ];

    const loadOptions = async (inputValue) => {
        let opts = await props.loadOptions?.(inputValue);
        // setOptions(opts)
        opts = opts.map((obj) => ({
            value: obj,
            label: props.getLabel(obj),
        }));

        if (!props.noDefaultOption) {
            opts = [...defaultOptions, ...opts];
        }

        return opts;
    };

    const loadDefaultOptions = () => {
        if (props.defaultOptions === false) {
            if (!props.noDefaultOption) {
                return defaultOptions;
            }
            return false;
        } else if (props.defaultOptions !== undefined) {
            return props.defaultOptions;
        }
        return true;
    };

    let value;

    if (props.isMulti) {
        value = (props.selected ?? []).map((obj) => {
            return {
                value: obj,
                label: props.getLabel(obj) ?? props.placeholder,
            };
        });
    } else {
        const matchedProperty = props.matchProperty ? props.selected?.[props.matchProperty] : props.selected;

        value = {
            value: props.selected,
            label: props.selected ? props.getLabel(props.selected) : props.defaultLabel || props.placeholder,
        };
    }

    return (
        <div
            style={{
                position: 'relative',
                ...props.containerStyle,
                textAlign: props.textAlign,
                width: props.width ?? 'unset',
                lineHeight: props.height,
            }}
            onClick={(e) => {
                e.stopPropagation();
            }}
        >
            <div>{props.label}</div>
            <AsyncSelect
                openMenuOnClick={!props.disabled}
                key={key}
                className={'userFilterSelect-select'}
                placeholder={props.placeholder ?? 'Filter by user'}
                loadOptions={(inputValue, callback) => {
                    // if (!inputValue) return callback([]);
                    clearTimeout(loadOptionsTimeout.current);
                    loadOptionsTimeout.current = setTimeout(() => {
                        loadOptions(inputValue).then((options) => callback(options));
                    }, 700);
                }}
                value={value}
                onChange={props.onChange}
                styles={{
                    control: (provided, state) => ({
                        ...provided,
                        width: props.width ?? '100%',
                        height: props.height,
                        ...props.style,
                    }),
                    menu: (provided, state) => ({
                        ...provided,
                        width: props.width ?? '100%',
                        ...props.style,
                    }),
                }}
                filterOption={props.filterOptionFunc}
                defaultOptions={loadDefaultOptions()}
                isMulti={props.isMulti}
                formatOptionLabel={props.formatOptionLabel}
            />
        </div>
    );
};
