import React from 'react';
import { generateStringHash } from '../../utilities/string/generateStringHash';
import ObjectSearchSelectTs, { ObjectSearchSelectProps } from '../Dropdowns/ObjectSearchSelectTs';
import api from '../../api';
import { toQueryString } from '../../utilities/apiHelpers/queryString';
import { Integration } from '../../types/Integration';
import api2 from '../../api2';

type GenericSearchFilterProps<T> = Omit<ObjectSearchSelectProps<T>, 'getLabel' | 'loadOptions'> & {
    filter?: any;
    excludeDisplayFields?: (keyof T)[];
    disabled?: boolean;
    getLabel: (item: T) => string;
    loadOptions: (inputValue: string, filter: any) => Promise<T[]>;
};

const GenericSearchFilter = <T,>({
    filter = {},
    onChange,
    selected,
    isMulti = false,
    formatOptionLabel,
    width,
    defaultOptions = false,
    defaultLabel = '',
    excludeDisplayFields = [],
    label = '',
    additionalOptions,
    disabled = false,
    getLabel,
    loadOptions,
}: GenericSearchFilterProps<T>) => {
    let filterHash = generateStringHash(JSON.stringify(filter));
    return (
        <ObjectSearchSelectTs<T>
            key={filterHash}
            label={label}
            placeholder={`Select Item${isMulti ? 's' : ''}`}
            selected={selected}
            onChange={onChange}
            getLabel={getLabel}
            matchProperty="_id"
            loadOptions={async (inputValue: string) => {
                try {
                    return await loadOptions(inputValue, filter);
                } catch (e) {
                    console.error('Error loading items:', e);
                    return [];
                }
            }}
            width={width ?? '100%'}
            isMulti={isMulti}
            formatOptionLabel={(option) => {
                if (formatOptionLabel) return formatOptionLabel(option);
                const item: T = option.value;

                if (!item) return defaultLabel || 'None';
                return (
                    <div>
                        <div>{getLabel(item)}</div>
                    </div>
                );
            }}
            defaultLabel={defaultLabel || 'None'}
            defaultOptions={defaultOptions}
            additionalOptions={additionalOptions}
            isDisabled={disabled}
        />
    );
};

export default GenericSearchFilter;

const IntegrationSearchFilter = (props: Omit<GenericSearchFilterProps<Integration>, 'getLabel' | 'loadOptions'>) => {
    return (
        <GenericSearchFilter<Integration>
            {...props}
            getLabel={(integration: Integration) => integration?.name}
            loadOptions={async (inputValue: string, filter: any) => {
                try {
                    const integrations = (
                        await api2.client.IntegrationApi.listIntegrations({
                            ...filter,
                            search: inputValue,
                            limit: 50,
                        })
                    ).data.integrations;
                    return integrations as Integration[];
                } catch (e) {
                    console.error('Error loading integrations:', e);
                    return [];
                }
            }}
            formatOptionLabel={(option) => {
                if (props.formatOptionLabel) return props.formatOptionLabel(option);
                const integration: Integration = option.value;
                if (!integration) return props.defaultLabel || 'None';
                return (
                    <div>
                        <div>{integration.name}</div>
                    </div>
                );
            }}
        />
    );
};

export { IntegrationSearchFilter };

const AdvisoryGroupSearchFilter = (props: Omit<GenericSearchFilterProps<any>, 'getLabel' | 'loadOptions'>) => {
    return (
        <GenericSearchFilter<any>
            {...props}
            getLabel={(advisoryGroup: any) => advisoryGroup?.name}
            loadOptions={async (inputValue: string, filter: any) => {
                try {
                    const query = {
                        ...filter,
                        search: inputValue,
                        count: 50,
                        sortField: 'name',
                    };

                    const advisoryGroups = (await api.get(`/advisorygroups?${toQueryString(query)}`)).results;

                    return advisoryGroups as any[];
                } catch (e) {
                    console.error('Error loading advisory groups:', e);
                    return [];
                }
            }}
            formatOptionLabel={(option) => {
                if (props.formatOptionLabel) return props.formatOptionLabel(option);
                const advisoryGroup: any = option.value;

                if (!advisoryGroup) return props.defaultLabel || 'All Advisory Groups';

                return (
                    <div>
                        <div style={{ fontSize: '12px', color: 'var(--color-light-gray)' }}>{advisoryGroup?.plan?.public_plan_name}</div>
                        <div>{advisoryGroup?.name}</div>
                    </div>
                );
            }}
        />
    );
};

export { AdvisoryGroupSearchFilter };

const UserSearchFilter = (props: Omit<GenericSearchFilterProps<any>, 'getLabel' | 'loadOptions'>) => {
    return (
        <GenericSearchFilter<any>
            {...props}
            getLabel={(user: any) => user?.name}
            loadOptions={async (inputValue: string, filter: any) => {
                try {
                    const userQuery = {
                        ...filter,
                        search: inputValue,
                        limit: 50,
                    };
                    const { data } = await api2.client.UserApi.listUsers(userQuery);

                    if (!data.success) return [];

                    return data.users ?? [];
                } catch (e) {
                    console.error('Error loading users:', e);
                    return [];
                }
            }}
            formatOptionLabel={(option) => {
                if (props.formatOptionLabel) return props.formatOptionLabel(option);
                const user: any = option.value;
                if (!user) return 'All Users';
                return (
                    <div>
                        <div style={{}}>{user.name}</div>
                        <div style={{ fontSize: '12px', color: 'var(--color-light-gray)' }}>{user.email}</div>
                    </div>
                );
            }}
        />
    );
};

export { UserSearchFilter };

const AccountSearchFilter = (props: Omit<GenericSearchFilterProps<any>, 'getLabel' | 'loadOptions'>) => {
    return (
        <GenericSearchFilter<any>
            {...props}
            getLabel={(account: any) => account?.name}
            loadOptions={async (inputValue: string, filter: any) => {
                try {
                    const accounts = (
                        await api2.client.AccountApi.listAccounts({
                            ...filter,
                            search: inputValue,
                            populate_user: true,
                            limit: 50,
                        })
                    ).data.accounts;

                    return accounts as any;
                } catch (e) {
                    console.error('Error loading accounts:', e);
                    return [];
                }
            }}
            formatOptionLabel={(option) => {
                if (props.formatOptionLabel) return props.formatOptionLabel(option);
                const account: any = option.value;

                if (!account) return props.defaultLabel || 'All Entities';
                const showUser = !(props.excludeDisplayFields ?? []).includes('user');
                const title = `${account.name}${account.user && showUser ? ` (${(account.user as any).name})` : ''}`;
                return (
                    <div title={title}>
                        {showUser && account.user && <div style={{ fontSize: '12px', color: 'var(--color-light-gray)' }}>{(account.user as any).name}</div>}
                        <div style={{}}>{account.name}</div>
                    </div>
                );
            }}
        />
    );
};

export { AccountSearchFilter };

const ConnectionSearchFilter = (props: Omit<GenericSearchFilterProps<any>, 'getLabel' | 'loadOptions'>) => {
    return (
        <GenericSearchFilter<any>
            {...props}
            getLabel={(connection: any) => connection?.name}
            loadOptions={async (inputValue: string, filter: any) => {
                try {
                    const connections = (
                        await api2.client.ConnectionApi.listConnections({
                            ...filter,
                            search: inputValue,
                            populate_user: true,
                            limit: 50,
                        })
                    ).data.connections;

                    return connections as any[];
                } catch (e) {
                    console.error('Error loading connections:', e);
                    return [];
                }
            }}
            formatOptionLabel={(option) => {
                if (props.formatOptionLabel) return props.formatOptionLabel(option);
                const connection: any = option.value;

                if (!connection) return props.defaultLabel || 'All Connections';
                const showUser = !(props.excludeDisplayFields ?? []).includes('user');
                const title = `${connection.name}${connection.user && showUser ? ` (${connection.user})` : ''}`;
                return (
                    <div title={title}>
                        {showUser && connection.user && <div style={{ fontSize: '12px', color: 'var(--color-light-gray)' }}>{connection.user?.name}</div>}
                        <div>{connection.name}</div>
                    </div>
                );
            }}
        />
    );
};

export { ConnectionSearchFilter };

const AssetManagerSearchFilter = (props: Omit<GenericSearchFilterProps<any>, 'getLabel' | 'loadOptions'>) => {
    return (
        <GenericSearchFilter<any>
            {...props}
            getLabel={(assetManager: any) => assetManager?.name}
            loadOptions={async (inputValue: string, filter: any) => {
                try {
                    const assetManagerQuery = {
                        ...filter,
                        search: inputValue,
                    };
                    const assetManagerQueryString = toQueryString(assetManagerQuery);
                    const assetManagers: any[] = (await api.get(`/assetmanagers?${assetManagerQueryString}`)).results ?? [];

                    return assetManagers;
                } catch (e) {
                    console.error('Error loading asset managers:', e);
                    return [];
                }
            }}
            formatOptionLabel={(option) => {
                if (props.formatOptionLabel) return props.formatOptionLabel(option);
                const assetManager: any = option.value;
                if (!assetManager) return props.defaultLabel || 'None';
                return (
                    <div>
                        <div style={{}}>{assetManager.name}</div>
                        <div style={{ fontSize: '12px', color: 'var(--color-light-gray)' }}>{assetManager.website}</div>
                    </div>
                );
            }}
        />
    );
};

export { AssetManagerSearchFilter };
