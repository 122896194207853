// Developed by Aptus Engineering, Inc. <https://aptus.aero>
// See LICENSE.md file in project root directory

import _ from 'lodash';
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';

import '../../styles/widget.css';
import '../../styles/widget-AllocationChart.css';

import { Doughnut } from 'react-chartjs-2';
// eslint-disable-next-line
import { Chart as ChartJS } from 'chart.js/auto';

import { getColorFromIndex } from '../../utilities/AdvisorVue/getColorFromIndex';
import { isFiniteNonzero } from '../../utilities/validation/numericalValidation';
import { calcTotalValue } from '../../utilities/calculate/financeCalculations';
class AllocationChart extends Component {
    state = {
        types: [],
        data: null,
        sum: 0,
        sumMultStr: '',
    };

    componentDidMount = () => {
        this.loadData();
    };

    componentDidUpdate = (prev) => {
        if (!_.isEqual(this.props.investments, prev.investments)) this.loadData();
    };

    loadData = () => {
        const investments = this.props.investments;
        if (!this.props.advisorVue) if (!investments || !investments.length) return;

        this.props.loading(320, 'allocationChart');

        const getInvestmentValue = (i) => {
            // const value = calcTotalValue(i);
            const value = i.valuation;
            return isFiniteNonzero(value) ? value : 0;
        };

        const types = [];
        for (const i of investments) {
            let value = getInvestmentValue(i);
            value = Number.isFinite(value) ? value : 0;

            const existing = types.find((t) => t.name === i.type);
            // Exists, aggregate
            if (existing) existing.value += value;
            // Else, add new type
            else {
                types.push({
                    name: i.type,
                    value,
                });
            }
        }

        // Now compile allocation %s
        let sum = types.reduce((x, i) => {
            let investmentValue = i.value;
            if (Number.isFinite(investmentValue)) {
                x += investmentValue;
            }
            return x;
        }, 0);
        let sumMultStr = '';

        const BILLION = 1000000000;
        const MILLION = 1000000;
        const THOUSAND = 1000;
        if (sum >= BILLION) {
            sum /= BILLION;
            sumMultStr = 'billion';
            sum = sum.toFixed(1);
        } else if (sum >= MILLION) {
            sum /= MILLION;
            sumMultStr = 'million';
            sum = sum.toFixed(1);
        } else if (sum >= THOUSAND) {
            sum /= THOUSAND;
            sum = sum.toFixed(1);
            sumMultStr = 'thousand';
        } else {
            // ensure 2 decimals if not "00"
            sum = sum.toFixed(2);
        }

        // sort by value
        types.sort((a, b) => b.value - a.value);

        // donut chart data and labels
        const datasets = [
            {
                type: 'doughnut',
                backgroundColor: types.map((_, i) => getColorFromIndex(i)),
                data: types.map((t) => t.value),
            },
        ];

        this.setState({
            types,
            data: {
                labels: types.map((t) => t.name),
                datasets,
            },
            sum,
            sumMultStr,
        });

        this.props.loaded('allocationChart');
    };

    getChartOptions = () => ({
        cutout: '70%',
        animation: false,
        responsive: true,
        plugins: {
            legend: {
                display: false,
            },
        },
    });

    renderLegendKeys = () => {
        return (
            <div className="donutLegend">
                {this.state.types.map((elem, idx) => (
                    <div className="donutLegendItem" key={`legendKey${idx}`}>
                        <div className="donutLegendSquare" style={{ backgroundColor: getColorFromIndex(idx) }} />
                        <div className="donutLegendText">{elem.name}</div>
                    </div>
                ))}
            </div>
        );
    };

    render = () => {
        return (
            <div className="widget donutComponent" style={{ ...this.props.containerStyle }}>
                <div className="donutGraph">
                    <div className="donutText">
                        <div>{'$' + this.state.sum}</div>
                        <div>{this.state.sumMultStr}</div>
                    </div>
                    {this.state.data && (
                        <Doughnut type="doughnut" data={this.state.data} options={this.getChartOptions()} style={{ position: 'relative', backgroundColor: 'transparent' }} />
                    )}
                </div>

                {this.renderLegendKeys()}
            </div>
        );
    };
}

export default withRouter(AllocationChart);
