import React from 'react';
import moment from 'moment';
import '../../styles/clientListStatus.css';
import { getClientStatusColor } from '../../utilities/AdvisorVue/getClientStatusColor';
import rephraseClientStatus from '../../utilities/AdvisorVue/rephraseClientStatus';

interface Advisor {
    invited_on?: string;
    revoked_on?: string;
    [key: string]: any; // Allow for additional properties that might exist on Advisor
}

interface Client {
    isConnectionRequest?: boolean;
    status?: string;
    last_login_at?: string;
    updated_at?: string;
    currentAdvisor?: Advisor;
    [key: string]: any; // Allow for additional properties that might exist on Client
}

interface ClientListStatusProps {
    client: Client;
}

export const ClientListStatus: React.FC<ClientListStatusProps> = ({ client }) => {
    return (
        <>
            {/* status */}
            {!client.isConnectionRequest && (
                <div className="clientCard-status" style={{ color: getClientStatusColor(client) ?? 'blue' }}>
                    {rephraseClientStatus(client.status ?? '')}
                </div>
            )}

            {/* relevant date depending on status/isConnectionRequest */}
            {client.isConnectionRequest ? (
                <div>
                    <span className="clientCard-date">
                        {client.currentAdvisor?.invited_on ? `Received ${moment(client.currentAdvisor?.invited_on).format('MM-DD-YYYY')}` : 'No Received Date'}
                    </span>
                </div>
            ) : client.status === 'Active' ? (
                <div className="clientCard-date">{client?.last_login_at ? `Last Login ${moment(client.last_login_at).format('MM-DD-YYYY')}` : 'Never Logged In'}</div>
            ) : client.status === 'Invite Pending' ? (
                <div className="clientCard-date">{client.updated_at ? `Sent ${moment(client.updated_at).format('MM-DD-YYYY')}` : 'Never Sent'}</div>
            ) : client.status === 'Deactivated' ? (
                <div className="clientCard-date">
                    {client.currentAdvisor?.revoked_on ? `Removed ${moment(client.currentAdvisor?.revoked_on).format('MM-DD-YYYY')}` : 'No Removed Date'}
                </div>
            ) : null}
        </>
    );
};
