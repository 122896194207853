import React from 'react';
import formatCurrency from './format/formatCurrency';
import { calculateNetAmount, calculateNetAmountFromTransaction } from './calculate/calculateNetAmount';
import { JOINT_TRANSACTION } from '../constants/constantStrings';
import { CURRENCIES } from '../constants/currencyConstants';
import additionalEnums from '../additionalEnums';
import _ from 'lodash';

export const DEFAULT_CURRENCY_LABEL = 'Net effect';

export const getNetTransactionAmountStr = (transaction, currency) => {
    if (transaction.type !== JOINT_TRANSACTION) return null;

    const types_list = additionalEnums.TransactionTypes.typeArr;

    // add shortened transaction type to amount string
    const getAmountStr = (t) => {
        let s = `${formatCurrency(t.amount, currency)} `;
        const type = types_list.find((tt) => tt.transaction_type === t.type);
        s += type?.transaction_type_name || t.type;
        return s;
    };

    const amountStrArr = transaction.joint_transactions?.sort((a, b) => Math.abs(b.amount) - Math.abs(a.amount)).map((jt) => (jt.amount ? getAmountStr(jt) : '')) || [];

    // make unique and join by " | "
    let amountStr = amountStrArr
        .filter((x, xIdx, xArr) => xArr.indexOf(x) === xIdx)
        .filter((t) => t !== '')
        .join(' | ');

    return amountStr;
};

// component that has total and amount string if it's a joint transaction (concatenated transaction types)
export const NetTransactionAmount = ({ transaction, amountOnly = false, currencyLabel = DEFAULT_CURRENCY_LABEL, currency = CURRENCIES.USD }) => {
    const types_list = additionalEnums.TransactionTypes.typeArr;
    if (transaction.type !== JOINT_TRANSACTION) {
        const type = types_list?.find((tt) => tt.transaction_type === transaction.type);
        const displayEffect = type?.display_effect || 1;
        return formatCurrency(transaction.amount * displayEffect, currency);
    }
    let total = calculateNetAmountFromTransaction(transaction);
    total = getNetDisplayEffect(transaction.joint_transactions ?? []);

    let amountStr = getNetTransactionAmountStr(transaction, currency);
    return (
        <>
            <div style={{ display: 'flex', alignItems: 'center' }}>
                {formatCurrency(total, currency)} <span style={{ fontSize: '14px', marginLeft: '10px' }}>({currencyLabel})</span>
            </div>
            <div style={{ fontSize: '11px', marginTop: '8px' }}>{amountStr}</div>
        </>
    );
};

const uniquifyById = (arr) => {
    return arr?.filter((t, idx, arr) => {
        return arr.findIndex((t2) => t2._id === t._id) === idx;
    });
};

/**
 * Returns a map of transaction types to amounts
 * @param transList: list of transactions, each with a type and amount
 * @returns
 */
export const getTranTypeAmtMap = (transList) => {
    const list = uniquifyById(transList);
    const res = {};
    if (!list) return res;
    try {
        list.forEach((t) => {
            if (!res[t.type]) res[t.type] = 0;
            res[t.type] += t.amount;
        });
    } catch (e) {
        console.log('error getting transaction data', e);
    }
    return res;
};

// returns Net Capital Call or Net Distribution
// or '' if it's not a joint transaction
export const getNetType = (transaction) => {
    let netType = '';
    if (transaction.type !== JOINT_TRANSACTION) return netType;
    const amount = getNetDisplayEffect(transaction.joint_transactions ?? []);
    if (amount > 0) netType = 'Net Distribution';
    else if (amount < 0) netType = 'Net Capital Call';
    else netType = 'Net Zero';
    return netType;
};

/**
 * Returns the net effect of a list of transactions
 * @param transactions: list of transactions, each with a type and amount - {type, amount}
 * @returns
 * 0 if no transactions
 */
export const getNetDisplayEffect = (transactions) => {
    const types_list = additionalEnums.TransactionTypes.typeArr;
    let netAmount = 0;
    transactions.forEach((t) => {
        const type = (types_list ?? []).find((tt) => tt.transaction_type === t.type);
        if (!type?.display_effect) return;
        netAmount += t.amount * type.display_effect;
    });
    return netAmount;
};

// returns a list of headers and a function that returns a list of values for a CSV row
// needed for the Capital Calls, Distributions, and Transactions tables
// params
// data: list of transactions
//
// returns
// {
//     headers: list of headers for the csv
//     getRowValuesFn: function that takes a single line item returns a list of values for a row
// }
//
// assumes the list doesn't include transactions of type "joint_transaction"
// it uses the populated joint_transaction field to get the transaction types of all siblings

export const getJointTransactionsFromTransactionList = (transactionList) => {
    const jointTransactions = transactionList
        .map((d) => {
            if (d.joint_transaction) {
                return d.joint_transaction?.joint_transactions ?? [];
            } else if (d.joint_transactions) {
                return d.joint_transactions ?? [];
            } else {
                return [];
            }
        })
        .flat();
    return jointTransactions;
};

export const getTransactionTypesFromTransactionList = (transactionList) => {
    const transactionTypes = transactionList
        .map((d) => d.type)
        .filter((value, index, self) => self.indexOf(value) === index)
        .sort();
    return transactionTypes;
};

export const getTransactionCsvAmountHeadersAndGetRowValuesFunction = (data, trimUnfilledTypes = true) => {
    const allJointTransactions = getJointTransactionsFromTransactionList(data);

    const jointTransactionTypes = getTransactionTypesFromTransactionList(allJointTransactions);

    const typesArr = additionalEnums.TransactionTypes.typeArr;
    const headers = [
        'Amount',
        ...jointTransactionTypes.map((jointType) => {
            return `${typesArr.find((t) => t.transaction_type === jointType).transaction_type_name} Amount`;
        }),
    ];

    // returns list of values for a row
    // the same order as the headers
    const getRowValuesFn = (item) => {
        let rowValues = [];

        // get joint transactions from either joint_transactions or joint_transaction.joint_transactions
        // depending on whether the parent or children are populated
        let jointTransactions = item.joint_transactions?.length > 0 ? item.joint_transactions : item.joint_transaction?.joint_transactions;

        // if item is part of a joint transaction, return the cashEffect of the transaction
        // otherwise, return the amount of the transaction
        if (jointTransactions) {
            const netCashEffect = getNetDisplayEffect(jointTransactions);
            rowValues.push(netCashEffect); // amount
            // add child amounts in the same order as the headers
            const jointRowValues = jointTransactionTypes.map((t) => {
                const tranOfType = jointTransactions.find((tran) => tran.type === t);
                return tranOfType?.amount ?? '';
            });
            rowValues.push(...jointRowValues);
        } else {
            // if not part of a joint transaction, return the amount of the transaction
            // and fill in the joint values with empty strings
            rowValues.push(item.amount); // amount
            rowValues.push(...jointTransactionTypes.map((t) => ''));
        }
        return rowValues;
    };

    const info = { headers, getRowValuesFn };

    return info;
};
