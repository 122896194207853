import { AuditLog } from './AuditLog';
import { Currency } from './Currency'; // Assuming Currency might be used based on the context provided

export enum UserAccess {
    Admin = 'admin',
    Advisor = 'advisor',
    Investor = 'investor',
    AssetManager = 'asset_manager',
}

export interface UserEmailVerification {
    token: string;
    verified: boolean;
    verified_at?: Date;
    send_times: Date[];
}

export interface UserPasswordHistory {
    password: string;
    stopped_using_at?: Date;
}

export interface UserTfaTotp {
    enabled: boolean;
    secret?: string;
}

export interface UserTfaSms {
    enabled: boolean;
    phone_number?: string;
    code?: string;
    code_expires_at?: Date;
}

export interface UserTfaRecoveryCode {
    code: string;
    used: boolean;
    used_at?: Date;
}

export interface UserTfa {
    totp: UserTfaTotp;
    sms: UserTfaSms;
    recovery_codes: UserTfaRecoveryCode[];
}

export interface UserAdvisorGroupMembership {
    group: string; // Assuming Types.ObjectId is represented as string in TypeScript
    role: string;
    clients: string[]; // Assuming Types.ObjectId is represented as string in TypeScript
    invited_on?: Date;
    accepted_on?: Date;
    revoked_on?: Date;
}

export interface UserSession {
    login_at: Date;
    token: string;
    expiry: Date;
    details: any; // Schema.Types.Mixed is represented as any in TypeScript
}

export interface UserNotification {
    timestamp: Date;
    title: string;
    description: string;
    notificationType: string;
    transaction?: string; // Assuming Types.ObjectId is represented as string in TypeScript
    sender?: string; // Assuming Types.ObjectId is represented as string in TypeScript
}

export interface UserAdvisor {
    group: string; // Assuming Types.ObjectId is represented as string in TypeScript
    invited_on: Date;
    accepted_on: Date;
    revoked_on: Date;
    permissions: string[];
}

export interface UserPerformance {
    irr_by_types: any; // Schema.Types.Mixed is represented as any in TypeScript
    irr_by_user?: number;
    irr_by_types_active: any; // Schema.Types.Mixed is represented as any in TypeScript
    irr_by_user_active?: number;
    last_irr_calculation?: Date;
}

export interface User {
    _id: string;
    sample_data: boolean;
    email: string;
    name?: string;
    connection?: string; // Assuming Types.ObjectId is represented as string in TypeScript
    email_verification: UserEmailVerification;
    password?: string;
    historical_passwords: UserPasswordHistory[];
    password_reset_token?: string;
    tfa: UserTfa;
    googleId?: string;
    status: string;
    access: string;
    failed_login_attempts: number;
    advisor_group_memberships: UserAdvisorGroupMembership[];
    sessions: UserSession[];
    last_login_at?: Date;
    setup_email_send_times: Date[];
    notifications: UserNotification[];
    notificationSettings: Record<string, boolean>;
    advisors: UserAdvisor[];
    performance: UserPerformance;
    external_ids: any; // Schema.Types.Mixed is represented as any in TypeScript
    // Assuming ParentGrantSchema is similar to other defined interfaces, but it's not detailed in the provided context
    parent_grants: any[]; // Placeholder, replace with actual ParentGrantSchema interface when available
    referral?: any; // Schema.Types.Mixed is represented as any in TypeScript
    created_at: Date;
    updated_at: Date;
    deleted_at?: Date;
}
