import React, { useState, useRef, useEffect } from 'react';
import '../../styles/HoldButton.css'; // assuming you'll have a stylesheet for the component

interface HoldButtonProps {
    text: string;
    className?: string;
    holdDuration?: number;
    action: () => void;
}

const HoldButton: React.FC<HoldButtonProps> = ({ text, className, holdDuration = 2000, action }) => {
    const [isPressing, setIsPressing] = useState(false);
    const [pressProgress, setPressProgress] = useState(0);
    const startTimeRef = useRef<number | null>(null);
    const intervalRef = useRef<NodeJS.Timeout | null>(null);

    const shouldHold = () => holdDuration > 0;

    const handleMouseDown = () => {
        setIsPressing(true);
        startTimeRef.current = Date.now();

        if (shouldHold()) {
            intervalRef.current = setInterval(() => {
                const elapsed = Date.now() - (startTimeRef.current ?? 0);
                const progress = Math.min((elapsed / holdDuration) * 100, 100);
                setPressProgress(progress);

                if (progress === 100) {
                    handleActionTriggered();
                }
            }, 50); // update 20 times a second
        } else {
            handleActionTriggered();
        }
    };

    const handleMouseUp = () => {
        if (intervalRef.current) {
            clearInterval(intervalRef.current);
            intervalRef.current = null;
        }
        setIsPressing(false);
        setPressProgress(0);
    };

    const handleActionTriggered = () => {
        if (intervalRef.current) {
            clearInterval(intervalRef.current);
            intervalRef.current = null;
        }
        setIsPressing(false);
        setPressProgress(0);
        action();
    };

    useEffect(() => {
        return () => {
            if (intervalRef.current) {
                clearInterval(intervalRef.current);
            }
        };
    }, []);

    const gradientStyle = {
        background: `conic-gradient(rgba(255, 255, 255, 0.5) 0% ${pressProgress}%, transparent ${pressProgress}% 100%)`,
    };

    return (
        <button className={`hold-button ${className}`} onMouseDown={handleMouseDown} onMouseUp={handleMouseUp} onMouseLeave={handleMouseUp}>
            {isPressing && shouldHold() && <div className="tooltip">(hold)</div>}
            {text}
            <div className="hold-progress" style={gradientStyle}></div>
        </button>
    );
};

export default HoldButton;
