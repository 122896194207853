import React from 'react';
import { ADVISOR_CLIENT_LIST_VIEW_MODES } from '../../constants/advisorVueConstants';

interface ClientListTableToggleProps {
    viewMode: string;
    setViewMode: (mode: string) => void;
}

// Table type toggle at top right of ClientList page
export const ClientListTableToggle: React.FC<ClientListTableToggleProps> = ({ viewMode, setViewMode }) => (
    <div className="clientList-filterRow-tableTypeToggle">
        {/* 4 green boxes */}
        <div
            className={'clientList-filterRow-tableTypeToggleCardView' + (viewMode === ADVISOR_CLIENT_LIST_VIEW_MODES.card ? ' clientList-filterRow-tableTypeToggleCardActive' : '')}
            title="Card View"
            onClick={() => setViewMode(ADVISOR_CLIENT_LIST_VIEW_MODES.card)}
        >
            <div>
                <div>
                    <div></div>
                    <div></div>
                </div>
            </div>
            <div>
                <div>
                    <div></div>
                    <div></div>
                </div>
            </div>
        </div>
        {/* 3 gray bars */}
        <div
            className={
                'clientList-filterRow-tableTypeToggleGridView' + (viewMode === ADVISOR_CLIENT_LIST_VIEW_MODES.table ? ' clientList-filterRow-tableTypeToggleGridActive' : '')
            }
            title="Grid View"
            onClick={() => setViewMode(ADVISOR_CLIENT_LIST_VIEW_MODES.table)}
        >
            <div></div>
            <div></div>
            <div></div>
        </div>
    </div>
);
