import React, { Component } from 'react';
import Multitoggle from '../../Toggles/Multitoggle';
import DetailedAlert from '../../Alerts/DetailedAlert';
import api from '../../../api';
import Dropdown from '../../Dropdowns/Dropdown';
import { setDismissableAlert } from '../../../utilities/alert/setDismissableAlert';
import AddEditConnectionDrawer from '../../Drawers/AddEditConnectionDrawer';
import AddInvestmentDrawer from '../../Drawers/AddInvestmentDrawer';

import AddEditAccountDrawer from '../../Drawers/AddEditAccountDrawer';
import { UserPermissions } from '../../../utilities/AdvisorVue/permissions';
import { ObjectFilterSelect } from '../../Dropdowns/ObjectFilterSelect';
import rephraseClientStatus from '../../../utilities/AdvisorVue/rephraseClientStatus';

import '../../../styles/supertool.css';

export default class SupertoolBar extends Component {
    state = {
        connection: {
            name: '',
            integration: null,
        },
        openAddInvestmentsDropdown: false,
        tooltips: {},
        openAdvisorList: false,

        openCreateSection: false,
    };
    componentDidMount = () => {
        this.resetState();
    };

    componentDidUpdate = (prevProps) => {
        if (prevProps.relevantUser?._id !== this.props.relevantUser?._id) {
            this.resetState();
        } else if (prevProps.relevantUser?.status !== this.props.relevantUser?.status) {
            this.resetState();
        }
    };

    componentWillUnmount = () => {
        this.props.closeDrawer();
    };

    resetState = () => {
        this.props.setAlert(null, null);
        this.props.closeDrawer();
        this.setState({
            mustConfirmActivation: this.props.relevantUser?.status === 'Staging',
            toggleState: this.props.relevantUser?.status === 'Staging' ? 'Staging' : 'Active',
        });
    };

    loginAs = async (user) => {
        if (!user) {
            return console.log('no user provided to login');
        }
        const res = await api.post(`/users/${user._id}/session`, {});
        if (res && res.token) {
            api.override(res.token);
            window.location.href = '/';
        }
    };

    confirmActivation = async () => {
        this.props.loading();
        this.props.setAlert(null, null);
        await this.setStatus('Active');

        if (this.props.match?.params?.id) {
            (await this.props?.loadUserData) ? this.props.loadUserData() : this.props.loadUser(this.props.match?.params?.id?.split('?')[0]);
        }
        if (this.props.onUpdate) {
            await this.props.onUpdate();
        }

        this.props.loaded();
    };

    setStatus = async (status) => {
        this.props.loading(320, 'setStatus');
        let res = await api.patch(
            '/users/' + this.props.relevantUser?._id,
            { status: status },
            {
                400: (res) => {
                    setDismissableAlert(this.props.setAlert, res.message, true, 10000);
                },
            }
        );
        this.props.loaded('setStatus');
        if (res) {
            this.setState({ toggleState: status });
        }
    };

    toggleState = async (selection) => {
        const s = this.state;

        if (!UserPermissions().canSetUserStagingActive) return;

        if (selection === this.state.toggleState) {
            return;
        } else if (selection === 'Active') {
            let _ = this.props.setAlert?.(
                <DetailedAlert
                    title="Are you sure you want to activate this Client?"
                    description="Client will be notified once activation is confirmed."
                    buttonText="Confirm Activation"
                    buttonId="confirm-activation-button"
                    onClick={() => this.confirmActivation()}
                />,
                'confirm activation'
            );
            return;
        } else {
            await this.props.loading();
            await this.setStatus('Staging');
            if (this.props.match?.params?.id) {
                (await this.props?.loadUserData) ? this.props.loadUserData() : this.props.loadUser('/users/' + this.props.match?.params?.id?.split('?')[0]);
            }
            if (this.props.onUpdate) {
                await this.props.onUpdate();
            }

            this.props.setAlert(null, null);
            this.props.loaded();
        }
        this.setState({ toggleState: selection });
    };

    showCreateAccountModal = () => {
        const p = this.props;
        this.props.openDrawer(() => (
            <AddEditAccountDrawer
                userId={this.props.relevantUser?._id}
                accountId={null}
                close={p.closeDrawer}
                setAlert={p.setAlert}
                loaded={p.loaded}
                loading={p.loading}
                reloadData={p.loadUserData}
                vue={this.props.vue}
            />
        ));
    };

    addAdvisorToClient = async (advisor, user) => {
        this.props.loading();
        this.props.setAlert();

        const group = advisor.advisor_group_memberships[0];

        if (!group) {
            setDismissableAlert(this.props.setAlert, 'Advisory group not found', true);
            this.props.loaded();
            return;
        }

        // add group to user
        const advGroupRes = await api.patch(`/users/${user._id}/group`, {
            group: group.group,
            action: 'add',
        });

        if (!advGroupRes) {
            setDismissableAlert(this.props.setAlert, 'Error adding group to user.', true);
            this.props.loaded();
            return;
        }

        // /:groupId/advisors/:advisorId
        const newClients = [...group.clients];
        newClients.push(user._id);
        const updateAdvisorRes = await api.patch(`/advisorygroups/${group.group}/advisors/${advisor._id}`, {
            clients: newClients,
        });

        if (!updateAdvisorRes?.success) {
            setDismissableAlert(this.props.setAlert, 'Error adding user to advisor.', true);
            this.props.loaded();
            return;
        }
        this.props.reloadData();
        this.setState({ selectedAdvisor: null });
        setDismissableAlert(this.props.setAlert, `${advisor.name} successfully added as ${user.name}'s advisor.`);
        this.props.loaded();
    };

    renderCreateSection = () => {
        const p = this.props;
        const s = this.state;

        return (
            <div
                onClick={() => this.setState({ openCreateSection: !this.state.openCreateSection })}
                style={{
                    display: 'flex',
                    // flexDirection: 'column',
                    alignItems: 'center',
                    position: 'relative',
                    // top: '0',
                    // right: '0',
                    // padding: '10px',
                    cursor: 'pointer',
                }}
            >
                <div className={'plus-icon'} />
                <span
                    style={{
                        fontSize: '16px',
                    }}
                >
                    New
                </span>

                {s.openCreateSection && (
                    <div
                        className={'supertoolCreateSection'}
                        style={{
                            // top: '100px',
                            position: 'absolute',
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'flex-start',
                            top: '57px',
                            left: '-14px',
                            backgroundColor: 'white',
                            border: '1px solid #ddd',
                            padding: '10px',
                            paddingRight: '15px',
                            boxShadow: 'var(--box-shadow)',
                            borderRadius: '5px',
                        }}
                    >
                        {UserPermissions().canCreateAccounts && (
                            <div id={'supertoolAddEntity'} className={'supertoolCreate'} onClick={this.showCreateAccountModal}>
                                <div className={'plus-icon'} />
                                <span>Entity</span>
                            </div>
                        )}

                        {UserPermissions().canCreateConnections && (
                            <div
                                id={'supertoolAddInvestment'}
                                className={'supertoolCreate'}
                                // style={{ marginLeft: '15px' }}
                                onClick={() =>
                                    p.openDrawer(() => (
                                        <AddEditConnectionDrawer
                                            mode={'create'}
                                            close={p.closeDrawer}
                                            relevantUser={p.relevantUser}
                                            accounts={p.accounts}
                                            reloadData={this.props.reloadData}
                                            loading={p.loading}
                                            loaded={p.loaded}
                                            setAlert={p.setAlert}
                                            adminOrAdvisorVue={true}
                                            vue={this.props.vue}
                                        />
                                    ))
                                }
                            >
                                <div className={'plus-icon'} />
                                <span>Connection</span>
                            </div>
                        )}

                        {UserPermissions().canCreatePortalInvestments && (
                            <div
                                id={'supertoolAddInvestment'}
                                className={'supertoolCreate'}
                                // style={{ marginLeft: '15px' }}
                                onClick={() =>
                                    p.openDrawer(() => (
                                        <AddInvestmentDrawer
                                            mode={'create'}
                                            close={p.closeDrawer}
                                            user={p.relevantUser}
                                            accounts={p.accounts}
                                            reloadData={this.props.reloadData}
                                            loading={p.loading}
                                            loaded={p.loaded}
                                            setAlert={p.setAlert}
                                            connections={p.connections}
                                            vue={this.props.vue}
                                        />
                                    ))
                                }
                            >
                                <div className={'plus-icon'} />
                                <span>Investment</span>
                            </div>
                        )}

                        {UserPermissions().canCreateManualInvestments && (
                            <div
                                id={'supertoolAddInvestment'}
                                className={'supertoolCreate'}
                                // style={{ marginLeft: '15px' }}
                                onClick={() => {
                                    this.props.closeDrawer();
                                    this.props.history.push(
                                        `/${this.props.user.access === 'advisor' ? 'advisor/clients' : 'admin/users'}/${this.props.match.params.id?.split('?')[0]}/addManual`
                                    );
                                }}
                            >
                                <span className={'plus-icon'} />
                                <span style={{ display: 'flex', flexDirection: 'column', maxHeight: '60px' }}>
                                    <span>Manual Investment</span>
                                    {/* <span>Investment</span> */}
                                </span>
                            </div>
                        )}
                    </div>
                )}
            </div>
        );
    };

    render = () => {
        const s = this.state;
        const p = this.props;
        return (
            <>
                <div className="supertoolContainer">
                    <div className="supertoolItems">
                        {p.user.access === 'admin' && (
                            <>
                                <div style={{}} className="a supertoolCreate" onClick={(e) => this.loginAs(p.relevantUser)}>
                                    Login
                                </div>
                                <div className="separator"></div>
                            </>
                        )}
                        <div className={'supertoolUserStatusBar'}>
                            <Multitoggle
                                keyStr={'userStatusToggle'}
                                options={['Staging', 'Active'].map((s) => rephraseClientStatus(s))}
                                selection={rephraseClientStatus(this.state.toggleState)}
                                zIndex={2000}
                                onSelect={(s) => this.toggleState(rephraseClientStatus(s, true))}
                                optionStyle={{ cursor: UserPermissions().canSetUserStagingActive ? null : 'default' }}
                            />
                        </div>

                        {UserPermissions()?.featureLocks?.setUserStagingActive && (
                            <>
                                <span
                                    className="show-tooltip"
                                    onMouseEnter={() => this.setState({ tooltips: { stagingActiveToggle: true } }, () => console.log('tooltip set'))}
                                    onMouseLeave={() => this.setState({ tooltips: { stagingActiveToggle: false } }, () => console.log('tooltip unset'))}
                                >
                                    <img style={{ width: '15px' }} src="/images/icons/lock_black.png" />
                                </span>
                                {s.tooltips?.stagingActiveToggle && (
                                    <div className="popper" style={{ marginLeft: '-50px', marginTop: '155px' }}>
                                        The ability to set a user to Active is a locked premium feature. Please upgrade your account to unlock this feature.
                                    </div>
                                )}
                            </>
                        )}

                        <div className="separator"></div>

                        {this.renderCreateSection()}
                    </div>
                </div>
            </>
        );
    };
}
