import React, { useEffect } from 'react';
import _ from 'lodash';

import TOTPDisplay from '../TOTPDisplay';
import { VisibilityIcon } from '../Icons/VisibilityIcon';
import renderInputError from '../renderInputError';

import '../../styles/keyValueList.css';

const KEY_WIDTH_PERCENT = 40;

interface KeyValue {
    key: string;
    value: string;
}

interface Credential {
    key: string;
    display_name?: string;
}

interface KeyValueDrawerListProps {
    label?: string;
    keyValueList: KeyValue[];
    setKeyValueList: (list: KeyValue[]) => void;
    requiredCredentials?: Credential[];
    showCredentials?: boolean;
    setShowCredentials?: (show: boolean) => void;
    hideVisibilityIcon?: boolean;
    readOnly?: boolean;
    errors?: any;
}

const KeyValueDrawerList: React.FC<KeyValueDrawerListProps> = (props) => {
    useEffect(() => {
        load();
    }, [props.requiredCredentials, props.keyValueList]);

    const load = () => {
        const p = props;

        // add required credentials to list if they don't exist
        let nextKVList = [...(p.keyValueList ?? [])];
        p.requiredCredentials?.forEach((cred) => {
            const exists = !!nextKVList.find((kv) => kv.key && [cred.key, cred.display_name].includes(kv.key));
            if (!exists) {
                nextKVList.push({ key: cred.display_name || cred.key, value: '' });
            }
        });

        // Only update the state if the new list is different from the current list
        if (!_.isEqual(nextKVList, p.keyValueList)) {
            p.setKeyValueList(nextKVList);
        }
    };

    const setKeyValueState = (newText: string, kvIdx: number, keyOrValue: 'key' | 'value') => {
        let keyValueList = JSON.parse(JSON.stringify(props.keyValueList));
        keyValueList[kvIdx][keyOrValue] = newText;
        props.setKeyValueList(keyValueList);
    };

    const isTOTPKey = (str: string) => {
        if (!str) return false;
        // Regular expressions to match TOTP key formats
        const urlFormatRegex = /^otpauth:\/\/totp\/.*$/i; // URL format starts with "otpauth://totp/"
        const keyFormatRegex = /^[a-z2-7]{16,128}=*$/i; // Base32 key format with length check (16 to 32 characters)

        // Check if the string matches either format
        return urlFormatRegex.test(str) || keyFormatRegex.test(str);
    };

    const addNewKeyValuePair = () => {
        let keyValueList = [...props.keyValueList];
        keyValueList.push({ key: '', value: '' });
        props.setKeyValueList(keyValueList);
        props.setShowCredentials?.(true);
    };

    const removeKeyValuePair = (kvIdx: number) => {
        let keyValueList = [...props.keyValueList];
        keyValueList.splice(kvIdx, 1);
        props.setKeyValueList(keyValueList);
    };

    return (
        <div style={{ marginBottom: 0 }}>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <label>{props.label ?? 'Required Credentials'}</label>
                {!props.readOnly && (
                    <div style={{ display: 'flex', alignItems: 'center', cursor: 'pointer', right: 0 }} onClick={addNewKeyValuePair}>
                        <div className="plus-icon" />
                        Add
                    </div>
                )}
            </div>
            <div>
                {props.keyValueList?.map((kv, kvIdx) => {
                    const isKeyRequired = !!props.requiredCredentials?.find((cred) => kv.key && [cred.key, cred.display_name].includes(kv.key)) || false;
                    return (
                        <React.Fragment key={'kvList_' + kvIdx}>
                            <div
                                style={{
                                    position: 'relative',
                                    display: 'flex',
                                    alignItems: 'center',
                                    marginTop: kvIdx !== 0 ? '10px' : '0px',
                                    width: '100%',
                                }}
                                key={`kvList_${kvIdx}`}
                            >
                                <div style={{ position: 'relative', width: `${KEY_WIDTH_PERCENT}%`, marginRight: '5px' }}>
                                    <input
                                        autoComplete="new-email"
                                        type="text"
                                        placeholder="Key"
                                        value={kv.key}
                                        onChange={!isKeyRequired ? (e) => setKeyValueState(e.target.value, kvIdx, 'key') : () => {}}
                                    />
                                </div>
                                <div style={{ position: 'relative', width: `${100 - KEY_WIDTH_PERCENT}%`, marginRight: '0px', display: 'flex', alignItems: 'center' }}>
                                    <input
                                        autoComplete="new-password"
                                        placeholder="Value"
                                        type={props.showCredentials ? 'text' : 'password'}
                                        value={kv.value}
                                        onChange={!props.showCredentials ? () => {} : (e) => setKeyValueState(e.target.value, kvIdx, 'value')}
                                    />
                                    {!props.hideVisibilityIcon && props.setShowCredentials && (
                                        <VisibilityIcon show={props.showCredentials} toggleShow={() => props.setShowCredentials?.(!props.showCredentials)} />
                                    )}
                                </div>
                                {isTOTPKey(kv.value) && <TOTPDisplay totp_key={kv.value} />}
                                {!isKeyRequired && <div className="delete-icon" data-testid={`delete-icon-${kvIdx}`} onClick={() => removeKeyValuePair(kvIdx)} />}
                            </div>
                        </React.Fragment>
                    );
                })}
                {renderInputError(props.errors, `kvList`, false)}
            </div>
        </div>
    );
};

export default KeyValueDrawerList;
