import React, { useState, useMemo } from 'react';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import '../../styles/widget-Dropdown.css';
import { PLEASE_SELECT___ } from '../../constants/constantStrings';

interface DropdownOption {
    title: string;
    connection?: any | null;
}

interface DropdownObjectsProps extends RouteComponentProps {
    options: DropdownOption[];
    defaultSelection?: DropdownOption | null;
    idPrefix?: string;
    excludeNull?: boolean;
    sticky?: boolean;
    dimDefault?: boolean;
    cssClass?: string;
    style?: {
        arrowSize?: string;
        fontSize?: string;
        height?: string;
        width?: string;
    };
    selection?: DropdownOption | null;
    select?: (newSelection: DropdownOption | null, prevSelection: DropdownOption | null) => void;
    extraOption?: () => React.ReactNode;
}

const DropdownObjects: React.FC<DropdownObjectsProps> = (props) => {
    const [expanded, setExpanded] = useState<boolean>(false);
    const [selection, setSelection] = useState<DropdownOption | null>(props.selection ?? props.defaultSelection ?? null);

    const headerClick = () => {
        setExpanded(!expanded);
    };

    const mouseLeave = () => {
        if (!props.sticky) {
            setExpanded(false);
        }
    };

    const handleSelect = (newSelection: DropdownOption | null) => {
        setExpanded(false);
        setSelection(newSelection);
        if (newSelection !== undefined && props.select) {
            // If the selection is controlled, call the parent-provided `select` function
            props.select(newSelection, selection);
        }
    };

    // Memoized styles to improve performance
    const getStyle = useMemo<React.CSSProperties>(() => {
        const style: React.CSSProperties = {
            '--arrow-size': props.style?.arrowSize ?? '9px',
            '--font-size': props.style?.fontSize ?? '16px',
            '--select-height': props.style?.height ?? '36px',
            width: props.style?.width,
        } as React.CSSProperties;

        return style;
    }, [props.style]);

    const idPrefix = props.idPrefix ?? '';
    const nullOptClass = 'dropdown-option' + (props.dimDefault ? ' dropdown-null' : '');

    return (
        <div className={'dropdown-container ' + (props.cssClass ?? '')} onMouseLeave={mouseLeave} style={getStyle} key={idPrefix + '_dd'}>
            <div className="dropdown-active" onClick={headerClick}>
                {selection?.title ?? PLEASE_SELECT___} {/* Make sure to render a string */}
            </div>
            {expanded && (
                <div className="dropdown-list">
                    {!props.excludeNull && (
                        <div key={idPrefix + 'emptyopt'} className={nullOptClass} onClick={() => handleSelect(null)}>
                            {PLEASE_SELECT___}
                        </div>
                    )}
                    {props.options.map((o, idx) => (
                        <div key={idPrefix + 'opt' + idx} className="dropdown-option" onClick={() => handleSelect(o)}>
                            {o.title} {/* Make sure to render a string */}
                        </div>
                    ))}
                    {props.extraOption && props.extraOption()}
                </div>
            )}
        </div>
    );
};

export default withRouter(DropdownObjects);
