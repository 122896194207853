import React, { useState } from 'react';
import { faPlusCircle, faXmarkCircle } from '@fortawesome/free-solid-svg-icons';

import IconButton from '../Buttons/IconButton';
import { capitalizeFirstEveryWord } from '../../utilities/format/capitalizeFirst';

export type GenericTypeWithControls<T> = T & {
    _nonEditableFields?: (keyof T)[]; // Fields that cannot be edited
    _required?: boolean; // If true, this object cannot be deleted
};

interface ObjectListEditorProps<T> {
    readOnly?: boolean; // If true, the list will be read-only
    label?: string; // Label for the list
    objects: GenericTypeWithControls<T>[]; // List of objects to be edited
    setObjects: (objects: GenericTypeWithControls<T>[]) => void; // Function to set the objects
    _nonEditableFields?: (keyof T)[]; // Fields that cannot be edited
    newObjectTemplate?: T | null; // If provided, this object will be used as the template for new objects
}

const ObjectListEditor = <
    T extends {
        [key: string]: any;
    },
>({
    readOnly = false,
    label,
    objects,
    setObjects,
    _nonEditableFields,
    newObjectTemplate,
}: ObjectListEditorProps<T>) => {
    const createEmptyObject = <T extends {}>(): T => {
        const prototype = {} as T;
        const keys = Object.keys(prototype) as Array<keyof T>;
        const emptyObject: Partial<T> = {};
        keys.forEach((key) => {
            emptyObject[key] = '' as any;
        });
        return emptyObject as T;
    };
    const [newObject, setNewObject] = useState(createEmptyObject());

    const addNewObject = () => {
        if (!newObjectTemplate) {
            return;
        }
        const newObject = { ...newObjectTemplate };
        setObjects([...objects, newObject] as T[]);
    };

    const renderDeleteButton = (index: number) => {
        if (objects[index]._required || readOnly) {
            return null;
        }

        return (
            <IconButton
                faIcon={faXmarkCircle}
                color="var(--color-dark-red)"
                iconStyle={{ width: '16px', height: '16px', float: 'right' }}
                aria-label="Delete"
                onClick={() => {
                    const newObjects = [...objects];
                    newObjects.splice(index, 1);
                    setObjects(newObjects);
                }}
            />
        );
    };

    const renderObject = (object: T, index: number) => {
        const updateObjectField = (field: keyof T, value: any) => {
            // don't allow editing of non-editable fields

            if ((object as GenericTypeWithControls<T>)._nonEditableFields?.includes(field)) {
                return;
            }

            if (_nonEditableFields?.includes(field)) {
                return;
            }

            const updatedObject = { ...object, [field]: value };
            const newObjects = [...objects];
            newObjects[index] = updatedObject;
            setObjects(newObjects);
        };

        return (
            <div
                key={index}
                style={
                    {
                        backgroundColor: 'white',
                        padding: '10px',
                        gap: '10px',
                        display: 'flex',
                        flexDirection: 'column',
                        marginTop: index !== 0 ? '10px' : '0px',
                        borderRadius: '4px',
                    } as React.CSSProperties
                }
            >
                {renderDeleteButton(index)}
                {Object.keys(object).map((key) => {
                    if (key.startsWith('_')) return null;
                    const keyName = capitalizeFirstEveryWord(key.replace('_', ' '));
                    return (
                        <div key={key} style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', gap: '10px' }}>
                            <label
                                style={{
                                    // whiteSpace: 'nowrap',
                                    minWidth: '80px',
                                }}
                            >
                                {keyName}
                            </label>
                            <input type="text" placeholder={keyName} value={object[key]} onChange={(e) => updateObjectField(key, e.target.value)} />
                        </div>
                    );
                })}
            </div>
        );
    };

    return (
        <div
            style={{
                gap: '10px',
            }}
        >
            {/* label and add button */}
            {(label || !readOnly) && (
                <div
                    style={
                        {
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                        } as React.CSSProperties
                    }
                >
                    {label ? <label>{label}</label> : <>&nbsp;</>}
                    {!readOnly && newObjectTemplate && (
                        <IconButton
                            label="Add"
                            labelPlacement="right"
                            faIcon={faPlusCircle}
                            color="#009d99"
                            iconStyle={{ width: '16px', height: '16px' }}
                            aria-label="Add"
                            onClick={() => addNewObject()}
                        />
                    )}
                </div>
            )}

            {/* object list */}
            {objects.map(renderObject)}
        </div>
    );
};

export default ObjectListEditor;
