import moment from 'moment';
import React, { useState, useEffect, useRef } from 'react';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import _ from 'lodash';

// components
import Checkbox from '../Inputs/Checkbox';

// utilities
import dateToUTCMidnight from '../../utilities/date/dateToUTCMidnight';
import clickOutsideHandler from '../../utilities/clickOutsideHandler';

// constants
import { PLEASE_SELECT___ } from '../../constants/constantStrings';

import '../../styles/widget-Dropdown.css';

export interface DropdownProps extends RouteComponentProps<{ account: string }> {
    options: any[];
    selection?: any;
    defaultSelection?: any;
    idPrefix?: string;
    onClickOutside?: () => void;
    multiSelect?: boolean;
    select?: (newSelection: any, prevSelection: any, index: number) => void;
    style?: React.CSSProperties & {
        arrowSize?: string;
        fontSize?: string;
        height?: string;
        width?: string;
    };
    defaultOptionString?: string;
    cssClass?: string;
    dataTestId?: string;
    fontColor?: string;
    textAlign?: string;
    readOnly?: boolean;
    renderOption?: (option: any, index: number) => React.ReactNode;
    optionField?: string;
    dropdownStyle?: React.CSSProperties;
    disableDefaultOption?: boolean;
    disabledOptions?: any[];
    sticky?: boolean;
    overrideClosedText?: string;
    extraOption?: () => React.ReactNode;
}

const Dropdown: React.FC<DropdownProps> = (props) => {
    const [expanded, setExpanded] = useState(false);
    const [selectionIdx, setSelectionIdx] = useState(0);
    const [selection, setSelection] = useState<any>(null);
    const [outclickSet, setOutclickSet] = useState(false);
    const containerRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        if (props.onClickOutside) {
            setOutclick();
        }
        setSelection(props.defaultSelection ?? props.selection ?? getDefaultOptionString());
    }, []);

    useEffect(() => {
        if (props.onClickOutside) {
            setOutclick();
        }
        setSelection(props.defaultSelection ?? props.selection ?? props.options?.[0]);
    }, [props.defaultSelection, props.selection, props.options]);

    useEffect(() => {
        return () => {
            if (props.onClickOutside) {
                setOutclickSet(false);
                clickOutsideHandler.remove(containerRef.current, props.onClickOutside);
            }
        };
    }, [props.onClickOutside]);

    const setOutclick = () => {
        if (containerRef.current && !outclickSet) {
            clickOutsideHandler.add(containerRef.current, props.onClickOutside);
            setOutclickSet(true);
        }
    };

    const headerClick = () => {
        setExpanded(!expanded);
    };

    const mouseLeave = () => {
        if (!props.sticky) {
            setExpanded(false);
        }
    };

    const loadAccount = (account: any) => {
        let fromId = props.match.params.account;
        let toId = account?._id;
        setExpanded(false);
        if (!account?._id) {
            toId = 'all';
        } else {
            toId = account._id;
        }
        if (fromId === toId) return;
        const path = window.location.pathname.replace(fromId, toId);
        props.history.push(path);
    };

    const _select = (newSelection: any, index: number) => {
        let prevSelection = selection;
        if (props.multiSelect) {
            prevSelection = Array.isArray(prevSelection) ? prevSelection : [prevSelection];
            if (prevSelection.filter((s: any) => _.isEqual(s, newSelection)).length > 0) {
                prevSelection = prevSelection.filter((s: any) => !_.isEqual(s, newSelection));
            } else {
                prevSelection = [...prevSelection, newSelection];
            }
            newSelection = prevSelection;
        }
        setSelection(newSelection);
        setSelectionIdx(index);
        setExpanded(props.multiSelect ? expanded : false);
        if (props.select) props.select(newSelection, prevSelection, index);
    };

    interface CustomCSSProperties extends React.CSSProperties {
        '--arrow-size'?: string;
        '--font-size'?: string;
        '--select-height'?: string;
    }

    const getStyle = () => {
        let style: CustomCSSProperties = {
            userSelect: 'none',
            '--arrow-size': props.style?.arrowSize ?? '9px',
            '--font-size': props.style?.fontSize ?? '16px',
            '--select-height': props.style?.height ?? '36px',
        };

        if (props.style?.width) style.width = props.style.width;

        return style;
    };

    const getDefaultOptionString = () => {
        return props.defaultOptionString ?? PLEASE_SELECT___;
    };

    let selectionValue = props.selection ?? selection;

    if (props.multiSelect && !Array.isArray(selectionValue)) {
        selectionValue = [selectionValue];
    }

    if (props?.renderOption) {
        let optionIndex = props.options.findIndex((o) => o === selectionValue, null);
        if (optionIndex >= 0) {
            selectionValue = props.renderOption(props.options[optionIndex], optionIndex);
        }
    } else if (selectionValue && props.optionField) {
        selectionValue = selectionValue[props.optionField];
    }

    return (
        <div ref={containerRef} className={'dropdown-container ' + (props.cssClass ?? '')} onMouseLeave={mouseLeave} style={getStyle()}>
            <div
                data-testid={props.dataTestId}
                className="dropdown-active"
                onClick={(e) => {
                    e.stopPropagation();
                    if (props.readOnly) return;
                    setExpanded(true);
                }}
                style={{ color: props.fontColor ?? undefined, textAlign: props.textAlign as React.CSSProperties['textAlign'] }}
            >
                {props.overrideClosedText ?? (props.renderOption ? props.renderOption(selectionValue ?? props.options?.[selectionIdx] ?? '', selectionIdx) : selectionValue)}
            </div>
            {expanded ? (
                <div className="dropdown-list" style={{ ...props.dropdownStyle }}>
                    {!props.disableDefaultOption && (
                        <div
                            key="emptyopt"
                            className="dropdown-option"
                            onClick={(e) => {
                                e.stopPropagation();
                                _select(getDefaultOptionString(), -1);
                            }}
                        >
                            {getDefaultOptionString()}
                        </div>
                    )}
                    {props.options.map((opt, idx) => {
                        const optionValue = props.optionField ? opt[props.optionField] : opt;
                        const isOptionDisabled = props.disabledOptions?.includes(optionValue);
                        return (
                            <div
                                title={optionValue}
                                key={props.idPrefix + 'opt' + idx}
                                className="dropdown-option"
                                style={{ ...{ display: 'flex', alignItems: 'center' }, ...(isOptionDisabled ? { color: 'gray' } : {}) }}
                                onClick={(e) => {
                                    e.stopPropagation();
                                    if (isOptionDisabled) return;
                                    if (!props.multiSelect) _select(opt, idx);
                                }}
                            >
                                {props.multiSelect && (
                                    <div style={{ position: 'absolute', left: '5px', display: 'flex', alignItems: 'center' }}>
                                        <Checkbox
                                            style={{ width: '15px', height: '15px', marginRight: '5px' }}
                                            checked={props.selection?.includes(opt)}
                                            setChecked={isOptionDisabled ? undefined : (checked, event) => _select(opt, idx)}
                                        />
                                        {props?.renderOption ? props.renderOption(opt, idx) : optionValue}
                                    </div>
                                )}
                                {!props.multiSelect ? (props?.renderOption ? props.renderOption(opt, idx) : optionValue) : null}
                            </div>
                        );
                    })}
                    {props?.extraOption && props?.extraOption()}
                </div>
            ) : (
                ''
            )}
        </div>
    );
};

export default withRouter(Dropdown);
