import React, { useState, useRef } from 'react';
import Select from 'react-select';
import Dropdown from '../Dropdowns/Dropdown';
import { DrawerDateInput } from './DrawerDateInput';
import renderInputError from '../renderInputError';
import formatCurrency from '../../utilities/format/formatCurrency';
import Checkbox from '../Inputs/Checkbox';
import DropdownSection from './DropdownSection';
import ReactDatePicker from 'react-datepicker';
import dateToUTCMidnight from '../../utilities/date/dateToUTCMidnight';
import 'react-datepicker/dist/react-datepicker.css';
import '../../styles/drawerInputObject.css';
import '../../styles/react-datepicker.css';
import _ from 'lodash';
import UTCDatePicker from '../Inputs/UTCDatePicker/UTCDatePicker';
import { PLEASE_SELECT___ } from '../../constants/constantStrings';
import { CURRENCIES } from '../../constants/currencyConstants';
import { TimelessDate } from '../../types/TimelessDate';
import { Currency } from '../../types/Currency';

const PLACEHOLDER_COLOR = '#888';

interface DrawerInputObjectProps {
    readOnly?: boolean;
    value?: any;
    object: {
        type?: string;
        label?: string;
        placeholder?: string;
        optionField?: string;
        options?: any[];
        optionClearable?: boolean;
        hideLabel?: boolean;
        required?: boolean;
        disabled?: boolean;
        currency?: string;
        allowShow?: boolean;
        component?: React.ReactNode;
        fieldName?: string;
        callback?: (val: any) => void;
    };
    onChangeCallback?: (val: any) => void;
    notateRequired?: boolean;
    errors?: any;
    inlineLabel?: boolean;
    compactMode?: boolean;
}

export const DrawerInputObject: React.FC<DrawerInputObjectProps> = ({ readOnly, value, object, onChangeCallback, notateRequired, errors }) => {
    const [isCurrencyFocused, setCurrencyFocused] = useState(false);
    const [isPercentFocused, setPercentFocused] = useState(false);
    const [showPassword, setShowPassword] = useState(false);

    const currencyInput = useRef<HTMLInputElement>(null);
    const percentInput = useRef<HTMLInputElement>(null);

    const toggleShowPassword = () => setShowPassword(!showPassword);

    const onChange = (val: any) => {
        if (object.type === 'number' && isNaN(val) && val !== '-' && val !== '+') {
            return;
        } else if (object.type === 'percent' && isNaN(val) && val !== '-' && val !== '+') {
            return;
        } else if (object.type === 'currency' && isNaN(val) && val !== '-' && val !== '+' && val !== '.') {
            return;
        }
        (async () => {
            await onChangeCallback?.(val);
            await object.callback?.(val);
        })();
    };

    const formatValue = (val: any, type: string, disabled?: boolean) => {
        if (type === 'currency' && (!isCurrencyFocused || disabled)) {
            val = formatCurrency(Number(val), (object?.currency as Currency) ?? CURRENCIES.USD);
        } else if (type === 'percent' && (!isPercentFocused || disabled)) {
            val = val + '%';
        }
        return val;
    };

    let optionComponent;
    if (['option', 'option-section'].includes(object.type ?? '')) {
        const ddProps = {
            selection: [undefined, null].includes(value) ? (object.optionField ? { [object.optionField]: object.placeholder } : object.placeholder) : value,
            fontColor: [undefined, null].includes(value) ? PLACEHOLDER_COLOR : undefined, // Set to undefined for Dropdown
            options: object.options ?? [],
            optionField: object.optionField,
            select: (value: any) => {
                if (value === PLEASE_SELECT___) {
                    if (object.optionClearable) onChange(null);
                    return;
                }
                onChange(value);
            },
            style: { height: '38px' },
            readOnly: readOnly,
            title: '',
            disableDefaultOption: false,
        };
        if (object.type === 'option') {
            optionComponent = (
                <div style={{ textAlign: 'left' }}>
                    <Dropdown {...ddProps} />
                </div>
            );
        } else {
            const ddSectionProps = {
                ...ddProps,
                fontColor: [undefined, null].includes(value) ? PLACEHOLDER_COLOR : null, // Set to null for DropdownSection
            };
            return <DropdownSection {...ddSectionProps} />;
        }
    }

    let selectComponent;
    if (['select'].includes(object.type ?? '')) {
        let options =
            object.options?.map((opt) => {
                return {
                    value: opt,
                    label: object.optionField ? opt[object.optionField] : opt,
                };
            }) ?? [];

        if (object.optionClearable) {
            options = [{ value: null, label: PLEASE_SELECT___ }, ...options];
        }

        const selectedValue = options.find((opt) => _.isEqual(opt.value, value));

        const ddProps = {
            value: selectedValue,
            placeholder: object.placeholder,
            options,
            onChange: (newSelection: any) => {
                if (newSelection.value === null) {
                    if (object.optionClearable) onChange(null);
                    return;
                }
                onChange(newSelection.value);
            },
            style: { height: '38px' },
            theme: (theme: any) => ({
                ...theme,
                colors: {
                    ...theme.colors,
                    neutral50: PLACEHOLDER_COLOR,
                },
            }),
            isDisabled: readOnly,
        };
        selectComponent = (
            <div style={{ textAlign: 'left' }}>
                <Select {...ddProps} />
            </div>
        );
    }

    return (
        <div key={'drawerInputObject' + object.type + object.fieldName} style={{ position: 'relative' }}>
            {!object.hideLabel && object.type !== 'checkbox' && (
                <label htmlFor={object.type} style={{ display: 'flex', position: 'relative' }}>
                    {object.label}
                    {notateRequired && object.required && <div style={{ position: 'absolute', right: 0, fontSize: '24px', marginTop: '5px' }}>*</div>}
                </label>
            )}

            {selectComponent}
            {optionComponent}

            {object.type === 'number' && (
                <input
                    id={object.type}
                    placeholder={object.placeholder}
                    type="text"
                    value={![null, undefined].includes(value) ? value : ''}
                    onChange={object.disabled ? () => {} : (e) => onChange(e.target.value)}
                    disabled={object.disabled}
                    style={{ cursor: object.disabled ? 'not-allowed' : undefined }}
                    readOnly={readOnly}
                />
            )}

            {object.type === 'currency' && (
                <input
                    ref={currencyInput}
                    id={object.type}
                    placeholder={object.placeholder}
                    type="text"
                    value={![null, undefined].includes(value) && !isNaN(value) ? formatValue(value, object.type, object.disabled) : ''}
                    onChange={object.disabled ? () => {} : (e) => onChange(e.target.value.replace(',', '').replace('$', ''))}
                    onFocus={() => (object.disabled ? currencyInput.current?.blur() : setCurrencyFocused(true))}
                    onBlur={() => setCurrencyFocused(false)}
                    style={{ cursor: object.disabled ? 'not-allowed' : undefined }}
                    readOnly={readOnly}
                />
            )}

            {object.type === 'text' && (
                <input
                    id={object.type}
                    placeholder={object.placeholder}
                    type="text"
                    value={value ?? ''}
                    onChange={object.disabled ? undefined : (e) => onChange(e.target.value)}
                    disabled={object.disabled}
                    style={{ cursor: object.disabled ? 'not-allowed' : undefined }}
                    readOnly={readOnly}
                />
            )}

            {object.type === 'password' && (
                <div style={{ position: 'relative' }}>
                    <input
                        id={object.type}
                        name="passwordInput"
                        placeholder={object.placeholder}
                        type={showPassword ? 'text' : 'password'}
                        autoComplete="new-password"
                        value={value ?? ''}
                        onChange={object.disabled ? undefined : (e) => onChange(e.target.value)}
                        disabled={object.disabled}
                        style={{ cursor: object.disabled ? 'not-allowed' : undefined }}
                        readOnly={readOnly}
                    />
                    {object.allowShow && (
                        <img
                            style={{ position: 'absolute', right: '10px', cursor: 'pointer', top: '7px' }}
                            src={`/images/icons/eye${showPassword ? '_filled' : ''}.png`}
                            alt={'show/hide password'}
                            title={'show/hide password'}
                            onClick={readOnly ? undefined : toggleShowPassword}
                        />
                    )}
                </div>
            )}

            {object.type === 'percent' && (
                <input
                    id={object.type}
                    placeholder={object.placeholder}
                    type="text"
                    value={value ? formatValue(value, object.type, object.disabled) : ''}
                    onChange={object.disabled ? undefined : (e) => onChange(e.target.value.replace('%', ''))}
                    disabled={object.disabled}
                    onFocus={() => (object.disabled ? percentInput.current?.blur() : setPercentFocused(true))}
                    onBlur={() => setPercentFocused(false)}
                    style={{ cursor: object.disabled ? 'not-allowed' : undefined }}
                    readOnly={readOnly}
                />
            )}

            {object.type === 'checkbox' && (
                <div style={{ display: 'flex', gap: '8px', alignItems: 'center', marginTop: '10px', marginBottom: '10px' }}>
                    <Checkbox id={object.type} setChecked={(newValue) => onChange(newValue)} checked={value} style={{ width: '12px', height: '12px' }} disabled={readOnly} />
                    {!object.hideLabel && <p style={{ fontSize: '14px' }}>{object.label}</p>}
                </div>
            )}

            {object.type === 'custom' && object.component}

            {object.type === 'dateDropdowns' && <DrawerDateInput date={value} setDate={onChange} readOnly={readOnly} />}

            {object.type === 'date' && (
                <div className="drawerInputObject-datePicker">
                    <UTCDatePicker
                        id={object.type}
                        placeholderText="Enter MM/DD/YYYY"
                        dateFormat="MM/dd/yyyy"
                        selected={value ? TimelessDate.parse(value) : null}
                        onChange={(newDate) => onChange(newDate ? newDate.toDate() : null)}
                        disabled={readOnly}
                    />
                </div>
            )}

            {object.type === 'dateCalendar' && <ReactDatePicker selected={dateToUTCMidnight(value)} onChange={readOnly ? null : onChange} dateFormat="MM-dd-yyyy" />}

            <div>{errors && renderInputError(errors, object.fieldName)}</div>
        </div>
    );
};
