import _ from 'lodash';
import Checkbox from '../../../components/Inputs/Checkbox';

import styles from '../../../styles/performancePdfExportModal.module.scss';

const PerformancePdfExportModal = ({
    sidebarOpen,
    closeModal,
    investments,
    // include overview
    pdfPerfOverviewInclude,
    setPdfPerfOverviewInclude,
    // include table
    pdfPerfTableInclude,
    setPdfPerfTableInclude,
    // investments to include
    pdfInvestmentsToPrintMap,
    setPdfInvestmentsToPrintMap,
    capturePdf,
}) => {
    const handleCancel = () => {
        closeModal();
    };

    const handleExport = () => {
        capturePdf();
    };

    const isExportDisabled = () => {
        return Object.keys(pdfInvestmentsToPrintMap).length === 0 && !pdfPerfOverviewInclude && !pdfPerfTableInclude;
    };

    return (
        <div className={styles.mainBackground} style={{ left: sidebarOpen ? '320px' : '0', width: sidebarOpen ? 'calc(100% - 320px)' : '100%' }}>
            <div className={styles.main}>
                <div className={styles.title}>Export To PDF</div>

                <p style={{ color: '' }}>Choose the export options below, then press the "Export To PDF" button.</p>
                <br />
                <p style={{ color: '' }}>This may take a little while, please be patient.</p>

                <br />

                <button
                    style={{ padding: '10px', width: '200px' }}
                    onClick={handleExport}
                    disabled={isExportDisabled()}
                    title={isExportDisabled() ? 'Please select at least one investment or include the performance overview or investment details table' : ''}
                >
                    Export To PDF
                </button>

                <button style={{ padding: '10px', width: '200px' }} onClick={handleCancel}>
                    Cancel
                </button>

                <br />

                {/* checkbox to choose whether to include the investment details table */}
                <div style={{ display: 'flex', alignItems: 'center', margin: 10 }}>
                    <Checkbox
                        style={{ margin: '0px 10px 0px 0px' }}
                        checked={pdfPerfOverviewInclude}
                        setChecked={() => {
                            setPdfPerfOverviewInclude(!pdfPerfOverviewInclude);
                        }}
                    />
                    Include Performance Overview
                </div>

                <br />

                {/* checkbox to choose whether to include the investment details table */}
                <div style={{ display: 'flex', alignItems: 'center', margin: 10 }}>
                    <Checkbox
                        style={{ margin: '0px 10px 0px 0px' }}
                        checked={pdfPerfTableInclude}
                        setChecked={() => {
                            setPdfPerfTableInclude(!pdfPerfTableInclude);
                        }}
                    />
                    Include Investment Details Table
                </div>

                <br />

                <div className={styles.investmentListHeader}>
                    <div className={styles.investmentListTitle}>Include Investments</div>
                    <Checkbox
                        style={{ margin: '0px 10px 0px 50px' }}
                        checked={Object.keys(pdfInvestmentsToPrintMap).length === investments.length}
                        setChecked={() => {
                            const allInvMap = investments.reduce((acc, investment) => {
                                return { ...acc, [investment._id]: true };
                            }, {});
                            const areAllSelected = Object.keys(pdfInvestmentsToPrintMap).length === investments.length;
                            const newPdfInvestmentsToPrintMap = areAllSelected ? {} : allInvMap;
                            setPdfInvestmentsToPrintMap(newPdfInvestmentsToPrintMap);
                        }}
                    />
                    Select All
                </div>

                <div className={styles.investmentList}>
                    {_.sortBy(investments, (i) => i.name.toLowerCase()).map((investment, i) => {
                        const selected = pdfInvestmentsToPrintMap[investment._id] ?? false;

                        const toggleSelected = () => {
                            setPdfInvestmentsToPrintMap({
                                ...pdfInvestmentsToPrintMap,
                                [investment._id]: !pdfInvestmentsToPrintMap[investment._id],
                            });
                        };

                        return (
                            <div key={'investmentChoices' + i} className={`${styles.investmentChoice}${selected ? ` ${styles.selected}` : ''}`} onClick={toggleSelected}>
                                {investment.name}
                            </div>
                        );
                    })}
                </div>
            </div>
        </div>
    );
};

export default PerformancePdfExportModal;
