import React from 'react';
import ScrollBars from 'react-perfect-scrollbar';
import api from '../../api';

import '../../styles/notificationsList.css';
import { EM_DASH } from '../../constants/constantStrings';

interface Notification {
    _id: string;
    sender?: {
        name?: string;
        email?: string;
    };
}

interface NotificationsListProps {
    notifications: Notification[];
    loading: (duration: number, key?: string) => void;
    loaded: (key?: string) => void;
    setAlert: (alert: React.ReactNode) => void;
    loadClients: () => Promise<void>;
    clearNotifications: () => void;
    alert?: boolean;
}

const NotificationsList: React.FC<NotificationsListProps> = (props) => {
    const accept = async (e: React.MouseEvent, invitationId: string) => {
        e.stopPropagation();
        props.loading(320);

        const res = await api.post(`/invitations/${invitationId}/accept`, {});

        if (res) {
            await props.loadClients();
            props.loaded();
            props.setAlert(
                <>
                    Connection request accepted.
                    <button className="alert-button" style={{ float: 'right', top: '20px' }} onClick={() => props.setAlert(null)}>
                        Dismiss
                    </button>
                </>
            );
            setTimeout(() => {
                props.setAlert(null);
            }, 3000);
        }
        props.loaded();
    };

    const decline = async (e: React.MouseEvent, invitationId: string) => {
        e.stopPropagation();
        props.loading(320);

        const res = await api.delete(`/invitations/${invitationId}`);

        if (res) {
            await props.loadClients();
            props.loaded();
            props.setAlert(
                <>
                    Connection request declined.
                    <button className="alert-button" style={{ float: 'right', top: '20px' }} onClick={() => props.setAlert(null)}>
                        Dismiss
                    </button>
                </>
            );
            setTimeout(() => {
                props.setAlert(null);
            }, 3000);
        }
        props.loaded();
    };

    const markAllAsRead = async () => {
        props.clearNotifications();
        props.loading(320, 'markAllAsRead');
        await Promise.all(
            props.notifications.map((n) => {
                return new Promise<void>((resolve) => {
                    api.patch(`/invitations/${n._id}/read`);
                    resolve();
                });
            })
        );
        props.loaded('markAllAsRead');
    };

    const renderNotification = (notification: Notification, idx: number) => {
        return (
            <div key={notification._id + idx} className="connections-list-item">
                <img src="/images/icons/addPerson.png" alt="person" />

                <div>
                    <div style={{ width: '100%', display: 'flex', justifyContent: 'space-between', alignItems: 'flex-end', marginBottom: 5 }}>
                        <div>
                            <b>{`${notification.sender?.name || EM_DASH}`}</b>
                        </div>
                        <span className="btn accept" onClick={(e) => accept(e, notification._id)}>
                            Accept
                        </span>
                        <span className="btn decline" onClick={(e) => decline(e, notification._id)}>
                            Decline
                        </span>
                    </div>
                    <div>{`(${notification.sender?.email || EM_DASH})`}</div>
                </div>
            </div>
        );
    };

    const p = props;
    const notifs = p.notifications;
    return (
        <div
            className="notifications-component"
            style={{
                marginTop: p.alert ? 80 : undefined,
            }}
            onClick={(e) => e.stopPropagation()}
        >
            <div
                className="notif-arrow-up"
                style={{
                    marginTop: p.alert ? 80 : undefined,
                }}
            />

            <div className="notifications-container">
                <div className="notifications-container-header">
                    <h1>Notifications</h1>
                </div>

                {!notifs.length && (
                    <div style={{ marginTop: '80px' }} className="account-recent-activity-caught-up">
                        <h3>You're all caught up!</h3>
                    </div>
                )}

                <ScrollBars>
                    <div className="notification-list-container">{notifs.map(renderNotification)}</div>
                </ScrollBars>

                {notifs.length > 0 && (
                    <div className="notifications-footer">
                        <div className="a notification-link" onClick={markAllAsRead}>
                            Mark all as read
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};

export default NotificationsList;
