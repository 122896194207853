import React from 'react';
import { withRouter, RouteComponentProps } from 'react-router-dom';

import Table from '../Tables/Table';
import { getClientStatusColor } from '../../utilities/AdvisorVue/getClientStatusColor';
import { addCommasToNumber } from '../../utilities/format/addCommasToNumber';
import { ClientHorizBarChart } from './ClientHorizBarChart';
import { ClientListStatus } from './ClientListStatus';
import '../../styles/clientListTable.css';
import formatCurrency from '../../utilities/format/formatCurrency';
import Dropdown from '../Dropdowns/Dropdown';
import api from '../../api';
import { setDismissableAlert } from '../../utilities/alert/setDismissableAlert';
import { UserPermissions } from '../../utilities/AdvisorVue/permissions';
import { EM_DASH } from '../../constants/constantStrings';
import _ from 'lodash';
import { User } from '../../types/User';
import { Client } from '../../types/Client';
import { Account } from '../../types/Account';
import { Investment } from '../../types/Investment';

interface ClientListTableProps extends RouteComponentProps {
    clients: Client[];
    user: User;
    includeHeader?: boolean;
    showTaxView?: boolean;
    loading: (val: number, key: string) => void;
    loaded: (key: string) => void;
    sendInvite: (email: string, name: string, resend: boolean, groupId?: string) => void;
    viewSettings: (client: Client) => void;
    declineInvite: (invitationId: string) => void;
    acceptInvite: (invitationId: string, groupId?: string) => void;
    setAlert: (alert: string) => void;
    isConnectionRequest?: boolean;
}

const getCallsDueColor = (callsDue: number) => (callsDue && callsDue > 0 ? 'var(--color-dark-red)' : '#969696');

const renderButton = (text: string, color: string, fn: () => void) => {
    return (
        <div
            className="clientCard-optionsButton"
            style={{ color }}
            onClick={(e) => {
                e.stopPropagation();
                fn?.();
            }}
        >
            {text}
        </div>
    );
};

const ClientListTable: React.FC<ClientListTableProps> = (props) => {
    const [sortState, setSortState] = React.useState<{ field: keyof Client; ascending: boolean } | null>(null);
    const [clientExportMap, setClientExportMap] = React.useState<Record<string, string[]>>({});
    const [groupMembership, setGroupMembership] = React.useState<User['advisor_group_memberships'] | { group: string; role: string } | null>(null);
    const [extegration, setExtegration] = React.useState<any>(null);
    const [group, setGroup] = React.useState<any>(null);

    let showTaxView = !UserPermissions().canReadCapitalCalls && !UserPermissions().canReadAnyInvestments && UserPermissions().canReadDocuments;

    if (props.showTaxView) {
        showTaxView = true;
    }

    const allowedToExport = () => groupMembership && extegration && UserPermissions().canExport;

    const haveExportChangesBeenMade = () => {
        if (!groupMembership || !extegration) return false;
        if (Object.keys(clientExportMap).length === 0) return false;
        const allAccountsInExportMap = Object.values(clientExportMap).flat();
        return (extegration?.entities?.sort() ?? []).join(',') !== allAccountsInExportMap.sort().join(',');
    };

    const setClientMapFromGroup = (grp: any, exteg: any) => {
        const clientMap: Record<string, string[]> = {};
        props.clients.forEach((client) => {
            const accountIds = client.accountIds?.filter((id: string) => exteg?.entities?.includes(id)) || [];
            if (accountIds.length) {
                clientMap[client._id] = accountIds;
            }
        });
        setClientExportMap(clientMap);
    };

    React.useEffect(() => {
        const groupMembership = props.user?.advisor_group_memberships?.[0];

        if (groupMembership) {
            setGroupMembership(groupMembership);
            (async () => {
                props.loading(0, 'clientListTableLoad');
                const group = await api.get(`/advisorygroups/${groupMembership.group}`);
                if (group) {
                    if (['Owner', 'Admin'].includes(groupMembership.role)) {
                        const extegRes = await api.get(`/advisorygroups/${groupMembership.group}/extegrations`);
                        group.extegrations = extegRes;
                        const exteg = extegRes?.[0];
                        setExtegration(exteg);
                        setClientMapFromGroup(groupMembership, exteg);
                    }
                    setGroup(group);
                }
                props.loaded('clientListTableLoad');
            })();
        }
    }, [JSON.stringify(props.clients), JSON.stringify(props.user?.advisor_group_memberships?.[0])]);

    const savePreferences = async () => {
        props.loading(0, 'clientListTableLoad');
        const res = await api.patch(`/advisorygroups/${(groupMembership as { group: string; role: string }).group}/extegrations/${extegration._id}`, {
            userAccountMap: clientExportMap,
        });
        if (res) {
            setDismissableAlert(props.setAlert, 'Preferences saved', false, 5000);
        } else {
            setDismissableAlert(props.setAlert, 'Error saving preferences', true, 5000);
        }
        props.loaded('clientListTableLoad');
    };

    const getAccountSum = (client: Client) => {
        const types = client.valuationByInvestmentType ?? [];
        let sum = types.reduce((x, i) => x + i.amount, 0);
        return sum;
    };

    const getColumns = () => {
        let columns: Array<any> = [];

        columns.push({
            title: 'Client',
            field: 'name',
            sort: {
                field: 'name',
            },
            render: (_colInfo: any, _rowInfo: any, dataItems: Client[], itemIdx: number) => {
                const client = dataItems[itemIdx];
                const name = client.name ?? client.receiver;
                return (
                    <td key={'client' + itemIdx} className="altx-table-cell" style={{ position: 'relative' }}>
                        {!client.isConnectionRequest && (
                            <div style={{ position: 'absolute', left: 0, top: 0, bottom: 0, width: '6px', backgroundColor: getClientStatusColor(client) }} />
                        )}
                        <div
                            title={name}
                            className="table-ellipses-3-line"
                            style={{
                                fontSize: '16px',
                                fontWeight: 600,
                                color: client.isConnectionRequest ? '#434343' : 'var(--color-primary)',
                            }}
                        >
                            {name}
                        </div>
                    </td>
                );
            },
        });

        if (showTaxView) {
            columns.push({
                title: 'Documents Collected',
                render: (_colInfo: any, _rowInfo: any, dataItems: Client[], itemIdx: number) => {
                    const client = dataItems[itemIdx];
                    let displayStr = EM_DASH;
                    if ((client as any).docs_collected_by_account?.total) {
                        // Temporary type assertion
                        const { received, expected } = (client as any).docs_collected_by_account.total;
                        displayStr = `${(!expected ? 0 : (received / expected) * 100).toFixed(0)}% | ${received} of ${expected}`;
                    }
                    return (
                        <td key={'docs' + itemIdx} className="altx-table-cell">
                            {displayStr}
                        </td>
                    );
                },
            });
        }

        columns.push({
            title: showTaxView ? 'Total Investments' : 'Investments',
            field: 'investments_count',
            sort: {
                field: 'investments_count',
            },
            render: (_colInfo: any, _rowInfo: any, dataItems: Client[], itemIdx: number) => {
                const client = dataItems[itemIdx];
                return (
                    <td key={'inv' + itemIdx} className="altx-table-cell">
                        {addCommasToNumber(client.investments_count ?? 0)} Investments
                    </td>
                );
            },
        });

        columns.push({
            title: 'Account Value',
            field: 'accountSum',
            sort: {
                field: 'accountSum',
            },
            render: (_colInfo: any, _rowInfo: any, dataItems: any[], itemIdx: number): JSX.Element => {
                const client = dataItems[itemIdx] as Client;
                let sum = getAccountSum(client);

                // Build map of currency to sum from a list of investments
                const investments = client.investments ?? [];
                const currencies = _.uniq(investments.map((i) => i.currency));
                const currencySumMap = currencies.reduce(
                    (acc: Record<string, number>, cur: string) => {
                        const sum = investments
                            .filter((i) => i.currency === cur)
                            .reduce((acc, i) => {
                                const amount = i.performance?.itd?.committed ?? i.performance?.itd?.contributions_to_date ?? 0;
                                return acc + amount;
                            }, 0);
                        acc[cur] = sum;
                        return acc;
                    },
                    {} as Record<string, number>
                );

                return (
                    <td key={'value' + itemIdx} className="altx-table-cell">
                        {formatCurrency(sum ?? 0)}
                        {/* {Object.entries(currencySumMap).map(([cur, sum], idx) => {
                            return <div>{formatCurrency(sum, cur)} ({cur})</div>
                        })} */}
                    </td>
                );
            },
        });

        columns.push({
            title: 'Allocations',
            render: (_colInfo: any, _rowInfo: any, dataItems: Client[], itemIdx: number) => {
                const client = dataItems[itemIdx];
                return (
                    <td key={'alloc' + itemIdx} className="altx-table-cell" style={{ position: 'relative', width: '130px' }}>
                        <ClientHorizBarChart investments={client.valuationByInvestmentType as Investment[]} blank={client.status === 'Invite Pending'} />
                    </td>
                );
            },
        });

        if (UserPermissions().canReadCapitalCalls && !showTaxView) {
            columns.push({
                title: 'Capital Calls Due',
                field: 'callsDue',
                sort: {
                    field: 'callsDue',
                },
                render: (_colInfo: any, _rowInfo: any, dataItems: Client[], itemIdx: number) => {
                    const client = dataItems[itemIdx];
                    return (
                        <td key={'calls' + itemIdx} className="altx-table-cell">
                            {client.isConnectionRequest ? (
                                <ClientListStatus client={client} />
                            ) : (
                                <div style={{ display: 'flex', flexDirection: 'row' }}>
                                    {client.status !== 'Invite Pending' ? (
                                        <>
                                            <div
                                                style={{
                                                    padding: '3px 6px 3px 6px',
                                                    marginRight: '7px',
                                                    backgroundColor: getCallsDueColor(client.callsDue!),
                                                    color: 'white',
                                                    fontSize: '16px',
                                                    fontWeight: 'bold',
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    justifyContent: 'center',
                                                    position: 'relative',
                                                    borderRadius: '2px',
                                                }}
                                            >
                                                {client.callsDue}
                                            </div>
                                            calls due
                                        </>
                                    ) : (
                                        <div style={{ width: '30px', borderBottom: '1px solid #434343', marginLeft: '30px' }} />
                                    )}
                                </div>
                            )}
                        </td>
                    );
                },
            });
        }

        if (!props.isConnectionRequest) {
            columns.push({
                title: 'Status',
                render: (_colInfo: any, _rowInfo: any, dataItems: Client[], itemIdx: number) => {
                    const client = dataItems[itemIdx];
                    return (
                        <td key={'statusDecline' + itemIdx} className="altx-table-cell">
                            <ClientListStatus client={client} />
                        </td>
                    );
                },
            });
            columns.push({
                title: '',
                render: (_colInfo: any, _rowInfo: any, dataItems: Client[], itemIdx: number) => {
                    const client = dataItems[itemIdx];
                    return (
                        <td key={'status_send' + client._id} className="altx-table-cell">
                            {(client.status === 'Invite Pending' || client.status === 'Deactivated') && (
                                <img
                                    src="/images/icons/send.png"
                                    onClick={() => {
                                        const group = Array.isArray(groupMembership) ? groupMembership[0]?.group : groupMembership?.group;
                                        props.sendInvite(client.email ?? client.receiver!, client.name!, true, group);
                                    }}
                                    alt={'send'}
                                    title="Resend Invite"
                                />
                            )}
                        </td>
                    );
                },
            });
            columns.push({
                title: '',
                render: (_colInfo: any, _rowInfo: any, dataItems: Client[], itemIdx: number) => {
                    const client = dataItems[itemIdx];
                    return (
                        <td key={'status_settings' + client._id} className="altx-table-cell">
                            <img
                                src="/images/icons/gear_small.png"
                                onClick={(e) => {
                                    e.stopPropagation();
                                    e.preventDefault();
                                    props.viewSettings(client);
                                }}
                                alt={'settings'}
                                style={{ marginRight: '10px' }}
                                title="Settings"
                                className="clientListTable_settingsBtn"
                            />
                        </td>
                    );
                },
            });

            if (allowedToExport()) {
                columns.push({
                    title: 'Export To',
                    render: (_colInfo: any, _rowInfo: any, dataItems: Client[], itemIdx: number) => {
                        const client = dataItems[itemIdx];
                        const numEntities = client.accounts?.length ?? 0;
                        const numSelected = clientExportMap[client._id]?.length ?? 0;
                        const numSelectedStr = numSelected === numEntities ? 'All Entities' : `${numSelected} of ${numEntities} Entities`;
                        if (client.status === 'Invite Pending') return <td key={'statusAccept' + itemIdx} className="altx-table-cell" />;
                        return (
                            <td key={'statusAccept' + itemIdx} className="altx-table-cell">
                                {
                                    <div>
                                        <Dropdown
                                            multiSelect={true}
                                            optionField="name"
                                            options={client.accounts ?? []}
                                            select={(accounts: Account[]) => {
                                                const newClientExportMap = {
                                                    ...clientExportMap,
                                                    [client._id]: accounts.map((a) => a._id),
                                                };
                                                setClientExportMap(newClientExportMap);
                                            }}
                                            selection={client.accounts?.filter((a) => clientExportMap[client._id]?.includes(a._id))}
                                            overrideClosedText={`${numSelectedStr}`}
                                            style={{ height: '28px', width: '150px' }}
                                            disableDefaultOption={true}
                                        />
                                    </div>
                                }
                            </td>
                        );
                    },
                });
            }
        } else {
            columns.push({
                title: 'Status',
                render: (_colInfo: any, _rowInfo: any, dataItems: Client[], itemIdx: number) => {
                    const client = dataItems[itemIdx];
                    return (
                        <td key={'statusDecline' + itemIdx} className="altx-table-cell">
                            <div style={{ marginLeft: '-50px' }}>
                                {renderButton('Decline', 'var(--color-dark-red)', () => props.declineInvite(client.currentAdvisor?.invitationId!))}
                            </div>
                        </td>
                    );
                },
            });
            columns.push({
                title: 'Status',
                render: (_colInfo: any, _rowInfo: any, dataItems: Client[], itemIdx: number) => {
                    const client = dataItems[itemIdx];
                    return (
                        <td key={'statusAccept' + itemIdx} className="altx-table-cell">
                            <div style={{ marginLeft: '-50px' }}>
                                {renderButton('Accept', 'var(--color-link)', () =>
                                    props.acceptInvite(client.currentAdvisor?.invitationId!, Array.isArray(groupMembership) ? groupMembership[0]?.group : groupMembership?.group!)
                                )}
                            </div>
                        </td>
                    );
                },
            });
        }

        return columns;
    };

    const sortItems = (items: Client[], sortState: { field: keyof Client | 'accountSum'; ascending: boolean }) => {
        const field = sortState.field;
        let sortedItems = items;

        if (['name', 'investments_count', 'callsDue'].includes(field as string)) {
            sortedItems = _.orderBy(items, [field as string], [sortState.ascending ? 'asc' : 'desc']);
        } else if (sortState.field === 'accountSum') {
            sortedItems = sortedItems.map((i) => ({ ...i, accountSum: getAccountSum(i) }));
            sortedItems = _.orderBy(sortedItems, ['accountSum'], [sortState.ascending ? 'asc' : 'desc']);
        }

        return sortedItems;
    };

    let listItems = props.clients;

    if (sortState) {
        listItems = sortItems(listItems, sortState);
    }

    return (
        <div style={{ position: 'relative' }}>
            {allowedToExport() && haveExportChangesBeenMade() && !props.isConnectionRequest && (
                <button style={{ position: 'absolute', top: '-60px', right: '-215px', width: '150px' }} onClick={savePreferences}>
                    Save Preferences
                </button>
            )}
            <Table
                noHoverColor
                includeHeader={props.includeHeader}
                columns={getColumns()}
                items={listItems}
                rowStyle={{
                    backgroundColor: props.isConnectionRequest ? 'var(--color-light-separator)' : '#fbfbfb',
                    borderBottomColor: 'rgba(0,0,0,0)',
                }}
                rowInfo={{
                    rowClick: (dataItems: Client[], itemIdx: number) => {
                        const item = dataItems[itemIdx];
                        if (item.status && ['Active', 'Staging'].includes(item.status)) {
                            props.history.push(`/advisor/clients/${item._id}`);
                        }
                    },
                }}
                onSort={(sortState: { field: keyof Client | 'accountSum'; ascending: boolean }) => {
                    setSortState(sortState);
                }}
            />
        </div>
    );
};

export default withRouter(ClientListTable);
