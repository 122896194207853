import React, { useState, useEffect } from 'react';
import dateToUTCMidnight from '../../utilities/date/dateToUTCMidnight';
import Dropdown from '../Dropdowns/Dropdown';
import { PLEASE_SELECT___ } from '../../constants/constantStrings';

interface DrawerDateInputProps {
    date?: Date | null;
    setDate: (date: Date) => void;
    readOnly?: boolean;
}

export const DrawerDateInput: React.FC<DrawerDateInputProps> = ({ date, setDate, readOnly }) => {
    const [day, setDay] = useState<number | null>(null);
    const [month, setMonth] = useState<number | null>(null);
    const [year, setYear] = useState<number | null>(null);

    useEffect(() => {
        if (date) {
            const utcDate = dateToUTCMidnight(date).toISOString().split('T')[0];
            const [newYear, newMonth, newDay] = utcDate.split('-').map(Number);
            if (newDay !== day && newMonth - 1 !== month && newYear !== year) {
                setDay(newDay - 1);
                setMonth(newMonth - 1);
                setYear(newYear);
            }
        }
        if (day && month && year && !date) {
            setDay(null);
            setMonth(null);
            setYear(null);
        }
    }, [date, day, month, year]);

    useEffect(() => {
        if (day !== null && month !== null && year !== null) {
            const newDate = dateToUTCMidnight(new Date(year, month, day + 1));
            setDate(newDate instanceof Date ? newDate : newDate.toDate());
        }
    }, [day, month, year, setDate]);

    const THIS_YEAR = new Date().getFullYear();
    const YEARS = Array.from(Array(100).keys()).map((y) => THIS_YEAR - y);

    const MONTHS = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
    const DAYS_PER_MONTH = [31, 29, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];

    const isLeapYear = (y: number) => y % 4 === 0 && y % 100 !== 0;

    if (year && !isLeapYear(year)) DAYS_PER_MONTH[1] = 28;

    const DAYS = Array.from(Array(month !== null ? DAYS_PER_MONTH[month] : 31).keys()).map((d) => d + 1);

    return (
        <div className="addEditDocument-basicFields-date">
            <Dropdown
                selection={month !== null ? MONTHS[month] : 'month'}
                fontColor={month === null ? 'lightgray' : undefined}
                idPrefix="Month"
                options={MONTHS}
                select={(newMonth: string) => {
                    if (newMonth === PLEASE_SELECT___) return;
                    const monthIndex = MONTHS.indexOf(newMonth);
                    setMonth(monthIndex);
                    if (day !== null && day > DAYS_PER_MONTH[monthIndex] - 1) {
                        setDay(DAYS_PER_MONTH[monthIndex] - 1);
                    }
                }}
                style={{ height: '38px', width: '40%' }}
                readOnly={readOnly}
            />
            <Dropdown
                selection={day !== null ? day + 1 : 'day'}
                fontColor={day === null ? 'lightgray' : undefined}
                idPrefix="Day"
                options={DAYS}
                select={(newDay: number) => {
                    if (newDay === Number(PLEASE_SELECT___)) return;
                    setDay(newDay - 1);
                }}
                style={{ height: '38px', width: '25%' }}
                readOnly={readOnly}
            />
            <Dropdown
                selection={year ?? 'year'}
                fontColor={year === null ? 'lightgray' : undefined}
                idPrefix="Year"
                options={YEARS}
                select={(newYear: number) => {
                    if (newYear === Number(PLEASE_SELECT___)) return;
                    setYear(newYear);
                    if (month === 1 && day === 28 && !isLeapYear(newYear)) {
                        setDay(27);
                    }
                }}
                style={{ height: '38px', width: '25%' }}
                readOnly={readOnly}
            />
        </div>
    );
};
