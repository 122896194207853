// Developed by Aptus Engineering, Inc. <https://aptus.aero>
// See LICENSE.md file in project root directory

import _ from 'lodash';
import React, { Component } from 'react';

import '../../styles/widget.css';
import '../../styles/clientHorizBarChart.css';

import { Chart } from 'react-chartjs-2';

import { getColorFromIndex } from '../../utilities/AdvisorVue/getColorFromIndex';

export class ClientHorizBarChart extends Component {
    state = {
        data: null,
    };

    componentDidMount = () => {
        this.loadData();
    };

    componentDidUpdate = (prev) => {
        // only update if data is new or data is not set yet
        if (!_.isEqual(this.props.investments, prev.investments) || !_.isEqual(this.props.blank, prev.blank)) this.loadData();
    };

    loadData = () => {
        const p = this.props;
        const investments = p.investments;
        // if (!investments || !investments.length) return;

        // aggregate committed values into grouped types
        const types = investments ?? [];
        let sum = types.reduce((x, i) => x + i.amount, 0);

        const datasets =
            p.blank || types.length === 0 || sum === 0
                ? [
                      {
                          type: 'bar',
                          label: 'empty',
                          backgroundColor: '#E0E0E0',
                          barThickness: 20,
                          data: [1],
                      },
                  ]
                : types.map((t, tIdx) => {
                      return {
                          type: 'bar',
                          label: t.name,
                          backgroundColor: getColorFromIndex(tIdx),
                          barThickness: 20,
                          data: [t.amount / sum],
                      };
                  });

        const labels = ['Allocations'];
        const data = { datasets, labels };
        this.setState({ data });
    };

    getChartOptions = (data) => ({
        indexAxis: 'y',
        animation: false,
        responsive: true,
        maintainAspectRatio: false,
        scales: {
            x: {
                display: false,
                stacked: true,
                grid: {
                    display: false,
                },
            },
            y: {
                display: false,
                grid: {
                    display: false,
                },
                stacked: true,
                ticks: {
                    display: false,
                },
            },
        },
        plugins: {
            legend: {
                display: false,
            },
            tooltip: {
                enabled: false,
            },
        },
    });

    render = () => {
        const data = this.state.data;
        return (
            <div className="clientBar-component">
                {data && <Chart data={data} options={this.getChartOptions(data)} style={{ position: 'relative', backgroundColor: 'transparent' }} />}
            </div>
        );
    };
}
