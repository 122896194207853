import '../../styles/alert.css';
import React from 'react';

interface DetailedAlertProps {
    title: string;
    description: string;
    buttonId?: string;
    buttonText: string;
    onClick: () => void;
}

const DetailedAlert: React.FC<DetailedAlertProps> = ({ title, description, buttonId, buttonText, onClick }) => {
    return (
        <div className={'detailedAlert'}>
            <div className={'detailedAlertMessage'}>
                <div className={'detailedAlertTitle'}>{title}</div>
                <div className={'detailedAlertDetails'}>{description}</div>
            </div>
            <div className="centerButtonContainer">
                <button id={buttonId ?? ''} className={'alert-button'} onClick={onClick}>
                    {buttonText}
                </button>
            </div>
        </div>
    );
};

export default DetailedAlert;
