/* tslint:disable */
/* eslint-disable */
/**
 * AltExchange API Version 2
 * This is the second version of the AltExchange API, designed to provide a comprehensive suite of financial data and reporting operations for AltExchange clients. It includes enhanced features for asset reporting, aiming to deliver a more robust and user-friendly experience.
 *
 * The version of the OpenAPI document: 2.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from './configuration';
import type { AxiosPromise, AxiosInstance, RawAxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
import type { RequestArgs } from './base';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, BaseAPI, RequiredError, operationServerMap } from './base';

/**
 * 
 * @export
 * @enum {string}
 */

export const AccountTypes = {
    Individual: 'individual',
    Joint: 'joint',
    Trust: 'trust',
    Ira: 'ira',
    Llc: 'llc',
    Partnership: 'partnership',
    Corporation: 'corporation',
    Other: 'other'
} as const;

export type AccountTypes = typeof AccountTypes[keyof typeof AccountTypes];


/**
 * 
 * @export
 * @enum {string}
 */

export const AdminActionOptions = {
    SyncManualConnection: 'sync_manual_connection'
} as const;

export type AdminActionOptions = typeof AdminActionOptions[keyof typeof AdminActionOptions];


/**
 * The status of the outreach process for a connection that is being processed by an asset manager.
 * @export
 * @interface AssetManagerConnectionOutreachStatuses
 */
export interface AssetManagerConnectionOutreachStatuses {
    /**
     * 
     * @type {string}
     * @memberof AssetManagerConnectionOutreachStatuses
     */
    'all_statuses'?: AssetManagerConnectionOutreachStatusesAllStatusesEnum;
    /**
     * 
     * @type {string}
     * @memberof AssetManagerConnectionOutreachStatuses
     */
    'incomplete_statuses'?: AssetManagerConnectionOutreachStatusesIncompleteStatusesEnum;
    /**
     * 
     * @type {string}
     * @memberof AssetManagerConnectionOutreachStatuses
     */
    'complete_statuses'?: AssetManagerConnectionOutreachStatusesCompleteStatusesEnum;
    /**
     * 
     * @type {AssetManagerConnectionOutreachStatusesPhrasingMap}
     * @memberof AssetManagerConnectionOutreachStatuses
     */
    'phrasing_map'?: AssetManagerConnectionOutreachStatusesPhrasingMap;
}

export const AssetManagerConnectionOutreachStatusesAllStatusesEnum = {
    ConnectionCreated: 'Connection Created',
    ReadyForAmToStart: 'Ready For AM To Start',
    AmUnableToSupport: 'AM Unable To Support',
    AmNeedsMoreInformation: 'AM Needs More Information',
    ConnectionSetupCompleteByAm: 'Connection Setup Complete By AM',
    PortalCredentialsCreated: 'Portal Credentials Created'
} as const;

export type AssetManagerConnectionOutreachStatusesAllStatusesEnum = typeof AssetManagerConnectionOutreachStatusesAllStatusesEnum[keyof typeof AssetManagerConnectionOutreachStatusesAllStatusesEnum];
export const AssetManagerConnectionOutreachStatusesIncompleteStatusesEnum = {
    ReadyForAmToStart: 'Ready For AM To Start',
    AmUnableToSupport: 'AM Unable To Support',
    AmNeedsMoreInformation: 'AM Needs More Information',
    ConnectionSetupCompleteByAm: 'Connection Setup Complete By AM'
} as const;

export type AssetManagerConnectionOutreachStatusesIncompleteStatusesEnum = typeof AssetManagerConnectionOutreachStatusesIncompleteStatusesEnum[keyof typeof AssetManagerConnectionOutreachStatusesIncompleteStatusesEnum];
export const AssetManagerConnectionOutreachStatusesCompleteStatusesEnum = {
    PortalCredentialsCreated: 'Portal Credentials Created'
} as const;

export type AssetManagerConnectionOutreachStatusesCompleteStatusesEnum = typeof AssetManagerConnectionOutreachStatusesCompleteStatusesEnum[keyof typeof AssetManagerConnectionOutreachStatusesCompleteStatusesEnum];

/**
 * 
 * @export
 * @interface AssetManagerConnectionOutreachStatusesPhrasingMap
 */
export interface AssetManagerConnectionOutreachStatusesPhrasingMap {
    /**
     * 
     * @type {string}
     * @memberof AssetManagerConnectionOutreachStatusesPhrasingMap
     */
    'keys'?: AssetManagerConnectionOutreachStatusesPhrasingMapKeysEnum;
    /**
     * 
     * @type {string}
     * @memberof AssetManagerConnectionOutreachStatusesPhrasingMap
     */
    'values'?: AssetManagerConnectionOutreachStatusesPhrasingMapValuesEnum;
}

export const AssetManagerConnectionOutreachStatusesPhrasingMapKeysEnum = {
    ConnectionCreated: 'Connection Created',
    ReadyForAmToStart: 'Ready For AM To Start',
    AmUnableToSupport: 'AM Unable To Support',
    AmNeedsMoreInformation: 'AM Needs More Information',
    ConnectionSetupCompleteByAm: 'Connection Setup Complete By AM',
    PortalCredentialsCreated: 'Portal Credentials Created'
} as const;

export type AssetManagerConnectionOutreachStatusesPhrasingMapKeysEnum = typeof AssetManagerConnectionOutreachStatusesPhrasingMapKeysEnum[keyof typeof AssetManagerConnectionOutreachStatusesPhrasingMapKeysEnum];
export const AssetManagerConnectionOutreachStatusesPhrasingMapValuesEnum = {
    ConnectionCreated: 'Connection Created',
    NotStarted: 'Not Started',
    UnableToSetupPortal: 'Unable to setup portal',
    NeedMoreInfoFromAltExchange: 'Need more info from AltExchange',
    PortalSetUpCompleted: 'Portal set up completed',
    Completed: 'Completed'
} as const;

export type AssetManagerConnectionOutreachStatusesPhrasingMapValuesEnum = typeof AssetManagerConnectionOutreachStatusesPhrasingMapValuesEnum[keyof typeof AssetManagerConnectionOutreachStatusesPhrasingMapValuesEnum];

/**
 * The status of the outreach process for an asset manager.
 * @export
 * @enum {string}
 */

export const AssetManagerOutreachStatus = {
    NotStarted: 'Not Started',
    NoResponse: 'No Response',
    UnableToSupport: 'Unable to Support',
    GrantingPortalAccess: 'Granting Portal Access',
    InvestmentSetup: 'Investment Setup',
    Done: 'Done'
} as const;

export type AssetManagerOutreachStatus = typeof AssetManagerOutreachStatus[keyof typeof AssetManagerOutreachStatus];


/**
 * Audit result categories.
 * @export
 * @enum {string}
 */

export const AuditResultCategory = {
    None: 'None',
    Biqc: 'BIQC',
    Sorting: 'Sorting',
    Tagging: 'Tagging',
    DocumentProcessing: 'Document Processing',
    Integrations: 'Integrations',
    Users: 'Users',
    Entities: 'Entities',
    Connections: 'Connections',
    Messages: 'Messages',
    Advisor: 'Advisor',
    Investments: 'Investments',
    Rals: 'RALs',
    SecurityMaster: 'Security Master',
    Extegrations: 'Extegrations'
} as const;

export type AuditResultCategory = typeof AuditResultCategory[keyof typeof AuditResultCategory];


/**
 * Audit result check names.
 * @export
 * @enum {string}
 */

export const AuditResultCheckNames = {
    InvestmentUnderAdministrativeReview: 'Investment Under Administrative Review',
    UnrealizedValueSpike: 'Unrealized Value Spike',
    EndingBeginningBalanceMismatch: 'Ending/beginning balance mismatch',
    InvalidDateRange: 'Invalid date range',
    InaccurateDistributionsSum: 'Inaccurate distributions sum',
    InaccurateContributionsSum: 'Inaccurate contributions sum',
    CapNavStatementTimeliness: 'Cap/NAV Statement Timeliness',
    HaveCapNavStatementEveryPeriod: 'Have Cap/NAV Statement every period',
    TaxDocumentTimeliness: 'Tax Document Timeliness',
    HasTaxDocumentEveryYearSinceInvestmentStart: 'Has Tax Document every year since investment start',
    TransactionWithDefaultDateData: 'Transaction with default date data',
    DuplicateTransactions: 'Duplicate Transactions',
    UnsortedDocument: 'Unsorted Document',
    CapitalStatementWithInvalidMetadata: 'Capital Statement With Invalid Metadata',
    CapitalCallWithInvalidMetadata: 'Capital Call With Invalid Metadata',
    DistributionWithInvalidMetadata: 'Distribution With Invalid Metadata',
    NavStatementWithInvalidMetadata: 'NAV Statement WithInvalid Metadata',
    NetCapitalCallWithInvalidMetadata: 'Net Capital Call With Invalid Metadata',
    NetDistributionWithInvalidMetadata: 'Net Distribution With Invalid Metadata',
    TaxDocumentWithInvalidMetadata: 'Tax Document With Invalid Metadata',
    ManualConnectionPortalSync: 'Manual Connection Portal Sync',
    ConnectionStatusNotActive: 'Connection Status Not Active',
    ConnectionWithoutContacts: 'Connection Without Contacts',
    ConnectionCompletedByAssetManager: 'Connection Completed By Asset Manager',
    UnmappedDataInMostRecentConnectionSyncStagingArea: 'Unmapped Data in Most Recent Connection Sync (Staging Area)',
    MessagesUnreadFor3Days: 'Messages Unread for 3 Days',
    MessagesWithDocumentsAttached: 'Messages with Documents Attached',
    MessagesFlaggedFor5Days: 'Messages Flagged for 5 Days',
    FundInvestmentsWithoutSecurityMasterTicker: 'Fund investments without Security Master Ticker',
    FundInvestmentsMissingSubtype: 'Fund investments missing Subtype',
    NonHiddenInvestmentHasTypeDiverseInvestments: 'Non-hidden Investment has type Diverse Investments',
    UnhiddenDiverseInvestments: 'Unhidden Diverse Investments',
    AbnormalInvestmentIrr: 'Abnormal Investment IRR',
    InvestmentHiddenTooLong: 'Investment Hidden Too Long',
    ExtegrationSyncFailedByAdvisoryGroup: 'Extegration Sync Failed by Advisory Group'
} as const;

export type AuditResultCheckNames = typeof AuditResultCheckNames[keyof typeof AuditResultCheckNames];


/**
 * The status of an audit result.
 * @export
 * @enum {string}
 */

export const AuditResultStatus = {
    Active: 'active',
    Ignored: 'ignored',
    AutoIgnored: 'auto-ignored',
    Completed: 'completed'
} as const;

export type AuditResultStatus = typeof AuditResultStatus[keyof typeof AuditResultStatus];


/**
 * Authorization categories.
 * @export
 * @enum {string}
 */

export const AuthCategories = {
    C: 'c',
    R: 'r',
    U: 'u',
    D: 'd',
    Star: '*'
} as const;

export type AuthCategories = typeof AuthCategories[keyof typeof AuthCategories];


/**
 * The status of the outreach process for a connection.
 * @export
 * @enum {string}
 */

export const ConnectionOutreachStatus = {
    ConnectionCreated: 'Connection Created',
    ReadyForAmToStart: 'Ready For AM To Start',
    AmUnableToSupport: 'AM Unable To Support',
    AmNeedsMoreInformation: 'AM Needs More Information',
    ConnectionSetupCompleteByAm: 'Connection Setup Complete By AM',
    PortalCredentialsCreated: 'Portal Credentials Created'
} as const;

export type ConnectionOutreachStatus = typeof ConnectionOutreachStatus[keyof typeof ConnectionOutreachStatus];


/**
 * The RAL status of a connection.
 * @export
 * @enum {string}
 */

export const ConnectionRALStatus = {
    Send: 'Send',
    Resend: 'Resend',
    Received: 'Received',
    Approved: 'Approved'
} as const;

export type ConnectionRALStatus = typeof ConnectionRALStatus[keyof typeof ConnectionRALStatus];


/**
 * The status of a connection.
 * @export
 * @enum {string}
 */

export const ConnectionStatus = {
    UnderReview: 'Under Review',
    Pending: 'Pending',
    Importing: 'Importing',
    Authenticating: 'Authenticating',
    Syncing: 'Syncing',
    Active: 'Active',
    Error: 'Error'
} as const;

export type ConnectionStatus = typeof ConnectionStatus[keyof typeof ConnectionStatus];


/**
 * Contact roles.
 * @export
 * @enum {string}
 */

export const ContactRole = {
    Primary: 'primary',
    Direct: 'direct',
    Tax: 'tax',
    Legal: 'legal',
    Ir: 'ir',
    Other: 'other'
} as const;

export type ContactRole = typeof ContactRole[keyof typeof ContactRole];


/**
 * 
 * @export
 * @interface CreateAccount201Response
 */
export interface CreateAccount201Response {
    /**
     * 
     * @type {boolean}
     * @memberof CreateAccount201Response
     */
    'success'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof CreateAccount201Response
     */
    'account_id'?: string;
}
/**
 * 
 * @export
 * @interface CreateAccountRequest
 */
export interface CreateAccountRequest {
    /**
     * 
     * @type {string}
     * @memberof CreateAccountRequest
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof CreateAccountRequest
     */
    'account_type'?: CreateAccountRequestAccountTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof CreateAccountRequest
     */
    'id_type'?: CreateAccountRequestIdTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof CreateAccountRequest
     */
    'id_number'?: string;
    /**
     * 
     * @type {CreateAccountRequestAddress}
     * @memberof CreateAccountRequest
     */
    'address'?: CreateAccountRequestAddress;
    /**
     * 
     * @type {string}
     * @memberof CreateAccountRequest
     */
    'user': string;
}

export const CreateAccountRequestAccountTypeEnum = {
    Individual: 'individual',
    Joint: 'joint',
    Trust: 'trust',
    Ira: 'ira',
    Llc: 'llc',
    Partnership: 'partnership',
    Corporation: 'corporation',
    Other: 'other',
    Empty: ''
} as const;

export type CreateAccountRequestAccountTypeEnum = typeof CreateAccountRequestAccountTypeEnum[keyof typeof CreateAccountRequestAccountTypeEnum];
export const CreateAccountRequestIdTypeEnum = {
    Ssn: 'SSN',
    Ein: 'EIN',
    Tin: 'TIN',
    Empty: ''
} as const;

export type CreateAccountRequestIdTypeEnum = typeof CreateAccountRequestIdTypeEnum[keyof typeof CreateAccountRequestIdTypeEnum];

/**
 * 
 * @export
 * @interface CreateAccountRequestAddress
 */
export interface CreateAccountRequestAddress {
    /**
     * 
     * @type {string}
     * @memberof CreateAccountRequestAddress
     */
    'streetLine1'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateAccountRequestAddress
     */
    'streetLine2'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateAccountRequestAddress
     */
    'city'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateAccountRequestAddress
     */
    'state'?: CreateAccountRequestAddressStateEnum;
    /**
     * 
     * @type {string}
     * @memberof CreateAccountRequestAddress
     */
    'country'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateAccountRequestAddress
     */
    'zipCode'?: string;
}

export const CreateAccountRequestAddressStateEnum = {
    Al: 'AL',
    Ak: 'AK',
    Az: 'AZ',
    Ar: 'AR',
    Ca: 'CA',
    Co: 'CO',
    Ct: 'CT',
    De: 'DE',
    Fl: 'FL',
    Ga: 'GA',
    Hi: 'HI',
    Id: 'ID',
    Il: 'IL',
    In: 'IN',
    Ia: 'IA',
    Ks: 'KS',
    Ky: 'KY',
    La: 'LA',
    Me: 'ME',
    Md: 'MD',
    Ma: 'MA',
    Mi: 'MI',
    Mn: 'MN',
    Ms: 'MS',
    Mo: 'MO',
    Mt: 'MT',
    Ne: 'NE',
    Nv: 'NV',
    Nh: 'NH',
    Nj: 'NJ',
    Nm: 'NM',
    Ny: 'NY',
    Nc: 'NC',
    Nd: 'ND',
    Oh: 'OH',
    Ok: 'OK',
    Or: 'OR',
    Pa: 'PA',
    Ri: 'RI',
    Sc: 'SC',
    Sd: 'SD',
    Tn: 'TN',
    Tx: 'TX',
    Ut: 'UT',
    Vt: 'VT',
    Va: 'VA',
    Wa: 'WA',
    Wv: 'WV',
    Wi: 'WI',
    Wy: 'WY',
    Nl: 'NL',
    Pe: 'PE',
    Ns: 'NS',
    Nb: 'NB',
    Qc: 'QC',
    True: 'true',
    Mb: 'MB',
    Sk: 'SK',
    Ab: 'AB',
    Bc: 'BC',
    Yt: 'YT',
    Nt: 'NT',
    Nu: 'NU',
    Empty: ''
} as const;

export type CreateAccountRequestAddressStateEnum = typeof CreateAccountRequestAddressStateEnum[keyof typeof CreateAccountRequestAddressStateEnum];

/**
 * 
 * @export
 * @interface CreateAdminTeam201Response
 */
export interface CreateAdminTeam201Response {
    /**
     * 
     * @type {boolean}
     * @memberof CreateAdminTeam201Response
     */
    'success'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof CreateAdminTeam201Response
     */
    'admin_team_id'?: string;
}
/**
 * 
 * @export
 * @interface CreateAdminTeamRequest
 */
export interface CreateAdminTeamRequest {
    /**
     * 
     * @type {string}
     * @memberof CreateAdminTeamRequest
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof CreateAdminTeamRequest
     */
    'type': CreateAdminTeamRequestTypeEnum;
    /**
     * 
     * @type {Array<string>}
     * @memberof CreateAdminTeamRequest
     */
    'advisory_groups': Array<string>;
}

export const CreateAdminTeamRequestTypeEnum = {
    AccountManagement: 'account_management',
    Onboarding: 'onboarding'
} as const;

export type CreateAdminTeamRequestTypeEnum = typeof CreateAdminTeamRequestTypeEnum[keyof typeof CreateAdminTeamRequestTypeEnum];

/**
 * 
 * @export
 * @interface CreateCommitment201Response
 */
export interface CreateCommitment201Response {
    /**
     * 
     * @type {boolean}
     * @memberof CreateCommitment201Response
     */
    'success'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof CreateCommitment201Response
     */
    'commitment_id'?: string;
}
/**
 * 
 * @export
 * @interface CreateCommitmentRequest
 */
export interface CreateCommitmentRequest {
    /**
     * 
     * @type {string}
     * @memberof CreateCommitmentRequest
     */
    'date': string;
    /**
     * 
     * @type {number}
     * @memberof CreateCommitmentRequest
     */
    'amount': number;
    /**
     * 
     * @type {Array<string>}
     * @memberof CreateCommitmentRequest
     */
    'documents'?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof CreateCommitmentRequest
     */
    'investment': string;
}
/**
 * 
 * @export
 * @interface CreateContact201Response
 */
export interface CreateContact201Response {
    /**
     * 
     * @type {boolean}
     * @memberof CreateContact201Response
     */
    'success'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof CreateContact201Response
     */
    'contact_id'?: string;
}
/**
 * 
 * @export
 * @interface CreateContactRequest
 */
export interface CreateContactRequest {
    /**
     * 
     * @type {string}
     * @memberof CreateContactRequest
     */
    'first_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateContactRequest
     */
    'last_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateContactRequest
     */
    'email'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateContactRequest
     */
    'phone'?: string;
}
/**
 * 
 * @export
 * @interface CreateDefaultDocumentImportSetting201Response
 */
export interface CreateDefaultDocumentImportSetting201Response {
    /**
     * 
     * @type {boolean}
     * @memberof CreateDefaultDocumentImportSetting201Response
     */
    'success'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof CreateDefaultDocumentImportSetting201Response
     */
    'default_document_import_setting_id'?: string;
}
/**
 * 
 * @export
 * @interface CreateDefaultDocumentImportSettingRequest
 */
export interface CreateDefaultDocumentImportSettingRequest {
    /**
     * 
     * @type {string}
     * @memberof CreateDefaultDocumentImportSettingRequest
     */
    'integration'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateDefaultDocumentImportSettingRequest
     */
    'asset_manager'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateDefaultDocumentImportSettingRequest
     */
    'advisory_group'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateDefaultDocumentImportSettingRequest
     */
    'global_default_extraction_setting'?: CreateDefaultDocumentImportSettingRequestGlobalDefaultExtractionSettingEnum;
    /**
     * 
     * @type {string}
     * @memberof CreateDefaultDocumentImportSettingRequest
     */
    'global_default_auto_sort_setting'?: CreateDefaultDocumentImportSettingRequestGlobalDefaultAutoSortSettingEnum;
    /**
     * 
     * @type {string}
     * @memberof CreateDefaultDocumentImportSettingRequest
     */
    'global_default_transaction_setting'?: CreateDefaultDocumentImportSettingRequestGlobalDefaultTransactionSettingEnum;
    /**
     * 
     * @type {string}
     * @memberof CreateDefaultDocumentImportSettingRequest
     */
    'global_default_process_setting'?: CreateDefaultDocumentImportSettingRequestGlobalDefaultProcessSettingEnum;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof CreateDefaultDocumentImportSettingRequest
     */
    'default_transaction_settings'?: { [key: string]: string; };
}

export const CreateDefaultDocumentImportSettingRequestGlobalDefaultExtractionSettingEnum = {
    Enabled: 'enabled',
    Disabled: 'disabled',
    Unset: 'unset'
} as const;

export type CreateDefaultDocumentImportSettingRequestGlobalDefaultExtractionSettingEnum = typeof CreateDefaultDocumentImportSettingRequestGlobalDefaultExtractionSettingEnum[keyof typeof CreateDefaultDocumentImportSettingRequestGlobalDefaultExtractionSettingEnum];
export const CreateDefaultDocumentImportSettingRequestGlobalDefaultAutoSortSettingEnum = {
    Enabled: 'enabled',
    Disabled: 'disabled',
    Unset: 'unset'
} as const;

export type CreateDefaultDocumentImportSettingRequestGlobalDefaultAutoSortSettingEnum = typeof CreateDefaultDocumentImportSettingRequestGlobalDefaultAutoSortSettingEnum[keyof typeof CreateDefaultDocumentImportSettingRequestGlobalDefaultAutoSortSettingEnum];
export const CreateDefaultDocumentImportSettingRequestGlobalDefaultTransactionSettingEnum = {
    Enabled: 'enabled',
    Disabled: 'disabled',
    Unset: 'unset'
} as const;

export type CreateDefaultDocumentImportSettingRequestGlobalDefaultTransactionSettingEnum = typeof CreateDefaultDocumentImportSettingRequestGlobalDefaultTransactionSettingEnum[keyof typeof CreateDefaultDocumentImportSettingRequestGlobalDefaultTransactionSettingEnum];
export const CreateDefaultDocumentImportSettingRequestGlobalDefaultProcessSettingEnum = {
    Enabled: 'enabled',
    Disabled: 'disabled',
    Unset: 'unset'
} as const;

export type CreateDefaultDocumentImportSettingRequestGlobalDefaultProcessSettingEnum = typeof CreateDefaultDocumentImportSettingRequestGlobalDefaultProcessSettingEnum[keyof typeof CreateDefaultDocumentImportSettingRequestGlobalDefaultProcessSettingEnum];

/**
 * 
 * @export
 * @interface CreateDocument201Response
 */
export interface CreateDocument201Response {
    /**
     * 
     * @type {boolean}
     * @memberof CreateDocument201Response
     */
    'success'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof CreateDocument201Response
     */
    'document_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateDocument201Response
     */
    'upload_url'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateDocument201Response
     */
    'metadata_error'?: string;
}
/**
 * 
 * @export
 * @interface CreateDocumentRequest
 */
export interface CreateDocumentRequest {
    /**
     * 
     * @type {string}
     * @memberof CreateDocumentRequest
     */
    'date': string;
    /**
     * 
     * @type {string}
     * @memberof CreateDocumentRequest
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof CreateDocumentRequest
     */
    'type': string | null;
    /**
     * 
     * @type {ListDocuments200ResponseDocumentsInnerTraining}
     * @memberof CreateDocumentRequest
     */
    'training'?: ListDocuments200ResponseDocumentsInnerTraining;
    /**
     * 
     * @type {boolean}
     * @memberof CreateDocumentRequest
     */
    'shouldGenerateTransactions'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CreateDocumentRequest
     */
    'shouldProcessDocument'?: boolean;
    /**
     * 
     * @type {Array<ListDocuments200ResponseDocumentsInnerMetadataInner>}
     * @memberof CreateDocumentRequest
     */
    'metadata'?: Array<ListDocuments200ResponseDocumentsInnerMetadataInner>;
    /**
     * 
     * @type {Array<string>}
     * @memberof CreateDocumentRequest
     */
    'ownership_ids'?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof CreateDocumentRequest
     */
    'ownership_type'?: CreateDocumentRequestOwnershipTypeEnum | null;
    /**
     * 
     * @type {string}
     * @memberof CreateDocumentRequest
     */
    'file_type'?: CreateDocumentRequestFileTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof CreateDocumentRequest
     */
    'hash': string;
    /**
     * 
     * @type {string}
     * @memberof CreateDocumentRequest
     */
    'user': string;
}

export const CreateDocumentRequestOwnershipTypeEnum = {
    Account: 'Account',
    Investment: 'Investment',
    Null: 'null'
} as const;

export type CreateDocumentRequestOwnershipTypeEnum = typeof CreateDocumentRequestOwnershipTypeEnum[keyof typeof CreateDocumentRequestOwnershipTypeEnum];
export const CreateDocumentRequestFileTypeEnum = {
    Pdf: 'pdf',
    Csv: 'csv',
    Xlsx: 'xlsx',
    Xls: 'xls'
} as const;

export type CreateDocumentRequestFileTypeEnum = typeof CreateDocumentRequestFileTypeEnum[keyof typeof CreateDocumentRequestFileTypeEnum];

/**
 * 
 * @export
 * @interface CreateIntegration201Response
 */
export interface CreateIntegration201Response {
    /**
     * 
     * @type {boolean}
     * @memberof CreateIntegration201Response
     */
    'success'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof CreateIntegration201Response
     */
    'integration_id'?: string;
}
/**
 * 
 * @export
 * @interface CreateIntegrationRequest
 */
export interface CreateIntegrationRequest {
    /**
     * 
     * @type {string}
     * @memberof CreateIntegrationRequest
     */
    'name': string;
    /**
     * 
     * @type {boolean}
     * @memberof CreateIntegrationRequest
     */
    'has_worker'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CreateIntegrationRequest
     */
    'tfa_required'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CreateIntegrationRequest
     */
    'phone_required'?: boolean;
    /**
     * 
     * @type {Array<ListIntegrations200ResponseIntegrationsInnerRequiredCredsInner>}
     * @memberof CreateIntegrationRequest
     */
    'required_creds'?: Array<ListIntegrations200ResponseIntegrationsInnerRequiredCredsInner>;
    /**
     * 
     * @type {string}
     * @memberof CreateIntegrationRequest
     */
    'portal_url'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateIntegrationRequest
     */
    'lambda_arn'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateIntegrationRequest
     */
    'cycle_container_id'?: string;
    /**
     * 
     * @type {number}
     * @memberof CreateIntegrationRequest
     */
    'worker_version'?: number;
}
/**
 * 
 * @export
 * @interface CreateInvestment201Response
 */
export interface CreateInvestment201Response {
    /**
     * 
     * @type {boolean}
     * @memberof CreateInvestment201Response
     */
    'success'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof CreateInvestment201Response
     */
    'investment_id'?: string;
}
/**
 * 
 * @export
 * @interface CreateInvestmentRequest
 */
export interface CreateInvestmentRequest {
    /**
     * 
     * @type {string}
     * @memberof CreateInvestmentRequest
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof CreateInvestmentRequest
     */
    'type'?: string;
    /**
     * 
     * @type {{ [key: string]: any; }}
     * @memberof CreateInvestmentRequest
     */
    'data'?: { [key: string]: any; };
    /**
     * 
     * @type {number}
     * @memberof CreateInvestmentRequest
     */
    'expected_tax_documents'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof CreateInvestmentRequest
     */
    'hidden'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CreateInvestmentRequest
     */
    'exclude_from_billing'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof CreateInvestmentRequest
     */
    'currency'?: CreateInvestmentRequestCurrencyEnum;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof CreateInvestmentRequest
     */
    'attributes'?: { [key: string]: string; };
    /**
     * 
     * @type {string}
     * @memberof CreateInvestmentRequest
     */
    'account': string;
    /**
     * 
     * @type {string}
     * @memberof CreateInvestmentRequest
     */
    'connection'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateInvestmentRequest
     */
    'investment_master'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof CreateInvestmentRequest
     */
    'inactive'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof CreateInvestmentRequest
     */
    'inactive_date'?: string;
    /**
     * 
     * @type {{ [key: string]: any; }}
     * @memberof CreateInvestmentRequest
     */
    'investment_data'?: { [key: string]: any; };
}

export const CreateInvestmentRequestCurrencyEnum = {
    Usd: 'USD',
    Eur: 'EUR',
    Cny: 'CNY',
    Jpy: 'JPY',
    Gbp: 'GBP',
    Aud: 'AUD',
    Cad: 'CAD',
    Chf: 'CHF',
    Inr: 'INR',
    Krw: 'KRW',
    Btc: 'BTC'
} as const;

export type CreateInvestmentRequestCurrencyEnum = typeof CreateInvestmentRequestCurrencyEnum[keyof typeof CreateInvestmentRequestCurrencyEnum];

/**
 * 
 * @export
 * @interface CreateRALRequest201Response
 */
export interface CreateRALRequest201Response {
    /**
     * 
     * @type {boolean}
     * @memberof CreateRALRequest201Response
     */
    'success'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof CreateRALRequest201Response
     */
    'ral_request_id'?: string;
}
/**
 * 
 * @export
 * @interface CreateRALRequestRequest
 */
export interface CreateRALRequestRequest {
    /**
     * 
     * @type {Array<string>}
     * @memberof CreateRALRequestRequest
     */
    'ownership_ids'?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof CreateRALRequestRequest
     */
    'ownership_type'?: CreateRALRequestRequestOwnershipTypeEnum | null;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof CreateRALRequestRequest
     */
    'tokens'?: { [key: string]: string; };
    /**
     * 
     * @type {Array<CreateRALRequestRequestSignatoriesInner>}
     * @memberof CreateRALRequestRequest
     */
    'signatories'?: Array<CreateRALRequestRequestSignatoriesInner>;
    /**
     * 
     * @type {string}
     * @memberof CreateRALRequestRequest
     */
    'ral_panda_doc_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateRALRequestRequest
     */
    'ral_request_template': string;
    /**
     * 
     * @type {string}
     * @memberof CreateRALRequestRequest
     */
    'user': string;
    /**
     * 
     * @type {string}
     * @memberof CreateRALRequestRequest
     */
    'signed_ral'?: string;
}

export const CreateRALRequestRequestOwnershipTypeEnum = {
    Account: 'Account',
    Investment: 'Investment',
    Null: 'null'
} as const;

export type CreateRALRequestRequestOwnershipTypeEnum = typeof CreateRALRequestRequestOwnershipTypeEnum[keyof typeof CreateRALRequestRequestOwnershipTypeEnum];

/**
 * 
 * @export
 * @interface CreateRALRequestRequestSignatoriesInner
 */
export interface CreateRALRequestRequestSignatoriesInner {
    /**
     * 
     * @type {string}
     * @memberof CreateRALRequestRequestSignatoriesInner
     */
    'first_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateRALRequestRequestSignatoriesInner
     */
    'last_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateRALRequestRequestSignatoriesInner
     */
    'role'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateRALRequestRequestSignatoriesInner
     */
    'email'?: string;
}
/**
 * 
 * @export
 * @interface CreateTransaction201Response
 */
export interface CreateTransaction201Response {
    /**
     * 
     * @type {boolean}
     * @memberof CreateTransaction201Response
     */
    'success'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof CreateTransaction201Response
     */
    'transaction_id'?: string;
}
/**
 * 
 * @export
 * @interface CreateTransactionRequest
 */
export interface CreateTransactionRequest {
    /**
     * 
     * @type {string}
     * @memberof CreateTransactionRequest
     */
    'type': string;
    /**
     * 
     * @type {string}
     * @memberof CreateTransactionRequest
     */
    'date': string;
    /**
     * 
     * @type {number}
     * @memberof CreateTransactionRequest
     */
    'amount': number;
    /**
     * 
     * @type {number}
     * @memberof CreateTransactionRequest
     */
    'units'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof CreateTransactionRequest
     */
    'open'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof CreateTransactionRequest
     */
    'description'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateTransactionRequest
     */
    'joint_transaction'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateTransactionRequest
     */
    'document'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateTransactionRequest
     */
    'user'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateTransactionRequest
     */
    'investment': string;
}
/**
 * 
 * @export
 * @interface CreateValuation201Response
 */
export interface CreateValuation201Response {
    /**
     * 
     * @type {boolean}
     * @memberof CreateValuation201Response
     */
    'success'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof CreateValuation201Response
     */
    'valuation_id'?: string;
}
/**
 * 
 * @export
 * @interface CreateValuationRequest
 */
export interface CreateValuationRequest {
    /**
     * 
     * @type {string}
     * @memberof CreateValuationRequest
     */
    'date': string;
    /**
     * 
     * @type {number}
     * @memberof CreateValuationRequest
     */
    'amount': number;
    /**
     * 
     * @type {number}
     * @memberof CreateValuationRequest
     */
    'unit_price'?: number;
    /**
     * 
     * @type {string}
     * @memberof CreateValuationRequest
     */
    'investment': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof CreateValuationRequest
     */
    'documents'?: Array<string>;
}
/**
 * Credential field types.
 * @export
 * @enum {string}
 */

export const CredentialFieldType = {
    Text: 'text',
    Password: 'password',
    Select: 'select',
    Checkbox: 'checkbox'
} as const;

export type CredentialFieldType = typeof CredentialFieldType[keyof typeof CredentialFieldType];


/**
 * 
 * @export
 * @interface DeleteAccount200Response
 */
export interface DeleteAccount200Response {
    /**
     * 
     * @type {boolean}
     * @memberof DeleteAccount200Response
     */
    'success'?: boolean;
}
/**
 * Document types.
 * @export
 * @enum {string}
 */

export const DocumentType = {
    CapitalAccountStatement: 'Capital Account Statement',
    CapitalCallNotice: 'Capital Call Notice',
    DistributionNotice: 'Distribution Notice',
    TaxDocument: 'Tax Document',
    Other: 'Other',
    NetCapitalCall: 'Net Capital Call',
    NetDistribution: 'Net Distribution',
    HedgeFund: 'Hedge Fund',
    PpmOm: 'PPM/OM',
    PrivacyNotice: 'Privacy Notice',
    DisclosureAdv: 'Disclosure / ADV',
    InvestmentUpdate: 'Investment Update',
    LegalAgreement: 'Legal Agreement',
    WireInstructionConfirmation: 'Wire Instruction / Confirmation',
    ReportingAuthorizationLetter: 'Reporting Authorization Letter'
} as const;

export type DocumentType = typeof DocumentType[keyof typeof DocumentType];


/**
 * 
 * @export
 * @enum {string}
 */

export const DocumentTypes = {
    CapitalAccountStatement: 'Capital Account Statement',
    CapitalCallNotice: 'Capital Call Notice',
    DistributionNotice: 'Distribution Notice',
    TaxDocument: 'Tax Document',
    Other: 'Other',
    NetCapitalCall: 'Net Capital Call',
    NetDistribution: 'Net Distribution',
    HedgeFund: 'Hedge Fund',
    PpmOm: 'PPM/OM',
    PrivacyNotice: 'Privacy Notice',
    DisclosureAdv: 'Disclosure / ADV',
    InvestmentUpdate: 'Investment Update',
    LegalAgreement: 'Legal Agreement',
    WireInstructionConfirmation: 'Wire Instruction / Confirmation',
    ReportingAuthorizationLetter: 'Reporting Authorization Letter'
} as const;

export type DocumentTypes = typeof DocumentTypes[keyof typeof DocumentTypes];


/**
 * The exit status of a connection sync.
 * @export
 * @enum {string}
 */

export const ExitStatus = {
    Success: 'success',
    Failure: 'failure',
    Pending: 'pending',
    Queued: 'queued'
} as const;

export type ExitStatus = typeof ExitStatus[keyof typeof ExitStatus];


/**
 * File types.
 * @export
 * @enum {string}
 */

export const FileType = {
    Pdf: 'pdf',
    Csv: 'csv',
    Xlsx: 'xlsx',
    Xls: 'xls'
} as const;

export type FileType = typeof FileType[keyof typeof FileType];


/**
 * 
 * @export
 * @interface FinishOAuthRequest
 */
export interface FinishOAuthRequest {
    /**
     * 
     * @type {string}
     * @memberof FinishOAuthRequest
     */
    'code': string;
}
/**
 * 
 * @export
 * @interface GeneratePandaDocSigningUrls200Response
 */
export interface GeneratePandaDocSigningUrls200Response {
    /**
     * 
     * @type {boolean}
     * @memberof GeneratePandaDocSigningUrls200Response
     */
    'success'?: boolean;
    /**
     * 
     * @type {{ [key: string]: Array<GeneratePandaDocSigningUrls200ResponseDataValueInner>; }}
     * @memberof GeneratePandaDocSigningUrls200Response
     */
    'data'?: { [key: string]: Array<GeneratePandaDocSigningUrls200ResponseDataValueInner>; };
}
/**
 * 
 * @export
 * @interface GeneratePandaDocSigningUrls200ResponseDataValueInner
 */
export interface GeneratePandaDocSigningUrls200ResponseDataValueInner {
    /**
     * 
     * @type {string}
     * @memberof GeneratePandaDocSigningUrls200ResponseDataValueInner
     */
    'email'?: string;
    /**
     * 
     * @type {string}
     * @memberof GeneratePandaDocSigningUrls200ResponseDataValueInner
     */
    'first_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof GeneratePandaDocSigningUrls200ResponseDataValueInner
     */
    'last_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof GeneratePandaDocSigningUrls200ResponseDataValueInner
     */
    'role'?: string;
    /**
     * 
     * @type {string}
     * @memberof GeneratePandaDocSigningUrls200ResponseDataValueInner
     */
    'signing_url'?: string;
}
/**
 * 
 * @export
 * @interface GetAccount200Response
 */
export interface GetAccount200Response {
    /**
     * 
     * @type {boolean}
     * @memberof GetAccount200Response
     */
    'success'?: boolean;
    /**
     * 
     * @type {ListAccounts200ResponseAccountsInner}
     * @memberof GetAccount200Response
     */
    'account'?: ListAccounts200ResponseAccountsInner;
}
/**
 * 
 * @export
 * @interface GetActivityCounts200Response
 */
export interface GetActivityCounts200Response {
    /**
     * 
     * @type {boolean}
     * @memberof GetActivityCounts200Response
     */
    'success'?: boolean;
    /**
     * 
     * @type {Array<GetActivityCounts200ResponseDataInner>}
     * @memberof GetActivityCounts200Response
     */
    'data'?: Array<GetActivityCounts200ResponseDataInner>;
}
/**
 * 
 * @export
 * @interface GetActivityCounts200ResponseDataInner
 */
export interface GetActivityCounts200ResponseDataInner {
    /**
     * 
     * @type {string}
     * @memberof GetActivityCounts200ResponseDataInner
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetActivityCounts200ResponseDataInner
     */
    'email'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetActivityCounts200ResponseDataInner
     */
    'id'?: string;
    /**
     * 
     * @type {number}
     * @memberof GetActivityCounts200ResponseDataInner
     */
    'messages_completed'?: number;
    /**
     * 
     * @type {number}
     * @memberof GetActivityCounts200ResponseDataInner
     */
    'messages_changed'?: number;
    /**
     * 
     * @type {number}
     * @memberof GetActivityCounts200ResponseDataInner
     */
    'documents_changed'?: number;
    /**
     * 
     * @type {number}
     * @memberof GetActivityCounts200ResponseDataInner
     */
    'extracts_changed'?: number;
    /**
     * 
     * @type {number}
     * @memberof GetActivityCounts200ResponseDataInner
     */
    'sorts_changed'?: number;
}
/**
 * 
 * @export
 * @interface GetAdminTeam200Response
 */
export interface GetAdminTeam200Response {
    /**
     * 
     * @type {boolean}
     * @memberof GetAdminTeam200Response
     */
    'success'?: boolean;
    /**
     * 
     * @type {ListAdminTeams200ResponseAdminTeamsInner}
     * @memberof GetAdminTeam200Response
     */
    'admin_team'?: ListAdminTeams200ResponseAdminTeamsInner;
}
/**
 * 
 * @export
 * @interface GetCommitment200Response
 */
export interface GetCommitment200Response {
    /**
     * 
     * @type {boolean}
     * @memberof GetCommitment200Response
     */
    'success'?: boolean;
    /**
     * 
     * @type {ListCommitments200ResponseCommitmentsInner}
     * @memberof GetCommitment200Response
     */
    'commitment'?: ListCommitments200ResponseCommitmentsInner;
}
/**
 * 
 * @export
 * @interface GetDefaultDocumentImportSetting200Response
 */
export interface GetDefaultDocumentImportSetting200Response {
    /**
     * 
     * @type {boolean}
     * @memberof GetDefaultDocumentImportSetting200Response
     */
    'success'?: boolean;
    /**
     * 
     * @type {ListDefaultDocumentImportSettings200ResponseDefaultDocumentImportSettingsInner}
     * @memberof GetDefaultDocumentImportSetting200Response
     */
    'default_document_import_setting'?: ListDefaultDocumentImportSettings200ResponseDefaultDocumentImportSettingsInner;
}
/**
 * 
 * @export
 * @interface GetDocument200Response
 */
export interface GetDocument200Response {
    /**
     * 
     * @type {boolean}
     * @memberof GetDocument200Response
     */
    'success'?: boolean;
    /**
     * 
     * @type {ListDocuments200ResponseDocumentsInner}
     * @memberof GetDocument200Response
     */
    'document'?: ListDocuments200ResponseDocumentsInner;
}
/**
 * 
 * @export
 * @interface GetIntegration200Response
 */
export interface GetIntegration200Response {
    /**
     * 
     * @type {boolean}
     * @memberof GetIntegration200Response
     */
    'success'?: boolean;
    /**
     * 
     * @type {ListIntegrations200ResponseIntegrationsInner}
     * @memberof GetIntegration200Response
     */
    'integration'?: ListIntegrations200ResponseIntegrationsInner;
}
/**
 * 
 * @export
 * @interface GetInvestment200Response
 */
export interface GetInvestment200Response {
    /**
     * 
     * @type {boolean}
     * @memberof GetInvestment200Response
     */
    'success'?: boolean;
    /**
     * 
     * @type {ListInvestments200ResponseInvestmentsInner}
     * @memberof GetInvestment200Response
     */
    'investment'?: ListInvestments200ResponseInvestmentsInner;
}
/**
 * 
 * @export
 * @interface GetMessageById200Response
 */
export interface GetMessageById200Response {
    /**
     * 
     * @type {boolean}
     * @memberof GetMessageById200Response
     */
    'success'?: boolean;
    /**
     * 
     * @type {GetMessageById200ResponseMessage}
     * @memberof GetMessageById200Response
     */
    'message'?: GetMessageById200ResponseMessage;
}
/**
 * 
 * @export
 * @interface GetMessageById200ResponseMessage
 */
export interface GetMessageById200ResponseMessage {
    /**
     * 
     * @type {string}
     * @memberof GetMessageById200ResponseMessage
     */
    '_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetMessageById200ResponseMessage
     */
    'type'?: string;
    /**
     * 
     * @type {ListConnectionSyncs200ResponseConnectionsyncsInnerConnectionUser}
     * @memberof GetMessageById200ResponseMessage
     */
    'connection'?: ListConnectionSyncs200ResponseConnectionsyncsInnerConnectionUser;
    /**
     * 
     * @type {boolean}
     * @memberof GetMessageById200ResponseMessage
     */
    'verified_sender'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof GetMessageById200ResponseMessage
     */
    'date'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetMessageById200ResponseMessage
     */
    'from'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetMessageById200ResponseMessage
     */
    'to'?: string;
    /**
     * 
     * @type {ListConnectionSyncs200ResponseConnectionsyncsInnerConnectionUser}
     * @memberof GetMessageById200ResponseMessage
     */
    'account'?: ListConnectionSyncs200ResponseConnectionsyncsInnerConnectionUser;
    /**
     * 
     * @type {ListInvestments200ResponseInvestmentsInnerUser}
     * @memberof GetMessageById200ResponseMessage
     */
    'user'?: ListInvestments200ResponseInvestmentsInnerUser;
    /**
     * 
     * @type {string}
     * @memberof GetMessageById200ResponseMessage
     */
    'subject'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetMessageById200ResponseMessage
     */
    'body'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof GetMessageById200ResponseMessage
     */
    'parsed'?: boolean;
    /**
     * 
     * @type {Array<GetMessageById200ResponseMessageAttachmentsInner>}
     * @memberof GetMessageById200ResponseMessage
     */
    'attachments'?: Array<GetMessageById200ResponseMessageAttachmentsInner>;
    /**
     * 
     * @type {GetMessageById200ResponseMessageEmailData}
     * @memberof GetMessageById200ResponseMessage
     */
    'email_data'?: GetMessageById200ResponseMessageEmailData;
    /**
     * 
     * @type {GetMessageById200ResponseMessageMetadata}
     * @memberof GetMessageById200ResponseMessage
     */
    'metadata'?: GetMessageById200ResponseMessageMetadata;
    /**
     * 
     * @type {boolean}
     * @memberof GetMessageById200ResponseMessage
     */
    'completed'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof GetMessageById200ResponseMessage
     */
    'category'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetMessageById200ResponseMessage
     */
    'updated_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetMessageById200ResponseMessage
     */
    'created_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetMessageById200ResponseMessage
     */
    'deleted_at'?: string;
}
/**
 * 
 * @export
 * @interface GetMessageById200ResponseMessageAttachmentsInner
 */
export interface GetMessageById200ResponseMessageAttachmentsInner {
    /**
     * 
     * @type {string}
     * @memberof GetMessageById200ResponseMessageAttachmentsInner
     */
    'presigned_url'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetMessageById200ResponseMessageAttachmentsInner
     */
    'filename'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetMessageById200ResponseMessageAttachmentsInner
     */
    'mimetype'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetMessageById200ResponseMessageAttachmentsInner
     */
    'key'?: string;
}
/**
 * 
 * @export
 * @interface GetMessageById200ResponseMessageEmailData
 */
export interface GetMessageById200ResponseMessageEmailData {
    /**
     * 
     * @type {object}
     * @memberof GetMessageById200ResponseMessageEmailData
     */
    'envelope'?: object;
    /**
     * 
     * @type {object}
     * @memberof GetMessageById200ResponseMessageEmailData
     */
    'html_format'?: object;
    /**
     * 
     * @type {object}
     * @memberof GetMessageById200ResponseMessageEmailData
     */
    'raw_to'?: object;
    /**
     * 
     * @type {object}
     * @memberof GetMessageById200ResponseMessageEmailData
     */
    'raw_from'?: object;
    /**
     * 
     * @type {object}
     * @memberof GetMessageById200ResponseMessageEmailData
     */
    'raw_headers'?: object;
    /**
     * 
     * @type {object}
     * @memberof GetMessageById200ResponseMessageEmailData
     */
    'cc'?: object;
    /**
     * 
     * @type {object}
     * @memberof GetMessageById200ResponseMessageEmailData
     */
    'spam_score'?: object;
    /**
     * 
     * @type {object}
     * @memberof GetMessageById200ResponseMessageEmailData
     */
    'spam_report'?: object;
    /**
     * 
     * @type {object}
     * @memberof GetMessageById200ResponseMessageEmailData
     */
    'dkim'?: object;
    /**
     * 
     * @type {object}
     * @memberof GetMessageById200ResponseMessageEmailData
     */
    'errors'?: object;
    /**
     * 
     * @type {object}
     * @memberof GetMessageById200ResponseMessageEmailData
     */
    'attachment_info'?: object;
    /**
     * 
     * @type {object}
     * @memberof GetMessageById200ResponseMessageEmailData
     */
    'html'?: object;
    /**
     * 
     * @type {object}
     * @memberof GetMessageById200ResponseMessageEmailData
     */
    'text'?: object;
}
/**
 * 
 * @export
 * @interface GetMessageById200ResponseMessageMetadata
 */
export interface GetMessageById200ResponseMessageMetadata {
    /**
     * 
     * @type {GetMessageById200ResponseMessageMetadataAdmin}
     * @memberof GetMessageById200ResponseMessageMetadata
     */
    'admin'?: GetMessageById200ResponseMessageMetadataAdmin;
    /**
     * 
     * @type {GetMessageById200ResponseMessageMetadataAdmin}
     * @memberof GetMessageById200ResponseMessageMetadata
     */
    'advisor'?: GetMessageById200ResponseMessageMetadataAdmin;
    /**
     * 
     * @type {GetMessageById200ResponseMessageMetadataAdmin}
     * @memberof GetMessageById200ResponseMessageMetadata
     */
    'investor'?: GetMessageById200ResponseMessageMetadataAdmin;
}
/**
 * 
 * @export
 * @interface GetMessageById200ResponseMessageMetadataAdmin
 */
export interface GetMessageById200ResponseMessageMetadataAdmin {
    /**
     * 
     * @type {boolean}
     * @memberof GetMessageById200ResponseMessageMetadataAdmin
     */
    'flagged'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof GetMessageById200ResponseMessageMetadataAdmin
     */
    'flagged_at'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof GetMessageById200ResponseMessageMetadataAdmin
     */
    'read'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof GetMessageById200ResponseMessageMetadataAdmin
     */
    'read_at'?: string;
}
/**
 * 
 * @export
 * @interface GetRALRequest200Response
 */
export interface GetRALRequest200Response {
    /**
     * 
     * @type {boolean}
     * @memberof GetRALRequest200Response
     */
    'success'?: boolean;
    /**
     * 
     * @type {ListRALRequests200ResponseRalRequestsInner}
     * @memberof GetRALRequest200Response
     */
    'ral_request'?: ListRALRequests200ResponseRalRequestsInner;
}
/**
 * 
 * @export
 * @interface GetStatementHistory200Response
 */
export interface GetStatementHistory200Response {
    /**
     * 
     * @type {boolean}
     * @memberof GetStatementHistory200Response
     */
    'success'?: boolean;
    /**
     * 
     * @type {Array<GetStatementHistory200ResponseStatementsInner>}
     * @memberof GetStatementHistory200Response
     */
    'statements'?: Array<GetStatementHistory200ResponseStatementsInner>;
}
/**
 * 
 * @export
 * @interface GetStatementHistory200ResponseStatementsInner
 */
export interface GetStatementHistory200ResponseStatementsInner {
    /**
     * 
     * @type {string}
     * @memberof GetStatementHistory200ResponseStatementsInner
     */
    '_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetStatementHistory200ResponseStatementsInner
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetStatementHistory200ResponseStatementsInner
     */
    'type'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetStatementHistory200ResponseStatementsInner
     */
    'date'?: string;
    /**
     * 
     * @type {GetStatementHistory200ResponseStatementsInnerMetadata}
     * @memberof GetStatementHistory200ResponseStatementsInner
     */
    'metadata'?: GetStatementHistory200ResponseStatementsInnerMetadata;
}
/**
 * 
 * @export
 * @interface GetStatementHistory200ResponseStatementsInnerMetadata
 */
export interface GetStatementHistory200ResponseStatementsInnerMetadata {
    /**
     * 
     * @type {string}
     * @memberof GetStatementHistory200ResponseStatementsInnerMetadata
     */
    'period_start'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetStatementHistory200ResponseStatementsInnerMetadata
     */
    'period_end'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetStatementHistory200ResponseStatementsInnerMetadata
     */
    'beginning_balance'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetStatementHistory200ResponseStatementsInnerMetadata
     */
    'ending_balance'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetStatementHistory200ResponseStatementsInnerMetadata
     */
    'total_committed'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetStatementHistory200ResponseStatementsInnerMetadata
     */
    'management_fee'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetStatementHistory200ResponseStatementsInnerMetadata
     */
    'carry'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetStatementHistory200ResponseStatementsInnerMetadata
     */
    'management_fee_rebate'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetStatementHistory200ResponseStatementsInnerMetadata
     */
    'contributions'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetStatementHistory200ResponseStatementsInnerMetadata
     */
    'profit_distribution'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetStatementHistory200ResponseStatementsInnerMetadata
     */
    'contributions_date'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetStatementHistory200ResponseStatementsInnerMetadata
     */
    'withdrawals_date'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetStatementHistory200ResponseStatementsInnerMetadata
     */
    'profit_distribution_date'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetStatementHistory200ResponseStatementsInnerMetadata
     */
    'principal_distribution'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetStatementHistory200ResponseStatementsInnerMetadata
     */
    'recallable_profit_distribution'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetStatementHistory200ResponseStatementsInnerMetadata
     */
    'recallable_principal_distribution'?: string;
}
/**
 * 
 * @export
 * @interface GetStats200Response
 */
export interface GetStats200Response {
    /**
     * 
     * @type {Array<GetStats200ResponseStatsInner>}
     * @memberof GetStats200Response
     */
    'stats'?: Array<GetStats200ResponseStatsInner>;
}
/**
 * 
 * @export
 * @interface GetStats200ResponseStatsInner
 */
export interface GetStats200ResponseStatsInner {
    /**
     * 
     * @type {string}
     * @memberof GetStats200ResponseStatsInner
     */
    'outreach_status'?: string;
    /**
     * 
     * @type {number}
     * @memberof GetStats200ResponseStatsInner
     */
    'count'?: number;
}
/**
 * 
 * @export
 * @interface GetTransaction200Response
 */
export interface GetTransaction200Response {
    /**
     * 
     * @type {boolean}
     * @memberof GetTransaction200Response
     */
    'success'?: boolean;
    /**
     * 
     * @type {ListTransactions200ResponseTransactionsInner}
     * @memberof GetTransaction200Response
     */
    'transaction'?: ListTransactions200ResponseTransactionsInner;
}
/**
 * 
 * @export
 * @interface GetValuation200Response
 */
export interface GetValuation200Response {
    /**
     * 
     * @type {boolean}
     * @memberof GetValuation200Response
     */
    'success'?: boolean;
    /**
     * 
     * @type {ListValuations200ResponseValuationsInner}
     * @memberof GetValuation200Response
     */
    'valuation'?: ListValuations200ResponseValuationsInner;
}
/**
 * 
 * @export
 * @interface ImportConnectionAccountRequest
 */
export interface ImportConnectionAccountRequest {
    /**
     * 
     * @type {string}
     * @memberof ImportConnectionAccountRequest
     */
    'user_id_override'?: string;
}
/**
 * 
 * @export
 * @interface ImportConnectionInvestmentRequest
 */
export interface ImportConnectionInvestmentRequest {
    /**
     * 
     * @type {string}
     * @memberof ImportConnectionInvestmentRequest
     */
    'account_id_override'?: string;
}
/**
 * The status of an investment.
 * @export
 * @enum {string}
 */

export const InvestmentStatuses = {
    Fundraising: 'fundraising',
    Investing: 'investing',
    Harvesting: 'harvesting',
    Liquidated: 'liquidated'
} as const;

export type InvestmentStatuses = typeof InvestmentStatuses[keyof typeof InvestmentStatuses];


/**
 * 
 * @export
 * @enum {string}
 */

export const InvestmentTypes = {
    DiverseInvestments: 'Diverse Investments',
    PrivateEquityFund: 'Private Equity Fund',
    PrivateCreditFund: 'Private Credit Fund',
    VentureCapitalFund: 'Venture Capital Fund',
    FundOfFunds: 'Fund of Funds',
    SecondaryFund: 'Secondary Fund',
    RealEstateFund: 'Real Estate Fund',
    CoinvestmentFund: 'Coinvestment Fund',
    RealAssetsFund: 'Real Assets Fund',
    HedgeFund: 'Hedge Fund',
    Collectible: 'Collectible',
    PrivateCompanyStartup: 'Private Company / Startup',
    RealEstate: 'Real Estate',
    RealAssetsDirectOwned: 'Real Assets (Direct Owned)',
    BondLoan: 'Bond / Loan',
    Crypto: 'Crypto'
} as const;

export type InvestmentTypes = typeof InvestmentTypes[keyof typeof InvestmentTypes];


/**
 * 
 * @export
 * @interface ListAccounts200Response
 */
export interface ListAccounts200Response {
    /**
     * 
     * @type {boolean}
     * @memberof ListAccounts200Response
     */
    'success'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof ListAccounts200Response
     */
    'total'?: number;
    /**
     * 
     * @type {Array<ListAccounts200ResponseAccountsInner>}
     * @memberof ListAccounts200Response
     */
    'accounts'?: Array<ListAccounts200ResponseAccountsInner>;
}
/**
 * 
 * @export
 * @interface ListAccounts200ResponseAccountsInner
 */
export interface ListAccounts200ResponseAccountsInner {
    /**
     * 
     * @type {string}
     * @memberof ListAccounts200ResponseAccountsInner
     */
    '_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof ListAccounts200ResponseAccountsInner
     */
    'name'?: string;
    /**
     * 
     * @type {ListAccounts200ResponseAccountsInnerUser}
     * @memberof ListAccounts200ResponseAccountsInner
     */
    'user'?: ListAccounts200ResponseAccountsInnerUser;
    /**
     * 
     * @type {Array<ListAccounts200ResponseAccountsInnerAuditLogInner>}
     * @memberof ListAccounts200ResponseAccountsInner
     */
    'audit_log'?: Array<ListAccounts200ResponseAccountsInnerAuditLogInner>;
    /**
     * 
     * @type {number}
     * @memberof ListAccounts200ResponseAccountsInner
     */
    'investments_count'?: number;
    /**
     * 
     * @type {ListAccounts200ResponseAccountsInnerAddress}
     * @memberof ListAccounts200ResponseAccountsInner
     */
    'address'?: ListAccounts200ResponseAccountsInnerAddress;
    /**
     * 
     * @type {object}
     * @memberof ListAccounts200ResponseAccountsInner
     */
    'performance'?: object;
    /**
     * 
     * @type {string}
     * @memberof ListAccounts200ResponseAccountsInner
     */
    'account_type'?: string;
    /**
     * 
     * @type {string}
     * @memberof ListAccounts200ResponseAccountsInner
     */
    'id_type'?: string;
    /**
     * 
     * @type {string}
     * @memberof ListAccounts200ResponseAccountsInner
     */
    'id_number'?: string;
    /**
     * 
     * @type {string}
     * @memberof ListAccounts200ResponseAccountsInner
     */
    'created_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof ListAccounts200ResponseAccountsInner
     */
    'updated_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof ListAccounts200ResponseAccountsInner
     */
    'deleted_at'?: string;
}
/**
 * 
 * @export
 * @interface ListAccounts200ResponseAccountsInnerAddress
 */
export interface ListAccounts200ResponseAccountsInnerAddress {
    /**
     * 
     * @type {string}
     * @memberof ListAccounts200ResponseAccountsInnerAddress
     */
    'streetLine1'?: string;
    /**
     * 
     * @type {string}
     * @memberof ListAccounts200ResponseAccountsInnerAddress
     */
    'streetLine2'?: string;
    /**
     * 
     * @type {string}
     * @memberof ListAccounts200ResponseAccountsInnerAddress
     */
    'city'?: string;
    /**
     * 
     * @type {string}
     * @memberof ListAccounts200ResponseAccountsInnerAddress
     */
    'state'?: string;
    /**
     * 
     * @type {string}
     * @memberof ListAccounts200ResponseAccountsInnerAddress
     */
    'country'?: string;
    /**
     * 
     * @type {string}
     * @memberof ListAccounts200ResponseAccountsInnerAddress
     */
    'zipCode'?: string;
}
/**
 * 
 * @export
 * @interface ListAccounts200ResponseAccountsInnerAuditLogInner
 */
export interface ListAccounts200ResponseAccountsInnerAuditLogInner {
    /**
     * 
     * @type {string}
     * @memberof ListAccounts200ResponseAccountsInnerAuditLogInner
     */
    'action'?: string;
    /**
     * 
     * @type {string}
     * @memberof ListAccounts200ResponseAccountsInnerAuditLogInner
     */
    'target'?: string;
    /**
     * 
     * @type {string}
     * @memberof ListAccounts200ResponseAccountsInnerAuditLogInner
     */
    'target_type'?: string;
    /**
     * 
     * @type {any}
     * @memberof ListAccounts200ResponseAccountsInnerAuditLogInner
     */
    'initiator'?: any;
    /**
     * 
     * @type {string}
     * @memberof ListAccounts200ResponseAccountsInnerAuditLogInner
     */
    'initiator_type'?: string;
    /**
     * 
     * @type {string}
     * @memberof ListAccounts200ResponseAccountsInnerAuditLogInner
     */
    'timestamp'?: string;
    /**
     * 
     * @type {ListAccounts200ResponseAccountsInnerAuditLogInnerActionDetails}
     * @memberof ListAccounts200ResponseAccountsInnerAuditLogInner
     */
    'action_details'?: ListAccounts200ResponseAccountsInnerAuditLogInnerActionDetails;
}
/**
 * 
 * @export
 * @interface ListAccounts200ResponseAccountsInnerAuditLogInnerActionDetails
 */
export interface ListAccounts200ResponseAccountsInnerAuditLogInnerActionDetails {
    /**
     * 
     * @type {boolean}
     * @memberof ListAccounts200ResponseAccountsInnerAuditLogInnerActionDetails
     */
    'login_success'?: boolean;
    /**
     * 
     * @type {Array<ListAccounts200ResponseAccountsInnerAuditLogInnerActionDetailsFieldModificationsInner>}
     * @memberof ListAccounts200ResponseAccountsInnerAuditLogInnerActionDetails
     */
    'field_modifications'?: Array<ListAccounts200ResponseAccountsInnerAuditLogInnerActionDetailsFieldModificationsInner>;
}
/**
 * 
 * @export
 * @interface ListAccounts200ResponseAccountsInnerAuditLogInnerActionDetailsFieldModificationsInner
 */
export interface ListAccounts200ResponseAccountsInnerAuditLogInnerActionDetailsFieldModificationsInner {
    /**
     * 
     * @type {string}
     * @memberof ListAccounts200ResponseAccountsInnerAuditLogInnerActionDetailsFieldModificationsInner
     */
    'field'?: string;
    /**
     * 
     * @type {any}
     * @memberof ListAccounts200ResponseAccountsInnerAuditLogInnerActionDetailsFieldModificationsInner
     */
    'old_value'?: any;
    /**
     * 
     * @type {any}
     * @memberof ListAccounts200ResponseAccountsInnerAuditLogInnerActionDetailsFieldModificationsInner
     */
    'new_value'?: any;
}
/**
 * 
 * @export
 * @interface ListAccounts200ResponseAccountsInnerUser
 */
export interface ListAccounts200ResponseAccountsInnerUser {
    /**
     * 
     * @type {string}
     * @memberof ListAccounts200ResponseAccountsInnerUser
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof ListAccounts200ResponseAccountsInnerUser
     */
    'email'?: string;
}
/**
 * 
 * @export
 * @interface ListAccounts400Response
 */
export interface ListAccounts400Response {
    [key: string]: any;

    /**
     * 
     * @type {string}
     * @memberof ListAccounts400Response
     */
    'request_id'?: string;
    /**
     * 
     * @type {number}
     * @memberof ListAccounts400Response
     */
    'status_code'?: number;
    /**
     * 
     * @type {string}
     * @memberof ListAccounts400Response
     */
    'error'?: string;
    /**
     * 
     * @type {string}
     * @memberof ListAccounts400Response
     */
    'message'?: string;
}
/**
 * 
 * @export
 * @interface ListAccounts401Response
 */
export interface ListAccounts401Response {
    /**
     * 
     * @type {string}
     * @memberof ListAccounts401Response
     */
    'request_id'?: string;
    /**
     * 
     * @type {number}
     * @memberof ListAccounts401Response
     */
    'status_code'?: number;
    /**
     * 
     * @type {string}
     * @memberof ListAccounts401Response
     */
    'error'?: string;
    /**
     * 
     * @type {string}
     * @memberof ListAccounts401Response
     */
    'message'?: string;
}
/**
 * 
 * @export
 * @interface ListAccounts409Response
 */
export interface ListAccounts409Response {
    [key: string]: any;

    /**
     * 
     * @type {any}
     * @memberof ListAccounts409Response
     */
    'request_id'?: any;
    /**
     * 
     * @type {any}
     * @memberof ListAccounts409Response
     */
    'status_code'?: any;
    /**
     * 
     * @type {any}
     * @memberof ListAccounts409Response
     */
    'error'?: any;
    /**
     * 
     * @type {any}
     * @memberof ListAccounts409Response
     */
    'message'?: any;
}
/**
 * 
 * @export
 * @interface ListAdminTeams200Response
 */
export interface ListAdminTeams200Response {
    /**
     * 
     * @type {boolean}
     * @memberof ListAdminTeams200Response
     */
    'success'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof ListAdminTeams200Response
     */
    'total'?: number;
    /**
     * 
     * @type {Array<ListAdminTeams200ResponseAdminTeamsInner>}
     * @memberof ListAdminTeams200Response
     */
    'admin_teams'?: Array<ListAdminTeams200ResponseAdminTeamsInner>;
}
/**
 * 
 * @export
 * @interface ListAdminTeams200ResponseAdminTeamsInner
 */
export interface ListAdminTeams200ResponseAdminTeamsInner {
    /**
     * 
     * @type {string}
     * @memberof ListAdminTeams200ResponseAdminTeamsInner
     */
    '_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof ListAdminTeams200ResponseAdminTeamsInner
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof ListAdminTeams200ResponseAdminTeamsInner
     */
    'type'?: ListAdminTeams200ResponseAdminTeamsInnerTypeEnum;
    /**
     * 
     * @type {Array<ListAdminTeams200ResponseAdminTeamsInnerAdvisoryGroupsInner>}
     * @memberof ListAdminTeams200ResponseAdminTeamsInner
     */
    'advisory_groups'?: Array<ListAdminTeams200ResponseAdminTeamsInnerAdvisoryGroupsInner>;
    /**
     * 
     * @type {string}
     * @memberof ListAdminTeams200ResponseAdminTeamsInner
     */
    'created_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof ListAdminTeams200ResponseAdminTeamsInner
     */
    'updated_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof ListAdminTeams200ResponseAdminTeamsInner
     */
    'deleted_at'?: string;
}

export const ListAdminTeams200ResponseAdminTeamsInnerTypeEnum = {
    AccountManagement: 'account_management',
    Onboarding: 'onboarding'
} as const;

export type ListAdminTeams200ResponseAdminTeamsInnerTypeEnum = typeof ListAdminTeams200ResponseAdminTeamsInnerTypeEnum[keyof typeof ListAdminTeams200ResponseAdminTeamsInnerTypeEnum];

/**
 * 
 * @export
 * @interface ListAdminTeams200ResponseAdminTeamsInnerAdvisoryGroupsInner
 */
export interface ListAdminTeams200ResponseAdminTeamsInnerAdvisoryGroupsInner {
    /**
     * 
     * @type {string}
     * @memberof ListAdminTeams200ResponseAdminTeamsInnerAdvisoryGroupsInner
     */
    '_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof ListAdminTeams200ResponseAdminTeamsInnerAdvisoryGroupsInner
     */
    'name'?: string;
}
/**
 * 
 * @export
 * @interface ListCommitments200Response
 */
export interface ListCommitments200Response {
    /**
     * 
     * @type {boolean}
     * @memberof ListCommitments200Response
     */
    'success'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof ListCommitments200Response
     */
    'total'?: number;
    /**
     * 
     * @type {Array<ListCommitments200ResponseCommitmentsInner>}
     * @memberof ListCommitments200Response
     */
    'commitments'?: Array<ListCommitments200ResponseCommitmentsInner>;
}
/**
 * 
 * @export
 * @interface ListCommitments200ResponseCommitmentsInner
 */
export interface ListCommitments200ResponseCommitmentsInner {
    /**
     * 
     * @type {string}
     * @memberof ListCommitments200ResponseCommitmentsInner
     */
    '_id'?: string;
    /**
     * 
     * @type {ListAdminTeams200ResponseAdminTeamsInnerAdvisoryGroupsInner}
     * @memberof ListCommitments200ResponseCommitmentsInner
     */
    'user'?: ListAdminTeams200ResponseAdminTeamsInnerAdvisoryGroupsInner;
    /**
     * 
     * @type {ListAdminTeams200ResponseAdminTeamsInnerAdvisoryGroupsInner}
     * @memberof ListCommitments200ResponseCommitmentsInner
     */
    'account'?: ListAdminTeams200ResponseAdminTeamsInnerAdvisoryGroupsInner;
    /**
     * 
     * @type {ListCommitments200ResponseCommitmentsInnerInvestment}
     * @memberof ListCommitments200ResponseCommitmentsInner
     */
    'investment'?: ListCommitments200ResponseCommitmentsInnerInvestment;
    /**
     * 
     * @type {ListAdminTeams200ResponseAdminTeamsInnerAdvisoryGroupsInner}
     * @memberof ListCommitments200ResponseCommitmentsInner
     */
    'connection'?: ListAdminTeams200ResponseAdminTeamsInnerAdvisoryGroupsInner;
    /**
     * 
     * @type {Array<ListAdminTeams200ResponseAdminTeamsInnerAdvisoryGroupsInner>}
     * @memberof ListCommitments200ResponseCommitmentsInner
     */
    'documents'?: Array<ListAdminTeams200ResponseAdminTeamsInnerAdvisoryGroupsInner>;
    /**
     * 
     * @type {boolean}
     * @memberof ListCommitments200ResponseCommitmentsInner
     */
    'auto_extracted'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ListCommitments200ResponseCommitmentsInner
     */
    'date'?: string;
    /**
     * 
     * @type {number}
     * @memberof ListCommitments200ResponseCommitmentsInner
     */
    'amount'?: number;
    /**
     * 
     * @type {string}
     * @memberof ListCommitments200ResponseCommitmentsInner
     */
    'created_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof ListCommitments200ResponseCommitmentsInner
     */
    'updated_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof ListCommitments200ResponseCommitmentsInner
     */
    'deleted_at'?: string;
    /**
     * 
     * @type {Array<ListAccounts200ResponseAccountsInnerAuditLogInner>}
     * @memberof ListCommitments200ResponseCommitmentsInner
     */
    'audit_log'?: Array<ListAccounts200ResponseAccountsInnerAuditLogInner>;
}
/**
 * 
 * @export
 * @interface ListCommitments200ResponseCommitmentsInnerInvestment
 */
export interface ListCommitments200ResponseCommitmentsInnerInvestment {
    /**
     * 
     * @type {string}
     * @memberof ListCommitments200ResponseCommitmentsInnerInvestment
     */
    '_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof ListCommitments200ResponseCommitmentsInnerInvestment
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof ListCommitments200ResponseCommitmentsInnerInvestment
     */
    'type'?: string;
    /**
     * 
     * @type {string}
     * @memberof ListCommitments200ResponseCommitmentsInnerInvestment
     */
    'currency'?: ListCommitments200ResponseCommitmentsInnerInvestmentCurrencyEnum;
}

export const ListCommitments200ResponseCommitmentsInnerInvestmentCurrencyEnum = {
    Usd: 'USD',
    Eur: 'EUR',
    Cny: 'CNY',
    Jpy: 'JPY',
    Gbp: 'GBP',
    Aud: 'AUD',
    Cad: 'CAD',
    Chf: 'CHF',
    Inr: 'INR',
    Krw: 'KRW',
    Btc: 'BTC'
} as const;

export type ListCommitments200ResponseCommitmentsInnerInvestmentCurrencyEnum = typeof ListCommitments200ResponseCommitmentsInnerInvestmentCurrencyEnum[keyof typeof ListCommitments200ResponseCommitmentsInnerInvestmentCurrencyEnum];

/**
 * 
 * @export
 * @interface ListConnectionAccounts200Response
 */
export interface ListConnectionAccounts200Response {
    /**
     * 
     * @type {number}
     * @memberof ListConnectionAccounts200Response
     */
    'total'?: number;
    /**
     * 
     * @type {Array<ListConnectionAccounts200ResponseAccountsInner>}
     * @memberof ListConnectionAccounts200Response
     */
    'accounts'?: Array<ListConnectionAccounts200ResponseAccountsInner>;
}
/**
 * 
 * @export
 * @interface ListConnectionAccounts200ResponseAccountsInner
 */
export interface ListConnectionAccounts200ResponseAccountsInner {
    /**
     * 
     * @type {string}
     * @memberof ListConnectionAccounts200ResponseAccountsInner
     */
    '_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof ListConnectionAccounts200ResponseAccountsInner
     */
    'custom_id'?: string;
    /**
     * 
     * @type {ListConnectionAccounts200ResponseAccountsInnerAccount}
     * @memberof ListConnectionAccounts200ResponseAccountsInner
     */
    'account'?: ListConnectionAccounts200ResponseAccountsInnerAccount;
    /**
     * 
     * @type {ListConnectionAccounts200ResponseAccountsInnerConnectionUser}
     * @memberof ListConnectionAccounts200ResponseAccountsInner
     */
    'connection_user'?: ListConnectionAccounts200ResponseAccountsInnerConnectionUser;
    /**
     * 
     * @type {boolean}
     * @memberof ListConnectionAccounts200ResponseAccountsInner
     */
    'is_auto_import_enabled'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ListConnectionAccounts200ResponseAccountsInner
     */
    'is_ignored'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ListConnectionAccounts200ResponseAccountsInner
     */
    'ignore_reason'?: string;
    /**
     * 
     * @type {ListConnectionAccounts200ResponseAccountsInnerAttributes}
     * @memberof ListConnectionAccounts200ResponseAccountsInner
     */
    'attributes'?: ListConnectionAccounts200ResponseAccountsInnerAttributes;
    /**
     * 
     * @type {Array<ListConnectionAccounts200ResponseAccountsInnerLikelyMatchesInner>}
     * @memberof ListConnectionAccounts200ResponseAccountsInner
     */
    'likely_matches'?: Array<ListConnectionAccounts200ResponseAccountsInnerLikelyMatchesInner>;
    /**
     * 
     * @type {string}
     * @memberof ListConnectionAccounts200ResponseAccountsInner
     */
    'created_at'?: string;
}
/**
 * 
 * @export
 * @interface ListConnectionAccounts200ResponseAccountsInnerAccount
 */
export interface ListConnectionAccounts200ResponseAccountsInnerAccount {
    /**
     * 
     * @type {string}
     * @memberof ListConnectionAccounts200ResponseAccountsInnerAccount
     */
    '_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof ListConnectionAccounts200ResponseAccountsInnerAccount
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof ListConnectionAccounts200ResponseAccountsInnerAccount
     */
    'account_type'?: string;
    /**
     * 
     * @type {ListValuations200ResponseValuationsInnerUser}
     * @memberof ListConnectionAccounts200ResponseAccountsInnerAccount
     */
    'user'?: ListValuations200ResponseValuationsInnerUser;
    /**
     * 
     * @type {string}
     * @memberof ListConnectionAccounts200ResponseAccountsInnerAccount
     */
    'deleted_at'?: string;
}
/**
 * 
 * @export
 * @interface ListConnectionAccounts200ResponseAccountsInnerAttributes
 */
export interface ListConnectionAccounts200ResponseAccountsInnerAttributes {
    /**
     * 
     * @type {string}
     * @memberof ListConnectionAccounts200ResponseAccountsInnerAttributes
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof ListConnectionAccounts200ResponseAccountsInnerAttributes
     */
    'type'?: string;
    /**
     * 
     * @type {string}
     * @memberof ListConnectionAccounts200ResponseAccountsInnerAttributes
     */
    'id_type'?: string;
    /**
     * 
     * @type {string}
     * @memberof ListConnectionAccounts200ResponseAccountsInnerAttributes
     */
    'id_number'?: string;
    /**
     * 
     * @type {string}
     * @memberof ListConnectionAccounts200ResponseAccountsInnerAttributes
     */
    'street_line1'?: string;
    /**
     * 
     * @type {string}
     * @memberof ListConnectionAccounts200ResponseAccountsInnerAttributes
     */
    'street_line2'?: string;
    /**
     * 
     * @type {string}
     * @memberof ListConnectionAccounts200ResponseAccountsInnerAttributes
     */
    'city'?: string;
    /**
     * 
     * @type {string}
     * @memberof ListConnectionAccounts200ResponseAccountsInnerAttributes
     */
    'state'?: string;
    /**
     * 
     * @type {string}
     * @memberof ListConnectionAccounts200ResponseAccountsInnerAttributes
     */
    'zip'?: string;
}
/**
 * 
 * @export
 * @interface ListConnectionAccounts200ResponseAccountsInnerConnectionUser
 */
export interface ListConnectionAccounts200ResponseAccountsInnerConnectionUser {
    /**
     * 
     * @type {string}
     * @memberof ListConnectionAccounts200ResponseAccountsInnerConnectionUser
     */
    '_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof ListConnectionAccounts200ResponseAccountsInnerConnectionUser
     */
    'custom_id'?: string;
    /**
     * 
     * @type {ListConnectionAccounts200ResponseAccountsInnerConnectionUserUser}
     * @memberof ListConnectionAccounts200ResponseAccountsInnerConnectionUser
     */
    'user'?: ListConnectionAccounts200ResponseAccountsInnerConnectionUserUser;
    /**
     * 
     * @type {ListAccounts200ResponseAccountsInnerUser}
     * @memberof ListConnectionAccounts200ResponseAccountsInnerConnectionUser
     */
    'attributes'?: ListAccounts200ResponseAccountsInnerUser;
}
/**
 * 
 * @export
 * @interface ListConnectionAccounts200ResponseAccountsInnerConnectionUserUser
 */
export interface ListConnectionAccounts200ResponseAccountsInnerConnectionUserUser {
    /**
     * 
     * @type {string}
     * @memberof ListConnectionAccounts200ResponseAccountsInnerConnectionUserUser
     */
    '_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof ListConnectionAccounts200ResponseAccountsInnerConnectionUserUser
     */
    'email'?: string;
    /**
     * 
     * @type {string}
     * @memberof ListConnectionAccounts200ResponseAccountsInnerConnectionUserUser
     */
    'name'?: string;
}
/**
 * 
 * @export
 * @interface ListConnectionAccounts200ResponseAccountsInnerLikelyMatchesInner
 */
export interface ListConnectionAccounts200ResponseAccountsInnerLikelyMatchesInner {
    /**
     * 
     * @type {string}
     * @memberof ListConnectionAccounts200ResponseAccountsInnerLikelyMatchesInner
     */
    '_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof ListConnectionAccounts200ResponseAccountsInnerLikelyMatchesInner
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof ListConnectionAccounts200ResponseAccountsInnerLikelyMatchesInner
     */
    'account_type'?: string;
    /**
     * 
     * @type {ListInvestments200ResponseInvestmentsInnerUser}
     * @memberof ListConnectionAccounts200ResponseAccountsInnerLikelyMatchesInner
     */
    'user'?: ListInvestments200ResponseInvestmentsInnerUser;
}
/**
 * @type ListConnectionAccountsAccountParameter
 * @export
 */
export type ListConnectionAccountsAccountParameter = string;

/**
 * 
 * @export
 * @interface ListConnectionCommitments200Response
 */
export interface ListConnectionCommitments200Response {
    /**
     * 
     * @type {number}
     * @memberof ListConnectionCommitments200Response
     */
    'total'?: number;
    /**
     * 
     * @type {Array<ListConnectionCommitments200ResponseCommitmentsInner>}
     * @memberof ListConnectionCommitments200Response
     */
    'commitments'?: Array<ListConnectionCommitments200ResponseCommitmentsInner>;
}
/**
 * 
 * @export
 * @interface ListConnectionCommitments200ResponseCommitmentsInner
 */
export interface ListConnectionCommitments200ResponseCommitmentsInner {
    /**
     * 
     * @type {string}
     * @memberof ListConnectionCommitments200ResponseCommitmentsInner
     */
    '_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof ListConnectionCommitments200ResponseCommitmentsInner
     */
    'custom_id'?: string;
    /**
     * 
     * @type {ListConnectionCommitments200ResponseCommitmentsInnerConnectionInvestment}
     * @memberof ListConnectionCommitments200ResponseCommitmentsInner
     */
    'connection_investment'?: ListConnectionCommitments200ResponseCommitmentsInnerConnectionInvestment;
    /**
     * 
     * @type {ListConnectionCommitments200ResponseCommitmentsInnerCommitment}
     * @memberof ListConnectionCommitments200ResponseCommitmentsInner
     */
    'commitment'?: ListConnectionCommitments200ResponseCommitmentsInnerCommitment;
    /**
     * 
     * @type {boolean}
     * @memberof ListConnectionCommitments200ResponseCommitmentsInner
     */
    'is_ignored'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ListConnectionCommitments200ResponseCommitmentsInner
     */
    'ignore_reason'?: string;
    /**
     * 
     * @type {ListConnectionCommitments200ResponseCommitmentsInnerAttributes}
     * @memberof ListConnectionCommitments200ResponseCommitmentsInner
     */
    'attributes'?: ListConnectionCommitments200ResponseCommitmentsInnerAttributes;
    /**
     * 
     * @type {Array<ListConnectionCommitments200ResponseCommitmentsInnerLikelyMatchesInner>}
     * @memberof ListConnectionCommitments200ResponseCommitmentsInner
     */
    'likely_matches'?: Array<ListConnectionCommitments200ResponseCommitmentsInnerLikelyMatchesInner>;
    /**
     * 
     * @type {string}
     * @memberof ListConnectionCommitments200ResponseCommitmentsInner
     */
    'created_at'?: string;
}
/**
 * 
 * @export
 * @interface ListConnectionCommitments200ResponseCommitmentsInnerAttributes
 */
export interface ListConnectionCommitments200ResponseCommitmentsInnerAttributes {
    /**
     * 
     * @type {number}
     * @memberof ListConnectionCommitments200ResponseCommitmentsInnerAttributes
     */
    'amount'?: number;
    /**
     * 
     * @type {string}
     * @memberof ListConnectionCommitments200ResponseCommitmentsInnerAttributes
     */
    'date'?: string;
}
/**
 * 
 * @export
 * @interface ListConnectionCommitments200ResponseCommitmentsInnerCommitment
 */
export interface ListConnectionCommitments200ResponseCommitmentsInnerCommitment {
    /**
     * 
     * @type {string}
     * @memberof ListConnectionCommitments200ResponseCommitmentsInnerCommitment
     */
    '_id'?: string;
    /**
     * 
     * @type {number}
     * @memberof ListConnectionCommitments200ResponseCommitmentsInnerCommitment
     */
    'amount'?: number;
    /**
     * 
     * @type {string}
     * @memberof ListConnectionCommitments200ResponseCommitmentsInnerCommitment
     */
    'date'?: string;
    /**
     * 
     * @type {string}
     * @memberof ListConnectionCommitments200ResponseCommitmentsInnerCommitment
     */
    'deleted_at'?: string;
    /**
     * 
     * @type {ListConnectionCommitments200ResponseCommitmentsInnerCommitmentInvestment}
     * @memberof ListConnectionCommitments200ResponseCommitmentsInnerCommitment
     */
    'investment'?: ListConnectionCommitments200ResponseCommitmentsInnerCommitmentInvestment;
    /**
     * 
     * @type {ListConnectionCommitments200ResponseCommitmentsInnerCommitmentAccount}
     * @memberof ListConnectionCommitments200ResponseCommitmentsInnerCommitment
     */
    'account'?: ListConnectionCommitments200ResponseCommitmentsInnerCommitmentAccount;
    /**
     * 
     * @type {ListConnectionCommitments200ResponseCommitmentsInnerCommitmentUser}
     * @memberof ListConnectionCommitments200ResponseCommitmentsInnerCommitment
     */
    'user'?: ListConnectionCommitments200ResponseCommitmentsInnerCommitmentUser;
}
/**
 * 
 * @export
 * @interface ListConnectionCommitments200ResponseCommitmentsInnerCommitmentAccount
 */
export interface ListConnectionCommitments200ResponseCommitmentsInnerCommitmentAccount {
    /**
     * 
     * @type {string}
     * @memberof ListConnectionCommitments200ResponseCommitmentsInnerCommitmentAccount
     */
    '_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof ListConnectionCommitments200ResponseCommitmentsInnerCommitmentAccount
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof ListConnectionCommitments200ResponseCommitmentsInnerCommitmentAccount
     */
    'account_type'?: string;
    /**
     * 
     * @type {string}
     * @memberof ListConnectionCommitments200ResponseCommitmentsInnerCommitmentAccount
     */
    'deleted_at'?: string;
}
/**
 * 
 * @export
 * @interface ListConnectionCommitments200ResponseCommitmentsInnerCommitmentInvestment
 */
export interface ListConnectionCommitments200ResponseCommitmentsInnerCommitmentInvestment {
    /**
     * 
     * @type {string}
     * @memberof ListConnectionCommitments200ResponseCommitmentsInnerCommitmentInvestment
     */
    '_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof ListConnectionCommitments200ResponseCommitmentsInnerCommitmentInvestment
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof ListConnectionCommitments200ResponseCommitmentsInnerCommitmentInvestment
     */
    'type'?: string;
    /**
     * 
     * @type {string}
     * @memberof ListConnectionCommitments200ResponseCommitmentsInnerCommitmentInvestment
     */
    'currency'?: ListConnectionCommitments200ResponseCommitmentsInnerCommitmentInvestmentCurrencyEnum;
    /**
     * 
     * @type {ListConnectionCommitments200ResponseCommitmentsInnerCommitmentInvestmentInvestmentData}
     * @memberof ListConnectionCommitments200ResponseCommitmentsInnerCommitmentInvestment
     */
    'investment_data'?: ListConnectionCommitments200ResponseCommitmentsInnerCommitmentInvestmentInvestmentData;
    /**
     * 
     * @type {string}
     * @memberof ListConnectionCommitments200ResponseCommitmentsInnerCommitmentInvestment
     */
    'deleted_at'?: string;
}

export const ListConnectionCommitments200ResponseCommitmentsInnerCommitmentInvestmentCurrencyEnum = {
    Usd: 'USD',
    Eur: 'EUR',
    Cny: 'CNY',
    Jpy: 'JPY',
    Gbp: 'GBP',
    Aud: 'AUD',
    Cad: 'CAD',
    Chf: 'CHF',
    Inr: 'INR',
    Krw: 'KRW',
    Btc: 'BTC'
} as const;

export type ListConnectionCommitments200ResponseCommitmentsInnerCommitmentInvestmentCurrencyEnum = typeof ListConnectionCommitments200ResponseCommitmentsInnerCommitmentInvestmentCurrencyEnum[keyof typeof ListConnectionCommitments200ResponseCommitmentsInnerCommitmentInvestmentCurrencyEnum];

/**
 * 
 * @export
 * @interface ListConnectionCommitments200ResponseCommitmentsInnerCommitmentInvestmentInvestmentData
 */
export interface ListConnectionCommitments200ResponseCommitmentsInnerCommitmentInvestmentInvestmentData {
    /**
     * 
     * @type {string}
     * @memberof ListConnectionCommitments200ResponseCommitmentsInnerCommitmentInvestmentInvestmentData
     */
    'type'?: string;
}
/**
 * 
 * @export
 * @interface ListConnectionCommitments200ResponseCommitmentsInnerCommitmentUser
 */
export interface ListConnectionCommitments200ResponseCommitmentsInnerCommitmentUser {
    /**
     * 
     * @type {string}
     * @memberof ListConnectionCommitments200ResponseCommitmentsInnerCommitmentUser
     */
    '_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof ListConnectionCommitments200ResponseCommitmentsInnerCommitmentUser
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof ListConnectionCommitments200ResponseCommitmentsInnerCommitmentUser
     */
    'email'?: string;
    /**
     * 
     * @type {string}
     * @memberof ListConnectionCommitments200ResponseCommitmentsInnerCommitmentUser
     */
    'deleted_at'?: string;
}
/**
 * 
 * @export
 * @interface ListConnectionCommitments200ResponseCommitmentsInnerConnectionInvestment
 */
export interface ListConnectionCommitments200ResponseCommitmentsInnerConnectionInvestment {
    /**
     * 
     * @type {string}
     * @memberof ListConnectionCommitments200ResponseCommitmentsInnerConnectionInvestment
     */
    '_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof ListConnectionCommitments200ResponseCommitmentsInnerConnectionInvestment
     */
    'custom_id'?: string;
    /**
     * 
     * @type {ListConnectionCommitments200ResponseCommitmentsInnerConnectionInvestmentAttributes}
     * @memberof ListConnectionCommitments200ResponseCommitmentsInnerConnectionInvestment
     */
    'attributes'?: ListConnectionCommitments200ResponseCommitmentsInnerConnectionInvestmentAttributes;
    /**
     * 
     * @type {ListConnectionCommitments200ResponseCommitmentsInnerConnectionInvestmentInvestment}
     * @memberof ListConnectionCommitments200ResponseCommitmentsInnerConnectionInvestment
     */
    'investment'?: ListConnectionCommitments200ResponseCommitmentsInnerConnectionInvestmentInvestment;
    /**
     * 
     * @type {ListConnectionCommitments200ResponseCommitmentsInnerConnectionInvestmentConnectionAccount}
     * @memberof ListConnectionCommitments200ResponseCommitmentsInnerConnectionInvestment
     */
    'connection_account'?: ListConnectionCommitments200ResponseCommitmentsInnerConnectionInvestmentConnectionAccount;
}
/**
 * 
 * @export
 * @interface ListConnectionCommitments200ResponseCommitmentsInnerConnectionInvestmentAttributes
 */
export interface ListConnectionCommitments200ResponseCommitmentsInnerConnectionInvestmentAttributes {
    /**
     * 
     * @type {string}
     * @memberof ListConnectionCommitments200ResponseCommitmentsInnerConnectionInvestmentAttributes
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof ListConnectionCommitments200ResponseCommitmentsInnerConnectionInvestmentAttributes
     */
    'type'?: string;
    /**
     * 
     * @type {string}
     * @memberof ListConnectionCommitments200ResponseCommitmentsInnerConnectionInvestmentAttributes
     */
    'subtype'?: string;
}
/**
 * 
 * @export
 * @interface ListConnectionCommitments200ResponseCommitmentsInnerConnectionInvestmentConnectionAccount
 */
export interface ListConnectionCommitments200ResponseCommitmentsInnerConnectionInvestmentConnectionAccount {
    /**
     * 
     * @type {string}
     * @memberof ListConnectionCommitments200ResponseCommitmentsInnerConnectionInvestmentConnectionAccount
     */
    '_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof ListConnectionCommitments200ResponseCommitmentsInnerConnectionInvestmentConnectionAccount
     */
    'custom_id'?: string;
    /**
     * 
     * @type {ListConnectionCommitments200ResponseCommitmentsInnerConnectionInvestmentConnectionAccountAttributes}
     * @memberof ListConnectionCommitments200ResponseCommitmentsInnerConnectionInvestmentConnectionAccount
     */
    'attributes'?: ListConnectionCommitments200ResponseCommitmentsInnerConnectionInvestmentConnectionAccountAttributes;
    /**
     * 
     * @type {ListConnectionCommitments200ResponseCommitmentsInnerConnectionInvestmentConnectionAccountConnectionUser}
     * @memberof ListConnectionCommitments200ResponseCommitmentsInnerConnectionInvestmentConnectionAccount
     */
    'connection_user'?: ListConnectionCommitments200ResponseCommitmentsInnerConnectionInvestmentConnectionAccountConnectionUser;
}
/**
 * 
 * @export
 * @interface ListConnectionCommitments200ResponseCommitmentsInnerConnectionInvestmentConnectionAccountAttributes
 */
export interface ListConnectionCommitments200ResponseCommitmentsInnerConnectionInvestmentConnectionAccountAttributes {
    /**
     * 
     * @type {string}
     * @memberof ListConnectionCommitments200ResponseCommitmentsInnerConnectionInvestmentConnectionAccountAttributes
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof ListConnectionCommitments200ResponseCommitmentsInnerConnectionInvestmentConnectionAccountAttributes
     */
    'type'?: string;
}
/**
 * 
 * @export
 * @interface ListConnectionCommitments200ResponseCommitmentsInnerConnectionInvestmentConnectionAccountConnectionUser
 */
export interface ListConnectionCommitments200ResponseCommitmentsInnerConnectionInvestmentConnectionAccountConnectionUser {
    /**
     * 
     * @type {string}
     * @memberof ListConnectionCommitments200ResponseCommitmentsInnerConnectionInvestmentConnectionAccountConnectionUser
     */
    '_id'?: string;
    /**
     * 
     * @type {ListAccounts200ResponseAccountsInnerUser}
     * @memberof ListConnectionCommitments200ResponseCommitmentsInnerConnectionInvestmentConnectionAccountConnectionUser
     */
    'attributes'?: ListAccounts200ResponseAccountsInnerUser;
}
/**
 * 
 * @export
 * @interface ListConnectionCommitments200ResponseCommitmentsInnerConnectionInvestmentInvestment
 */
export interface ListConnectionCommitments200ResponseCommitmentsInnerConnectionInvestmentInvestment {
    /**
     * 
     * @type {string}
     * @memberof ListConnectionCommitments200ResponseCommitmentsInnerConnectionInvestmentInvestment
     */
    '_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof ListConnectionCommitments200ResponseCommitmentsInnerConnectionInvestmentInvestment
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof ListConnectionCommitments200ResponseCommitmentsInnerConnectionInvestmentInvestment
     */
    'currency'?: ListConnectionCommitments200ResponseCommitmentsInnerConnectionInvestmentInvestmentCurrencyEnum;
}

export const ListConnectionCommitments200ResponseCommitmentsInnerConnectionInvestmentInvestmentCurrencyEnum = {
    Usd: 'USD',
    Eur: 'EUR',
    Cny: 'CNY',
    Jpy: 'JPY',
    Gbp: 'GBP',
    Aud: 'AUD',
    Cad: 'CAD',
    Chf: 'CHF',
    Inr: 'INR',
    Krw: 'KRW',
    Btc: 'BTC'
} as const;

export type ListConnectionCommitments200ResponseCommitmentsInnerConnectionInvestmentInvestmentCurrencyEnum = typeof ListConnectionCommitments200ResponseCommitmentsInnerConnectionInvestmentInvestmentCurrencyEnum[keyof typeof ListConnectionCommitments200ResponseCommitmentsInnerConnectionInvestmentInvestmentCurrencyEnum];

/**
 * 
 * @export
 * @interface ListConnectionCommitments200ResponseCommitmentsInnerLikelyMatchesInner
 */
export interface ListConnectionCommitments200ResponseCommitmentsInnerLikelyMatchesInner {
    /**
     * 
     * @type {string}
     * @memberof ListConnectionCommitments200ResponseCommitmentsInnerLikelyMatchesInner
     */
    '_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof ListConnectionCommitments200ResponseCommitmentsInnerLikelyMatchesInner
     */
    'date'?: string;
    /**
     * 
     * @type {number}
     * @memberof ListConnectionCommitments200ResponseCommitmentsInnerLikelyMatchesInner
     */
    'amount'?: number;
    /**
     * 
     * @type {ListConnectionCommitments200ResponseCommitmentsInnerCommitmentInvestment}
     * @memberof ListConnectionCommitments200ResponseCommitmentsInnerLikelyMatchesInner
     */
    'investment'?: ListConnectionCommitments200ResponseCommitmentsInnerCommitmentInvestment;
    /**
     * 
     * @type {ListConnectionCommitments200ResponseCommitmentsInnerCommitmentAccount}
     * @memberof ListConnectionCommitments200ResponseCommitmentsInnerLikelyMatchesInner
     */
    'account'?: ListConnectionCommitments200ResponseCommitmentsInnerCommitmentAccount;
    /**
     * 
     * @type {ListConnectionCommitments200ResponseCommitmentsInnerCommitmentUser}
     * @memberof ListConnectionCommitments200ResponseCommitmentsInnerLikelyMatchesInner
     */
    'user'?: ListConnectionCommitments200ResponseCommitmentsInnerCommitmentUser;
}
/**
 * @type ListConnectionCommitmentsCommitmentParameter
 * @export
 */
export type ListConnectionCommitmentsCommitmentParameter = string;

/**
 * 
 * @export
 * @interface ListConnectionDocuments200Response
 */
export interface ListConnectionDocuments200Response {
    /**
     * 
     * @type {number}
     * @memberof ListConnectionDocuments200Response
     */
    'total'?: number;
    /**
     * 
     * @type {Array<ListConnectionDocuments200ResponseDocumentsInner>}
     * @memberof ListConnectionDocuments200Response
     */
    'documents'?: Array<ListConnectionDocuments200ResponseDocumentsInner>;
}
/**
 * 
 * @export
 * @interface ListConnectionDocuments200ResponseDocumentsInner
 */
export interface ListConnectionDocuments200ResponseDocumentsInner {
    /**
     * 
     * @type {string}
     * @memberof ListConnectionDocuments200ResponseDocumentsInner
     */
    '_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof ListConnectionDocuments200ResponseDocumentsInner
     */
    'custom_id'?: string;
    /**
     * 
     * @type {ListConnectionCommitments200ResponseCommitmentsInnerConnectionInvestment}
     * @memberof ListConnectionDocuments200ResponseDocumentsInner
     */
    'connection_investment'?: ListConnectionCommitments200ResponseCommitmentsInnerConnectionInvestment;
    /**
     * 
     * @type {ListConnectionDocuments200ResponseDocumentsInnerConnectionAccount}
     * @memberof ListConnectionDocuments200ResponseDocumentsInner
     */
    'connection_account'?: ListConnectionDocuments200ResponseDocumentsInnerConnectionAccount;
    /**
     * 
     * @type {ListConnectionDocuments200ResponseDocumentsInnerDocument}
     * @memberof ListConnectionDocuments200ResponseDocumentsInner
     */
    'document'?: ListConnectionDocuments200ResponseDocumentsInnerDocument;
    /**
     * 
     * @type {boolean}
     * @memberof ListConnectionDocuments200ResponseDocumentsInner
     */
    'is_ignored'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ListConnectionDocuments200ResponseDocumentsInner
     */
    'ignore_reason'?: string;
    /**
     * 
     * @type {ListConnectionDocuments200ResponseDocumentsInnerAttributes}
     * @memberof ListConnectionDocuments200ResponseDocumentsInner
     */
    'attributes'?: ListConnectionDocuments200ResponseDocumentsInnerAttributes;
    /**
     * 
     * @type {Array<ListConnectionDocuments200ResponseDocumentsInnerLikelyMatchesInner>}
     * @memberof ListConnectionDocuments200ResponseDocumentsInner
     */
    'likely_matches'?: Array<ListConnectionDocuments200ResponseDocumentsInnerLikelyMatchesInner>;
    /**
     * 
     * @type {string}
     * @memberof ListConnectionDocuments200ResponseDocumentsInner
     */
    'hash'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ListConnectionDocuments200ResponseDocumentsInner
     */
    'is_file_current'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ListConnectionDocuments200ResponseDocumentsInner
     */
    'file_key'?: string;
    /**
     * 
     * @type {string}
     * @memberof ListConnectionDocuments200ResponseDocumentsInner
     */
    'created_at'?: string;
}
/**
 * 
 * @export
 * @interface ListConnectionDocuments200ResponseDocumentsInnerAttributes
 */
export interface ListConnectionDocuments200ResponseDocumentsInnerAttributes {
    /**
     * 
     * @type {string}
     * @memberof ListConnectionDocuments200ResponseDocumentsInnerAttributes
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof ListConnectionDocuments200ResponseDocumentsInnerAttributes
     */
    'type'?: string;
    /**
     * 
     * @type {string}
     * @memberof ListConnectionDocuments200ResponseDocumentsInnerAttributes
     */
    'date'?: string;
}
/**
 * 
 * @export
 * @interface ListConnectionDocuments200ResponseDocumentsInnerConnectionAccount
 */
export interface ListConnectionDocuments200ResponseDocumentsInnerConnectionAccount {
    /**
     * 
     * @type {string}
     * @memberof ListConnectionDocuments200ResponseDocumentsInnerConnectionAccount
     */
    '_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof ListConnectionDocuments200ResponseDocumentsInnerConnectionAccount
     */
    'custom_id'?: string;
    /**
     * 
     * @type {ListConnectionCommitments200ResponseCommitmentsInnerConnectionInvestmentConnectionAccountAttributes}
     * @memberof ListConnectionDocuments200ResponseDocumentsInnerConnectionAccount
     */
    'attributes'?: ListConnectionCommitments200ResponseCommitmentsInnerConnectionInvestmentConnectionAccountAttributes;
    /**
     * 
     * @type {ListConnectionDocuments200ResponseDocumentsInnerConnectionAccountAccount}
     * @memberof ListConnectionDocuments200ResponseDocumentsInnerConnectionAccount
     */
    'account'?: ListConnectionDocuments200ResponseDocumentsInnerConnectionAccountAccount;
}
/**
 * 
 * @export
 * @interface ListConnectionDocuments200ResponseDocumentsInnerConnectionAccountAccount
 */
export interface ListConnectionDocuments200ResponseDocumentsInnerConnectionAccountAccount {
    /**
     * 
     * @type {string}
     * @memberof ListConnectionDocuments200ResponseDocumentsInnerConnectionAccountAccount
     */
    '_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof ListConnectionDocuments200ResponseDocumentsInnerConnectionAccountAccount
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof ListConnectionDocuments200ResponseDocumentsInnerConnectionAccountAccount
     */
    'account_type'?: string;
}
/**
 * 
 * @export
 * @interface ListConnectionDocuments200ResponseDocumentsInnerDocument
 */
export interface ListConnectionDocuments200ResponseDocumentsInnerDocument {
    /**
     * 
     * @type {string}
     * @memberof ListConnectionDocuments200ResponseDocumentsInnerDocument
     */
    '_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof ListConnectionDocuments200ResponseDocumentsInnerDocument
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof ListConnectionDocuments200ResponseDocumentsInnerDocument
     */
    'date'?: string;
    /**
     * 
     * @type {string}
     * @memberof ListConnectionDocuments200ResponseDocumentsInnerDocument
     */
    'type'?: string;
    /**
     * 
     * @type {string}
     * @memberof ListConnectionDocuments200ResponseDocumentsInnerDocument
     */
    'hash'?: string;
    /**
     * 
     * @type {string}
     * @memberof ListConnectionDocuments200ResponseDocumentsInnerDocument
     */
    'deleted_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof ListConnectionDocuments200ResponseDocumentsInnerDocument
     */
    'ownership_type'?: string;
    /**
     * 
     * @type {Array<ListConnectionDocuments200ResponseDocumentsInnerDocumentOwnershipIdsInner>}
     * @memberof ListConnectionDocuments200ResponseDocumentsInnerDocument
     */
    'ownership_ids'?: Array<ListConnectionDocuments200ResponseDocumentsInnerDocumentOwnershipIdsInner>;
    /**
     * 
     * @type {ListConnectionCommitments200ResponseCommitmentsInnerCommitmentUser}
     * @memberof ListConnectionDocuments200ResponseDocumentsInnerDocument
     */
    'user'?: ListConnectionCommitments200ResponseCommitmentsInnerCommitmentUser;
}
/**
 * 
 * @export
 * @interface ListConnectionDocuments200ResponseDocumentsInnerDocumentOwnershipIdsInner
 */
export interface ListConnectionDocuments200ResponseDocumentsInnerDocumentOwnershipIdsInner {
    /**
     * 
     * @type {string}
     * @memberof ListConnectionDocuments200ResponseDocumentsInnerDocumentOwnershipIdsInner
     */
    '_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof ListConnectionDocuments200ResponseDocumentsInnerDocumentOwnershipIdsInner
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof ListConnectionDocuments200ResponseDocumentsInnerDocumentOwnershipIdsInner
     */
    'type'?: string;
    /**
     * 
     * @type {object}
     * @memberof ListConnectionDocuments200ResponseDocumentsInnerDocumentOwnershipIdsInner
     */
    'investment_data'?: object;
    /**
     * 
     * @type {string}
     * @memberof ListConnectionDocuments200ResponseDocumentsInnerDocumentOwnershipIdsInner
     */
    'currency'?: string;
    /**
     * 
     * @type {ListValuations200ResponseValuationsInnerUser}
     * @memberof ListConnectionDocuments200ResponseDocumentsInnerDocumentOwnershipIdsInner
     */
    'user'?: ListValuations200ResponseValuationsInnerUser;
    /**
     * 
     * @type {ListConnectionDocuments200ResponseDocumentsInnerDocumentOwnershipIdsInnerAccount}
     * @memberof ListConnectionDocuments200ResponseDocumentsInnerDocumentOwnershipIdsInner
     */
    'account'?: ListConnectionDocuments200ResponseDocumentsInnerDocumentOwnershipIdsInnerAccount;
}
/**
 * 
 * @export
 * @interface ListConnectionDocuments200ResponseDocumentsInnerDocumentOwnershipIdsInnerAccount
 */
export interface ListConnectionDocuments200ResponseDocumentsInnerDocumentOwnershipIdsInnerAccount {
    /**
     * 
     * @type {string}
     * @memberof ListConnectionDocuments200ResponseDocumentsInnerDocumentOwnershipIdsInnerAccount
     */
    '_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof ListConnectionDocuments200ResponseDocumentsInnerDocumentOwnershipIdsInnerAccount
     */
    'name'?: string;
    /**
     * 
     * @type {ListConnectionDocuments200ResponseDocumentsInnerDocumentOwnershipIdsInnerAccountUser}
     * @memberof ListConnectionDocuments200ResponseDocumentsInnerDocumentOwnershipIdsInnerAccount
     */
    'user'?: ListConnectionDocuments200ResponseDocumentsInnerDocumentOwnershipIdsInnerAccountUser;
}
/**
 * 
 * @export
 * @interface ListConnectionDocuments200ResponseDocumentsInnerDocumentOwnershipIdsInnerAccountUser
 */
export interface ListConnectionDocuments200ResponseDocumentsInnerDocumentOwnershipIdsInnerAccountUser {
    /**
     * 
     * @type {string}
     * @memberof ListConnectionDocuments200ResponseDocumentsInnerDocumentOwnershipIdsInnerAccountUser
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof ListConnectionDocuments200ResponseDocumentsInnerDocumentOwnershipIdsInnerAccountUser
     */
    'email'?: string;
}
/**
 * 
 * @export
 * @interface ListConnectionDocuments200ResponseDocumentsInnerLikelyMatchesInner
 */
export interface ListConnectionDocuments200ResponseDocumentsInnerLikelyMatchesInner {
    /**
     * 
     * @type {string}
     * @memberof ListConnectionDocuments200ResponseDocumentsInnerLikelyMatchesInner
     */
    '_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof ListConnectionDocuments200ResponseDocumentsInnerLikelyMatchesInner
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof ListConnectionDocuments200ResponseDocumentsInnerLikelyMatchesInner
     */
    'type'?: string;
    /**
     * 
     * @type {string}
     * @memberof ListConnectionDocuments200ResponseDocumentsInnerLikelyMatchesInner
     */
    'date'?: string;
    /**
     * 
     * @type {number}
     * @memberof ListConnectionDocuments200ResponseDocumentsInnerLikelyMatchesInner
     */
    'amount'?: number;
    /**
     * 
     * @type {string}
     * @memberof ListConnectionDocuments200ResponseDocumentsInnerLikelyMatchesInner
     */
    'description'?: string;
    /**
     * 
     * @type {string}
     * @memberof ListConnectionDocuments200ResponseDocumentsInnerLikelyMatchesInner
     */
    'ownership_type'?: string;
    /**
     * 
     * @type {Array<ListConnectionDocuments200ResponseDocumentsInnerDocumentOwnershipIdsInner>}
     * @memberof ListConnectionDocuments200ResponseDocumentsInnerLikelyMatchesInner
     */
    'ownership_ids'?: Array<ListConnectionDocuments200ResponseDocumentsInnerDocumentOwnershipIdsInner>;
}
/**
 * @type ListConnectionDocumentsDocumentParameter
 * @export
 */
export type ListConnectionDocumentsDocumentParameter = string;

/**
 * 
 * @export
 * @interface ListConnectionInvestments200Response
 */
export interface ListConnectionInvestments200Response {
    /**
     * 
     * @type {number}
     * @memberof ListConnectionInvestments200Response
     */
    'total'?: number;
    /**
     * 
     * @type {Array<ListConnectionInvestments200ResponseInvestmentsInner>}
     * @memberof ListConnectionInvestments200Response
     */
    'investments'?: Array<ListConnectionInvestments200ResponseInvestmentsInner>;
}
/**
 * 
 * @export
 * @interface ListConnectionInvestments200ResponseInvestmentsInner
 */
export interface ListConnectionInvestments200ResponseInvestmentsInner {
    /**
     * 
     * @type {string}
     * @memberof ListConnectionInvestments200ResponseInvestmentsInner
     */
    '_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof ListConnectionInvestments200ResponseInvestmentsInner
     */
    'custom_id'?: string;
    /**
     * 
     * @type {ListConnectionInvestments200ResponseInvestmentsInnerConnectionAccount}
     * @memberof ListConnectionInvestments200ResponseInvestmentsInner
     */
    'connection_account'?: ListConnectionInvestments200ResponseInvestmentsInnerConnectionAccount;
    /**
     * 
     * @type {ListConnectionInvestments200ResponseInvestmentsInnerInvestment}
     * @memberof ListConnectionInvestments200ResponseInvestmentsInner
     */
    'investment'?: ListConnectionInvestments200ResponseInvestmentsInnerInvestment;
    /**
     * 
     * @type {boolean}
     * @memberof ListConnectionInvestments200ResponseInvestmentsInner
     */
    'is_auto_import_enabled'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ListConnectionInvestments200ResponseInvestmentsInner
     */
    'is_ignored'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ListConnectionInvestments200ResponseInvestmentsInner
     */
    'ignore_reason'?: string;
    /**
     * 
     * @type {ListConnectionCommitments200ResponseCommitmentsInnerConnectionInvestmentAttributes}
     * @memberof ListConnectionInvestments200ResponseInvestmentsInner
     */
    'attributes'?: ListConnectionCommitments200ResponseCommitmentsInnerConnectionInvestmentAttributes;
    /**
     * 
     * @type {Array<ListConnectionInvestments200ResponseInvestmentsInnerLikelyMatchesInner>}
     * @memberof ListConnectionInvestments200ResponseInvestmentsInner
     */
    'likely_matches'?: Array<ListConnectionInvestments200ResponseInvestmentsInnerLikelyMatchesInner>;
    /**
     * 
     * @type {string}
     * @memberof ListConnectionInvestments200ResponseInvestmentsInner
     */
    'created_at'?: string;
}
/**
 * 
 * @export
 * @interface ListConnectionInvestments200ResponseInvestmentsInnerConnectionAccount
 */
export interface ListConnectionInvestments200ResponseInvestmentsInnerConnectionAccount {
    /**
     * 
     * @type {string}
     * @memberof ListConnectionInvestments200ResponseInvestmentsInnerConnectionAccount
     */
    '_id'?: string;
    /**
     * 
     * @type {ListConnectionCommitments200ResponseCommitmentsInnerConnectionInvestmentConnectionAccountAttributes}
     * @memberof ListConnectionInvestments200ResponseInvestmentsInnerConnectionAccount
     */
    'attributes'?: ListConnectionCommitments200ResponseCommitmentsInnerConnectionInvestmentConnectionAccountAttributes;
    /**
     * 
     * @type {ListConnectionInvestments200ResponseInvestmentsInnerConnectionAccountAccount}
     * @memberof ListConnectionInvestments200ResponseInvestmentsInnerConnectionAccount
     */
    'account'?: ListConnectionInvestments200ResponseInvestmentsInnerConnectionAccountAccount;
    /**
     * 
     * @type {ListConnectionInvestments200ResponseInvestmentsInnerConnectionAccountConnectionUser}
     * @memberof ListConnectionInvestments200ResponseInvestmentsInnerConnectionAccount
     */
    'connection_user'?: ListConnectionInvestments200ResponseInvestmentsInnerConnectionAccountConnectionUser;
}
/**
 * 
 * @export
 * @interface ListConnectionInvestments200ResponseInvestmentsInnerConnectionAccountAccount
 */
export interface ListConnectionInvestments200ResponseInvestmentsInnerConnectionAccountAccount {
    /**
     * 
     * @type {string}
     * @memberof ListConnectionInvestments200ResponseInvestmentsInnerConnectionAccountAccount
     */
    '_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof ListConnectionInvestments200ResponseInvestmentsInnerConnectionAccountAccount
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof ListConnectionInvestments200ResponseInvestmentsInnerConnectionAccountAccount
     */
    'type'?: string;
    /**
     * 
     * @type {ListInvestments200ResponseInvestmentsInnerUser}
     * @memberof ListConnectionInvestments200ResponseInvestmentsInnerConnectionAccountAccount
     */
    'user'?: ListInvestments200ResponseInvestmentsInnerUser;
}
/**
 * 
 * @export
 * @interface ListConnectionInvestments200ResponseInvestmentsInnerConnectionAccountConnectionUser
 */
export interface ListConnectionInvestments200ResponseInvestmentsInnerConnectionAccountConnectionUser {
    /**
     * 
     * @type {ListAccounts200ResponseAccountsInnerUser}
     * @memberof ListConnectionInvestments200ResponseInvestmentsInnerConnectionAccountConnectionUser
     */
    'attributes'?: ListAccounts200ResponseAccountsInnerUser;
}
/**
 * 
 * @export
 * @interface ListConnectionInvestments200ResponseInvestmentsInnerInvestment
 */
export interface ListConnectionInvestments200ResponseInvestmentsInnerInvestment {
    /**
     * 
     * @type {string}
     * @memberof ListConnectionInvestments200ResponseInvestmentsInnerInvestment
     */
    '_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof ListConnectionInvestments200ResponseInvestmentsInnerInvestment
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof ListConnectionInvestments200ResponseInvestmentsInnerInvestment
     */
    'type'?: string;
    /**
     * 
     * @type {string}
     * @memberof ListConnectionInvestments200ResponseInvestmentsInnerInvestment
     */
    'deleted_at'?: string;
    /**
     * 
     * @type {ListConnectionInvestments200ResponseInvestmentsInnerInvestmentInvestmentData}
     * @memberof ListConnectionInvestments200ResponseInvestmentsInnerInvestment
     */
    'investment_data'?: ListConnectionInvestments200ResponseInvestmentsInnerInvestmentInvestmentData;
    /**
     * 
     * @type {ListConnectionDocuments200ResponseDocumentsInnerConnectionAccountAccount}
     * @memberof ListConnectionInvestments200ResponseInvestmentsInnerInvestment
     */
    'account'?: ListConnectionDocuments200ResponseDocumentsInnerConnectionAccountAccount;
    /**
     * 
     * @type {ListInvestments200ResponseInvestmentsInnerUser}
     * @memberof ListConnectionInvestments200ResponseInvestmentsInnerInvestment
     */
    'user'?: ListInvestments200ResponseInvestmentsInnerUser;
    /**
     * 
     * @type {string}
     * @memberof ListConnectionInvestments200ResponseInvestmentsInnerInvestment
     */
    'currency'?: ListConnectionInvestments200ResponseInvestmentsInnerInvestmentCurrencyEnum;
}

export const ListConnectionInvestments200ResponseInvestmentsInnerInvestmentCurrencyEnum = {
    Usd: 'USD',
    Eur: 'EUR',
    Cny: 'CNY',
    Jpy: 'JPY',
    Gbp: 'GBP',
    Aud: 'AUD',
    Cad: 'CAD',
    Chf: 'CHF',
    Inr: 'INR',
    Krw: 'KRW',
    Btc: 'BTC'
} as const;

export type ListConnectionInvestments200ResponseInvestmentsInnerInvestmentCurrencyEnum = typeof ListConnectionInvestments200ResponseInvestmentsInnerInvestmentCurrencyEnum[keyof typeof ListConnectionInvestments200ResponseInvestmentsInnerInvestmentCurrencyEnum];

/**
 * 
 * @export
 * @interface ListConnectionInvestments200ResponseInvestmentsInnerInvestmentInvestmentData
 */
export interface ListConnectionInvestments200ResponseInvestmentsInnerInvestmentInvestmentData {
    /**
     * 
     * @type {string}
     * @memberof ListConnectionInvestments200ResponseInvestmentsInnerInvestmentInvestmentData
     */
    'type'?: string;
}
/**
 * 
 * @export
 * @interface ListConnectionInvestments200ResponseInvestmentsInnerLikelyMatchesInner
 */
export interface ListConnectionInvestments200ResponseInvestmentsInnerLikelyMatchesInner {
    /**
     * 
     * @type {string}
     * @memberof ListConnectionInvestments200ResponseInvestmentsInnerLikelyMatchesInner
     */
    '_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof ListConnectionInvestments200ResponseInvestmentsInnerLikelyMatchesInner
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof ListConnectionInvestments200ResponseInvestmentsInnerLikelyMatchesInner
     */
    'type'?: string;
    /**
     * 
     * @type {ListConnectionInvestments200ResponseInvestmentsInnerInvestmentInvestmentData}
     * @memberof ListConnectionInvestments200ResponseInvestmentsInnerLikelyMatchesInner
     */
    'investment_data'?: ListConnectionInvestments200ResponseInvestmentsInnerInvestmentInvestmentData;
    /**
     * 
     * @type {ListConnectionDocuments200ResponseDocumentsInnerConnectionAccountAccount}
     * @memberof ListConnectionInvestments200ResponseInvestmentsInnerLikelyMatchesInner
     */
    'account'?: ListConnectionDocuments200ResponseDocumentsInnerConnectionAccountAccount;
    /**
     * 
     * @type {ListInvestments200ResponseInvestmentsInnerUser}
     * @memberof ListConnectionInvestments200ResponseInvestmentsInnerLikelyMatchesInner
     */
    'user'?: ListInvestments200ResponseInvestmentsInnerUser;
    /**
     * 
     * @type {string}
     * @memberof ListConnectionInvestments200ResponseInvestmentsInnerLikelyMatchesInner
     */
    'currency'?: ListConnectionInvestments200ResponseInvestmentsInnerLikelyMatchesInnerCurrencyEnum;
}

export const ListConnectionInvestments200ResponseInvestmentsInnerLikelyMatchesInnerCurrencyEnum = {
    Usd: 'USD',
    Eur: 'EUR',
    Cny: 'CNY',
    Jpy: 'JPY',
    Gbp: 'GBP',
    Aud: 'AUD',
    Cad: 'CAD',
    Chf: 'CHF',
    Inr: 'INR',
    Krw: 'KRW',
    Btc: 'BTC'
} as const;

export type ListConnectionInvestments200ResponseInvestmentsInnerLikelyMatchesInnerCurrencyEnum = typeof ListConnectionInvestments200ResponseInvestmentsInnerLikelyMatchesInnerCurrencyEnum[keyof typeof ListConnectionInvestments200ResponseInvestmentsInnerLikelyMatchesInnerCurrencyEnum];

/**
 * @type ListConnectionInvestmentsInvestmentParameter
 * @export
 */
export type ListConnectionInvestmentsInvestmentParameter = string;

/**
 * 
 * @export
 * @interface ListConnectionSyncs200Response
 */
export interface ListConnectionSyncs200Response {
    /**
     * 
     * @type {number}
     * @memberof ListConnectionSyncs200Response
     */
    'total'?: number;
    /**
     * 
     * @type {Array<ListConnectionSyncs200ResponseConnectionsyncsInner>}
     * @memberof ListConnectionSyncs200Response
     */
    'connectionsyncs'?: Array<ListConnectionSyncs200ResponseConnectionsyncsInner>;
}
/**
 * 
 * @export
 * @interface ListConnectionSyncs200ResponseConnectionsyncsInner
 */
export interface ListConnectionSyncs200ResponseConnectionsyncsInner {
    /**
     * 
     * @type {string}
     * @memberof ListConnectionSyncs200ResponseConnectionsyncsInner
     */
    '_id'?: string;
    /**
     * 
     * @type {object}
     * @memberof ListConnectionSyncs200ResponseConnectionsyncsInner
     */
    'completion_result'?: object;
    /**
     * 
     * @type {string}
     * @memberof ListConnectionSyncs200ResponseConnectionsyncsInner
     */
    'sync_result_file_key'?: string;
    /**
     * 
     * @type {object}
     * @memberof ListConnectionSyncs200ResponseConnectionsyncsInner
     */
    'error_report'?: object;
    /**
     * 
     * @type {string}
     * @memberof ListConnectionSyncs200ResponseConnectionsyncsInner
     */
    'job_id'?: string;
    /**
     * 
     * @type {object}
     * @memberof ListConnectionSyncs200ResponseConnectionsyncsInner
     */
    'job_response'?: object;
    /**
     * 
     * @type {string}
     * @memberof ListConnectionSyncs200ResponseConnectionsyncsInner
     */
    'error_code'?: string;
    /**
     * 
     * @type {string}
     * @memberof ListConnectionSyncs200ResponseConnectionsyncsInner
     */
    'completed_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof ListConnectionSyncs200ResponseConnectionsyncsInner
     */
    'exit_status'?: string;
    /**
     * 
     * @type {ListConnectionSyncs200ResponseConnectionsyncsInnerConnection}
     * @memberof ListConnectionSyncs200ResponseConnectionsyncsInner
     */
    'connection'?: ListConnectionSyncs200ResponseConnectionsyncsInnerConnection;
    /**
     * 
     * @type {string}
     * @memberof ListConnectionSyncs200ResponseConnectionsyncsInner
     */
    'user'?: string;
    /**
     * 
     * @type {string}
     * @memberof ListConnectionSyncs200ResponseConnectionsyncsInner
     */
    'import'?: string;
    /**
     * 
     * @type {string}
     * @memberof ListConnectionSyncs200ResponseConnectionsyncsInner
     */
    'created_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof ListConnectionSyncs200ResponseConnectionsyncsInner
     */
    'updated_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof ListConnectionSyncs200ResponseConnectionsyncsInner
     */
    'deleted_at'?: string;
    /**
     * 
     * @type {number}
     * @memberof ListConnectionSyncs200ResponseConnectionsyncsInner
     */
    'documents_count'?: number;
    /**
     * 
     * @type {number}
     * @memberof ListConnectionSyncs200ResponseConnectionsyncsInner
     */
    'mapped_documents_count'?: number;
    /**
     * 
     * @type {number}
     * @memberof ListConnectionSyncs200ResponseConnectionsyncsInner
     */
    'commitments_count'?: number;
    /**
     * 
     * @type {number}
     * @memberof ListConnectionSyncs200ResponseConnectionsyncsInner
     */
    'mapped_commitments_count'?: number;
    /**
     * 
     * @type {number}
     * @memberof ListConnectionSyncs200ResponseConnectionsyncsInner
     */
    'transactions_count'?: number;
    /**
     * 
     * @type {number}
     * @memberof ListConnectionSyncs200ResponseConnectionsyncsInner
     */
    'mapped_transactions_count'?: number;
    /**
     * 
     * @type {number}
     * @memberof ListConnectionSyncs200ResponseConnectionsyncsInner
     */
    'users_count'?: number;
    /**
     * 
     * @type {number}
     * @memberof ListConnectionSyncs200ResponseConnectionsyncsInner
     */
    'mapped_users_count'?: number;
    /**
     * 
     * @type {number}
     * @memberof ListConnectionSyncs200ResponseConnectionsyncsInner
     */
    'valuations_count'?: number;
    /**
     * 
     * @type {number}
     * @memberof ListConnectionSyncs200ResponseConnectionsyncsInner
     */
    'mapped_valuations_count'?: number;
    /**
     * 
     * @type {number}
     * @memberof ListConnectionSyncs200ResponseConnectionsyncsInner
     */
    'accounts_count'?: number;
    /**
     * 
     * @type {number}
     * @memberof ListConnectionSyncs200ResponseConnectionsyncsInner
     */
    'mapped_accounts_count'?: number;
    /**
     * 
     * @type {number}
     * @memberof ListConnectionSyncs200ResponseConnectionsyncsInner
     */
    'investments_count'?: number;
    /**
     * 
     * @type {number}
     * @memberof ListConnectionSyncs200ResponseConnectionsyncsInner
     */
    'mapped_investments_count'?: number;
}
/**
 * 
 * @export
 * @interface ListConnectionSyncs200ResponseConnectionsyncsInnerConnection
 */
export interface ListConnectionSyncs200ResponseConnectionsyncsInnerConnection {
    /**
     * 
     * @type {string}
     * @memberof ListConnectionSyncs200ResponseConnectionsyncsInnerConnection
     */
    '_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof ListConnectionSyncs200ResponseConnectionsyncsInnerConnection
     */
    'name'?: string;
    /**
     * 
     * @type {ListConnectionSyncs200ResponseConnectionsyncsInnerConnectionUser}
     * @memberof ListConnectionSyncs200ResponseConnectionsyncsInnerConnection
     */
    'user'?: ListConnectionSyncs200ResponseConnectionsyncsInnerConnectionUser;
    /**
     * 
     * @type {ListConnectionSyncs200ResponseConnectionsyncsInnerConnectionUser}
     * @memberof ListConnectionSyncs200ResponseConnectionsyncsInnerConnection
     */
    'account'?: ListConnectionSyncs200ResponseConnectionsyncsInnerConnectionUser;
}
/**
 * 
 * @export
 * @interface ListConnectionSyncs200ResponseConnectionsyncsInnerConnectionUser
 */
export interface ListConnectionSyncs200ResponseConnectionsyncsInnerConnectionUser {
    /**
     * 
     * @type {string}
     * @memberof ListConnectionSyncs200ResponseConnectionsyncsInnerConnectionUser
     */
    '_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof ListConnectionSyncs200ResponseConnectionsyncsInnerConnectionUser
     */
    'name'?: string;
}
/**
 * 
 * @export
 * @interface ListConnectionTransactions200Response
 */
export interface ListConnectionTransactions200Response {
    /**
     * 
     * @type {number}
     * @memberof ListConnectionTransactions200Response
     */
    'total'?: number;
    /**
     * 
     * @type {Array<ListConnectionTransactions200ResponseTransactionsInner>}
     * @memberof ListConnectionTransactions200Response
     */
    'transactions'?: Array<ListConnectionTransactions200ResponseTransactionsInner>;
}
/**
 * 
 * @export
 * @interface ListConnectionTransactions200ResponseTransactionsInner
 */
export interface ListConnectionTransactions200ResponseTransactionsInner {
    /**
     * 
     * @type {string}
     * @memberof ListConnectionTransactions200ResponseTransactionsInner
     */
    '_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof ListConnectionTransactions200ResponseTransactionsInner
     */
    'custom_id'?: string;
    /**
     * 
     * @type {ListConnectionCommitments200ResponseCommitmentsInnerConnectionInvestment}
     * @memberof ListConnectionTransactions200ResponseTransactionsInner
     */
    'connection_investment'?: ListConnectionCommitments200ResponseCommitmentsInnerConnectionInvestment;
    /**
     * 
     * @type {Array<ListConnectionTransactions200ResponseTransactionsInnerJointTransactionsInner>}
     * @memberof ListConnectionTransactions200ResponseTransactionsInner
     */
    'joint_transactions'?: Array<ListConnectionTransactions200ResponseTransactionsInnerJointTransactionsInner>;
    /**
     * 
     * @type {ListConnectionTransactions200ResponseTransactionsInnerTransaction}
     * @memberof ListConnectionTransactions200ResponseTransactionsInner
     */
    'transaction'?: ListConnectionTransactions200ResponseTransactionsInnerTransaction;
    /**
     * 
     * @type {boolean}
     * @memberof ListConnectionTransactions200ResponseTransactionsInner
     */
    'is_ignored'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ListConnectionTransactions200ResponseTransactionsInner
     */
    'ignore_reason'?: string;
    /**
     * 
     * @type {ListConnectionTransactions200ResponseTransactionsInnerJointTransactionsInnerAttributes}
     * @memberof ListConnectionTransactions200ResponseTransactionsInner
     */
    'attributes'?: ListConnectionTransactions200ResponseTransactionsInnerJointTransactionsInnerAttributes;
    /**
     * 
     * @type {Array<ListConnectionTransactions200ResponseTransactionsInnerLikelyMatchesInner>}
     * @memberof ListConnectionTransactions200ResponseTransactionsInner
     */
    'likely_matches'?: Array<ListConnectionTransactions200ResponseTransactionsInnerLikelyMatchesInner>;
    /**
     * 
     * @type {string}
     * @memberof ListConnectionTransactions200ResponseTransactionsInner
     */
    'created_at'?: string;
}
/**
 * 
 * @export
 * @interface ListConnectionTransactions200ResponseTransactionsInnerJointTransactionsInner
 */
export interface ListConnectionTransactions200ResponseTransactionsInnerJointTransactionsInner {
    /**
     * 
     * @type {string}
     * @memberof ListConnectionTransactions200ResponseTransactionsInnerJointTransactionsInner
     */
    '_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof ListConnectionTransactions200ResponseTransactionsInnerJointTransactionsInner
     */
    'custom_id'?: string;
    /**
     * 
     * @type {ListConnectionTransactions200ResponseTransactionsInnerJointTransactionsInnerAttributes}
     * @memberof ListConnectionTransactions200ResponseTransactionsInnerJointTransactionsInner
     */
    'attributes'?: ListConnectionTransactions200ResponseTransactionsInnerJointTransactionsInnerAttributes;
}
/**
 * 
 * @export
 * @interface ListConnectionTransactions200ResponseTransactionsInnerJointTransactionsInnerAttributes
 */
export interface ListConnectionTransactions200ResponseTransactionsInnerJointTransactionsInnerAttributes {
    /**
     * 
     * @type {string}
     * @memberof ListConnectionTransactions200ResponseTransactionsInnerJointTransactionsInnerAttributes
     */
    'type'?: string;
    /**
     * 
     * @type {number}
     * @memberof ListConnectionTransactions200ResponseTransactionsInnerJointTransactionsInnerAttributes
     */
    'amount'?: number;
    /**
     * 
     * @type {string}
     * @memberof ListConnectionTransactions200ResponseTransactionsInnerJointTransactionsInnerAttributes
     */
    'date'?: string;
    /**
     * 
     * @type {string}
     * @memberof ListConnectionTransactions200ResponseTransactionsInnerJointTransactionsInnerAttributes
     */
    'description'?: string;
}
/**
 * 
 * @export
 * @interface ListConnectionTransactions200ResponseTransactionsInnerLikelyMatchesInner
 */
export interface ListConnectionTransactions200ResponseTransactionsInnerLikelyMatchesInner {
    /**
     * 
     * @type {string}
     * @memberof ListConnectionTransactions200ResponseTransactionsInnerLikelyMatchesInner
     */
    '_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof ListConnectionTransactions200ResponseTransactionsInnerLikelyMatchesInner
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof ListConnectionTransactions200ResponseTransactionsInnerLikelyMatchesInner
     */
    'type'?: string;
    /**
     * 
     * @type {string}
     * @memberof ListConnectionTransactions200ResponseTransactionsInnerLikelyMatchesInner
     */
    'date'?: string;
    /**
     * 
     * @type {number}
     * @memberof ListConnectionTransactions200ResponseTransactionsInnerLikelyMatchesInner
     */
    'amount'?: number;
    /**
     * 
     * @type {string}
     * @memberof ListConnectionTransactions200ResponseTransactionsInnerLikelyMatchesInner
     */
    'description'?: string;
    /**
     * 
     * @type {ListConnectionCommitments200ResponseCommitmentsInnerCommitmentInvestment}
     * @memberof ListConnectionTransactions200ResponseTransactionsInnerLikelyMatchesInner
     */
    'investment'?: ListConnectionCommitments200ResponseCommitmentsInnerCommitmentInvestment;
    /**
     * 
     * @type {ListConnectionCommitments200ResponseCommitmentsInnerCommitmentAccount}
     * @memberof ListConnectionTransactions200ResponseTransactionsInnerLikelyMatchesInner
     */
    'account'?: ListConnectionCommitments200ResponseCommitmentsInnerCommitmentAccount;
    /**
     * 
     * @type {ListConnectionCommitments200ResponseCommitmentsInnerCommitmentUser}
     * @memberof ListConnectionTransactions200ResponseTransactionsInnerLikelyMatchesInner
     */
    'user'?: ListConnectionCommitments200ResponseCommitmentsInnerCommitmentUser;
}
/**
 * 
 * @export
 * @interface ListConnectionTransactions200ResponseTransactionsInnerTransaction
 */
export interface ListConnectionTransactions200ResponseTransactionsInnerTransaction {
    /**
     * 
     * @type {string}
     * @memberof ListConnectionTransactions200ResponseTransactionsInnerTransaction
     */
    '_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof ListConnectionTransactions200ResponseTransactionsInnerTransaction
     */
    'type'?: string;
    /**
     * 
     * @type {number}
     * @memberof ListConnectionTransactions200ResponseTransactionsInnerTransaction
     */
    'amount'?: number;
    /**
     * 
     * @type {string}
     * @memberof ListConnectionTransactions200ResponseTransactionsInnerTransaction
     */
    'date'?: string;
    /**
     * 
     * @type {string}
     * @memberof ListConnectionTransactions200ResponseTransactionsInnerTransaction
     */
    'description'?: string;
    /**
     * 
     * @type {string}
     * @memberof ListConnectionTransactions200ResponseTransactionsInnerTransaction
     */
    'deleted_at'?: string;
    /**
     * 
     * @type {ListConnectionCommitments200ResponseCommitmentsInnerCommitmentInvestment}
     * @memberof ListConnectionTransactions200ResponseTransactionsInnerTransaction
     */
    'investment'?: ListConnectionCommitments200ResponseCommitmentsInnerCommitmentInvestment;
    /**
     * 
     * @type {ListConnectionCommitments200ResponseCommitmentsInnerCommitmentAccount}
     * @memberof ListConnectionTransactions200ResponseTransactionsInnerTransaction
     */
    'account'?: ListConnectionCommitments200ResponseCommitmentsInnerCommitmentAccount;
    /**
     * 
     * @type {ListConnectionCommitments200ResponseCommitmentsInnerCommitmentUser}
     * @memberof ListConnectionTransactions200ResponseTransactionsInnerTransaction
     */
    'user'?: ListConnectionCommitments200ResponseCommitmentsInnerCommitmentUser;
}
/**
 * @type ListConnectionTransactionsTransactionParameter
 * @export
 */
export type ListConnectionTransactionsTransactionParameter = string;

/**
 * 
 * @export
 * @interface ListConnectionUsers200Response
 */
export interface ListConnectionUsers200Response {
    /**
     * 
     * @type {number}
     * @memberof ListConnectionUsers200Response
     */
    'total'?: number;
    /**
     * 
     * @type {Array<ListConnectionUsers200ResponseUsersInner>}
     * @memberof ListConnectionUsers200Response
     */
    'users'?: Array<ListConnectionUsers200ResponseUsersInner>;
}
/**
 * 
 * @export
 * @interface ListConnectionUsers200ResponseUsersInner
 */
export interface ListConnectionUsers200ResponseUsersInner {
    /**
     * 
     * @type {string}
     * @memberof ListConnectionUsers200ResponseUsersInner
     */
    '_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof ListConnectionUsers200ResponseUsersInner
     */
    'custom_id'?: string;
    /**
     * 
     * @type {ListConnectionUsers200ResponseUsersInnerUser}
     * @memberof ListConnectionUsers200ResponseUsersInner
     */
    'user'?: ListConnectionUsers200ResponseUsersInnerUser;
    /**
     * 
     * @type {boolean}
     * @memberof ListConnectionUsers200ResponseUsersInner
     */
    'is_auto_import_enabled'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ListConnectionUsers200ResponseUsersInner
     */
    'is_ignored'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ListConnectionUsers200ResponseUsersInner
     */
    'ignore_reason'?: string;
    /**
     * 
     * @type {ListAccounts200ResponseAccountsInnerUser}
     * @memberof ListConnectionUsers200ResponseUsersInner
     */
    'attributes'?: ListAccounts200ResponseAccountsInnerUser;
    /**
     * 
     * @type {string}
     * @memberof ListConnectionUsers200ResponseUsersInner
     */
    'created_at'?: string;
    /**
     * 
     * @type {Array<ListInvestments200ResponseInvestmentsInnerUser>}
     * @memberof ListConnectionUsers200ResponseUsersInner
     */
    'likely_matches'?: Array<ListInvestments200ResponseInvestmentsInnerUser>;
}
/**
 * 
 * @export
 * @interface ListConnectionUsers200ResponseUsersInnerUser
 */
export interface ListConnectionUsers200ResponseUsersInnerUser {
    /**
     * 
     * @type {string}
     * @memberof ListConnectionUsers200ResponseUsersInnerUser
     */
    '_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof ListConnectionUsers200ResponseUsersInnerUser
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof ListConnectionUsers200ResponseUsersInnerUser
     */
    'email'?: string;
    /**
     * 
     * @type {string}
     * @memberof ListConnectionUsers200ResponseUsersInnerUser
     */
    'deleted_at'?: string;
}
/**
 * @type ListConnectionUsersUserParameter
 * @export
 */
export type ListConnectionUsersUserParameter = string;

/**
 * 
 * @export
 * @interface ListConnectionValuations200Response
 */
export interface ListConnectionValuations200Response {
    /**
     * 
     * @type {number}
     * @memberof ListConnectionValuations200Response
     */
    'total'?: number;
    /**
     * 
     * @type {Array<ListConnectionValuations200ResponseValuationsInner>}
     * @memberof ListConnectionValuations200Response
     */
    'valuations'?: Array<ListConnectionValuations200ResponseValuationsInner>;
}
/**
 * 
 * @export
 * @interface ListConnectionValuations200ResponseValuationsInner
 */
export interface ListConnectionValuations200ResponseValuationsInner {
    /**
     * 
     * @type {string}
     * @memberof ListConnectionValuations200ResponseValuationsInner
     */
    '_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof ListConnectionValuations200ResponseValuationsInner
     */
    'custom_id'?: string;
    /**
     * 
     * @type {ListConnectionCommitments200ResponseCommitmentsInnerConnectionInvestment}
     * @memberof ListConnectionValuations200ResponseValuationsInner
     */
    'connection_investment'?: ListConnectionCommitments200ResponseCommitmentsInnerConnectionInvestment;
    /**
     * 
     * @type {ListConnectionValuations200ResponseValuationsInnerValuation}
     * @memberof ListConnectionValuations200ResponseValuationsInner
     */
    'valuation'?: ListConnectionValuations200ResponseValuationsInnerValuation;
    /**
     * 
     * @type {boolean}
     * @memberof ListConnectionValuations200ResponseValuationsInner
     */
    'is_ignored'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ListConnectionValuations200ResponseValuationsInner
     */
    'ignore_reason'?: string;
    /**
     * 
     * @type {ListConnectionCommitments200ResponseCommitmentsInnerAttributes}
     * @memberof ListConnectionValuations200ResponseValuationsInner
     */
    'attributes'?: ListConnectionCommitments200ResponseCommitmentsInnerAttributes;
    /**
     * 
     * @type {Array<ListConnectionCommitments200ResponseCommitmentsInnerLikelyMatchesInner>}
     * @memberof ListConnectionValuations200ResponseValuationsInner
     */
    'likely_matches'?: Array<ListConnectionCommitments200ResponseCommitmentsInnerLikelyMatchesInner>;
    /**
     * 
     * @type {string}
     * @memberof ListConnectionValuations200ResponseValuationsInner
     */
    'created_at'?: string;
}
/**
 * 
 * @export
 * @interface ListConnectionValuations200ResponseValuationsInnerValuation
 */
export interface ListConnectionValuations200ResponseValuationsInnerValuation {
    /**
     * 
     * @type {string}
     * @memberof ListConnectionValuations200ResponseValuationsInnerValuation
     */
    '_id'?: string;
    /**
     * 
     * @type {number}
     * @memberof ListConnectionValuations200ResponseValuationsInnerValuation
     */
    'amount'?: number;
    /**
     * 
     * @type {string}
     * @memberof ListConnectionValuations200ResponseValuationsInnerValuation
     */
    'date'?: string;
    /**
     * 
     * @type {ListConnectionCommitments200ResponseCommitmentsInnerCommitmentInvestment}
     * @memberof ListConnectionValuations200ResponseValuationsInnerValuation
     */
    'investment'?: ListConnectionCommitments200ResponseCommitmentsInnerCommitmentInvestment;
    /**
     * 
     * @type {ListConnectionCommitments200ResponseCommitmentsInnerCommitmentAccount}
     * @memberof ListConnectionValuations200ResponseValuationsInnerValuation
     */
    'account'?: ListConnectionCommitments200ResponseCommitmentsInnerCommitmentAccount;
    /**
     * 
     * @type {ListConnectionCommitments200ResponseCommitmentsInnerCommitmentUser}
     * @memberof ListConnectionValuations200ResponseValuationsInnerValuation
     */
    'user'?: ListConnectionCommitments200ResponseCommitmentsInnerCommitmentUser;
}
/**
 * @type ListConnectionValuationsValuationParameter
 * @export
 */
export type ListConnectionValuationsValuationParameter = string;

/**
 * 
 * @export
 * @interface ListConnections200Response
 */
export interface ListConnections200Response {
    /**
     * 
     * @type {boolean}
     * @memberof ListConnections200Response
     */
    'success'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof ListConnections200Response
     */
    'total'?: number;
    /**
     * 
     * @type {Array<ListConnections200ResponseConnectionsInner>}
     * @memberof ListConnections200Response
     */
    'connections'?: Array<ListConnections200ResponseConnectionsInner>;
}
/**
 * 
 * @export
 * @interface ListConnections200ResponseConnectionsInner
 */
export interface ListConnections200ResponseConnectionsInner {
    /**
     * 
     * @type {string}
     * @memberof ListConnections200ResponseConnectionsInner
     */
    '_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof ListConnections200ResponseConnectionsInner
     */
    'name'?: string;
    /**
     * 
     * @type {ListConnections200ResponseConnectionsInnerIntegration}
     * @memberof ListConnections200ResponseConnectionsInner
     */
    'integration'?: ListConnections200ResponseConnectionsInnerIntegration;
    /**
     * 
     * @type {ListAccounts200ResponseAccountsInnerUser}
     * @memberof ListConnections200ResponseConnectionsInner
     */
    'user'?: ListAccounts200ResponseAccountsInnerUser;
    /**
     * 
     * @type {ListConnections200ResponseConnectionsInnerAccount}
     * @memberof ListConnections200ResponseConnectionsInner
     */
    'account'?: ListConnections200ResponseConnectionsInnerAccount;
    /**
     * 
     * @type {Array<ListConnections200ResponseConnectionsInnerAccount>}
     * @memberof ListConnections200ResponseConnectionsInner
     */
    'account_list'?: Array<ListConnections200ResponseConnectionsInnerAccount>;
    /**
     * 
     * @type {ListConnections200ResponseConnectionsInnerAccount}
     * @memberof ListConnections200ResponseConnectionsInner
     */
    'asset_manager'?: ListConnections200ResponseConnectionsInnerAccount;
    /**
     * 
     * @type {Array<ListAccounts200ResponseAccountsInnerUser>}
     * @memberof ListConnections200ResponseConnectionsInner
     */
    'contacts'?: Array<ListAccounts200ResponseAccountsInnerUser>;
    /**
     * 
     * @type {ListConnections200ResponseConnectionsInnerMostRecentSync}
     * @memberof ListConnections200ResponseConnectionsInner
     */
    'most_recent_sync'?: ListConnections200ResponseConnectionsInnerMostRecentSync;
    /**
     * 
     * @type {Array<ListConnections200ResponseConnectionsInnerAuditLogInner>}
     * @memberof ListConnections200ResponseConnectionsInner
     */
    'audit_log'?: Array<ListConnections200ResponseConnectionsInnerAuditLogInner>;
    /**
     * 
     * @type {string}
     * @memberof ListConnections200ResponseConnectionsInner
     */
    'status'?: string;
    /**
     * 
     * @type {string}
     * @memberof ListConnections200ResponseConnectionsInner
     */
    'ral_status'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ListConnections200ResponseConnectionsInner
     */
    'is_enabled'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ListConnections200ResponseConnectionsInner
     */
    'outreach_status'?: string;
    /**
     * 
     * @type {string}
     * @memberof ListConnections200ResponseConnectionsInner
     */
    'outreach_status_updated_at'?: string;
    /**
     * 
     * @type {ListConnections200ResponseConnectionsInnerError}
     * @memberof ListConnections200ResponseConnectionsInner
     */
    'error'?: ListConnections200ResponseConnectionsInnerError;
    /**
     * 
     * @type {ListConnections200ResponseConnectionsInnerErrorInformation}
     * @memberof ListConnections200ResponseConnectionsInner
     */
    'error_information'?: ListConnections200ResponseConnectionsInnerErrorInformation;
    /**
     * 
     * @type {{ [key: string]: any; }}
     * @memberof ListConnections200ResponseConnectionsInner
     */
    'credentials'?: { [key: string]: any; };
    /**
     * 
     * @type {string}
     * @memberof ListConnections200ResponseConnectionsInner
     */
    'tfa_email'?: string;
    /**
     * 
     * @type {string}
     * @memberof ListConnections200ResponseConnectionsInner
     */
    'tfa_phone'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ListConnections200ResponseConnectionsInner
     */
    'require_phone'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ListConnections200ResponseConnectionsInner
     */
    'newest_data_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof ListConnections200ResponseConnectionsInner
     */
    'last_connection_at'?: string;
    /**
     * 
     * @type {{ [key: string]: any; }}
     * @memberof ListConnections200ResponseConnectionsInner
     */
    'data'?: { [key: string]: any; };
    /**
     * 
     * @type {boolean}
     * @memberof ListConnections200ResponseConnectionsInner
     */
    'use_tfa'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ListConnections200ResponseConnectionsInner
     */
    'test'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ListConnections200ResponseConnectionsInner
     */
    'created_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof ListConnections200ResponseConnectionsInner
     */
    'updated_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof ListConnections200ResponseConnectionsInner
     */
    'deleted_at'?: string;
}
/**
 * 
 * @export
 * @interface ListConnections200ResponseConnectionsInnerAccount
 */
export interface ListConnections200ResponseConnectionsInnerAccount {
    /**
     * 
     * @type {string}
     * @memberof ListConnections200ResponseConnectionsInnerAccount
     */
    'name'?: string;
}
/**
 * 
 * @export
 * @interface ListConnections200ResponseConnectionsInnerAuditLogInner
 */
export interface ListConnections200ResponseConnectionsInnerAuditLogInner {
    /**
     * 
     * @type {string}
     * @memberof ListConnections200ResponseConnectionsInnerAuditLogInner
     */
    'timestamp'?: string;
}
/**
 * 
 * @export
 * @interface ListConnections200ResponseConnectionsInnerError
 */
export interface ListConnections200ResponseConnectionsInnerError {
    /**
     * 
     * @type {string}
     * @memberof ListConnections200ResponseConnectionsInnerError
     */
    'user'?: string;
    /**
     * 
     * @type {string}
     * @memberof ListConnections200ResponseConnectionsInnerError
     */
    'message'?: string;
    /**
     * 
     * @type {string}
     * @memberof ListConnections200ResponseConnectionsInnerError
     */
    'traceback'?: string;
}
/**
 * 
 * @export
 * @interface ListConnections200ResponseConnectionsInnerErrorInformation
 */
export interface ListConnections200ResponseConnectionsInnerErrorInformation {
    /**
     * 
     * @type {string}
     * @memberof ListConnections200ResponseConnectionsInnerErrorInformation
     */
    'error_job_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof ListConnections200ResponseConnectionsInnerErrorInformation
     */
    'error_job_type'?: string;
    /**
     * 
     * @type {string}
     * @memberof ListConnections200ResponseConnectionsInnerErrorInformation
     */
    'error_code'?: string;
}
/**
 * 
 * @export
 * @interface ListConnections200ResponseConnectionsInnerIntegration
 */
export interface ListConnections200ResponseConnectionsInnerIntegration {
    /**
     * 
     * @type {string}
     * @memberof ListConnections200ResponseConnectionsInnerIntegration
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof ListConnections200ResponseConnectionsInnerIntegration
     */
    'logo_url'?: string;
}
/**
 * 
 * @export
 * @interface ListConnections200ResponseConnectionsInnerMostRecentSync
 */
export interface ListConnections200ResponseConnectionsInnerMostRecentSync {
    /**
     * 
     * @type {string}
     * @memberof ListConnections200ResponseConnectionsInnerMostRecentSync
     */
    'created_at'?: string;
}
/**
 * 
 * @export
 * @interface ListContacts200Response
 */
export interface ListContacts200Response {
    /**
     * 
     * @type {boolean}
     * @memberof ListContacts200Response
     */
    'success'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof ListContacts200Response
     */
    'total'?: number;
    /**
     * 
     * @type {Array<ListContacts200ResponseContactsInner>}
     * @memberof ListContacts200Response
     */
    'contacts'?: Array<ListContacts200ResponseContactsInner>;
}
/**
 * 
 * @export
 * @interface ListContacts200ResponseContactsInner
 */
export interface ListContacts200ResponseContactsInner {
    /**
     * 
     * @type {string}
     * @memberof ListContacts200ResponseContactsInner
     */
    '_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof ListContacts200ResponseContactsInner
     */
    'first_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof ListContacts200ResponseContactsInner
     */
    'last_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof ListContacts200ResponseContactsInner
     */
    'email'?: string;
    /**
     * 
     * @type {string}
     * @memberof ListContacts200ResponseContactsInner
     */
    'phone'?: string;
    /**
     * 
     * @type {string}
     * @memberof ListContacts200ResponseContactsInner
     */
    'created_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof ListContacts200ResponseContactsInner
     */
    'updated_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof ListContacts200ResponseContactsInner
     */
    'deleted_at'?: string;
}
/**
 * 
 * @export
 * @interface ListDefaultDocumentImportSettings200Response
 */
export interface ListDefaultDocumentImportSettings200Response {
    /**
     * 
     * @type {boolean}
     * @memberof ListDefaultDocumentImportSettings200Response
     */
    'success'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof ListDefaultDocumentImportSettings200Response
     */
    'total'?: number;
    /**
     * 
     * @type {Array<ListDefaultDocumentImportSettings200ResponseDefaultDocumentImportSettingsInner>}
     * @memberof ListDefaultDocumentImportSettings200Response
     */
    'default_document_import_settings'?: Array<ListDefaultDocumentImportSettings200ResponseDefaultDocumentImportSettingsInner>;
    /**
     * 
     * @type {ListDefaultDocumentImportSettings200ResponseMergedSetting}
     * @memberof ListDefaultDocumentImportSettings200Response
     */
    'merged_setting'?: ListDefaultDocumentImportSettings200ResponseMergedSetting;
}
/**
 * 
 * @export
 * @interface ListDefaultDocumentImportSettings200ResponseDefaultDocumentImportSettingsInner
 */
export interface ListDefaultDocumentImportSettings200ResponseDefaultDocumentImportSettingsInner {
    /**
     * 
     * @type {string}
     * @memberof ListDefaultDocumentImportSettings200ResponseDefaultDocumentImportSettingsInner
     */
    '_id'?: string;
    /**
     * 
     * @type {ListDefaultDocumentImportSettings200ResponseDefaultDocumentImportSettingsInnerIntegration}
     * @memberof ListDefaultDocumentImportSettings200ResponseDefaultDocumentImportSettingsInner
     */
    'integration'?: ListDefaultDocumentImportSettings200ResponseDefaultDocumentImportSettingsInnerIntegration;
    /**
     * 
     * @type {ListDefaultDocumentImportSettings200ResponseDefaultDocumentImportSettingsInnerAssetManager}
     * @memberof ListDefaultDocumentImportSettings200ResponseDefaultDocumentImportSettingsInner
     */
    'asset_manager'?: ListDefaultDocumentImportSettings200ResponseDefaultDocumentImportSettingsInnerAssetManager;
    /**
     * 
     * @type {ListDefaultDocumentImportSettings200ResponseDefaultDocumentImportSettingsInnerAssetManager}
     * @memberof ListDefaultDocumentImportSettings200ResponseDefaultDocumentImportSettingsInner
     */
    'advisory_group'?: ListDefaultDocumentImportSettings200ResponseDefaultDocumentImportSettingsInnerAssetManager;
    /**
     * 
     * @type {string}
     * @memberof ListDefaultDocumentImportSettings200ResponseDefaultDocumentImportSettingsInner
     */
    'global_default_extraction_setting'?: ListDefaultDocumentImportSettings200ResponseDefaultDocumentImportSettingsInnerGlobalDefaultExtractionSettingEnum;
    /**
     * 
     * @type {string}
     * @memberof ListDefaultDocumentImportSettings200ResponseDefaultDocumentImportSettingsInner
     */
    'global_default_auto_sort_setting'?: ListDefaultDocumentImportSettings200ResponseDefaultDocumentImportSettingsInnerGlobalDefaultAutoSortSettingEnum;
    /**
     * 
     * @type {string}
     * @memberof ListDefaultDocumentImportSettings200ResponseDefaultDocumentImportSettingsInner
     */
    'global_default_transaction_setting'?: ListDefaultDocumentImportSettings200ResponseDefaultDocumentImportSettingsInnerGlobalDefaultTransactionSettingEnum;
    /**
     * 
     * @type {string}
     * @memberof ListDefaultDocumentImportSettings200ResponseDefaultDocumentImportSettingsInner
     */
    'global_default_process_setting'?: ListDefaultDocumentImportSettings200ResponseDefaultDocumentImportSettingsInnerGlobalDefaultProcessSettingEnum;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof ListDefaultDocumentImportSettings200ResponseDefaultDocumentImportSettingsInner
     */
    'default_transaction_settings'?: { [key: string]: string; };
    /**
     * 
     * @type {string}
     * @memberof ListDefaultDocumentImportSettings200ResponseDefaultDocumentImportSettingsInner
     */
    'created_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof ListDefaultDocumentImportSettings200ResponseDefaultDocumentImportSettingsInner
     */
    'updated_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof ListDefaultDocumentImportSettings200ResponseDefaultDocumentImportSettingsInner
     */
    'deleted_at'?: string;
}

export const ListDefaultDocumentImportSettings200ResponseDefaultDocumentImportSettingsInnerGlobalDefaultExtractionSettingEnum = {
    Enabled: 'enabled',
    Disabled: 'disabled',
    Unset: 'unset'
} as const;

export type ListDefaultDocumentImportSettings200ResponseDefaultDocumentImportSettingsInnerGlobalDefaultExtractionSettingEnum = typeof ListDefaultDocumentImportSettings200ResponseDefaultDocumentImportSettingsInnerGlobalDefaultExtractionSettingEnum[keyof typeof ListDefaultDocumentImportSettings200ResponseDefaultDocumentImportSettingsInnerGlobalDefaultExtractionSettingEnum];
export const ListDefaultDocumentImportSettings200ResponseDefaultDocumentImportSettingsInnerGlobalDefaultAutoSortSettingEnum = {
    Enabled: 'enabled',
    Disabled: 'disabled',
    Unset: 'unset'
} as const;

export type ListDefaultDocumentImportSettings200ResponseDefaultDocumentImportSettingsInnerGlobalDefaultAutoSortSettingEnum = typeof ListDefaultDocumentImportSettings200ResponseDefaultDocumentImportSettingsInnerGlobalDefaultAutoSortSettingEnum[keyof typeof ListDefaultDocumentImportSettings200ResponseDefaultDocumentImportSettingsInnerGlobalDefaultAutoSortSettingEnum];
export const ListDefaultDocumentImportSettings200ResponseDefaultDocumentImportSettingsInnerGlobalDefaultTransactionSettingEnum = {
    Enabled: 'enabled',
    Disabled: 'disabled',
    Unset: 'unset'
} as const;

export type ListDefaultDocumentImportSettings200ResponseDefaultDocumentImportSettingsInnerGlobalDefaultTransactionSettingEnum = typeof ListDefaultDocumentImportSettings200ResponseDefaultDocumentImportSettingsInnerGlobalDefaultTransactionSettingEnum[keyof typeof ListDefaultDocumentImportSettings200ResponseDefaultDocumentImportSettingsInnerGlobalDefaultTransactionSettingEnum];
export const ListDefaultDocumentImportSettings200ResponseDefaultDocumentImportSettingsInnerGlobalDefaultProcessSettingEnum = {
    Enabled: 'enabled',
    Disabled: 'disabled',
    Unset: 'unset'
} as const;

export type ListDefaultDocumentImportSettings200ResponseDefaultDocumentImportSettingsInnerGlobalDefaultProcessSettingEnum = typeof ListDefaultDocumentImportSettings200ResponseDefaultDocumentImportSettingsInnerGlobalDefaultProcessSettingEnum[keyof typeof ListDefaultDocumentImportSettings200ResponseDefaultDocumentImportSettingsInnerGlobalDefaultProcessSettingEnum];

/**
 * 
 * @export
 * @interface ListDefaultDocumentImportSettings200ResponseDefaultDocumentImportSettingsInnerAssetManager
 */
export interface ListDefaultDocumentImportSettings200ResponseDefaultDocumentImportSettingsInnerAssetManager {
    /**
     * 
     * @type {string}
     * @memberof ListDefaultDocumentImportSettings200ResponseDefaultDocumentImportSettingsInnerAssetManager
     */
    '_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof ListDefaultDocumentImportSettings200ResponseDefaultDocumentImportSettingsInnerAssetManager
     */
    'name'?: string;
}
/**
 * 
 * @export
 * @interface ListDefaultDocumentImportSettings200ResponseDefaultDocumentImportSettingsInnerIntegration
 */
export interface ListDefaultDocumentImportSettings200ResponseDefaultDocumentImportSettingsInnerIntegration {
    /**
     * 
     * @type {string}
     * @memberof ListDefaultDocumentImportSettings200ResponseDefaultDocumentImportSettingsInnerIntegration
     */
    '_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof ListDefaultDocumentImportSettings200ResponseDefaultDocumentImportSettingsInnerIntegration
     */
    'name'?: string;
}
/**
 * 
 * @export
 * @interface ListDefaultDocumentImportSettings200ResponseMergedSetting
 */
export interface ListDefaultDocumentImportSettings200ResponseMergedSetting {
    /**
     * 
     * @type {string}
     * @memberof ListDefaultDocumentImportSettings200ResponseMergedSetting
     */
    'global_default_extraction_setting'?: ListDefaultDocumentImportSettings200ResponseMergedSettingGlobalDefaultExtractionSettingEnum;
    /**
     * 
     * @type {string}
     * @memberof ListDefaultDocumentImportSettings200ResponseMergedSetting
     */
    'global_default_auto_sort_setting'?: ListDefaultDocumentImportSettings200ResponseMergedSettingGlobalDefaultAutoSortSettingEnum;
    /**
     * 
     * @type {string}
     * @memberof ListDefaultDocumentImportSettings200ResponseMergedSetting
     */
    'global_default_transaction_setting'?: ListDefaultDocumentImportSettings200ResponseMergedSettingGlobalDefaultTransactionSettingEnum;
    /**
     * 
     * @type {string}
     * @memberof ListDefaultDocumentImportSettings200ResponseMergedSetting
     */
    'global_default_process_setting'?: ListDefaultDocumentImportSettings200ResponseMergedSettingGlobalDefaultProcessSettingEnum;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof ListDefaultDocumentImportSettings200ResponseMergedSetting
     */
    'default_transaction_settings'?: { [key: string]: string; };
}

export const ListDefaultDocumentImportSettings200ResponseMergedSettingGlobalDefaultExtractionSettingEnum = {
    Enabled: 'enabled',
    Disabled: 'disabled'
} as const;

export type ListDefaultDocumentImportSettings200ResponseMergedSettingGlobalDefaultExtractionSettingEnum = typeof ListDefaultDocumentImportSettings200ResponseMergedSettingGlobalDefaultExtractionSettingEnum[keyof typeof ListDefaultDocumentImportSettings200ResponseMergedSettingGlobalDefaultExtractionSettingEnum];
export const ListDefaultDocumentImportSettings200ResponseMergedSettingGlobalDefaultAutoSortSettingEnum = {
    Enabled: 'enabled',
    Disabled: 'disabled'
} as const;

export type ListDefaultDocumentImportSettings200ResponseMergedSettingGlobalDefaultAutoSortSettingEnum = typeof ListDefaultDocumentImportSettings200ResponseMergedSettingGlobalDefaultAutoSortSettingEnum[keyof typeof ListDefaultDocumentImportSettings200ResponseMergedSettingGlobalDefaultAutoSortSettingEnum];
export const ListDefaultDocumentImportSettings200ResponseMergedSettingGlobalDefaultTransactionSettingEnum = {
    Enabled: 'enabled',
    Disabled: 'disabled'
} as const;

export type ListDefaultDocumentImportSettings200ResponseMergedSettingGlobalDefaultTransactionSettingEnum = typeof ListDefaultDocumentImportSettings200ResponseMergedSettingGlobalDefaultTransactionSettingEnum[keyof typeof ListDefaultDocumentImportSettings200ResponseMergedSettingGlobalDefaultTransactionSettingEnum];
export const ListDefaultDocumentImportSettings200ResponseMergedSettingGlobalDefaultProcessSettingEnum = {
    Enabled: 'enabled',
    Disabled: 'disabled'
} as const;

export type ListDefaultDocumentImportSettings200ResponseMergedSettingGlobalDefaultProcessSettingEnum = typeof ListDefaultDocumentImportSettings200ResponseMergedSettingGlobalDefaultProcessSettingEnum[keyof typeof ListDefaultDocumentImportSettings200ResponseMergedSettingGlobalDefaultProcessSettingEnum];

/**
 * 
 * @export
 * @interface ListDocuments200Response
 */
export interface ListDocuments200Response {
    /**
     * 
     * @type {boolean}
     * @memberof ListDocuments200Response
     */
    'success'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof ListDocuments200Response
     */
    'total'?: number;
    /**
     * 
     * @type {Array<ListDocuments200ResponseDocumentsInner>}
     * @memberof ListDocuments200Response
     */
    'documents'?: Array<ListDocuments200ResponseDocumentsInner>;
}
/**
 * 
 * @export
 * @interface ListDocuments200ResponseDocumentsInner
 */
export interface ListDocuments200ResponseDocumentsInner {
    /**
     * 
     * @type {string}
     * @memberof ListDocuments200ResponseDocumentsInner
     */
    '_id'?: string;
    /**
     * 
     * @type {ListAdminTeams200ResponseAdminTeamsInnerAdvisoryGroupsInner}
     * @memberof ListDocuments200ResponseDocumentsInner
     */
    'user'?: ListAdminTeams200ResponseAdminTeamsInnerAdvisoryGroupsInner;
    /**
     * 
     * @type {Array<ListDocuments200ResponseDocumentsInnerOwnershipIdsInner>}
     * @memberof ListDocuments200ResponseDocumentsInner
     */
    'ownership_ids'?: Array<ListDocuments200ResponseDocumentsInnerOwnershipIdsInner>;
    /**
     * 
     * @type {string}
     * @memberof ListDocuments200ResponseDocumentsInner
     */
    'ownership_type'?: string;
    /**
     * 
     * @type {ListAdminTeams200ResponseAdminTeamsInnerAdvisoryGroupsInner}
     * @memberof ListDocuments200ResponseDocumentsInner
     */
    'connection'?: ListAdminTeams200ResponseAdminTeamsInnerAdvisoryGroupsInner;
    /**
     * 
     * @type {Array<ListDocuments200ResponseDocumentsInnerMetadataInner>}
     * @memberof ListDocuments200ResponseDocumentsInner
     */
    'metadata'?: Array<ListDocuments200ResponseDocumentsInnerMetadataInner>;
    /**
     * 
     * @type {string}
     * @memberof ListDocuments200ResponseDocumentsInner
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof ListDocuments200ResponseDocumentsInner
     */
    'type'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ListDocuments200ResponseDocumentsInner
     */
    'hasValidMetadata'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ListDocuments200ResponseDocumentsInner
     */
    'shouldGenerateTransactions'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ListDocuments200ResponseDocumentsInner
     */
    'shouldProcessDocument'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ListDocuments200ResponseDocumentsInner
     */
    'date'?: string;
    /**
     * 
     * @type {string}
     * @memberof ListDocuments200ResponseDocumentsInner
     */
    'hash'?: string;
    /**
     * 
     * @type {string}
     * @memberof ListDocuments200ResponseDocumentsInner
     */
    'file_type'?: string;
    /**
     * 
     * @type {ListDocuments200ResponseDocumentsInnerFiles}
     * @memberof ListDocuments200ResponseDocumentsInner
     */
    'files'?: ListDocuments200ResponseDocumentsInnerFiles;
    /**
     * 
     * @type {ListDocuments200ResponseDocumentsInnerTraining}
     * @memberof ListDocuments200ResponseDocumentsInner
     */
    'training'?: ListDocuments200ResponseDocumentsInnerTraining;
    /**
     * 
     * @type {ListDocuments200ResponseDocumentsInnerTraining}
     * @memberof ListDocuments200ResponseDocumentsInner
     */
    'post_job_review_required'?: ListDocuments200ResponseDocumentsInnerTraining;
    /**
     * 
     * @type {string}
     * @memberof ListDocuments200ResponseDocumentsInner
     */
    'created_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof ListDocuments200ResponseDocumentsInner
     */
    'updated_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof ListDocuments200ResponseDocumentsInner
     */
    'deleted_at'?: string;
    /**
     * 
     * @type {ListDocuments200ResponseDocumentsInnerFileDownloadUrls}
     * @memberof ListDocuments200ResponseDocumentsInner
     */
    'file_download_urls'?: ListDocuments200ResponseDocumentsInnerFileDownloadUrls;
    /**
     * 
     * @type {Array<ListAccounts200ResponseAccountsInnerAuditLogInner>}
     * @memberof ListDocuments200ResponseDocumentsInner
     */
    'audit_log'?: Array<ListAccounts200ResponseAccountsInnerAuditLogInner>;
}
/**
 * 
 * @export
 * @interface ListDocuments200ResponseDocumentsInnerFileDownloadUrls
 */
export interface ListDocuments200ResponseDocumentsInnerFileDownloadUrls {
    /**
     * 
     * @type {string}
     * @memberof ListDocuments200ResponseDocumentsInnerFileDownloadUrls
     */
    'words'?: string;
    /**
     * 
     * @type {string}
     * @memberof ListDocuments200ResponseDocumentsInnerFileDownloadUrls
     */
    'doc'?: string;
    /**
     * 
     * @type {string}
     * @memberof ListDocuments200ResponseDocumentsInnerFileDownloadUrls
     */
    'stax_doc'?: string;
    /**
     * 
     * @type {string}
     * @memberof ListDocuments200ResponseDocumentsInnerFileDownloadUrls
     */
    'ocr_result'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof ListDocuments200ResponseDocumentsInnerFileDownloadUrls
     */
    'pages'?: Array<string>;
}
/**
 * 
 * @export
 * @interface ListDocuments200ResponseDocumentsInnerFiles
 */
export interface ListDocuments200ResponseDocumentsInnerFiles {
    /**
     * 
     * @type {string}
     * @memberof ListDocuments200ResponseDocumentsInnerFiles
     */
    'words'?: string;
    /**
     * 
     * @type {string}
     * @memberof ListDocuments200ResponseDocumentsInnerFiles
     */
    'doc'?: string;
    /**
     * 
     * @type {string}
     * @memberof ListDocuments200ResponseDocumentsInnerFiles
     */
    'stax_doc'?: string;
    /**
     * 
     * @type {string}
     * @memberof ListDocuments200ResponseDocumentsInnerFiles
     */
    'ocr_result'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof ListDocuments200ResponseDocumentsInnerFiles
     */
    'pages'?: Array<string>;
}
/**
 * 
 * @export
 * @interface ListDocuments200ResponseDocumentsInnerMetadataInner
 */
export interface ListDocuments200ResponseDocumentsInnerMetadataInner {
    /**
     * 
     * @type {string}
     * @memberof ListDocuments200ResponseDocumentsInnerMetadataInner
     */
    'key'?: string;
    /**
     * 
     * @type {string}
     * @memberof ListDocuments200ResponseDocumentsInnerMetadataInner
     */
    'investment'?: string;
    /**
     * 
     * @type {any}
     * @memberof ListDocuments200ResponseDocumentsInnerMetadataInner
     */
    'value'?: any;
    /**
     * 
     * @type {Array<ListDocuments200ResponseDocumentsInnerMetadataInnerTagsInner>}
     * @memberof ListDocuments200ResponseDocumentsInnerMetadataInner
     */
    'tags'?: Array<ListDocuments200ResponseDocumentsInnerMetadataInnerTagsInner>;
    /**
     * 
     * @type {boolean}
     * @memberof ListDocuments200ResponseDocumentsInnerMetadataInner
     */
    'shouldGenerateTransactions'?: boolean;
}
/**
 * 
 * @export
 * @interface ListDocuments200ResponseDocumentsInnerMetadataInnerTagsInner
 */
export interface ListDocuments200ResponseDocumentsInnerMetadataInnerTagsInner {
    /**
     * 
     * @type {number}
     * @memberof ListDocuments200ResponseDocumentsInnerMetadataInnerTagsInner
     */
    'page': number;
    /**
     * 
     * @type {number}
     * @memberof ListDocuments200ResponseDocumentsInnerMetadataInnerTagsInner
     */
    'line': number;
    /**
     * 
     * @type {number}
     * @memberof ListDocuments200ResponseDocumentsInnerMetadataInnerTagsInner
     */
    'word': number;
    /**
     * 
     * @type {string}
     * @memberof ListDocuments200ResponseDocumentsInnerMetadataInnerTagsInner
     */
    'text': string;
}
/**
 * 
 * @export
 * @interface ListDocuments200ResponseDocumentsInnerOwnershipIdsInner
 */
export interface ListDocuments200ResponseDocumentsInnerOwnershipIdsInner {
    /**
     * 
     * @type {string}
     * @memberof ListDocuments200ResponseDocumentsInnerOwnershipIdsInner
     */
    '_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof ListDocuments200ResponseDocumentsInnerOwnershipIdsInner
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof ListDocuments200ResponseDocumentsInnerOwnershipIdsInner
     */
    'type'?: string;
    /**
     * 
     * @type {string}
     * @memberof ListDocuments200ResponseDocumentsInnerOwnershipIdsInner
     */
    'currency'?: string;
    /**
     * 
     * @type {ListAdminTeams200ResponseAdminTeamsInnerAdvisoryGroupsInner}
     * @memberof ListDocuments200ResponseDocumentsInnerOwnershipIdsInner
     */
    'user'?: ListAdminTeams200ResponseAdminTeamsInnerAdvisoryGroupsInner;
    /**
     * 
     * @type {ListAdminTeams200ResponseAdminTeamsInnerAdvisoryGroupsInner}
     * @memberof ListDocuments200ResponseDocumentsInnerOwnershipIdsInner
     */
    'account'?: ListAdminTeams200ResponseAdminTeamsInnerAdvisoryGroupsInner;
}
/**
 * 
 * @export
 * @interface ListDocuments200ResponseDocumentsInnerTraining
 */
export interface ListDocuments200ResponseDocumentsInnerTraining {
    /**
     * 
     * @type {boolean}
     * @memberof ListDocuments200ResponseDocumentsInnerTraining
     */
    'sort'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ListDocuments200ResponseDocumentsInnerTraining
     */
    'extraction'?: boolean;
}
/**
 * 
 * @export
 * @interface ListIntegrations200Response
 */
export interface ListIntegrations200Response {
    /**
     * 
     * @type {boolean}
     * @memberof ListIntegrations200Response
     */
    'success'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof ListIntegrations200Response
     */
    'total'?: number;
    /**
     * 
     * @type {Array<ListIntegrations200ResponseIntegrationsInner>}
     * @memberof ListIntegrations200Response
     */
    'integrations'?: Array<ListIntegrations200ResponseIntegrationsInner>;
}
/**
 * 
 * @export
 * @interface ListIntegrations200ResponseIntegrationsInner
 */
export interface ListIntegrations200ResponseIntegrationsInner {
    /**
     * 
     * @type {string}
     * @memberof ListIntegrations200ResponseIntegrationsInner
     */
    '_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof ListIntegrations200ResponseIntegrationsInner
     */
    'name'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ListIntegrations200ResponseIntegrationsInner
     */
    'has_worker'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ListIntegrations200ResponseIntegrationsInner
     */
    'tfa_required'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ListIntegrations200ResponseIntegrationsInner
     */
    'phone_required'?: boolean;
    /**
     * 
     * @type {Array<ListIntegrations200ResponseIntegrationsInnerRequiredCredsInner>}
     * @memberof ListIntegrations200ResponseIntegrationsInner
     */
    'required_creds'?: Array<ListIntegrations200ResponseIntegrationsInnerRequiredCredsInner>;
    /**
     * 
     * @type {string}
     * @memberof ListIntegrations200ResponseIntegrationsInner
     */
    'portal_url'?: string;
    /**
     * 
     * @type {string}
     * @memberof ListIntegrations200ResponseIntegrationsInner
     */
    'lambda_arn'?: string;
    /**
     * 
     * @type {string}
     * @memberof ListIntegrations200ResponseIntegrationsInner
     */
    'cycle_container_id'?: string;
    /**
     * 
     * @type {number}
     * @memberof ListIntegrations200ResponseIntegrationsInner
     */
    'worker_version'?: number;
    /**
     * 
     * @type {string}
     * @memberof ListIntegrations200ResponseIntegrationsInner
     */
    'created_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof ListIntegrations200ResponseIntegrationsInner
     */
    'updated_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof ListIntegrations200ResponseIntegrationsInner
     */
    'deleted_at'?: string;
    /**
     * 
     * @type {number}
     * @memberof ListIntegrations200ResponseIntegrationsInner
     */
    'connections_count'?: number;
    /**
     * 
     * @type {number}
     * @memberof ListIntegrations200ResponseIntegrationsInner
     */
    'errored_connections_count'?: number;
    /**
     * 
     * @type {string}
     * @memberof ListIntegrations200ResponseIntegrationsInner
     */
    'logo_url'?: string;
}
/**
 * 
 * @export
 * @interface ListIntegrations200ResponseIntegrationsInnerRequiredCredsInner
 */
export interface ListIntegrations200ResponseIntegrationsInnerRequiredCredsInner {
    /**
     * 
     * @type {string}
     * @memberof ListIntegrations200ResponseIntegrationsInnerRequiredCredsInner
     */
    'key'?: string;
    /**
     * 
     * @type {string}
     * @memberof ListIntegrations200ResponseIntegrationsInnerRequiredCredsInner
     */
    'input'?: ListIntegrations200ResponseIntegrationsInnerRequiredCredsInnerInputEnum;
    /**
     * 
     * @type {Array<string>}
     * @memberof ListIntegrations200ResponseIntegrationsInnerRequiredCredsInner
     */
    'options'?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof ListIntegrations200ResponseIntegrationsInnerRequiredCredsInner
     */
    'hint'?: string;
}

export const ListIntegrations200ResponseIntegrationsInnerRequiredCredsInnerInputEnum = {
    Text: 'text',
    Password: 'password',
    Select: 'select',
    Checkbox: 'checkbox'
} as const;

export type ListIntegrations200ResponseIntegrationsInnerRequiredCredsInnerInputEnum = typeof ListIntegrations200ResponseIntegrationsInnerRequiredCredsInnerInputEnum[keyof typeof ListIntegrations200ResponseIntegrationsInnerRequiredCredsInnerInputEnum];

/**
 * 
 * @export
 * @interface ListInvestments200Response
 */
export interface ListInvestments200Response {
    /**
     * 
     * @type {boolean}
     * @memberof ListInvestments200Response
     */
    'success'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof ListInvestments200Response
     */
    'total'?: number;
    /**
     * 
     * @type {Array<ListInvestments200ResponseInvestmentsInner>}
     * @memberof ListInvestments200Response
     */
    'investments'?: Array<ListInvestments200ResponseInvestmentsInner>;
}
/**
 * 
 * @export
 * @interface ListInvestments200ResponseInvestmentsInner
 */
export interface ListInvestments200ResponseInvestmentsInner {
    /**
     * 
     * @type {string}
     * @memberof ListInvestments200ResponseInvestmentsInner
     */
    '_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof ListInvestments200ResponseInvestmentsInner
     */
    'type'?: string;
    /**
     * 
     * @type {string}
     * @memberof ListInvestments200ResponseInvestmentsInner
     */
    'name'?: string;
    /**
     * 
     * @type {ListInvestments200ResponseInvestmentsInnerUser}
     * @memberof ListInvestments200ResponseInvestmentsInner
     */
    'user'?: ListInvestments200ResponseInvestmentsInnerUser;
    /**
     * 
     * @type {ListConnectionSyncs200ResponseConnectionsyncsInnerConnectionUser}
     * @memberof ListInvestments200ResponseInvestmentsInner
     */
    'account'?: ListConnectionSyncs200ResponseConnectionsyncsInnerConnectionUser;
    /**
     * 
     * @type {ListInvestments200ResponseInvestmentsInnerConnection}
     * @memberof ListInvestments200ResponseInvestmentsInner
     */
    'connection'?: ListInvestments200ResponseInvestmentsInnerConnection;
    /**
     * 
     * @type {string}
     * @memberof ListInvestments200ResponseInvestmentsInner
     */
    'last_recalculate_time'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ListInvestments200ResponseInvestmentsInner
     */
    'hidden'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ListInvestments200ResponseInvestmentsInner
     */
    'incomplete'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ListInvestments200ResponseInvestmentsInner
     */
    'inactive'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ListInvestments200ResponseInvestmentsInner
     */
    'inactive_date'?: string;
    /**
     * 
     * @type {number}
     * @memberof ListInvestments200ResponseInvestmentsInner
     */
    'initial_contribution'?: number;
    /**
     * 
     * @type {{ [key: string]: any; }}
     * @memberof ListInvestments200ResponseInvestmentsInner
     */
    'investment_data'?: { [key: string]: any; };
    /**
     * 
     * @type {ListInvestments200ResponseInvestmentsInnerInvestmentMaster}
     * @memberof ListInvestments200ResponseInvestmentsInner
     */
    'investment_master'?: ListInvestments200ResponseInvestmentsInnerInvestmentMaster;
    /**
     * 
     * @type {string}
     * @memberof ListInvestments200ResponseInvestmentsInner
     */
    'currency'?: ListInvestments200ResponseInvestmentsInnerCurrencyEnum;
    /**
     * 
     * @type {{ [key: string]: any; }}
     * @memberof ListInvestments200ResponseInvestmentsInner
     */
    'performance'?: { [key: string]: any; };
    /**
     * 
     * @type {number}
     * @memberof ListInvestments200ResponseInvestmentsInner
     */
    'valuation'?: number;
    /**
     * 
     * @type {number}
     * @memberof ListInvestments200ResponseInvestmentsInner
     */
    'beginning'?: number;
    /**
     * 
     * @type {string}
     * @memberof ListInvestments200ResponseInvestmentsInner
     */
    'tax_document_requested'?: string;
    /**
     * 
     * @type {number}
     * @memberof ListInvestments200ResponseInvestmentsInner
     */
    'expected_tax_documents'?: number;
    /**
     * 
     * @type {string}
     * @memberof ListInvestments200ResponseInvestmentsInner
     */
    'started_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof ListInvestments200ResponseInvestmentsInner
     */
    'first_cashflow_date'?: string;
    /**
     * 
     * @type {string}
     * @memberof ListInvestments200ResponseInvestmentsInner
     */
    'created_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof ListInvestments200ResponseInvestmentsInner
     */
    'updated_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof ListInvestments200ResponseInvestmentsInner
     */
    'deleted_at'?: string;
    /**
     * 
     * @type {Array<ListAccounts200ResponseAccountsInnerAuditLogInner>}
     * @memberof ListInvestments200ResponseInvestmentsInner
     */
    'audit_log'?: Array<ListAccounts200ResponseAccountsInnerAuditLogInner>;
    /**
     * 
     * @type {boolean}
     * @memberof ListInvestments200ResponseInvestmentsInner
     */
    'exclude_from_billing'?: boolean;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof ListInvestments200ResponseInvestmentsInner
     */
    'attributes'?: { [key: string]: string; };
}

export const ListInvestments200ResponseInvestmentsInnerCurrencyEnum = {
    Usd: 'USD',
    Eur: 'EUR',
    Cny: 'CNY',
    Jpy: 'JPY',
    Gbp: 'GBP',
    Aud: 'AUD',
    Cad: 'CAD',
    Chf: 'CHF',
    Inr: 'INR',
    Krw: 'KRW',
    Btc: 'BTC'
} as const;

export type ListInvestments200ResponseInvestmentsInnerCurrencyEnum = typeof ListInvestments200ResponseInvestmentsInnerCurrencyEnum[keyof typeof ListInvestments200ResponseInvestmentsInnerCurrencyEnum];

/**
 * 
 * @export
 * @interface ListInvestments200ResponseInvestmentsInnerConnection
 */
export interface ListInvestments200ResponseInvestmentsInnerConnection {
    /**
     * 
     * @type {string}
     * @memberof ListInvestments200ResponseInvestmentsInnerConnection
     */
    '_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof ListInvestments200ResponseInvestmentsInnerConnection
     */
    'name'?: string;
    /**
     * 
     * @type {ListInvestments200ResponseInvestmentsInnerConnectionIntegration}
     * @memberof ListInvestments200ResponseInvestmentsInnerConnection
     */
    'integration'?: ListInvestments200ResponseInvestmentsInnerConnectionIntegration;
}
/**
 * 
 * @export
 * @interface ListInvestments200ResponseInvestmentsInnerConnectionIntegration
 */
export interface ListInvestments200ResponseInvestmentsInnerConnectionIntegration {
    /**
     * 
     * @type {string}
     * @memberof ListInvestments200ResponseInvestmentsInnerConnectionIntegration
     */
    'logo_url'?: string;
}
/**
 * 
 * @export
 * @interface ListInvestments200ResponseInvestmentsInnerInvestmentMaster
 */
export interface ListInvestments200ResponseInvestmentsInnerInvestmentMaster {
    /**
     * 
     * @type {string}
     * @memberof ListInvestments200ResponseInvestmentsInnerInvestmentMaster
     */
    '_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof ListInvestments200ResponseInvestmentsInnerInvestmentMaster
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof ListInvestments200ResponseInvestmentsInnerInvestmentMaster
     */
    'ticker'?: string;
    /**
     * 
     * @type {ListConnectionSyncs200ResponseConnectionsyncsInnerConnectionUser}
     * @memberof ListInvestments200ResponseInvestmentsInnerInvestmentMaster
     */
    'asset_manager'?: ListConnectionSyncs200ResponseConnectionsyncsInnerConnectionUser;
}
/**
 * 
 * @export
 * @interface ListInvestments200ResponseInvestmentsInnerUser
 */
export interface ListInvestments200ResponseInvestmentsInnerUser {
    /**
     * 
     * @type {string}
     * @memberof ListInvestments200ResponseInvestmentsInnerUser
     */
    '_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof ListInvestments200ResponseInvestmentsInnerUser
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof ListInvestments200ResponseInvestmentsInnerUser
     */
    'email'?: string;
}
/**
 * 
 * @export
 * @interface ListMessages200Response
 */
export interface ListMessages200Response {
    /**
     * 
     * @type {boolean}
     * @memberof ListMessages200Response
     */
    'success'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof ListMessages200Response
     */
    'total'?: number;
    /**
     * 
     * @type {Array<GetMessageById200ResponseMessage>}
     * @memberof ListMessages200Response
     */
    'messages'?: Array<GetMessageById200ResponseMessage>;
}
/**
 * 
 * @export
 * @interface ListRALRequestTemplates200Response
 */
export interface ListRALRequestTemplates200Response {
    /**
     * 
     * @type {boolean}
     * @memberof ListRALRequestTemplates200Response
     */
    'success'?: boolean;
    /**
     * 
     * @type {Array<ListRALRequestTemplates200ResponseTemplatesInner>}
     * @memberof ListRALRequestTemplates200Response
     */
    'templates'?: Array<ListRALRequestTemplates200ResponseTemplatesInner>;
}
/**
 * 
 * @export
 * @interface ListRALRequestTemplates200ResponseTemplatesInner
 */
export interface ListRALRequestTemplates200ResponseTemplatesInner {
    /**
     * 
     * @type {string}
     * @memberof ListRALRequestTemplates200ResponseTemplatesInner
     */
    '_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof ListRALRequestTemplates200ResponseTemplatesInner
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof ListRALRequestTemplates200ResponseTemplatesInner
     */
    'description'?: string;
    /**
     * 
     * @type {string}
     * @memberof ListRALRequestTemplates200ResponseTemplatesInner
     */
    'panda_doc_template_id'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ListRALRequestTemplates200ResponseTemplatesInner
     */
    'default'?: boolean;
    /**
     * 
     * @type {Array<ListRALRequestTemplates200ResponseTemplatesInnerRequiredSignatoriesInner>}
     * @memberof ListRALRequestTemplates200ResponseTemplatesInner
     */
    'required_signatories'?: Array<ListRALRequestTemplates200ResponseTemplatesInnerRequiredSignatoriesInner>;
    /**
     * 
     * @type {Array<string>}
     * @memberof ListRALRequestTemplates200ResponseTemplatesInner
     */
    'required_tokens'?: Array<string>;
}
/**
 * 
 * @export
 * @interface ListRALRequestTemplates200ResponseTemplatesInnerRequiredSignatoriesInner
 */
export interface ListRALRequestTemplates200ResponseTemplatesInnerRequiredSignatoriesInner {
    /**
     * 
     * @type {string}
     * @memberof ListRALRequestTemplates200ResponseTemplatesInnerRequiredSignatoriesInner
     */
    'role'?: string;
    /**
     * 
     * @type {number}
     * @memberof ListRALRequestTemplates200ResponseTemplatesInnerRequiredSignatoriesInner
     */
    'count'?: number;
}
/**
 * 
 * @export
 * @interface ListRALRequests200Response
 */
export interface ListRALRequests200Response {
    /**
     * 
     * @type {boolean}
     * @memberof ListRALRequests200Response
     */
    'success'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof ListRALRequests200Response
     */
    'total'?: number;
    /**
     * 
     * @type {Array<ListRALRequests200ResponseRalRequestsInner>}
     * @memberof ListRALRequests200Response
     */
    'ral_requests'?: Array<ListRALRequests200ResponseRalRequestsInner>;
}
/**
 * 
 * @export
 * @interface ListRALRequests200ResponseRalRequestsInner
 */
export interface ListRALRequests200ResponseRalRequestsInner {
    /**
     * 
     * @type {string}
     * @memberof ListRALRequests200ResponseRalRequestsInner
     */
    '_id'?: string;
    /**
     * 
     * @type {ListRALRequests200ResponseRalRequestsInnerUser}
     * @memberof ListRALRequests200ResponseRalRequestsInner
     */
    'user'?: ListRALRequests200ResponseRalRequestsInnerUser;
    /**
     * 
     * @type {Array<ListDocuments200ResponseDocumentsInnerOwnershipIdsInner>}
     * @memberof ListRALRequests200ResponseRalRequestsInner
     */
    'ownership_ids'?: Array<ListDocuments200ResponseDocumentsInnerOwnershipIdsInner>;
    /**
     * 
     * @type {string}
     * @memberof ListRALRequests200ResponseRalRequestsInner
     */
    'ownership_type'?: string;
    /**
     * 
     * @type {ListRALRequests200ResponseRalRequestsInnerSignedRal}
     * @memberof ListRALRequests200ResponseRalRequestsInner
     */
    'signed_ral'?: ListRALRequests200ResponseRalRequestsInnerSignedRal;
    /**
     * 
     * @type {Array<ListRALRequests200ResponseRalRequestsInnerSignatoriesInner>}
     * @memberof ListRALRequests200ResponseRalRequestsInner
     */
    'signatories'?: Array<ListRALRequests200ResponseRalRequestsInnerSignatoriesInner>;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof ListRALRequests200ResponseRalRequestsInner
     */
    'tokens'?: { [key: string]: string; };
    /**
     * 
     * @type {ListAdminTeams200ResponseAdminTeamsInnerAdvisoryGroupsInner}
     * @memberof ListRALRequests200ResponseRalRequestsInner
     */
    'ral_request_template'?: ListAdminTeams200ResponseAdminTeamsInnerAdvisoryGroupsInner;
    /**
     * 
     * @type {string}
     * @memberof ListRALRequests200ResponseRalRequestsInner
     */
    'ral_panda_doc_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof ListRALRequests200ResponseRalRequestsInner
     */
    'ral_panda_doc_status'?: string;
    /**
     * 
     * @type {string}
     * @memberof ListRALRequests200ResponseRalRequestsInner
     */
    'sent_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof ListRALRequests200ResponseRalRequestsInner
     */
    'signed_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof ListRALRequests200ResponseRalRequestsInner
     */
    'created_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof ListRALRequests200ResponseRalRequestsInner
     */
    'updated_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof ListRALRequests200ResponseRalRequestsInner
     */
    'deleted_at'?: string;
}
/**
 * 
 * @export
 * @interface ListRALRequests200ResponseRalRequestsInnerSignatoriesInner
 */
export interface ListRALRequests200ResponseRalRequestsInnerSignatoriesInner {
    /**
     * 
     * @type {string}
     * @memberof ListRALRequests200ResponseRalRequestsInnerSignatoriesInner
     */
    '_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof ListRALRequests200ResponseRalRequestsInnerSignatoriesInner
     */
    'first_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof ListRALRequests200ResponseRalRequestsInnerSignatoriesInner
     */
    'last_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof ListRALRequests200ResponseRalRequestsInnerSignatoriesInner
     */
    'role'?: string;
    /**
     * 
     * @type {string}
     * @memberof ListRALRequests200ResponseRalRequestsInnerSignatoriesInner
     */
    'email'?: string;
}
/**
 * 
 * @export
 * @interface ListRALRequests200ResponseRalRequestsInnerSignedRal
 */
export interface ListRALRequests200ResponseRalRequestsInnerSignedRal {
    /**
     * 
     * @type {string}
     * @memberof ListRALRequests200ResponseRalRequestsInnerSignedRal
     */
    '_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof ListRALRequests200ResponseRalRequestsInnerSignedRal
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof ListRALRequests200ResponseRalRequestsInnerSignedRal
     */
    'status'?: string;
}
/**
 * 
 * @export
 * @interface ListRALRequests200ResponseRalRequestsInnerUser
 */
export interface ListRALRequests200ResponseRalRequestsInnerUser {
    /**
     * 
     * @type {string}
     * @memberof ListRALRequests200ResponseRalRequestsInnerUser
     */
    '_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof ListRALRequests200ResponseRalRequestsInnerUser
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof ListRALRequests200ResponseRalRequestsInnerUser
     */
    'email'?: string;
    /**
     * 
     * @type {Array<ListRALRequests200ResponseRalRequestsInnerUserAdvisorsInner>}
     * @memberof ListRALRequests200ResponseRalRequestsInnerUser
     */
    'advisors'?: Array<ListRALRequests200ResponseRalRequestsInnerUserAdvisorsInner>;
}
/**
 * 
 * @export
 * @interface ListRALRequests200ResponseRalRequestsInnerUserAdvisorsInner
 */
export interface ListRALRequests200ResponseRalRequestsInnerUserAdvisorsInner {
    /**
     * 
     * @type {string}
     * @memberof ListRALRequests200ResponseRalRequestsInnerUserAdvisorsInner
     */
    '_id'?: string;
    /**
     * 
     * @type {ListConnectionSyncs200ResponseConnectionsyncsInnerConnectionUser}
     * @memberof ListRALRequests200ResponseRalRequestsInnerUserAdvisorsInner
     */
    'group'?: ListConnectionSyncs200ResponseConnectionsyncsInnerConnectionUser;
}
/**
 * 
 * @export
 * @interface ListTransactions200Response
 */
export interface ListTransactions200Response {
    /**
     * 
     * @type {boolean}
     * @memberof ListTransactions200Response
     */
    'success'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof ListTransactions200Response
     */
    'total'?: number;
    /**
     * 
     * @type {Array<ListTransactions200ResponseTransactionsInner>}
     * @memberof ListTransactions200Response
     */
    'transactions'?: Array<ListTransactions200ResponseTransactionsInner>;
}
/**
 * 
 * @export
 * @interface ListTransactions200ResponseTransactionsInner
 */
export interface ListTransactions200ResponseTransactionsInner {
    /**
     * 
     * @type {string}
     * @memberof ListTransactions200ResponseTransactionsInner
     */
    '_id'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ListTransactions200ResponseTransactionsInner
     */
    'sample_data'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ListTransactions200ResponseTransactionsInner
     */
    'type'?: string;
    /**
     * 
     * @type {ListAdminTeams200ResponseAdminTeamsInnerAdvisoryGroupsInner}
     * @memberof ListTransactions200ResponseTransactionsInner
     */
    'user'?: ListAdminTeams200ResponseAdminTeamsInnerAdvisoryGroupsInner;
    /**
     * 
     * @type {ListAdminTeams200ResponseAdminTeamsInnerAdvisoryGroupsInner}
     * @memberof ListTransactions200ResponseTransactionsInner
     */
    'account'?: ListAdminTeams200ResponseAdminTeamsInnerAdvisoryGroupsInner;
    /**
     * 
     * @type {ListCommitments200ResponseCommitmentsInnerInvestment}
     * @memberof ListTransactions200ResponseTransactionsInner
     */
    'investment'?: ListCommitments200ResponseCommitmentsInnerInvestment;
    /**
     * 
     * @type {ListAdminTeams200ResponseAdminTeamsInnerAdvisoryGroupsInner}
     * @memberof ListTransactions200ResponseTransactionsInner
     */
    'document'?: ListAdminTeams200ResponseAdminTeamsInnerAdvisoryGroupsInner;
    /**
     * 
     * @type {ListAdminTeams200ResponseAdminTeamsInnerAdvisoryGroupsInner}
     * @memberof ListTransactions200ResponseTransactionsInner
     */
    'connection'?: ListAdminTeams200ResponseAdminTeamsInnerAdvisoryGroupsInner;
    /**
     * 
     * @type {boolean}
     * @memberof ListTransactions200ResponseTransactionsInner
     */
    'auto_extracted'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ListTransactions200ResponseTransactionsInner
     */
    'date'?: string;
    /**
     * 
     * @type {number}
     * @memberof ListTransactions200ResponseTransactionsInner
     */
    'amount'?: number;
    /**
     * 
     * @type {number}
     * @memberof ListTransactions200ResponseTransactionsInner
     */
    'units'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof ListTransactions200ResponseTransactionsInner
     */
    'open'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ListTransactions200ResponseTransactionsInner
     */
    'description'?: string;
    /**
     * 
     * @type {string}
     * @memberof ListTransactions200ResponseTransactionsInner
     */
    'linked_transaction'?: string;
    /**
     * 
     * @type {ListTransactions200ResponseTransactionsInnerJointTransaction}
     * @memberof ListTransactions200ResponseTransactionsInner
     */
    'joint_transaction'?: ListTransactions200ResponseTransactionsInnerJointTransaction;
    /**
     * 
     * @type {Array<ListTransactions200ResponseTransactionsInnerJointTransactionJointTransactionsInner>}
     * @memberof ListTransactions200ResponseTransactionsInner
     */
    'joint_transactions'?: Array<ListTransactions200ResponseTransactionsInnerJointTransactionJointTransactionsInner>;
    /**
     * 
     * @type {string}
     * @memberof ListTransactions200ResponseTransactionsInner
     */
    'created_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof ListTransactions200ResponseTransactionsInner
     */
    'updated_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof ListTransactions200ResponseTransactionsInner
     */
    'deleted_at'?: string;
    /**
     * 
     * @type {Array<ListAccounts200ResponseAccountsInnerAuditLogInner>}
     * @memberof ListTransactions200ResponseTransactionsInner
     */
    'audit_log'?: Array<ListAccounts200ResponseAccountsInnerAuditLogInner>;
}
/**
 * 
 * @export
 * @interface ListTransactions200ResponseTransactionsInnerJointTransaction
 */
export interface ListTransactions200ResponseTransactionsInnerJointTransaction {
    /**
     * 
     * @type {string}
     * @memberof ListTransactions200ResponseTransactionsInnerJointTransaction
     */
    '_id'?: string;
    /**
     * 
     * @type {number}
     * @memberof ListTransactions200ResponseTransactionsInnerJointTransaction
     */
    'amount'?: number;
    /**
     * 
     * @type {string}
     * @memberof ListTransactions200ResponseTransactionsInnerJointTransaction
     */
    'type'?: string;
    /**
     * 
     * @type {string}
     * @memberof ListTransactions200ResponseTransactionsInnerJointTransaction
     */
    'date'?: string;
    /**
     * 
     * @type {Array<ListTransactions200ResponseTransactionsInnerJointTransactionJointTransactionsInner>}
     * @memberof ListTransactions200ResponseTransactionsInnerJointTransaction
     */
    'joint_transactions'?: Array<ListTransactions200ResponseTransactionsInnerJointTransactionJointTransactionsInner>;
}
/**
 * 
 * @export
 * @interface ListTransactions200ResponseTransactionsInnerJointTransactionJointTransactionsInner
 */
export interface ListTransactions200ResponseTransactionsInnerJointTransactionJointTransactionsInner {
    /**
     * 
     * @type {string}
     * @memberof ListTransactions200ResponseTransactionsInnerJointTransactionJointTransactionsInner
     */
    '_id'?: string;
    /**
     * 
     * @type {number}
     * @memberof ListTransactions200ResponseTransactionsInnerJointTransactionJointTransactionsInner
     */
    'amount'?: number;
    /**
     * 
     * @type {string}
     * @memberof ListTransactions200ResponseTransactionsInnerJointTransactionJointTransactionsInner
     */
    'type'?: string;
    /**
     * 
     * @type {string}
     * @memberof ListTransactions200ResponseTransactionsInnerJointTransactionJointTransactionsInner
     */
    'date'?: string;
}
/**
 * 
 * @export
 * @interface ListUsers200Response
 */
export interface ListUsers200Response {
    /**
     * 
     * @type {boolean}
     * @memberof ListUsers200Response
     */
    'success'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof ListUsers200Response
     */
    'total'?: number;
    /**
     * 
     * @type {Array<ListUsers200ResponseUsersInner>}
     * @memberof ListUsers200Response
     */
    'users'?: Array<ListUsers200ResponseUsersInner>;
}
/**
 * 
 * @export
 * @interface ListUsers200ResponseUsersInner
 */
export interface ListUsers200ResponseUsersInner {
    /**
     * 
     * @type {string}
     * @memberof ListUsers200ResponseUsersInner
     */
    '_id'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ListUsers200ResponseUsersInner
     */
    'has_password'?: boolean;
    /**
     * 
     * @type {Array<ListUsers200ResponseUsersInnerInvestmentsInner>}
     * @memberof ListUsers200ResponseUsersInner
     */
    'investments'?: Array<ListUsers200ResponseUsersInnerInvestmentsInner>;
    /**
     * 
     * @type {number}
     * @memberof ListUsers200ResponseUsersInner
     */
    'investments_count'?: number;
    /**
     * 
     * @type {Array<ListUsers200ResponseUsersInnerAccountsInner>}
     * @memberof ListUsers200ResponseUsersInner
     */
    'accounts'?: Array<ListUsers200ResponseUsersInnerAccountsInner>;
    /**
     * 
     * @type {string}
     * @memberof ListUsers200ResponseUsersInner
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof ListUsers200ResponseUsersInner
     */
    'email'?: string;
    /**
     * 
     * @type {ListUsers200ResponseUsersInnerEmailVerification}
     * @memberof ListUsers200ResponseUsersInner
     */
    'email_verification'?: ListUsers200ResponseUsersInnerEmailVerification;
    /**
     * 
     * @type {string}
     * @memberof ListUsers200ResponseUsersInner
     */
    'access'?: string;
    /**
     * 
     * @type {string}
     * @memberof ListUsers200ResponseUsersInner
     */
    'created_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof ListUsers200ResponseUsersInner
     */
    'updated_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof ListUsers200ResponseUsersInner
     */
    'deleted_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof ListUsers200ResponseUsersInner
     */
    'status'?: string;
    /**
     * 
     * @type {ListUsers200ResponseUsersInnerTfa}
     * @memberof ListUsers200ResponseUsersInner
     */
    'tfa'?: ListUsers200ResponseUsersInnerTfa;
    /**
     * 
     * @type {ListUsers200ResponseUsersInnerNotificationSettings}
     * @memberof ListUsers200ResponseUsersInner
     */
    'notificationSettings'?: ListUsers200ResponseUsersInnerNotificationSettings;
    /**
     * 
     * @type {boolean}
     * @memberof ListUsers200ResponseUsersInner
     */
    'sample_data'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof ListUsers200ResponseUsersInner
     */
    'failed_attempts'?: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof ListUsers200ResponseUsersInner
     */
    'setup_email_sent_times'?: Array<string>;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof ListUsers200ResponseUsersInner
     */
    'external_ids'?: { [key: string]: string; };
    /**
     * 
     * @type {string}
     * @memberof ListUsers200ResponseUsersInner
     */
    'last_login_at'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof ListUsers200ResponseUsersInner
     */
    'setup_email_send_times'?: Array<string>;
    /**
     * 
     * @type {Array<ListUsers200ResponseUsersInnerAdvisorGroupMembershipsInner>}
     * @memberof ListUsers200ResponseUsersInner
     */
    'advisor_group_memberships'?: Array<ListUsers200ResponseUsersInnerAdvisorGroupMembershipsInner>;
    /**
     * 
     * @type {any}
     * @memberof ListUsers200ResponseUsersInner
     */
    'sessions'?: any;
    /**
     * 
     * @type {Array<ListUsers200ResponseUsersInnerNotificationsInner>}
     * @memberof ListUsers200ResponseUsersInner
     */
    'notifications'?: Array<ListUsers200ResponseUsersInnerNotificationsInner>;
    /**
     * 
     * @type {Array<ListUsers200ResponseUsersInnerAdvisorsInner>}
     * @memberof ListUsers200ResponseUsersInner
     */
    'advisors'?: Array<ListUsers200ResponseUsersInnerAdvisorsInner>;
    /**
     * 
     * @type {string}
     * @memberof ListUsers200ResponseUsersInner
     */
    'outreach_status'?: string;
}
/**
 * 
 * @export
 * @interface ListUsers200ResponseUsersInnerAccountsInner
 */
export interface ListUsers200ResponseUsersInnerAccountsInner {
    /**
     * 
     * @type {string}
     * @memberof ListUsers200ResponseUsersInnerAccountsInner
     */
    '_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof ListUsers200ResponseUsersInnerAccountsInner
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof ListUsers200ResponseUsersInnerAccountsInner
     */
    'created_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof ListUsers200ResponseUsersInnerAccountsInner
     */
    'updated_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof ListUsers200ResponseUsersInnerAccountsInner
     */
    'deleted_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof ListUsers200ResponseUsersInnerAccountsInner
     */
    'account_type'?: string;
    /**
     * 
     * @type {number}
     * @memberof ListUsers200ResponseUsersInnerAccountsInner
     */
    'investments_count'?: number;
}
/**
 * 
 * @export
 * @interface ListUsers200ResponseUsersInnerAdvisorGroupMembershipsInner
 */
export interface ListUsers200ResponseUsersInnerAdvisorGroupMembershipsInner {
    /**
     * 
     * @type {ListUsers200ResponseUsersInnerAdvisorGroupMembershipsInnerGroup}
     * @memberof ListUsers200ResponseUsersInnerAdvisorGroupMembershipsInner
     */
    'group'?: ListUsers200ResponseUsersInnerAdvisorGroupMembershipsInnerGroup;
    /**
     * 
     * @type {string}
     * @memberof ListUsers200ResponseUsersInnerAdvisorGroupMembershipsInner
     */
    'role'?: string;
    /**
     * 
     * @type {Array<ListUsers200ResponseUsersInnerAdvisorGroupMembershipsInnerClientsInner>}
     * @memberof ListUsers200ResponseUsersInnerAdvisorGroupMembershipsInner
     */
    'clients'?: Array<ListUsers200ResponseUsersInnerAdvisorGroupMembershipsInnerClientsInner>;
    /**
     * 
     * @type {string}
     * @memberof ListUsers200ResponseUsersInnerAdvisorGroupMembershipsInner
     */
    'invited_on'?: string;
    /**
     * 
     * @type {string}
     * @memberof ListUsers200ResponseUsersInnerAdvisorGroupMembershipsInner
     */
    'accepted_on'?: string;
    /**
     * 
     * @type {string}
     * @memberof ListUsers200ResponseUsersInnerAdvisorGroupMembershipsInner
     */
    '_id'?: string;
}
/**
 * 
 * @export
 * @interface ListUsers200ResponseUsersInnerAdvisorGroupMembershipsInnerClientsInner
 */
export interface ListUsers200ResponseUsersInnerAdvisorGroupMembershipsInnerClientsInner {
    /**
     * 
     * @type {string}
     * @memberof ListUsers200ResponseUsersInnerAdvisorGroupMembershipsInnerClientsInner
     */
    '_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof ListUsers200ResponseUsersInnerAdvisorGroupMembershipsInnerClientsInner
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof ListUsers200ResponseUsersInnerAdvisorGroupMembershipsInnerClientsInner
     */
    'email'?: string;
    /**
     * 
     * @type {string}
     * @memberof ListUsers200ResponseUsersInnerAdvisorGroupMembershipsInnerClientsInner
     */
    'created_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof ListUsers200ResponseUsersInnerAdvisorGroupMembershipsInnerClientsInner
     */
    'updated_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof ListUsers200ResponseUsersInnerAdvisorGroupMembershipsInnerClientsInner
     */
    'deleted_at'?: string;
}
/**
 * 
 * @export
 * @interface ListUsers200ResponseUsersInnerAdvisorGroupMembershipsInnerGroup
 */
export interface ListUsers200ResponseUsersInnerAdvisorGroupMembershipsInnerGroup {
    /**
     * 
     * @type {string}
     * @memberof ListUsers200ResponseUsersInnerAdvisorGroupMembershipsInnerGroup
     */
    '_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof ListUsers200ResponseUsersInnerAdvisorGroupMembershipsInnerGroup
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof ListUsers200ResponseUsersInnerAdvisorGroupMembershipsInnerGroup
     */
    'created_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof ListUsers200ResponseUsersInnerAdvisorGroupMembershipsInnerGroup
     */
    'updated_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof ListUsers200ResponseUsersInnerAdvisorGroupMembershipsInnerGroup
     */
    'deleted_at'?: string;
}
/**
 * 
 * @export
 * @interface ListUsers200ResponseUsersInnerAdvisorsInner
 */
export interface ListUsers200ResponseUsersInnerAdvisorsInner {
    /**
     * 
     * @type {string}
     * @memberof ListUsers200ResponseUsersInnerAdvisorsInner
     */
    'role'?: string;
    /**
     * 
     * @type {ListUsers200ResponseUsersInnerAdvisorGroupMembershipsInnerGroup}
     * @memberof ListUsers200ResponseUsersInnerAdvisorsInner
     */
    'group'?: ListUsers200ResponseUsersInnerAdvisorGroupMembershipsInnerGroup;
    /**
     * 
     * @type {string}
     * @memberof ListUsers200ResponseUsersInnerAdvisorsInner
     */
    'invited_on'?: string;
    /**
     * 
     * @type {string}
     * @memberof ListUsers200ResponseUsersInnerAdvisorsInner
     */
    'accepted_on'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof ListUsers200ResponseUsersInnerAdvisorsInner
     */
    'permissions'?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof ListUsers200ResponseUsersInnerAdvisorsInner
     */
    '_id'?: string;
}
/**
 * 
 * @export
 * @interface ListUsers200ResponseUsersInnerEmailVerification
 */
export interface ListUsers200ResponseUsersInnerEmailVerification {
    /**
     * 
     * @type {Array<string>}
     * @memberof ListUsers200ResponseUsersInnerEmailVerification
     */
    'send_times'?: Array<string>;
    /**
     * 
     * @type {boolean}
     * @memberof ListUsers200ResponseUsersInnerEmailVerification
     */
    'verified'?: boolean;
}
/**
 * 
 * @export
 * @interface ListUsers200ResponseUsersInnerInvestmentsInner
 */
export interface ListUsers200ResponseUsersInnerInvestmentsInner {
    /**
     * 
     * @type {string}
     * @memberof ListUsers200ResponseUsersInnerInvestmentsInner
     */
    '_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof ListUsers200ResponseUsersInnerInvestmentsInner
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof ListUsers200ResponseUsersInnerInvestmentsInner
     */
    'account'?: string;
    /**
     * 
     * @type {string}
     * @memberof ListUsers200ResponseUsersInnerInvestmentsInner
     */
    'connection'?: string;
    /**
     * 
     * @type {string}
     * @memberof ListUsers200ResponseUsersInnerInvestmentsInner
     */
    'created_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof ListUsers200ResponseUsersInnerInvestmentsInner
     */
    'updated_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof ListUsers200ResponseUsersInnerInvestmentsInner
     */
    'deleted_at'?: string;
}
/**
 * 
 * @export
 * @interface ListUsers200ResponseUsersInnerNotificationSettings
 */
export interface ListUsers200ResponseUsersInnerNotificationSettings {
    /**
     * 
     * @type {boolean}
     * @memberof ListUsers200ResponseUsersInnerNotificationSettings
     */
    'Master Enabled'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ListUsers200ResponseUsersInnerNotificationSettings
     */
    'CapitalCall'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ListUsers200ResponseUsersInnerNotificationSettings
     */
    'Distribution'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ListUsers200ResponseUsersInnerNotificationSettings
     */
    'TaxDocument'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ListUsers200ResponseUsersInnerNotificationSettings
     */
    'ConnectionActive'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ListUsers200ResponseUsersInnerNotificationSettings
     */
    'ConnectionError'?: boolean;
}
/**
 * 
 * @export
 * @interface ListUsers200ResponseUsersInnerNotificationsInner
 */
export interface ListUsers200ResponseUsersInnerNotificationsInner {
    /**
     * 
     * @type {string}
     * @memberof ListUsers200ResponseUsersInnerNotificationsInner
     */
    'timestamp'?: string;
    /**
     * 
     * @type {string}
     * @memberof ListUsers200ResponseUsersInnerNotificationsInner
     */
    'title'?: string;
    /**
     * 
     * @type {string}
     * @memberof ListUsers200ResponseUsersInnerNotificationsInner
     */
    'description'?: string;
    /**
     * 
     * @type {string}
     * @memberof ListUsers200ResponseUsersInnerNotificationsInner
     */
    'notificationType'?: string;
    /**
     * 
     * @type {string}
     * @memberof ListUsers200ResponseUsersInnerNotificationsInner
     */
    '_id'?: string;
}
/**
 * 
 * @export
 * @interface ListUsers200ResponseUsersInnerTfa
 */
export interface ListUsers200ResponseUsersInnerTfa {
    /**
     * 
     * @type {ListUsers200ResponseUsersInnerTfaTotp}
     * @memberof ListUsers200ResponseUsersInnerTfa
     */
    'totp'?: ListUsers200ResponseUsersInnerTfaTotp;
    /**
     * 
     * @type {ListUsers200ResponseUsersInnerTfaTotp}
     * @memberof ListUsers200ResponseUsersInnerTfa
     */
    'sms'?: ListUsers200ResponseUsersInnerTfaTotp;
    /**
     * 
     * @type {Array<ListUsers200ResponseUsersInnerTfaRecoveryCodesInner>}
     * @memberof ListUsers200ResponseUsersInnerTfa
     */
    'recovery_codes'?: Array<ListUsers200ResponseUsersInnerTfaRecoveryCodesInner>;
}
/**
 * 
 * @export
 * @interface ListUsers200ResponseUsersInnerTfaRecoveryCodesInner
 */
export interface ListUsers200ResponseUsersInnerTfaRecoveryCodesInner {
    /**
     * 
     * @type {string}
     * @memberof ListUsers200ResponseUsersInnerTfaRecoveryCodesInner
     */
    'code'?: string;
}
/**
 * 
 * @export
 * @interface ListUsers200ResponseUsersInnerTfaTotp
 */
export interface ListUsers200ResponseUsersInnerTfaTotp {
    /**
     * 
     * @type {boolean}
     * @memberof ListUsers200ResponseUsersInnerTfaTotp
     */
    'enabled'?: boolean;
}
/**
 * 
 * @export
 * @interface ListValuations200Response
 */
export interface ListValuations200Response {
    /**
     * 
     * @type {boolean}
     * @memberof ListValuations200Response
     */
    'success'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof ListValuations200Response
     */
    'total'?: number;
    /**
     * 
     * @type {Array<ListValuations200ResponseValuationsInner>}
     * @memberof ListValuations200Response
     */
    'valuations'?: Array<ListValuations200ResponseValuationsInner>;
}
/**
 * 
 * @export
 * @interface ListValuations200ResponseValuationsInner
 */
export interface ListValuations200ResponseValuationsInner {
    /**
     * 
     * @type {string}
     * @memberof ListValuations200ResponseValuationsInner
     */
    '_id'?: string;
    /**
     * 
     * @type {ListValuations200ResponseValuationsInnerUser}
     * @memberof ListValuations200ResponseValuationsInner
     */
    'user'?: ListValuations200ResponseValuationsInnerUser;
    /**
     * 
     * @type {ListAdminTeams200ResponseAdminTeamsInnerAdvisoryGroupsInner}
     * @memberof ListValuations200ResponseValuationsInner
     */
    'account'?: ListAdminTeams200ResponseAdminTeamsInnerAdvisoryGroupsInner;
    /**
     * 
     * @type {ListCommitments200ResponseCommitmentsInnerInvestment}
     * @memberof ListValuations200ResponseValuationsInner
     */
    'investment'?: ListCommitments200ResponseCommitmentsInnerInvestment;
    /**
     * 
     * @type {ListAdminTeams200ResponseAdminTeamsInnerAdvisoryGroupsInner}
     * @memberof ListValuations200ResponseValuationsInner
     */
    'connection'?: ListAdminTeams200ResponseAdminTeamsInnerAdvisoryGroupsInner;
    /**
     * 
     * @type {Array<ListValuations200ResponseValuationsInnerDocumentsInner>}
     * @memberof ListValuations200ResponseValuationsInner
     */
    'documents'?: Array<ListValuations200ResponseValuationsInnerDocumentsInner>;
    /**
     * 
     * @type {boolean}
     * @memberof ListValuations200ResponseValuationsInner
     */
    'auto_extracted'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ListValuations200ResponseValuationsInner
     */
    'date'?: string;
    /**
     * 
     * @type {number}
     * @memberof ListValuations200ResponseValuationsInner
     */
    'amount'?: number;
    /**
     * 
     * @type {number}
     * @memberof ListValuations200ResponseValuationsInner
     */
    'unit_price'?: number;
    /**
     * 
     * @type {string}
     * @memberof ListValuations200ResponseValuationsInner
     */
    'created_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof ListValuations200ResponseValuationsInner
     */
    'updated_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof ListValuations200ResponseValuationsInner
     */
    'deleted_at'?: string;
    /**
     * 
     * @type {Array<ListAccounts200ResponseAccountsInnerAuditLogInner>}
     * @memberof ListValuations200ResponseValuationsInner
     */
    'audit_log'?: Array<ListAccounts200ResponseAccountsInnerAuditLogInner>;
}
/**
 * 
 * @export
 * @interface ListValuations200ResponseValuationsInnerDocumentsInner
 */
export interface ListValuations200ResponseValuationsInnerDocumentsInner {
    /**
     * 
     * @type {string}
     * @memberof ListValuations200ResponseValuationsInnerDocumentsInner
     */
    '_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof ListValuations200ResponseValuationsInnerDocumentsInner
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof ListValuations200ResponseValuationsInnerDocumentsInner
     */
    'type'?: string;
    /**
     * 
     * @type {string}
     * @memberof ListValuations200ResponseValuationsInnerDocumentsInner
     */
    'user'?: string;
    /**
     * 
     * @type {string}
     * @memberof ListValuations200ResponseValuationsInnerDocumentsInner
     */
    'ownership_type'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof ListValuations200ResponseValuationsInnerDocumentsInner
     */
    'ownership_ids'?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof ListValuations200ResponseValuationsInnerDocumentsInner
     */
    'date'?: string;
}
/**
 * 
 * @export
 * @interface ListValuations200ResponseValuationsInnerUser
 */
export interface ListValuations200ResponseValuationsInnerUser {
    /**
     * 
     * @type {string}
     * @memberof ListValuations200ResponseValuationsInnerUser
     */
    '_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof ListValuations200ResponseValuationsInnerUser
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof ListValuations200ResponseValuationsInnerUser
     */
    'email'?: string;
}
/**
 * 
 * @export
 * @interface LoginUserWithGoogle200Response
 */
export interface LoginUserWithGoogle200Response {
    /**
     * 
     * @type {boolean}
     * @memberof LoginUserWithGoogle200Response
     */
    'success'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof LoginUserWithGoogle200Response
     */
    'token'?: string;
}
/**
 * 
 * @export
 * @interface LoginUserWithGoogleRequest
 */
export interface LoginUserWithGoogleRequest {
    /**
     * A JWT credential from the Google Identity API
     * @type {string}
     * @memberof LoginUserWithGoogleRequest
     */
    'credential': string;
}
/**
 * Message categories.
 * @export
 * @enum {string}
 */

export const MessageCategory = {
    Tfa: 'tfa'
} as const;

export type MessageCategory = typeof MessageCategory[keyof typeof MessageCategory];


/**
 * Message types.
 * @export
 * @enum {string}
 */

export const MessageType = {
    Email: 'email',
    Sms: 'sms',
    Manual: 'manual'
} as const;

export type MessageType = typeof MessageType[keyof typeof MessageType];


/**
 * Notification send options.
 * @export
 * @enum {string}
 */

export const NotificationSendOptions = {
    Email: 'email',
    Calendar: 'calendar'
} as const;

export type NotificationSendOptions = typeof NotificationSendOptions[keyof typeof NotificationSendOptions];


/**
 * Notification types.
 * @export
 * @enum {string}
 */

export const NotificationTypes = {
    CapitalCall: 'Capital Call',
    Distribution: 'Distribution',
    TaxDocument: 'Tax Document',
    ConnectionActive: 'Connection Active',
    ConnectionError: 'Connection Error'
} as const;

export type NotificationTypes = typeof NotificationTypes[keyof typeof NotificationTypes];


/**
 * User permissions.
 * @export
 * @enum {string}
 */

export const Permissions = {
    User: 'user',
    Partner: 'partner',
    Admin: 'admin',
    Advisor: 'advisor',
    Internal: 'internal',
    Extegration: 'extegration',
    Investor: 'investor',
    Sync: 'sync',
    None: 'none'
} as const;

export type Permissions = typeof Permissions[keyof typeof Permissions];


/**
 * RAL PandaDoc statuses.
 * @export
 * @enum {string}
 */

export const RALPandaDocStatus = {
    Unstarted: 'unstarted',
    DocumentUploaded: 'document.uploaded',
    DocumentDraft: 'document.draft',
    DocumentSent: 'document.sent',
    DocumentViewed: 'document.viewed',
    DocumentWaitingApproval: 'document.waiting_approval',
    DocumentRejected: 'document.rejected',
    DocumentApproved: 'document.approved',
    DocumentWaitingPay: 'document.waiting_pay',
    DocumentPaid: 'document.paid',
    DocumentCompleted: 'document.completed',
    DocumentVoided: 'document.voided',
    DocumentDeclined: 'document.declined',
    DocumentExternalReview: 'document.external_review'
} as const;

export type RALPandaDocStatus = typeof RALPandaDocStatus[keyof typeof RALPandaDocStatus];


/**
 * 
 * @export
 * @interface ReprocessMetadata200Response
 */
export interface ReprocessMetadata200Response {
    /**
     * 
     * @type {boolean}
     * @memberof ReprocessMetadata200Response
     */
    'success'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ReprocessMetadata200Response
     */
    'message'?: string;
    /**
     * 
     * @type {string}
     * @memberof ReprocessMetadata200Response
     */
    'metadata_error'?: string;
}
/**
 * Resource methods.
 * @export
 * @enum {string}
 */

export const ResourceMethods = {
    Portal: 'portal',
    Link: 'link',
    Api: 'api',
    Auto: 'auto',
    None: 'none',
    Unknown: 'unknown'
} as const;

export type ResourceMethods = typeof ResourceMethods[keyof typeof ResourceMethods];


/**
 * 
 * @export
 * @interface SendRALRequest200Response
 */
export interface SendRALRequest200Response {
    /**
     * 
     * @type {boolean}
     * @memberof SendRALRequest200Response
     */
    'success'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof SendRALRequest200Response
     */
    'message'?: string;
}
/**
 * 
 * @export
 * @interface SendRALRequestRequest
 */
export interface SendRALRequestRequest {
    /**
     * 
     * @type {string}
     * @memberof SendRALRequestRequest
     */
    'ral_request_id': string;
    /**
     * 
     * @type {boolean}
     * @memberof SendRALRequestRequest
     */
    'send_silent'?: boolean;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const SyncInterval = {
    Daily: 'daily',
    Weekdays: 'weekdays',
    Weekly: 'weekly',
    Monthly: 'monthly',
    Tetrahourly: 'tetrahourly'
} as const;

export type SyncInterval = typeof SyncInterval[keyof typeof SyncInterval];


/**
 * Tax document form types.
 * @export
 * @enum {string}
 */

export const TaxDocumentFormType = {
    K1: 'K-1',
    Estimate: 'Estimate',
    _1099: '1099',
    W9: 'W-9',
    Other: 'Other'
} as const;

export type TaxDocumentFormType = typeof TaxDocumentFormType[keyof typeof TaxDocumentFormType];


/**
 * 
 * @export
 * @enum {string}
 */

export const TransactionNotificationStatus = {
    Unsent: 'unsent',
    Sent: 'sent',
    Error: 'error',
    NoNeedToSend: 'no_need_to_send'
} as const;

export type TransactionNotificationStatus = typeof TransactionNotificationStatus[keyof typeof TransactionNotificationStatus];


/**
 * The types of transactions.
 * @export
 * @interface TransactionTypes
 */
export interface TransactionTypes {
    /**
     * 
     * @type {string}
     * @memberof TransactionTypes
     */
    'typeArr'?: TransactionTypesTypeArrEnum;
    /**
     * 
     * @type {TransactionTypesTypeMap}
     * @memberof TransactionTypes
     */
    'typeMap'?: TransactionTypesTypeMap;
}

export const TransactionTypesTypeArrEnum = {
    CapitalCalltransactionTypeNameCapitalCalltransactionTypeNameOldCapitalCallinvestmentTypePrivateEquitycategoryAlternativesallowedNegative1allowedExport1capitalCalled1amountInvested1unitEffect1unrealizedValue1cashEffect1displayEffect1contributionEffect0transactionTypeBlackDiamondNameCapitalCalltransactionTypeAddeparNamecapitalCall: '{\"transaction_type\":\"capital_call\",\"transaction_type_name\":\"Capital Call\",\"transaction_type_name_old\":\"Capital Call\",\"investment_type\":\"Private Equity\",\"category\":\"Alternatives\",\"allowed_negative\":\"1\",\"allowed_export\":\"1\",\"capital_called\":\"1\",\"amount_invested\":\"1\",\"unit_effect\":\"1\",\"unrealized_value\":\"1\",\"cash_effect\":\"-1\",\"display_effect\":\"-1\",\"contribution_effect\":\"0\",\"transaction_type_black_diamond_name\":\"Capital Call\",\"transaction_type_addepar_name\":\"capital_call\"}',
    CapitalCommitmenttransactionTypeNameCapitalCommittmenttransactionTypeNameOldCapitalCommittmentinvestmentTypePrivateEquitycategoryJournalsallowedNegative1allowedExport1committedCapital1displayEffect1transactionTypeBlackDiamondNameCommittedCapitaltransactionTypeAddeparNamecommitment: '{\"transaction_type\":\"capital_commitment\",\"transaction_type_name\":\"Capital Committment\",\"transaction_type_name_old\":\"Capital Committment\",\"investment_type\":\"Private Equity\",\"category\":\"Journals\",\"allowed_negative\":\"1\",\"allowed_export\":\"1\",\"committed_capital\":\"1\",\"display_effect\":\"1\",\"transaction_type_black_diamond_name\":\"Committed Capital\",\"transaction_type_addepar_name\":\"commitment\"}',
    CashDeposittransactionTypeNameCashDeposittransactionTypeNameOldCashDepositinvestmentTypeStandardcategoryContributionsallowedExport1cashEffect1displayEffect1contributionEffect1transactionTypeBlackDiamondNameCashDeposit: '{\"transaction_type\":\"cash_deposit\",\"transaction_type_name\":\"Cash Deposit\",\"transaction_type_name_old\":\"Cash Deposit\",\"investment_type\":\"Standard\",\"category\":\"Contributions\",\"allowed_export\":\"1\",\"cash_effect\":\"1\",\"display_effect\":\"1\",\"contribution_effect\":\"1\",\"transaction_type_black_diamond_name\":\"Cash Deposit\"}',
    CashDistributionReturnOfCapitaltransactionTypeNameCashDistributionReturnOfCapitaltransactionTypeNameOldCashDistributionReturnOfCapitalinvestmentTypeGeneralAlternativescategoryOtherallowedExport1amountInvested1unrealizedValue1realizedValue1cashEffect1displayEffect1transactionTypeBlackDiamondNameCashDistributionReturnOfCapitaltransactionTypeAddeparNamedistribution: '{\"transaction_type\":\"cash_distribution_return_of_capital\",\"transaction_type_name\":\"Cash Distribution - Return of Capital\",\"transaction_type_name_old\":\"Cash Distribution - Return of Capital\",\"investment_type\":\"General Alternatives\",\"category\":\"Other\",\"allowed_export\":\"1\",\"amount_invested\":\"-1\",\"unrealized_value\":\"-1\",\"realized_value\":\"1\",\"cash_effect\":\"1\",\"display_effect\":\"1\",\"transaction_type_black_diamond_name\":\"Cash Distribution - Return of Capital\",\"transaction_type_addepar_name\":\"distribution\"}',
    CashWithdrawaltransactionTypeNameCashWithdrawaltransactionTypeNameOldCashWithdrawalinvestmentTypeStandardcategoryWithdrawalsallowedExport1cashEffect1displayEffect1contributionEffect1transactionTypeBlackDiamondNameCashWithdrawal: '{\"transaction_type\":\"cash_withdrawal\",\"transaction_type_name\":\"Cash Withdrawal\",\"transaction_type_name_old\":\"Cash Withdrawal\",\"investment_type\":\"Standard\",\"category\":\"Withdrawals\",\"allowed_export\":\"1\",\"cash_effect\":\"-1\",\"display_effect\":\"-1\",\"contribution_effect\":\"-1\",\"transaction_type_black_diamond_name\":\"Cash Withdrawal\"}',
    BuytransactionTypeNameContributiontransactionTypeNameOldContributioninvestmentTypeHedgeFundscategoryBuysallowedNegative1allowedExport1capitalCalled1amountInvested1unitEffect1unrealizedValue1cashEffect1displayEffect1contributionEffect0transactionTypeBlackDiamondNameBuytransactionTypeAddeparNamecontribution: '{\"transaction_type\":\"buy\",\"transaction_type_name\":\"Contribution\",\"transaction_type_name_old\":\"Contribution\",\"investment_type\":\"Hedge Funds\",\"category\":\"Buys\",\"allowed_negative\":\"1\",\"allowed_export\":\"1\",\"capital_called\":\"1\",\"amount_invested\":\"1\",\"unit_effect\":\"1\",\"unrealized_value\":\"1\",\"cash_effect\":\"-1\",\"display_effect\":\"-1\",\"contribution_effect\":\"0\",\"transaction_type_black_diamond_name\":\"Buy\",\"transaction_type_addepar_name\":\"contribution\"}',
    DividendReversaltransactionTypeNameDividendReversaltransactionTypeNameOldDividendReversalinvestmentTypeStandardcategoryIncomeallowedExport1realizedValue1cashEffect1displayEffect1transactionTypeBlackDiamondNameDividendReversal: '{\"transaction_type\":\"dividend_reversal\",\"transaction_type_name\":\"Dividend Reversal\",\"transaction_type_name_old\":\"Dividend Reversal\",\"investment_type\":\"Standard\",\"category\":\"Income\",\"allowed_export\":\"1\",\"realized_value\":\"-1\",\"cash_effect\":\"-1\",\"display_effect\":\"-1\",\"transaction_type_black_diamond_name\":\"Dividend Reversal\"}',
    DividendtransactionTypeNameDividendIncometransactionTypeNameOldDividendinvestmentTypeStandardcategoryIncomeallowedExport1realizedValue1cashEffect1displayEffect1transactionTypeBlackDiamondNameDividendtransactionTypeAddeparNamedistribution: '{\"transaction_type\":\"dividend\",\"transaction_type_name\":\"Dividend/Income\",\"transaction_type_name_old\":\"Dividend\",\"investment_type\":\"Standard\",\"category\":\"Income\",\"allowed_export\":\"1\",\"realized_value\":\"1\",\"cash_effect\":\"1\",\"display_effect\":\"1\",\"transaction_type_black_diamond_name\":\"Dividend\",\"transaction_type_addepar_name\":\"distribution\"}',
    IncentiveFeetransactionTypeNameIncentiveFeetransactionTypeNameOldPerformanceAllocationinvestmentTypeGeneralAlternativescategoryFeesallowedNegative1allowedExport1unrealizedValue1feeEffect1displayEffect1transactionTypeBlackDiamondNameIncentiveFeetransactionTypeAddeparNamefee: '{\"transaction_type\":\"incentive_fee\",\"transaction_type_name\":\"Incentive Fee\",\"transaction_type_name_old\":\"Performance Allocation\",\"investment_type\":\"General Alternatives\",\"category\":\"Fees\",\"allowed_negative\":\"1\",\"allowed_export\":\"1\",\"unrealized_value\":\"-1\",\"fee_effect\":\"1\",\"display_effect\":\"-1\",\"transaction_type_black_diamond_name\":\"Incentive Fee\",\"transaction_type_addepar_name\":\"fee\"}',
    InterestExpensetransactionTypeNameInterestExpenseinvestmentTypeGeneralAlternativescategoryIncomeallowedExport1unrealizedValue1feeEffect1cashEffect1displayEffect1contributionEffect1transactionTypeAddeparNameinterestExpense: '{\"transaction_type\":\"interest_expense\",\"transaction_type_name\":\"Interest Expense\",\"investment_type\":\"General Alternatives\",\"category\":\"Income\",\"allowed_export\":\"1\",\"unrealized_value\":\"-1\",\"fee_effect\":\"1\",\"cash_effect\":\"-1\",\"display_effect\":\"-1\",\"contribution_effect\":\"-1\",\"transaction_type_addepar_name\":\"interest_expense\"}',
    InterestIncometransactionTypeNameInterestIncomeinvestmentTypeGeneralAlternativescategoryIncomeallowedExport1unrealizedValue1cashEffect1displayEffect1contributionEffect1transactionTypeAddeparNameincome: '{\"transaction_type\":\"interest_income\",\"transaction_type_name\":\"Interest Income\",\"investment_type\":\"General Alternatives\",\"category\":\"Income\",\"allowed_export\":\"1\",\"unrealized_value\":\"1\",\"cash_effect\":\"1\",\"display_effect\":\"1\",\"contribution_effect\":\"1\",\"transaction_type_addepar_name\":\"income\"}',
    JointTransactiontransactionTypeNameJointTransactiontransactionTypeNameOldJointTransactioninvestmentTypeStandardcategoryOtherdisplayEffect1: '{\"transaction_type\":\"joint_transaction\",\"transaction_type_name\":\"Joint Transaction\",\"transaction_type_name_old\":\"Joint Transaction\",\"investment_type\":\"Standard\",\"category\":\"Other\",\"display_effect\":\"1\"}',
    ManagementFeetransactionTypeNameManagementFeetransactionTypeNameOldManagementFeeinvestmentTypeStandardcategoryFeesallowedNegative1allowedExport1unrealizedValue1feeEffect1displayEffect1contributionEffect1transactionTypeBlackDiamondNameManagementFeetransactionTypeAddeparNamefee: '{\"transaction_type\":\"management_fee\",\"transaction_type_name\":\"Management Fee\",\"transaction_type_name_old\":\"Management Fee\",\"investment_type\":\"Standard\",\"category\":\"Fees\",\"allowed_negative\":\"1\",\"allowed_export\":\"1\",\"unrealized_value\":\"-1\",\"fee_effect\":\"1\",\"display_effect\":\"-1\",\"contribution_effect\":\"-1\",\"transaction_type_black_diamond_name\":\"Management Fee\",\"transaction_type_addepar_name\":\"fee\"}',
    ManagementFeeRebatetransactionTypeNameManagementFeeRebatetransactionTypeNameOldManagementFeeRebateinvestmentTypeStandardcategoryFeesallowedNegative1allowedExport1unrealizedValue1feeEffect1displayEffect1contributionEffect1transactionTypeBlackDiamondNameManagementFeeReversaltransactionTypeAddeparNamefeeReimbursement: '{\"transaction_type\":\"management_fee_rebate\",\"transaction_type_name\":\"Management Fee Rebate\",\"transaction_type_name_old\":\"Management Fee Rebate\",\"investment_type\":\"Standard\",\"category\":\"Fees\",\"allowed_negative\":\"1\",\"allowed_export\":\"1\",\"unrealized_value\":\"1\",\"fee_effect\":\"-1\",\"display_effect\":\"1\",\"contribution_effect\":\"1\",\"transaction_type_black_diamond_name\":\"Management Fee Reversal\",\"transaction_type_addepar_name\":\"fee_reimbursement\"}',
    OthertransactionTypeNameOtherinvestmentTypeStandardcategoryOtherdisplayEffect1: '{\"transaction_type\":\"other\",\"transaction_type_name\":\"Other\",\"investment_type\":\"Standard\",\"category\":\"Other\",\"display_effect\":\"1\"}',
    OtherFeetransactionTypeNameOtherFeetransactionTypeNameOldOtherFeeinvestmentTypeStandardcategoryWithdrawalsallowedNegative1allowedExport1unrealizedValue1feeEffect1displayEffect1contributionEffect1transactionTypeBlackDiamondNameOtherFeetransactionTypeAddeparNamefee: '{\"transaction_type\":\"other_fee\",\"transaction_type_name\":\"Other Fee\",\"transaction_type_name_old\":\"Other Fee\",\"investment_type\":\"Standard\",\"category\":\"Withdrawals\",\"allowed_negative\":\"1\",\"allowed_export\":\"1\",\"unrealized_value\":\"-1\",\"fee_effect\":\"1\",\"display_effect\":\"-1\",\"contribution_effect\":\"-1\",\"transaction_type_black_diamond_name\":\"Other Fee\",\"transaction_type_addepar_name\":\"fee\"}',
    ReturnOfCapitaltransactionTypeNamePrincipalDistributiontransactionTypeNameOldPrincipalDistributioninvestmentTypeGeneralAlternativescategoryOtherallowedNegative1allowedExport1amountInvested1unrealizedValue1realizedValue1cashEffect1displayEffect1transactionTypeBlackDiamondNameCashDistributionReturnOfCapitaltransactionTypeAddeparNamedistribution: '{\"transaction_type\":\"return_of_capital\",\"transaction_type_name\":\"Principal Distribution\",\"transaction_type_name_old\":\"Principal Distribution\",\"investment_type\":\"General Alternatives\",\"category\":\"Other\",\"allowed_negative\":\"1\",\"allowed_export\":\"1\",\"amount_invested\":\"-1\",\"unrealized_value\":\"-1\",\"realized_value\":\"1\",\"cash_effect\":\"1\",\"display_effect\":\"1\",\"transaction_type_black_diamond_name\":\"Cash Distribution - Return of Capital\",\"transaction_type_addepar_name\":\"distribution\"}',
    ReturnOnCapitaltransactionTypeNameProfitDistributiontransactionTypeNameOldProfitDistributioninvestmentTypeGeneralAlternativescategoryIncomeallowedNegative1allowedExport1realizedValue1cashEffect1displayEffect1transactionTypeBlackDiamondNameCashDistributionIncometransactionTypeAddeparNamedistribution: '{\"transaction_type\":\"return_on_capital\",\"transaction_type_name\":\"Profit Distribution\",\"transaction_type_name_old\":\"Profit Distribution\",\"investment_type\":\"General Alternatives\",\"category\":\"Income\",\"allowed_negative\":\"1\",\"allowed_export\":\"1\",\"realized_value\":\"1\",\"cash_effect\":\"1\",\"display_effect\":\"1\",\"transaction_type_black_diamond_name\":\"Cash Distribution - Income\",\"transaction_type_addepar_name\":\"distribution\"}',
    RecallableReturnOfCapitaltransactionTypeNameRecallablePrincipalDistributiontransactionTypeNameOldRecallablePrincipalDistributioninvestmentTypePrivateEquitycategoryOtherallowedNegative1allowedExport1capitalCalled1amountInvested1unrealizedValue1realizedValue1cashEffect1displayEffect1transactionTypeBlackDiamondNameCashDistributionReturnOfCapitaltransactionTypeAddeparNamedistribution: '{\"transaction_type\":\"recallable_return_of_capital\",\"transaction_type_name\":\"Recallable Principal Distribution\",\"transaction_type_name_old\":\"Recallable Principal Distribution\",\"investment_type\":\"Private Equity\",\"category\":\"Other\",\"allowed_negative\":\"1\",\"allowed_export\":\"1\",\"capital_called\":\"-1\",\"amount_invested\":\"-1\",\"unrealized_value\":\"-1\",\"realized_value\":\"1\",\"cash_effect\":\"1\",\"display_effect\":\"1\",\"transaction_type_black_diamond_name\":\"Cash Distribution - Return of Capital\",\"transaction_type_addepar_name\":\"distribution\"}',
    RecallableReturnOnCapitaltransactionTypeNameRecallableProfitDistributiontransactionTypeNameOldRecallableProfitDistributioninvestmentTypePrivateEquitycategoryIncomeallowedNegative1allowedExport1capitalCalled1realizedValue1cashEffect1displayEffect1transactionTypeBlackDiamondNameCashDistributionIncometransactionTypeAddeparNamedistribution: '{\"transaction_type\":\"recallable_return_on_capital\",\"transaction_type_name\":\"Recallable Profit Distribution\",\"transaction_type_name_old\":\"Recallable Profit Distribution\",\"investment_type\":\"Private Equity\",\"category\":\"Income\",\"allowed_negative\":\"1\",\"allowed_export\":\"1\",\"capital_called\":\"-1\",\"realized_value\":\"1\",\"cash_effect\":\"1\",\"display_effect\":\"1\",\"transaction_type_black_diamond_name\":\"Cash Distribution - Income\",\"transaction_type_addepar_name\":\"distribution\"}',
    RedemptiontransactionTypeNameRedemptiontransactionTypeNameOldRedemptionWithdrawalHFinvestmentTypeHedgeFundscategorySellsallowedExport1unrealizedValue1realizedValue1cashEffect1displayEffect1transactionTypeBlackDiamondNameRedemptiontransactionTypeAddeparNamefundRedemption: '{\"transaction_type\":\"redemption\",\"transaction_type_name\":\"Redemption\",\"transaction_type_name_old\":\"Redemption / Withdrawal (HF)\",\"investment_type\":\"Hedge Funds\",\"category\":\"Sells\",\"allowed_export\":\"1\",\"unrealized_value\":\"-1\",\"realized_value\":\"1\",\"cash_effect\":\"1\",\"display_effect\":\"1\",\"transaction_type_black_diamond_name\":\"Redemption\",\"transaction_type_addepar_name\":\"fund_redemption\"}',
    ReinvestmenttransactionTypeNameReinvestmentinvestmentTypeStandardcategoryBuysallowedExport1amountInvested1unitEffect1unrealizedValue1cashEffect1displayEffect1contributionEffect0transactionTypeBlackDiamondNameBuytransactionTypeAddeparNamereinvestment: '{\"transaction_type\":\"reinvestment\",\"transaction_type_name\":\"Reinvestment\",\"investment_type\":\"Standard\",\"category\":\"Buys\",\"allowed_export\":\"1\",\"amount_invested\":\"1\",\"unit_effect\":\"1\",\"unrealized_value\":\"1\",\"cash_effect\":\"-1\",\"display_effect\":\"-1\",\"contribution_effect\":\"0\",\"transaction_type_black_diamond_name\":\"Buy\",\"transaction_type_addepar_name\":\"reinvestment\"}',
    SubscriptiontransactionTypeNameSubscriptiontransactionTypeNameOldSubscriptionContributionHFinvestmentTypeHedgeFundscategoryBuyallowedExport1amountInvested1cashEffect1displayEffect1transactionTypeBlackDiamondNameSubscriptiontransactionTypeAddeparNamebuy: '{\"transaction_type\":\"subscription\",\"transaction_type_name\":\"Subscription\",\"transaction_type_name_old\":\"Subscription / Contribution (HF)\",\"investment_type\":\"Hedge Funds\",\"category\":\"Buy\",\"allowed_export\":\"1\",\"amount_invested\":\"1\",\"cash_effect\":\"-1\",\"display_effect\":\"-1\",\"transaction_type_black_diamond_name\":\"Subscription\",\"transaction_type_addepar_name\":\"buy\"}',
    TransferIntransactionTypeNameTransferIntransactionTypeNameOldTransferIninvestmentTypeGeneralAlternativescategoryBuysallowedExport1amountInvested1unitEffect1unrealizedValue1cashEffect1displayEffect1transactionTypeBlackDiamondNameTransfertransactionTypeAddeparNametransfer: '{\"transaction_type\":\"transfer_in\",\"transaction_type_name\":\"Transfer In\",\"transaction_type_name_old\":\"Transfer In\",\"investment_type\":\"General Alternatives\",\"category\":\"Buys\",\"allowed_export\":\"1\",\"amount_invested\":\"1\",\"unit_effect\":\"1\",\"unrealized_value\":\"1\",\"cash_effect\":\"-1\",\"display_effect\":\"1\",\"transaction_type_black_diamond_name\":\"Transfer\",\"transaction_type_addepar_name\":\"transfer\"}',
    TransferOuttransactionTypeNameTransferOuttransactionTypeNameOldTransferOutinvestmentTypeGeneralAlternativescategorySellsallowedExport1unitEffect1unrealizedValue1realizedValue1cashEffect1displayEffect1transactionTypeBlackDiamondNameTransfertransactionTypeAddeparNametransfer: '{\"transaction_type\":\"transfer_out\",\"transaction_type_name\":\"Transfer Out\",\"transaction_type_name_old\":\"Transfer Out\",\"investment_type\":\"General Alternatives\",\"category\":\"Sells\",\"allowed_export\":\"1\",\"unit_effect\":\"-1\",\"unrealized_value\":\"-1\",\"realized_value\":\"1\",\"cash_effect\":\"1\",\"display_effect\":\"1\",\"transaction_type_black_diamond_name\":\"Transfer\",\"transaction_type_addepar_name\":\"transfer\"}',
    ValuationChangetransactionTypeNameValuationChangetransactionTypeNameOldValuationChangeinvestmentTypeGeneralAlternativescategoryAlternativesallowedNegative1allowedExport1unrealizedValue1displayEffect1transactionTypeBlackDiamondNameValuationChange: '{\"transaction_type\":\"valuation_change\",\"transaction_type_name\":\"Valuation Change\",\"transaction_type_name_old\":\"Valuation Change\",\"investment_type\":\"General Alternatives\",\"category\":\"Alternatives\",\"allowed_negative\":\"1\",\"allowed_export\":\"1\",\"unrealized_value\":\"1\",\"display_effect\":\"1\",\"transaction_type_black_diamond_name\":\"Valuation Change\"}',
    SaletransactionTypeNameWithdrawaltransactionTypeNameOldWithdrawalinvestmentTypeHedgeFundscategorySellsallowedExport1amountInvested1unitEffect1unrealizedValue1realizedValue1cashEffect1displayEffect1transactionTypeBlackDiamondNameSaletransactionTypeAddeparNamefundRedemption: '{\"transaction_type\":\"sale\",\"transaction_type_name\":\"Withdrawal\",\"transaction_type_name_old\":\"Withdrawal\",\"investment_type\":\"Hedge Funds\",\"category\":\"Sells\",\"allowed_export\":\"1\",\"amount_invested\":\"-1\",\"unit_effect\":\"-1\",\"unrealized_value\":\"-1\",\"realized_value\":\"1\",\"cash_effect\":\"1\",\"display_effect\":\"1\",\"transaction_type_black_diamond_name\":\"Sale\",\"transaction_type_addepar_name\":\"fund_redemption\"}'
} as const;

export type TransactionTypesTypeArrEnum = typeof TransactionTypesTypeArrEnum[keyof typeof TransactionTypesTypeArrEnum];

/**
 * 
 * @export
 * @interface TransactionTypesTypeMap
 */
export interface TransactionTypesTypeMap {
    /**
     * 
     * @type {string}
     * @memberof TransactionTypesTypeMap
     */
    'keys'?: TransactionTypesTypeMapKeysEnum;
    /**
     * 
     * @type {string}
     * @memberof TransactionTypesTypeMap
     */
    'values'?: TransactionTypesTypeMapValuesEnum;
}

export const TransactionTypesTypeMapKeysEnum = {
    Buy: 'buy',
    Sale: 'sale',
    CapitalCall: 'capital_call',
    CashDistributionReturnOfCapital: 'cash_distribution_return_of_capital',
    CapitalCommitment: 'capital_commitment',
    RecallableReturnOfCapital: 'recallable_return_of_capital',
    RecallableReturnOnCapital: 'recallable_return_on_capital',
    ReturnOfCapital: 'return_of_capital',
    ReturnOnCapital: 'return_on_capital',
    InterestIncome: 'interest_income',
    InterestExpense: 'interest_expense',
    IncentiveFee: 'incentive_fee',
    Redemption: 'redemption',
    Subscription: 'subscription',
    ValuationChange: 'valuation_change',
    CashDeposit: 'cash_deposit',
    CashWithdrawal: 'cash_withdrawal',
    Reinvestment: 'reinvestment',
    Dividend: 'dividend',
    DividendReversal: 'dividend_reversal',
    ManagementFee: 'management_fee',
    ManagementFeeRebate: 'management_fee_rebate',
    OtherFee: 'other_fee',
    TransferIn: 'transfer_in',
    TransferOut: 'transfer_out',
    Other: 'other',
    JointTransaction: 'joint_transaction'
} as const;

export type TransactionTypesTypeMapKeysEnum = typeof TransactionTypesTypeMapKeysEnum[keyof typeof TransactionTypesTypeMapKeysEnum];
export const TransactionTypesTypeMapValuesEnum = {
    BuytransactionTypeNameContributiontransactionTypeNameOldContributioninvestmentTypeHedgeFundscategoryBuysallowedNegative1allowedExport1capitalCalled1amountInvested1unitEffect1unrealizedValue1cashEffect1displayEffect1contributionEffect0transactionTypeBlackDiamondNameBuytransactionTypeAddeparNamecontribution: '{\"transaction_type\":\"buy\",\"transaction_type_name\":\"Contribution\",\"transaction_type_name_old\":\"Contribution\",\"investment_type\":\"Hedge Funds\",\"category\":\"Buys\",\"allowed_negative\":\"1\",\"allowed_export\":\"1\",\"capital_called\":\"1\",\"amount_invested\":\"1\",\"unit_effect\":\"1\",\"unrealized_value\":\"1\",\"cash_effect\":\"-1\",\"display_effect\":\"-1\",\"contribution_effect\":\"0\",\"transaction_type_black_diamond_name\":\"Buy\",\"transaction_type_addepar_name\":\"contribution\"}',
    SaletransactionTypeNameWithdrawaltransactionTypeNameOldWithdrawalinvestmentTypeHedgeFundscategorySellsallowedExport1amountInvested1unitEffect1unrealizedValue1realizedValue1cashEffect1displayEffect1transactionTypeBlackDiamondNameSaletransactionTypeAddeparNamefundRedemption: '{\"transaction_type\":\"sale\",\"transaction_type_name\":\"Withdrawal\",\"transaction_type_name_old\":\"Withdrawal\",\"investment_type\":\"Hedge Funds\",\"category\":\"Sells\",\"allowed_export\":\"1\",\"amount_invested\":\"-1\",\"unit_effect\":\"-1\",\"unrealized_value\":\"-1\",\"realized_value\":\"1\",\"cash_effect\":\"1\",\"display_effect\":\"1\",\"transaction_type_black_diamond_name\":\"Sale\",\"transaction_type_addepar_name\":\"fund_redemption\"}',
    CapitalCalltransactionTypeNameCapitalCalltransactionTypeNameOldCapitalCallinvestmentTypePrivateEquitycategoryAlternativesallowedNegative1allowedExport1capitalCalled1amountInvested1unitEffect1unrealizedValue1cashEffect1displayEffect1contributionEffect0transactionTypeBlackDiamondNameCapitalCalltransactionTypeAddeparNamecapitalCall: '{\"transaction_type\":\"capital_call\",\"transaction_type_name\":\"Capital Call\",\"transaction_type_name_old\":\"Capital Call\",\"investment_type\":\"Private Equity\",\"category\":\"Alternatives\",\"allowed_negative\":\"1\",\"allowed_export\":\"1\",\"capital_called\":\"1\",\"amount_invested\":\"1\",\"unit_effect\":\"1\",\"unrealized_value\":\"1\",\"cash_effect\":\"-1\",\"display_effect\":\"-1\",\"contribution_effect\":\"0\",\"transaction_type_black_diamond_name\":\"Capital Call\",\"transaction_type_addepar_name\":\"capital_call\"}',
    CashDistributionReturnOfCapitaltransactionTypeNameCashDistributionReturnOfCapitaltransactionTypeNameOldCashDistributionReturnOfCapitalinvestmentTypeGeneralAlternativescategoryOtherallowedExport1amountInvested1unrealizedValue1realizedValue1cashEffect1displayEffect1transactionTypeBlackDiamondNameCashDistributionReturnOfCapitaltransactionTypeAddeparNamedistribution: '{\"transaction_type\":\"cash_distribution_return_of_capital\",\"transaction_type_name\":\"Cash Distribution - Return of Capital\",\"transaction_type_name_old\":\"Cash Distribution - Return of Capital\",\"investment_type\":\"General Alternatives\",\"category\":\"Other\",\"allowed_export\":\"1\",\"amount_invested\":\"-1\",\"unrealized_value\":\"-1\",\"realized_value\":\"1\",\"cash_effect\":\"1\",\"display_effect\":\"1\",\"transaction_type_black_diamond_name\":\"Cash Distribution - Return of Capital\",\"transaction_type_addepar_name\":\"distribution\"}',
    CapitalCommitmenttransactionTypeNameCapitalCommittmenttransactionTypeNameOldCapitalCommittmentinvestmentTypePrivateEquitycategoryJournalsallowedNegative1allowedExport1committedCapital1displayEffect1transactionTypeBlackDiamondNameCommittedCapitaltransactionTypeAddeparNamecommitment: '{\"transaction_type\":\"capital_commitment\",\"transaction_type_name\":\"Capital Committment\",\"transaction_type_name_old\":\"Capital Committment\",\"investment_type\":\"Private Equity\",\"category\":\"Journals\",\"allowed_negative\":\"1\",\"allowed_export\":\"1\",\"committed_capital\":\"1\",\"display_effect\":\"1\",\"transaction_type_black_diamond_name\":\"Committed Capital\",\"transaction_type_addepar_name\":\"commitment\"}',
    RecallableReturnOfCapitaltransactionTypeNameRecallablePrincipalDistributiontransactionTypeNameOldRecallablePrincipalDistributioninvestmentTypePrivateEquitycategoryOtherallowedNegative1allowedExport1capitalCalled1amountInvested1unrealizedValue1realizedValue1cashEffect1displayEffect1transactionTypeBlackDiamondNameCashDistributionReturnOfCapitaltransactionTypeAddeparNamedistribution: '{\"transaction_type\":\"recallable_return_of_capital\",\"transaction_type_name\":\"Recallable Principal Distribution\",\"transaction_type_name_old\":\"Recallable Principal Distribution\",\"investment_type\":\"Private Equity\",\"category\":\"Other\",\"allowed_negative\":\"1\",\"allowed_export\":\"1\",\"capital_called\":\"-1\",\"amount_invested\":\"-1\",\"unrealized_value\":\"-1\",\"realized_value\":\"1\",\"cash_effect\":\"1\",\"display_effect\":\"1\",\"transaction_type_black_diamond_name\":\"Cash Distribution - Return of Capital\",\"transaction_type_addepar_name\":\"distribution\"}',
    RecallableReturnOnCapitaltransactionTypeNameRecallableProfitDistributiontransactionTypeNameOldRecallableProfitDistributioninvestmentTypePrivateEquitycategoryIncomeallowedNegative1allowedExport1capitalCalled1realizedValue1cashEffect1displayEffect1transactionTypeBlackDiamondNameCashDistributionIncometransactionTypeAddeparNamedistribution: '{\"transaction_type\":\"recallable_return_on_capital\",\"transaction_type_name\":\"Recallable Profit Distribution\",\"transaction_type_name_old\":\"Recallable Profit Distribution\",\"investment_type\":\"Private Equity\",\"category\":\"Income\",\"allowed_negative\":\"1\",\"allowed_export\":\"1\",\"capital_called\":\"-1\",\"realized_value\":\"1\",\"cash_effect\":\"1\",\"display_effect\":\"1\",\"transaction_type_black_diamond_name\":\"Cash Distribution - Income\",\"transaction_type_addepar_name\":\"distribution\"}',
    ReturnOfCapitaltransactionTypeNamePrincipalDistributiontransactionTypeNameOldPrincipalDistributioninvestmentTypeGeneralAlternativescategoryOtherallowedNegative1allowedExport1amountInvested1unrealizedValue1realizedValue1cashEffect1displayEffect1transactionTypeBlackDiamondNameCashDistributionReturnOfCapitaltransactionTypeAddeparNamedistribution: '{\"transaction_type\":\"return_of_capital\",\"transaction_type_name\":\"Principal Distribution\",\"transaction_type_name_old\":\"Principal Distribution\",\"investment_type\":\"General Alternatives\",\"category\":\"Other\",\"allowed_negative\":\"1\",\"allowed_export\":\"1\",\"amount_invested\":\"-1\",\"unrealized_value\":\"-1\",\"realized_value\":\"1\",\"cash_effect\":\"1\",\"display_effect\":\"1\",\"transaction_type_black_diamond_name\":\"Cash Distribution - Return of Capital\",\"transaction_type_addepar_name\":\"distribution\"}',
    ReturnOnCapitaltransactionTypeNameProfitDistributiontransactionTypeNameOldProfitDistributioninvestmentTypeGeneralAlternativescategoryIncomeallowedNegative1allowedExport1realizedValue1cashEffect1displayEffect1transactionTypeBlackDiamondNameCashDistributionIncometransactionTypeAddeparNamedistribution: '{\"transaction_type\":\"return_on_capital\",\"transaction_type_name\":\"Profit Distribution\",\"transaction_type_name_old\":\"Profit Distribution\",\"investment_type\":\"General Alternatives\",\"category\":\"Income\",\"allowed_negative\":\"1\",\"allowed_export\":\"1\",\"realized_value\":\"1\",\"cash_effect\":\"1\",\"display_effect\":\"1\",\"transaction_type_black_diamond_name\":\"Cash Distribution - Income\",\"transaction_type_addepar_name\":\"distribution\"}',
    InterestIncometransactionTypeNameInterestIncomeinvestmentTypeGeneralAlternativescategoryIncomeallowedExport1unrealizedValue1cashEffect1displayEffect1contributionEffect1transactionTypeAddeparNameincome: '{\"transaction_type\":\"interest_income\",\"transaction_type_name\":\"Interest Income\",\"investment_type\":\"General Alternatives\",\"category\":\"Income\",\"allowed_export\":\"1\",\"unrealized_value\":\"1\",\"cash_effect\":\"1\",\"display_effect\":\"1\",\"contribution_effect\":\"1\",\"transaction_type_addepar_name\":\"income\"}',
    InterestExpensetransactionTypeNameInterestExpenseinvestmentTypeGeneralAlternativescategoryIncomeallowedExport1unrealizedValue1feeEffect1cashEffect1displayEffect1contributionEffect1transactionTypeAddeparNameinterestExpense: '{\"transaction_type\":\"interest_expense\",\"transaction_type_name\":\"Interest Expense\",\"investment_type\":\"General Alternatives\",\"category\":\"Income\",\"allowed_export\":\"1\",\"unrealized_value\":\"-1\",\"fee_effect\":\"1\",\"cash_effect\":\"-1\",\"display_effect\":\"-1\",\"contribution_effect\":\"-1\",\"transaction_type_addepar_name\":\"interest_expense\"}',
    IncentiveFeetransactionTypeNameIncentiveFeetransactionTypeNameOldPerformanceAllocationinvestmentTypeGeneralAlternativescategoryFeesallowedNegative1allowedExport1unrealizedValue1feeEffect1displayEffect1transactionTypeBlackDiamondNameIncentiveFeetransactionTypeAddeparNamefee: '{\"transaction_type\":\"incentive_fee\",\"transaction_type_name\":\"Incentive Fee\",\"transaction_type_name_old\":\"Performance Allocation\",\"investment_type\":\"General Alternatives\",\"category\":\"Fees\",\"allowed_negative\":\"1\",\"allowed_export\":\"1\",\"unrealized_value\":\"-1\",\"fee_effect\":\"1\",\"display_effect\":\"-1\",\"transaction_type_black_diamond_name\":\"Incentive Fee\",\"transaction_type_addepar_name\":\"fee\"}',
    RedemptiontransactionTypeNameRedemptiontransactionTypeNameOldRedemptionWithdrawalHFinvestmentTypeHedgeFundscategorySellsallowedExport1unrealizedValue1realizedValue1cashEffect1displayEffect1transactionTypeBlackDiamondNameRedemptiontransactionTypeAddeparNamefundRedemption: '{\"transaction_type\":\"redemption\",\"transaction_type_name\":\"Redemption\",\"transaction_type_name_old\":\"Redemption / Withdrawal (HF)\",\"investment_type\":\"Hedge Funds\",\"category\":\"Sells\",\"allowed_export\":\"1\",\"unrealized_value\":\"-1\",\"realized_value\":\"1\",\"cash_effect\":\"1\",\"display_effect\":\"1\",\"transaction_type_black_diamond_name\":\"Redemption\",\"transaction_type_addepar_name\":\"fund_redemption\"}',
    SubscriptiontransactionTypeNameSubscriptiontransactionTypeNameOldSubscriptionContributionHFinvestmentTypeHedgeFundscategoryBuyallowedExport1amountInvested1cashEffect1displayEffect1transactionTypeBlackDiamondNameSubscriptiontransactionTypeAddeparNamebuy: '{\"transaction_type\":\"subscription\",\"transaction_type_name\":\"Subscription\",\"transaction_type_name_old\":\"Subscription / Contribution (HF)\",\"investment_type\":\"Hedge Funds\",\"category\":\"Buy\",\"allowed_export\":\"1\",\"amount_invested\":\"1\",\"cash_effect\":\"-1\",\"display_effect\":\"-1\",\"transaction_type_black_diamond_name\":\"Subscription\",\"transaction_type_addepar_name\":\"buy\"}',
    ValuationChangetransactionTypeNameValuationChangetransactionTypeNameOldValuationChangeinvestmentTypeGeneralAlternativescategoryAlternativesallowedNegative1allowedExport1unrealizedValue1displayEffect1transactionTypeBlackDiamondNameValuationChange: '{\"transaction_type\":\"valuation_change\",\"transaction_type_name\":\"Valuation Change\",\"transaction_type_name_old\":\"Valuation Change\",\"investment_type\":\"General Alternatives\",\"category\":\"Alternatives\",\"allowed_negative\":\"1\",\"allowed_export\":\"1\",\"unrealized_value\":\"1\",\"display_effect\":\"1\",\"transaction_type_black_diamond_name\":\"Valuation Change\"}',
    CashDeposittransactionTypeNameCashDeposittransactionTypeNameOldCashDepositinvestmentTypeStandardcategoryContributionsallowedExport1cashEffect1displayEffect1contributionEffect1transactionTypeBlackDiamondNameCashDeposit: '{\"transaction_type\":\"cash_deposit\",\"transaction_type_name\":\"Cash Deposit\",\"transaction_type_name_old\":\"Cash Deposit\",\"investment_type\":\"Standard\",\"category\":\"Contributions\",\"allowed_export\":\"1\",\"cash_effect\":\"1\",\"display_effect\":\"1\",\"contribution_effect\":\"1\",\"transaction_type_black_diamond_name\":\"Cash Deposit\"}',
    CashWithdrawaltransactionTypeNameCashWithdrawaltransactionTypeNameOldCashWithdrawalinvestmentTypeStandardcategoryWithdrawalsallowedExport1cashEffect1displayEffect1contributionEffect1transactionTypeBlackDiamondNameCashWithdrawal: '{\"transaction_type\":\"cash_withdrawal\",\"transaction_type_name\":\"Cash Withdrawal\",\"transaction_type_name_old\":\"Cash Withdrawal\",\"investment_type\":\"Standard\",\"category\":\"Withdrawals\",\"allowed_export\":\"1\",\"cash_effect\":\"-1\",\"display_effect\":\"-1\",\"contribution_effect\":\"-1\",\"transaction_type_black_diamond_name\":\"Cash Withdrawal\"}',
    ReinvestmenttransactionTypeNameReinvestmentinvestmentTypeStandardcategoryBuysallowedExport1amountInvested1unitEffect1unrealizedValue1cashEffect1displayEffect1contributionEffect0transactionTypeBlackDiamondNameBuytransactionTypeAddeparNamereinvestment: '{\"transaction_type\":\"reinvestment\",\"transaction_type_name\":\"Reinvestment\",\"investment_type\":\"Standard\",\"category\":\"Buys\",\"allowed_export\":\"1\",\"amount_invested\":\"1\",\"unit_effect\":\"1\",\"unrealized_value\":\"1\",\"cash_effect\":\"-1\",\"display_effect\":\"-1\",\"contribution_effect\":\"0\",\"transaction_type_black_diamond_name\":\"Buy\",\"transaction_type_addepar_name\":\"reinvestment\"}',
    DividendtransactionTypeNameDividendIncometransactionTypeNameOldDividendinvestmentTypeStandardcategoryIncomeallowedExport1realizedValue1cashEffect1displayEffect1transactionTypeBlackDiamondNameDividendtransactionTypeAddeparNamedistribution: '{\"transaction_type\":\"dividend\",\"transaction_type_name\":\"Dividend/Income\",\"transaction_type_name_old\":\"Dividend\",\"investment_type\":\"Standard\",\"category\":\"Income\",\"allowed_export\":\"1\",\"realized_value\":\"1\",\"cash_effect\":\"1\",\"display_effect\":\"1\",\"transaction_type_black_diamond_name\":\"Dividend\",\"transaction_type_addepar_name\":\"distribution\"}',
    DividendReversaltransactionTypeNameDividendReversaltransactionTypeNameOldDividendReversalinvestmentTypeStandardcategoryIncomeallowedExport1realizedValue1cashEffect1displayEffect1transactionTypeBlackDiamondNameDividendReversal: '{\"transaction_type\":\"dividend_reversal\",\"transaction_type_name\":\"Dividend Reversal\",\"transaction_type_name_old\":\"Dividend Reversal\",\"investment_type\":\"Standard\",\"category\":\"Income\",\"allowed_export\":\"1\",\"realized_value\":\"-1\",\"cash_effect\":\"-1\",\"display_effect\":\"-1\",\"transaction_type_black_diamond_name\":\"Dividend Reversal\"}',
    ManagementFeetransactionTypeNameManagementFeetransactionTypeNameOldManagementFeeinvestmentTypeStandardcategoryFeesallowedNegative1allowedExport1unrealizedValue1feeEffect1displayEffect1contributionEffect1transactionTypeBlackDiamondNameManagementFeetransactionTypeAddeparNamefee: '{\"transaction_type\":\"management_fee\",\"transaction_type_name\":\"Management Fee\",\"transaction_type_name_old\":\"Management Fee\",\"investment_type\":\"Standard\",\"category\":\"Fees\",\"allowed_negative\":\"1\",\"allowed_export\":\"1\",\"unrealized_value\":\"-1\",\"fee_effect\":\"1\",\"display_effect\":\"-1\",\"contribution_effect\":\"-1\",\"transaction_type_black_diamond_name\":\"Management Fee\",\"transaction_type_addepar_name\":\"fee\"}',
    ManagementFeeRebatetransactionTypeNameManagementFeeRebatetransactionTypeNameOldManagementFeeRebateinvestmentTypeStandardcategoryFeesallowedNegative1allowedExport1unrealizedValue1feeEffect1displayEffect1contributionEffect1transactionTypeBlackDiamondNameManagementFeeReversaltransactionTypeAddeparNamefeeReimbursement: '{\"transaction_type\":\"management_fee_rebate\",\"transaction_type_name\":\"Management Fee Rebate\",\"transaction_type_name_old\":\"Management Fee Rebate\",\"investment_type\":\"Standard\",\"category\":\"Fees\",\"allowed_negative\":\"1\",\"allowed_export\":\"1\",\"unrealized_value\":\"1\",\"fee_effect\":\"-1\",\"display_effect\":\"1\",\"contribution_effect\":\"1\",\"transaction_type_black_diamond_name\":\"Management Fee Reversal\",\"transaction_type_addepar_name\":\"fee_reimbursement\"}',
    OtherFeetransactionTypeNameOtherFeetransactionTypeNameOldOtherFeeinvestmentTypeStandardcategoryWithdrawalsallowedNegative1allowedExport1unrealizedValue1feeEffect1displayEffect1contributionEffect1transactionTypeBlackDiamondNameOtherFeetransactionTypeAddeparNamefee: '{\"transaction_type\":\"other_fee\",\"transaction_type_name\":\"Other Fee\",\"transaction_type_name_old\":\"Other Fee\",\"investment_type\":\"Standard\",\"category\":\"Withdrawals\",\"allowed_negative\":\"1\",\"allowed_export\":\"1\",\"unrealized_value\":\"-1\",\"fee_effect\":\"1\",\"display_effect\":\"-1\",\"contribution_effect\":\"-1\",\"transaction_type_black_diamond_name\":\"Other Fee\",\"transaction_type_addepar_name\":\"fee\"}',
    TransferIntransactionTypeNameTransferIntransactionTypeNameOldTransferIninvestmentTypeGeneralAlternativescategoryBuysallowedExport1amountInvested1unitEffect1unrealizedValue1cashEffect1displayEffect1transactionTypeBlackDiamondNameTransfertransactionTypeAddeparNametransfer: '{\"transaction_type\":\"transfer_in\",\"transaction_type_name\":\"Transfer In\",\"transaction_type_name_old\":\"Transfer In\",\"investment_type\":\"General Alternatives\",\"category\":\"Buys\",\"allowed_export\":\"1\",\"amount_invested\":\"1\",\"unit_effect\":\"1\",\"unrealized_value\":\"1\",\"cash_effect\":\"-1\",\"display_effect\":\"1\",\"transaction_type_black_diamond_name\":\"Transfer\",\"transaction_type_addepar_name\":\"transfer\"}',
    TransferOuttransactionTypeNameTransferOuttransactionTypeNameOldTransferOutinvestmentTypeGeneralAlternativescategorySellsallowedExport1unitEffect1unrealizedValue1realizedValue1cashEffect1displayEffect1transactionTypeBlackDiamondNameTransfertransactionTypeAddeparNametransfer: '{\"transaction_type\":\"transfer_out\",\"transaction_type_name\":\"Transfer Out\",\"transaction_type_name_old\":\"Transfer Out\",\"investment_type\":\"General Alternatives\",\"category\":\"Sells\",\"allowed_export\":\"1\",\"unit_effect\":\"-1\",\"unrealized_value\":\"-1\",\"realized_value\":\"1\",\"cash_effect\":\"1\",\"display_effect\":\"1\",\"transaction_type_black_diamond_name\":\"Transfer\",\"transaction_type_addepar_name\":\"transfer\"}',
    OthertransactionTypeNameOtherinvestmentTypeStandardcategoryOtherdisplayEffect1: '{\"transaction_type\":\"other\",\"transaction_type_name\":\"Other\",\"investment_type\":\"Standard\",\"category\":\"Other\",\"display_effect\":\"1\"}',
    JointTransactiontransactionTypeNameJointTransactiontransactionTypeNameOldJointTransactioninvestmentTypeStandardcategoryOtherdisplayEffect1: '{\"transaction_type\":\"joint_transaction\",\"transaction_type_name\":\"Joint Transaction\",\"transaction_type_name_old\":\"Joint Transaction\",\"investment_type\":\"Standard\",\"category\":\"Other\",\"display_effect\":\"1\"}'
} as const;

export type TransactionTypesTypeMapValuesEnum = typeof TransactionTypesTypeMapValuesEnum[keyof typeof TransactionTypesTypeMapValuesEnum];

/**
 * 
 * @export
 * @interface UpdateAccountRequest
 */
export interface UpdateAccountRequest {
    [key: string]: any;

    /**
     * 
     * @type {string}
     * @memberof UpdateAccountRequest
     */
    'name'?: string;
    /**
     * 
     * @type {any}
     * @memberof UpdateAccountRequest
     */
    'account_type'?: UpdateAccountRequestAccountTypeEnum;
    /**
     * 
     * @type {any}
     * @memberof UpdateAccountRequest
     */
    'id_type'?: UpdateAccountRequestIdTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof UpdateAccountRequest
     */
    'id_number'?: string;
    /**
     * 
     * @type {UpdateAccountRequestAddress}
     * @memberof UpdateAccountRequest
     */
    'address'?: UpdateAccountRequestAddress;
    /**
     * Admins are permitted to manually update the panda doc id for an account ral
     * @type {any}
     * @memberof UpdateAccountRequest
     */
    'ral_panda_doc_id'?: any;
    /**
     * 
     * @type {object}
     * @memberof UpdateAccountRequest
     */
    'external_ids_diff'?: object;
}

export const UpdateAccountRequestAccountTypeEnum = {
    Individual: 'individual',
    Joint: 'joint',
    Trust: 'trust',
    Ira: 'ira',
    Llc: 'llc',
    Partnership: 'partnership',
    Corporation: 'corporation',
    Other: 'other',
    Empty: ''
} as const;

export type UpdateAccountRequestAccountTypeEnum = typeof UpdateAccountRequestAccountTypeEnum[keyof typeof UpdateAccountRequestAccountTypeEnum];
export const UpdateAccountRequestIdTypeEnum = {
    Ssn: 'SSN',
    Ein: 'EIN',
    Tin: 'TIN',
    Empty: ''
} as const;

export type UpdateAccountRequestIdTypeEnum = typeof UpdateAccountRequestIdTypeEnum[keyof typeof UpdateAccountRequestIdTypeEnum];

/**
 * 
 * @export
 * @interface UpdateAccountRequestAddress
 */
export interface UpdateAccountRequestAddress {
    /**
     * 
     * @type {any}
     * @memberof UpdateAccountRequestAddress
     */
    'streetLine1'?: any;
    /**
     * 
     * @type {any}
     * @memberof UpdateAccountRequestAddress
     */
    'streetLine2'?: any;
    /**
     * 
     * @type {any}
     * @memberof UpdateAccountRequestAddress
     */
    'city'?: any;
    /**
     * 
     * @type {any}
     * @memberof UpdateAccountRequestAddress
     */
    'state'?: UpdateAccountRequestAddressStateEnum;
    /**
     * 
     * @type {any}
     * @memberof UpdateAccountRequestAddress
     */
    'country'?: any;
    /**
     * 
     * @type {any}
     * @memberof UpdateAccountRequestAddress
     */
    'zipCode'?: any;
}

export const UpdateAccountRequestAddressStateEnum = {
    Al: 'AL',
    Ak: 'AK',
    Az: 'AZ',
    Ar: 'AR',
    Ca: 'CA',
    Co: 'CO',
    Ct: 'CT',
    De: 'DE',
    Fl: 'FL',
    Ga: 'GA',
    Hi: 'HI',
    Id: 'ID',
    Il: 'IL',
    In: 'IN',
    Ia: 'IA',
    Ks: 'KS',
    Ky: 'KY',
    La: 'LA',
    Me: 'ME',
    Md: 'MD',
    Ma: 'MA',
    Mi: 'MI',
    Mn: 'MN',
    Ms: 'MS',
    Mo: 'MO',
    Mt: 'MT',
    Ne: 'NE',
    Nv: 'NV',
    Nh: 'NH',
    Nj: 'NJ',
    Nm: 'NM',
    Ny: 'NY',
    Nc: 'NC',
    Nd: 'ND',
    Oh: 'OH',
    Ok: 'OK',
    Or: 'OR',
    Pa: 'PA',
    Ri: 'RI',
    Sc: 'SC',
    Sd: 'SD',
    Tn: 'TN',
    Tx: 'TX',
    Ut: 'UT',
    Vt: 'VT',
    Va: 'VA',
    Wa: 'WA',
    Wv: 'WV',
    Wi: 'WI',
    Wy: 'WY',
    Nl: 'NL',
    Pe: 'PE',
    Ns: 'NS',
    Nb: 'NB',
    Qc: 'QC',
    True: 'true',
    Mb: 'MB',
    Sk: 'SK',
    Ab: 'AB',
    Bc: 'BC',
    Yt: 'YT',
    Nt: 'NT',
    Nu: 'NU',
    Empty: ''
} as const;

export type UpdateAccountRequestAddressStateEnum = typeof UpdateAccountRequestAddressStateEnum[keyof typeof UpdateAccountRequestAddressStateEnum];

/**
 * @type UpdateAccountRequestExternalIdsDiffValue
 * @export
 */
export type UpdateAccountRequestExternalIdsDiffValue = any | string;

/**
 * 
 * @export
 * @interface UpdateAdminTeamRequest
 */
export interface UpdateAdminTeamRequest {
    [key: string]: any;

    /**
     * 
     * @type {string}
     * @memberof UpdateAdminTeamRequest
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateAdminTeamRequest
     */
    'type'?: UpdateAdminTeamRequestTypeEnum;
    /**
     * 
     * @type {Array<string>}
     * @memberof UpdateAdminTeamRequest
     */
    'advisory_groups'?: Array<string>;
}

export const UpdateAdminTeamRequestTypeEnum = {
    AccountManagement: 'account_management',
    Onboarding: 'onboarding'
} as const;

export type UpdateAdminTeamRequestTypeEnum = typeof UpdateAdminTeamRequestTypeEnum[keyof typeof UpdateAdminTeamRequestTypeEnum];

/**
 * 
 * @export
 * @interface UpdateCommitmentRequest
 */
export interface UpdateCommitmentRequest {
    [key: string]: any;

    /**
     * 
     * @type {string}
     * @memberof UpdateCommitmentRequest
     */
    'date'?: string;
    /**
     * 
     * @type {number}
     * @memberof UpdateCommitmentRequest
     */
    'amount'?: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof UpdateCommitmentRequest
     */
    'documents'?: Array<string>;
}
/**
 * 
 * @export
 * @interface UpdateConnectionAccountRequest
 */
export interface UpdateConnectionAccountRequest {
    /**
     * 
     * @type {string}
     * @memberof UpdateConnectionAccountRequest
     */
    'account'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateConnectionAccountRequest
     */
    'is_ignored'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof UpdateConnectionAccountRequest
     */
    'ignore_reason'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateConnectionAccountRequest
     */
    'is_auto_import_enabled'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateConnectionAccountRequest
     */
    'is_hierarchy_override_enabled'?: boolean;
}
/**
 * 
 * @export
 * @interface UpdateConnectionCommitmentRequest
 */
export interface UpdateConnectionCommitmentRequest {
    /**
     * 
     * @type {string}
     * @memberof UpdateConnectionCommitmentRequest
     */
    'commitment'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateConnectionCommitmentRequest
     */
    'is_ignored'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof UpdateConnectionCommitmentRequest
     */
    'ignore_reason'?: string;
}
/**
 * 
 * @export
 * @interface UpdateConnectionCredentialsRequest
 */
export interface UpdateConnectionCredentialsRequest {
    [key: string]: any;

    /**
     * 
     * @type {{ [key: string]: any; }}
     * @memberof UpdateConnectionCredentialsRequest
     */
    'credentials': { [key: string]: any; };
}
/**
 * 
 * @export
 * @interface UpdateConnectionDocumentRequest
 */
export interface UpdateConnectionDocumentRequest {
    /**
     * 
     * @type {string}
     * @memberof UpdateConnectionDocumentRequest
     */
    'document'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateConnectionDocumentRequest
     */
    'is_ignored'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof UpdateConnectionDocumentRequest
     */
    'ignore_reason'?: string;
}
/**
 * 
 * @export
 * @interface UpdateConnectionInvestmentRequest
 */
export interface UpdateConnectionInvestmentRequest {
    /**
     * 
     * @type {string}
     * @memberof UpdateConnectionInvestmentRequest
     */
    'investment'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateConnectionInvestmentRequest
     */
    'is_ignored'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof UpdateConnectionInvestmentRequest
     */
    'ignore_reason'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateConnectionInvestmentRequest
     */
    'is_auto_import_enabled'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateConnectionInvestmentRequest
     */
    'is_hierarchy_override_enabled'?: boolean;
}
/**
 * 
 * @export
 * @interface UpdateConnectionTransactionRequest
 */
export interface UpdateConnectionTransactionRequest {
    /**
     * 
     * @type {string}
     * @memberof UpdateConnectionTransactionRequest
     */
    'transaction'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateConnectionTransactionRequest
     */
    'is_ignored'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof UpdateConnectionTransactionRequest
     */
    'ignore_reason'?: string;
}
/**
 * 
 * @export
 * @interface UpdateConnectionUserRequest
 */
export interface UpdateConnectionUserRequest {
    /**
     * 
     * @type {string}
     * @memberof UpdateConnectionUserRequest
     */
    'user'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateConnectionUserRequest
     */
    'is_ignored'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof UpdateConnectionUserRequest
     */
    'ignore_reason'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateConnectionUserRequest
     */
    'is_auto_import_enabled'?: boolean;
}
/**
 * 
 * @export
 * @interface UpdateConnectionValuationRequest
 */
export interface UpdateConnectionValuationRequest {
    /**
     * 
     * @type {string}
     * @memberof UpdateConnectionValuationRequest
     */
    'valuation'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateConnectionValuationRequest
     */
    'is_ignored'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof UpdateConnectionValuationRequest
     */
    'ignore_reason'?: string;
}
/**
 * 
 * @export
 * @interface UpdateContactRequest
 */
export interface UpdateContactRequest {
    [key: string]: any;

    /**
     * 
     * @type {string}
     * @memberof UpdateContactRequest
     */
    'first_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateContactRequest
     */
    'last_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateContactRequest
     */
    'email'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateContactRequest
     */
    'phone'?: string;
}
/**
 * 
 * @export
 * @interface UpdateDefaultDocumentImportSettingRequest
 */
export interface UpdateDefaultDocumentImportSettingRequest {
    [key: string]: any;

    /**
     * 
     * @type {string}
     * @memberof UpdateDefaultDocumentImportSettingRequest
     */
    'integration'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateDefaultDocumentImportSettingRequest
     */
    'asset_manager'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateDefaultDocumentImportSettingRequest
     */
    'advisory_group'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateDefaultDocumentImportSettingRequest
     */
    'global_default_extraction_setting'?: UpdateDefaultDocumentImportSettingRequestGlobalDefaultExtractionSettingEnum;
    /**
     * 
     * @type {string}
     * @memberof UpdateDefaultDocumentImportSettingRequest
     */
    'global_default_auto_sort_setting'?: UpdateDefaultDocumentImportSettingRequestGlobalDefaultAutoSortSettingEnum;
    /**
     * 
     * @type {string}
     * @memberof UpdateDefaultDocumentImportSettingRequest
     */
    'global_default_transaction_setting'?: UpdateDefaultDocumentImportSettingRequestGlobalDefaultTransactionSettingEnum;
    /**
     * 
     * @type {string}
     * @memberof UpdateDefaultDocumentImportSettingRequest
     */
    'global_default_process_setting'?: UpdateDefaultDocumentImportSettingRequestGlobalDefaultProcessSettingEnum;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof UpdateDefaultDocumentImportSettingRequest
     */
    'default_transaction_settings'?: { [key: string]: string; };
}

export const UpdateDefaultDocumentImportSettingRequestGlobalDefaultExtractionSettingEnum = {
    Enabled: 'enabled',
    Disabled: 'disabled',
    Unset: 'unset'
} as const;

export type UpdateDefaultDocumentImportSettingRequestGlobalDefaultExtractionSettingEnum = typeof UpdateDefaultDocumentImportSettingRequestGlobalDefaultExtractionSettingEnum[keyof typeof UpdateDefaultDocumentImportSettingRequestGlobalDefaultExtractionSettingEnum];
export const UpdateDefaultDocumentImportSettingRequestGlobalDefaultAutoSortSettingEnum = {
    Enabled: 'enabled',
    Disabled: 'disabled',
    Unset: 'unset'
} as const;

export type UpdateDefaultDocumentImportSettingRequestGlobalDefaultAutoSortSettingEnum = typeof UpdateDefaultDocumentImportSettingRequestGlobalDefaultAutoSortSettingEnum[keyof typeof UpdateDefaultDocumentImportSettingRequestGlobalDefaultAutoSortSettingEnum];
export const UpdateDefaultDocumentImportSettingRequestGlobalDefaultTransactionSettingEnum = {
    Enabled: 'enabled',
    Disabled: 'disabled',
    Unset: 'unset'
} as const;

export type UpdateDefaultDocumentImportSettingRequestGlobalDefaultTransactionSettingEnum = typeof UpdateDefaultDocumentImportSettingRequestGlobalDefaultTransactionSettingEnum[keyof typeof UpdateDefaultDocumentImportSettingRequestGlobalDefaultTransactionSettingEnum];
export const UpdateDefaultDocumentImportSettingRequestGlobalDefaultProcessSettingEnum = {
    Enabled: 'enabled',
    Disabled: 'disabled',
    Unset: 'unset'
} as const;

export type UpdateDefaultDocumentImportSettingRequestGlobalDefaultProcessSettingEnum = typeof UpdateDefaultDocumentImportSettingRequestGlobalDefaultProcessSettingEnum[keyof typeof UpdateDefaultDocumentImportSettingRequestGlobalDefaultProcessSettingEnum];

/**
 * 
 * @export
 * @interface UpdateDocument200Response
 */
export interface UpdateDocument200Response {
    /**
     * 
     * @type {boolean}
     * @memberof UpdateDocument200Response
     */
    'success'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof UpdateDocument200Response
     */
    'metadata_error'?: string;
}
/**
 * 
 * @export
 * @interface UpdateDocumentRequest
 */
export interface UpdateDocumentRequest {
    [key: string]: any;

    /**
     * 
     * @type {string}
     * @memberof UpdateDocumentRequest
     */
    'date'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateDocumentRequest
     */
    'name'?: string;
    /**
     * 
     * @type {any}
     * @memberof UpdateDocumentRequest
     */
    'type'?: any;
    /**
     * 
     * @type {ListDocuments200ResponseDocumentsInnerTraining}
     * @memberof UpdateDocumentRequest
     */
    'training'?: ListDocuments200ResponseDocumentsInnerTraining;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateDocumentRequest
     */
    'shouldGenerateTransactions'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateDocumentRequest
     */
    'shouldProcessDocument'?: boolean;
    /**
     * 
     * @type {Array<ListDocuments200ResponseDocumentsInnerMetadataInner>}
     * @memberof UpdateDocumentRequest
     */
    'metadata'?: Array<ListDocuments200ResponseDocumentsInnerMetadataInner>;
    /**
     * 
     * @type {Array<any>}
     * @memberof UpdateDocumentRequest
     */
    'ownership_ids'?: Array<any>;
    /**
     * 
     * @type {string}
     * @memberof UpdateDocumentRequest
     */
    'ownership_type'?: UpdateDocumentRequestOwnershipTypeEnum | null;
    /**
     * 
     * @type {any}
     * @memberof UpdateDocumentRequest
     */
    'file_type'?: UpdateDocumentRequestFileTypeEnum;
}

export const UpdateDocumentRequestOwnershipTypeEnum = {
    Account: 'Account',
    Investment: 'Investment',
    Null: 'null'
} as const;

export type UpdateDocumentRequestOwnershipTypeEnum = typeof UpdateDocumentRequestOwnershipTypeEnum[keyof typeof UpdateDocumentRequestOwnershipTypeEnum];
export const UpdateDocumentRequestFileTypeEnum = {
    Pdf: 'pdf',
    Csv: 'csv',
    Xlsx: 'xlsx',
    Xls: 'xls'
} as const;

export type UpdateDocumentRequestFileTypeEnum = typeof UpdateDocumentRequestFileTypeEnum[keyof typeof UpdateDocumentRequestFileTypeEnum];

/**
 * 
 * @export
 * @interface UpdateExtegrationConnectionRequest
 */
export interface UpdateExtegrationConnectionRequest {
    [key: string]: any;

    /**
     * 
     * @type {boolean}
     * @memberof UpdateExtegrationConnectionRequest
     */
    'enabled'?: boolean;
    /**
     * 
     * @type {Array<string>}
     * @memberof UpdateExtegrationConnectionRequest
     */
    'entities'?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof UpdateExtegrationConnectionRequest
     */
    'name'?: string;
    /**
     * 
     * @type {{ [key: string]: any; }}
     * @memberof UpdateExtegrationConnectionRequest
     */
    'credentials'?: { [key: string]: any; };
    /**
     * 
     * @type {any}
     * @memberof UpdateExtegrationConnectionRequest
     */
    'sync_interval'?: UpdateExtegrationConnectionRequestSyncIntervalEnum;
}

export const UpdateExtegrationConnectionRequestSyncIntervalEnum = {
    Null: 'null',
    Daily: 'daily',
    Weekdays: 'weekdays',
    Weekly: 'weekly',
    Monthly: 'monthly',
    Tetrahourly: 'tetrahourly'
} as const;

export type UpdateExtegrationConnectionRequestSyncIntervalEnum = typeof UpdateExtegrationConnectionRequestSyncIntervalEnum[keyof typeof UpdateExtegrationConnectionRequestSyncIntervalEnum];

/**
 * 
 * @export
 * @interface UpdateIntegrationRequest
 */
export interface UpdateIntegrationRequest {
    [key: string]: any;

    /**
     * 
     * @type {string}
     * @memberof UpdateIntegrationRequest
     */
    'name'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateIntegrationRequest
     */
    'has_worker'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateIntegrationRequest
     */
    'tfa_required'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateIntegrationRequest
     */
    'phone_required'?: boolean;
    /**
     * 
     * @type {Array<ListIntegrations200ResponseIntegrationsInnerRequiredCredsInner>}
     * @memberof UpdateIntegrationRequest
     */
    'required_creds'?: Array<ListIntegrations200ResponseIntegrationsInnerRequiredCredsInner>;
    /**
     * 
     * @type {string}
     * @memberof UpdateIntegrationRequest
     */
    'portal_url'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateIntegrationRequest
     */
    'lambda_arn'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateIntegrationRequest
     */
    'cycle_container_id'?: string;
    /**
     * 
     * @type {number}
     * @memberof UpdateIntegrationRequest
     */
    'worker_version'?: number;
}
/**
 * 
 * @export
 * @interface UpdateInvestmentRequest
 */
export interface UpdateInvestmentRequest {
    [key: string]: any;

    /**
     * 
     * @type {string}
     * @memberof UpdateInvestmentRequest
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateInvestmentRequest
     */
    'type'?: string;
    /**
     * 
     * @type {{ [key: string]: any; }}
     * @memberof UpdateInvestmentRequest
     */
    'data'?: { [key: string]: any; };
    /**
     * 
     * @type {number}
     * @memberof UpdateInvestmentRequest
     */
    'expected_tax_documents'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateInvestmentRequest
     */
    'hidden'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateInvestmentRequest
     */
    'exclude_from_billing'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof UpdateInvestmentRequest
     */
    'currency'?: UpdateInvestmentRequestCurrencyEnum;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof UpdateInvestmentRequest
     */
    'attributes'?: { [key: string]: string; };
    /**
     * 
     * @type {string}
     * @memberof UpdateInvestmentRequest
     */
    'account'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateInvestmentRequest
     */
    'connection'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateInvestmentRequest
     */
    'investment_master'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateInvestmentRequest
     */
    'inactive'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof UpdateInvestmentRequest
     */
    'inactive_date'?: string;
    /**
     * 
     * @type {{ [key: string]: any; }}
     * @memberof UpdateInvestmentRequest
     */
    'investment_data'?: { [key: string]: any; };
}

export const UpdateInvestmentRequestCurrencyEnum = {
    Usd: 'USD',
    Eur: 'EUR',
    Cny: 'CNY',
    Jpy: 'JPY',
    Gbp: 'GBP',
    Aud: 'AUD',
    Cad: 'CAD',
    Chf: 'CHF',
    Inr: 'INR',
    Krw: 'KRW',
    Btc: 'BTC'
} as const;

export type UpdateInvestmentRequestCurrencyEnum = typeof UpdateInvestmentRequestCurrencyEnum[keyof typeof UpdateInvestmentRequestCurrencyEnum];

/**
 * 
 * @export
 * @interface UpdateMessage200Response
 */
export interface UpdateMessage200Response {
    /**
     * 
     * @type {boolean}
     * @memberof UpdateMessage200Response
     */
    'success'?: boolean;
}
/**
 * 
 * @export
 * @interface UpdateMessage400Response
 */
export interface UpdateMessage400Response {
    /**
     * 
     * @type {string}
     * @memberof UpdateMessage400Response
     */
    'message'?: string;
}
/**
 * 
 * @export
 * @interface UpdateMessageRequest
 */
export interface UpdateMessageRequest {
    [key: string]: any;

    /**
     * 
     * @type {boolean}
     * @memberof UpdateMessageRequest
     */
    'completed'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateMessageRequest
     */
    'read'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateMessageRequest
     */
    'flagged'?: boolean;
    /**
     * 
     * @type {any}
     * @memberof UpdateMessageRequest
     */
    'access'?: UpdateMessageRequestAccessEnum;
}

export const UpdateMessageRequestAccessEnum = {
    Investor: 'investor',
    Advisor: 'advisor',
    Admin: 'admin'
} as const;

export type UpdateMessageRequestAccessEnum = typeof UpdateMessageRequestAccessEnum[keyof typeof UpdateMessageRequestAccessEnum];

/**
 * 
 * @export
 * @interface UpdateRALRequestRequest
 */
export interface UpdateRALRequestRequest {
    [key: string]: any;

    /**
     * 
     * @type {Array<any>}
     * @memberof UpdateRALRequestRequest
     */
    'ownership_ids'?: Array<any>;
    /**
     * 
     * @type {string}
     * @memberof UpdateRALRequestRequest
     */
    'ownership_type'?: UpdateRALRequestRequestOwnershipTypeEnum | null;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof UpdateRALRequestRequest
     */
    'tokens'?: { [key: string]: string; };
    /**
     * 
     * @type {Array<UpdateRALRequestRequestSignatoriesInner>}
     * @memberof UpdateRALRequestRequest
     */
    'signatories'?: Array<UpdateRALRequestRequestSignatoriesInner>;
    /**
     * 
     * @type {string}
     * @memberof UpdateRALRequestRequest
     */
    'ral_panda_doc_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateRALRequestRequest
     */
    'ral_request_template'?: string;
}

export const UpdateRALRequestRequestOwnershipTypeEnum = {
    Account: 'Account',
    Investment: 'Investment',
    Null: 'null'
} as const;

export type UpdateRALRequestRequestOwnershipTypeEnum = typeof UpdateRALRequestRequestOwnershipTypeEnum[keyof typeof UpdateRALRequestRequestOwnershipTypeEnum];

/**
 * 
 * @export
 * @interface UpdateRALRequestRequestSignatoriesInner
 */
export interface UpdateRALRequestRequestSignatoriesInner {
    /**
     * 
     * @type {any}
     * @memberof UpdateRALRequestRequestSignatoriesInner
     */
    'first_name'?: any;
    /**
     * 
     * @type {any}
     * @memberof UpdateRALRequestRequestSignatoriesInner
     */
    'last_name'?: any;
    /**
     * 
     * @type {any}
     * @memberof UpdateRALRequestRequestSignatoriesInner
     */
    'role'?: any;
    /**
     * 
     * @type {any}
     * @memberof UpdateRALRequestRequestSignatoriesInner
     */
    'email'?: any;
}
/**
 * 
 * @export
 * @interface UpdateTransactionRequest
 */
export interface UpdateTransactionRequest {
    [key: string]: any;

    /**
     * 
     * @type {string}
     * @memberof UpdateTransactionRequest
     */
    'type'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateTransactionRequest
     */
    'date'?: string;
    /**
     * 
     * @type {number}
     * @memberof UpdateTransactionRequest
     */
    'amount'?: number;
    /**
     * 
     * @type {number}
     * @memberof UpdateTransactionRequest
     */
    'units'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateTransactionRequest
     */
    'open'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof UpdateTransactionRequest
     */
    'description'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateTransactionRequest
     */
    'joint_transaction'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateTransactionRequest
     */
    'document'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateTransactionRequest
     */
    'user'?: string;
}
/**
 * 
 * @export
 * @interface UpdateValuationRequest
 */
export interface UpdateValuationRequest {
    [key: string]: any;

    /**
     * 
     * @type {string}
     * @memberof UpdateValuationRequest
     */
    'date'?: string;
    /**
     * 
     * @type {number}
     * @memberof UpdateValuationRequest
     */
    'amount'?: number;
    /**
     * 
     * @type {number}
     * @memberof UpdateValuationRequest
     */
    'unit_price'?: number;
    /**
     * 
     * @type {string}
     * @memberof UpdateValuationRequest
     */
    'investment'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof UpdateValuationRequest
     */
    'documents'?: Array<string>;
}
/**
 * 
 * @export
 * @interface V2AssetmanagersPortalAdvisorygroupsGet200Response
 */
export interface V2AssetmanagersPortalAdvisorygroupsGet200Response {
    /**
     * 
     * @type {Array<V2AssetmanagersPortalAdvisorygroupsGet200ResponseAdvisoryGroupsInner>}
     * @memberof V2AssetmanagersPortalAdvisorygroupsGet200Response
     */
    'advisory_groups'?: Array<V2AssetmanagersPortalAdvisorygroupsGet200ResponseAdvisoryGroupsInner>;
}
/**
 * 
 * @export
 * @interface V2AssetmanagersPortalAdvisorygroupsGet200ResponseAdvisoryGroupsInner
 */
export interface V2AssetmanagersPortalAdvisorygroupsGet200ResponseAdvisoryGroupsInner {
    [key: string]: any;

    /**
     * 
     * @type {string}
     * @memberof V2AssetmanagersPortalAdvisorygroupsGet200ResponseAdvisoryGroupsInner
     */
    '_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof V2AssetmanagersPortalAdvisorygroupsGet200ResponseAdvisoryGroupsInner
     */
    'name'?: string;
}
/**
 * 
 * @export
 * @interface V2AssetmanagersPortalConnectionsIdPatchRequest
 */
export interface V2AssetmanagersPortalConnectionsIdPatchRequest {
    /**
     * 
     * @type {{ [key: string]: any; }}
     * @memberof V2AssetmanagersPortalConnectionsIdPatchRequest
     */
    'credentials'?: { [key: string]: any; };
    /**
     * 
     * @type {string}
     * @memberof V2AssetmanagersPortalConnectionsIdPatchRequest
     */
    'outreach_status'?: V2AssetmanagersPortalConnectionsIdPatchRequestOutreachStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof V2AssetmanagersPortalConnectionsIdPatchRequest
     */
    'asset_manager_request'?: string;
}

export const V2AssetmanagersPortalConnectionsIdPatchRequestOutreachStatusEnum = {
    ReadyForAmToStart: 'Ready For AM To Start',
    AmUnableToSupport: 'AM Unable To Support',
    AmNeedsMoreInformation: 'AM Needs More Information',
    ConnectionSetupCompleteByAm: 'Connection Setup Complete By AM'
} as const;

export type V2AssetmanagersPortalConnectionsIdPatchRequestOutreachStatusEnum = typeof V2AssetmanagersPortalConnectionsIdPatchRequestOutreachStatusEnum[keyof typeof V2AssetmanagersPortalConnectionsIdPatchRequestOutreachStatusEnum];

/**
 * 
 * @export
 * @interface V2AssetmanagersPortalListGet200Response
 */
export interface V2AssetmanagersPortalListGet200Response {
    /**
     * 
     * @type {number}
     * @memberof V2AssetmanagersPortalListGet200Response
     */
    'total'?: number;
    /**
     * 
     * @type {Array<V2AssetmanagersPortalListGet200ResponseConnectionsInner>}
     * @memberof V2AssetmanagersPortalListGet200Response
     */
    'connections'?: Array<V2AssetmanagersPortalListGet200ResponseConnectionsInner>;
}
/**
 * 
 * @export
 * @interface V2AssetmanagersPortalListGet200ResponseConnectionsInner
 */
export interface V2AssetmanagersPortalListGet200ResponseConnectionsInner {
    /**
     * 
     * @type {string}
     * @memberof V2AssetmanagersPortalListGet200ResponseConnectionsInner
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof V2AssetmanagersPortalListGet200ResponseConnectionsInner
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof V2AssetmanagersPortalListGet200ResponseConnectionsInner
     */
    'outreach_status'?: string;
    /**
     * 
     * @type {{ [key: string]: any; }}
     * @memberof V2AssetmanagersPortalListGet200ResponseConnectionsInner
     */
    'credentials'?: { [key: string]: any; };
    /**
     * 
     * @type {Array<V2AssetmanagersPortalListGet200ResponseConnectionsInnerRequiredCredsInner>}
     * @memberof V2AssetmanagersPortalListGet200ResponseConnectionsInner
     */
    'required_creds'?: Array<V2AssetmanagersPortalListGet200ResponseConnectionsInnerRequiredCredsInner>;
    /**
     * 
     * @type {string}
     * @memberof V2AssetmanagersPortalListGet200ResponseConnectionsInner
     */
    'tfa_email'?: string;
    /**
     * 
     * @type {string}
     * @memberof V2AssetmanagersPortalListGet200ResponseConnectionsInner
     */
    'tfa_phone'?: string;
    /**
     * 
     * @type {string}
     * @memberof V2AssetmanagersPortalListGet200ResponseConnectionsInner
     */
    'user_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof V2AssetmanagersPortalListGet200ResponseConnectionsInner
     */
    'user_email'?: string;
    /**
     * 
     * @type {Array<V2AssetmanagersPortalListGet200ResponseConnectionsInnerAccountListInner>}
     * @memberof V2AssetmanagersPortalListGet200ResponseConnectionsInner
     */
    'account_list'?: Array<V2AssetmanagersPortalListGet200ResponseConnectionsInnerAccountListInner>;
    /**
     * 
     * @type {Array<V2AssetmanagersPortalListGet200ResponseConnectionsInnerAdvisoryGroupsInner>}
     * @memberof V2AssetmanagersPortalListGet200ResponseConnectionsInner
     */
    'advisory_groups'?: Array<V2AssetmanagersPortalListGet200ResponseConnectionsInnerAdvisoryGroupsInner>;
    /**
     * 
     * @type {string}
     * @memberof V2AssetmanagersPortalListGet200ResponseConnectionsInner
     */
    'asset_manager_request'?: string;
    /**
     * 
     * @type {Array<V2AssetmanagersPortalListGet200ResponseConnectionsInnerRalRequestsInner>}
     * @memberof V2AssetmanagersPortalListGet200ResponseConnectionsInner
     */
    'ral_requests'?: Array<V2AssetmanagersPortalListGet200ResponseConnectionsInnerRalRequestsInner>;
}
/**
 * 
 * @export
 * @interface V2AssetmanagersPortalListGet200ResponseConnectionsInnerAccountListInner
 */
export interface V2AssetmanagersPortalListGet200ResponseConnectionsInnerAccountListInner {
    /**
     * 
     * @type {string}
     * @memberof V2AssetmanagersPortalListGet200ResponseConnectionsInnerAccountListInner
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof V2AssetmanagersPortalListGet200ResponseConnectionsInnerAccountListInner
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof V2AssetmanagersPortalListGet200ResponseConnectionsInnerAccountListInner
     */
    'signed_on'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof V2AssetmanagersPortalListGet200ResponseConnectionsInnerAccountListInner
     */
    'ral_send_times'?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof V2AssetmanagersPortalListGet200ResponseConnectionsInnerAccountListInner
     */
    'ral_panda_doc_status'?: string;
    /**
     * 
     * @type {string}
     * @memberof V2AssetmanagersPortalListGet200ResponseConnectionsInnerAccountListInner
     */
    'reporting_auth_id'?: string;
    /**
     * 
     * @type {{ [key: string]: any; }}
     * @memberof V2AssetmanagersPortalListGet200ResponseConnectionsInnerAccountListInner
     */
    'reporting_auth_urls'?: { [key: string]: any; };
}
/**
 * 
 * @export
 * @interface V2AssetmanagersPortalListGet200ResponseConnectionsInnerAdvisoryGroupsInner
 */
export interface V2AssetmanagersPortalListGet200ResponseConnectionsInnerAdvisoryGroupsInner {
    /**
     * 
     * @type {string}
     * @memberof V2AssetmanagersPortalListGet200ResponseConnectionsInnerAdvisoryGroupsInner
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof V2AssetmanagersPortalListGet200ResponseConnectionsInnerAdvisoryGroupsInner
     */
    'name'?: string;
}
/**
 * 
 * @export
 * @interface V2AssetmanagersPortalListGet200ResponseConnectionsInnerRalRequestsInner
 */
export interface V2AssetmanagersPortalListGet200ResponseConnectionsInnerRalRequestsInner {
    /**
     * 
     * @type {string}
     * @memberof V2AssetmanagersPortalListGet200ResponseConnectionsInnerRalRequestsInner
     */
    'id'?: string;
    /**
     * 
     * @type {V2AssetmanagersPortalListGet200ResponseConnectionsInnerRalRequestsInnerSignedRal}
     * @memberof V2AssetmanagersPortalListGet200ResponseConnectionsInnerRalRequestsInner
     */
    'signed_ral'?: V2AssetmanagersPortalListGet200ResponseConnectionsInnerRalRequestsInnerSignedRal;
    /**
     * 
     * @type {string}
     * @memberof V2AssetmanagersPortalListGet200ResponseConnectionsInnerRalRequestsInner
     */
    'sent_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof V2AssetmanagersPortalListGet200ResponseConnectionsInnerRalRequestsInner
     */
    'signed_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof V2AssetmanagersPortalListGet200ResponseConnectionsInnerRalRequestsInner
     */
    'ral_panda_doc_status'?: string;
    /**
     * 
     * @type {string}
     * @memberof V2AssetmanagersPortalListGet200ResponseConnectionsInnerRalRequestsInner
     */
    'ownership_type'?: string;
    /**
     * 
     * @type {Array<ListConnectionSyncs200ResponseConnectionsyncsInnerConnectionUser>}
     * @memberof V2AssetmanagersPortalListGet200ResponseConnectionsInnerRalRequestsInner
     */
    'ownership_ids'?: Array<ListConnectionSyncs200ResponseConnectionsyncsInnerConnectionUser>;
    /**
     * 
     * @type {ListAccounts200ResponseAccountsInnerUser}
     * @memberof V2AssetmanagersPortalListGet200ResponseConnectionsInnerRalRequestsInner
     */
    'user'?: ListAccounts200ResponseAccountsInnerUser;
    /**
     * 
     * @type {string}
     * @memberof V2AssetmanagersPortalListGet200ResponseConnectionsInnerRalRequestsInner
     */
    'reporting_auth_id'?: string;
    /**
     * 
     * @type {{ [key: string]: any; }}
     * @memberof V2AssetmanagersPortalListGet200ResponseConnectionsInnerRalRequestsInner
     */
    'reporting_auth_urls'?: { [key: string]: any; };
}
/**
 * 
 * @export
 * @interface V2AssetmanagersPortalListGet200ResponseConnectionsInnerRalRequestsInnerSignedRal
 */
export interface V2AssetmanagersPortalListGet200ResponseConnectionsInnerRalRequestsInnerSignedRal {
    /**
     * 
     * @type {string}
     * @memberof V2AssetmanagersPortalListGet200ResponseConnectionsInnerRalRequestsInnerSignedRal
     */
    '_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof V2AssetmanagersPortalListGet200ResponseConnectionsInnerRalRequestsInnerSignedRal
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof V2AssetmanagersPortalListGet200ResponseConnectionsInnerRalRequestsInnerSignedRal
     */
    'created_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof V2AssetmanagersPortalListGet200ResponseConnectionsInnerRalRequestsInnerSignedRal
     */
    'updated_at'?: string;
    /**
     * 
     * @type {{ [key: string]: any; }}
     * @memberof V2AssetmanagersPortalListGet200ResponseConnectionsInnerRalRequestsInnerSignedRal
     */
    'files'?: { [key: string]: any; };
}
/**
 * 
 * @export
 * @interface V2AssetmanagersPortalListGet200ResponseConnectionsInnerRequiredCredsInner
 */
export interface V2AssetmanagersPortalListGet200ResponseConnectionsInnerRequiredCredsInner {
    /**
     * 
     * @type {string}
     * @memberof V2AssetmanagersPortalListGet200ResponseConnectionsInnerRequiredCredsInner
     */
    'key'?: string;
    /**
     * 
     * @type {string}
     * @memberof V2AssetmanagersPortalListGet200ResponseConnectionsInnerRequiredCredsInner
     */
    'input'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof V2AssetmanagersPortalListGet200ResponseConnectionsInnerRequiredCredsInner
     */
    'options'?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof V2AssetmanagersPortalListGet200ResponseConnectionsInnerRequiredCredsInner
     */
    'hint'?: string;
}
/**
 * 
 * @export
 * @interface V2AssetmanagersPortalLoginPost200Response
 */
export interface V2AssetmanagersPortalLoginPost200Response {
    /**
     * 
     * @type {string}
     * @memberof V2AssetmanagersPortalLoginPost200Response
     */
    'auth_token'?: string;
}
/**
 * 
 * @export
 * @interface V2AssetmanagersPortalLoginPostRequest
 */
export interface V2AssetmanagersPortalLoginPostRequest {
    /**
     * 
     * @type {string}
     * @memberof V2AssetmanagersPortalLoginPostRequest
     */
    'asset_manager_id': string;
    /**
     * 
     * @type {string}
     * @memberof V2AssetmanagersPortalLoginPostRequest
     */
    'asset_manager_passkey': string;
}
/**
 * 
 * @export
 * @interface V2ConnectionsSyncCredentialsGet200Response
 */
export interface V2ConnectionsSyncCredentialsGet200Response {
    /**
     * 
     * @type {{ [key: string]: any; }}
     * @memberof V2ConnectionsSyncCredentialsGet200Response
     */
    'credentials'?: { [key: string]: any; };
}
/**
 * 
 * @export
 * @interface V2ConnectionsSyncExistingGet200Response
 */
export interface V2ConnectionsSyncExistingGet200Response {
    /**
     * 
     * @type {{ [key: string]: any; }}
     * @memberof V2ConnectionsSyncExistingGet200Response
     */
    'existing_data'?: { [key: string]: any; };
}
/**
 * 
 * @export
 * @interface V2ConnectionsSyncExistingGet400Response
 */
export interface V2ConnectionsSyncExistingGet400Response {
    /**
     * 
     * @type {string}
     * @memberof V2ConnectionsSyncExistingGet400Response
     */
    'error'?: string;
}
/**
 * 
 * @export
 * @interface V2ConnectionsSyncIdCompletePostRequest
 */
export interface V2ConnectionsSyncIdCompletePostRequest {
    /**
     * 
     * @type {string}
     * @memberof V2ConnectionsSyncIdCompletePostRequest
     */
    'status': V2ConnectionsSyncIdCompletePostRequestStatusEnum;
    /**
     * 
     * @type {{ [key: string]: any; }}
     * @memberof V2ConnectionsSyncIdCompletePostRequest
     */
    'job_response': { [key: string]: any; };
    /**
     * 
     * @type {{ [key: string]: any; }}
     * @memberof V2ConnectionsSyncIdCompletePostRequest
     */
    'error_report': { [key: string]: any; };
    /**
     * 
     * @type {string}
     * @memberof V2ConnectionsSyncIdCompletePostRequest
     */
    'error_code'?: string;
}

export const V2ConnectionsSyncIdCompletePostRequestStatusEnum = {
    Success: 'success',
    Failure: 'failure',
    Pending: 'pending'
} as const;

export type V2ConnectionsSyncIdCompletePostRequestStatusEnum = typeof V2ConnectionsSyncIdCompletePostRequestStatusEnum[keyof typeof V2ConnectionsSyncIdCompletePostRequestStatusEnum];

/**
 * 
 * @export
 * @interface V2ConnectionsSyncIdImportAccountsPostRequest
 */
export interface V2ConnectionsSyncIdImportAccountsPostRequest {
    /**
     * 
     * @type {Set<V2ConnectionsSyncIdImportAccountsPostRequestAccountsInner>}
     * @memberof V2ConnectionsSyncIdImportAccountsPostRequest
     */
    'accounts': Set<V2ConnectionsSyncIdImportAccountsPostRequestAccountsInner>;
}
/**
 * 
 * @export
 * @interface V2ConnectionsSyncIdImportAccountsPostRequestAccountsInner
 */
export interface V2ConnectionsSyncIdImportAccountsPostRequestAccountsInner {
    /**
     * 
     * @type {string}
     * @memberof V2ConnectionsSyncIdImportAccountsPostRequestAccountsInner
     */
    'custom_id': string;
    /**
     * 
     * @type {string}
     * @memberof V2ConnectionsSyncIdImportAccountsPostRequestAccountsInner
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof V2ConnectionsSyncIdImportAccountsPostRequestAccountsInner
     */
    'id_type'?: string;
    /**
     * 
     * @type {string}
     * @memberof V2ConnectionsSyncIdImportAccountsPostRequestAccountsInner
     */
    'id_number'?: string;
    /**
     * 
     * @type {string}
     * @memberof V2ConnectionsSyncIdImportAccountsPostRequestAccountsInner
     */
    'type'?: string;
    /**
     * 
     * @type {string}
     * @memberof V2ConnectionsSyncIdImportAccountsPostRequestAccountsInner
     */
    'street_line1'?: string;
    /**
     * 
     * @type {string}
     * @memberof V2ConnectionsSyncIdImportAccountsPostRequestAccountsInner
     */
    'street_line2'?: string;
    /**
     * 
     * @type {string}
     * @memberof V2ConnectionsSyncIdImportAccountsPostRequestAccountsInner
     */
    'city'?: string;
    /**
     * 
     * @type {string}
     * @memberof V2ConnectionsSyncIdImportAccountsPostRequestAccountsInner
     */
    'state'?: string;
    /**
     * 
     * @type {string}
     * @memberof V2ConnectionsSyncIdImportAccountsPostRequestAccountsInner
     */
    'zip'?: string;
    /**
     * 
     * @type {string}
     * @memberof V2ConnectionsSyncIdImportAccountsPostRequestAccountsInner
     */
    'user_custom_id'?: string;
}
/**
 * 
 * @export
 * @interface V2ConnectionsSyncIdImportCommitmentsPostRequest
 */
export interface V2ConnectionsSyncIdImportCommitmentsPostRequest {
    /**
     * 
     * @type {Set<V2ConnectionsSyncIdImportCommitmentsPostRequestCommitmentsInner>}
     * @memberof V2ConnectionsSyncIdImportCommitmentsPostRequest
     */
    'commitments': Set<V2ConnectionsSyncIdImportCommitmentsPostRequestCommitmentsInner>;
}
/**
 * 
 * @export
 * @interface V2ConnectionsSyncIdImportCommitmentsPostRequestCommitmentsInner
 */
export interface V2ConnectionsSyncIdImportCommitmentsPostRequestCommitmentsInner {
    /**
     * 
     * @type {string}
     * @memberof V2ConnectionsSyncIdImportCommitmentsPostRequestCommitmentsInner
     */
    'custom_id': string;
    /**
     * 
     * @type {number}
     * @memberof V2ConnectionsSyncIdImportCommitmentsPostRequestCommitmentsInner
     */
    'amount': number;
    /**
     * 
     * @type {string}
     * @memberof V2ConnectionsSyncIdImportCommitmentsPostRequestCommitmentsInner
     */
    'date': string;
    /**
     * 
     * @type {string}
     * @memberof V2ConnectionsSyncIdImportCommitmentsPostRequestCommitmentsInner
     */
    'investment_custom_id'?: string;
}
/**
 * 
 * @export
 * @interface V2ConnectionsSyncIdImportDocumentsPost200Response
 */
export interface V2ConnectionsSyncIdImportDocumentsPost200Response {
    /**
     * 
     * @type {boolean}
     * @memberof V2ConnectionsSyncIdImportDocumentsPost200Response
     */
    'success'?: boolean;
    /**
     * 
     * @type {Array<V2ConnectionsSyncIdImportDocumentsPost200ResponseUploadUrlsInner>}
     * @memberof V2ConnectionsSyncIdImportDocumentsPost200Response
     */
    'upload_urls'?: Array<V2ConnectionsSyncIdImportDocumentsPost200ResponseUploadUrlsInner>;
}
/**
 * 
 * @export
 * @interface V2ConnectionsSyncIdImportDocumentsPost200ResponseUploadUrlsInner
 */
export interface V2ConnectionsSyncIdImportDocumentsPost200ResponseUploadUrlsInner {
    /**
     * 
     * @type {string}
     * @memberof V2ConnectionsSyncIdImportDocumentsPost200ResponseUploadUrlsInner
     */
    'custom_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof V2ConnectionsSyncIdImportDocumentsPost200ResponseUploadUrlsInner
     */
    'hash'?: string;
    /**
     * 
     * @type {string}
     * @memberof V2ConnectionsSyncIdImportDocumentsPost200ResponseUploadUrlsInner
     */
    'url'?: string;
}
/**
 * 
 * @export
 * @interface V2ConnectionsSyncIdImportDocumentsPostRequest
 */
export interface V2ConnectionsSyncIdImportDocumentsPostRequest {
    /**
     * 
     * @type {Set<V2ConnectionsSyncIdImportDocumentsPostRequestDocumentsInner>}
     * @memberof V2ConnectionsSyncIdImportDocumentsPostRequest
     */
    'documents': Set<V2ConnectionsSyncIdImportDocumentsPostRequestDocumentsInner>;
}
/**
 * 
 * @export
 * @interface V2ConnectionsSyncIdImportDocumentsPostRequestDocumentsInner
 */
export interface V2ConnectionsSyncIdImportDocumentsPostRequestDocumentsInner {
    /**
     * 
     * @type {string}
     * @memberof V2ConnectionsSyncIdImportDocumentsPostRequestDocumentsInner
     */
    'custom_id': string;
    /**
     * 
     * @type {string}
     * @memberof V2ConnectionsSyncIdImportDocumentsPostRequestDocumentsInner
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof V2ConnectionsSyncIdImportDocumentsPostRequestDocumentsInner
     */
    'date': string;
    /**
     * 
     * @type {string}
     * @memberof V2ConnectionsSyncIdImportDocumentsPostRequestDocumentsInner
     */
    'type'?: string;
    /**
     * 
     * @type {string}
     * @memberof V2ConnectionsSyncIdImportDocumentsPostRequestDocumentsInner
     */
    'hash'?: string;
    /**
     * 
     * @type {string}
     * @memberof V2ConnectionsSyncIdImportDocumentsPostRequestDocumentsInner
     */
    'investment_custom_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof V2ConnectionsSyncIdImportDocumentsPostRequestDocumentsInner
     */
    'account_custom_id'?: string;
}
/**
 * 
 * @export
 * @interface V2ConnectionsSyncIdImportInvestmentsPostRequest
 */
export interface V2ConnectionsSyncIdImportInvestmentsPostRequest {
    /**
     * 
     * @type {Set<V2ConnectionsSyncIdImportInvestmentsPostRequestInvestmentsInner>}
     * @memberof V2ConnectionsSyncIdImportInvestmentsPostRequest
     */
    'investments': Set<V2ConnectionsSyncIdImportInvestmentsPostRequestInvestmentsInner>;
}
/**
 * 
 * @export
 * @interface V2ConnectionsSyncIdImportInvestmentsPostRequestInvestmentsInner
 */
export interface V2ConnectionsSyncIdImportInvestmentsPostRequestInvestmentsInner {
    /**
     * 
     * @type {string}
     * @memberof V2ConnectionsSyncIdImportInvestmentsPostRequestInvestmentsInner
     */
    'custom_id': string;
    /**
     * 
     * @type {string}
     * @memberof V2ConnectionsSyncIdImportInvestmentsPostRequestInvestmentsInner
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof V2ConnectionsSyncIdImportInvestmentsPostRequestInvestmentsInner
     */
    'type'?: string;
    /**
     * 
     * @type {string}
     * @memberof V2ConnectionsSyncIdImportInvestmentsPostRequestInvestmentsInner
     */
    'subtype'?: string;
    /**
     * 
     * @type {string}
     * @memberof V2ConnectionsSyncIdImportInvestmentsPostRequestInvestmentsInner
     */
    'account_custom_id'?: string;
}
/**
 * 
 * @export
 * @interface V2ConnectionsSyncIdImportTransactionsPostRequest
 */
export interface V2ConnectionsSyncIdImportTransactionsPostRequest {
    /**
     * 
     * @type {Set<V2ConnectionsSyncIdImportTransactionsPostRequestTransactionsInner>}
     * @memberof V2ConnectionsSyncIdImportTransactionsPostRequest
     */
    'transactions': Set<V2ConnectionsSyncIdImportTransactionsPostRequestTransactionsInner>;
}
/**
 * 
 * @export
 * @interface V2ConnectionsSyncIdImportTransactionsPostRequestTransactionsInner
 */
export interface V2ConnectionsSyncIdImportTransactionsPostRequestTransactionsInner {
    /**
     * 
     * @type {string}
     * @memberof V2ConnectionsSyncIdImportTransactionsPostRequestTransactionsInner
     */
    'custom_id': string;
    /**
     * 
     * @type {string}
     * @memberof V2ConnectionsSyncIdImportTransactionsPostRequestTransactionsInner
     */
    'type': V2ConnectionsSyncIdImportTransactionsPostRequestTransactionsInnerTypeEnum;
    /**
     * 
     * @type {number}
     * @memberof V2ConnectionsSyncIdImportTransactionsPostRequestTransactionsInner
     */
    'amount': number;
    /**
     * 
     * @type {string}
     * @memberof V2ConnectionsSyncIdImportTransactionsPostRequestTransactionsInner
     */
    'date': string;
    /**
     * 
     * @type {string}
     * @memberof V2ConnectionsSyncIdImportTransactionsPostRequestTransactionsInner
     */
    'description'?: string;
    /**
     * 
     * @type {string}
     * @memberof V2ConnectionsSyncIdImportTransactionsPostRequestTransactionsInner
     */
    'joint_transaction_custom_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof V2ConnectionsSyncIdImportTransactionsPostRequestTransactionsInner
     */
    'investment_custom_id': string;
}

export const V2ConnectionsSyncIdImportTransactionsPostRequestTransactionsInnerTypeEnum = {
    Buy: 'buy',
    Sale: 'sale',
    CapitalCall: 'capital_call',
    CashDistributionReturnOfCapital: 'cash_distribution_return_of_capital',
    CapitalCommitment: 'capital_commitment',
    RecallableReturnOfCapital: 'recallable_return_of_capital',
    RecallableReturnOnCapital: 'recallable_return_on_capital',
    ReturnOfCapital: 'return_of_capital',
    ReturnOnCapital: 'return_on_capital',
    InterestIncome: 'interest_income',
    InterestExpense: 'interest_expense',
    IncentiveFee: 'incentive_fee',
    Redemption: 'redemption',
    Subscription: 'subscription',
    ValuationChange: 'valuation_change',
    CashDeposit: 'cash_deposit',
    CashWithdrawal: 'cash_withdrawal',
    Reinvestment: 'reinvestment',
    Dividend: 'dividend',
    DividendReversal: 'dividend_reversal',
    ManagementFee: 'management_fee',
    ManagementFeeRebate: 'management_fee_rebate',
    OtherFee: 'other_fee',
    TransferIn: 'transfer_in',
    TransferOut: 'transfer_out',
    Other: 'other',
    JointTransaction: 'joint_transaction'
} as const;

export type V2ConnectionsSyncIdImportTransactionsPostRequestTransactionsInnerTypeEnum = typeof V2ConnectionsSyncIdImportTransactionsPostRequestTransactionsInnerTypeEnum[keyof typeof V2ConnectionsSyncIdImportTransactionsPostRequestTransactionsInnerTypeEnum];

/**
 * 
 * @export
 * @interface V2ConnectionsSyncIdImportUsersPostRequest
 */
export interface V2ConnectionsSyncIdImportUsersPostRequest {
    /**
     * 
     * @type {Set<V2ConnectionsSyncIdImportUsersPostRequestUsersInner>}
     * @memberof V2ConnectionsSyncIdImportUsersPostRequest
     */
    'users': Set<V2ConnectionsSyncIdImportUsersPostRequestUsersInner>;
}
/**
 * 
 * @export
 * @interface V2ConnectionsSyncIdImportUsersPostRequestUsersInner
 */
export interface V2ConnectionsSyncIdImportUsersPostRequestUsersInner {
    /**
     * 
     * @type {string}
     * @memberof V2ConnectionsSyncIdImportUsersPostRequestUsersInner
     */
    'custom_id': string;
    /**
     * 
     * @type {string}
     * @memberof V2ConnectionsSyncIdImportUsersPostRequestUsersInner
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof V2ConnectionsSyncIdImportUsersPostRequestUsersInner
     */
    'email': string;
}
/**
 * 
 * @export
 * @interface V2ConnectionsSyncIdImportValuationsPostRequest
 */
export interface V2ConnectionsSyncIdImportValuationsPostRequest {
    /**
     * 
     * @type {Set<V2ConnectionsSyncIdImportValuationsPostRequestValuationsInner>}
     * @memberof V2ConnectionsSyncIdImportValuationsPostRequest
     */
    'valuations': Set<V2ConnectionsSyncIdImportValuationsPostRequestValuationsInner>;
}
/**
 * 
 * @export
 * @interface V2ConnectionsSyncIdImportValuationsPostRequestValuationsInner
 */
export interface V2ConnectionsSyncIdImportValuationsPostRequestValuationsInner {
    /**
     * 
     * @type {string}
     * @memberof V2ConnectionsSyncIdImportValuationsPostRequestValuationsInner
     */
    'custom_id': string;
    /**
     * 
     * @type {number}
     * @memberof V2ConnectionsSyncIdImportValuationsPostRequestValuationsInner
     */
    'amount': number;
    /**
     * 
     * @type {string}
     * @memberof V2ConnectionsSyncIdImportValuationsPostRequestValuationsInner
     */
    'date': string;
    /**
     * 
     * @type {string}
     * @memberof V2ConnectionsSyncIdImportValuationsPostRequestValuationsInner
     */
    'investment_custom_id': string;
}
/**
 * 
 * @export
 * @interface V2ConnectionsSyncMessageGet200Response
 */
export interface V2ConnectionsSyncMessageGet200Response {
    /**
     * 
     * @type {V2ConnectionsSyncMessageGet200ResponseMessage}
     * @memberof V2ConnectionsSyncMessageGet200Response
     */
    'message'?: V2ConnectionsSyncMessageGet200ResponseMessage;
}
/**
 * 
 * @export
 * @interface V2ConnectionsSyncMessageGet200ResponseMessage
 */
export interface V2ConnectionsSyncMessageGet200ResponseMessage {
    /**
     * 
     * @type {string}
     * @memberof V2ConnectionsSyncMessageGet200ResponseMessage
     */
    '_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof V2ConnectionsSyncMessageGet200ResponseMessage
     */
    'type'?: V2ConnectionsSyncMessageGet200ResponseMessageTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof V2ConnectionsSyncMessageGet200ResponseMessage
     */
    'date'?: string;
    /**
     * 
     * @type {string}
     * @memberof V2ConnectionsSyncMessageGet200ResponseMessage
     */
    'subject'?: string;
    /**
     * 
     * @type {string}
     * @memberof V2ConnectionsSyncMessageGet200ResponseMessage
     */
    'body'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof V2ConnectionsSyncMessageGet200ResponseMessage
     */
    'parsed'?: boolean;
}

export const V2ConnectionsSyncMessageGet200ResponseMessageTypeEnum = {
    Email: 'email',
    Sms: 'sms',
    Manual: 'manual'
} as const;

export type V2ConnectionsSyncMessageGet200ResponseMessageTypeEnum = typeof V2ConnectionsSyncMessageGet200ResponseMessageTypeEnum[keyof typeof V2ConnectionsSyncMessageGet200ResponseMessageTypeEnum];


/**
 * AccountApi - axios parameter creator
 * @export
 */
export const AccountApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Create a new Account document.
         * @param {CreateAccountRequest} CreateAccountRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createAccount: async (CreateAccountRequest: CreateAccountRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'CreateAccountRequest' is not null or undefined
            assertParamExists('createAccount', 'CreateAccountRequest', CreateAccountRequest)
            const localVarPath = `/v2/accounts/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication adminAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication advisorAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication investorAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(CreateAccountRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Delete an Account document.
         * @param {string} account_id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteAccount: async (account_id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'account_id' is not null or undefined
            assertParamExists('deleteAccount', 'account_id', account_id)
            const localVarPath = `/v2/accounts/{account_id}`
                .replace(`{${"account_id"}}`, encodeURIComponent(String(account_id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication adminAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication advisorAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication investorAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get a single Account document by ID.
         * @param {string} account_id 
         * @param {boolean} [populate_user] 
         * @param {boolean} [populate_investments_count] 
         * @param {boolean} [populate_audit_log] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAccount: async (account_id: string, populate_user?: boolean, populate_investments_count?: boolean, populate_audit_log?: boolean, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'account_id' is not null or undefined
            assertParamExists('getAccount', 'account_id', account_id)
            const localVarPath = `/v2/accounts/{account_id}`
                .replace(`{${"account_id"}}`, encodeURIComponent(String(account_id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication adminAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication advisorAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication investorAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (populate_user !== undefined) {
                localVarQueryParameter['populate_user'] = populate_user;
            }

            if (populate_investments_count !== undefined) {
                localVarQueryParameter['populate_investments_count'] = populate_investments_count;
            }

            if (populate_audit_log !== undefined) {
                localVarQueryParameter['populate_audit_log'] = populate_audit_log;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Paginate through Account documents.
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {string} [search] 
         * @param {ListAccountsSortFieldEnum} [sort_field] 
         * @param {ListAccountsSortDirectionEnum} [sort_direction] 
         * @param {boolean} [populate_user] 
         * @param {boolean} [populate_investments_count] 
         * @param {Array<string>} [users] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listAccounts: async (skip?: number, limit?: number, search?: string, sort_field?: ListAccountsSortFieldEnum, sort_direction?: ListAccountsSortDirectionEnum, populate_user?: boolean, populate_investments_count?: boolean, users?: Array<string>, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v2/accounts/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication adminAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication advisorAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication investorAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (skip !== undefined) {
                localVarQueryParameter['skip'] = skip;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (sort_field !== undefined) {
                localVarQueryParameter['sort_field'] = sort_field;
            }

            if (sort_direction !== undefined) {
                localVarQueryParameter['sort_direction'] = sort_direction;
            }

            if (populate_user !== undefined) {
                localVarQueryParameter['populate_user'] = populate_user;
            }

            if (populate_investments_count !== undefined) {
                localVarQueryParameter['populate_investments_count'] = populate_investments_count;
            }

            if (users) {
                localVarQueryParameter['users'] = users;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update an Account document.
         * @param {string} account_id 
         * @param {UpdateAccountRequest} [UpdateAccountRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAccount: async (account_id: string, UpdateAccountRequest?: UpdateAccountRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'account_id' is not null or undefined
            assertParamExists('updateAccount', 'account_id', account_id)
            const localVarPath = `/v2/accounts/{account_id}`
                .replace(`{${"account_id"}}`, encodeURIComponent(String(account_id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication adminAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication advisorAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication investorAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(UpdateAccountRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AccountApi - functional programming interface
 * @export
 */
export const AccountApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AccountApiAxiosParamCreator(configuration)
    return {
        /**
         * Create a new Account document.
         * @param {CreateAccountRequest} CreateAccountRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createAccount(CreateAccountRequest: CreateAccountRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CreateAccount201Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createAccount(CreateAccountRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AccountApi.createAccount']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Delete an Account document.
         * @param {string} account_id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteAccount(account_id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DeleteAccount200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteAccount(account_id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AccountApi.deleteAccount']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Get a single Account document by ID.
         * @param {string} account_id 
         * @param {boolean} [populate_user] 
         * @param {boolean} [populate_investments_count] 
         * @param {boolean} [populate_audit_log] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAccount(account_id: string, populate_user?: boolean, populate_investments_count?: boolean, populate_audit_log?: boolean, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetAccount200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAccount(account_id, populate_user, populate_investments_count, populate_audit_log, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AccountApi.getAccount']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Paginate through Account documents.
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {string} [search] 
         * @param {ListAccountsSortFieldEnum} [sort_field] 
         * @param {ListAccountsSortDirectionEnum} [sort_direction] 
         * @param {boolean} [populate_user] 
         * @param {boolean} [populate_investments_count] 
         * @param {Array<string>} [users] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listAccounts(skip?: number, limit?: number, search?: string, sort_field?: ListAccountsSortFieldEnum, sort_direction?: ListAccountsSortDirectionEnum, populate_user?: boolean, populate_investments_count?: boolean, users?: Array<string>, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListAccounts200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listAccounts(skip, limit, search, sort_field, sort_direction, populate_user, populate_investments_count, users, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AccountApi.listAccounts']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Update an Account document.
         * @param {string} account_id 
         * @param {UpdateAccountRequest} [UpdateAccountRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateAccount(account_id: string, UpdateAccountRequest?: UpdateAccountRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DeleteAccount200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateAccount(account_id, UpdateAccountRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AccountApi.updateAccount']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * AccountApi - factory interface
 * @export
 */
export const AccountApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AccountApiFp(configuration)
    return {
        /**
         * Create a new Account document.
         * @param {AccountApiCreateAccountRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createAccount(requestParameters: AccountApiCreateAccountRequest, options?: RawAxiosRequestConfig): AxiosPromise<CreateAccount201Response> {
            return localVarFp.createAccount(requestParameters.CreateAccountRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Delete an Account document.
         * @param {AccountApiDeleteAccountRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteAccount(requestParameters: AccountApiDeleteAccountRequest, options?: RawAxiosRequestConfig): AxiosPromise<DeleteAccount200Response> {
            return localVarFp.deleteAccount(requestParameters.account_id, options).then((request) => request(axios, basePath));
        },
        /**
         * Get a single Account document by ID.
         * @param {AccountApiGetAccountRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAccount(requestParameters: AccountApiGetAccountRequest, options?: RawAxiosRequestConfig): AxiosPromise<GetAccount200Response> {
            return localVarFp.getAccount(requestParameters.account_id, requestParameters.populate_user, requestParameters.populate_investments_count, requestParameters.populate_audit_log, options).then((request) => request(axios, basePath));
        },
        /**
         * Paginate through Account documents.
         * @param {AccountApiListAccountsRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listAccounts(requestParameters: AccountApiListAccountsRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<ListAccounts200Response> {
            return localVarFp.listAccounts(requestParameters.skip, requestParameters.limit, requestParameters.search, requestParameters.sort_field, requestParameters.sort_direction, requestParameters.populate_user, requestParameters.populate_investments_count, requestParameters.users, options).then((request) => request(axios, basePath));
        },
        /**
         * Update an Account document.
         * @param {AccountApiUpdateAccountRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAccount(requestParameters: AccountApiUpdateAccountRequest, options?: RawAxiosRequestConfig): AxiosPromise<DeleteAccount200Response> {
            return localVarFp.updateAccount(requestParameters.account_id, requestParameters.UpdateAccountRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for createAccount operation in AccountApi.
 * @export
 * @interface AccountApiCreateAccountRequest
 */
export interface AccountApiCreateAccountRequest {
    /**
     * 
     * @type {CreateAccountRequest}
     * @memberof AccountApiCreateAccount
     */
    readonly CreateAccountRequest: CreateAccountRequest
}

/**
 * Request parameters for deleteAccount operation in AccountApi.
 * @export
 * @interface AccountApiDeleteAccountRequest
 */
export interface AccountApiDeleteAccountRequest {
    /**
     * 
     * @type {string}
     * @memberof AccountApiDeleteAccount
     */
    readonly account_id: string
}

/**
 * Request parameters for getAccount operation in AccountApi.
 * @export
 * @interface AccountApiGetAccountRequest
 */
export interface AccountApiGetAccountRequest {
    /**
     * 
     * @type {string}
     * @memberof AccountApiGetAccount
     */
    readonly account_id: string

    /**
     * 
     * @type {boolean}
     * @memberof AccountApiGetAccount
     */
    readonly populate_user?: boolean

    /**
     * 
     * @type {boolean}
     * @memberof AccountApiGetAccount
     */
    readonly populate_investments_count?: boolean

    /**
     * 
     * @type {boolean}
     * @memberof AccountApiGetAccount
     */
    readonly populate_audit_log?: boolean
}

/**
 * Request parameters for listAccounts operation in AccountApi.
 * @export
 * @interface AccountApiListAccountsRequest
 */
export interface AccountApiListAccountsRequest {
    /**
     * 
     * @type {number}
     * @memberof AccountApiListAccounts
     */
    readonly skip?: number

    /**
     * 
     * @type {number}
     * @memberof AccountApiListAccounts
     */
    readonly limit?: number

    /**
     * 
     * @type {string}
     * @memberof AccountApiListAccounts
     */
    readonly search?: string

    /**
     * 
     * @type {'created_at' | 'name' | 'account_type'}
     * @memberof AccountApiListAccounts
     */
    readonly sort_field?: ListAccountsSortFieldEnum

    /**
     * 
     * @type {'asc' | 'desc'}
     * @memberof AccountApiListAccounts
     */
    readonly sort_direction?: ListAccountsSortDirectionEnum

    /**
     * 
     * @type {boolean}
     * @memberof AccountApiListAccounts
     */
    readonly populate_user?: boolean

    /**
     * 
     * @type {boolean}
     * @memberof AccountApiListAccounts
     */
    readonly populate_investments_count?: boolean

    /**
     * 
     * @type {Array<string>}
     * @memberof AccountApiListAccounts
     */
    readonly users?: Array<string>
}

/**
 * Request parameters for updateAccount operation in AccountApi.
 * @export
 * @interface AccountApiUpdateAccountRequest
 */
export interface AccountApiUpdateAccountRequest {
    /**
     * 
     * @type {string}
     * @memberof AccountApiUpdateAccount
     */
    readonly account_id: string

    /**
     * 
     * @type {UpdateAccountRequest}
     * @memberof AccountApiUpdateAccount
     */
    readonly UpdateAccountRequest?: UpdateAccountRequest
}

/**
 * AccountApi - object-oriented interface
 * @export
 * @class AccountApi
 * @extends {BaseAPI}
 */
export class AccountApi extends BaseAPI {
    /**
     * Create a new Account document.
     * @param {AccountApiCreateAccountRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountApi
     */
    public createAccount(requestParameters: AccountApiCreateAccountRequest, options?: RawAxiosRequestConfig) {
        return AccountApiFp(this.configuration).createAccount(requestParameters.CreateAccountRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Delete an Account document.
     * @param {AccountApiDeleteAccountRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountApi
     */
    public deleteAccount(requestParameters: AccountApiDeleteAccountRequest, options?: RawAxiosRequestConfig) {
        return AccountApiFp(this.configuration).deleteAccount(requestParameters.account_id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get a single Account document by ID.
     * @param {AccountApiGetAccountRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountApi
     */
    public getAccount(requestParameters: AccountApiGetAccountRequest, options?: RawAxiosRequestConfig) {
        return AccountApiFp(this.configuration).getAccount(requestParameters.account_id, requestParameters.populate_user, requestParameters.populate_investments_count, requestParameters.populate_audit_log, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Paginate through Account documents.
     * @param {AccountApiListAccountsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountApi
     */
    public listAccounts(requestParameters: AccountApiListAccountsRequest = {}, options?: RawAxiosRequestConfig) {
        return AccountApiFp(this.configuration).listAccounts(requestParameters.skip, requestParameters.limit, requestParameters.search, requestParameters.sort_field, requestParameters.sort_direction, requestParameters.populate_user, requestParameters.populate_investments_count, requestParameters.users, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Update an Account document.
     * @param {AccountApiUpdateAccountRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountApi
     */
    public updateAccount(requestParameters: AccountApiUpdateAccountRequest, options?: RawAxiosRequestConfig) {
        return AccountApiFp(this.configuration).updateAccount(requestParameters.account_id, requestParameters.UpdateAccountRequest, options).then((request) => request(this.axios, this.basePath));
    }
}

/**
 * @export
 */
export const ListAccountsSortFieldEnum = {
    CreatedAt: 'created_at',
    Name: 'name',
    AccountType: 'account_type'
} as const;
export type ListAccountsSortFieldEnum = typeof ListAccountsSortFieldEnum[keyof typeof ListAccountsSortFieldEnum];
/**
 * @export
 */
export const ListAccountsSortDirectionEnum = {
    Asc: 'asc',
    Desc: 'desc'
} as const;
export type ListAccountsSortDirectionEnum = typeof ListAccountsSortDirectionEnum[keyof typeof ListAccountsSortDirectionEnum];


/**
 * AdminApi - axios parameter creator
 * @export
 */
export const AdminApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Get counts of various activities within a specified time range.
         * @param {string} start_date 
         * @param {string} end_date 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getActivityCounts: async (start_date: string, end_date: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'start_date' is not null or undefined
            assertParamExists('getActivityCounts', 'start_date', start_date)
            // verify required parameter 'end_date' is not null or undefined
            assertParamExists('getActivityCounts', 'end_date', end_date)
            const localVarPath = `/v2/admin/activity_counts`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication adminAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (start_date !== undefined) {
                localVarQueryParameter['start_date'] = (start_date as any instanceof Date) ?
                    (start_date as any).toISOString() :
                    start_date;
            }

            if (end_date !== undefined) {
                localVarQueryParameter['end_date'] = (end_date as any instanceof Date) ?
                    (end_date as any).toISOString() :
                    end_date;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AdminApi - functional programming interface
 * @export
 */
export const AdminApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AdminApiAxiosParamCreator(configuration)
    return {
        /**
         * Get counts of various activities within a specified time range.
         * @param {string} start_date 
         * @param {string} end_date 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getActivityCounts(start_date: string, end_date: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetActivityCounts200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getActivityCounts(start_date, end_date, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AdminApi.getActivityCounts']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * AdminApi - factory interface
 * @export
 */
export const AdminApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AdminApiFp(configuration)
    return {
        /**
         * Get counts of various activities within a specified time range.
         * @param {AdminApiGetActivityCountsRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getActivityCounts(requestParameters: AdminApiGetActivityCountsRequest, options?: RawAxiosRequestConfig): AxiosPromise<GetActivityCounts200Response> {
            return localVarFp.getActivityCounts(requestParameters.start_date, requestParameters.end_date, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for getActivityCounts operation in AdminApi.
 * @export
 * @interface AdminApiGetActivityCountsRequest
 */
export interface AdminApiGetActivityCountsRequest {
    /**
     * 
     * @type {string}
     * @memberof AdminApiGetActivityCounts
     */
    readonly start_date: string

    /**
     * 
     * @type {string}
     * @memberof AdminApiGetActivityCounts
     */
    readonly end_date: string
}

/**
 * AdminApi - object-oriented interface
 * @export
 * @class AdminApi
 * @extends {BaseAPI}
 */
export class AdminApi extends BaseAPI {
    /**
     * Get counts of various activities within a specified time range.
     * @param {AdminApiGetActivityCountsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public getActivityCounts(requestParameters: AdminApiGetActivityCountsRequest, options?: RawAxiosRequestConfig) {
        return AdminApiFp(this.configuration).getActivityCounts(requestParameters.start_date, requestParameters.end_date, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * AdminTeamApi - axios parameter creator
 * @export
 */
export const AdminTeamApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Create a new Admin Team.
         * @param {CreateAdminTeamRequest} CreateAdminTeamRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createAdminTeam: async (CreateAdminTeamRequest: CreateAdminTeamRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'CreateAdminTeamRequest' is not null or undefined
            assertParamExists('createAdminTeam', 'CreateAdminTeamRequest', CreateAdminTeamRequest)
            const localVarPath = `/v2/adminteams/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication adminAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(CreateAdminTeamRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Delete an Admin Team document.
         * @param {string} admin_team_id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteAdminTeam: async (admin_team_id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'admin_team_id' is not null or undefined
            assertParamExists('deleteAdminTeam', 'admin_team_id', admin_team_id)
            const localVarPath = `/v2/adminteams/{admin_team_id}`
                .replace(`{${"admin_team_id"}}`, encodeURIComponent(String(admin_team_id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication adminAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get a single Admin Team document by ID.
         * @param {string} admin_team_id 
         * @param {boolean} [populate_advisory_groups] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAdminTeam: async (admin_team_id: string, populate_advisory_groups?: boolean, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'admin_team_id' is not null or undefined
            assertParamExists('getAdminTeam', 'admin_team_id', admin_team_id)
            const localVarPath = `/v2/adminteams/{admin_team_id}`
                .replace(`{${"admin_team_id"}}`, encodeURIComponent(String(admin_team_id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication adminAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication advisorAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication investorAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (populate_advisory_groups !== undefined) {
                localVarQueryParameter['populate_advisory_groups'] = populate_advisory_groups;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Paginate through Admin Team documents.
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {ListAdminTeamsSortFieldEnum} [sort_field] 
         * @param {ListAdminTeamsSortDirectionEnum} [sort_direction] 
         * @param {string} [name] 
         * @param {ListAdminTeamsTypeEnum} [type] 
         * @param {Array<string>} [advisory_groups] 
         * @param {boolean} [populate_advisory_groups] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listAdminTeams: async (skip?: number, limit?: number, sort_field?: ListAdminTeamsSortFieldEnum, sort_direction?: ListAdminTeamsSortDirectionEnum, name?: string, type?: ListAdminTeamsTypeEnum, advisory_groups?: Array<string>, populate_advisory_groups?: boolean, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v2/adminteams/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication adminAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (skip !== undefined) {
                localVarQueryParameter['skip'] = skip;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (sort_field !== undefined) {
                localVarQueryParameter['sort_field'] = sort_field;
            }

            if (sort_direction !== undefined) {
                localVarQueryParameter['sort_direction'] = sort_direction;
            }

            if (name !== undefined) {
                localVarQueryParameter['name'] = name;
            }

            if (type !== undefined) {
                localVarQueryParameter['type'] = type;
            }

            if (advisory_groups) {
                localVarQueryParameter['advisory_groups'] = advisory_groups;
            }

            if (populate_advisory_groups !== undefined) {
                localVarQueryParameter['populate_advisory_groups'] = populate_advisory_groups;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update an existing Admin Team document.
         * @param {string} admin_team_id 
         * @param {UpdateAdminTeamRequest} [UpdateAdminTeamRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAdminTeam: async (admin_team_id: string, UpdateAdminTeamRequest?: UpdateAdminTeamRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'admin_team_id' is not null or undefined
            assertParamExists('updateAdminTeam', 'admin_team_id', admin_team_id)
            const localVarPath = `/v2/adminteams/{admin_team_id}`
                .replace(`{${"admin_team_id"}}`, encodeURIComponent(String(admin_team_id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication adminAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(UpdateAdminTeamRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AdminTeamApi - functional programming interface
 * @export
 */
export const AdminTeamApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AdminTeamApiAxiosParamCreator(configuration)
    return {
        /**
         * Create a new Admin Team.
         * @param {CreateAdminTeamRequest} CreateAdminTeamRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createAdminTeam(CreateAdminTeamRequest: CreateAdminTeamRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CreateAdminTeam201Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createAdminTeam(CreateAdminTeamRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AdminTeamApi.createAdminTeam']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Delete an Admin Team document.
         * @param {string} admin_team_id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteAdminTeam(admin_team_id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DeleteAccount200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteAdminTeam(admin_team_id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AdminTeamApi.deleteAdminTeam']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Get a single Admin Team document by ID.
         * @param {string} admin_team_id 
         * @param {boolean} [populate_advisory_groups] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAdminTeam(admin_team_id: string, populate_advisory_groups?: boolean, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetAdminTeam200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAdminTeam(admin_team_id, populate_advisory_groups, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AdminTeamApi.getAdminTeam']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Paginate through Admin Team documents.
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {ListAdminTeamsSortFieldEnum} [sort_field] 
         * @param {ListAdminTeamsSortDirectionEnum} [sort_direction] 
         * @param {string} [name] 
         * @param {ListAdminTeamsTypeEnum} [type] 
         * @param {Array<string>} [advisory_groups] 
         * @param {boolean} [populate_advisory_groups] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listAdminTeams(skip?: number, limit?: number, sort_field?: ListAdminTeamsSortFieldEnum, sort_direction?: ListAdminTeamsSortDirectionEnum, name?: string, type?: ListAdminTeamsTypeEnum, advisory_groups?: Array<string>, populate_advisory_groups?: boolean, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListAdminTeams200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listAdminTeams(skip, limit, sort_field, sort_direction, name, type, advisory_groups, populate_advisory_groups, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AdminTeamApi.listAdminTeams']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Update an existing Admin Team document.
         * @param {string} admin_team_id 
         * @param {UpdateAdminTeamRequest} [UpdateAdminTeamRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateAdminTeam(admin_team_id: string, UpdateAdminTeamRequest?: UpdateAdminTeamRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DeleteAccount200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateAdminTeam(admin_team_id, UpdateAdminTeamRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AdminTeamApi.updateAdminTeam']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * AdminTeamApi - factory interface
 * @export
 */
export const AdminTeamApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AdminTeamApiFp(configuration)
    return {
        /**
         * Create a new Admin Team.
         * @param {AdminTeamApiCreateAdminTeamRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createAdminTeam(requestParameters: AdminTeamApiCreateAdminTeamRequest, options?: RawAxiosRequestConfig): AxiosPromise<CreateAdminTeam201Response> {
            return localVarFp.createAdminTeam(requestParameters.CreateAdminTeamRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Delete an Admin Team document.
         * @param {AdminTeamApiDeleteAdminTeamRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteAdminTeam(requestParameters: AdminTeamApiDeleteAdminTeamRequest, options?: RawAxiosRequestConfig): AxiosPromise<DeleteAccount200Response> {
            return localVarFp.deleteAdminTeam(requestParameters.admin_team_id, options).then((request) => request(axios, basePath));
        },
        /**
         * Get a single Admin Team document by ID.
         * @param {AdminTeamApiGetAdminTeamRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAdminTeam(requestParameters: AdminTeamApiGetAdminTeamRequest, options?: RawAxiosRequestConfig): AxiosPromise<GetAdminTeam200Response> {
            return localVarFp.getAdminTeam(requestParameters.admin_team_id, requestParameters.populate_advisory_groups, options).then((request) => request(axios, basePath));
        },
        /**
         * Paginate through Admin Team documents.
         * @param {AdminTeamApiListAdminTeamsRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listAdminTeams(requestParameters: AdminTeamApiListAdminTeamsRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<ListAdminTeams200Response> {
            return localVarFp.listAdminTeams(requestParameters.skip, requestParameters.limit, requestParameters.sort_field, requestParameters.sort_direction, requestParameters.name, requestParameters.type, requestParameters.advisory_groups, requestParameters.populate_advisory_groups, options).then((request) => request(axios, basePath));
        },
        /**
         * Update an existing Admin Team document.
         * @param {AdminTeamApiUpdateAdminTeamRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAdminTeam(requestParameters: AdminTeamApiUpdateAdminTeamRequest, options?: RawAxiosRequestConfig): AxiosPromise<DeleteAccount200Response> {
            return localVarFp.updateAdminTeam(requestParameters.admin_team_id, requestParameters.UpdateAdminTeamRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for createAdminTeam operation in AdminTeamApi.
 * @export
 * @interface AdminTeamApiCreateAdminTeamRequest
 */
export interface AdminTeamApiCreateAdminTeamRequest {
    /**
     * 
     * @type {CreateAdminTeamRequest}
     * @memberof AdminTeamApiCreateAdminTeam
     */
    readonly CreateAdminTeamRequest: CreateAdminTeamRequest
}

/**
 * Request parameters for deleteAdminTeam operation in AdminTeamApi.
 * @export
 * @interface AdminTeamApiDeleteAdminTeamRequest
 */
export interface AdminTeamApiDeleteAdminTeamRequest {
    /**
     * 
     * @type {string}
     * @memberof AdminTeamApiDeleteAdminTeam
     */
    readonly admin_team_id: string
}

/**
 * Request parameters for getAdminTeam operation in AdminTeamApi.
 * @export
 * @interface AdminTeamApiGetAdminTeamRequest
 */
export interface AdminTeamApiGetAdminTeamRequest {
    /**
     * 
     * @type {string}
     * @memberof AdminTeamApiGetAdminTeam
     */
    readonly admin_team_id: string

    /**
     * 
     * @type {boolean}
     * @memberof AdminTeamApiGetAdminTeam
     */
    readonly populate_advisory_groups?: boolean
}

/**
 * Request parameters for listAdminTeams operation in AdminTeamApi.
 * @export
 * @interface AdminTeamApiListAdminTeamsRequest
 */
export interface AdminTeamApiListAdminTeamsRequest {
    /**
     * 
     * @type {number}
     * @memberof AdminTeamApiListAdminTeams
     */
    readonly skip?: number

    /**
     * 
     * @type {number}
     * @memberof AdminTeamApiListAdminTeams
     */
    readonly limit?: number

    /**
     * 
     * @type {'created_at' | 'updated_at' | 'name' | 'type'}
     * @memberof AdminTeamApiListAdminTeams
     */
    readonly sort_field?: ListAdminTeamsSortFieldEnum

    /**
     * 
     * @type {'asc' | 'desc'}
     * @memberof AdminTeamApiListAdminTeams
     */
    readonly sort_direction?: ListAdminTeamsSortDirectionEnum

    /**
     * 
     * @type {string}
     * @memberof AdminTeamApiListAdminTeams
     */
    readonly name?: string

    /**
     * 
     * @type {'account_management' | 'onboarding'}
     * @memberof AdminTeamApiListAdminTeams
     */
    readonly type?: ListAdminTeamsTypeEnum

    /**
     * 
     * @type {Array<string>}
     * @memberof AdminTeamApiListAdminTeams
     */
    readonly advisory_groups?: Array<string>

    /**
     * 
     * @type {boolean}
     * @memberof AdminTeamApiListAdminTeams
     */
    readonly populate_advisory_groups?: boolean
}

/**
 * Request parameters for updateAdminTeam operation in AdminTeamApi.
 * @export
 * @interface AdminTeamApiUpdateAdminTeamRequest
 */
export interface AdminTeamApiUpdateAdminTeamRequest {
    /**
     * 
     * @type {string}
     * @memberof AdminTeamApiUpdateAdminTeam
     */
    readonly admin_team_id: string

    /**
     * 
     * @type {UpdateAdminTeamRequest}
     * @memberof AdminTeamApiUpdateAdminTeam
     */
    readonly UpdateAdminTeamRequest?: UpdateAdminTeamRequest
}

/**
 * AdminTeamApi - object-oriented interface
 * @export
 * @class AdminTeamApi
 * @extends {BaseAPI}
 */
export class AdminTeamApi extends BaseAPI {
    /**
     * Create a new Admin Team.
     * @param {AdminTeamApiCreateAdminTeamRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminTeamApi
     */
    public createAdminTeam(requestParameters: AdminTeamApiCreateAdminTeamRequest, options?: RawAxiosRequestConfig) {
        return AdminTeamApiFp(this.configuration).createAdminTeam(requestParameters.CreateAdminTeamRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Delete an Admin Team document.
     * @param {AdminTeamApiDeleteAdminTeamRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminTeamApi
     */
    public deleteAdminTeam(requestParameters: AdminTeamApiDeleteAdminTeamRequest, options?: RawAxiosRequestConfig) {
        return AdminTeamApiFp(this.configuration).deleteAdminTeam(requestParameters.admin_team_id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get a single Admin Team document by ID.
     * @param {AdminTeamApiGetAdminTeamRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminTeamApi
     */
    public getAdminTeam(requestParameters: AdminTeamApiGetAdminTeamRequest, options?: RawAxiosRequestConfig) {
        return AdminTeamApiFp(this.configuration).getAdminTeam(requestParameters.admin_team_id, requestParameters.populate_advisory_groups, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Paginate through Admin Team documents.
     * @param {AdminTeamApiListAdminTeamsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminTeamApi
     */
    public listAdminTeams(requestParameters: AdminTeamApiListAdminTeamsRequest = {}, options?: RawAxiosRequestConfig) {
        return AdminTeamApiFp(this.configuration).listAdminTeams(requestParameters.skip, requestParameters.limit, requestParameters.sort_field, requestParameters.sort_direction, requestParameters.name, requestParameters.type, requestParameters.advisory_groups, requestParameters.populate_advisory_groups, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Update an existing Admin Team document.
     * @param {AdminTeamApiUpdateAdminTeamRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminTeamApi
     */
    public updateAdminTeam(requestParameters: AdminTeamApiUpdateAdminTeamRequest, options?: RawAxiosRequestConfig) {
        return AdminTeamApiFp(this.configuration).updateAdminTeam(requestParameters.admin_team_id, requestParameters.UpdateAdminTeamRequest, options).then((request) => request(this.axios, this.basePath));
    }
}

/**
 * @export
 */
export const ListAdminTeamsSortFieldEnum = {
    CreatedAt: 'created_at',
    UpdatedAt: 'updated_at',
    Name: 'name',
    Type: 'type'
} as const;
export type ListAdminTeamsSortFieldEnum = typeof ListAdminTeamsSortFieldEnum[keyof typeof ListAdminTeamsSortFieldEnum];
/**
 * @export
 */
export const ListAdminTeamsSortDirectionEnum = {
    Asc: 'asc',
    Desc: 'desc'
} as const;
export type ListAdminTeamsSortDirectionEnum = typeof ListAdminTeamsSortDirectionEnum[keyof typeof ListAdminTeamsSortDirectionEnum];
/**
 * @export
 */
export const ListAdminTeamsTypeEnum = {
    AccountManagement: 'account_management',
    Onboarding: 'onboarding'
} as const;
export type ListAdminTeamsTypeEnum = typeof ListAdminTeamsTypeEnum[keyof typeof ListAdminTeamsTypeEnum];


/**
 * AdvisoryGroupApi - axios parameter creator
 * @export
 */
export const AdvisoryGroupApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Update an Extegration Connection on an Advisory Group document.
         * @param {string} advisorygroup_id 
         * @param {string} extegrationconnection_id 
         * @param {UpdateExtegrationConnectionRequest} [UpdateExtegrationConnectionRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateExtegrationConnection: async (advisorygroup_id: string, extegrationconnection_id: string, UpdateExtegrationConnectionRequest?: UpdateExtegrationConnectionRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'advisorygroup_id' is not null or undefined
            assertParamExists('updateExtegrationConnection', 'advisorygroup_id', advisorygroup_id)
            // verify required parameter 'extegrationconnection_id' is not null or undefined
            assertParamExists('updateExtegrationConnection', 'extegrationconnection_id', extegrationconnection_id)
            const localVarPath = `/v2/advisorygroups/{advisorygroup_id}/extegrationconnections/{extegrationconnection_id}`
                .replace(`{${"advisorygroup_id"}}`, encodeURIComponent(String(advisorygroup_id)))
                .replace(`{${"extegrationconnection_id"}}`, encodeURIComponent(String(extegrationconnection_id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication adminAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication advisorAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(UpdateExtegrationConnectionRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AdvisoryGroupApi - functional programming interface
 * @export
 */
export const AdvisoryGroupApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AdvisoryGroupApiAxiosParamCreator(configuration)
    return {
        /**
         * Update an Extegration Connection on an Advisory Group document.
         * @param {string} advisorygroup_id 
         * @param {string} extegrationconnection_id 
         * @param {UpdateExtegrationConnectionRequest} [UpdateExtegrationConnectionRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateExtegrationConnection(advisorygroup_id: string, extegrationconnection_id: string, UpdateExtegrationConnectionRequest?: UpdateExtegrationConnectionRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DeleteAccount200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateExtegrationConnection(advisorygroup_id, extegrationconnection_id, UpdateExtegrationConnectionRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AdvisoryGroupApi.updateExtegrationConnection']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * AdvisoryGroupApi - factory interface
 * @export
 */
export const AdvisoryGroupApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AdvisoryGroupApiFp(configuration)
    return {
        /**
         * Update an Extegration Connection on an Advisory Group document.
         * @param {AdvisoryGroupApiUpdateExtegrationConnectionRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateExtegrationConnection(requestParameters: AdvisoryGroupApiUpdateExtegrationConnectionRequest, options?: RawAxiosRequestConfig): AxiosPromise<DeleteAccount200Response> {
            return localVarFp.updateExtegrationConnection(requestParameters.advisorygroup_id, requestParameters.extegrationconnection_id, requestParameters.UpdateExtegrationConnectionRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for updateExtegrationConnection operation in AdvisoryGroupApi.
 * @export
 * @interface AdvisoryGroupApiUpdateExtegrationConnectionRequest
 */
export interface AdvisoryGroupApiUpdateExtegrationConnectionRequest {
    /**
     * 
     * @type {string}
     * @memberof AdvisoryGroupApiUpdateExtegrationConnection
     */
    readonly advisorygroup_id: string

    /**
     * 
     * @type {string}
     * @memberof AdvisoryGroupApiUpdateExtegrationConnection
     */
    readonly extegrationconnection_id: string

    /**
     * 
     * @type {UpdateExtegrationConnectionRequest}
     * @memberof AdvisoryGroupApiUpdateExtegrationConnection
     */
    readonly UpdateExtegrationConnectionRequest?: UpdateExtegrationConnectionRequest
}

/**
 * AdvisoryGroupApi - object-oriented interface
 * @export
 * @class AdvisoryGroupApi
 * @extends {BaseAPI}
 */
export class AdvisoryGroupApi extends BaseAPI {
    /**
     * Update an Extegration Connection on an Advisory Group document.
     * @param {AdvisoryGroupApiUpdateExtegrationConnectionRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdvisoryGroupApi
     */
    public updateExtegrationConnection(requestParameters: AdvisoryGroupApiUpdateExtegrationConnectionRequest, options?: RawAxiosRequestConfig) {
        return AdvisoryGroupApiFp(this.configuration).updateExtegrationConnection(requestParameters.advisorygroup_id, requestParameters.extegrationconnection_id, requestParameters.UpdateExtegrationConnectionRequest, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * AssetManagerPortalApi - axios parameter creator
 * @export
 */
export const AssetManagerPortalApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Get counts of connections in each outreach status for the asset manager.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getStats: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v2/assetmanagers/portal/stats`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication assetManagerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get the list of advisory groups for an asset manager.
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {string} [asset_manager] 
         * @param {string} [connection] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v2AssetmanagersPortalAdvisorygroupsGet: async (skip?: number, limit?: number, asset_manager?: string, connection?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v2/assetmanagers/portal/advisorygroups`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication assetManagerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (skip !== undefined) {
                localVarQueryParameter['skip'] = skip;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (asset_manager !== undefined) {
                localVarQueryParameter['asset_manager'] = asset_manager;
            }

            if (connection !== undefined) {
                localVarQueryParameter['connection'] = connection;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Provide the ability for an asset manager to update their connection credentials and outreach status.
         * @param {string} id 
         * @param {V2AssetmanagersPortalConnectionsIdPatchRequest} [V2AssetmanagersPortalConnectionsIdPatchRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v2AssetmanagersPortalConnectionsIdPatch: async (id: string, V2AssetmanagersPortalConnectionsIdPatchRequest?: V2AssetmanagersPortalConnectionsIdPatchRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('v2AssetmanagersPortalConnectionsIdPatch', 'id', id)
            const localVarPath = `/v2/assetmanagers/portal/connections/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication assetManagerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(V2AssetmanagersPortalConnectionsIdPatchRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Fetch the assigned asset manager connections for the asset manager to work through.
         * @param {V2AssetmanagersPortalListGetSortFieldEnum} [sort_field] 
         * @param {string} [sort_direction] 
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {string} [search] 
         * @param {Array<string>} [outreach_statuses] 
         * @param {string} [advisory_group] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v2AssetmanagersPortalListGet: async (sort_field?: V2AssetmanagersPortalListGetSortFieldEnum, sort_direction?: string, skip?: number, limit?: number, search?: string, outreach_statuses?: Array<string>, advisory_group?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v2/assetmanagers/portal/list`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication assetManagerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (sort_field !== undefined) {
                localVarQueryParameter['sort_field'] = sort_field;
            }

            if (sort_direction !== undefined) {
                localVarQueryParameter['sort_direction'] = sort_direction;
            }

            if (skip !== undefined) {
                localVarQueryParameter['skip'] = skip;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (outreach_statuses) {
                localVarQueryParameter['outreach_statuses'] = outreach_statuses;
            }

            if (advisory_group !== undefined) {
                localVarQueryParameter['advisory_group'] = advisory_group;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Create an authentication token for an asset manager to fetch and update information with.
         * @param {V2AssetmanagersPortalLoginPostRequest} V2AssetmanagersPortalLoginPostRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v2AssetmanagersPortalLoginPost: async (V2AssetmanagersPortalLoginPostRequest: V2AssetmanagersPortalLoginPostRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'V2AssetmanagersPortalLoginPostRequest' is not null or undefined
            assertParamExists('v2AssetmanagersPortalLoginPost', 'V2AssetmanagersPortalLoginPostRequest', V2AssetmanagersPortalLoginPostRequest)
            const localVarPath = `/v2/assetmanagers/portal/login`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication assetManagerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(V2AssetmanagersPortalLoginPostRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AssetManagerPortalApi - functional programming interface
 * @export
 */
export const AssetManagerPortalApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AssetManagerPortalApiAxiosParamCreator(configuration)
    return {
        /**
         * Get counts of connections in each outreach status for the asset manager.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getStats(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetStats200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getStats(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AssetManagerPortalApi.getStats']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Get the list of advisory groups for an asset manager.
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {string} [asset_manager] 
         * @param {string} [connection] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v2AssetmanagersPortalAdvisorygroupsGet(skip?: number, limit?: number, asset_manager?: string, connection?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<V2AssetmanagersPortalAdvisorygroupsGet200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v2AssetmanagersPortalAdvisorygroupsGet(skip, limit, asset_manager, connection, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AssetManagerPortalApi.v2AssetmanagersPortalAdvisorygroupsGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Provide the ability for an asset manager to update their connection credentials and outreach status.
         * @param {string} id 
         * @param {V2AssetmanagersPortalConnectionsIdPatchRequest} [V2AssetmanagersPortalConnectionsIdPatchRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v2AssetmanagersPortalConnectionsIdPatch(id: string, V2AssetmanagersPortalConnectionsIdPatchRequest?: V2AssetmanagersPortalConnectionsIdPatchRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DeleteAccount200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v2AssetmanagersPortalConnectionsIdPatch(id, V2AssetmanagersPortalConnectionsIdPatchRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AssetManagerPortalApi.v2AssetmanagersPortalConnectionsIdPatch']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Fetch the assigned asset manager connections for the asset manager to work through.
         * @param {V2AssetmanagersPortalListGetSortFieldEnum} [sort_field] 
         * @param {string} [sort_direction] 
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {string} [search] 
         * @param {Array<string>} [outreach_statuses] 
         * @param {string} [advisory_group] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v2AssetmanagersPortalListGet(sort_field?: V2AssetmanagersPortalListGetSortFieldEnum, sort_direction?: string, skip?: number, limit?: number, search?: string, outreach_statuses?: Array<string>, advisory_group?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<V2AssetmanagersPortalListGet200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v2AssetmanagersPortalListGet(sort_field, sort_direction, skip, limit, search, outreach_statuses, advisory_group, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AssetManagerPortalApi.v2AssetmanagersPortalListGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Create an authentication token for an asset manager to fetch and update information with.
         * @param {V2AssetmanagersPortalLoginPostRequest} V2AssetmanagersPortalLoginPostRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v2AssetmanagersPortalLoginPost(V2AssetmanagersPortalLoginPostRequest: V2AssetmanagersPortalLoginPostRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<V2AssetmanagersPortalLoginPost200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v2AssetmanagersPortalLoginPost(V2AssetmanagersPortalLoginPostRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AssetManagerPortalApi.v2AssetmanagersPortalLoginPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * AssetManagerPortalApi - factory interface
 * @export
 */
export const AssetManagerPortalApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AssetManagerPortalApiFp(configuration)
    return {
        /**
         * Get counts of connections in each outreach status for the asset manager.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getStats(options?: RawAxiosRequestConfig): AxiosPromise<GetStats200Response> {
            return localVarFp.getStats(options).then((request) => request(axios, basePath));
        },
        /**
         * Get the list of advisory groups for an asset manager.
         * @param {AssetManagerPortalApiV2AssetmanagersPortalAdvisorygroupsGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v2AssetmanagersPortalAdvisorygroupsGet(requestParameters: AssetManagerPortalApiV2AssetmanagersPortalAdvisorygroupsGetRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<V2AssetmanagersPortalAdvisorygroupsGet200Response> {
            return localVarFp.v2AssetmanagersPortalAdvisorygroupsGet(requestParameters.skip, requestParameters.limit, requestParameters.asset_manager, requestParameters.connection, options).then((request) => request(axios, basePath));
        },
        /**
         * Provide the ability for an asset manager to update their connection credentials and outreach status.
         * @param {AssetManagerPortalApiV2AssetmanagersPortalConnectionsIdPatchRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v2AssetmanagersPortalConnectionsIdPatch(requestParameters: AssetManagerPortalApiV2AssetmanagersPortalConnectionsIdPatchRequest, options?: RawAxiosRequestConfig): AxiosPromise<DeleteAccount200Response> {
            return localVarFp.v2AssetmanagersPortalConnectionsIdPatch(requestParameters.id, requestParameters.V2AssetmanagersPortalConnectionsIdPatchRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Fetch the assigned asset manager connections for the asset manager to work through.
         * @param {AssetManagerPortalApiV2AssetmanagersPortalListGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v2AssetmanagersPortalListGet(requestParameters: AssetManagerPortalApiV2AssetmanagersPortalListGetRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<V2AssetmanagersPortalListGet200Response> {
            return localVarFp.v2AssetmanagersPortalListGet(requestParameters.sort_field, requestParameters.sort_direction, requestParameters.skip, requestParameters.limit, requestParameters.search, requestParameters.outreach_statuses, requestParameters.advisory_group, options).then((request) => request(axios, basePath));
        },
        /**
         * Create an authentication token for an asset manager to fetch and update information with.
         * @param {AssetManagerPortalApiV2AssetmanagersPortalLoginPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v2AssetmanagersPortalLoginPost(requestParameters: AssetManagerPortalApiV2AssetmanagersPortalLoginPostRequest, options?: RawAxiosRequestConfig): AxiosPromise<V2AssetmanagersPortalLoginPost200Response> {
            return localVarFp.v2AssetmanagersPortalLoginPost(requestParameters.V2AssetmanagersPortalLoginPostRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for v2AssetmanagersPortalAdvisorygroupsGet operation in AssetManagerPortalApi.
 * @export
 * @interface AssetManagerPortalApiV2AssetmanagersPortalAdvisorygroupsGetRequest
 */
export interface AssetManagerPortalApiV2AssetmanagersPortalAdvisorygroupsGetRequest {
    /**
     * 
     * @type {number}
     * @memberof AssetManagerPortalApiV2AssetmanagersPortalAdvisorygroupsGet
     */
    readonly skip?: number

    /**
     * 
     * @type {number}
     * @memberof AssetManagerPortalApiV2AssetmanagersPortalAdvisorygroupsGet
     */
    readonly limit?: number

    /**
     * 
     * @type {string}
     * @memberof AssetManagerPortalApiV2AssetmanagersPortalAdvisorygroupsGet
     */
    readonly asset_manager?: string

    /**
     * 
     * @type {string}
     * @memberof AssetManagerPortalApiV2AssetmanagersPortalAdvisorygroupsGet
     */
    readonly connection?: string
}

/**
 * Request parameters for v2AssetmanagersPortalConnectionsIdPatch operation in AssetManagerPortalApi.
 * @export
 * @interface AssetManagerPortalApiV2AssetmanagersPortalConnectionsIdPatchRequest
 */
export interface AssetManagerPortalApiV2AssetmanagersPortalConnectionsIdPatchRequest {
    /**
     * 
     * @type {string}
     * @memberof AssetManagerPortalApiV2AssetmanagersPortalConnectionsIdPatch
     */
    readonly id: string

    /**
     * 
     * @type {V2AssetmanagersPortalConnectionsIdPatchRequest}
     * @memberof AssetManagerPortalApiV2AssetmanagersPortalConnectionsIdPatch
     */
    readonly V2AssetmanagersPortalConnectionsIdPatchRequest?: V2AssetmanagersPortalConnectionsIdPatchRequest
}

/**
 * Request parameters for v2AssetmanagersPortalListGet operation in AssetManagerPortalApi.
 * @export
 * @interface AssetManagerPortalApiV2AssetmanagersPortalListGetRequest
 */
export interface AssetManagerPortalApiV2AssetmanagersPortalListGetRequest {
    /**
     * 
     * @type {'user_name' | 'name' | 'created_at' | 'updated_at' | 'outreach_status'}
     * @memberof AssetManagerPortalApiV2AssetmanagersPortalListGet
     */
    readonly sort_field?: V2AssetmanagersPortalListGetSortFieldEnum

    /**
     * 
     * @type {string}
     * @memberof AssetManagerPortalApiV2AssetmanagersPortalListGet
     */
    readonly sort_direction?: string

    /**
     * 
     * @type {number}
     * @memberof AssetManagerPortalApiV2AssetmanagersPortalListGet
     */
    readonly skip?: number

    /**
     * 
     * @type {number}
     * @memberof AssetManagerPortalApiV2AssetmanagersPortalListGet
     */
    readonly limit?: number

    /**
     * 
     * @type {string}
     * @memberof AssetManagerPortalApiV2AssetmanagersPortalListGet
     */
    readonly search?: string

    /**
     * 
     * @type {Array<string>}
     * @memberof AssetManagerPortalApiV2AssetmanagersPortalListGet
     */
    readonly outreach_statuses?: Array<string>

    /**
     * 
     * @type {string}
     * @memberof AssetManagerPortalApiV2AssetmanagersPortalListGet
     */
    readonly advisory_group?: string
}

/**
 * Request parameters for v2AssetmanagersPortalLoginPost operation in AssetManagerPortalApi.
 * @export
 * @interface AssetManagerPortalApiV2AssetmanagersPortalLoginPostRequest
 */
export interface AssetManagerPortalApiV2AssetmanagersPortalLoginPostRequest {
    /**
     * 
     * @type {V2AssetmanagersPortalLoginPostRequest}
     * @memberof AssetManagerPortalApiV2AssetmanagersPortalLoginPost
     */
    readonly V2AssetmanagersPortalLoginPostRequest: V2AssetmanagersPortalLoginPostRequest
}

/**
 * AssetManagerPortalApi - object-oriented interface
 * @export
 * @class AssetManagerPortalApi
 * @extends {BaseAPI}
 */
export class AssetManagerPortalApi extends BaseAPI {
    /**
     * Get counts of connections in each outreach status for the asset manager.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AssetManagerPortalApi
     */
    public getStats(options?: RawAxiosRequestConfig) {
        return AssetManagerPortalApiFp(this.configuration).getStats(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get the list of advisory groups for an asset manager.
     * @param {AssetManagerPortalApiV2AssetmanagersPortalAdvisorygroupsGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AssetManagerPortalApi
     */
    public v2AssetmanagersPortalAdvisorygroupsGet(requestParameters: AssetManagerPortalApiV2AssetmanagersPortalAdvisorygroupsGetRequest = {}, options?: RawAxiosRequestConfig) {
        return AssetManagerPortalApiFp(this.configuration).v2AssetmanagersPortalAdvisorygroupsGet(requestParameters.skip, requestParameters.limit, requestParameters.asset_manager, requestParameters.connection, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Provide the ability for an asset manager to update their connection credentials and outreach status.
     * @param {AssetManagerPortalApiV2AssetmanagersPortalConnectionsIdPatchRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AssetManagerPortalApi
     */
    public v2AssetmanagersPortalConnectionsIdPatch(requestParameters: AssetManagerPortalApiV2AssetmanagersPortalConnectionsIdPatchRequest, options?: RawAxiosRequestConfig) {
        return AssetManagerPortalApiFp(this.configuration).v2AssetmanagersPortalConnectionsIdPatch(requestParameters.id, requestParameters.V2AssetmanagersPortalConnectionsIdPatchRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Fetch the assigned asset manager connections for the asset manager to work through.
     * @param {AssetManagerPortalApiV2AssetmanagersPortalListGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AssetManagerPortalApi
     */
    public v2AssetmanagersPortalListGet(requestParameters: AssetManagerPortalApiV2AssetmanagersPortalListGetRequest = {}, options?: RawAxiosRequestConfig) {
        return AssetManagerPortalApiFp(this.configuration).v2AssetmanagersPortalListGet(requestParameters.sort_field, requestParameters.sort_direction, requestParameters.skip, requestParameters.limit, requestParameters.search, requestParameters.outreach_statuses, requestParameters.advisory_group, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Create an authentication token for an asset manager to fetch and update information with.
     * @param {AssetManagerPortalApiV2AssetmanagersPortalLoginPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AssetManagerPortalApi
     */
    public v2AssetmanagersPortalLoginPost(requestParameters: AssetManagerPortalApiV2AssetmanagersPortalLoginPostRequest, options?: RawAxiosRequestConfig) {
        return AssetManagerPortalApiFp(this.configuration).v2AssetmanagersPortalLoginPost(requestParameters.V2AssetmanagersPortalLoginPostRequest, options).then((request) => request(this.axios, this.basePath));
    }
}

/**
 * @export
 */
export const V2AssetmanagersPortalListGetSortFieldEnum = {
    UserName: 'user_name',
    Name: 'name',
    CreatedAt: 'created_at',
    UpdatedAt: 'updated_at',
    OutreachStatus: 'outreach_status'
} as const;
export type V2AssetmanagersPortalListGetSortFieldEnum = typeof V2AssetmanagersPortalListGetSortFieldEnum[keyof typeof V2AssetmanagersPortalListGetSortFieldEnum];


/**
 * CommitmentApi - axios parameter creator
 * @export
 */
export const CommitmentApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Create a new Commitment document.
         * @param {CreateCommitmentRequest} CreateCommitmentRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createCommitment: async (CreateCommitmentRequest: CreateCommitmentRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'CreateCommitmentRequest' is not null or undefined
            assertParamExists('createCommitment', 'CreateCommitmentRequest', CreateCommitmentRequest)
            const localVarPath = `/v2/commitments/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication adminAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication advisorAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication investorAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(CreateCommitmentRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Delete a Commitment document.
         * @param {string} commitment_id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteCommitment: async (commitment_id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'commitment_id' is not null or undefined
            assertParamExists('deleteCommitment', 'commitment_id', commitment_id)
            const localVarPath = `/v2/commitments/{commitment_id}`
                .replace(`{${"commitment_id"}}`, encodeURIComponent(String(commitment_id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication adminAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication advisorAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication investorAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get a single Commitment document by ID.
         * @param {string} commitment_id 
         * @param {boolean} [populate_user] 
         * @param {boolean} [populate_account] 
         * @param {boolean} [populate_investment] 
         * @param {boolean} [populate_documents] 
         * @param {boolean} [populate_connection] 
         * @param {boolean} [populate_audit_log] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCommitment: async (commitment_id: string, populate_user?: boolean, populate_account?: boolean, populate_investment?: boolean, populate_documents?: boolean, populate_connection?: boolean, populate_audit_log?: boolean, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'commitment_id' is not null or undefined
            assertParamExists('getCommitment', 'commitment_id', commitment_id)
            const localVarPath = `/v2/commitments/{commitment_id}`
                .replace(`{${"commitment_id"}}`, encodeURIComponent(String(commitment_id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication adminAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication advisorAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication investorAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (populate_user !== undefined) {
                localVarQueryParameter['populate_user'] = populate_user;
            }

            if (populate_account !== undefined) {
                localVarQueryParameter['populate_account'] = populate_account;
            }

            if (populate_investment !== undefined) {
                localVarQueryParameter['populate_investment'] = populate_investment;
            }

            if (populate_documents !== undefined) {
                localVarQueryParameter['populate_documents'] = populate_documents;
            }

            if (populate_connection !== undefined) {
                localVarQueryParameter['populate_connection'] = populate_connection;
            }

            if (populate_audit_log !== undefined) {
                localVarQueryParameter['populate_audit_log'] = populate_audit_log;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Paginate through Commitment documents.
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {string} [search] 
         * @param {ListCommitmentsSortFieldEnum} [sort_field] 
         * @param {ListCommitmentsSortDirectionEnum} [sort_direction] 
         * @param {Array<string>} [users] 
         * @param {Array<string>} [accounts] 
         * @param {Array<string>} [investments] 
         * @param {boolean} [populate_user] 
         * @param {boolean} [populate_account] 
         * @param {boolean} [populate_investment] 
         * @param {boolean} [populate_documents] 
         * @param {boolean} [populate_connection] 
         * @param {boolean} [populate_audit_log] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listCommitments: async (skip?: number, limit?: number, search?: string, sort_field?: ListCommitmentsSortFieldEnum, sort_direction?: ListCommitmentsSortDirectionEnum, users?: Array<string>, accounts?: Array<string>, investments?: Array<string>, populate_user?: boolean, populate_account?: boolean, populate_investment?: boolean, populate_documents?: boolean, populate_connection?: boolean, populate_audit_log?: boolean, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v2/commitments/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication adminAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication advisorAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication investorAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (skip !== undefined) {
                localVarQueryParameter['skip'] = skip;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (sort_field !== undefined) {
                localVarQueryParameter['sort_field'] = sort_field;
            }

            if (sort_direction !== undefined) {
                localVarQueryParameter['sort_direction'] = sort_direction;
            }

            if (users) {
                localVarQueryParameter['users'] = users;
            }

            if (accounts) {
                localVarQueryParameter['accounts'] = accounts;
            }

            if (investments) {
                localVarQueryParameter['investments'] = investments;
            }

            if (populate_user !== undefined) {
                localVarQueryParameter['populate_user'] = populate_user;
            }

            if (populate_account !== undefined) {
                localVarQueryParameter['populate_account'] = populate_account;
            }

            if (populate_investment !== undefined) {
                localVarQueryParameter['populate_investment'] = populate_investment;
            }

            if (populate_documents !== undefined) {
                localVarQueryParameter['populate_documents'] = populate_documents;
            }

            if (populate_connection !== undefined) {
                localVarQueryParameter['populate_connection'] = populate_connection;
            }

            if (populate_audit_log !== undefined) {
                localVarQueryParameter['populate_audit_log'] = populate_audit_log;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update an existing Commitment document.
         * @param {string} commitment_id 
         * @param {UpdateCommitmentRequest} [UpdateCommitmentRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateCommitment: async (commitment_id: string, UpdateCommitmentRequest?: UpdateCommitmentRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'commitment_id' is not null or undefined
            assertParamExists('updateCommitment', 'commitment_id', commitment_id)
            const localVarPath = `/v2/commitments/{commitment_id}`
                .replace(`{${"commitment_id"}}`, encodeURIComponent(String(commitment_id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication adminAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication advisorAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication investorAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(UpdateCommitmentRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CommitmentApi - functional programming interface
 * @export
 */
export const CommitmentApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CommitmentApiAxiosParamCreator(configuration)
    return {
        /**
         * Create a new Commitment document.
         * @param {CreateCommitmentRequest} CreateCommitmentRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createCommitment(CreateCommitmentRequest: CreateCommitmentRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CreateCommitment201Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createCommitment(CreateCommitmentRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CommitmentApi.createCommitment']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Delete a Commitment document.
         * @param {string} commitment_id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteCommitment(commitment_id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DeleteAccount200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteCommitment(commitment_id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CommitmentApi.deleteCommitment']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Get a single Commitment document by ID.
         * @param {string} commitment_id 
         * @param {boolean} [populate_user] 
         * @param {boolean} [populate_account] 
         * @param {boolean} [populate_investment] 
         * @param {boolean} [populate_documents] 
         * @param {boolean} [populate_connection] 
         * @param {boolean} [populate_audit_log] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCommitment(commitment_id: string, populate_user?: boolean, populate_account?: boolean, populate_investment?: boolean, populate_documents?: boolean, populate_connection?: boolean, populate_audit_log?: boolean, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetCommitment200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCommitment(commitment_id, populate_user, populate_account, populate_investment, populate_documents, populate_connection, populate_audit_log, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CommitmentApi.getCommitment']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Paginate through Commitment documents.
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {string} [search] 
         * @param {ListCommitmentsSortFieldEnum} [sort_field] 
         * @param {ListCommitmentsSortDirectionEnum} [sort_direction] 
         * @param {Array<string>} [users] 
         * @param {Array<string>} [accounts] 
         * @param {Array<string>} [investments] 
         * @param {boolean} [populate_user] 
         * @param {boolean} [populate_account] 
         * @param {boolean} [populate_investment] 
         * @param {boolean} [populate_documents] 
         * @param {boolean} [populate_connection] 
         * @param {boolean} [populate_audit_log] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listCommitments(skip?: number, limit?: number, search?: string, sort_field?: ListCommitmentsSortFieldEnum, sort_direction?: ListCommitmentsSortDirectionEnum, users?: Array<string>, accounts?: Array<string>, investments?: Array<string>, populate_user?: boolean, populate_account?: boolean, populate_investment?: boolean, populate_documents?: boolean, populate_connection?: boolean, populate_audit_log?: boolean, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListCommitments200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listCommitments(skip, limit, search, sort_field, sort_direction, users, accounts, investments, populate_user, populate_account, populate_investment, populate_documents, populate_connection, populate_audit_log, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CommitmentApi.listCommitments']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Update an existing Commitment document.
         * @param {string} commitment_id 
         * @param {UpdateCommitmentRequest} [UpdateCommitmentRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateCommitment(commitment_id: string, UpdateCommitmentRequest?: UpdateCommitmentRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DeleteAccount200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateCommitment(commitment_id, UpdateCommitmentRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CommitmentApi.updateCommitment']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * CommitmentApi - factory interface
 * @export
 */
export const CommitmentApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CommitmentApiFp(configuration)
    return {
        /**
         * Create a new Commitment document.
         * @param {CommitmentApiCreateCommitmentRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createCommitment(requestParameters: CommitmentApiCreateCommitmentRequest, options?: RawAxiosRequestConfig): AxiosPromise<CreateCommitment201Response> {
            return localVarFp.createCommitment(requestParameters.CreateCommitmentRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Delete a Commitment document.
         * @param {CommitmentApiDeleteCommitmentRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteCommitment(requestParameters: CommitmentApiDeleteCommitmentRequest, options?: RawAxiosRequestConfig): AxiosPromise<DeleteAccount200Response> {
            return localVarFp.deleteCommitment(requestParameters.commitment_id, options).then((request) => request(axios, basePath));
        },
        /**
         * Get a single Commitment document by ID.
         * @param {CommitmentApiGetCommitmentRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCommitment(requestParameters: CommitmentApiGetCommitmentRequest, options?: RawAxiosRequestConfig): AxiosPromise<GetCommitment200Response> {
            return localVarFp.getCommitment(requestParameters.commitment_id, requestParameters.populate_user, requestParameters.populate_account, requestParameters.populate_investment, requestParameters.populate_documents, requestParameters.populate_connection, requestParameters.populate_audit_log, options).then((request) => request(axios, basePath));
        },
        /**
         * Paginate through Commitment documents.
         * @param {CommitmentApiListCommitmentsRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listCommitments(requestParameters: CommitmentApiListCommitmentsRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<ListCommitments200Response> {
            return localVarFp.listCommitments(requestParameters.skip, requestParameters.limit, requestParameters.search, requestParameters.sort_field, requestParameters.sort_direction, requestParameters.users, requestParameters.accounts, requestParameters.investments, requestParameters.populate_user, requestParameters.populate_account, requestParameters.populate_investment, requestParameters.populate_documents, requestParameters.populate_connection, requestParameters.populate_audit_log, options).then((request) => request(axios, basePath));
        },
        /**
         * Update an existing Commitment document.
         * @param {CommitmentApiUpdateCommitmentRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateCommitment(requestParameters: CommitmentApiUpdateCommitmentRequest, options?: RawAxiosRequestConfig): AxiosPromise<DeleteAccount200Response> {
            return localVarFp.updateCommitment(requestParameters.commitment_id, requestParameters.UpdateCommitmentRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for createCommitment operation in CommitmentApi.
 * @export
 * @interface CommitmentApiCreateCommitmentRequest
 */
export interface CommitmentApiCreateCommitmentRequest {
    /**
     * 
     * @type {CreateCommitmentRequest}
     * @memberof CommitmentApiCreateCommitment
     */
    readonly CreateCommitmentRequest: CreateCommitmentRequest
}

/**
 * Request parameters for deleteCommitment operation in CommitmentApi.
 * @export
 * @interface CommitmentApiDeleteCommitmentRequest
 */
export interface CommitmentApiDeleteCommitmentRequest {
    /**
     * 
     * @type {string}
     * @memberof CommitmentApiDeleteCommitment
     */
    readonly commitment_id: string
}

/**
 * Request parameters for getCommitment operation in CommitmentApi.
 * @export
 * @interface CommitmentApiGetCommitmentRequest
 */
export interface CommitmentApiGetCommitmentRequest {
    /**
     * 
     * @type {string}
     * @memberof CommitmentApiGetCommitment
     */
    readonly commitment_id: string

    /**
     * 
     * @type {boolean}
     * @memberof CommitmentApiGetCommitment
     */
    readonly populate_user?: boolean

    /**
     * 
     * @type {boolean}
     * @memberof CommitmentApiGetCommitment
     */
    readonly populate_account?: boolean

    /**
     * 
     * @type {boolean}
     * @memberof CommitmentApiGetCommitment
     */
    readonly populate_investment?: boolean

    /**
     * 
     * @type {boolean}
     * @memberof CommitmentApiGetCommitment
     */
    readonly populate_documents?: boolean

    /**
     * 
     * @type {boolean}
     * @memberof CommitmentApiGetCommitment
     */
    readonly populate_connection?: boolean

    /**
     * 
     * @type {boolean}
     * @memberof CommitmentApiGetCommitment
     */
    readonly populate_audit_log?: boolean
}

/**
 * Request parameters for listCommitments operation in CommitmentApi.
 * @export
 * @interface CommitmentApiListCommitmentsRequest
 */
export interface CommitmentApiListCommitmentsRequest {
    /**
     * 
     * @type {number}
     * @memberof CommitmentApiListCommitments
     */
    readonly skip?: number

    /**
     * 
     * @type {number}
     * @memberof CommitmentApiListCommitments
     */
    readonly limit?: number

    /**
     * 
     * @type {string}
     * @memberof CommitmentApiListCommitments
     */
    readonly search?: string

    /**
     * 
     * @type {'date' | 'amount'}
     * @memberof CommitmentApiListCommitments
     */
    readonly sort_field?: ListCommitmentsSortFieldEnum

    /**
     * 
     * @type {'asc' | 'desc'}
     * @memberof CommitmentApiListCommitments
     */
    readonly sort_direction?: ListCommitmentsSortDirectionEnum

    /**
     * 
     * @type {Array<string>}
     * @memberof CommitmentApiListCommitments
     */
    readonly users?: Array<string>

    /**
     * 
     * @type {Array<string>}
     * @memberof CommitmentApiListCommitments
     */
    readonly accounts?: Array<string>

    /**
     * 
     * @type {Array<string>}
     * @memberof CommitmentApiListCommitments
     */
    readonly investments?: Array<string>

    /**
     * 
     * @type {boolean}
     * @memberof CommitmentApiListCommitments
     */
    readonly populate_user?: boolean

    /**
     * 
     * @type {boolean}
     * @memberof CommitmentApiListCommitments
     */
    readonly populate_account?: boolean

    /**
     * 
     * @type {boolean}
     * @memberof CommitmentApiListCommitments
     */
    readonly populate_investment?: boolean

    /**
     * 
     * @type {boolean}
     * @memberof CommitmentApiListCommitments
     */
    readonly populate_documents?: boolean

    /**
     * 
     * @type {boolean}
     * @memberof CommitmentApiListCommitments
     */
    readonly populate_connection?: boolean

    /**
     * 
     * @type {boolean}
     * @memberof CommitmentApiListCommitments
     */
    readonly populate_audit_log?: boolean
}

/**
 * Request parameters for updateCommitment operation in CommitmentApi.
 * @export
 * @interface CommitmentApiUpdateCommitmentRequest
 */
export interface CommitmentApiUpdateCommitmentRequest {
    /**
     * 
     * @type {string}
     * @memberof CommitmentApiUpdateCommitment
     */
    readonly commitment_id: string

    /**
     * 
     * @type {UpdateCommitmentRequest}
     * @memberof CommitmentApiUpdateCommitment
     */
    readonly UpdateCommitmentRequest?: UpdateCommitmentRequest
}

/**
 * CommitmentApi - object-oriented interface
 * @export
 * @class CommitmentApi
 * @extends {BaseAPI}
 */
export class CommitmentApi extends BaseAPI {
    /**
     * Create a new Commitment document.
     * @param {CommitmentApiCreateCommitmentRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CommitmentApi
     */
    public createCommitment(requestParameters: CommitmentApiCreateCommitmentRequest, options?: RawAxiosRequestConfig) {
        return CommitmentApiFp(this.configuration).createCommitment(requestParameters.CreateCommitmentRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Delete a Commitment document.
     * @param {CommitmentApiDeleteCommitmentRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CommitmentApi
     */
    public deleteCommitment(requestParameters: CommitmentApiDeleteCommitmentRequest, options?: RawAxiosRequestConfig) {
        return CommitmentApiFp(this.configuration).deleteCommitment(requestParameters.commitment_id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get a single Commitment document by ID.
     * @param {CommitmentApiGetCommitmentRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CommitmentApi
     */
    public getCommitment(requestParameters: CommitmentApiGetCommitmentRequest, options?: RawAxiosRequestConfig) {
        return CommitmentApiFp(this.configuration).getCommitment(requestParameters.commitment_id, requestParameters.populate_user, requestParameters.populate_account, requestParameters.populate_investment, requestParameters.populate_documents, requestParameters.populate_connection, requestParameters.populate_audit_log, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Paginate through Commitment documents.
     * @param {CommitmentApiListCommitmentsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CommitmentApi
     */
    public listCommitments(requestParameters: CommitmentApiListCommitmentsRequest = {}, options?: RawAxiosRequestConfig) {
        return CommitmentApiFp(this.configuration).listCommitments(requestParameters.skip, requestParameters.limit, requestParameters.search, requestParameters.sort_field, requestParameters.sort_direction, requestParameters.users, requestParameters.accounts, requestParameters.investments, requestParameters.populate_user, requestParameters.populate_account, requestParameters.populate_investment, requestParameters.populate_documents, requestParameters.populate_connection, requestParameters.populate_audit_log, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Update an existing Commitment document.
     * @param {CommitmentApiUpdateCommitmentRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CommitmentApi
     */
    public updateCommitment(requestParameters: CommitmentApiUpdateCommitmentRequest, options?: RawAxiosRequestConfig) {
        return CommitmentApiFp(this.configuration).updateCommitment(requestParameters.commitment_id, requestParameters.UpdateCommitmentRequest, options).then((request) => request(this.axios, this.basePath));
    }
}

/**
 * @export
 */
export const ListCommitmentsSortFieldEnum = {
    Date: 'date',
    Amount: 'amount'
} as const;
export type ListCommitmentsSortFieldEnum = typeof ListCommitmentsSortFieldEnum[keyof typeof ListCommitmentsSortFieldEnum];
/**
 * @export
 */
export const ListCommitmentsSortDirectionEnum = {
    Asc: 'asc',
    Desc: 'desc'
} as const;
export type ListCommitmentsSortDirectionEnum = typeof ListCommitmentsSortDirectionEnum[keyof typeof ListCommitmentsSortDirectionEnum];


/**
 * ConnectionApi - axios parameter creator
 * @export
 */
export const ConnectionApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Finish the OAuth process for an API.
         * @param {string} connection_id 
         * @param {string} integration_slug 
         * @param {FinishOAuthRequest} FinishOAuthRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        finishOAuth: async (connection_id: string, integration_slug: string, FinishOAuthRequest: FinishOAuthRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'connection_id' is not null or undefined
            assertParamExists('finishOAuth', 'connection_id', connection_id)
            // verify required parameter 'integration_slug' is not null or undefined
            assertParamExists('finishOAuth', 'integration_slug', integration_slug)
            // verify required parameter 'FinishOAuthRequest' is not null or undefined
            assertParamExists('finishOAuth', 'FinishOAuthRequest', FinishOAuthRequest)
            const localVarPath = `/v2/connections/{connection_id}/oauth/{integration_slug}`
                .replace(`{${"connection_id"}}`, encodeURIComponent(String(connection_id)))
                .replace(`{${"integration_slug"}}`, encodeURIComponent(String(integration_slug)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication adminAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(FinishOAuthRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Search and list all Connections.
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {string} [search] 
         * @param {ListConnectionsSortFieldEnum} [sort_field] 
         * @param {ListConnectionsSortDirectionEnum} [sort_direction] 
         * @param {boolean} [populate_most_recent_sync] 
         * @param {boolean} [populate_user] 
         * @param {boolean} [populate_account] 
         * @param {boolean} [populate_account_list] 
         * @param {boolean} [populate_asset_manager] 
         * @param {boolean} [populate_contacts] 
         * @param {boolean} [populate_audit_log] 
         * @param {boolean} [populate_integration] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listConnections: async (skip?: number, limit?: number, search?: string, sort_field?: ListConnectionsSortFieldEnum, sort_direction?: ListConnectionsSortDirectionEnum, populate_most_recent_sync?: boolean, populate_user?: boolean, populate_account?: boolean, populate_account_list?: boolean, populate_asset_manager?: boolean, populate_contacts?: boolean, populate_audit_log?: boolean, populate_integration?: boolean, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v2/connections/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication adminAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication advisorAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication investorAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (skip !== undefined) {
                localVarQueryParameter['skip'] = skip;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (sort_field !== undefined) {
                localVarQueryParameter['sort_field'] = sort_field;
            }

            if (sort_direction !== undefined) {
                localVarQueryParameter['sort_direction'] = sort_direction;
            }

            if (populate_most_recent_sync !== undefined) {
                localVarQueryParameter['populate_most_recent_sync'] = populate_most_recent_sync;
            }

            if (populate_user !== undefined) {
                localVarQueryParameter['populate_user'] = populate_user;
            }

            if (populate_account !== undefined) {
                localVarQueryParameter['populate_account'] = populate_account;
            }

            if (populate_account_list !== undefined) {
                localVarQueryParameter['populate_account_list'] = populate_account_list;
            }

            if (populate_asset_manager !== undefined) {
                localVarQueryParameter['populate_asset_manager'] = populate_asset_manager;
            }

            if (populate_contacts !== undefined) {
                localVarQueryParameter['populate_contacts'] = populate_contacts;
            }

            if (populate_audit_log !== undefined) {
                localVarQueryParameter['populate_audit_log'] = populate_audit_log;
            }

            if (populate_integration !== undefined) {
                localVarQueryParameter['populate_integration'] = populate_integration;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ConnectionApi - functional programming interface
 * @export
 */
export const ConnectionApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ConnectionApiAxiosParamCreator(configuration)
    return {
        /**
         * Finish the OAuth process for an API.
         * @param {string} connection_id 
         * @param {string} integration_slug 
         * @param {FinishOAuthRequest} FinishOAuthRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async finishOAuth(connection_id: string, integration_slug: string, FinishOAuthRequest: FinishOAuthRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DeleteAccount200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.finishOAuth(connection_id, integration_slug, FinishOAuthRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ConnectionApi.finishOAuth']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Search and list all Connections.
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {string} [search] 
         * @param {ListConnectionsSortFieldEnum} [sort_field] 
         * @param {ListConnectionsSortDirectionEnum} [sort_direction] 
         * @param {boolean} [populate_most_recent_sync] 
         * @param {boolean} [populate_user] 
         * @param {boolean} [populate_account] 
         * @param {boolean} [populate_account_list] 
         * @param {boolean} [populate_asset_manager] 
         * @param {boolean} [populate_contacts] 
         * @param {boolean} [populate_audit_log] 
         * @param {boolean} [populate_integration] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listConnections(skip?: number, limit?: number, search?: string, sort_field?: ListConnectionsSortFieldEnum, sort_direction?: ListConnectionsSortDirectionEnum, populate_most_recent_sync?: boolean, populate_user?: boolean, populate_account?: boolean, populate_account_list?: boolean, populate_asset_manager?: boolean, populate_contacts?: boolean, populate_audit_log?: boolean, populate_integration?: boolean, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListConnections200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listConnections(skip, limit, search, sort_field, sort_direction, populate_most_recent_sync, populate_user, populate_account, populate_account_list, populate_asset_manager, populate_contacts, populate_audit_log, populate_integration, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ConnectionApi.listConnections']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * ConnectionApi - factory interface
 * @export
 */
export const ConnectionApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ConnectionApiFp(configuration)
    return {
        /**
         * Finish the OAuth process for an API.
         * @param {ConnectionApiFinishOAuthRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        finishOAuth(requestParameters: ConnectionApiFinishOAuthRequest, options?: RawAxiosRequestConfig): AxiosPromise<DeleteAccount200Response> {
            return localVarFp.finishOAuth(requestParameters.connection_id, requestParameters.integration_slug, requestParameters.FinishOAuthRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Search and list all Connections.
         * @param {ConnectionApiListConnectionsRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listConnections(requestParameters: ConnectionApiListConnectionsRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<ListConnections200Response> {
            return localVarFp.listConnections(requestParameters.skip, requestParameters.limit, requestParameters.search, requestParameters.sort_field, requestParameters.sort_direction, requestParameters.populate_most_recent_sync, requestParameters.populate_user, requestParameters.populate_account, requestParameters.populate_account_list, requestParameters.populate_asset_manager, requestParameters.populate_contacts, requestParameters.populate_audit_log, requestParameters.populate_integration, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for finishOAuth operation in ConnectionApi.
 * @export
 * @interface ConnectionApiFinishOAuthRequest
 */
export interface ConnectionApiFinishOAuthRequest {
    /**
     * 
     * @type {string}
     * @memberof ConnectionApiFinishOAuth
     */
    readonly connection_id: string

    /**
     * 
     * @type {string}
     * @memberof ConnectionApiFinishOAuth
     */
    readonly integration_slug: string

    /**
     * 
     * @type {FinishOAuthRequest}
     * @memberof ConnectionApiFinishOAuth
     */
    readonly FinishOAuthRequest: FinishOAuthRequest
}

/**
 * Request parameters for listConnections operation in ConnectionApi.
 * @export
 * @interface ConnectionApiListConnectionsRequest
 */
export interface ConnectionApiListConnectionsRequest {
    /**
     * 
     * @type {number}
     * @memberof ConnectionApiListConnections
     */
    readonly skip?: number

    /**
     * 
     * @type {number}
     * @memberof ConnectionApiListConnections
     */
    readonly limit?: number

    /**
     * 
     * @type {string}
     * @memberof ConnectionApiListConnections
     */
    readonly search?: string

    /**
     * 
     * @type {'created_at'}
     * @memberof ConnectionApiListConnections
     */
    readonly sort_field?: ListConnectionsSortFieldEnum

    /**
     * 
     * @type {'asc' | 'desc'}
     * @memberof ConnectionApiListConnections
     */
    readonly sort_direction?: ListConnectionsSortDirectionEnum

    /**
     * 
     * @type {boolean}
     * @memberof ConnectionApiListConnections
     */
    readonly populate_most_recent_sync?: boolean

    /**
     * 
     * @type {boolean}
     * @memberof ConnectionApiListConnections
     */
    readonly populate_user?: boolean

    /**
     * 
     * @type {boolean}
     * @memberof ConnectionApiListConnections
     */
    readonly populate_account?: boolean

    /**
     * 
     * @type {boolean}
     * @memberof ConnectionApiListConnections
     */
    readonly populate_account_list?: boolean

    /**
     * 
     * @type {boolean}
     * @memberof ConnectionApiListConnections
     */
    readonly populate_asset_manager?: boolean

    /**
     * 
     * @type {boolean}
     * @memberof ConnectionApiListConnections
     */
    readonly populate_contacts?: boolean

    /**
     * 
     * @type {boolean}
     * @memberof ConnectionApiListConnections
     */
    readonly populate_audit_log?: boolean

    /**
     * 
     * @type {boolean}
     * @memberof ConnectionApiListConnections
     */
    readonly populate_integration?: boolean
}

/**
 * ConnectionApi - object-oriented interface
 * @export
 * @class ConnectionApi
 * @extends {BaseAPI}
 */
export class ConnectionApi extends BaseAPI {
    /**
     * Finish the OAuth process for an API.
     * @param {ConnectionApiFinishOAuthRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConnectionApi
     */
    public finishOAuth(requestParameters: ConnectionApiFinishOAuthRequest, options?: RawAxiosRequestConfig) {
        return ConnectionApiFp(this.configuration).finishOAuth(requestParameters.connection_id, requestParameters.integration_slug, requestParameters.FinishOAuthRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Search and list all Connections.
     * @param {ConnectionApiListConnectionsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConnectionApi
     */
    public listConnections(requestParameters: ConnectionApiListConnectionsRequest = {}, options?: RawAxiosRequestConfig) {
        return ConnectionApiFp(this.configuration).listConnections(requestParameters.skip, requestParameters.limit, requestParameters.search, requestParameters.sort_field, requestParameters.sort_direction, requestParameters.populate_most_recent_sync, requestParameters.populate_user, requestParameters.populate_account, requestParameters.populate_account_list, requestParameters.populate_asset_manager, requestParameters.populate_contacts, requestParameters.populate_audit_log, requestParameters.populate_integration, options).then((request) => request(this.axios, this.basePath));
    }
}

/**
 * @export
 */
export const ListConnectionsSortFieldEnum = {
    CreatedAt: 'created_at'
} as const;
export type ListConnectionsSortFieldEnum = typeof ListConnectionsSortFieldEnum[keyof typeof ListConnectionsSortFieldEnum];
/**
 * @export
 */
export const ListConnectionsSortDirectionEnum = {
    Asc: 'asc',
    Desc: 'desc'
} as const;
export type ListConnectionsSortDirectionEnum = typeof ListConnectionsSortDirectionEnum[keyof typeof ListConnectionsSortDirectionEnum];


/**
 * ConnectionDataApi - axios parameter creator
 * @export
 */
export const ConnectionDataApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Attempt import of a ConnectionAccount document with optional user override.
         * @param {string} connection_id 
         * @param {string} account_id 
         * @param {ImportConnectionAccountRequest} [ImportConnectionAccountRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        importConnectionAccount: async (connection_id: string, account_id: string, ImportConnectionAccountRequest?: ImportConnectionAccountRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'connection_id' is not null or undefined
            assertParamExists('importConnectionAccount', 'connection_id', connection_id)
            // verify required parameter 'account_id' is not null or undefined
            assertParamExists('importConnectionAccount', 'account_id', account_id)
            const localVarPath = `/v2/connections/{connection_id}/data/accounts/{account_id}/import`
                .replace(`{${"connection_id"}}`, encodeURIComponent(String(connection_id)))
                .replace(`{${"account_id"}}`, encodeURIComponent(String(account_id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication adminAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(ImportConnectionAccountRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Attempt import of a ConnectionCommitment document with optional user override.
         * @param {string} connection_id 
         * @param {string} commitment_id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        importConnectionCommitment: async (connection_id: string, commitment_id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'connection_id' is not null or undefined
            assertParamExists('importConnectionCommitment', 'connection_id', connection_id)
            // verify required parameter 'commitment_id' is not null or undefined
            assertParamExists('importConnectionCommitment', 'commitment_id', commitment_id)
            const localVarPath = `/v2/connections/{connection_id}/data/commitments/{commitment_id}/import`
                .replace(`{${"connection_id"}}`, encodeURIComponent(String(connection_id)))
                .replace(`{${"commitment_id"}}`, encodeURIComponent(String(commitment_id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication adminAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Attempt import of a ConnectionDocument document.
         * @param {string} connection_id 
         * @param {string} document_id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        importConnectionDocument: async (connection_id: string, document_id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'connection_id' is not null or undefined
            assertParamExists('importConnectionDocument', 'connection_id', connection_id)
            // verify required parameter 'document_id' is not null or undefined
            assertParamExists('importConnectionDocument', 'document_id', document_id)
            const localVarPath = `/v2/connections/{connection_id}/data/documents/{document_id}/import`
                .replace(`{${"connection_id"}}`, encodeURIComponent(String(connection_id)))
                .replace(`{${"document_id"}}`, encodeURIComponent(String(document_id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication adminAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Attempt import of a ConnectionInvestment document with optional account override.
         * @param {string} connection_id 
         * @param {string} investment_id 
         * @param {ImportConnectionInvestmentRequest} [ImportConnectionInvestmentRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        importConnectionInvestment: async (connection_id: string, investment_id: string, ImportConnectionInvestmentRequest?: ImportConnectionInvestmentRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'connection_id' is not null or undefined
            assertParamExists('importConnectionInvestment', 'connection_id', connection_id)
            // verify required parameter 'investment_id' is not null or undefined
            assertParamExists('importConnectionInvestment', 'investment_id', investment_id)
            const localVarPath = `/v2/connections/{connection_id}/data/investments/{investment_id}/import`
                .replace(`{${"connection_id"}}`, encodeURIComponent(String(connection_id)))
                .replace(`{${"investment_id"}}`, encodeURIComponent(String(investment_id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication adminAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(ImportConnectionInvestmentRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Attempt import of a ConnectionTransaction document.
         * @param {string} connection_id 
         * @param {string} transaction_id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        importConnectionTransaction: async (connection_id: string, transaction_id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'connection_id' is not null or undefined
            assertParamExists('importConnectionTransaction', 'connection_id', connection_id)
            // verify required parameter 'transaction_id' is not null or undefined
            assertParamExists('importConnectionTransaction', 'transaction_id', transaction_id)
            const localVarPath = `/v2/connections/{connection_id}/data/transactions/{transaction_id}/import`
                .replace(`{${"connection_id"}}`, encodeURIComponent(String(connection_id)))
                .replace(`{${"transaction_id"}}`, encodeURIComponent(String(transaction_id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication adminAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Attempt import of a ConnectionUser document.
         * @param {string} connection_id 
         * @param {string} user_id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        importConnectionUser: async (connection_id: string, user_id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'connection_id' is not null or undefined
            assertParamExists('importConnectionUser', 'connection_id', connection_id)
            // verify required parameter 'user_id' is not null or undefined
            assertParamExists('importConnectionUser', 'user_id', user_id)
            const localVarPath = `/v2/connections/{connection_id}/data/users/{user_id}/import`
                .replace(`{${"connection_id"}}`, encodeURIComponent(String(connection_id)))
                .replace(`{${"user_id"}}`, encodeURIComponent(String(user_id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication adminAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Attempt import of a ConnectionValuation document.
         * @param {string} connection_id 
         * @param {string} valuation_id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        importConnectionValuation: async (connection_id: string, valuation_id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'connection_id' is not null or undefined
            assertParamExists('importConnectionValuation', 'connection_id', connection_id)
            // verify required parameter 'valuation_id' is not null or undefined
            assertParamExists('importConnectionValuation', 'valuation_id', valuation_id)
            const localVarPath = `/v2/connections/{connection_id}/data/valuations/{valuation_id}/import`
                .replace(`{${"connection_id"}}`, encodeURIComponent(String(connection_id)))
                .replace(`{${"valuation_id"}}`, encodeURIComponent(String(valuation_id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication adminAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * List and paginate through ConnectionAccount documents.
         * @param {string} connection_id 
         * @param {string} connectionsync_id 
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {ListConnectionAccountsSortFieldEnum} [sort_field] 
         * @param {ListConnectionAccountsSortDirectionEnum} [sort_direction] 
         * @param {boolean} [is_auto_import_enabled] 
         * @param {boolean} [is_ignored] 
         * @param {string} [connection_user] 
         * @param {ListConnectionAccountsAccountParameter} [account] 
         * @param {string} [search] 
         * @param {boolean} [populate_account] 
         * @param {boolean} [populate_connection_user] 
         * @param {boolean} [populate_likely_matches] 
         * @param {string} [connection_account] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listConnectionAccounts: async (connection_id: string, connectionsync_id: string, skip?: number, limit?: number, sort_field?: ListConnectionAccountsSortFieldEnum, sort_direction?: ListConnectionAccountsSortDirectionEnum, is_auto_import_enabled?: boolean, is_ignored?: boolean, connection_user?: string, account?: ListConnectionAccountsAccountParameter, search?: string, populate_account?: boolean, populate_connection_user?: boolean, populate_likely_matches?: boolean, connection_account?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'connection_id' is not null or undefined
            assertParamExists('listConnectionAccounts', 'connection_id', connection_id)
            // verify required parameter 'connectionsync_id' is not null or undefined
            assertParamExists('listConnectionAccounts', 'connectionsync_id', connectionsync_id)
            const localVarPath = `/v2/connections/{connection_id}/sync/{connectionsync_id}/data/accounts`
                .replace(`{${"connection_id"}}`, encodeURIComponent(String(connection_id)))
                .replace(`{${"connectionsync_id"}}`, encodeURIComponent(String(connectionsync_id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication adminAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (skip !== undefined) {
                localVarQueryParameter['skip'] = skip;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (sort_field !== undefined) {
                localVarQueryParameter['sort_field'] = sort_field;
            }

            if (sort_direction !== undefined) {
                localVarQueryParameter['sort_direction'] = sort_direction;
            }

            if (is_auto_import_enabled !== undefined) {
                localVarQueryParameter['is_auto_import_enabled'] = is_auto_import_enabled;
            }

            if (is_ignored !== undefined) {
                localVarQueryParameter['is_ignored'] = is_ignored;
            }

            if (connection_user !== undefined) {
                localVarQueryParameter['connection_user'] = connection_user;
            }

            if (account !== undefined) {
                for (const [key, value] of Object.entries(account)) {
                    localVarQueryParameter[key] = value;
                }
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (populate_account !== undefined) {
                localVarQueryParameter['populate_account'] = populate_account;
            }

            if (populate_connection_user !== undefined) {
                localVarQueryParameter['populate_connection_user'] = populate_connection_user;
            }

            if (populate_likely_matches !== undefined) {
                localVarQueryParameter['populate_likely_matches'] = populate_likely_matches;
            }

            if (connection_account !== undefined) {
                localVarQueryParameter['connection_account'] = connection_account;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * List and paginate through ConnectionCommitment documents.
         * @param {string} connection_id 
         * @param {string} connectionsync_id 
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {ListConnectionCommitmentsSortFieldEnum} [sort_field] 
         * @param {ListConnectionCommitmentsSortDirectionEnum} [sort_direction] 
         * @param {boolean} [is_ignored] 
         * @param {ListConnectionCommitmentsCommitmentParameter} [commitment] 
         * @param {string} [connection_investment] 
         * @param {boolean} [populate_commitment] 
         * @param {boolean} [populate_connection_investment] 
         * @param {boolean} [populate_likely_matches] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listConnectionCommitments: async (connection_id: string, connectionsync_id: string, skip?: number, limit?: number, sort_field?: ListConnectionCommitmentsSortFieldEnum, sort_direction?: ListConnectionCommitmentsSortDirectionEnum, is_ignored?: boolean, commitment?: ListConnectionCommitmentsCommitmentParameter, connection_investment?: string, populate_commitment?: boolean, populate_connection_investment?: boolean, populate_likely_matches?: boolean, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'connection_id' is not null or undefined
            assertParamExists('listConnectionCommitments', 'connection_id', connection_id)
            // verify required parameter 'connectionsync_id' is not null or undefined
            assertParamExists('listConnectionCommitments', 'connectionsync_id', connectionsync_id)
            const localVarPath = `/v2/connections/{connection_id}/sync/{connectionsync_id}/data/commitments`
                .replace(`{${"connection_id"}}`, encodeURIComponent(String(connection_id)))
                .replace(`{${"connectionsync_id"}}`, encodeURIComponent(String(connectionsync_id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication adminAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (skip !== undefined) {
                localVarQueryParameter['skip'] = skip;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (sort_field !== undefined) {
                localVarQueryParameter['sort_field'] = sort_field;
            }

            if (sort_direction !== undefined) {
                localVarQueryParameter['sort_direction'] = sort_direction;
            }

            if (is_ignored !== undefined) {
                localVarQueryParameter['is_ignored'] = is_ignored;
            }

            if (commitment !== undefined) {
                for (const [key, value] of Object.entries(commitment)) {
                    localVarQueryParameter[key] = value;
                }
            }

            if (connection_investment !== undefined) {
                localVarQueryParameter['connection_investment'] = connection_investment;
            }

            if (populate_commitment !== undefined) {
                localVarQueryParameter['populate_commitment'] = populate_commitment;
            }

            if (populate_connection_investment !== undefined) {
                localVarQueryParameter['populate_connection_investment'] = populate_connection_investment;
            }

            if (populate_likely_matches !== undefined) {
                localVarQueryParameter['populate_likely_matches'] = populate_likely_matches;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * List and paginate through ConnectionDocument documents.
         * @param {string} connection_id 
         * @param {string} connectionsync_id 
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {ListConnectionDocumentsSortFieldEnum} [sort_field] 
         * @param {ListConnectionDocumentsSortDirectionEnum} [sort_direction] 
         * @param {boolean} [is_ignored] 
         * @param {string} [connection_investment] 
         * @param {string} [connection_account] 
         * @param {boolean} [populate_document] 
         * @param {boolean} [populate_parent] 
         * @param {boolean} [populate_likely_matches] 
         * @param {ListConnectionDocumentsDocumentParameter} [document] 
         * @param {boolean} [populate_connection_investment] 
         * @param {boolean} [populate_connection_account] 
         * @param {string} [document_types] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listConnectionDocuments: async (connection_id: string, connectionsync_id: string, skip?: number, limit?: number, sort_field?: ListConnectionDocumentsSortFieldEnum, sort_direction?: ListConnectionDocumentsSortDirectionEnum, is_ignored?: boolean, connection_investment?: string, connection_account?: string, populate_document?: boolean, populate_parent?: boolean, populate_likely_matches?: boolean, document?: ListConnectionDocumentsDocumentParameter, populate_connection_investment?: boolean, populate_connection_account?: boolean, document_types?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'connection_id' is not null or undefined
            assertParamExists('listConnectionDocuments', 'connection_id', connection_id)
            // verify required parameter 'connectionsync_id' is not null or undefined
            assertParamExists('listConnectionDocuments', 'connectionsync_id', connectionsync_id)
            const localVarPath = `/v2/connections/{connection_id}/sync/{connectionsync_id}/data/documents`
                .replace(`{${"connection_id"}}`, encodeURIComponent(String(connection_id)))
                .replace(`{${"connectionsync_id"}}`, encodeURIComponent(String(connectionsync_id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication adminAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (skip !== undefined) {
                localVarQueryParameter['skip'] = skip;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (sort_field !== undefined) {
                localVarQueryParameter['sort_field'] = sort_field;
            }

            if (sort_direction !== undefined) {
                localVarQueryParameter['sort_direction'] = sort_direction;
            }

            if (is_ignored !== undefined) {
                localVarQueryParameter['is_ignored'] = is_ignored;
            }

            if (connection_investment !== undefined) {
                localVarQueryParameter['connection_investment'] = connection_investment;
            }

            if (connection_account !== undefined) {
                localVarQueryParameter['connection_account'] = connection_account;
            }

            if (populate_document !== undefined) {
                localVarQueryParameter['populate_document'] = populate_document;
            }

            if (populate_parent !== undefined) {
                localVarQueryParameter['populate_parent'] = populate_parent;
            }

            if (populate_likely_matches !== undefined) {
                localVarQueryParameter['populate_likely_matches'] = populate_likely_matches;
            }

            if (document !== undefined) {
                for (const [key, value] of Object.entries(document)) {
                    localVarQueryParameter[key] = value;
                }
            }

            if (populate_connection_investment !== undefined) {
                localVarQueryParameter['populate_connection_investment'] = populate_connection_investment;
            }

            if (populate_connection_account !== undefined) {
                localVarQueryParameter['populate_connection_account'] = populate_connection_account;
            }

            if (document_types !== undefined) {
                localVarQueryParameter['document_types'] = document_types;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * List and paginate through ConnectionInvestment documents.
         * @param {string} connection_id 
         * @param {string} connectionsync_id 
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {ListConnectionInvestmentsSortFieldEnum} [sort_field] 
         * @param {ListConnectionInvestmentsSortDirectionEnum} [sort_direction] 
         * @param {boolean} [is_auto_import_enabled] 
         * @param {boolean} [is_ignored] 
         * @param {ListConnectionInvestmentsInvestmentParameter} [investment] 
         * @param {string} [search] 
         * @param {string} [connection_account] 
         * @param {boolean} [populate_investment] 
         * @param {boolean} [populate_connection_account] 
         * @param {boolean} [populate_likely_matches] 
         * @param {string} [connection_investment] 
         * @param {string} [investment_types] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listConnectionInvestments: async (connection_id: string, connectionsync_id: string, skip?: number, limit?: number, sort_field?: ListConnectionInvestmentsSortFieldEnum, sort_direction?: ListConnectionInvestmentsSortDirectionEnum, is_auto_import_enabled?: boolean, is_ignored?: boolean, investment?: ListConnectionInvestmentsInvestmentParameter, search?: string, connection_account?: string, populate_investment?: boolean, populate_connection_account?: boolean, populate_likely_matches?: boolean, connection_investment?: string, investment_types?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'connection_id' is not null or undefined
            assertParamExists('listConnectionInvestments', 'connection_id', connection_id)
            // verify required parameter 'connectionsync_id' is not null or undefined
            assertParamExists('listConnectionInvestments', 'connectionsync_id', connectionsync_id)
            const localVarPath = `/v2/connections/{connection_id}/sync/{connectionsync_id}/data/investments`
                .replace(`{${"connection_id"}}`, encodeURIComponent(String(connection_id)))
                .replace(`{${"connectionsync_id"}}`, encodeURIComponent(String(connectionsync_id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication adminAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (skip !== undefined) {
                localVarQueryParameter['skip'] = skip;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (sort_field !== undefined) {
                localVarQueryParameter['sort_field'] = sort_field;
            }

            if (sort_direction !== undefined) {
                localVarQueryParameter['sort_direction'] = sort_direction;
            }

            if (is_auto_import_enabled !== undefined) {
                localVarQueryParameter['is_auto_import_enabled'] = is_auto_import_enabled;
            }

            if (is_ignored !== undefined) {
                localVarQueryParameter['is_ignored'] = is_ignored;
            }

            if (investment !== undefined) {
                for (const [key, value] of Object.entries(investment)) {
                    localVarQueryParameter[key] = value;
                }
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (connection_account !== undefined) {
                localVarQueryParameter['connection_account'] = connection_account;
            }

            if (populate_investment !== undefined) {
                localVarQueryParameter['populate_investment'] = populate_investment;
            }

            if (populate_connection_account !== undefined) {
                localVarQueryParameter['populate_connection_account'] = populate_connection_account;
            }

            if (populate_likely_matches !== undefined) {
                localVarQueryParameter['populate_likely_matches'] = populate_likely_matches;
            }

            if (connection_investment !== undefined) {
                localVarQueryParameter['connection_investment'] = connection_investment;
            }

            if (investment_types !== undefined) {
                localVarQueryParameter['investment_types'] = investment_types;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * List and paginate through ConnectionSync documents.
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {ListConnectionSyncsSortFieldEnum} [sort_field] 
         * @param {ListConnectionSyncsSortDirectionEnum} [sort_direction] 
         * @param {boolean} [populate_counts] 
         * @param {boolean} [populate_connection] 
         * @param {ListConnectionSyncsExitStatusEnum} [exit_status] 
         * @param {string} [connection] 
         * @param {string} [connection_sync_ids] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listConnectionSyncs: async (skip?: number, limit?: number, sort_field?: ListConnectionSyncsSortFieldEnum, sort_direction?: ListConnectionSyncsSortDirectionEnum, populate_counts?: boolean, populate_connection?: boolean, exit_status?: ListConnectionSyncsExitStatusEnum, connection?: string, connection_sync_ids?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v2/connectionsyncs/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication adminAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (skip !== undefined) {
                localVarQueryParameter['skip'] = skip;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (sort_field !== undefined) {
                localVarQueryParameter['sort_field'] = sort_field;
            }

            if (sort_direction !== undefined) {
                localVarQueryParameter['sort_direction'] = sort_direction;
            }

            if (populate_counts !== undefined) {
                localVarQueryParameter['populate_counts'] = populate_counts;
            }

            if (populate_connection !== undefined) {
                localVarQueryParameter['populate_connection'] = populate_connection;
            }

            if (exit_status !== undefined) {
                localVarQueryParameter['exit_status'] = exit_status;
            }

            if (connection !== undefined) {
                localVarQueryParameter['connection'] = connection;
            }

            if (connection_sync_ids !== undefined) {
                localVarQueryParameter['connection_sync_ids'] = connection_sync_ids;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * List and paginate through ConnectionTransaction documents.
         * @param {string} connection_id 
         * @param {string} connectionsync_id 
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {ListConnectionTransactionsSortFieldEnum} [sort_field] 
         * @param {ListConnectionTransactionsSortDirectionEnum} [sort_direction] 
         * @param {boolean} [is_ignored] 
         * @param {ListConnectionTransactionsTransactionParameter} [transaction] 
         * @param {string} [connection_investment] 
         * @param {boolean} [populate_transaction] 
         * @param {boolean} [populate_connection_investment] 
         * @param {boolean} [populate_likely_matches] 
         * @param {string} [transaction_types] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listConnectionTransactions: async (connection_id: string, connectionsync_id: string, skip?: number, limit?: number, sort_field?: ListConnectionTransactionsSortFieldEnum, sort_direction?: ListConnectionTransactionsSortDirectionEnum, is_ignored?: boolean, transaction?: ListConnectionTransactionsTransactionParameter, connection_investment?: string, populate_transaction?: boolean, populate_connection_investment?: boolean, populate_likely_matches?: boolean, transaction_types?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'connection_id' is not null or undefined
            assertParamExists('listConnectionTransactions', 'connection_id', connection_id)
            // verify required parameter 'connectionsync_id' is not null or undefined
            assertParamExists('listConnectionTransactions', 'connectionsync_id', connectionsync_id)
            const localVarPath = `/v2/connections/{connection_id}/sync/{connectionsync_id}/data/transactions`
                .replace(`{${"connection_id"}}`, encodeURIComponent(String(connection_id)))
                .replace(`{${"connectionsync_id"}}`, encodeURIComponent(String(connectionsync_id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication adminAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (skip !== undefined) {
                localVarQueryParameter['skip'] = skip;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (sort_field !== undefined) {
                localVarQueryParameter['sort_field'] = sort_field;
            }

            if (sort_direction !== undefined) {
                localVarQueryParameter['sort_direction'] = sort_direction;
            }

            if (is_ignored !== undefined) {
                localVarQueryParameter['is_ignored'] = is_ignored;
            }

            if (transaction !== undefined) {
                for (const [key, value] of Object.entries(transaction)) {
                    localVarQueryParameter[key] = value;
                }
            }

            if (connection_investment !== undefined) {
                localVarQueryParameter['connection_investment'] = connection_investment;
            }

            if (populate_transaction !== undefined) {
                localVarQueryParameter['populate_transaction'] = populate_transaction;
            }

            if (populate_connection_investment !== undefined) {
                localVarQueryParameter['populate_connection_investment'] = populate_connection_investment;
            }

            if (populate_likely_matches !== undefined) {
                localVarQueryParameter['populate_likely_matches'] = populate_likely_matches;
            }

            if (transaction_types !== undefined) {
                localVarQueryParameter['transaction_types'] = transaction_types;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * List and paginate through ConnectionUser documents.
         * @param {string} connection_id 
         * @param {string} connectionsync_id 
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {ListConnectionUsersSortFieldEnum} [sort_field] 
         * @param {ListConnectionUsersSortDirectionEnum} [sort_direction] 
         * @param {boolean} [is_ignored] 
         * @param {boolean} [is_auto_import_enabled] 
         * @param {ListConnectionUsersUserParameter} [user] 
         * @param {boolean} [populate_user] 
         * @param {boolean} [populate_likely_matches] 
         * @param {string} [search] 
         * @param {string} [connection_user] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listConnectionUsers: async (connection_id: string, connectionsync_id: string, skip?: number, limit?: number, sort_field?: ListConnectionUsersSortFieldEnum, sort_direction?: ListConnectionUsersSortDirectionEnum, is_ignored?: boolean, is_auto_import_enabled?: boolean, user?: ListConnectionUsersUserParameter, populate_user?: boolean, populate_likely_matches?: boolean, search?: string, connection_user?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'connection_id' is not null or undefined
            assertParamExists('listConnectionUsers', 'connection_id', connection_id)
            // verify required parameter 'connectionsync_id' is not null or undefined
            assertParamExists('listConnectionUsers', 'connectionsync_id', connectionsync_id)
            const localVarPath = `/v2/connections/{connection_id}/sync/{connectionsync_id}/data/users`
                .replace(`{${"connection_id"}}`, encodeURIComponent(String(connection_id)))
                .replace(`{${"connectionsync_id"}}`, encodeURIComponent(String(connectionsync_id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication adminAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (skip !== undefined) {
                localVarQueryParameter['skip'] = skip;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (sort_field !== undefined) {
                localVarQueryParameter['sort_field'] = sort_field;
            }

            if (sort_direction !== undefined) {
                localVarQueryParameter['sort_direction'] = sort_direction;
            }

            if (is_ignored !== undefined) {
                localVarQueryParameter['is_ignored'] = is_ignored;
            }

            if (is_auto_import_enabled !== undefined) {
                localVarQueryParameter['is_auto_import_enabled'] = is_auto_import_enabled;
            }

            if (user !== undefined) {
                for (const [key, value] of Object.entries(user)) {
                    localVarQueryParameter[key] = value;
                }
            }

            if (populate_user !== undefined) {
                localVarQueryParameter['populate_user'] = populate_user;
            }

            if (populate_likely_matches !== undefined) {
                localVarQueryParameter['populate_likely_matches'] = populate_likely_matches;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (connection_user !== undefined) {
                localVarQueryParameter['connection_user'] = connection_user;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * List and paginate through ConnectionValuation documents.
         * @param {string} connection_id 
         * @param {string} connectionsync_id 
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {ListConnectionValuationsSortFieldEnum} [sort_field] 
         * @param {ListConnectionValuationsSortDirectionEnum} [sort_direction] 
         * @param {boolean} [is_ignored] 
         * @param {ListConnectionValuationsValuationParameter} [valuation] 
         * @param {string} [connection_investment] 
         * @param {boolean} [populate_valuation] 
         * @param {boolean} [populate_connection_investment] 
         * @param {boolean} [populate_likely_matches] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listConnectionValuations: async (connection_id: string, connectionsync_id: string, skip?: number, limit?: number, sort_field?: ListConnectionValuationsSortFieldEnum, sort_direction?: ListConnectionValuationsSortDirectionEnum, is_ignored?: boolean, valuation?: ListConnectionValuationsValuationParameter, connection_investment?: string, populate_valuation?: boolean, populate_connection_investment?: boolean, populate_likely_matches?: boolean, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'connection_id' is not null or undefined
            assertParamExists('listConnectionValuations', 'connection_id', connection_id)
            // verify required parameter 'connectionsync_id' is not null or undefined
            assertParamExists('listConnectionValuations', 'connectionsync_id', connectionsync_id)
            const localVarPath = `/v2/connections/{connection_id}/sync/{connectionsync_id}/data/valuations`
                .replace(`{${"connection_id"}}`, encodeURIComponent(String(connection_id)))
                .replace(`{${"connectionsync_id"}}`, encodeURIComponent(String(connectionsync_id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication adminAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (skip !== undefined) {
                localVarQueryParameter['skip'] = skip;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (sort_field !== undefined) {
                localVarQueryParameter['sort_field'] = sort_field;
            }

            if (sort_direction !== undefined) {
                localVarQueryParameter['sort_direction'] = sort_direction;
            }

            if (is_ignored !== undefined) {
                localVarQueryParameter['is_ignored'] = is_ignored;
            }

            if (valuation !== undefined) {
                for (const [key, value] of Object.entries(valuation)) {
                    localVarQueryParameter[key] = value;
                }
            }

            if (connection_investment !== undefined) {
                localVarQueryParameter['connection_investment'] = connection_investment;
            }

            if (populate_valuation !== undefined) {
                localVarQueryParameter['populate_valuation'] = populate_valuation;
            }

            if (populate_connection_investment !== undefined) {
                localVarQueryParameter['populate_connection_investment'] = populate_connection_investment;
            }

            if (populate_likely_matches !== undefined) {
                localVarQueryParameter['populate_likely_matches'] = populate_likely_matches;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update a ConnectionAccount document.
         * @param {string} connection_id 
         * @param {string} account_id 
         * @param {UpdateConnectionAccountRequest} [UpdateConnectionAccountRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateConnectionAccount: async (connection_id: string, account_id: string, UpdateConnectionAccountRequest?: UpdateConnectionAccountRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'connection_id' is not null or undefined
            assertParamExists('updateConnectionAccount', 'connection_id', connection_id)
            // verify required parameter 'account_id' is not null or undefined
            assertParamExists('updateConnectionAccount', 'account_id', account_id)
            const localVarPath = `/v2/connections/{connection_id}/data/accounts/{account_id}`
                .replace(`{${"connection_id"}}`, encodeURIComponent(String(connection_id)))
                .replace(`{${"account_id"}}`, encodeURIComponent(String(account_id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication adminAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(UpdateConnectionAccountRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update a ConnectionCommitment document.
         * @param {string} connection_id 
         * @param {string} commitment_id 
         * @param {UpdateConnectionCommitmentRequest} [UpdateConnectionCommitmentRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateConnectionCommitment: async (connection_id: string, commitment_id: string, UpdateConnectionCommitmentRequest?: UpdateConnectionCommitmentRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'connection_id' is not null or undefined
            assertParamExists('updateConnectionCommitment', 'connection_id', connection_id)
            // verify required parameter 'commitment_id' is not null or undefined
            assertParamExists('updateConnectionCommitment', 'commitment_id', commitment_id)
            const localVarPath = `/v2/connections/{connection_id}/data/commitments/{commitment_id}`
                .replace(`{${"connection_id"}}`, encodeURIComponent(String(connection_id)))
                .replace(`{${"commitment_id"}}`, encodeURIComponent(String(commitment_id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication adminAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(UpdateConnectionCommitmentRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update a ConnectionDocument document.
         * @param {string} connection_id 
         * @param {string} document_id 
         * @param {UpdateConnectionDocumentRequest} [UpdateConnectionDocumentRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateConnectionDocument: async (connection_id: string, document_id: string, UpdateConnectionDocumentRequest?: UpdateConnectionDocumentRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'connection_id' is not null or undefined
            assertParamExists('updateConnectionDocument', 'connection_id', connection_id)
            // verify required parameter 'document_id' is not null or undefined
            assertParamExists('updateConnectionDocument', 'document_id', document_id)
            const localVarPath = `/v2/connections/{connection_id}/data/documents/{document_id}`
                .replace(`{${"connection_id"}}`, encodeURIComponent(String(connection_id)))
                .replace(`{${"document_id"}}`, encodeURIComponent(String(document_id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication adminAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(UpdateConnectionDocumentRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update a ConnectionInvestment document.
         * @param {string} connection_id 
         * @param {string} investment_id 
         * @param {UpdateConnectionInvestmentRequest} [UpdateConnectionInvestmentRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateConnectionInvestment: async (connection_id: string, investment_id: string, UpdateConnectionInvestmentRequest?: UpdateConnectionInvestmentRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'connection_id' is not null or undefined
            assertParamExists('updateConnectionInvestment', 'connection_id', connection_id)
            // verify required parameter 'investment_id' is not null or undefined
            assertParamExists('updateConnectionInvestment', 'investment_id', investment_id)
            const localVarPath = `/v2/connections/{connection_id}/data/investments/{investment_id}`
                .replace(`{${"connection_id"}}`, encodeURIComponent(String(connection_id)))
                .replace(`{${"investment_id"}}`, encodeURIComponent(String(investment_id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication adminAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(UpdateConnectionInvestmentRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update a ConnectionTransaction document.
         * @param {string} connection_id 
         * @param {string} transaction_id 
         * @param {UpdateConnectionTransactionRequest} [UpdateConnectionTransactionRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateConnectionTransaction: async (connection_id: string, transaction_id: string, UpdateConnectionTransactionRequest?: UpdateConnectionTransactionRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'connection_id' is not null or undefined
            assertParamExists('updateConnectionTransaction', 'connection_id', connection_id)
            // verify required parameter 'transaction_id' is not null or undefined
            assertParamExists('updateConnectionTransaction', 'transaction_id', transaction_id)
            const localVarPath = `/v2/connections/{connection_id}/data/transactions/{transaction_id}`
                .replace(`{${"connection_id"}}`, encodeURIComponent(String(connection_id)))
                .replace(`{${"transaction_id"}}`, encodeURIComponent(String(transaction_id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication adminAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(UpdateConnectionTransactionRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update a ConnectionUser document.
         * @param {string} connection_id 
         * @param {string} user_id 
         * @param {UpdateConnectionUserRequest} [UpdateConnectionUserRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateConnectionUser: async (connection_id: string, user_id: string, UpdateConnectionUserRequest?: UpdateConnectionUserRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'connection_id' is not null or undefined
            assertParamExists('updateConnectionUser', 'connection_id', connection_id)
            // verify required parameter 'user_id' is not null or undefined
            assertParamExists('updateConnectionUser', 'user_id', user_id)
            const localVarPath = `/v2/connections/{connection_id}/data/users/{user_id}`
                .replace(`{${"connection_id"}}`, encodeURIComponent(String(connection_id)))
                .replace(`{${"user_id"}}`, encodeURIComponent(String(user_id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication adminAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(UpdateConnectionUserRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update a ConnectionValuation document.
         * @param {string} connection_id 
         * @param {string} valuation_id 
         * @param {UpdateConnectionValuationRequest} [UpdateConnectionValuationRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateConnectionValuation: async (connection_id: string, valuation_id: string, UpdateConnectionValuationRequest?: UpdateConnectionValuationRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'connection_id' is not null or undefined
            assertParamExists('updateConnectionValuation', 'connection_id', connection_id)
            // verify required parameter 'valuation_id' is not null or undefined
            assertParamExists('updateConnectionValuation', 'valuation_id', valuation_id)
            const localVarPath = `/v2/connections/{connection_id}/data/valuations/{valuation_id}`
                .replace(`{${"connection_id"}}`, encodeURIComponent(String(connection_id)))
                .replace(`{${"valuation_id"}}`, encodeURIComponent(String(valuation_id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication adminAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(UpdateConnectionValuationRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ConnectionDataApi - functional programming interface
 * @export
 */
export const ConnectionDataApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ConnectionDataApiAxiosParamCreator(configuration)
    return {
        /**
         * Attempt import of a ConnectionAccount document with optional user override.
         * @param {string} connection_id 
         * @param {string} account_id 
         * @param {ImportConnectionAccountRequest} [ImportConnectionAccountRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async importConnectionAccount(connection_id: string, account_id: string, ImportConnectionAccountRequest?: ImportConnectionAccountRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DeleteAccount200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.importConnectionAccount(connection_id, account_id, ImportConnectionAccountRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ConnectionDataApi.importConnectionAccount']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Attempt import of a ConnectionCommitment document with optional user override.
         * @param {string} connection_id 
         * @param {string} commitment_id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async importConnectionCommitment(connection_id: string, commitment_id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DeleteAccount200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.importConnectionCommitment(connection_id, commitment_id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ConnectionDataApi.importConnectionCommitment']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Attempt import of a ConnectionDocument document.
         * @param {string} connection_id 
         * @param {string} document_id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async importConnectionDocument(connection_id: string, document_id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DeleteAccount200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.importConnectionDocument(connection_id, document_id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ConnectionDataApi.importConnectionDocument']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Attempt import of a ConnectionInvestment document with optional account override.
         * @param {string} connection_id 
         * @param {string} investment_id 
         * @param {ImportConnectionInvestmentRequest} [ImportConnectionInvestmentRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async importConnectionInvestment(connection_id: string, investment_id: string, ImportConnectionInvestmentRequest?: ImportConnectionInvestmentRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DeleteAccount200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.importConnectionInvestment(connection_id, investment_id, ImportConnectionInvestmentRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ConnectionDataApi.importConnectionInvestment']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Attempt import of a ConnectionTransaction document.
         * @param {string} connection_id 
         * @param {string} transaction_id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async importConnectionTransaction(connection_id: string, transaction_id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DeleteAccount200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.importConnectionTransaction(connection_id, transaction_id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ConnectionDataApi.importConnectionTransaction']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Attempt import of a ConnectionUser document.
         * @param {string} connection_id 
         * @param {string} user_id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async importConnectionUser(connection_id: string, user_id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DeleteAccount200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.importConnectionUser(connection_id, user_id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ConnectionDataApi.importConnectionUser']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Attempt import of a ConnectionValuation document.
         * @param {string} connection_id 
         * @param {string} valuation_id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async importConnectionValuation(connection_id: string, valuation_id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DeleteAccount200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.importConnectionValuation(connection_id, valuation_id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ConnectionDataApi.importConnectionValuation']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * List and paginate through ConnectionAccount documents.
         * @param {string} connection_id 
         * @param {string} connectionsync_id 
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {ListConnectionAccountsSortFieldEnum} [sort_field] 
         * @param {ListConnectionAccountsSortDirectionEnum} [sort_direction] 
         * @param {boolean} [is_auto_import_enabled] 
         * @param {boolean} [is_ignored] 
         * @param {string} [connection_user] 
         * @param {ListConnectionAccountsAccountParameter} [account] 
         * @param {string} [search] 
         * @param {boolean} [populate_account] 
         * @param {boolean} [populate_connection_user] 
         * @param {boolean} [populate_likely_matches] 
         * @param {string} [connection_account] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listConnectionAccounts(connection_id: string, connectionsync_id: string, skip?: number, limit?: number, sort_field?: ListConnectionAccountsSortFieldEnum, sort_direction?: ListConnectionAccountsSortDirectionEnum, is_auto_import_enabled?: boolean, is_ignored?: boolean, connection_user?: string, account?: ListConnectionAccountsAccountParameter, search?: string, populate_account?: boolean, populate_connection_user?: boolean, populate_likely_matches?: boolean, connection_account?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListConnectionAccounts200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listConnectionAccounts(connection_id, connectionsync_id, skip, limit, sort_field, sort_direction, is_auto_import_enabled, is_ignored, connection_user, account, search, populate_account, populate_connection_user, populate_likely_matches, connection_account, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ConnectionDataApi.listConnectionAccounts']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * List and paginate through ConnectionCommitment documents.
         * @param {string} connection_id 
         * @param {string} connectionsync_id 
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {ListConnectionCommitmentsSortFieldEnum} [sort_field] 
         * @param {ListConnectionCommitmentsSortDirectionEnum} [sort_direction] 
         * @param {boolean} [is_ignored] 
         * @param {ListConnectionCommitmentsCommitmentParameter} [commitment] 
         * @param {string} [connection_investment] 
         * @param {boolean} [populate_commitment] 
         * @param {boolean} [populate_connection_investment] 
         * @param {boolean} [populate_likely_matches] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listConnectionCommitments(connection_id: string, connectionsync_id: string, skip?: number, limit?: number, sort_field?: ListConnectionCommitmentsSortFieldEnum, sort_direction?: ListConnectionCommitmentsSortDirectionEnum, is_ignored?: boolean, commitment?: ListConnectionCommitmentsCommitmentParameter, connection_investment?: string, populate_commitment?: boolean, populate_connection_investment?: boolean, populate_likely_matches?: boolean, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListConnectionCommitments200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listConnectionCommitments(connection_id, connectionsync_id, skip, limit, sort_field, sort_direction, is_ignored, commitment, connection_investment, populate_commitment, populate_connection_investment, populate_likely_matches, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ConnectionDataApi.listConnectionCommitments']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * List and paginate through ConnectionDocument documents.
         * @param {string} connection_id 
         * @param {string} connectionsync_id 
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {ListConnectionDocumentsSortFieldEnum} [sort_field] 
         * @param {ListConnectionDocumentsSortDirectionEnum} [sort_direction] 
         * @param {boolean} [is_ignored] 
         * @param {string} [connection_investment] 
         * @param {string} [connection_account] 
         * @param {boolean} [populate_document] 
         * @param {boolean} [populate_parent] 
         * @param {boolean} [populate_likely_matches] 
         * @param {ListConnectionDocumentsDocumentParameter} [document] 
         * @param {boolean} [populate_connection_investment] 
         * @param {boolean} [populate_connection_account] 
         * @param {string} [document_types] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listConnectionDocuments(connection_id: string, connectionsync_id: string, skip?: number, limit?: number, sort_field?: ListConnectionDocumentsSortFieldEnum, sort_direction?: ListConnectionDocumentsSortDirectionEnum, is_ignored?: boolean, connection_investment?: string, connection_account?: string, populate_document?: boolean, populate_parent?: boolean, populate_likely_matches?: boolean, document?: ListConnectionDocumentsDocumentParameter, populate_connection_investment?: boolean, populate_connection_account?: boolean, document_types?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListConnectionDocuments200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listConnectionDocuments(connection_id, connectionsync_id, skip, limit, sort_field, sort_direction, is_ignored, connection_investment, connection_account, populate_document, populate_parent, populate_likely_matches, document, populate_connection_investment, populate_connection_account, document_types, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ConnectionDataApi.listConnectionDocuments']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * List and paginate through ConnectionInvestment documents.
         * @param {string} connection_id 
         * @param {string} connectionsync_id 
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {ListConnectionInvestmentsSortFieldEnum} [sort_field] 
         * @param {ListConnectionInvestmentsSortDirectionEnum} [sort_direction] 
         * @param {boolean} [is_auto_import_enabled] 
         * @param {boolean} [is_ignored] 
         * @param {ListConnectionInvestmentsInvestmentParameter} [investment] 
         * @param {string} [search] 
         * @param {string} [connection_account] 
         * @param {boolean} [populate_investment] 
         * @param {boolean} [populate_connection_account] 
         * @param {boolean} [populate_likely_matches] 
         * @param {string} [connection_investment] 
         * @param {string} [investment_types] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listConnectionInvestments(connection_id: string, connectionsync_id: string, skip?: number, limit?: number, sort_field?: ListConnectionInvestmentsSortFieldEnum, sort_direction?: ListConnectionInvestmentsSortDirectionEnum, is_auto_import_enabled?: boolean, is_ignored?: boolean, investment?: ListConnectionInvestmentsInvestmentParameter, search?: string, connection_account?: string, populate_investment?: boolean, populate_connection_account?: boolean, populate_likely_matches?: boolean, connection_investment?: string, investment_types?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListConnectionInvestments200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listConnectionInvestments(connection_id, connectionsync_id, skip, limit, sort_field, sort_direction, is_auto_import_enabled, is_ignored, investment, search, connection_account, populate_investment, populate_connection_account, populate_likely_matches, connection_investment, investment_types, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ConnectionDataApi.listConnectionInvestments']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * List and paginate through ConnectionSync documents.
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {ListConnectionSyncsSortFieldEnum} [sort_field] 
         * @param {ListConnectionSyncsSortDirectionEnum} [sort_direction] 
         * @param {boolean} [populate_counts] 
         * @param {boolean} [populate_connection] 
         * @param {ListConnectionSyncsExitStatusEnum} [exit_status] 
         * @param {string} [connection] 
         * @param {string} [connection_sync_ids] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listConnectionSyncs(skip?: number, limit?: number, sort_field?: ListConnectionSyncsSortFieldEnum, sort_direction?: ListConnectionSyncsSortDirectionEnum, populate_counts?: boolean, populate_connection?: boolean, exit_status?: ListConnectionSyncsExitStatusEnum, connection?: string, connection_sync_ids?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListConnectionSyncs200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listConnectionSyncs(skip, limit, sort_field, sort_direction, populate_counts, populate_connection, exit_status, connection, connection_sync_ids, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ConnectionDataApi.listConnectionSyncs']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * List and paginate through ConnectionTransaction documents.
         * @param {string} connection_id 
         * @param {string} connectionsync_id 
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {ListConnectionTransactionsSortFieldEnum} [sort_field] 
         * @param {ListConnectionTransactionsSortDirectionEnum} [sort_direction] 
         * @param {boolean} [is_ignored] 
         * @param {ListConnectionTransactionsTransactionParameter} [transaction] 
         * @param {string} [connection_investment] 
         * @param {boolean} [populate_transaction] 
         * @param {boolean} [populate_connection_investment] 
         * @param {boolean} [populate_likely_matches] 
         * @param {string} [transaction_types] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listConnectionTransactions(connection_id: string, connectionsync_id: string, skip?: number, limit?: number, sort_field?: ListConnectionTransactionsSortFieldEnum, sort_direction?: ListConnectionTransactionsSortDirectionEnum, is_ignored?: boolean, transaction?: ListConnectionTransactionsTransactionParameter, connection_investment?: string, populate_transaction?: boolean, populate_connection_investment?: boolean, populate_likely_matches?: boolean, transaction_types?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListConnectionTransactions200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listConnectionTransactions(connection_id, connectionsync_id, skip, limit, sort_field, sort_direction, is_ignored, transaction, connection_investment, populate_transaction, populate_connection_investment, populate_likely_matches, transaction_types, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ConnectionDataApi.listConnectionTransactions']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * List and paginate through ConnectionUser documents.
         * @param {string} connection_id 
         * @param {string} connectionsync_id 
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {ListConnectionUsersSortFieldEnum} [sort_field] 
         * @param {ListConnectionUsersSortDirectionEnum} [sort_direction] 
         * @param {boolean} [is_ignored] 
         * @param {boolean} [is_auto_import_enabled] 
         * @param {ListConnectionUsersUserParameter} [user] 
         * @param {boolean} [populate_user] 
         * @param {boolean} [populate_likely_matches] 
         * @param {string} [search] 
         * @param {string} [connection_user] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listConnectionUsers(connection_id: string, connectionsync_id: string, skip?: number, limit?: number, sort_field?: ListConnectionUsersSortFieldEnum, sort_direction?: ListConnectionUsersSortDirectionEnum, is_ignored?: boolean, is_auto_import_enabled?: boolean, user?: ListConnectionUsersUserParameter, populate_user?: boolean, populate_likely_matches?: boolean, search?: string, connection_user?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListConnectionUsers200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listConnectionUsers(connection_id, connectionsync_id, skip, limit, sort_field, sort_direction, is_ignored, is_auto_import_enabled, user, populate_user, populate_likely_matches, search, connection_user, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ConnectionDataApi.listConnectionUsers']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * List and paginate through ConnectionValuation documents.
         * @param {string} connection_id 
         * @param {string} connectionsync_id 
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {ListConnectionValuationsSortFieldEnum} [sort_field] 
         * @param {ListConnectionValuationsSortDirectionEnum} [sort_direction] 
         * @param {boolean} [is_ignored] 
         * @param {ListConnectionValuationsValuationParameter} [valuation] 
         * @param {string} [connection_investment] 
         * @param {boolean} [populate_valuation] 
         * @param {boolean} [populate_connection_investment] 
         * @param {boolean} [populate_likely_matches] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listConnectionValuations(connection_id: string, connectionsync_id: string, skip?: number, limit?: number, sort_field?: ListConnectionValuationsSortFieldEnum, sort_direction?: ListConnectionValuationsSortDirectionEnum, is_ignored?: boolean, valuation?: ListConnectionValuationsValuationParameter, connection_investment?: string, populate_valuation?: boolean, populate_connection_investment?: boolean, populate_likely_matches?: boolean, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListConnectionValuations200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listConnectionValuations(connection_id, connectionsync_id, skip, limit, sort_field, sort_direction, is_ignored, valuation, connection_investment, populate_valuation, populate_connection_investment, populate_likely_matches, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ConnectionDataApi.listConnectionValuations']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Update a ConnectionAccount document.
         * @param {string} connection_id 
         * @param {string} account_id 
         * @param {UpdateConnectionAccountRequest} [UpdateConnectionAccountRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateConnectionAccount(connection_id: string, account_id: string, UpdateConnectionAccountRequest?: UpdateConnectionAccountRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DeleteAccount200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateConnectionAccount(connection_id, account_id, UpdateConnectionAccountRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ConnectionDataApi.updateConnectionAccount']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Update a ConnectionCommitment document.
         * @param {string} connection_id 
         * @param {string} commitment_id 
         * @param {UpdateConnectionCommitmentRequest} [UpdateConnectionCommitmentRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateConnectionCommitment(connection_id: string, commitment_id: string, UpdateConnectionCommitmentRequest?: UpdateConnectionCommitmentRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DeleteAccount200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateConnectionCommitment(connection_id, commitment_id, UpdateConnectionCommitmentRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ConnectionDataApi.updateConnectionCommitment']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Update a ConnectionDocument document.
         * @param {string} connection_id 
         * @param {string} document_id 
         * @param {UpdateConnectionDocumentRequest} [UpdateConnectionDocumentRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateConnectionDocument(connection_id: string, document_id: string, UpdateConnectionDocumentRequest?: UpdateConnectionDocumentRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DeleteAccount200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateConnectionDocument(connection_id, document_id, UpdateConnectionDocumentRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ConnectionDataApi.updateConnectionDocument']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Update a ConnectionInvestment document.
         * @param {string} connection_id 
         * @param {string} investment_id 
         * @param {UpdateConnectionInvestmentRequest} [UpdateConnectionInvestmentRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateConnectionInvestment(connection_id: string, investment_id: string, UpdateConnectionInvestmentRequest?: UpdateConnectionInvestmentRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DeleteAccount200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateConnectionInvestment(connection_id, investment_id, UpdateConnectionInvestmentRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ConnectionDataApi.updateConnectionInvestment']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Update a ConnectionTransaction document.
         * @param {string} connection_id 
         * @param {string} transaction_id 
         * @param {UpdateConnectionTransactionRequest} [UpdateConnectionTransactionRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateConnectionTransaction(connection_id: string, transaction_id: string, UpdateConnectionTransactionRequest?: UpdateConnectionTransactionRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DeleteAccount200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateConnectionTransaction(connection_id, transaction_id, UpdateConnectionTransactionRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ConnectionDataApi.updateConnectionTransaction']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Update a ConnectionUser document.
         * @param {string} connection_id 
         * @param {string} user_id 
         * @param {UpdateConnectionUserRequest} [UpdateConnectionUserRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateConnectionUser(connection_id: string, user_id: string, UpdateConnectionUserRequest?: UpdateConnectionUserRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DeleteAccount200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateConnectionUser(connection_id, user_id, UpdateConnectionUserRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ConnectionDataApi.updateConnectionUser']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Update a ConnectionValuation document.
         * @param {string} connection_id 
         * @param {string} valuation_id 
         * @param {UpdateConnectionValuationRequest} [UpdateConnectionValuationRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateConnectionValuation(connection_id: string, valuation_id: string, UpdateConnectionValuationRequest?: UpdateConnectionValuationRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DeleteAccount200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateConnectionValuation(connection_id, valuation_id, UpdateConnectionValuationRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ConnectionDataApi.updateConnectionValuation']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * ConnectionDataApi - factory interface
 * @export
 */
export const ConnectionDataApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ConnectionDataApiFp(configuration)
    return {
        /**
         * Attempt import of a ConnectionAccount document with optional user override.
         * @param {ConnectionDataApiImportConnectionAccountRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        importConnectionAccount(requestParameters: ConnectionDataApiImportConnectionAccountRequest, options?: RawAxiosRequestConfig): AxiosPromise<DeleteAccount200Response> {
            return localVarFp.importConnectionAccount(requestParameters.connection_id, requestParameters.account_id, requestParameters.ImportConnectionAccountRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Attempt import of a ConnectionCommitment document with optional user override.
         * @param {ConnectionDataApiImportConnectionCommitmentRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        importConnectionCommitment(requestParameters: ConnectionDataApiImportConnectionCommitmentRequest, options?: RawAxiosRequestConfig): AxiosPromise<DeleteAccount200Response> {
            return localVarFp.importConnectionCommitment(requestParameters.connection_id, requestParameters.commitment_id, options).then((request) => request(axios, basePath));
        },
        /**
         * Attempt import of a ConnectionDocument document.
         * @param {ConnectionDataApiImportConnectionDocumentRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        importConnectionDocument(requestParameters: ConnectionDataApiImportConnectionDocumentRequest, options?: RawAxiosRequestConfig): AxiosPromise<DeleteAccount200Response> {
            return localVarFp.importConnectionDocument(requestParameters.connection_id, requestParameters.document_id, options).then((request) => request(axios, basePath));
        },
        /**
         * Attempt import of a ConnectionInvestment document with optional account override.
         * @param {ConnectionDataApiImportConnectionInvestmentRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        importConnectionInvestment(requestParameters: ConnectionDataApiImportConnectionInvestmentRequest, options?: RawAxiosRequestConfig): AxiosPromise<DeleteAccount200Response> {
            return localVarFp.importConnectionInvestment(requestParameters.connection_id, requestParameters.investment_id, requestParameters.ImportConnectionInvestmentRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Attempt import of a ConnectionTransaction document.
         * @param {ConnectionDataApiImportConnectionTransactionRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        importConnectionTransaction(requestParameters: ConnectionDataApiImportConnectionTransactionRequest, options?: RawAxiosRequestConfig): AxiosPromise<DeleteAccount200Response> {
            return localVarFp.importConnectionTransaction(requestParameters.connection_id, requestParameters.transaction_id, options).then((request) => request(axios, basePath));
        },
        /**
         * Attempt import of a ConnectionUser document.
         * @param {ConnectionDataApiImportConnectionUserRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        importConnectionUser(requestParameters: ConnectionDataApiImportConnectionUserRequest, options?: RawAxiosRequestConfig): AxiosPromise<DeleteAccount200Response> {
            return localVarFp.importConnectionUser(requestParameters.connection_id, requestParameters.user_id, options).then((request) => request(axios, basePath));
        },
        /**
         * Attempt import of a ConnectionValuation document.
         * @param {ConnectionDataApiImportConnectionValuationRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        importConnectionValuation(requestParameters: ConnectionDataApiImportConnectionValuationRequest, options?: RawAxiosRequestConfig): AxiosPromise<DeleteAccount200Response> {
            return localVarFp.importConnectionValuation(requestParameters.connection_id, requestParameters.valuation_id, options).then((request) => request(axios, basePath));
        },
        /**
         * List and paginate through ConnectionAccount documents.
         * @param {ConnectionDataApiListConnectionAccountsRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listConnectionAccounts(requestParameters: ConnectionDataApiListConnectionAccountsRequest, options?: RawAxiosRequestConfig): AxiosPromise<ListConnectionAccounts200Response> {
            return localVarFp.listConnectionAccounts(requestParameters.connection_id, requestParameters.connectionsync_id, requestParameters.skip, requestParameters.limit, requestParameters.sort_field, requestParameters.sort_direction, requestParameters.is_auto_import_enabled, requestParameters.is_ignored, requestParameters.connection_user, requestParameters.account, requestParameters.search, requestParameters.populate_account, requestParameters.populate_connection_user, requestParameters.populate_likely_matches, requestParameters.connection_account, options).then((request) => request(axios, basePath));
        },
        /**
         * List and paginate through ConnectionCommitment documents.
         * @param {ConnectionDataApiListConnectionCommitmentsRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listConnectionCommitments(requestParameters: ConnectionDataApiListConnectionCommitmentsRequest, options?: RawAxiosRequestConfig): AxiosPromise<ListConnectionCommitments200Response> {
            return localVarFp.listConnectionCommitments(requestParameters.connection_id, requestParameters.connectionsync_id, requestParameters.skip, requestParameters.limit, requestParameters.sort_field, requestParameters.sort_direction, requestParameters.is_ignored, requestParameters.commitment, requestParameters.connection_investment, requestParameters.populate_commitment, requestParameters.populate_connection_investment, requestParameters.populate_likely_matches, options).then((request) => request(axios, basePath));
        },
        /**
         * List and paginate through ConnectionDocument documents.
         * @param {ConnectionDataApiListConnectionDocumentsRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listConnectionDocuments(requestParameters: ConnectionDataApiListConnectionDocumentsRequest, options?: RawAxiosRequestConfig): AxiosPromise<ListConnectionDocuments200Response> {
            return localVarFp.listConnectionDocuments(requestParameters.connection_id, requestParameters.connectionsync_id, requestParameters.skip, requestParameters.limit, requestParameters.sort_field, requestParameters.sort_direction, requestParameters.is_ignored, requestParameters.connection_investment, requestParameters.connection_account, requestParameters.populate_document, requestParameters.populate_parent, requestParameters.populate_likely_matches, requestParameters.document, requestParameters.populate_connection_investment, requestParameters.populate_connection_account, requestParameters.document_types, options).then((request) => request(axios, basePath));
        },
        /**
         * List and paginate through ConnectionInvestment documents.
         * @param {ConnectionDataApiListConnectionInvestmentsRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listConnectionInvestments(requestParameters: ConnectionDataApiListConnectionInvestmentsRequest, options?: RawAxiosRequestConfig): AxiosPromise<ListConnectionInvestments200Response> {
            return localVarFp.listConnectionInvestments(requestParameters.connection_id, requestParameters.connectionsync_id, requestParameters.skip, requestParameters.limit, requestParameters.sort_field, requestParameters.sort_direction, requestParameters.is_auto_import_enabled, requestParameters.is_ignored, requestParameters.investment, requestParameters.search, requestParameters.connection_account, requestParameters.populate_investment, requestParameters.populate_connection_account, requestParameters.populate_likely_matches, requestParameters.connection_investment, requestParameters.investment_types, options).then((request) => request(axios, basePath));
        },
        /**
         * List and paginate through ConnectionSync documents.
         * @param {ConnectionDataApiListConnectionSyncsRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listConnectionSyncs(requestParameters: ConnectionDataApiListConnectionSyncsRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<ListConnectionSyncs200Response> {
            return localVarFp.listConnectionSyncs(requestParameters.skip, requestParameters.limit, requestParameters.sort_field, requestParameters.sort_direction, requestParameters.populate_counts, requestParameters.populate_connection, requestParameters.exit_status, requestParameters.connection, requestParameters.connection_sync_ids, options).then((request) => request(axios, basePath));
        },
        /**
         * List and paginate through ConnectionTransaction documents.
         * @param {ConnectionDataApiListConnectionTransactionsRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listConnectionTransactions(requestParameters: ConnectionDataApiListConnectionTransactionsRequest, options?: RawAxiosRequestConfig): AxiosPromise<ListConnectionTransactions200Response> {
            return localVarFp.listConnectionTransactions(requestParameters.connection_id, requestParameters.connectionsync_id, requestParameters.skip, requestParameters.limit, requestParameters.sort_field, requestParameters.sort_direction, requestParameters.is_ignored, requestParameters.transaction, requestParameters.connection_investment, requestParameters.populate_transaction, requestParameters.populate_connection_investment, requestParameters.populate_likely_matches, requestParameters.transaction_types, options).then((request) => request(axios, basePath));
        },
        /**
         * List and paginate through ConnectionUser documents.
         * @param {ConnectionDataApiListConnectionUsersRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listConnectionUsers(requestParameters: ConnectionDataApiListConnectionUsersRequest, options?: RawAxiosRequestConfig): AxiosPromise<ListConnectionUsers200Response> {
            return localVarFp.listConnectionUsers(requestParameters.connection_id, requestParameters.connectionsync_id, requestParameters.skip, requestParameters.limit, requestParameters.sort_field, requestParameters.sort_direction, requestParameters.is_ignored, requestParameters.is_auto_import_enabled, requestParameters.user, requestParameters.populate_user, requestParameters.populate_likely_matches, requestParameters.search, requestParameters.connection_user, options).then((request) => request(axios, basePath));
        },
        /**
         * List and paginate through ConnectionValuation documents.
         * @param {ConnectionDataApiListConnectionValuationsRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listConnectionValuations(requestParameters: ConnectionDataApiListConnectionValuationsRequest, options?: RawAxiosRequestConfig): AxiosPromise<ListConnectionValuations200Response> {
            return localVarFp.listConnectionValuations(requestParameters.connection_id, requestParameters.connectionsync_id, requestParameters.skip, requestParameters.limit, requestParameters.sort_field, requestParameters.sort_direction, requestParameters.is_ignored, requestParameters.valuation, requestParameters.connection_investment, requestParameters.populate_valuation, requestParameters.populate_connection_investment, requestParameters.populate_likely_matches, options).then((request) => request(axios, basePath));
        },
        /**
         * Update a ConnectionAccount document.
         * @param {ConnectionDataApiUpdateConnectionAccountRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateConnectionAccount(requestParameters: ConnectionDataApiUpdateConnectionAccountRequest, options?: RawAxiosRequestConfig): AxiosPromise<DeleteAccount200Response> {
            return localVarFp.updateConnectionAccount(requestParameters.connection_id, requestParameters.account_id, requestParameters.UpdateConnectionAccountRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Update a ConnectionCommitment document.
         * @param {ConnectionDataApiUpdateConnectionCommitmentRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateConnectionCommitment(requestParameters: ConnectionDataApiUpdateConnectionCommitmentRequest, options?: RawAxiosRequestConfig): AxiosPromise<DeleteAccount200Response> {
            return localVarFp.updateConnectionCommitment(requestParameters.connection_id, requestParameters.commitment_id, requestParameters.UpdateConnectionCommitmentRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Update a ConnectionDocument document.
         * @param {ConnectionDataApiUpdateConnectionDocumentRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateConnectionDocument(requestParameters: ConnectionDataApiUpdateConnectionDocumentRequest, options?: RawAxiosRequestConfig): AxiosPromise<DeleteAccount200Response> {
            return localVarFp.updateConnectionDocument(requestParameters.connection_id, requestParameters.document_id, requestParameters.UpdateConnectionDocumentRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Update a ConnectionInvestment document.
         * @param {ConnectionDataApiUpdateConnectionInvestmentRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateConnectionInvestment(requestParameters: ConnectionDataApiUpdateConnectionInvestmentRequest, options?: RawAxiosRequestConfig): AxiosPromise<DeleteAccount200Response> {
            return localVarFp.updateConnectionInvestment(requestParameters.connection_id, requestParameters.investment_id, requestParameters.UpdateConnectionInvestmentRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Update a ConnectionTransaction document.
         * @param {ConnectionDataApiUpdateConnectionTransactionRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateConnectionTransaction(requestParameters: ConnectionDataApiUpdateConnectionTransactionRequest, options?: RawAxiosRequestConfig): AxiosPromise<DeleteAccount200Response> {
            return localVarFp.updateConnectionTransaction(requestParameters.connection_id, requestParameters.transaction_id, requestParameters.UpdateConnectionTransactionRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Update a ConnectionUser document.
         * @param {ConnectionDataApiUpdateConnectionUserRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateConnectionUser(requestParameters: ConnectionDataApiUpdateConnectionUserRequest, options?: RawAxiosRequestConfig): AxiosPromise<DeleteAccount200Response> {
            return localVarFp.updateConnectionUser(requestParameters.connection_id, requestParameters.user_id, requestParameters.UpdateConnectionUserRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Update a ConnectionValuation document.
         * @param {ConnectionDataApiUpdateConnectionValuationRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateConnectionValuation(requestParameters: ConnectionDataApiUpdateConnectionValuationRequest, options?: RawAxiosRequestConfig): AxiosPromise<DeleteAccount200Response> {
            return localVarFp.updateConnectionValuation(requestParameters.connection_id, requestParameters.valuation_id, requestParameters.UpdateConnectionValuationRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for importConnectionAccount operation in ConnectionDataApi.
 * @export
 * @interface ConnectionDataApiImportConnectionAccountRequest
 */
export interface ConnectionDataApiImportConnectionAccountRequest {
    /**
     * 
     * @type {string}
     * @memberof ConnectionDataApiImportConnectionAccount
     */
    readonly connection_id: string

    /**
     * 
     * @type {string}
     * @memberof ConnectionDataApiImportConnectionAccount
     */
    readonly account_id: string

    /**
     * 
     * @type {ImportConnectionAccountRequest}
     * @memberof ConnectionDataApiImportConnectionAccount
     */
    readonly ImportConnectionAccountRequest?: ImportConnectionAccountRequest
}

/**
 * Request parameters for importConnectionCommitment operation in ConnectionDataApi.
 * @export
 * @interface ConnectionDataApiImportConnectionCommitmentRequest
 */
export interface ConnectionDataApiImportConnectionCommitmentRequest {
    /**
     * 
     * @type {string}
     * @memberof ConnectionDataApiImportConnectionCommitment
     */
    readonly connection_id: string

    /**
     * 
     * @type {string}
     * @memberof ConnectionDataApiImportConnectionCommitment
     */
    readonly commitment_id: string
}

/**
 * Request parameters for importConnectionDocument operation in ConnectionDataApi.
 * @export
 * @interface ConnectionDataApiImportConnectionDocumentRequest
 */
export interface ConnectionDataApiImportConnectionDocumentRequest {
    /**
     * 
     * @type {string}
     * @memberof ConnectionDataApiImportConnectionDocument
     */
    readonly connection_id: string

    /**
     * 
     * @type {string}
     * @memberof ConnectionDataApiImportConnectionDocument
     */
    readonly document_id: string
}

/**
 * Request parameters for importConnectionInvestment operation in ConnectionDataApi.
 * @export
 * @interface ConnectionDataApiImportConnectionInvestmentRequest
 */
export interface ConnectionDataApiImportConnectionInvestmentRequest {
    /**
     * 
     * @type {string}
     * @memberof ConnectionDataApiImportConnectionInvestment
     */
    readonly connection_id: string

    /**
     * 
     * @type {string}
     * @memberof ConnectionDataApiImportConnectionInvestment
     */
    readonly investment_id: string

    /**
     * 
     * @type {ImportConnectionInvestmentRequest}
     * @memberof ConnectionDataApiImportConnectionInvestment
     */
    readonly ImportConnectionInvestmentRequest?: ImportConnectionInvestmentRequest
}

/**
 * Request parameters for importConnectionTransaction operation in ConnectionDataApi.
 * @export
 * @interface ConnectionDataApiImportConnectionTransactionRequest
 */
export interface ConnectionDataApiImportConnectionTransactionRequest {
    /**
     * 
     * @type {string}
     * @memberof ConnectionDataApiImportConnectionTransaction
     */
    readonly connection_id: string

    /**
     * 
     * @type {string}
     * @memberof ConnectionDataApiImportConnectionTransaction
     */
    readonly transaction_id: string
}

/**
 * Request parameters for importConnectionUser operation in ConnectionDataApi.
 * @export
 * @interface ConnectionDataApiImportConnectionUserRequest
 */
export interface ConnectionDataApiImportConnectionUserRequest {
    /**
     * 
     * @type {string}
     * @memberof ConnectionDataApiImportConnectionUser
     */
    readonly connection_id: string

    /**
     * 
     * @type {string}
     * @memberof ConnectionDataApiImportConnectionUser
     */
    readonly user_id: string
}

/**
 * Request parameters for importConnectionValuation operation in ConnectionDataApi.
 * @export
 * @interface ConnectionDataApiImportConnectionValuationRequest
 */
export interface ConnectionDataApiImportConnectionValuationRequest {
    /**
     * 
     * @type {string}
     * @memberof ConnectionDataApiImportConnectionValuation
     */
    readonly connection_id: string

    /**
     * 
     * @type {string}
     * @memberof ConnectionDataApiImportConnectionValuation
     */
    readonly valuation_id: string
}

/**
 * Request parameters for listConnectionAccounts operation in ConnectionDataApi.
 * @export
 * @interface ConnectionDataApiListConnectionAccountsRequest
 */
export interface ConnectionDataApiListConnectionAccountsRequest {
    /**
     * 
     * @type {string}
     * @memberof ConnectionDataApiListConnectionAccounts
     */
    readonly connection_id: string

    /**
     * 
     * @type {string}
     * @memberof ConnectionDataApiListConnectionAccounts
     */
    readonly connectionsync_id: string

    /**
     * 
     * @type {number}
     * @memberof ConnectionDataApiListConnectionAccounts
     */
    readonly skip?: number

    /**
     * 
     * @type {number}
     * @memberof ConnectionDataApiListConnectionAccounts
     */
    readonly limit?: number

    /**
     * 
     * @type {'created_at' | 'name'}
     * @memberof ConnectionDataApiListConnectionAccounts
     */
    readonly sort_field?: ListConnectionAccountsSortFieldEnum

    /**
     * 
     * @type {'asc' | 'desc'}
     * @memberof ConnectionDataApiListConnectionAccounts
     */
    readonly sort_direction?: ListConnectionAccountsSortDirectionEnum

    /**
     * 
     * @type {boolean}
     * @memberof ConnectionDataApiListConnectionAccounts
     */
    readonly is_auto_import_enabled?: boolean

    /**
     * 
     * @type {boolean}
     * @memberof ConnectionDataApiListConnectionAccounts
     */
    readonly is_ignored?: boolean

    /**
     * 
     * @type {string}
     * @memberof ConnectionDataApiListConnectionAccounts
     */
    readonly connection_user?: string

    /**
     * 
     * @type {ListConnectionAccountsAccountParameter}
     * @memberof ConnectionDataApiListConnectionAccounts
     */
    readonly account?: ListConnectionAccountsAccountParameter

    /**
     * 
     * @type {string}
     * @memberof ConnectionDataApiListConnectionAccounts
     */
    readonly search?: string

    /**
     * 
     * @type {boolean}
     * @memberof ConnectionDataApiListConnectionAccounts
     */
    readonly populate_account?: boolean

    /**
     * 
     * @type {boolean}
     * @memberof ConnectionDataApiListConnectionAccounts
     */
    readonly populate_connection_user?: boolean

    /**
     * 
     * @type {boolean}
     * @memberof ConnectionDataApiListConnectionAccounts
     */
    readonly populate_likely_matches?: boolean

    /**
     * 
     * @type {string}
     * @memberof ConnectionDataApiListConnectionAccounts
     */
    readonly connection_account?: string
}

/**
 * Request parameters for listConnectionCommitments operation in ConnectionDataApi.
 * @export
 * @interface ConnectionDataApiListConnectionCommitmentsRequest
 */
export interface ConnectionDataApiListConnectionCommitmentsRequest {
    /**
     * 
     * @type {string}
     * @memberof ConnectionDataApiListConnectionCommitments
     */
    readonly connection_id: string

    /**
     * 
     * @type {string}
     * @memberof ConnectionDataApiListConnectionCommitments
     */
    readonly connectionsync_id: string

    /**
     * 
     * @type {number}
     * @memberof ConnectionDataApiListConnectionCommitments
     */
    readonly skip?: number

    /**
     * 
     * @type {number}
     * @memberof ConnectionDataApiListConnectionCommitments
     */
    readonly limit?: number

    /**
     * 
     * @type {'created_at' | 'name'}
     * @memberof ConnectionDataApiListConnectionCommitments
     */
    readonly sort_field?: ListConnectionCommitmentsSortFieldEnum

    /**
     * 
     * @type {'asc' | 'desc'}
     * @memberof ConnectionDataApiListConnectionCommitments
     */
    readonly sort_direction?: ListConnectionCommitmentsSortDirectionEnum

    /**
     * 
     * @type {boolean}
     * @memberof ConnectionDataApiListConnectionCommitments
     */
    readonly is_ignored?: boolean

    /**
     * 
     * @type {ListConnectionCommitmentsCommitmentParameter}
     * @memberof ConnectionDataApiListConnectionCommitments
     */
    readonly commitment?: ListConnectionCommitmentsCommitmentParameter

    /**
     * 
     * @type {string}
     * @memberof ConnectionDataApiListConnectionCommitments
     */
    readonly connection_investment?: string

    /**
     * 
     * @type {boolean}
     * @memberof ConnectionDataApiListConnectionCommitments
     */
    readonly populate_commitment?: boolean

    /**
     * 
     * @type {boolean}
     * @memberof ConnectionDataApiListConnectionCommitments
     */
    readonly populate_connection_investment?: boolean

    /**
     * 
     * @type {boolean}
     * @memberof ConnectionDataApiListConnectionCommitments
     */
    readonly populate_likely_matches?: boolean
}

/**
 * Request parameters for listConnectionDocuments operation in ConnectionDataApi.
 * @export
 * @interface ConnectionDataApiListConnectionDocumentsRequest
 */
export interface ConnectionDataApiListConnectionDocumentsRequest {
    /**
     * 
     * @type {string}
     * @memberof ConnectionDataApiListConnectionDocuments
     */
    readonly connection_id: string

    /**
     * 
     * @type {string}
     * @memberof ConnectionDataApiListConnectionDocuments
     */
    readonly connectionsync_id: string

    /**
     * 
     * @type {number}
     * @memberof ConnectionDataApiListConnectionDocuments
     */
    readonly skip?: number

    /**
     * 
     * @type {number}
     * @memberof ConnectionDataApiListConnectionDocuments
     */
    readonly limit?: number

    /**
     * 
     * @type {'created_at' | 'name'}
     * @memberof ConnectionDataApiListConnectionDocuments
     */
    readonly sort_field?: ListConnectionDocumentsSortFieldEnum

    /**
     * 
     * @type {'asc' | 'desc'}
     * @memberof ConnectionDataApiListConnectionDocuments
     */
    readonly sort_direction?: ListConnectionDocumentsSortDirectionEnum

    /**
     * 
     * @type {boolean}
     * @memberof ConnectionDataApiListConnectionDocuments
     */
    readonly is_ignored?: boolean

    /**
     * 
     * @type {string}
     * @memberof ConnectionDataApiListConnectionDocuments
     */
    readonly connection_investment?: string

    /**
     * 
     * @type {string}
     * @memberof ConnectionDataApiListConnectionDocuments
     */
    readonly connection_account?: string

    /**
     * 
     * @type {boolean}
     * @memberof ConnectionDataApiListConnectionDocuments
     */
    readonly populate_document?: boolean

    /**
     * 
     * @type {boolean}
     * @memberof ConnectionDataApiListConnectionDocuments
     */
    readonly populate_parent?: boolean

    /**
     * 
     * @type {boolean}
     * @memberof ConnectionDataApiListConnectionDocuments
     */
    readonly populate_likely_matches?: boolean

    /**
     * 
     * @type {ListConnectionDocumentsDocumentParameter}
     * @memberof ConnectionDataApiListConnectionDocuments
     */
    readonly document?: ListConnectionDocumentsDocumentParameter

    /**
     * 
     * @type {boolean}
     * @memberof ConnectionDataApiListConnectionDocuments
     */
    readonly populate_connection_investment?: boolean

    /**
     * 
     * @type {boolean}
     * @memberof ConnectionDataApiListConnectionDocuments
     */
    readonly populate_connection_account?: boolean

    /**
     * 
     * @type {string}
     * @memberof ConnectionDataApiListConnectionDocuments
     */
    readonly document_types?: string
}

/**
 * Request parameters for listConnectionInvestments operation in ConnectionDataApi.
 * @export
 * @interface ConnectionDataApiListConnectionInvestmentsRequest
 */
export interface ConnectionDataApiListConnectionInvestmentsRequest {
    /**
     * 
     * @type {string}
     * @memberof ConnectionDataApiListConnectionInvestments
     */
    readonly connection_id: string

    /**
     * 
     * @type {string}
     * @memberof ConnectionDataApiListConnectionInvestments
     */
    readonly connectionsync_id: string

    /**
     * 
     * @type {number}
     * @memberof ConnectionDataApiListConnectionInvestments
     */
    readonly skip?: number

    /**
     * 
     * @type {number}
     * @memberof ConnectionDataApiListConnectionInvestments
     */
    readonly limit?: number

    /**
     * 
     * @type {'created_at' | 'name'}
     * @memberof ConnectionDataApiListConnectionInvestments
     */
    readonly sort_field?: ListConnectionInvestmentsSortFieldEnum

    /**
     * 
     * @type {'asc' | 'desc'}
     * @memberof ConnectionDataApiListConnectionInvestments
     */
    readonly sort_direction?: ListConnectionInvestmentsSortDirectionEnum

    /**
     * 
     * @type {boolean}
     * @memberof ConnectionDataApiListConnectionInvestments
     */
    readonly is_auto_import_enabled?: boolean

    /**
     * 
     * @type {boolean}
     * @memberof ConnectionDataApiListConnectionInvestments
     */
    readonly is_ignored?: boolean

    /**
     * 
     * @type {ListConnectionInvestmentsInvestmentParameter}
     * @memberof ConnectionDataApiListConnectionInvestments
     */
    readonly investment?: ListConnectionInvestmentsInvestmentParameter

    /**
     * 
     * @type {string}
     * @memberof ConnectionDataApiListConnectionInvestments
     */
    readonly search?: string

    /**
     * 
     * @type {string}
     * @memberof ConnectionDataApiListConnectionInvestments
     */
    readonly connection_account?: string

    /**
     * 
     * @type {boolean}
     * @memberof ConnectionDataApiListConnectionInvestments
     */
    readonly populate_investment?: boolean

    /**
     * 
     * @type {boolean}
     * @memberof ConnectionDataApiListConnectionInvestments
     */
    readonly populate_connection_account?: boolean

    /**
     * 
     * @type {boolean}
     * @memberof ConnectionDataApiListConnectionInvestments
     */
    readonly populate_likely_matches?: boolean

    /**
     * 
     * @type {string}
     * @memberof ConnectionDataApiListConnectionInvestments
     */
    readonly connection_investment?: string

    /**
     * 
     * @type {string}
     * @memberof ConnectionDataApiListConnectionInvestments
     */
    readonly investment_types?: string
}

/**
 * Request parameters for listConnectionSyncs operation in ConnectionDataApi.
 * @export
 * @interface ConnectionDataApiListConnectionSyncsRequest
 */
export interface ConnectionDataApiListConnectionSyncsRequest {
    /**
     * 
     * @type {number}
     * @memberof ConnectionDataApiListConnectionSyncs
     */
    readonly skip?: number

    /**
     * 
     * @type {number}
     * @memberof ConnectionDataApiListConnectionSyncs
     */
    readonly limit?: number

    /**
     * 
     * @type {'created_at' | 'completed_at'}
     * @memberof ConnectionDataApiListConnectionSyncs
     */
    readonly sort_field?: ListConnectionSyncsSortFieldEnum

    /**
     * 
     * @type {'asc' | 'desc'}
     * @memberof ConnectionDataApiListConnectionSyncs
     */
    readonly sort_direction?: ListConnectionSyncsSortDirectionEnum

    /**
     * 
     * @type {boolean}
     * @memberof ConnectionDataApiListConnectionSyncs
     */
    readonly populate_counts?: boolean

    /**
     * 
     * @type {boolean}
     * @memberof ConnectionDataApiListConnectionSyncs
     */
    readonly populate_connection?: boolean

    /**
     * 
     * @type {'success' | 'failure' | 'pending' | 'queued'}
     * @memberof ConnectionDataApiListConnectionSyncs
     */
    readonly exit_status?: ListConnectionSyncsExitStatusEnum

    /**
     * 
     * @type {string}
     * @memberof ConnectionDataApiListConnectionSyncs
     */
    readonly connection?: string

    /**
     * 
     * @type {string}
     * @memberof ConnectionDataApiListConnectionSyncs
     */
    readonly connection_sync_ids?: string
}

/**
 * Request parameters for listConnectionTransactions operation in ConnectionDataApi.
 * @export
 * @interface ConnectionDataApiListConnectionTransactionsRequest
 */
export interface ConnectionDataApiListConnectionTransactionsRequest {
    /**
     * 
     * @type {string}
     * @memberof ConnectionDataApiListConnectionTransactions
     */
    readonly connection_id: string

    /**
     * 
     * @type {string}
     * @memberof ConnectionDataApiListConnectionTransactions
     */
    readonly connectionsync_id: string

    /**
     * 
     * @type {number}
     * @memberof ConnectionDataApiListConnectionTransactions
     */
    readonly skip?: number

    /**
     * 
     * @type {number}
     * @memberof ConnectionDataApiListConnectionTransactions
     */
    readonly limit?: number

    /**
     * 
     * @type {'created_at' | 'name'}
     * @memberof ConnectionDataApiListConnectionTransactions
     */
    readonly sort_field?: ListConnectionTransactionsSortFieldEnum

    /**
     * 
     * @type {'asc' | 'desc'}
     * @memberof ConnectionDataApiListConnectionTransactions
     */
    readonly sort_direction?: ListConnectionTransactionsSortDirectionEnum

    /**
     * 
     * @type {boolean}
     * @memberof ConnectionDataApiListConnectionTransactions
     */
    readonly is_ignored?: boolean

    /**
     * 
     * @type {ListConnectionTransactionsTransactionParameter}
     * @memberof ConnectionDataApiListConnectionTransactions
     */
    readonly transaction?: ListConnectionTransactionsTransactionParameter

    /**
     * 
     * @type {string}
     * @memberof ConnectionDataApiListConnectionTransactions
     */
    readonly connection_investment?: string

    /**
     * 
     * @type {boolean}
     * @memberof ConnectionDataApiListConnectionTransactions
     */
    readonly populate_transaction?: boolean

    /**
     * 
     * @type {boolean}
     * @memberof ConnectionDataApiListConnectionTransactions
     */
    readonly populate_connection_investment?: boolean

    /**
     * 
     * @type {boolean}
     * @memberof ConnectionDataApiListConnectionTransactions
     */
    readonly populate_likely_matches?: boolean

    /**
     * 
     * @type {string}
     * @memberof ConnectionDataApiListConnectionTransactions
     */
    readonly transaction_types?: string
}

/**
 * Request parameters for listConnectionUsers operation in ConnectionDataApi.
 * @export
 * @interface ConnectionDataApiListConnectionUsersRequest
 */
export interface ConnectionDataApiListConnectionUsersRequest {
    /**
     * 
     * @type {string}
     * @memberof ConnectionDataApiListConnectionUsers
     */
    readonly connection_id: string

    /**
     * 
     * @type {string}
     * @memberof ConnectionDataApiListConnectionUsers
     */
    readonly connectionsync_id: string

    /**
     * 
     * @type {number}
     * @memberof ConnectionDataApiListConnectionUsers
     */
    readonly skip?: number

    /**
     * 
     * @type {number}
     * @memberof ConnectionDataApiListConnectionUsers
     */
    readonly limit?: number

    /**
     * 
     * @type {'created_at' | 'name'}
     * @memberof ConnectionDataApiListConnectionUsers
     */
    readonly sort_field?: ListConnectionUsersSortFieldEnum

    /**
     * 
     * @type {'asc' | 'desc'}
     * @memberof ConnectionDataApiListConnectionUsers
     */
    readonly sort_direction?: ListConnectionUsersSortDirectionEnum

    /**
     * 
     * @type {boolean}
     * @memberof ConnectionDataApiListConnectionUsers
     */
    readonly is_ignored?: boolean

    /**
     * 
     * @type {boolean}
     * @memberof ConnectionDataApiListConnectionUsers
     */
    readonly is_auto_import_enabled?: boolean

    /**
     * 
     * @type {ListConnectionUsersUserParameter}
     * @memberof ConnectionDataApiListConnectionUsers
     */
    readonly user?: ListConnectionUsersUserParameter

    /**
     * 
     * @type {boolean}
     * @memberof ConnectionDataApiListConnectionUsers
     */
    readonly populate_user?: boolean

    /**
     * 
     * @type {boolean}
     * @memberof ConnectionDataApiListConnectionUsers
     */
    readonly populate_likely_matches?: boolean

    /**
     * 
     * @type {string}
     * @memberof ConnectionDataApiListConnectionUsers
     */
    readonly search?: string

    /**
     * 
     * @type {string}
     * @memberof ConnectionDataApiListConnectionUsers
     */
    readonly connection_user?: string
}

/**
 * Request parameters for listConnectionValuations operation in ConnectionDataApi.
 * @export
 * @interface ConnectionDataApiListConnectionValuationsRequest
 */
export interface ConnectionDataApiListConnectionValuationsRequest {
    /**
     * 
     * @type {string}
     * @memberof ConnectionDataApiListConnectionValuations
     */
    readonly connection_id: string

    /**
     * 
     * @type {string}
     * @memberof ConnectionDataApiListConnectionValuations
     */
    readonly connectionsync_id: string

    /**
     * 
     * @type {number}
     * @memberof ConnectionDataApiListConnectionValuations
     */
    readonly skip?: number

    /**
     * 
     * @type {number}
     * @memberof ConnectionDataApiListConnectionValuations
     */
    readonly limit?: number

    /**
     * 
     * @type {'created_at' | 'name'}
     * @memberof ConnectionDataApiListConnectionValuations
     */
    readonly sort_field?: ListConnectionValuationsSortFieldEnum

    /**
     * 
     * @type {'asc' | 'desc'}
     * @memberof ConnectionDataApiListConnectionValuations
     */
    readonly sort_direction?: ListConnectionValuationsSortDirectionEnum

    /**
     * 
     * @type {boolean}
     * @memberof ConnectionDataApiListConnectionValuations
     */
    readonly is_ignored?: boolean

    /**
     * 
     * @type {ListConnectionValuationsValuationParameter}
     * @memberof ConnectionDataApiListConnectionValuations
     */
    readonly valuation?: ListConnectionValuationsValuationParameter

    /**
     * 
     * @type {string}
     * @memberof ConnectionDataApiListConnectionValuations
     */
    readonly connection_investment?: string

    /**
     * 
     * @type {boolean}
     * @memberof ConnectionDataApiListConnectionValuations
     */
    readonly populate_valuation?: boolean

    /**
     * 
     * @type {boolean}
     * @memberof ConnectionDataApiListConnectionValuations
     */
    readonly populate_connection_investment?: boolean

    /**
     * 
     * @type {boolean}
     * @memberof ConnectionDataApiListConnectionValuations
     */
    readonly populate_likely_matches?: boolean
}

/**
 * Request parameters for updateConnectionAccount operation in ConnectionDataApi.
 * @export
 * @interface ConnectionDataApiUpdateConnectionAccountRequest
 */
export interface ConnectionDataApiUpdateConnectionAccountRequest {
    /**
     * 
     * @type {string}
     * @memberof ConnectionDataApiUpdateConnectionAccount
     */
    readonly connection_id: string

    /**
     * 
     * @type {string}
     * @memberof ConnectionDataApiUpdateConnectionAccount
     */
    readonly account_id: string

    /**
     * 
     * @type {UpdateConnectionAccountRequest}
     * @memberof ConnectionDataApiUpdateConnectionAccount
     */
    readonly UpdateConnectionAccountRequest?: UpdateConnectionAccountRequest
}

/**
 * Request parameters for updateConnectionCommitment operation in ConnectionDataApi.
 * @export
 * @interface ConnectionDataApiUpdateConnectionCommitmentRequest
 */
export interface ConnectionDataApiUpdateConnectionCommitmentRequest {
    /**
     * 
     * @type {string}
     * @memberof ConnectionDataApiUpdateConnectionCommitment
     */
    readonly connection_id: string

    /**
     * 
     * @type {string}
     * @memberof ConnectionDataApiUpdateConnectionCommitment
     */
    readonly commitment_id: string

    /**
     * 
     * @type {UpdateConnectionCommitmentRequest}
     * @memberof ConnectionDataApiUpdateConnectionCommitment
     */
    readonly UpdateConnectionCommitmentRequest?: UpdateConnectionCommitmentRequest
}

/**
 * Request parameters for updateConnectionDocument operation in ConnectionDataApi.
 * @export
 * @interface ConnectionDataApiUpdateConnectionDocumentRequest
 */
export interface ConnectionDataApiUpdateConnectionDocumentRequest {
    /**
     * 
     * @type {string}
     * @memberof ConnectionDataApiUpdateConnectionDocument
     */
    readonly connection_id: string

    /**
     * 
     * @type {string}
     * @memberof ConnectionDataApiUpdateConnectionDocument
     */
    readonly document_id: string

    /**
     * 
     * @type {UpdateConnectionDocumentRequest}
     * @memberof ConnectionDataApiUpdateConnectionDocument
     */
    readonly UpdateConnectionDocumentRequest?: UpdateConnectionDocumentRequest
}

/**
 * Request parameters for updateConnectionInvestment operation in ConnectionDataApi.
 * @export
 * @interface ConnectionDataApiUpdateConnectionInvestmentRequest
 */
export interface ConnectionDataApiUpdateConnectionInvestmentRequest {
    /**
     * 
     * @type {string}
     * @memberof ConnectionDataApiUpdateConnectionInvestment
     */
    readonly connection_id: string

    /**
     * 
     * @type {string}
     * @memberof ConnectionDataApiUpdateConnectionInvestment
     */
    readonly investment_id: string

    /**
     * 
     * @type {UpdateConnectionInvestmentRequest}
     * @memberof ConnectionDataApiUpdateConnectionInvestment
     */
    readonly UpdateConnectionInvestmentRequest?: UpdateConnectionInvestmentRequest
}

/**
 * Request parameters for updateConnectionTransaction operation in ConnectionDataApi.
 * @export
 * @interface ConnectionDataApiUpdateConnectionTransactionRequest
 */
export interface ConnectionDataApiUpdateConnectionTransactionRequest {
    /**
     * 
     * @type {string}
     * @memberof ConnectionDataApiUpdateConnectionTransaction
     */
    readonly connection_id: string

    /**
     * 
     * @type {string}
     * @memberof ConnectionDataApiUpdateConnectionTransaction
     */
    readonly transaction_id: string

    /**
     * 
     * @type {UpdateConnectionTransactionRequest}
     * @memberof ConnectionDataApiUpdateConnectionTransaction
     */
    readonly UpdateConnectionTransactionRequest?: UpdateConnectionTransactionRequest
}

/**
 * Request parameters for updateConnectionUser operation in ConnectionDataApi.
 * @export
 * @interface ConnectionDataApiUpdateConnectionUserRequest
 */
export interface ConnectionDataApiUpdateConnectionUserRequest {
    /**
     * 
     * @type {string}
     * @memberof ConnectionDataApiUpdateConnectionUser
     */
    readonly connection_id: string

    /**
     * 
     * @type {string}
     * @memberof ConnectionDataApiUpdateConnectionUser
     */
    readonly user_id: string

    /**
     * 
     * @type {UpdateConnectionUserRequest}
     * @memberof ConnectionDataApiUpdateConnectionUser
     */
    readonly UpdateConnectionUserRequest?: UpdateConnectionUserRequest
}

/**
 * Request parameters for updateConnectionValuation operation in ConnectionDataApi.
 * @export
 * @interface ConnectionDataApiUpdateConnectionValuationRequest
 */
export interface ConnectionDataApiUpdateConnectionValuationRequest {
    /**
     * 
     * @type {string}
     * @memberof ConnectionDataApiUpdateConnectionValuation
     */
    readonly connection_id: string

    /**
     * 
     * @type {string}
     * @memberof ConnectionDataApiUpdateConnectionValuation
     */
    readonly valuation_id: string

    /**
     * 
     * @type {UpdateConnectionValuationRequest}
     * @memberof ConnectionDataApiUpdateConnectionValuation
     */
    readonly UpdateConnectionValuationRequest?: UpdateConnectionValuationRequest
}

/**
 * ConnectionDataApi - object-oriented interface
 * @export
 * @class ConnectionDataApi
 * @extends {BaseAPI}
 */
export class ConnectionDataApi extends BaseAPI {
    /**
     * Attempt import of a ConnectionAccount document with optional user override.
     * @param {ConnectionDataApiImportConnectionAccountRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConnectionDataApi
     */
    public importConnectionAccount(requestParameters: ConnectionDataApiImportConnectionAccountRequest, options?: RawAxiosRequestConfig) {
        return ConnectionDataApiFp(this.configuration).importConnectionAccount(requestParameters.connection_id, requestParameters.account_id, requestParameters.ImportConnectionAccountRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Attempt import of a ConnectionCommitment document with optional user override.
     * @param {ConnectionDataApiImportConnectionCommitmentRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConnectionDataApi
     */
    public importConnectionCommitment(requestParameters: ConnectionDataApiImportConnectionCommitmentRequest, options?: RawAxiosRequestConfig) {
        return ConnectionDataApiFp(this.configuration).importConnectionCommitment(requestParameters.connection_id, requestParameters.commitment_id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Attempt import of a ConnectionDocument document.
     * @param {ConnectionDataApiImportConnectionDocumentRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConnectionDataApi
     */
    public importConnectionDocument(requestParameters: ConnectionDataApiImportConnectionDocumentRequest, options?: RawAxiosRequestConfig) {
        return ConnectionDataApiFp(this.configuration).importConnectionDocument(requestParameters.connection_id, requestParameters.document_id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Attempt import of a ConnectionInvestment document with optional account override.
     * @param {ConnectionDataApiImportConnectionInvestmentRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConnectionDataApi
     */
    public importConnectionInvestment(requestParameters: ConnectionDataApiImportConnectionInvestmentRequest, options?: RawAxiosRequestConfig) {
        return ConnectionDataApiFp(this.configuration).importConnectionInvestment(requestParameters.connection_id, requestParameters.investment_id, requestParameters.ImportConnectionInvestmentRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Attempt import of a ConnectionTransaction document.
     * @param {ConnectionDataApiImportConnectionTransactionRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConnectionDataApi
     */
    public importConnectionTransaction(requestParameters: ConnectionDataApiImportConnectionTransactionRequest, options?: RawAxiosRequestConfig) {
        return ConnectionDataApiFp(this.configuration).importConnectionTransaction(requestParameters.connection_id, requestParameters.transaction_id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Attempt import of a ConnectionUser document.
     * @param {ConnectionDataApiImportConnectionUserRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConnectionDataApi
     */
    public importConnectionUser(requestParameters: ConnectionDataApiImportConnectionUserRequest, options?: RawAxiosRequestConfig) {
        return ConnectionDataApiFp(this.configuration).importConnectionUser(requestParameters.connection_id, requestParameters.user_id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Attempt import of a ConnectionValuation document.
     * @param {ConnectionDataApiImportConnectionValuationRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConnectionDataApi
     */
    public importConnectionValuation(requestParameters: ConnectionDataApiImportConnectionValuationRequest, options?: RawAxiosRequestConfig) {
        return ConnectionDataApiFp(this.configuration).importConnectionValuation(requestParameters.connection_id, requestParameters.valuation_id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * List and paginate through ConnectionAccount documents.
     * @param {ConnectionDataApiListConnectionAccountsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConnectionDataApi
     */
    public listConnectionAccounts(requestParameters: ConnectionDataApiListConnectionAccountsRequest, options?: RawAxiosRequestConfig) {
        return ConnectionDataApiFp(this.configuration).listConnectionAccounts(requestParameters.connection_id, requestParameters.connectionsync_id, requestParameters.skip, requestParameters.limit, requestParameters.sort_field, requestParameters.sort_direction, requestParameters.is_auto_import_enabled, requestParameters.is_ignored, requestParameters.connection_user, requestParameters.account, requestParameters.search, requestParameters.populate_account, requestParameters.populate_connection_user, requestParameters.populate_likely_matches, requestParameters.connection_account, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * List and paginate through ConnectionCommitment documents.
     * @param {ConnectionDataApiListConnectionCommitmentsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConnectionDataApi
     */
    public listConnectionCommitments(requestParameters: ConnectionDataApiListConnectionCommitmentsRequest, options?: RawAxiosRequestConfig) {
        return ConnectionDataApiFp(this.configuration).listConnectionCommitments(requestParameters.connection_id, requestParameters.connectionsync_id, requestParameters.skip, requestParameters.limit, requestParameters.sort_field, requestParameters.sort_direction, requestParameters.is_ignored, requestParameters.commitment, requestParameters.connection_investment, requestParameters.populate_commitment, requestParameters.populate_connection_investment, requestParameters.populate_likely_matches, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * List and paginate through ConnectionDocument documents.
     * @param {ConnectionDataApiListConnectionDocumentsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConnectionDataApi
     */
    public listConnectionDocuments(requestParameters: ConnectionDataApiListConnectionDocumentsRequest, options?: RawAxiosRequestConfig) {
        return ConnectionDataApiFp(this.configuration).listConnectionDocuments(requestParameters.connection_id, requestParameters.connectionsync_id, requestParameters.skip, requestParameters.limit, requestParameters.sort_field, requestParameters.sort_direction, requestParameters.is_ignored, requestParameters.connection_investment, requestParameters.connection_account, requestParameters.populate_document, requestParameters.populate_parent, requestParameters.populate_likely_matches, requestParameters.document, requestParameters.populate_connection_investment, requestParameters.populate_connection_account, requestParameters.document_types, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * List and paginate through ConnectionInvestment documents.
     * @param {ConnectionDataApiListConnectionInvestmentsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConnectionDataApi
     */
    public listConnectionInvestments(requestParameters: ConnectionDataApiListConnectionInvestmentsRequest, options?: RawAxiosRequestConfig) {
        return ConnectionDataApiFp(this.configuration).listConnectionInvestments(requestParameters.connection_id, requestParameters.connectionsync_id, requestParameters.skip, requestParameters.limit, requestParameters.sort_field, requestParameters.sort_direction, requestParameters.is_auto_import_enabled, requestParameters.is_ignored, requestParameters.investment, requestParameters.search, requestParameters.connection_account, requestParameters.populate_investment, requestParameters.populate_connection_account, requestParameters.populate_likely_matches, requestParameters.connection_investment, requestParameters.investment_types, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * List and paginate through ConnectionSync documents.
     * @param {ConnectionDataApiListConnectionSyncsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConnectionDataApi
     */
    public listConnectionSyncs(requestParameters: ConnectionDataApiListConnectionSyncsRequest = {}, options?: RawAxiosRequestConfig) {
        return ConnectionDataApiFp(this.configuration).listConnectionSyncs(requestParameters.skip, requestParameters.limit, requestParameters.sort_field, requestParameters.sort_direction, requestParameters.populate_counts, requestParameters.populate_connection, requestParameters.exit_status, requestParameters.connection, requestParameters.connection_sync_ids, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * List and paginate through ConnectionTransaction documents.
     * @param {ConnectionDataApiListConnectionTransactionsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConnectionDataApi
     */
    public listConnectionTransactions(requestParameters: ConnectionDataApiListConnectionTransactionsRequest, options?: RawAxiosRequestConfig) {
        return ConnectionDataApiFp(this.configuration).listConnectionTransactions(requestParameters.connection_id, requestParameters.connectionsync_id, requestParameters.skip, requestParameters.limit, requestParameters.sort_field, requestParameters.sort_direction, requestParameters.is_ignored, requestParameters.transaction, requestParameters.connection_investment, requestParameters.populate_transaction, requestParameters.populate_connection_investment, requestParameters.populate_likely_matches, requestParameters.transaction_types, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * List and paginate through ConnectionUser documents.
     * @param {ConnectionDataApiListConnectionUsersRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConnectionDataApi
     */
    public listConnectionUsers(requestParameters: ConnectionDataApiListConnectionUsersRequest, options?: RawAxiosRequestConfig) {
        return ConnectionDataApiFp(this.configuration).listConnectionUsers(requestParameters.connection_id, requestParameters.connectionsync_id, requestParameters.skip, requestParameters.limit, requestParameters.sort_field, requestParameters.sort_direction, requestParameters.is_ignored, requestParameters.is_auto_import_enabled, requestParameters.user, requestParameters.populate_user, requestParameters.populate_likely_matches, requestParameters.search, requestParameters.connection_user, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * List and paginate through ConnectionValuation documents.
     * @param {ConnectionDataApiListConnectionValuationsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConnectionDataApi
     */
    public listConnectionValuations(requestParameters: ConnectionDataApiListConnectionValuationsRequest, options?: RawAxiosRequestConfig) {
        return ConnectionDataApiFp(this.configuration).listConnectionValuations(requestParameters.connection_id, requestParameters.connectionsync_id, requestParameters.skip, requestParameters.limit, requestParameters.sort_field, requestParameters.sort_direction, requestParameters.is_ignored, requestParameters.valuation, requestParameters.connection_investment, requestParameters.populate_valuation, requestParameters.populate_connection_investment, requestParameters.populate_likely_matches, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Update a ConnectionAccount document.
     * @param {ConnectionDataApiUpdateConnectionAccountRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConnectionDataApi
     */
    public updateConnectionAccount(requestParameters: ConnectionDataApiUpdateConnectionAccountRequest, options?: RawAxiosRequestConfig) {
        return ConnectionDataApiFp(this.configuration).updateConnectionAccount(requestParameters.connection_id, requestParameters.account_id, requestParameters.UpdateConnectionAccountRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Update a ConnectionCommitment document.
     * @param {ConnectionDataApiUpdateConnectionCommitmentRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConnectionDataApi
     */
    public updateConnectionCommitment(requestParameters: ConnectionDataApiUpdateConnectionCommitmentRequest, options?: RawAxiosRequestConfig) {
        return ConnectionDataApiFp(this.configuration).updateConnectionCommitment(requestParameters.connection_id, requestParameters.commitment_id, requestParameters.UpdateConnectionCommitmentRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Update a ConnectionDocument document.
     * @param {ConnectionDataApiUpdateConnectionDocumentRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConnectionDataApi
     */
    public updateConnectionDocument(requestParameters: ConnectionDataApiUpdateConnectionDocumentRequest, options?: RawAxiosRequestConfig) {
        return ConnectionDataApiFp(this.configuration).updateConnectionDocument(requestParameters.connection_id, requestParameters.document_id, requestParameters.UpdateConnectionDocumentRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Update a ConnectionInvestment document.
     * @param {ConnectionDataApiUpdateConnectionInvestmentRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConnectionDataApi
     */
    public updateConnectionInvestment(requestParameters: ConnectionDataApiUpdateConnectionInvestmentRequest, options?: RawAxiosRequestConfig) {
        return ConnectionDataApiFp(this.configuration).updateConnectionInvestment(requestParameters.connection_id, requestParameters.investment_id, requestParameters.UpdateConnectionInvestmentRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Update a ConnectionTransaction document.
     * @param {ConnectionDataApiUpdateConnectionTransactionRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConnectionDataApi
     */
    public updateConnectionTransaction(requestParameters: ConnectionDataApiUpdateConnectionTransactionRequest, options?: RawAxiosRequestConfig) {
        return ConnectionDataApiFp(this.configuration).updateConnectionTransaction(requestParameters.connection_id, requestParameters.transaction_id, requestParameters.UpdateConnectionTransactionRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Update a ConnectionUser document.
     * @param {ConnectionDataApiUpdateConnectionUserRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConnectionDataApi
     */
    public updateConnectionUser(requestParameters: ConnectionDataApiUpdateConnectionUserRequest, options?: RawAxiosRequestConfig) {
        return ConnectionDataApiFp(this.configuration).updateConnectionUser(requestParameters.connection_id, requestParameters.user_id, requestParameters.UpdateConnectionUserRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Update a ConnectionValuation document.
     * @param {ConnectionDataApiUpdateConnectionValuationRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConnectionDataApi
     */
    public updateConnectionValuation(requestParameters: ConnectionDataApiUpdateConnectionValuationRequest, options?: RawAxiosRequestConfig) {
        return ConnectionDataApiFp(this.configuration).updateConnectionValuation(requestParameters.connection_id, requestParameters.valuation_id, requestParameters.UpdateConnectionValuationRequest, options).then((request) => request(this.axios, this.basePath));
    }
}

/**
 * @export
 */
export const ListConnectionAccountsSortFieldEnum = {
    CreatedAt: 'created_at',
    Name: 'name'
} as const;
export type ListConnectionAccountsSortFieldEnum = typeof ListConnectionAccountsSortFieldEnum[keyof typeof ListConnectionAccountsSortFieldEnum];
/**
 * @export
 */
export const ListConnectionAccountsSortDirectionEnum = {
    Asc: 'asc',
    Desc: 'desc'
} as const;
export type ListConnectionAccountsSortDirectionEnum = typeof ListConnectionAccountsSortDirectionEnum[keyof typeof ListConnectionAccountsSortDirectionEnum];
/**
 * @export
 */
export const ListConnectionCommitmentsSortFieldEnum = {
    CreatedAt: 'created_at',
    Name: 'name'
} as const;
export type ListConnectionCommitmentsSortFieldEnum = typeof ListConnectionCommitmentsSortFieldEnum[keyof typeof ListConnectionCommitmentsSortFieldEnum];
/**
 * @export
 */
export const ListConnectionCommitmentsSortDirectionEnum = {
    Asc: 'asc',
    Desc: 'desc'
} as const;
export type ListConnectionCommitmentsSortDirectionEnum = typeof ListConnectionCommitmentsSortDirectionEnum[keyof typeof ListConnectionCommitmentsSortDirectionEnum];
/**
 * @export
 */
export const ListConnectionDocumentsSortFieldEnum = {
    CreatedAt: 'created_at',
    Name: 'name'
} as const;
export type ListConnectionDocumentsSortFieldEnum = typeof ListConnectionDocumentsSortFieldEnum[keyof typeof ListConnectionDocumentsSortFieldEnum];
/**
 * @export
 */
export const ListConnectionDocumentsSortDirectionEnum = {
    Asc: 'asc',
    Desc: 'desc'
} as const;
export type ListConnectionDocumentsSortDirectionEnum = typeof ListConnectionDocumentsSortDirectionEnum[keyof typeof ListConnectionDocumentsSortDirectionEnum];
/**
 * @export
 */
export const ListConnectionInvestmentsSortFieldEnum = {
    CreatedAt: 'created_at',
    Name: 'name'
} as const;
export type ListConnectionInvestmentsSortFieldEnum = typeof ListConnectionInvestmentsSortFieldEnum[keyof typeof ListConnectionInvestmentsSortFieldEnum];
/**
 * @export
 */
export const ListConnectionInvestmentsSortDirectionEnum = {
    Asc: 'asc',
    Desc: 'desc'
} as const;
export type ListConnectionInvestmentsSortDirectionEnum = typeof ListConnectionInvestmentsSortDirectionEnum[keyof typeof ListConnectionInvestmentsSortDirectionEnum];
/**
 * @export
 */
export const ListConnectionSyncsSortFieldEnum = {
    CreatedAt: 'created_at',
    CompletedAt: 'completed_at'
} as const;
export type ListConnectionSyncsSortFieldEnum = typeof ListConnectionSyncsSortFieldEnum[keyof typeof ListConnectionSyncsSortFieldEnum];
/**
 * @export
 */
export const ListConnectionSyncsSortDirectionEnum = {
    Asc: 'asc',
    Desc: 'desc'
} as const;
export type ListConnectionSyncsSortDirectionEnum = typeof ListConnectionSyncsSortDirectionEnum[keyof typeof ListConnectionSyncsSortDirectionEnum];
/**
 * @export
 */
export const ListConnectionSyncsExitStatusEnum = {
    Success: 'success',
    Failure: 'failure',
    Pending: 'pending',
    Queued: 'queued'
} as const;
export type ListConnectionSyncsExitStatusEnum = typeof ListConnectionSyncsExitStatusEnum[keyof typeof ListConnectionSyncsExitStatusEnum];
/**
 * @export
 */
export const ListConnectionTransactionsSortFieldEnum = {
    CreatedAt: 'created_at',
    Name: 'name'
} as const;
export type ListConnectionTransactionsSortFieldEnum = typeof ListConnectionTransactionsSortFieldEnum[keyof typeof ListConnectionTransactionsSortFieldEnum];
/**
 * @export
 */
export const ListConnectionTransactionsSortDirectionEnum = {
    Asc: 'asc',
    Desc: 'desc'
} as const;
export type ListConnectionTransactionsSortDirectionEnum = typeof ListConnectionTransactionsSortDirectionEnum[keyof typeof ListConnectionTransactionsSortDirectionEnum];
/**
 * @export
 */
export const ListConnectionUsersSortFieldEnum = {
    CreatedAt: 'created_at',
    Name: 'name'
} as const;
export type ListConnectionUsersSortFieldEnum = typeof ListConnectionUsersSortFieldEnum[keyof typeof ListConnectionUsersSortFieldEnum];
/**
 * @export
 */
export const ListConnectionUsersSortDirectionEnum = {
    Asc: 'asc',
    Desc: 'desc'
} as const;
export type ListConnectionUsersSortDirectionEnum = typeof ListConnectionUsersSortDirectionEnum[keyof typeof ListConnectionUsersSortDirectionEnum];
/**
 * @export
 */
export const ListConnectionValuationsSortFieldEnum = {
    CreatedAt: 'created_at',
    Name: 'name'
} as const;
export type ListConnectionValuationsSortFieldEnum = typeof ListConnectionValuationsSortFieldEnum[keyof typeof ListConnectionValuationsSortFieldEnum];
/**
 * @export
 */
export const ListConnectionValuationsSortDirectionEnum = {
    Asc: 'asc',
    Desc: 'desc'
} as const;
export type ListConnectionValuationsSortDirectionEnum = typeof ListConnectionValuationsSortDirectionEnum[keyof typeof ListConnectionValuationsSortDirectionEnum];


/**
 * ContactApi - axios parameter creator
 * @export
 */
export const ContactApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Create a Contact document.
         * @param {CreateContactRequest} [CreateContactRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createContact: async (CreateContactRequest?: CreateContactRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v2/contacts/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication adminAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(CreateContactRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Delete a Contact document.
         * @param {string} contact_id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteContact: async (contact_id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'contact_id' is not null or undefined
            assertParamExists('deleteContact', 'contact_id', contact_id)
            const localVarPath = `/v2/contacts/{contact_id}`
                .replace(`{${"contact_id"}}`, encodeURIComponent(String(contact_id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication adminAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication advisorAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication investorAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Paginate through Contact documents.
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {ListContactsSortDirectionEnum} [sort_direction] 
         * @param {ListContactsSortFieldEnum} [sort_field] 
         * @param {string} [search] 
         * @param {Array<string>} [contact_ids] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listContacts: async (skip?: number, limit?: number, sort_direction?: ListContactsSortDirectionEnum, sort_field?: ListContactsSortFieldEnum, search?: string, contact_ids?: Array<string>, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v2/contacts/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication adminAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication advisorAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication investorAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (skip !== undefined) {
                localVarQueryParameter['skip'] = skip;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (sort_direction !== undefined) {
                localVarQueryParameter['sort_direction'] = sort_direction;
            }

            if (sort_field !== undefined) {
                localVarQueryParameter['sort_field'] = sort_field;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (contact_ids) {
                localVarQueryParameter['contact_ids'] = contact_ids;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update a Contact document.
         * @param {string} contact_id 
         * @param {UpdateContactRequest} [UpdateContactRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateContact: async (contact_id: string, UpdateContactRequest?: UpdateContactRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'contact_id' is not null or undefined
            assertParamExists('updateContact', 'contact_id', contact_id)
            const localVarPath = `/v2/contacts/{contact_id}`
                .replace(`{${"contact_id"}}`, encodeURIComponent(String(contact_id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication adminAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication advisorAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication investorAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(UpdateContactRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ContactApi - functional programming interface
 * @export
 */
export const ContactApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ContactApiAxiosParamCreator(configuration)
    return {
        /**
         * Create a Contact document.
         * @param {CreateContactRequest} [CreateContactRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createContact(CreateContactRequest?: CreateContactRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CreateContact201Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createContact(CreateContactRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ContactApi.createContact']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Delete a Contact document.
         * @param {string} contact_id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteContact(contact_id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DeleteAccount200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteContact(contact_id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ContactApi.deleteContact']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Paginate through Contact documents.
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {ListContactsSortDirectionEnum} [sort_direction] 
         * @param {ListContactsSortFieldEnum} [sort_field] 
         * @param {string} [search] 
         * @param {Array<string>} [contact_ids] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listContacts(skip?: number, limit?: number, sort_direction?: ListContactsSortDirectionEnum, sort_field?: ListContactsSortFieldEnum, search?: string, contact_ids?: Array<string>, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListContacts200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listContacts(skip, limit, sort_direction, sort_field, search, contact_ids, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ContactApi.listContacts']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Update a Contact document.
         * @param {string} contact_id 
         * @param {UpdateContactRequest} [UpdateContactRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateContact(contact_id: string, UpdateContactRequest?: UpdateContactRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DeleteAccount200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateContact(contact_id, UpdateContactRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ContactApi.updateContact']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * ContactApi - factory interface
 * @export
 */
export const ContactApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ContactApiFp(configuration)
    return {
        /**
         * Create a Contact document.
         * @param {ContactApiCreateContactRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createContact(requestParameters: ContactApiCreateContactRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<CreateContact201Response> {
            return localVarFp.createContact(requestParameters.CreateContactRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Delete a Contact document.
         * @param {ContactApiDeleteContactRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteContact(requestParameters: ContactApiDeleteContactRequest, options?: RawAxiosRequestConfig): AxiosPromise<DeleteAccount200Response> {
            return localVarFp.deleteContact(requestParameters.contact_id, options).then((request) => request(axios, basePath));
        },
        /**
         * Paginate through Contact documents.
         * @param {ContactApiListContactsRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listContacts(requestParameters: ContactApiListContactsRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<ListContacts200Response> {
            return localVarFp.listContacts(requestParameters.skip, requestParameters.limit, requestParameters.sort_direction, requestParameters.sort_field, requestParameters.search, requestParameters.contact_ids, options).then((request) => request(axios, basePath));
        },
        /**
         * Update a Contact document.
         * @param {ContactApiUpdateContactRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateContact(requestParameters: ContactApiUpdateContactRequest, options?: RawAxiosRequestConfig): AxiosPromise<DeleteAccount200Response> {
            return localVarFp.updateContact(requestParameters.contact_id, requestParameters.UpdateContactRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for createContact operation in ContactApi.
 * @export
 * @interface ContactApiCreateContactRequest
 */
export interface ContactApiCreateContactRequest {
    /**
     * 
     * @type {CreateContactRequest}
     * @memberof ContactApiCreateContact
     */
    readonly CreateContactRequest?: CreateContactRequest
}

/**
 * Request parameters for deleteContact operation in ContactApi.
 * @export
 * @interface ContactApiDeleteContactRequest
 */
export interface ContactApiDeleteContactRequest {
    /**
     * 
     * @type {string}
     * @memberof ContactApiDeleteContact
     */
    readonly contact_id: string
}

/**
 * Request parameters for listContacts operation in ContactApi.
 * @export
 * @interface ContactApiListContactsRequest
 */
export interface ContactApiListContactsRequest {
    /**
     * 
     * @type {number}
     * @memberof ContactApiListContacts
     */
    readonly skip?: number

    /**
     * 
     * @type {number}
     * @memberof ContactApiListContacts
     */
    readonly limit?: number

    /**
     * 
     * @type {'asc' | 'desc'}
     * @memberof ContactApiListContacts
     */
    readonly sort_direction?: ListContactsSortDirectionEnum

    /**
     * 
     * @type {'date' | 'name' | 'created_at' | 'updated_at'}
     * @memberof ContactApiListContacts
     */
    readonly sort_field?: ListContactsSortFieldEnum

    /**
     * 
     * @type {string}
     * @memberof ContactApiListContacts
     */
    readonly search?: string

    /**
     * 
     * @type {Array<string>}
     * @memberof ContactApiListContacts
     */
    readonly contact_ids?: Array<string>
}

/**
 * Request parameters for updateContact operation in ContactApi.
 * @export
 * @interface ContactApiUpdateContactRequest
 */
export interface ContactApiUpdateContactRequest {
    /**
     * 
     * @type {string}
     * @memberof ContactApiUpdateContact
     */
    readonly contact_id: string

    /**
     * 
     * @type {UpdateContactRequest}
     * @memberof ContactApiUpdateContact
     */
    readonly UpdateContactRequest?: UpdateContactRequest
}

/**
 * ContactApi - object-oriented interface
 * @export
 * @class ContactApi
 * @extends {BaseAPI}
 */
export class ContactApi extends BaseAPI {
    /**
     * Create a Contact document.
     * @param {ContactApiCreateContactRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContactApi
     */
    public createContact(requestParameters: ContactApiCreateContactRequest = {}, options?: RawAxiosRequestConfig) {
        return ContactApiFp(this.configuration).createContact(requestParameters.CreateContactRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Delete a Contact document.
     * @param {ContactApiDeleteContactRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContactApi
     */
    public deleteContact(requestParameters: ContactApiDeleteContactRequest, options?: RawAxiosRequestConfig) {
        return ContactApiFp(this.configuration).deleteContact(requestParameters.contact_id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Paginate through Contact documents.
     * @param {ContactApiListContactsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContactApi
     */
    public listContacts(requestParameters: ContactApiListContactsRequest = {}, options?: RawAxiosRequestConfig) {
        return ContactApiFp(this.configuration).listContacts(requestParameters.skip, requestParameters.limit, requestParameters.sort_direction, requestParameters.sort_field, requestParameters.search, requestParameters.contact_ids, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Update a Contact document.
     * @param {ContactApiUpdateContactRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContactApi
     */
    public updateContact(requestParameters: ContactApiUpdateContactRequest, options?: RawAxiosRequestConfig) {
        return ContactApiFp(this.configuration).updateContact(requestParameters.contact_id, requestParameters.UpdateContactRequest, options).then((request) => request(this.axios, this.basePath));
    }
}

/**
 * @export
 */
export const ListContactsSortDirectionEnum = {
    Asc: 'asc',
    Desc: 'desc'
} as const;
export type ListContactsSortDirectionEnum = typeof ListContactsSortDirectionEnum[keyof typeof ListContactsSortDirectionEnum];
/**
 * @export
 */
export const ListContactsSortFieldEnum = {
    Date: 'date',
    Name: 'name',
    CreatedAt: 'created_at',
    UpdatedAt: 'updated_at'
} as const;
export type ListContactsSortFieldEnum = typeof ListContactsSortFieldEnum[keyof typeof ListContactsSortFieldEnum];


/**
 * DefaultDocumentImportSettingApi - axios parameter creator
 * @export
 */
export const DefaultDocumentImportSettingApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Create a new Default Document Import Setting.
         * @param {CreateDefaultDocumentImportSettingRequest} [CreateDefaultDocumentImportSettingRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createDefaultDocumentImportSetting: async (CreateDefaultDocumentImportSettingRequest?: CreateDefaultDocumentImportSettingRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v2/defaultdocumentimportsettings/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication adminAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(CreateDefaultDocumentImportSettingRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Delete a Default Document Import Setting document.
         * @param {string} default_document_import_setting_id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteDefaultDocumentImportSetting: async (default_document_import_setting_id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'default_document_import_setting_id' is not null or undefined
            assertParamExists('deleteDefaultDocumentImportSetting', 'default_document_import_setting_id', default_document_import_setting_id)
            const localVarPath = `/v2/defaultdocumentimportsettings/{default_document_import_setting_id}`
                .replace(`{${"default_document_import_setting_id"}}`, encodeURIComponent(String(default_document_import_setting_id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication adminAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get a single Default Document Import Setting document by ID.
         * @param {string} default_document_import_setting_id 
         * @param {boolean} [populate_integration] 
         * @param {boolean} [populate_asset_manager] 
         * @param {boolean} [populate_advisory_group] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDefaultDocumentImportSetting: async (default_document_import_setting_id: string, populate_integration?: boolean, populate_asset_manager?: boolean, populate_advisory_group?: boolean, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'default_document_import_setting_id' is not null or undefined
            assertParamExists('getDefaultDocumentImportSetting', 'default_document_import_setting_id', default_document_import_setting_id)
            const localVarPath = `/v2/defaultdocumentimportsettings/{default_document_import_setting_id}`
                .replace(`{${"default_document_import_setting_id"}}`, encodeURIComponent(String(default_document_import_setting_id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication adminAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (populate_integration !== undefined) {
                localVarQueryParameter['populate_integration'] = populate_integration;
            }

            if (populate_asset_manager !== undefined) {
                localVarQueryParameter['populate_asset_manager'] = populate_asset_manager;
            }

            if (populate_advisory_group !== undefined) {
                localVarQueryParameter['populate_advisory_group'] = populate_advisory_group;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Paginate through Default Document Import Setting documents.
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {ListDefaultDocumentImportSettingsSortFieldEnum} [sort_field] 
         * @param {ListDefaultDocumentImportSettingsSortDirectionEnum} [sort_direction] 
         * @param {string} [integration] 
         * @param {ListDefaultDocumentImportSettingsGlobalDefaultTransactionSettingEnum} [global_default_transaction_setting] 
         * @param {string} [asset_manager] 
         * @param {string} [connection] 
         * @param {boolean} [populate_integration] 
         * @param {boolean} [populate_asset_manager] 
         * @param {boolean} [populate_advisory_group] 
         * @param {boolean} [include_merged_setting] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listDefaultDocumentImportSettings: async (skip?: number, limit?: number, sort_field?: ListDefaultDocumentImportSettingsSortFieldEnum, sort_direction?: ListDefaultDocumentImportSettingsSortDirectionEnum, integration?: string, global_default_transaction_setting?: ListDefaultDocumentImportSettingsGlobalDefaultTransactionSettingEnum, asset_manager?: string, connection?: string, populate_integration?: boolean, populate_asset_manager?: boolean, populate_advisory_group?: boolean, include_merged_setting?: boolean, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v2/defaultdocumentimportsettings/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication adminAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (skip !== undefined) {
                localVarQueryParameter['skip'] = skip;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (sort_field !== undefined) {
                localVarQueryParameter['sort_field'] = sort_field;
            }

            if (sort_direction !== undefined) {
                localVarQueryParameter['sort_direction'] = sort_direction;
            }

            if (integration !== undefined) {
                localVarQueryParameter['integration'] = integration;
            }

            if (global_default_transaction_setting !== undefined) {
                localVarQueryParameter['global_default_transaction_setting'] = global_default_transaction_setting;
            }

            if (asset_manager !== undefined) {
                localVarQueryParameter['asset_manager'] = asset_manager;
            }

            if (connection !== undefined) {
                localVarQueryParameter['connection'] = connection;
            }

            if (populate_integration !== undefined) {
                localVarQueryParameter['populate_integration'] = populate_integration;
            }

            if (populate_asset_manager !== undefined) {
                localVarQueryParameter['populate_asset_manager'] = populate_asset_manager;
            }

            if (populate_advisory_group !== undefined) {
                localVarQueryParameter['populate_advisory_group'] = populate_advisory_group;
            }

            if (include_merged_setting !== undefined) {
                localVarQueryParameter['include_merged_setting'] = include_merged_setting;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update an existing Default Document Import Setting document.
         * @param {string} default_document_import_setting_id 
         * @param {UpdateDefaultDocumentImportSettingRequest} [UpdateDefaultDocumentImportSettingRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateDefaultDocumentImportSetting: async (default_document_import_setting_id: string, UpdateDefaultDocumentImportSettingRequest?: UpdateDefaultDocumentImportSettingRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'default_document_import_setting_id' is not null or undefined
            assertParamExists('updateDefaultDocumentImportSetting', 'default_document_import_setting_id', default_document_import_setting_id)
            const localVarPath = `/v2/defaultdocumentimportsettings/{default_document_import_setting_id}`
                .replace(`{${"default_document_import_setting_id"}}`, encodeURIComponent(String(default_document_import_setting_id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication adminAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(UpdateDefaultDocumentImportSettingRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DefaultDocumentImportSettingApi - functional programming interface
 * @export
 */
export const DefaultDocumentImportSettingApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DefaultDocumentImportSettingApiAxiosParamCreator(configuration)
    return {
        /**
         * Create a new Default Document Import Setting.
         * @param {CreateDefaultDocumentImportSettingRequest} [CreateDefaultDocumentImportSettingRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createDefaultDocumentImportSetting(CreateDefaultDocumentImportSettingRequest?: CreateDefaultDocumentImportSettingRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CreateDefaultDocumentImportSetting201Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createDefaultDocumentImportSetting(CreateDefaultDocumentImportSettingRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultDocumentImportSettingApi.createDefaultDocumentImportSetting']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Delete a Default Document Import Setting document.
         * @param {string} default_document_import_setting_id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteDefaultDocumentImportSetting(default_document_import_setting_id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DeleteAccount200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteDefaultDocumentImportSetting(default_document_import_setting_id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultDocumentImportSettingApi.deleteDefaultDocumentImportSetting']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Get a single Default Document Import Setting document by ID.
         * @param {string} default_document_import_setting_id 
         * @param {boolean} [populate_integration] 
         * @param {boolean} [populate_asset_manager] 
         * @param {boolean} [populate_advisory_group] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDefaultDocumentImportSetting(default_document_import_setting_id: string, populate_integration?: boolean, populate_asset_manager?: boolean, populate_advisory_group?: boolean, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetDefaultDocumentImportSetting200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDefaultDocumentImportSetting(default_document_import_setting_id, populate_integration, populate_asset_manager, populate_advisory_group, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultDocumentImportSettingApi.getDefaultDocumentImportSetting']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Paginate through Default Document Import Setting documents.
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {ListDefaultDocumentImportSettingsSortFieldEnum} [sort_field] 
         * @param {ListDefaultDocumentImportSettingsSortDirectionEnum} [sort_direction] 
         * @param {string} [integration] 
         * @param {ListDefaultDocumentImportSettingsGlobalDefaultTransactionSettingEnum} [global_default_transaction_setting] 
         * @param {string} [asset_manager] 
         * @param {string} [connection] 
         * @param {boolean} [populate_integration] 
         * @param {boolean} [populate_asset_manager] 
         * @param {boolean} [populate_advisory_group] 
         * @param {boolean} [include_merged_setting] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listDefaultDocumentImportSettings(skip?: number, limit?: number, sort_field?: ListDefaultDocumentImportSettingsSortFieldEnum, sort_direction?: ListDefaultDocumentImportSettingsSortDirectionEnum, integration?: string, global_default_transaction_setting?: ListDefaultDocumentImportSettingsGlobalDefaultTransactionSettingEnum, asset_manager?: string, connection?: string, populate_integration?: boolean, populate_asset_manager?: boolean, populate_advisory_group?: boolean, include_merged_setting?: boolean, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListDefaultDocumentImportSettings200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listDefaultDocumentImportSettings(skip, limit, sort_field, sort_direction, integration, global_default_transaction_setting, asset_manager, connection, populate_integration, populate_asset_manager, populate_advisory_group, include_merged_setting, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultDocumentImportSettingApi.listDefaultDocumentImportSettings']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Update an existing Default Document Import Setting document.
         * @param {string} default_document_import_setting_id 
         * @param {UpdateDefaultDocumentImportSettingRequest} [UpdateDefaultDocumentImportSettingRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateDefaultDocumentImportSetting(default_document_import_setting_id: string, UpdateDefaultDocumentImportSettingRequest?: UpdateDefaultDocumentImportSettingRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DeleteAccount200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateDefaultDocumentImportSetting(default_document_import_setting_id, UpdateDefaultDocumentImportSettingRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultDocumentImportSettingApi.updateDefaultDocumentImportSetting']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * DefaultDocumentImportSettingApi - factory interface
 * @export
 */
export const DefaultDocumentImportSettingApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DefaultDocumentImportSettingApiFp(configuration)
    return {
        /**
         * Create a new Default Document Import Setting.
         * @param {DefaultDocumentImportSettingApiCreateDefaultDocumentImportSettingRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createDefaultDocumentImportSetting(requestParameters: DefaultDocumentImportSettingApiCreateDefaultDocumentImportSettingRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<CreateDefaultDocumentImportSetting201Response> {
            return localVarFp.createDefaultDocumentImportSetting(requestParameters.CreateDefaultDocumentImportSettingRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Delete a Default Document Import Setting document.
         * @param {DefaultDocumentImportSettingApiDeleteDefaultDocumentImportSettingRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteDefaultDocumentImportSetting(requestParameters: DefaultDocumentImportSettingApiDeleteDefaultDocumentImportSettingRequest, options?: RawAxiosRequestConfig): AxiosPromise<DeleteAccount200Response> {
            return localVarFp.deleteDefaultDocumentImportSetting(requestParameters.default_document_import_setting_id, options).then((request) => request(axios, basePath));
        },
        /**
         * Get a single Default Document Import Setting document by ID.
         * @param {DefaultDocumentImportSettingApiGetDefaultDocumentImportSettingRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDefaultDocumentImportSetting(requestParameters: DefaultDocumentImportSettingApiGetDefaultDocumentImportSettingRequest, options?: RawAxiosRequestConfig): AxiosPromise<GetDefaultDocumentImportSetting200Response> {
            return localVarFp.getDefaultDocumentImportSetting(requestParameters.default_document_import_setting_id, requestParameters.populate_integration, requestParameters.populate_asset_manager, requestParameters.populate_advisory_group, options).then((request) => request(axios, basePath));
        },
        /**
         * Paginate through Default Document Import Setting documents.
         * @param {DefaultDocumentImportSettingApiListDefaultDocumentImportSettingsRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listDefaultDocumentImportSettings(requestParameters: DefaultDocumentImportSettingApiListDefaultDocumentImportSettingsRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<ListDefaultDocumentImportSettings200Response> {
            return localVarFp.listDefaultDocumentImportSettings(requestParameters.skip, requestParameters.limit, requestParameters.sort_field, requestParameters.sort_direction, requestParameters.integration, requestParameters.global_default_transaction_setting, requestParameters.asset_manager, requestParameters.connection, requestParameters.populate_integration, requestParameters.populate_asset_manager, requestParameters.populate_advisory_group, requestParameters.include_merged_setting, options).then((request) => request(axios, basePath));
        },
        /**
         * Update an existing Default Document Import Setting document.
         * @param {DefaultDocumentImportSettingApiUpdateDefaultDocumentImportSettingRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateDefaultDocumentImportSetting(requestParameters: DefaultDocumentImportSettingApiUpdateDefaultDocumentImportSettingRequest, options?: RawAxiosRequestConfig): AxiosPromise<DeleteAccount200Response> {
            return localVarFp.updateDefaultDocumentImportSetting(requestParameters.default_document_import_setting_id, requestParameters.UpdateDefaultDocumentImportSettingRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for createDefaultDocumentImportSetting operation in DefaultDocumentImportSettingApi.
 * @export
 * @interface DefaultDocumentImportSettingApiCreateDefaultDocumentImportSettingRequest
 */
export interface DefaultDocumentImportSettingApiCreateDefaultDocumentImportSettingRequest {
    /**
     * 
     * @type {CreateDefaultDocumentImportSettingRequest}
     * @memberof DefaultDocumentImportSettingApiCreateDefaultDocumentImportSetting
     */
    readonly CreateDefaultDocumentImportSettingRequest?: CreateDefaultDocumentImportSettingRequest
}

/**
 * Request parameters for deleteDefaultDocumentImportSetting operation in DefaultDocumentImportSettingApi.
 * @export
 * @interface DefaultDocumentImportSettingApiDeleteDefaultDocumentImportSettingRequest
 */
export interface DefaultDocumentImportSettingApiDeleteDefaultDocumentImportSettingRequest {
    /**
     * 
     * @type {string}
     * @memberof DefaultDocumentImportSettingApiDeleteDefaultDocumentImportSetting
     */
    readonly default_document_import_setting_id: string
}

/**
 * Request parameters for getDefaultDocumentImportSetting operation in DefaultDocumentImportSettingApi.
 * @export
 * @interface DefaultDocumentImportSettingApiGetDefaultDocumentImportSettingRequest
 */
export interface DefaultDocumentImportSettingApiGetDefaultDocumentImportSettingRequest {
    /**
     * 
     * @type {string}
     * @memberof DefaultDocumentImportSettingApiGetDefaultDocumentImportSetting
     */
    readonly default_document_import_setting_id: string

    /**
     * 
     * @type {boolean}
     * @memberof DefaultDocumentImportSettingApiGetDefaultDocumentImportSetting
     */
    readonly populate_integration?: boolean

    /**
     * 
     * @type {boolean}
     * @memberof DefaultDocumentImportSettingApiGetDefaultDocumentImportSetting
     */
    readonly populate_asset_manager?: boolean

    /**
     * 
     * @type {boolean}
     * @memberof DefaultDocumentImportSettingApiGetDefaultDocumentImportSetting
     */
    readonly populate_advisory_group?: boolean
}

/**
 * Request parameters for listDefaultDocumentImportSettings operation in DefaultDocumentImportSettingApi.
 * @export
 * @interface DefaultDocumentImportSettingApiListDefaultDocumentImportSettingsRequest
 */
export interface DefaultDocumentImportSettingApiListDefaultDocumentImportSettingsRequest {
    /**
     * 
     * @type {number}
     * @memberof DefaultDocumentImportSettingApiListDefaultDocumentImportSettings
     */
    readonly skip?: number

    /**
     * 
     * @type {number}
     * @memberof DefaultDocumentImportSettingApiListDefaultDocumentImportSettings
     */
    readonly limit?: number

    /**
     * 
     * @type {'created_at' | 'updated_at' | 'global_default_transaction_setting'}
     * @memberof DefaultDocumentImportSettingApiListDefaultDocumentImportSettings
     */
    readonly sort_field?: ListDefaultDocumentImportSettingsSortFieldEnum

    /**
     * 
     * @type {'asc' | 'desc'}
     * @memberof DefaultDocumentImportSettingApiListDefaultDocumentImportSettings
     */
    readonly sort_direction?: ListDefaultDocumentImportSettingsSortDirectionEnum

    /**
     * 
     * @type {string}
     * @memberof DefaultDocumentImportSettingApiListDefaultDocumentImportSettings
     */
    readonly integration?: string

    /**
     * 
     * @type {'enabled' | 'disabled'}
     * @memberof DefaultDocumentImportSettingApiListDefaultDocumentImportSettings
     */
    readonly global_default_transaction_setting?: ListDefaultDocumentImportSettingsGlobalDefaultTransactionSettingEnum

    /**
     * 
     * @type {string}
     * @memberof DefaultDocumentImportSettingApiListDefaultDocumentImportSettings
     */
    readonly asset_manager?: string

    /**
     * 
     * @type {string}
     * @memberof DefaultDocumentImportSettingApiListDefaultDocumentImportSettings
     */
    readonly connection?: string

    /**
     * 
     * @type {boolean}
     * @memberof DefaultDocumentImportSettingApiListDefaultDocumentImportSettings
     */
    readonly populate_integration?: boolean

    /**
     * 
     * @type {boolean}
     * @memberof DefaultDocumentImportSettingApiListDefaultDocumentImportSettings
     */
    readonly populate_asset_manager?: boolean

    /**
     * 
     * @type {boolean}
     * @memberof DefaultDocumentImportSettingApiListDefaultDocumentImportSettings
     */
    readonly populate_advisory_group?: boolean

    /**
     * 
     * @type {boolean}
     * @memberof DefaultDocumentImportSettingApiListDefaultDocumentImportSettings
     */
    readonly include_merged_setting?: boolean
}

/**
 * Request parameters for updateDefaultDocumentImportSetting operation in DefaultDocumentImportSettingApi.
 * @export
 * @interface DefaultDocumentImportSettingApiUpdateDefaultDocumentImportSettingRequest
 */
export interface DefaultDocumentImportSettingApiUpdateDefaultDocumentImportSettingRequest {
    /**
     * 
     * @type {string}
     * @memberof DefaultDocumentImportSettingApiUpdateDefaultDocumentImportSetting
     */
    readonly default_document_import_setting_id: string

    /**
     * 
     * @type {UpdateDefaultDocumentImportSettingRequest}
     * @memberof DefaultDocumentImportSettingApiUpdateDefaultDocumentImportSetting
     */
    readonly UpdateDefaultDocumentImportSettingRequest?: UpdateDefaultDocumentImportSettingRequest
}

/**
 * DefaultDocumentImportSettingApi - object-oriented interface
 * @export
 * @class DefaultDocumentImportSettingApi
 * @extends {BaseAPI}
 */
export class DefaultDocumentImportSettingApi extends BaseAPI {
    /**
     * Create a new Default Document Import Setting.
     * @param {DefaultDocumentImportSettingApiCreateDefaultDocumentImportSettingRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultDocumentImportSettingApi
     */
    public createDefaultDocumentImportSetting(requestParameters: DefaultDocumentImportSettingApiCreateDefaultDocumentImportSettingRequest = {}, options?: RawAxiosRequestConfig) {
        return DefaultDocumentImportSettingApiFp(this.configuration).createDefaultDocumentImportSetting(requestParameters.CreateDefaultDocumentImportSettingRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Delete a Default Document Import Setting document.
     * @param {DefaultDocumentImportSettingApiDeleteDefaultDocumentImportSettingRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultDocumentImportSettingApi
     */
    public deleteDefaultDocumentImportSetting(requestParameters: DefaultDocumentImportSettingApiDeleteDefaultDocumentImportSettingRequest, options?: RawAxiosRequestConfig) {
        return DefaultDocumentImportSettingApiFp(this.configuration).deleteDefaultDocumentImportSetting(requestParameters.default_document_import_setting_id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get a single Default Document Import Setting document by ID.
     * @param {DefaultDocumentImportSettingApiGetDefaultDocumentImportSettingRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultDocumentImportSettingApi
     */
    public getDefaultDocumentImportSetting(requestParameters: DefaultDocumentImportSettingApiGetDefaultDocumentImportSettingRequest, options?: RawAxiosRequestConfig) {
        return DefaultDocumentImportSettingApiFp(this.configuration).getDefaultDocumentImportSetting(requestParameters.default_document_import_setting_id, requestParameters.populate_integration, requestParameters.populate_asset_manager, requestParameters.populate_advisory_group, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Paginate through Default Document Import Setting documents.
     * @param {DefaultDocumentImportSettingApiListDefaultDocumentImportSettingsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultDocumentImportSettingApi
     */
    public listDefaultDocumentImportSettings(requestParameters: DefaultDocumentImportSettingApiListDefaultDocumentImportSettingsRequest = {}, options?: RawAxiosRequestConfig) {
        return DefaultDocumentImportSettingApiFp(this.configuration).listDefaultDocumentImportSettings(requestParameters.skip, requestParameters.limit, requestParameters.sort_field, requestParameters.sort_direction, requestParameters.integration, requestParameters.global_default_transaction_setting, requestParameters.asset_manager, requestParameters.connection, requestParameters.populate_integration, requestParameters.populate_asset_manager, requestParameters.populate_advisory_group, requestParameters.include_merged_setting, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Update an existing Default Document Import Setting document.
     * @param {DefaultDocumentImportSettingApiUpdateDefaultDocumentImportSettingRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultDocumentImportSettingApi
     */
    public updateDefaultDocumentImportSetting(requestParameters: DefaultDocumentImportSettingApiUpdateDefaultDocumentImportSettingRequest, options?: RawAxiosRequestConfig) {
        return DefaultDocumentImportSettingApiFp(this.configuration).updateDefaultDocumentImportSetting(requestParameters.default_document_import_setting_id, requestParameters.UpdateDefaultDocumentImportSettingRequest, options).then((request) => request(this.axios, this.basePath));
    }
}

/**
 * @export
 */
export const ListDefaultDocumentImportSettingsSortFieldEnum = {
    CreatedAt: 'created_at',
    UpdatedAt: 'updated_at',
    GlobalDefaultTransactionSetting: 'global_default_transaction_setting'
} as const;
export type ListDefaultDocumentImportSettingsSortFieldEnum = typeof ListDefaultDocumentImportSettingsSortFieldEnum[keyof typeof ListDefaultDocumentImportSettingsSortFieldEnum];
/**
 * @export
 */
export const ListDefaultDocumentImportSettingsSortDirectionEnum = {
    Asc: 'asc',
    Desc: 'desc'
} as const;
export type ListDefaultDocumentImportSettingsSortDirectionEnum = typeof ListDefaultDocumentImportSettingsSortDirectionEnum[keyof typeof ListDefaultDocumentImportSettingsSortDirectionEnum];
/**
 * @export
 */
export const ListDefaultDocumentImportSettingsGlobalDefaultTransactionSettingEnum = {
    Enabled: 'enabled',
    Disabled: 'disabled'
} as const;
export type ListDefaultDocumentImportSettingsGlobalDefaultTransactionSettingEnum = typeof ListDefaultDocumentImportSettingsGlobalDefaultTransactionSettingEnum[keyof typeof ListDefaultDocumentImportSettingsGlobalDefaultTransactionSettingEnum];


/**
 * DocumentApi - axios parameter creator
 * @export
 */
export const DocumentApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Create a new Document.
         * @param {CreateDocumentRequest} CreateDocumentRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createDocument: async (CreateDocumentRequest: CreateDocumentRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'CreateDocumentRequest' is not null or undefined
            assertParamExists('createDocument', 'CreateDocumentRequest', CreateDocumentRequest)
            const localVarPath = `/v2/documents/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication adminAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication advisorAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication investorAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(CreateDocumentRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Delete a Document.
         * @param {string} document_id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteDocument: async (document_id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'document_id' is not null or undefined
            assertParamExists('deleteDocument', 'document_id', document_id)
            const localVarPath = `/v2/documents/{document_id}`
                .replace(`{${"document_id"}}`, encodeURIComponent(String(document_id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication adminAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication advisorAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication investorAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get a single Document by ID.
         * @param {string} document_id 
         * @param {boolean} [populate_user] 
         * @param {boolean} [populate_ownership] 
         * @param {boolean} [populate_connection] 
         * @param {boolean} [populate_audit_log] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDocument: async (document_id: string, populate_user?: boolean, populate_ownership?: boolean, populate_connection?: boolean, populate_audit_log?: boolean, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'document_id' is not null or undefined
            assertParamExists('getDocument', 'document_id', document_id)
            const localVarPath = `/v2/documents/{document_id}`
                .replace(`{${"document_id"}}`, encodeURIComponent(String(document_id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication adminAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication advisorAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication investorAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (populate_user !== undefined) {
                localVarQueryParameter['populate_user'] = populate_user;
            }

            if (populate_ownership !== undefined) {
                localVarQueryParameter['populate_ownership'] = populate_ownership;
            }

            if (populate_connection !== undefined) {
                localVarQueryParameter['populate_connection'] = populate_connection;
            }

            if (populate_audit_log !== undefined) {
                localVarQueryParameter['populate_audit_log'] = populate_audit_log;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Paginate through Document documents.
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {string} [search] 
         * @param {ListDocumentsSortFieldEnum} [sort_field] 
         * @param {ListDocumentsSortDirectionEnum} [sort_direction] 
         * @param {Array<string>} [users] 
         * @param {Array<string>} [ownership_ids] 
         * @param {ListDocumentsOwnershipTypeEnum} [ownership_type] 
         * @param {Array<string>} [investment_masters] 
         * @param {Array<string>} [advisory_groups] 
         * @param {boolean} [hidden] 
         * @param {boolean} [inactive] 
         * @param {Array<string>} [accounts] 
         * @param {boolean} [populate_user] 
         * @param {boolean} [populate_ownership] 
         * @param {boolean} [populate_connection] 
         * @param {boolean} [populate_audit_log] 
         * @param {boolean} [typeIsNull] 
         * @param {boolean} [hasValidMetadata] 
         * @param {boolean} [shouldGenerateTransactions] 
         * @param {boolean} [shouldProcessDocument] 
         * @param {boolean} [trainingSort] 
         * @param {boolean} [trainingExtraction] 
         * @param {boolean} [postJobReviewRequiredSort] 
         * @param {boolean} [postJobReviewRequiredExtraction] 
         * @param {Array<ListDocumentsTypesEnum>} [types] 
         * @param {Array<ListDocumentsFormTypesEnum>} [form_types] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listDocuments: async (skip?: number, limit?: number, search?: string, sort_field?: ListDocumentsSortFieldEnum, sort_direction?: ListDocumentsSortDirectionEnum, users?: Array<string>, ownership_ids?: Array<string>, ownership_type?: ListDocumentsOwnershipTypeEnum, investment_masters?: Array<string>, advisory_groups?: Array<string>, hidden?: boolean, inactive?: boolean, accounts?: Array<string>, populate_user?: boolean, populate_ownership?: boolean, populate_connection?: boolean, populate_audit_log?: boolean, typeIsNull?: boolean, hasValidMetadata?: boolean, shouldGenerateTransactions?: boolean, shouldProcessDocument?: boolean, trainingSort?: boolean, trainingExtraction?: boolean, postJobReviewRequiredSort?: boolean, postJobReviewRequiredExtraction?: boolean, types?: Array<ListDocumentsTypesEnum>, form_types?: Array<ListDocumentsFormTypesEnum>, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v2/documents/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication adminAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication advisorAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication investorAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (skip !== undefined) {
                localVarQueryParameter['skip'] = skip;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (sort_field !== undefined) {
                localVarQueryParameter['sort_field'] = sort_field;
            }

            if (sort_direction !== undefined) {
                localVarQueryParameter['sort_direction'] = sort_direction;
            }

            if (users) {
                localVarQueryParameter['users'] = users;
            }

            if (ownership_ids) {
                localVarQueryParameter['ownership_ids'] = ownership_ids;
            }

            if (ownership_type !== undefined) {
                localVarQueryParameter['ownership_type'] = ownership_type;
            }

            if (investment_masters) {
                localVarQueryParameter['investment_masters'] = investment_masters;
            }

            if (advisory_groups) {
                localVarQueryParameter['advisory_groups'] = advisory_groups;
            }

            if (hidden !== undefined) {
                localVarQueryParameter['hidden'] = hidden;
            }

            if (inactive !== undefined) {
                localVarQueryParameter['inactive'] = inactive;
            }

            if (accounts) {
                localVarQueryParameter['accounts'] = accounts;
            }

            if (populate_user !== undefined) {
                localVarQueryParameter['populate_user'] = populate_user;
            }

            if (populate_ownership !== undefined) {
                localVarQueryParameter['populate_ownership'] = populate_ownership;
            }

            if (populate_connection !== undefined) {
                localVarQueryParameter['populate_connection'] = populate_connection;
            }

            if (populate_audit_log !== undefined) {
                localVarQueryParameter['populate_audit_log'] = populate_audit_log;
            }

            if (typeIsNull !== undefined) {
                localVarQueryParameter['typeIsNull'] = typeIsNull;
            }

            if (hasValidMetadata !== undefined) {
                localVarQueryParameter['hasValidMetadata'] = hasValidMetadata;
            }

            if (shouldGenerateTransactions !== undefined) {
                localVarQueryParameter['shouldGenerateTransactions'] = shouldGenerateTransactions;
            }

            if (shouldProcessDocument !== undefined) {
                localVarQueryParameter['shouldProcessDocument'] = shouldProcessDocument;
            }

            if (trainingSort !== undefined) {
                localVarQueryParameter['trainingSort'] = trainingSort;
            }

            if (trainingExtraction !== undefined) {
                localVarQueryParameter['trainingExtraction'] = trainingExtraction;
            }

            if (postJobReviewRequiredSort !== undefined) {
                localVarQueryParameter['postJobReviewRequiredSort'] = postJobReviewRequiredSort;
            }

            if (postJobReviewRequiredExtraction !== undefined) {
                localVarQueryParameter['postJobReviewRequiredExtraction'] = postJobReviewRequiredExtraction;
            }

            if (types) {
                localVarQueryParameter['types'] = types;
            }

            if (form_types) {
                localVarQueryParameter['form_types'] = form_types;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Mark a Document as uploaded and process it accordingly.
         * @param {string} document_id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        markDocumentAsUploaded: async (document_id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'document_id' is not null or undefined
            assertParamExists('markDocumentAsUploaded', 'document_id', document_id)
            const localVarPath = `/v2/documents/{document_id}/uploaded`
                .replace(`{${"document_id"}}`, encodeURIComponent(String(document_id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication adminAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication advisorAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication investorAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Reprocess metadata for a given document.
         * @param {string} document_id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reprocessMetadata: async (document_id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'document_id' is not null or undefined
            assertParamExists('reprocessMetadata', 'document_id', document_id)
            const localVarPath = `/v2/documents/{document_id}/reprocess_metadata`
                .replace(`{${"document_id"}}`, encodeURIComponent(String(document_id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication adminAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication advisorAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication investorAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update an existing Document.
         * @param {string} document_id 
         * @param {UpdateDocumentRequest} [UpdateDocumentRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateDocument: async (document_id: string, UpdateDocumentRequest?: UpdateDocumentRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'document_id' is not null or undefined
            assertParamExists('updateDocument', 'document_id', document_id)
            const localVarPath = `/v2/documents/{document_id}`
                .replace(`{${"document_id"}}`, encodeURIComponent(String(document_id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication adminAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication advisorAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication investorAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(UpdateDocumentRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DocumentApi - functional programming interface
 * @export
 */
export const DocumentApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DocumentApiAxiosParamCreator(configuration)
    return {
        /**
         * Create a new Document.
         * @param {CreateDocumentRequest} CreateDocumentRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createDocument(CreateDocumentRequest: CreateDocumentRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CreateDocument201Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createDocument(CreateDocumentRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DocumentApi.createDocument']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Delete a Document.
         * @param {string} document_id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteDocument(document_id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DeleteAccount200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteDocument(document_id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DocumentApi.deleteDocument']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Get a single Document by ID.
         * @param {string} document_id 
         * @param {boolean} [populate_user] 
         * @param {boolean} [populate_ownership] 
         * @param {boolean} [populate_connection] 
         * @param {boolean} [populate_audit_log] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDocument(document_id: string, populate_user?: boolean, populate_ownership?: boolean, populate_connection?: boolean, populate_audit_log?: boolean, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetDocument200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDocument(document_id, populate_user, populate_ownership, populate_connection, populate_audit_log, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DocumentApi.getDocument']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Paginate through Document documents.
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {string} [search] 
         * @param {ListDocumentsSortFieldEnum} [sort_field] 
         * @param {ListDocumentsSortDirectionEnum} [sort_direction] 
         * @param {Array<string>} [users] 
         * @param {Array<string>} [ownership_ids] 
         * @param {ListDocumentsOwnershipTypeEnum} [ownership_type] 
         * @param {Array<string>} [investment_masters] 
         * @param {Array<string>} [advisory_groups] 
         * @param {boolean} [hidden] 
         * @param {boolean} [inactive] 
         * @param {Array<string>} [accounts] 
         * @param {boolean} [populate_user] 
         * @param {boolean} [populate_ownership] 
         * @param {boolean} [populate_connection] 
         * @param {boolean} [populate_audit_log] 
         * @param {boolean} [typeIsNull] 
         * @param {boolean} [hasValidMetadata] 
         * @param {boolean} [shouldGenerateTransactions] 
         * @param {boolean} [shouldProcessDocument] 
         * @param {boolean} [trainingSort] 
         * @param {boolean} [trainingExtraction] 
         * @param {boolean} [postJobReviewRequiredSort] 
         * @param {boolean} [postJobReviewRequiredExtraction] 
         * @param {Array<ListDocumentsTypesEnum>} [types] 
         * @param {Array<ListDocumentsFormTypesEnum>} [form_types] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listDocuments(skip?: number, limit?: number, search?: string, sort_field?: ListDocumentsSortFieldEnum, sort_direction?: ListDocumentsSortDirectionEnum, users?: Array<string>, ownership_ids?: Array<string>, ownership_type?: ListDocumentsOwnershipTypeEnum, investment_masters?: Array<string>, advisory_groups?: Array<string>, hidden?: boolean, inactive?: boolean, accounts?: Array<string>, populate_user?: boolean, populate_ownership?: boolean, populate_connection?: boolean, populate_audit_log?: boolean, typeIsNull?: boolean, hasValidMetadata?: boolean, shouldGenerateTransactions?: boolean, shouldProcessDocument?: boolean, trainingSort?: boolean, trainingExtraction?: boolean, postJobReviewRequiredSort?: boolean, postJobReviewRequiredExtraction?: boolean, types?: Array<ListDocumentsTypesEnum>, form_types?: Array<ListDocumentsFormTypesEnum>, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListDocuments200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listDocuments(skip, limit, search, sort_field, sort_direction, users, ownership_ids, ownership_type, investment_masters, advisory_groups, hidden, inactive, accounts, populate_user, populate_ownership, populate_connection, populate_audit_log, typeIsNull, hasValidMetadata, shouldGenerateTransactions, shouldProcessDocument, trainingSort, trainingExtraction, postJobReviewRequiredSort, postJobReviewRequiredExtraction, types, form_types, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DocumentApi.listDocuments']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Mark a Document as uploaded and process it accordingly.
         * @param {string} document_id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async markDocumentAsUploaded(document_id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SendRALRequest200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.markDocumentAsUploaded(document_id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DocumentApi.markDocumentAsUploaded']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Reprocess metadata for a given document.
         * @param {string} document_id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async reprocessMetadata(document_id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ReprocessMetadata200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.reprocessMetadata(document_id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DocumentApi.reprocessMetadata']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Update an existing Document.
         * @param {string} document_id 
         * @param {UpdateDocumentRequest} [UpdateDocumentRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateDocument(document_id: string, UpdateDocumentRequest?: UpdateDocumentRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UpdateDocument200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateDocument(document_id, UpdateDocumentRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DocumentApi.updateDocument']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * DocumentApi - factory interface
 * @export
 */
export const DocumentApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DocumentApiFp(configuration)
    return {
        /**
         * Create a new Document.
         * @param {DocumentApiCreateDocumentRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createDocument(requestParameters: DocumentApiCreateDocumentRequest, options?: RawAxiosRequestConfig): AxiosPromise<CreateDocument201Response> {
            return localVarFp.createDocument(requestParameters.CreateDocumentRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Delete a Document.
         * @param {DocumentApiDeleteDocumentRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteDocument(requestParameters: DocumentApiDeleteDocumentRequest, options?: RawAxiosRequestConfig): AxiosPromise<DeleteAccount200Response> {
            return localVarFp.deleteDocument(requestParameters.document_id, options).then((request) => request(axios, basePath));
        },
        /**
         * Get a single Document by ID.
         * @param {DocumentApiGetDocumentRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDocument(requestParameters: DocumentApiGetDocumentRequest, options?: RawAxiosRequestConfig): AxiosPromise<GetDocument200Response> {
            return localVarFp.getDocument(requestParameters.document_id, requestParameters.populate_user, requestParameters.populate_ownership, requestParameters.populate_connection, requestParameters.populate_audit_log, options).then((request) => request(axios, basePath));
        },
        /**
         * Paginate through Document documents.
         * @param {DocumentApiListDocumentsRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listDocuments(requestParameters: DocumentApiListDocumentsRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<ListDocuments200Response> {
            return localVarFp.listDocuments(requestParameters.skip, requestParameters.limit, requestParameters.search, requestParameters.sort_field, requestParameters.sort_direction, requestParameters.users, requestParameters.ownership_ids, requestParameters.ownership_type, requestParameters.investment_masters, requestParameters.advisory_groups, requestParameters.hidden, requestParameters.inactive, requestParameters.accounts, requestParameters.populate_user, requestParameters.populate_ownership, requestParameters.populate_connection, requestParameters.populate_audit_log, requestParameters.typeIsNull, requestParameters.hasValidMetadata, requestParameters.shouldGenerateTransactions, requestParameters.shouldProcessDocument, requestParameters.trainingSort, requestParameters.trainingExtraction, requestParameters.postJobReviewRequiredSort, requestParameters.postJobReviewRequiredExtraction, requestParameters.types, requestParameters.form_types, options).then((request) => request(axios, basePath));
        },
        /**
         * Mark a Document as uploaded and process it accordingly.
         * @param {DocumentApiMarkDocumentAsUploadedRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        markDocumentAsUploaded(requestParameters: DocumentApiMarkDocumentAsUploadedRequest, options?: RawAxiosRequestConfig): AxiosPromise<SendRALRequest200Response> {
            return localVarFp.markDocumentAsUploaded(requestParameters.document_id, options).then((request) => request(axios, basePath));
        },
        /**
         * Reprocess metadata for a given document.
         * @param {DocumentApiReprocessMetadataRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reprocessMetadata(requestParameters: DocumentApiReprocessMetadataRequest, options?: RawAxiosRequestConfig): AxiosPromise<ReprocessMetadata200Response> {
            return localVarFp.reprocessMetadata(requestParameters.document_id, options).then((request) => request(axios, basePath));
        },
        /**
         * Update an existing Document.
         * @param {DocumentApiUpdateDocumentRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateDocument(requestParameters: DocumentApiUpdateDocumentRequest, options?: RawAxiosRequestConfig): AxiosPromise<UpdateDocument200Response> {
            return localVarFp.updateDocument(requestParameters.document_id, requestParameters.UpdateDocumentRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for createDocument operation in DocumentApi.
 * @export
 * @interface DocumentApiCreateDocumentRequest
 */
export interface DocumentApiCreateDocumentRequest {
    /**
     * 
     * @type {CreateDocumentRequest}
     * @memberof DocumentApiCreateDocument
     */
    readonly CreateDocumentRequest: CreateDocumentRequest
}

/**
 * Request parameters for deleteDocument operation in DocumentApi.
 * @export
 * @interface DocumentApiDeleteDocumentRequest
 */
export interface DocumentApiDeleteDocumentRequest {
    /**
     * 
     * @type {string}
     * @memberof DocumentApiDeleteDocument
     */
    readonly document_id: string
}

/**
 * Request parameters for getDocument operation in DocumentApi.
 * @export
 * @interface DocumentApiGetDocumentRequest
 */
export interface DocumentApiGetDocumentRequest {
    /**
     * 
     * @type {string}
     * @memberof DocumentApiGetDocument
     */
    readonly document_id: string

    /**
     * 
     * @type {boolean}
     * @memberof DocumentApiGetDocument
     */
    readonly populate_user?: boolean

    /**
     * 
     * @type {boolean}
     * @memberof DocumentApiGetDocument
     */
    readonly populate_ownership?: boolean

    /**
     * 
     * @type {boolean}
     * @memberof DocumentApiGetDocument
     */
    readonly populate_connection?: boolean

    /**
     * 
     * @type {boolean}
     * @memberof DocumentApiGetDocument
     */
    readonly populate_audit_log?: boolean
}

/**
 * Request parameters for listDocuments operation in DocumentApi.
 * @export
 * @interface DocumentApiListDocumentsRequest
 */
export interface DocumentApiListDocumentsRequest {
    /**
     * 
     * @type {number}
     * @memberof DocumentApiListDocuments
     */
    readonly skip?: number

    /**
     * 
     * @type {number}
     * @memberof DocumentApiListDocuments
     */
    readonly limit?: number

    /**
     * 
     * @type {string}
     * @memberof DocumentApiListDocuments
     */
    readonly search?: string

    /**
     * 
     * @type {'date' | 'name' | 'type' | 'created_at' | 'updated_at' | 'ownership_type'}
     * @memberof DocumentApiListDocuments
     */
    readonly sort_field?: ListDocumentsSortFieldEnum

    /**
     * 
     * @type {'asc' | 'desc'}
     * @memberof DocumentApiListDocuments
     */
    readonly sort_direction?: ListDocumentsSortDirectionEnum

    /**
     * 
     * @type {Array<string>}
     * @memberof DocumentApiListDocuments
     */
    readonly users?: Array<string>

    /**
     * 
     * @type {Array<string>}
     * @memberof DocumentApiListDocuments
     */
    readonly ownership_ids?: Array<string>

    /**
     * 
     * @type {'Account' | 'Investment' | 'null'}
     * @memberof DocumentApiListDocuments
     */
    readonly ownership_type?: ListDocumentsOwnershipTypeEnum

    /**
     * 
     * @type {Array<string>}
     * @memberof DocumentApiListDocuments
     */
    readonly investment_masters?: Array<string>

    /**
     * 
     * @type {Array<string>}
     * @memberof DocumentApiListDocuments
     */
    readonly advisory_groups?: Array<string>

    /**
     * 
     * @type {boolean}
     * @memberof DocumentApiListDocuments
     */
    readonly hidden?: boolean

    /**
     * 
     * @type {boolean}
     * @memberof DocumentApiListDocuments
     */
    readonly inactive?: boolean

    /**
     * 
     * @type {Array<string>}
     * @memberof DocumentApiListDocuments
     */
    readonly accounts?: Array<string>

    /**
     * 
     * @type {boolean}
     * @memberof DocumentApiListDocuments
     */
    readonly populate_user?: boolean

    /**
     * 
     * @type {boolean}
     * @memberof DocumentApiListDocuments
     */
    readonly populate_ownership?: boolean

    /**
     * 
     * @type {boolean}
     * @memberof DocumentApiListDocuments
     */
    readonly populate_connection?: boolean

    /**
     * 
     * @type {boolean}
     * @memberof DocumentApiListDocuments
     */
    readonly populate_audit_log?: boolean

    /**
     * 
     * @type {boolean}
     * @memberof DocumentApiListDocuments
     */
    readonly typeIsNull?: boolean

    /**
     * 
     * @type {boolean}
     * @memberof DocumentApiListDocuments
     */
    readonly hasValidMetadata?: boolean

    /**
     * 
     * @type {boolean}
     * @memberof DocumentApiListDocuments
     */
    readonly shouldGenerateTransactions?: boolean

    /**
     * 
     * @type {boolean}
     * @memberof DocumentApiListDocuments
     */
    readonly shouldProcessDocument?: boolean

    /**
     * 
     * @type {boolean}
     * @memberof DocumentApiListDocuments
     */
    readonly trainingSort?: boolean

    /**
     * 
     * @type {boolean}
     * @memberof DocumentApiListDocuments
     */
    readonly trainingExtraction?: boolean

    /**
     * 
     * @type {boolean}
     * @memberof DocumentApiListDocuments
     */
    readonly postJobReviewRequiredSort?: boolean

    /**
     * 
     * @type {boolean}
     * @memberof DocumentApiListDocuments
     */
    readonly postJobReviewRequiredExtraction?: boolean

    /**
     * 
     * @type {Array<'null' | 'Capital Account Statement' | 'Capital Call Notice' | 'Distribution Notice' | 'Tax Document' | 'Other' | 'Net Capital Call' | 'Net Distribution' | 'Hedge Fund' | 'PPM/OM' | 'Privacy Notice' | 'Disclosure / ADV' | 'Investment Update' | 'Legal Agreement' | 'Wire Instruction / Confirmation' | 'Reporting Authorization Letter'>}
     * @memberof DocumentApiListDocuments
     */
    readonly types?: Array<ListDocumentsTypesEnum>

    /**
     * 
     * @type {Array<'K-1' | 'Estimate' | '1099' | 'W-9' | 'Other'>}
     * @memberof DocumentApiListDocuments
     */
    readonly form_types?: Array<ListDocumentsFormTypesEnum>
}

/**
 * Request parameters for markDocumentAsUploaded operation in DocumentApi.
 * @export
 * @interface DocumentApiMarkDocumentAsUploadedRequest
 */
export interface DocumentApiMarkDocumentAsUploadedRequest {
    /**
     * 
     * @type {string}
     * @memberof DocumentApiMarkDocumentAsUploaded
     */
    readonly document_id: string
}

/**
 * Request parameters for reprocessMetadata operation in DocumentApi.
 * @export
 * @interface DocumentApiReprocessMetadataRequest
 */
export interface DocumentApiReprocessMetadataRequest {
    /**
     * 
     * @type {string}
     * @memberof DocumentApiReprocessMetadata
     */
    readonly document_id: string
}

/**
 * Request parameters for updateDocument operation in DocumentApi.
 * @export
 * @interface DocumentApiUpdateDocumentRequest
 */
export interface DocumentApiUpdateDocumentRequest {
    /**
     * 
     * @type {string}
     * @memberof DocumentApiUpdateDocument
     */
    readonly document_id: string

    /**
     * 
     * @type {UpdateDocumentRequest}
     * @memberof DocumentApiUpdateDocument
     */
    readonly UpdateDocumentRequest?: UpdateDocumentRequest
}

/**
 * DocumentApi - object-oriented interface
 * @export
 * @class DocumentApi
 * @extends {BaseAPI}
 */
export class DocumentApi extends BaseAPI {
    /**
     * Create a new Document.
     * @param {DocumentApiCreateDocumentRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DocumentApi
     */
    public createDocument(requestParameters: DocumentApiCreateDocumentRequest, options?: RawAxiosRequestConfig) {
        return DocumentApiFp(this.configuration).createDocument(requestParameters.CreateDocumentRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Delete a Document.
     * @param {DocumentApiDeleteDocumentRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DocumentApi
     */
    public deleteDocument(requestParameters: DocumentApiDeleteDocumentRequest, options?: RawAxiosRequestConfig) {
        return DocumentApiFp(this.configuration).deleteDocument(requestParameters.document_id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get a single Document by ID.
     * @param {DocumentApiGetDocumentRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DocumentApi
     */
    public getDocument(requestParameters: DocumentApiGetDocumentRequest, options?: RawAxiosRequestConfig) {
        return DocumentApiFp(this.configuration).getDocument(requestParameters.document_id, requestParameters.populate_user, requestParameters.populate_ownership, requestParameters.populate_connection, requestParameters.populate_audit_log, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Paginate through Document documents.
     * @param {DocumentApiListDocumentsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DocumentApi
     */
    public listDocuments(requestParameters: DocumentApiListDocumentsRequest = {}, options?: RawAxiosRequestConfig) {
        return DocumentApiFp(this.configuration).listDocuments(requestParameters.skip, requestParameters.limit, requestParameters.search, requestParameters.sort_field, requestParameters.sort_direction, requestParameters.users, requestParameters.ownership_ids, requestParameters.ownership_type, requestParameters.investment_masters, requestParameters.advisory_groups, requestParameters.hidden, requestParameters.inactive, requestParameters.accounts, requestParameters.populate_user, requestParameters.populate_ownership, requestParameters.populate_connection, requestParameters.populate_audit_log, requestParameters.typeIsNull, requestParameters.hasValidMetadata, requestParameters.shouldGenerateTransactions, requestParameters.shouldProcessDocument, requestParameters.trainingSort, requestParameters.trainingExtraction, requestParameters.postJobReviewRequiredSort, requestParameters.postJobReviewRequiredExtraction, requestParameters.types, requestParameters.form_types, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Mark a Document as uploaded and process it accordingly.
     * @param {DocumentApiMarkDocumentAsUploadedRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DocumentApi
     */
    public markDocumentAsUploaded(requestParameters: DocumentApiMarkDocumentAsUploadedRequest, options?: RawAxiosRequestConfig) {
        return DocumentApiFp(this.configuration).markDocumentAsUploaded(requestParameters.document_id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Reprocess metadata for a given document.
     * @param {DocumentApiReprocessMetadataRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DocumentApi
     */
    public reprocessMetadata(requestParameters: DocumentApiReprocessMetadataRequest, options?: RawAxiosRequestConfig) {
        return DocumentApiFp(this.configuration).reprocessMetadata(requestParameters.document_id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Update an existing Document.
     * @param {DocumentApiUpdateDocumentRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DocumentApi
     */
    public updateDocument(requestParameters: DocumentApiUpdateDocumentRequest, options?: RawAxiosRequestConfig) {
        return DocumentApiFp(this.configuration).updateDocument(requestParameters.document_id, requestParameters.UpdateDocumentRequest, options).then((request) => request(this.axios, this.basePath));
    }
}

/**
 * @export
 */
export const ListDocumentsSortFieldEnum = {
    Date: 'date',
    Name: 'name',
    Type: 'type',
    CreatedAt: 'created_at',
    UpdatedAt: 'updated_at',
    OwnershipType: 'ownership_type'
} as const;
export type ListDocumentsSortFieldEnum = typeof ListDocumentsSortFieldEnum[keyof typeof ListDocumentsSortFieldEnum];
/**
 * @export
 */
export const ListDocumentsSortDirectionEnum = {
    Asc: 'asc',
    Desc: 'desc'
} as const;
export type ListDocumentsSortDirectionEnum = typeof ListDocumentsSortDirectionEnum[keyof typeof ListDocumentsSortDirectionEnum];
/**
 * @export
 */
export const ListDocumentsOwnershipTypeEnum = {
    Account: 'Account',
    Investment: 'Investment',
    Null: 'null'
} as const;
export type ListDocumentsOwnershipTypeEnum = typeof ListDocumentsOwnershipTypeEnum[keyof typeof ListDocumentsOwnershipTypeEnum];
/**
 * @export
 */
export const ListDocumentsTypesEnum = {
    Null: 'null',
    CapitalAccountStatement: 'Capital Account Statement',
    CapitalCallNotice: 'Capital Call Notice',
    DistributionNotice: 'Distribution Notice',
    TaxDocument: 'Tax Document',
    Other: 'Other',
    NetCapitalCall: 'Net Capital Call',
    NetDistribution: 'Net Distribution',
    HedgeFund: 'Hedge Fund',
    PpmOm: 'PPM/OM',
    PrivacyNotice: 'Privacy Notice',
    DisclosureAdv: 'Disclosure / ADV',
    InvestmentUpdate: 'Investment Update',
    LegalAgreement: 'Legal Agreement',
    WireInstructionConfirmation: 'Wire Instruction / Confirmation',
    ReportingAuthorizationLetter: 'Reporting Authorization Letter'
} as const;
export type ListDocumentsTypesEnum = typeof ListDocumentsTypesEnum[keyof typeof ListDocumentsTypesEnum];
/**
 * @export
 */
export const ListDocumentsFormTypesEnum = {
    K1: 'K-1',
    Estimate: 'Estimate',
    _1099: '1099',
    W9: 'W-9',
    Other: 'Other'
} as const;
export type ListDocumentsFormTypesEnum = typeof ListDocumentsFormTypesEnum[keyof typeof ListDocumentsFormTypesEnum];


/**
 * IntegrationApi - axios parameter creator
 * @export
 */
export const IntegrationApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Create a new Integration document.
         * @param {CreateIntegrationRequest} CreateIntegrationRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createIntegration: async (CreateIntegrationRequest: CreateIntegrationRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'CreateIntegrationRequest' is not null or undefined
            assertParamExists('createIntegration', 'CreateIntegrationRequest', CreateIntegrationRequest)
            const localVarPath = `/v2/integrations/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication adminAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(CreateIntegrationRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Delete an Integration document.
         * @param {string} integration_id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteIntegration: async (integration_id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'integration_id' is not null or undefined
            assertParamExists('deleteIntegration', 'integration_id', integration_id)
            const localVarPath = `/v2/integrations/{integration_id}`
                .replace(`{${"integration_id"}}`, encodeURIComponent(String(integration_id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication adminAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get a single Integration document by ID.
         * @param {string} integration_id 
         * @param {boolean} [populate_connection_counts] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getIntegration: async (integration_id: string, populate_connection_counts?: boolean, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'integration_id' is not null or undefined
            assertParamExists('getIntegration', 'integration_id', integration_id)
            const localVarPath = `/v2/integrations/{integration_id}`
                .replace(`{${"integration_id"}}`, encodeURIComponent(String(integration_id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication adminAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication advisorAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication investorAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (populate_connection_counts !== undefined) {
                localVarQueryParameter['populate_connection_counts'] = populate_connection_counts;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Paginate through Integration documents.
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {string} [search] 
         * @param {ListIntegrationsSortFieldEnum} [sort_field] 
         * @param {ListIntegrationsSortDirectionEnum} [sort_direction] 
         * @param {boolean} [populate_connection_counts] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listIntegrations: async (skip?: number, limit?: number, search?: string, sort_field?: ListIntegrationsSortFieldEnum, sort_direction?: ListIntegrationsSortDirectionEnum, populate_connection_counts?: boolean, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v2/integrations/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication adminAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication advisorAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication investorAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (skip !== undefined) {
                localVarQueryParameter['skip'] = skip;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (sort_field !== undefined) {
                localVarQueryParameter['sort_field'] = sort_field;
            }

            if (sort_direction !== undefined) {
                localVarQueryParameter['sort_direction'] = sort_direction;
            }

            if (populate_connection_counts !== undefined) {
                localVarQueryParameter['populate_connection_counts'] = populate_connection_counts;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update an existing Integration document.
         * @param {string} integration_id 
         * @param {UpdateIntegrationRequest} [UpdateIntegrationRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateIntegration: async (integration_id: string, UpdateIntegrationRequest?: UpdateIntegrationRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'integration_id' is not null or undefined
            assertParamExists('updateIntegration', 'integration_id', integration_id)
            const localVarPath = `/v2/integrations/{integration_id}`
                .replace(`{${"integration_id"}}`, encodeURIComponent(String(integration_id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication adminAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(UpdateIntegrationRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * IntegrationApi - functional programming interface
 * @export
 */
export const IntegrationApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = IntegrationApiAxiosParamCreator(configuration)
    return {
        /**
         * Create a new Integration document.
         * @param {CreateIntegrationRequest} CreateIntegrationRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createIntegration(CreateIntegrationRequest: CreateIntegrationRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CreateIntegration201Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createIntegration(CreateIntegrationRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['IntegrationApi.createIntegration']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Delete an Integration document.
         * @param {string} integration_id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteIntegration(integration_id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DeleteAccount200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteIntegration(integration_id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['IntegrationApi.deleteIntegration']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Get a single Integration document by ID.
         * @param {string} integration_id 
         * @param {boolean} [populate_connection_counts] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getIntegration(integration_id: string, populate_connection_counts?: boolean, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetIntegration200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getIntegration(integration_id, populate_connection_counts, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['IntegrationApi.getIntegration']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Paginate through Integration documents.
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {string} [search] 
         * @param {ListIntegrationsSortFieldEnum} [sort_field] 
         * @param {ListIntegrationsSortDirectionEnum} [sort_direction] 
         * @param {boolean} [populate_connection_counts] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listIntegrations(skip?: number, limit?: number, search?: string, sort_field?: ListIntegrationsSortFieldEnum, sort_direction?: ListIntegrationsSortDirectionEnum, populate_connection_counts?: boolean, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListIntegrations200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listIntegrations(skip, limit, search, sort_field, sort_direction, populate_connection_counts, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['IntegrationApi.listIntegrations']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Update an existing Integration document.
         * @param {string} integration_id 
         * @param {UpdateIntegrationRequest} [UpdateIntegrationRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateIntegration(integration_id: string, UpdateIntegrationRequest?: UpdateIntegrationRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DeleteAccount200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateIntegration(integration_id, UpdateIntegrationRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['IntegrationApi.updateIntegration']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * IntegrationApi - factory interface
 * @export
 */
export const IntegrationApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = IntegrationApiFp(configuration)
    return {
        /**
         * Create a new Integration document.
         * @param {IntegrationApiCreateIntegrationRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createIntegration(requestParameters: IntegrationApiCreateIntegrationRequest, options?: RawAxiosRequestConfig): AxiosPromise<CreateIntegration201Response> {
            return localVarFp.createIntegration(requestParameters.CreateIntegrationRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Delete an Integration document.
         * @param {IntegrationApiDeleteIntegrationRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteIntegration(requestParameters: IntegrationApiDeleteIntegrationRequest, options?: RawAxiosRequestConfig): AxiosPromise<DeleteAccount200Response> {
            return localVarFp.deleteIntegration(requestParameters.integration_id, options).then((request) => request(axios, basePath));
        },
        /**
         * Get a single Integration document by ID.
         * @param {IntegrationApiGetIntegrationRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getIntegration(requestParameters: IntegrationApiGetIntegrationRequest, options?: RawAxiosRequestConfig): AxiosPromise<GetIntegration200Response> {
            return localVarFp.getIntegration(requestParameters.integration_id, requestParameters.populate_connection_counts, options).then((request) => request(axios, basePath));
        },
        /**
         * Paginate through Integration documents.
         * @param {IntegrationApiListIntegrationsRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listIntegrations(requestParameters: IntegrationApiListIntegrationsRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<ListIntegrations200Response> {
            return localVarFp.listIntegrations(requestParameters.skip, requestParameters.limit, requestParameters.search, requestParameters.sort_field, requestParameters.sort_direction, requestParameters.populate_connection_counts, options).then((request) => request(axios, basePath));
        },
        /**
         * Update an existing Integration document.
         * @param {IntegrationApiUpdateIntegrationRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateIntegration(requestParameters: IntegrationApiUpdateIntegrationRequest, options?: RawAxiosRequestConfig): AxiosPromise<DeleteAccount200Response> {
            return localVarFp.updateIntegration(requestParameters.integration_id, requestParameters.UpdateIntegrationRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for createIntegration operation in IntegrationApi.
 * @export
 * @interface IntegrationApiCreateIntegrationRequest
 */
export interface IntegrationApiCreateIntegrationRequest {
    /**
     * 
     * @type {CreateIntegrationRequest}
     * @memberof IntegrationApiCreateIntegration
     */
    readonly CreateIntegrationRequest: CreateIntegrationRequest
}

/**
 * Request parameters for deleteIntegration operation in IntegrationApi.
 * @export
 * @interface IntegrationApiDeleteIntegrationRequest
 */
export interface IntegrationApiDeleteIntegrationRequest {
    /**
     * 
     * @type {string}
     * @memberof IntegrationApiDeleteIntegration
     */
    readonly integration_id: string
}

/**
 * Request parameters for getIntegration operation in IntegrationApi.
 * @export
 * @interface IntegrationApiGetIntegrationRequest
 */
export interface IntegrationApiGetIntegrationRequest {
    /**
     * 
     * @type {string}
     * @memberof IntegrationApiGetIntegration
     */
    readonly integration_id: string

    /**
     * 
     * @type {boolean}
     * @memberof IntegrationApiGetIntegration
     */
    readonly populate_connection_counts?: boolean
}

/**
 * Request parameters for listIntegrations operation in IntegrationApi.
 * @export
 * @interface IntegrationApiListIntegrationsRequest
 */
export interface IntegrationApiListIntegrationsRequest {
    /**
     * 
     * @type {number}
     * @memberof IntegrationApiListIntegrations
     */
    readonly skip?: number

    /**
     * 
     * @type {number}
     * @memberof IntegrationApiListIntegrations
     */
    readonly limit?: number

    /**
     * 
     * @type {string}
     * @memberof IntegrationApiListIntegrations
     */
    readonly search?: string

    /**
     * 
     * @type {'created_at' | 'name' | 'worker_version' | 'has_worker'}
     * @memberof IntegrationApiListIntegrations
     */
    readonly sort_field?: ListIntegrationsSortFieldEnum

    /**
     * 
     * @type {'asc' | 'desc'}
     * @memberof IntegrationApiListIntegrations
     */
    readonly sort_direction?: ListIntegrationsSortDirectionEnum

    /**
     * 
     * @type {boolean}
     * @memberof IntegrationApiListIntegrations
     */
    readonly populate_connection_counts?: boolean
}

/**
 * Request parameters for updateIntegration operation in IntegrationApi.
 * @export
 * @interface IntegrationApiUpdateIntegrationRequest
 */
export interface IntegrationApiUpdateIntegrationRequest {
    /**
     * 
     * @type {string}
     * @memberof IntegrationApiUpdateIntegration
     */
    readonly integration_id: string

    /**
     * 
     * @type {UpdateIntegrationRequest}
     * @memberof IntegrationApiUpdateIntegration
     */
    readonly UpdateIntegrationRequest?: UpdateIntegrationRequest
}

/**
 * IntegrationApi - object-oriented interface
 * @export
 * @class IntegrationApi
 * @extends {BaseAPI}
 */
export class IntegrationApi extends BaseAPI {
    /**
     * Create a new Integration document.
     * @param {IntegrationApiCreateIntegrationRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IntegrationApi
     */
    public createIntegration(requestParameters: IntegrationApiCreateIntegrationRequest, options?: RawAxiosRequestConfig) {
        return IntegrationApiFp(this.configuration).createIntegration(requestParameters.CreateIntegrationRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Delete an Integration document.
     * @param {IntegrationApiDeleteIntegrationRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IntegrationApi
     */
    public deleteIntegration(requestParameters: IntegrationApiDeleteIntegrationRequest, options?: RawAxiosRequestConfig) {
        return IntegrationApiFp(this.configuration).deleteIntegration(requestParameters.integration_id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get a single Integration document by ID.
     * @param {IntegrationApiGetIntegrationRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IntegrationApi
     */
    public getIntegration(requestParameters: IntegrationApiGetIntegrationRequest, options?: RawAxiosRequestConfig) {
        return IntegrationApiFp(this.configuration).getIntegration(requestParameters.integration_id, requestParameters.populate_connection_counts, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Paginate through Integration documents.
     * @param {IntegrationApiListIntegrationsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IntegrationApi
     */
    public listIntegrations(requestParameters: IntegrationApiListIntegrationsRequest = {}, options?: RawAxiosRequestConfig) {
        return IntegrationApiFp(this.configuration).listIntegrations(requestParameters.skip, requestParameters.limit, requestParameters.search, requestParameters.sort_field, requestParameters.sort_direction, requestParameters.populate_connection_counts, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Update an existing Integration document.
     * @param {IntegrationApiUpdateIntegrationRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IntegrationApi
     */
    public updateIntegration(requestParameters: IntegrationApiUpdateIntegrationRequest, options?: RawAxiosRequestConfig) {
        return IntegrationApiFp(this.configuration).updateIntegration(requestParameters.integration_id, requestParameters.UpdateIntegrationRequest, options).then((request) => request(this.axios, this.basePath));
    }
}

/**
 * @export
 */
export const ListIntegrationsSortFieldEnum = {
    CreatedAt: 'created_at',
    Name: 'name',
    WorkerVersion: 'worker_version',
    HasWorker: 'has_worker'
} as const;
export type ListIntegrationsSortFieldEnum = typeof ListIntegrationsSortFieldEnum[keyof typeof ListIntegrationsSortFieldEnum];
/**
 * @export
 */
export const ListIntegrationsSortDirectionEnum = {
    Asc: 'asc',
    Desc: 'desc'
} as const;
export type ListIntegrationsSortDirectionEnum = typeof ListIntegrationsSortDirectionEnum[keyof typeof ListIntegrationsSortDirectionEnum];


/**
 * InvestmentApi - axios parameter creator
 * @export
 */
export const InvestmentApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Create a new Investment document.
         * @param {CreateInvestmentRequest} CreateInvestmentRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createInvestment: async (CreateInvestmentRequest: CreateInvestmentRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'CreateInvestmentRequest' is not null or undefined
            assertParamExists('createInvestment', 'CreateInvestmentRequest', CreateInvestmentRequest)
            const localVarPath = `/v2/investments/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication adminAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication advisorAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication investorAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(CreateInvestmentRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Delete an Investment document.
         * @param {string} investment_id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteInvestment: async (investment_id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'investment_id' is not null or undefined
            assertParamExists('deleteInvestment', 'investment_id', investment_id)
            const localVarPath = `/v2/investments/{investment_id}`
                .replace(`{${"investment_id"}}`, encodeURIComponent(String(investment_id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication adminAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication advisorAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication investorAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get a single Investment document by ID.
         * @param {string} investment_id 
         * @param {boolean} [populate_user] 
         * @param {boolean} [populate_account] 
         * @param {boolean} [populate_audit_log] 
         * @param {boolean} [populate_investment_master] 
         * @param {boolean} [populate_connection] 
         * @param {boolean} [populate_integration_logo] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInvestment: async (investment_id: string, populate_user?: boolean, populate_account?: boolean, populate_audit_log?: boolean, populate_investment_master?: boolean, populate_connection?: boolean, populate_integration_logo?: boolean, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'investment_id' is not null or undefined
            assertParamExists('getInvestment', 'investment_id', investment_id)
            const localVarPath = `/v2/investments/{investment_id}`
                .replace(`{${"investment_id"}}`, encodeURIComponent(String(investment_id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication adminAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication advisorAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication investorAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (populate_user !== undefined) {
                localVarQueryParameter['populate_user'] = populate_user;
            }

            if (populate_account !== undefined) {
                localVarQueryParameter['populate_account'] = populate_account;
            }

            if (populate_audit_log !== undefined) {
                localVarQueryParameter['populate_audit_log'] = populate_audit_log;
            }

            if (populate_investment_master !== undefined) {
                localVarQueryParameter['populate_investment_master'] = populate_investment_master;
            }

            if (populate_connection !== undefined) {
                localVarQueryParameter['populate_connection'] = populate_connection;
            }

            if (populate_integration_logo !== undefined) {
                localVarQueryParameter['populate_integration_logo'] = populate_integration_logo;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieve JSON metadata information about historical statements for a given Investment.
         * @param {string} investment_id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getStatementHistory: async (investment_id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'investment_id' is not null or undefined
            assertParamExists('getStatementHistory', 'investment_id', investment_id)
            const localVarPath = `/v2/investments/{investment_id}/get_statement_history`
                .replace(`{${"investment_id"}}`, encodeURIComponent(String(investment_id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication adminAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication advisorAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication investorAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Paginate through Investment documents.
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {string} [search] 
         * @param {ListInvestmentsSortFieldEnum} [sort_field] 
         * @param {ListInvestmentsSortDirectionEnum} [sort_direction] 
         * @param {Array<string>} [users] 
         * @param {Array<string>} [accounts] 
         * @param {Array<string>} [connections] 
         * @param {Array<string>} [investments] 
         * @param {Array<string>} [investment_masters] 
         * @param {boolean} [hidden] 
         * @param {boolean} [incomplete] 
         * @param {boolean} [inactive] 
         * @param {boolean} [exclude_from_billing] 
         * @param {boolean} [populate_user] 
         * @param {boolean} [populate_account] 
         * @param {boolean} [populate_audit_log] 
         * @param {boolean} [populate_investment_master] 
         * @param {boolean} [populate_connection] 
         * @param {boolean} [populate_integration_logo] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listInvestments: async (skip?: number, limit?: number, search?: string, sort_field?: ListInvestmentsSortFieldEnum, sort_direction?: ListInvestmentsSortDirectionEnum, users?: Array<string>, accounts?: Array<string>, connections?: Array<string>, investments?: Array<string>, investment_masters?: Array<string>, hidden?: boolean, incomplete?: boolean, inactive?: boolean, exclude_from_billing?: boolean, populate_user?: boolean, populate_account?: boolean, populate_audit_log?: boolean, populate_investment_master?: boolean, populate_connection?: boolean, populate_integration_logo?: boolean, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v2/investments/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication adminAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication advisorAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication investorAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (skip !== undefined) {
                localVarQueryParameter['skip'] = skip;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (sort_field !== undefined) {
                localVarQueryParameter['sort_field'] = sort_field;
            }

            if (sort_direction !== undefined) {
                localVarQueryParameter['sort_direction'] = sort_direction;
            }

            if (users) {
                localVarQueryParameter['users'] = users;
            }

            if (accounts) {
                localVarQueryParameter['accounts'] = accounts;
            }

            if (connections) {
                localVarQueryParameter['connections'] = connections;
            }

            if (investments) {
                localVarQueryParameter['investments'] = investments;
            }

            if (investment_masters) {
                localVarQueryParameter['investment_masters'] = investment_masters;
            }

            if (hidden !== undefined) {
                localVarQueryParameter['hidden'] = hidden;
            }

            if (incomplete !== undefined) {
                localVarQueryParameter['incomplete'] = incomplete;
            }

            if (inactive !== undefined) {
                localVarQueryParameter['inactive'] = inactive;
            }

            if (exclude_from_billing !== undefined) {
                localVarQueryParameter['exclude_from_billing'] = exclude_from_billing;
            }

            if (populate_user !== undefined) {
                localVarQueryParameter['populate_user'] = populate_user;
            }

            if (populate_account !== undefined) {
                localVarQueryParameter['populate_account'] = populate_account;
            }

            if (populate_audit_log !== undefined) {
                localVarQueryParameter['populate_audit_log'] = populate_audit_log;
            }

            if (populate_investment_master !== undefined) {
                localVarQueryParameter['populate_investment_master'] = populate_investment_master;
            }

            if (populate_connection !== undefined) {
                localVarQueryParameter['populate_connection'] = populate_connection;
            }

            if (populate_integration_logo !== undefined) {
                localVarQueryParameter['populate_integration_logo'] = populate_integration_logo;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update an existing Investment document.
         * @param {string} investment_id 
         * @param {UpdateInvestmentRequest} [UpdateInvestmentRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateInvestment: async (investment_id: string, UpdateInvestmentRequest?: UpdateInvestmentRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'investment_id' is not null or undefined
            assertParamExists('updateInvestment', 'investment_id', investment_id)
            const localVarPath = `/v2/investments/{investment_id}`
                .replace(`{${"investment_id"}}`, encodeURIComponent(String(investment_id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication adminAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication advisorAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication investorAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(UpdateInvestmentRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * InvestmentApi - functional programming interface
 * @export
 */
export const InvestmentApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = InvestmentApiAxiosParamCreator(configuration)
    return {
        /**
         * Create a new Investment document.
         * @param {CreateInvestmentRequest} CreateInvestmentRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createInvestment(CreateInvestmentRequest: CreateInvestmentRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CreateInvestment201Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createInvestment(CreateInvestmentRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['InvestmentApi.createInvestment']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Delete an Investment document.
         * @param {string} investment_id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteInvestment(investment_id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DeleteAccount200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteInvestment(investment_id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['InvestmentApi.deleteInvestment']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Get a single Investment document by ID.
         * @param {string} investment_id 
         * @param {boolean} [populate_user] 
         * @param {boolean} [populate_account] 
         * @param {boolean} [populate_audit_log] 
         * @param {boolean} [populate_investment_master] 
         * @param {boolean} [populate_connection] 
         * @param {boolean} [populate_integration_logo] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getInvestment(investment_id: string, populate_user?: boolean, populate_account?: boolean, populate_audit_log?: boolean, populate_investment_master?: boolean, populate_connection?: boolean, populate_integration_logo?: boolean, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetInvestment200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getInvestment(investment_id, populate_user, populate_account, populate_audit_log, populate_investment_master, populate_connection, populate_integration_logo, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['InvestmentApi.getInvestment']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Retrieve JSON metadata information about historical statements for a given Investment.
         * @param {string} investment_id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getStatementHistory(investment_id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetStatementHistory200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getStatementHistory(investment_id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['InvestmentApi.getStatementHistory']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Paginate through Investment documents.
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {string} [search] 
         * @param {ListInvestmentsSortFieldEnum} [sort_field] 
         * @param {ListInvestmentsSortDirectionEnum} [sort_direction] 
         * @param {Array<string>} [users] 
         * @param {Array<string>} [accounts] 
         * @param {Array<string>} [connections] 
         * @param {Array<string>} [investments] 
         * @param {Array<string>} [investment_masters] 
         * @param {boolean} [hidden] 
         * @param {boolean} [incomplete] 
         * @param {boolean} [inactive] 
         * @param {boolean} [exclude_from_billing] 
         * @param {boolean} [populate_user] 
         * @param {boolean} [populate_account] 
         * @param {boolean} [populate_audit_log] 
         * @param {boolean} [populate_investment_master] 
         * @param {boolean} [populate_connection] 
         * @param {boolean} [populate_integration_logo] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listInvestments(skip?: number, limit?: number, search?: string, sort_field?: ListInvestmentsSortFieldEnum, sort_direction?: ListInvestmentsSortDirectionEnum, users?: Array<string>, accounts?: Array<string>, connections?: Array<string>, investments?: Array<string>, investment_masters?: Array<string>, hidden?: boolean, incomplete?: boolean, inactive?: boolean, exclude_from_billing?: boolean, populate_user?: boolean, populate_account?: boolean, populate_audit_log?: boolean, populate_investment_master?: boolean, populate_connection?: boolean, populate_integration_logo?: boolean, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListInvestments200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listInvestments(skip, limit, search, sort_field, sort_direction, users, accounts, connections, investments, investment_masters, hidden, incomplete, inactive, exclude_from_billing, populate_user, populate_account, populate_audit_log, populate_investment_master, populate_connection, populate_integration_logo, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['InvestmentApi.listInvestments']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Update an existing Investment document.
         * @param {string} investment_id 
         * @param {UpdateInvestmentRequest} [UpdateInvestmentRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateInvestment(investment_id: string, UpdateInvestmentRequest?: UpdateInvestmentRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DeleteAccount200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateInvestment(investment_id, UpdateInvestmentRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['InvestmentApi.updateInvestment']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * InvestmentApi - factory interface
 * @export
 */
export const InvestmentApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = InvestmentApiFp(configuration)
    return {
        /**
         * Create a new Investment document.
         * @param {InvestmentApiCreateInvestmentRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createInvestment(requestParameters: InvestmentApiCreateInvestmentRequest, options?: RawAxiosRequestConfig): AxiosPromise<CreateInvestment201Response> {
            return localVarFp.createInvestment(requestParameters.CreateInvestmentRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Delete an Investment document.
         * @param {InvestmentApiDeleteInvestmentRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteInvestment(requestParameters: InvestmentApiDeleteInvestmentRequest, options?: RawAxiosRequestConfig): AxiosPromise<DeleteAccount200Response> {
            return localVarFp.deleteInvestment(requestParameters.investment_id, options).then((request) => request(axios, basePath));
        },
        /**
         * Get a single Investment document by ID.
         * @param {InvestmentApiGetInvestmentRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInvestment(requestParameters: InvestmentApiGetInvestmentRequest, options?: RawAxiosRequestConfig): AxiosPromise<GetInvestment200Response> {
            return localVarFp.getInvestment(requestParameters.investment_id, requestParameters.populate_user, requestParameters.populate_account, requestParameters.populate_audit_log, requestParameters.populate_investment_master, requestParameters.populate_connection, requestParameters.populate_integration_logo, options).then((request) => request(axios, basePath));
        },
        /**
         * Retrieve JSON metadata information about historical statements for a given Investment.
         * @param {InvestmentApiGetStatementHistoryRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getStatementHistory(requestParameters: InvestmentApiGetStatementHistoryRequest, options?: RawAxiosRequestConfig): AxiosPromise<GetStatementHistory200Response> {
            return localVarFp.getStatementHistory(requestParameters.investment_id, options).then((request) => request(axios, basePath));
        },
        /**
         * Paginate through Investment documents.
         * @param {InvestmentApiListInvestmentsRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listInvestments(requestParameters: InvestmentApiListInvestmentsRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<ListInvestments200Response> {
            return localVarFp.listInvestments(requestParameters.skip, requestParameters.limit, requestParameters.search, requestParameters.sort_field, requestParameters.sort_direction, requestParameters.users, requestParameters.accounts, requestParameters.connections, requestParameters.investments, requestParameters.investment_masters, requestParameters.hidden, requestParameters.incomplete, requestParameters.inactive, requestParameters.exclude_from_billing, requestParameters.populate_user, requestParameters.populate_account, requestParameters.populate_audit_log, requestParameters.populate_investment_master, requestParameters.populate_connection, requestParameters.populate_integration_logo, options).then((request) => request(axios, basePath));
        },
        /**
         * Update an existing Investment document.
         * @param {InvestmentApiUpdateInvestmentRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateInvestment(requestParameters: InvestmentApiUpdateInvestmentRequest, options?: RawAxiosRequestConfig): AxiosPromise<DeleteAccount200Response> {
            return localVarFp.updateInvestment(requestParameters.investment_id, requestParameters.UpdateInvestmentRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for createInvestment operation in InvestmentApi.
 * @export
 * @interface InvestmentApiCreateInvestmentRequest
 */
export interface InvestmentApiCreateInvestmentRequest {
    /**
     * 
     * @type {CreateInvestmentRequest}
     * @memberof InvestmentApiCreateInvestment
     */
    readonly CreateInvestmentRequest: CreateInvestmentRequest
}

/**
 * Request parameters for deleteInvestment operation in InvestmentApi.
 * @export
 * @interface InvestmentApiDeleteInvestmentRequest
 */
export interface InvestmentApiDeleteInvestmentRequest {
    /**
     * 
     * @type {string}
     * @memberof InvestmentApiDeleteInvestment
     */
    readonly investment_id: string
}

/**
 * Request parameters for getInvestment operation in InvestmentApi.
 * @export
 * @interface InvestmentApiGetInvestmentRequest
 */
export interface InvestmentApiGetInvestmentRequest {
    /**
     * 
     * @type {string}
     * @memberof InvestmentApiGetInvestment
     */
    readonly investment_id: string

    /**
     * 
     * @type {boolean}
     * @memberof InvestmentApiGetInvestment
     */
    readonly populate_user?: boolean

    /**
     * 
     * @type {boolean}
     * @memberof InvestmentApiGetInvestment
     */
    readonly populate_account?: boolean

    /**
     * 
     * @type {boolean}
     * @memberof InvestmentApiGetInvestment
     */
    readonly populate_audit_log?: boolean

    /**
     * 
     * @type {boolean}
     * @memberof InvestmentApiGetInvestment
     */
    readonly populate_investment_master?: boolean

    /**
     * 
     * @type {boolean}
     * @memberof InvestmentApiGetInvestment
     */
    readonly populate_connection?: boolean

    /**
     * 
     * @type {boolean}
     * @memberof InvestmentApiGetInvestment
     */
    readonly populate_integration_logo?: boolean
}

/**
 * Request parameters for getStatementHistory operation in InvestmentApi.
 * @export
 * @interface InvestmentApiGetStatementHistoryRequest
 */
export interface InvestmentApiGetStatementHistoryRequest {
    /**
     * 
     * @type {string}
     * @memberof InvestmentApiGetStatementHistory
     */
    readonly investment_id: string
}

/**
 * Request parameters for listInvestments operation in InvestmentApi.
 * @export
 * @interface InvestmentApiListInvestmentsRequest
 */
export interface InvestmentApiListInvestmentsRequest {
    /**
     * 
     * @type {number}
     * @memberof InvestmentApiListInvestments
     */
    readonly skip?: number

    /**
     * 
     * @type {number}
     * @memberof InvestmentApiListInvestments
     */
    readonly limit?: number

    /**
     * 
     * @type {string}
     * @memberof InvestmentApiListInvestments
     */
    readonly search?: string

    /**
     * 
     * @type {'created_at' | 'name' | 'type' | 'date' | 'inactive' | 'started_at' | 'hidden'}
     * @memberof InvestmentApiListInvestments
     */
    readonly sort_field?: ListInvestmentsSortFieldEnum

    /**
     * 
     * @type {'asc' | 'desc'}
     * @memberof InvestmentApiListInvestments
     */
    readonly sort_direction?: ListInvestmentsSortDirectionEnum

    /**
     * 
     * @type {Array<string>}
     * @memberof InvestmentApiListInvestments
     */
    readonly users?: Array<string>

    /**
     * 
     * @type {Array<string>}
     * @memberof InvestmentApiListInvestments
     */
    readonly accounts?: Array<string>

    /**
     * 
     * @type {Array<string>}
     * @memberof InvestmentApiListInvestments
     */
    readonly connections?: Array<string>

    /**
     * 
     * @type {Array<string>}
     * @memberof InvestmentApiListInvestments
     */
    readonly investments?: Array<string>

    /**
     * 
     * @type {Array<string>}
     * @memberof InvestmentApiListInvestments
     */
    readonly investment_masters?: Array<string>

    /**
     * 
     * @type {boolean}
     * @memberof InvestmentApiListInvestments
     */
    readonly hidden?: boolean

    /**
     * 
     * @type {boolean}
     * @memberof InvestmentApiListInvestments
     */
    readonly incomplete?: boolean

    /**
     * 
     * @type {boolean}
     * @memberof InvestmentApiListInvestments
     */
    readonly inactive?: boolean

    /**
     * 
     * @type {boolean}
     * @memberof InvestmentApiListInvestments
     */
    readonly exclude_from_billing?: boolean

    /**
     * 
     * @type {boolean}
     * @memberof InvestmentApiListInvestments
     */
    readonly populate_user?: boolean

    /**
     * 
     * @type {boolean}
     * @memberof InvestmentApiListInvestments
     */
    readonly populate_account?: boolean

    /**
     * 
     * @type {boolean}
     * @memberof InvestmentApiListInvestments
     */
    readonly populate_audit_log?: boolean

    /**
     * 
     * @type {boolean}
     * @memberof InvestmentApiListInvestments
     */
    readonly populate_investment_master?: boolean

    /**
     * 
     * @type {boolean}
     * @memberof InvestmentApiListInvestments
     */
    readonly populate_connection?: boolean

    /**
     * 
     * @type {boolean}
     * @memberof InvestmentApiListInvestments
     */
    readonly populate_integration_logo?: boolean
}

/**
 * Request parameters for updateInvestment operation in InvestmentApi.
 * @export
 * @interface InvestmentApiUpdateInvestmentRequest
 */
export interface InvestmentApiUpdateInvestmentRequest {
    /**
     * 
     * @type {string}
     * @memberof InvestmentApiUpdateInvestment
     */
    readonly investment_id: string

    /**
     * 
     * @type {UpdateInvestmentRequest}
     * @memberof InvestmentApiUpdateInvestment
     */
    readonly UpdateInvestmentRequest?: UpdateInvestmentRequest
}

/**
 * InvestmentApi - object-oriented interface
 * @export
 * @class InvestmentApi
 * @extends {BaseAPI}
 */
export class InvestmentApi extends BaseAPI {
    /**
     * Create a new Investment document.
     * @param {InvestmentApiCreateInvestmentRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvestmentApi
     */
    public createInvestment(requestParameters: InvestmentApiCreateInvestmentRequest, options?: RawAxiosRequestConfig) {
        return InvestmentApiFp(this.configuration).createInvestment(requestParameters.CreateInvestmentRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Delete an Investment document.
     * @param {InvestmentApiDeleteInvestmentRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvestmentApi
     */
    public deleteInvestment(requestParameters: InvestmentApiDeleteInvestmentRequest, options?: RawAxiosRequestConfig) {
        return InvestmentApiFp(this.configuration).deleteInvestment(requestParameters.investment_id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get a single Investment document by ID.
     * @param {InvestmentApiGetInvestmentRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvestmentApi
     */
    public getInvestment(requestParameters: InvestmentApiGetInvestmentRequest, options?: RawAxiosRequestConfig) {
        return InvestmentApiFp(this.configuration).getInvestment(requestParameters.investment_id, requestParameters.populate_user, requestParameters.populate_account, requestParameters.populate_audit_log, requestParameters.populate_investment_master, requestParameters.populate_connection, requestParameters.populate_integration_logo, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieve JSON metadata information about historical statements for a given Investment.
     * @param {InvestmentApiGetStatementHistoryRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvestmentApi
     */
    public getStatementHistory(requestParameters: InvestmentApiGetStatementHistoryRequest, options?: RawAxiosRequestConfig) {
        return InvestmentApiFp(this.configuration).getStatementHistory(requestParameters.investment_id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Paginate through Investment documents.
     * @param {InvestmentApiListInvestmentsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvestmentApi
     */
    public listInvestments(requestParameters: InvestmentApiListInvestmentsRequest = {}, options?: RawAxiosRequestConfig) {
        return InvestmentApiFp(this.configuration).listInvestments(requestParameters.skip, requestParameters.limit, requestParameters.search, requestParameters.sort_field, requestParameters.sort_direction, requestParameters.users, requestParameters.accounts, requestParameters.connections, requestParameters.investments, requestParameters.investment_masters, requestParameters.hidden, requestParameters.incomplete, requestParameters.inactive, requestParameters.exclude_from_billing, requestParameters.populate_user, requestParameters.populate_account, requestParameters.populate_audit_log, requestParameters.populate_investment_master, requestParameters.populate_connection, requestParameters.populate_integration_logo, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Update an existing Investment document.
     * @param {InvestmentApiUpdateInvestmentRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvestmentApi
     */
    public updateInvestment(requestParameters: InvestmentApiUpdateInvestmentRequest, options?: RawAxiosRequestConfig) {
        return InvestmentApiFp(this.configuration).updateInvestment(requestParameters.investment_id, requestParameters.UpdateInvestmentRequest, options).then((request) => request(this.axios, this.basePath));
    }
}

/**
 * @export
 */
export const ListInvestmentsSortFieldEnum = {
    CreatedAt: 'created_at',
    Name: 'name',
    Type: 'type',
    Date: 'date',
    Inactive: 'inactive',
    StartedAt: 'started_at',
    Hidden: 'hidden'
} as const;
export type ListInvestmentsSortFieldEnum = typeof ListInvestmentsSortFieldEnum[keyof typeof ListInvestmentsSortFieldEnum];
/**
 * @export
 */
export const ListInvestmentsSortDirectionEnum = {
    Asc: 'asc',
    Desc: 'desc'
} as const;
export type ListInvestmentsSortDirectionEnum = typeof ListInvestmentsSortDirectionEnum[keyof typeof ListInvestmentsSortDirectionEnum];


/**
 * MessagesApi - axios parameter creator
 * @export
 */
export const MessagesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Get a message by its ID
         * @param {string} message_id 
         * @param {boolean} [populate_account] 
         * @param {boolean} [populate_user] 
         * @param {boolean} [populate_connection] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMessageById: async (message_id: string, populate_account?: boolean, populate_user?: boolean, populate_connection?: boolean, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'message_id' is not null or undefined
            assertParamExists('getMessageById', 'message_id', message_id)
            const localVarPath = `/v2/messages/{message_id}`
                .replace(`{${"message_id"}}`, encodeURIComponent(String(message_id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication adminAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication advisorAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication investorAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (populate_account !== undefined) {
                localVarQueryParameter['populate_account'] = populate_account;
            }

            if (populate_user !== undefined) {
                localVarQueryParameter['populate_user'] = populate_user;
            }

            if (populate_connection !== undefined) {
                localVarQueryParameter['populate_connection'] = populate_connection;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get messages
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {boolean} [populate_user] 
         * @param {boolean} [populate_account] 
         * @param {boolean} [populate_connection] 
         * @param {ListMessagesSortFieldEnum} [sort_field] 
         * @param {ListMessagesSortDirectionEnum} [sort_direction] 
         * @param {Array<string>} [advisory_groups] 
         * @param {Array<string>} [accounts] 
         * @param {Array<string>} [connections] 
         * @param {Array<string>} [message_ids] 
         * @param {string} [search] 
         * @param {ListMessagesHasAttachmentsEnum} [has_attachments] 
         * @param {ListMessagesHasConnectionEnum} [has_connection] 
         * @param {ListMessagesMetadataInvestorReadEnum} [metadata_investor_read] 
         * @param {ListMessagesMetadataInvestorFlaggedEnum} [metadata_investor_flagged] 
         * @param {ListMessagesMetadataAdvisorReadEnum} [metadata_advisor_read] 
         * @param {ListMessagesMetadataAdvisorFlaggedEnum} [metadata_advisor_flagged] 
         * @param {ListMessagesMetadataAdminReadEnum} [metadata_admin_read] 
         * @param {ListMessagesMetadataAdminFlaggedEnum} [metadata_admin_flagged] 
         * @param {ListMessagesCategoryEnum} [category] 
         * @param {string} [date] 
         * @param {string} [start] 
         * @param {string} [end] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listMessages: async (skip?: number, limit?: number, populate_user?: boolean, populate_account?: boolean, populate_connection?: boolean, sort_field?: ListMessagesSortFieldEnum, sort_direction?: ListMessagesSortDirectionEnum, advisory_groups?: Array<string>, accounts?: Array<string>, connections?: Array<string>, message_ids?: Array<string>, search?: string, has_attachments?: ListMessagesHasAttachmentsEnum, has_connection?: ListMessagesHasConnectionEnum, metadata_investor_read?: ListMessagesMetadataInvestorReadEnum, metadata_investor_flagged?: ListMessagesMetadataInvestorFlaggedEnum, metadata_advisor_read?: ListMessagesMetadataAdvisorReadEnum, metadata_advisor_flagged?: ListMessagesMetadataAdvisorFlaggedEnum, metadata_admin_read?: ListMessagesMetadataAdminReadEnum, metadata_admin_flagged?: ListMessagesMetadataAdminFlaggedEnum, category?: ListMessagesCategoryEnum, date?: string, start?: string, end?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v2/messages/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication adminAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication advisorAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication investorAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (skip !== undefined) {
                localVarQueryParameter['skip'] = skip;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (populate_user !== undefined) {
                localVarQueryParameter['populate_user'] = populate_user;
            }

            if (populate_account !== undefined) {
                localVarQueryParameter['populate_account'] = populate_account;
            }

            if (populate_connection !== undefined) {
                localVarQueryParameter['populate_connection'] = populate_connection;
            }

            if (sort_field !== undefined) {
                localVarQueryParameter['sort_field'] = sort_field;
            }

            if (sort_direction !== undefined) {
                localVarQueryParameter['sort_direction'] = sort_direction;
            }

            if (advisory_groups) {
                localVarQueryParameter['advisory_groups'] = advisory_groups;
            }

            if (accounts) {
                localVarQueryParameter['accounts'] = accounts;
            }

            if (connections) {
                localVarQueryParameter['connections'] = connections;
            }

            if (message_ids) {
                localVarQueryParameter['message_ids'] = message_ids;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (has_attachments !== undefined) {
                localVarQueryParameter['has_attachments'] = has_attachments;
            }

            if (has_connection !== undefined) {
                localVarQueryParameter['has_connection'] = has_connection;
            }

            if (metadata_investor_read !== undefined) {
                localVarQueryParameter['metadata.investor.read'] = metadata_investor_read;
            }

            if (metadata_investor_flagged !== undefined) {
                localVarQueryParameter['metadata.investor.flagged'] = metadata_investor_flagged;
            }

            if (metadata_advisor_read !== undefined) {
                localVarQueryParameter['metadata.advisor.read'] = metadata_advisor_read;
            }

            if (metadata_advisor_flagged !== undefined) {
                localVarQueryParameter['metadata.advisor.flagged'] = metadata_advisor_flagged;
            }

            if (metadata_admin_read !== undefined) {
                localVarQueryParameter['metadata.admin.read'] = metadata_admin_read;
            }

            if (metadata_admin_flagged !== undefined) {
                localVarQueryParameter['metadata.admin.flagged'] = metadata_admin_flagged;
            }

            if (category !== undefined) {
                localVarQueryParameter['category'] = category;
            }

            if (date !== undefined) {
                localVarQueryParameter['date'] = (date as any instanceof Date) ?
                    (date as any).toISOString() :
                    date;
            }

            if (start !== undefined) {
                localVarQueryParameter['start'] = (start as any instanceof Date) ?
                    (start as any).toISOString().substring(0,10) :
                    start;
            }

            if (end !== undefined) {
                localVarQueryParameter['end'] = (end as any instanceof Date) ?
                    (end as any).toISOString().substring(0,10) :
                    end;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update a Message document.
         * @param {string} message_id 
         * @param {UpdateMessageRequest} [UpdateMessageRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateMessage: async (message_id: string, UpdateMessageRequest?: UpdateMessageRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'message_id' is not null or undefined
            assertParamExists('updateMessage', 'message_id', message_id)
            const localVarPath = `/v2/messages/{message_id}`
                .replace(`{${"message_id"}}`, encodeURIComponent(String(message_id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication adminAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication advisorAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication investorAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(UpdateMessageRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * MessagesApi - functional programming interface
 * @export
 */
export const MessagesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = MessagesApiAxiosParamCreator(configuration)
    return {
        /**
         * Get a message by its ID
         * @param {string} message_id 
         * @param {boolean} [populate_account] 
         * @param {boolean} [populate_user] 
         * @param {boolean} [populate_connection] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getMessageById(message_id: string, populate_account?: boolean, populate_user?: boolean, populate_connection?: boolean, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetMessageById200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getMessageById(message_id, populate_account, populate_user, populate_connection, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['MessagesApi.getMessageById']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Get messages
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {boolean} [populate_user] 
         * @param {boolean} [populate_account] 
         * @param {boolean} [populate_connection] 
         * @param {ListMessagesSortFieldEnum} [sort_field] 
         * @param {ListMessagesSortDirectionEnum} [sort_direction] 
         * @param {Array<string>} [advisory_groups] 
         * @param {Array<string>} [accounts] 
         * @param {Array<string>} [connections] 
         * @param {Array<string>} [message_ids] 
         * @param {string} [search] 
         * @param {ListMessagesHasAttachmentsEnum} [has_attachments] 
         * @param {ListMessagesHasConnectionEnum} [has_connection] 
         * @param {ListMessagesMetadataInvestorReadEnum} [metadata_investor_read] 
         * @param {ListMessagesMetadataInvestorFlaggedEnum} [metadata_investor_flagged] 
         * @param {ListMessagesMetadataAdvisorReadEnum} [metadata_advisor_read] 
         * @param {ListMessagesMetadataAdvisorFlaggedEnum} [metadata_advisor_flagged] 
         * @param {ListMessagesMetadataAdminReadEnum} [metadata_admin_read] 
         * @param {ListMessagesMetadataAdminFlaggedEnum} [metadata_admin_flagged] 
         * @param {ListMessagesCategoryEnum} [category] 
         * @param {string} [date] 
         * @param {string} [start] 
         * @param {string} [end] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listMessages(skip?: number, limit?: number, populate_user?: boolean, populate_account?: boolean, populate_connection?: boolean, sort_field?: ListMessagesSortFieldEnum, sort_direction?: ListMessagesSortDirectionEnum, advisory_groups?: Array<string>, accounts?: Array<string>, connections?: Array<string>, message_ids?: Array<string>, search?: string, has_attachments?: ListMessagesHasAttachmentsEnum, has_connection?: ListMessagesHasConnectionEnum, metadata_investor_read?: ListMessagesMetadataInvestorReadEnum, metadata_investor_flagged?: ListMessagesMetadataInvestorFlaggedEnum, metadata_advisor_read?: ListMessagesMetadataAdvisorReadEnum, metadata_advisor_flagged?: ListMessagesMetadataAdvisorFlaggedEnum, metadata_admin_read?: ListMessagesMetadataAdminReadEnum, metadata_admin_flagged?: ListMessagesMetadataAdminFlaggedEnum, category?: ListMessagesCategoryEnum, date?: string, start?: string, end?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListMessages200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listMessages(skip, limit, populate_user, populate_account, populate_connection, sort_field, sort_direction, advisory_groups, accounts, connections, message_ids, search, has_attachments, has_connection, metadata_investor_read, metadata_investor_flagged, metadata_advisor_read, metadata_advisor_flagged, metadata_admin_read, metadata_admin_flagged, category, date, start, end, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['MessagesApi.listMessages']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Update a Message document.
         * @param {string} message_id 
         * @param {UpdateMessageRequest} [UpdateMessageRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateMessage(message_id: string, UpdateMessageRequest?: UpdateMessageRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UpdateMessage200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateMessage(message_id, UpdateMessageRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['MessagesApi.updateMessage']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * MessagesApi - factory interface
 * @export
 */
export const MessagesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = MessagesApiFp(configuration)
    return {
        /**
         * Get a message by its ID
         * @param {MessagesApiGetMessageByIdRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMessageById(requestParameters: MessagesApiGetMessageByIdRequest, options?: RawAxiosRequestConfig): AxiosPromise<GetMessageById200Response> {
            return localVarFp.getMessageById(requestParameters.message_id, requestParameters.populate_account, requestParameters.populate_user, requestParameters.populate_connection, options).then((request) => request(axios, basePath));
        },
        /**
         * Get messages
         * @param {MessagesApiListMessagesRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listMessages(requestParameters: MessagesApiListMessagesRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<ListMessages200Response> {
            return localVarFp.listMessages(requestParameters.skip, requestParameters.limit, requestParameters.populate_user, requestParameters.populate_account, requestParameters.populate_connection, requestParameters.sort_field, requestParameters.sort_direction, requestParameters.advisory_groups, requestParameters.accounts, requestParameters.connections, requestParameters.message_ids, requestParameters.search, requestParameters.has_attachments, requestParameters.has_connection, requestParameters.metadata_investor_read, requestParameters.metadata_investor_flagged, requestParameters.metadata_advisor_read, requestParameters.metadata_advisor_flagged, requestParameters.metadata_admin_read, requestParameters.metadata_admin_flagged, requestParameters.category, requestParameters.date, requestParameters.start, requestParameters.end, options).then((request) => request(axios, basePath));
        },
        /**
         * Update a Message document.
         * @param {MessagesApiUpdateMessageRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateMessage(requestParameters: MessagesApiUpdateMessageRequest, options?: RawAxiosRequestConfig): AxiosPromise<UpdateMessage200Response> {
            return localVarFp.updateMessage(requestParameters.message_id, requestParameters.UpdateMessageRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for getMessageById operation in MessagesApi.
 * @export
 * @interface MessagesApiGetMessageByIdRequest
 */
export interface MessagesApiGetMessageByIdRequest {
    /**
     * 
     * @type {string}
     * @memberof MessagesApiGetMessageById
     */
    readonly message_id: string

    /**
     * 
     * @type {boolean}
     * @memberof MessagesApiGetMessageById
     */
    readonly populate_account?: boolean

    /**
     * 
     * @type {boolean}
     * @memberof MessagesApiGetMessageById
     */
    readonly populate_user?: boolean

    /**
     * 
     * @type {boolean}
     * @memberof MessagesApiGetMessageById
     */
    readonly populate_connection?: boolean
}

/**
 * Request parameters for listMessages operation in MessagesApi.
 * @export
 * @interface MessagesApiListMessagesRequest
 */
export interface MessagesApiListMessagesRequest {
    /**
     * 
     * @type {number}
     * @memberof MessagesApiListMessages
     */
    readonly skip?: number

    /**
     * 
     * @type {number}
     * @memberof MessagesApiListMessages
     */
    readonly limit?: number

    /**
     * 
     * @type {boolean}
     * @memberof MessagesApiListMessages
     */
    readonly populate_user?: boolean

    /**
     * 
     * @type {boolean}
     * @memberof MessagesApiListMessages
     */
    readonly populate_account?: boolean

    /**
     * 
     * @type {boolean}
     * @memberof MessagesApiListMessages
     */
    readonly populate_connection?: boolean

    /**
     * 
     * @type {'date' | 'updated_at' | 'created_at' | 'subject' | 'connection' | 'account' | 'user' | 'from' | 'to' | 'metadata.admin.read' | 'metadata.admin.flagged' | 'metadata.advisor.read' | 'metadata.advisor.flagged' | 'metadata.investor.read' | 'metadata.investor.flagged'}
     * @memberof MessagesApiListMessages
     */
    readonly sort_field?: ListMessagesSortFieldEnum

    /**
     * 
     * @type {'asc' | 'desc'}
     * @memberof MessagesApiListMessages
     */
    readonly sort_direction?: ListMessagesSortDirectionEnum

    /**
     * 
     * @type {Array<string>}
     * @memberof MessagesApiListMessages
     */
    readonly advisory_groups?: Array<string>

    /**
     * 
     * @type {Array<string>}
     * @memberof MessagesApiListMessages
     */
    readonly accounts?: Array<string>

    /**
     * 
     * @type {Array<string>}
     * @memberof MessagesApiListMessages
     */
    readonly connections?: Array<string>

    /**
     * 
     * @type {Array<string>}
     * @memberof MessagesApiListMessages
     */
    readonly message_ids?: Array<string>

    /**
     * 
     * @type {string}
     * @memberof MessagesApiListMessages
     */
    readonly search?: string

    /**
     * 
     * @type {'true' | 'false'}
     * @memberof MessagesApiListMessages
     */
    readonly has_attachments?: ListMessagesHasAttachmentsEnum

    /**
     * 
     * @type {'true' | 'false'}
     * @memberof MessagesApiListMessages
     */
    readonly has_connection?: ListMessagesHasConnectionEnum

    /**
     * 
     * @type {'true' | 'false'}
     * @memberof MessagesApiListMessages
     */
    readonly metadata_investor_read?: ListMessagesMetadataInvestorReadEnum

    /**
     * 
     * @type {'true' | 'false'}
     * @memberof MessagesApiListMessages
     */
    readonly metadata_investor_flagged?: ListMessagesMetadataInvestorFlaggedEnum

    /**
     * 
     * @type {'true' | 'false'}
     * @memberof MessagesApiListMessages
     */
    readonly metadata_advisor_read?: ListMessagesMetadataAdvisorReadEnum

    /**
     * 
     * @type {'true' | 'false'}
     * @memberof MessagesApiListMessages
     */
    readonly metadata_advisor_flagged?: ListMessagesMetadataAdvisorFlaggedEnum

    /**
     * 
     * @type {'true' | 'false'}
     * @memberof MessagesApiListMessages
     */
    readonly metadata_admin_read?: ListMessagesMetadataAdminReadEnum

    /**
     * 
     * @type {'true' | 'false'}
     * @memberof MessagesApiListMessages
     */
    readonly metadata_admin_flagged?: ListMessagesMetadataAdminFlaggedEnum

    /**
     * 
     * @type {'null' | 'tfa'}
     * @memberof MessagesApiListMessages
     */
    readonly category?: ListMessagesCategoryEnum

    /**
     * 
     * @type {string}
     * @memberof MessagesApiListMessages
     */
    readonly date?: string

    /**
     * 
     * @type {string}
     * @memberof MessagesApiListMessages
     */
    readonly start?: string

    /**
     * 
     * @type {string}
     * @memberof MessagesApiListMessages
     */
    readonly end?: string
}

/**
 * Request parameters for updateMessage operation in MessagesApi.
 * @export
 * @interface MessagesApiUpdateMessageRequest
 */
export interface MessagesApiUpdateMessageRequest {
    /**
     * 
     * @type {string}
     * @memberof MessagesApiUpdateMessage
     */
    readonly message_id: string

    /**
     * 
     * @type {UpdateMessageRequest}
     * @memberof MessagesApiUpdateMessage
     */
    readonly UpdateMessageRequest?: UpdateMessageRequest
}

/**
 * MessagesApi - object-oriented interface
 * @export
 * @class MessagesApi
 * @extends {BaseAPI}
 */
export class MessagesApi extends BaseAPI {
    /**
     * Get a message by its ID
     * @param {MessagesApiGetMessageByIdRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MessagesApi
     */
    public getMessageById(requestParameters: MessagesApiGetMessageByIdRequest, options?: RawAxiosRequestConfig) {
        return MessagesApiFp(this.configuration).getMessageById(requestParameters.message_id, requestParameters.populate_account, requestParameters.populate_user, requestParameters.populate_connection, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get messages
     * @param {MessagesApiListMessagesRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MessagesApi
     */
    public listMessages(requestParameters: MessagesApiListMessagesRequest = {}, options?: RawAxiosRequestConfig) {
        return MessagesApiFp(this.configuration).listMessages(requestParameters.skip, requestParameters.limit, requestParameters.populate_user, requestParameters.populate_account, requestParameters.populate_connection, requestParameters.sort_field, requestParameters.sort_direction, requestParameters.advisory_groups, requestParameters.accounts, requestParameters.connections, requestParameters.message_ids, requestParameters.search, requestParameters.has_attachments, requestParameters.has_connection, requestParameters.metadata_investor_read, requestParameters.metadata_investor_flagged, requestParameters.metadata_advisor_read, requestParameters.metadata_advisor_flagged, requestParameters.metadata_admin_read, requestParameters.metadata_admin_flagged, requestParameters.category, requestParameters.date, requestParameters.start, requestParameters.end, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Update a Message document.
     * @param {MessagesApiUpdateMessageRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MessagesApi
     */
    public updateMessage(requestParameters: MessagesApiUpdateMessageRequest, options?: RawAxiosRequestConfig) {
        return MessagesApiFp(this.configuration).updateMessage(requestParameters.message_id, requestParameters.UpdateMessageRequest, options).then((request) => request(this.axios, this.basePath));
    }
}

/**
 * @export
 */
export const ListMessagesSortFieldEnum = {
    Date: 'date',
    UpdatedAt: 'updated_at',
    CreatedAt: 'created_at',
    Subject: 'subject',
    Connection: 'connection',
    Account: 'account',
    User: 'user',
    From: 'from',
    To: 'to',
    MetadataAdminRead: 'metadata.admin.read',
    MetadataAdminFlagged: 'metadata.admin.flagged',
    MetadataAdvisorRead: 'metadata.advisor.read',
    MetadataAdvisorFlagged: 'metadata.advisor.flagged',
    MetadataInvestorRead: 'metadata.investor.read',
    MetadataInvestorFlagged: 'metadata.investor.flagged'
} as const;
export type ListMessagesSortFieldEnum = typeof ListMessagesSortFieldEnum[keyof typeof ListMessagesSortFieldEnum];
/**
 * @export
 */
export const ListMessagesSortDirectionEnum = {
    Asc: 'asc',
    Desc: 'desc'
} as const;
export type ListMessagesSortDirectionEnum = typeof ListMessagesSortDirectionEnum[keyof typeof ListMessagesSortDirectionEnum];
/**
 * @export
 */
export const ListMessagesHasAttachmentsEnum = {
    True: 'true',
    False: 'false'
} as const;
export type ListMessagesHasAttachmentsEnum = typeof ListMessagesHasAttachmentsEnum[keyof typeof ListMessagesHasAttachmentsEnum];
/**
 * @export
 */
export const ListMessagesHasConnectionEnum = {
    True: 'true',
    False: 'false'
} as const;
export type ListMessagesHasConnectionEnum = typeof ListMessagesHasConnectionEnum[keyof typeof ListMessagesHasConnectionEnum];
/**
 * @export
 */
export const ListMessagesMetadataInvestorReadEnum = {
    True: 'true',
    False: 'false'
} as const;
export type ListMessagesMetadataInvestorReadEnum = typeof ListMessagesMetadataInvestorReadEnum[keyof typeof ListMessagesMetadataInvestorReadEnum];
/**
 * @export
 */
export const ListMessagesMetadataInvestorFlaggedEnum = {
    True: 'true',
    False: 'false'
} as const;
export type ListMessagesMetadataInvestorFlaggedEnum = typeof ListMessagesMetadataInvestorFlaggedEnum[keyof typeof ListMessagesMetadataInvestorFlaggedEnum];
/**
 * @export
 */
export const ListMessagesMetadataAdvisorReadEnum = {
    True: 'true',
    False: 'false'
} as const;
export type ListMessagesMetadataAdvisorReadEnum = typeof ListMessagesMetadataAdvisorReadEnum[keyof typeof ListMessagesMetadataAdvisorReadEnum];
/**
 * @export
 */
export const ListMessagesMetadataAdvisorFlaggedEnum = {
    True: 'true',
    False: 'false'
} as const;
export type ListMessagesMetadataAdvisorFlaggedEnum = typeof ListMessagesMetadataAdvisorFlaggedEnum[keyof typeof ListMessagesMetadataAdvisorFlaggedEnum];
/**
 * @export
 */
export const ListMessagesMetadataAdminReadEnum = {
    True: 'true',
    False: 'false'
} as const;
export type ListMessagesMetadataAdminReadEnum = typeof ListMessagesMetadataAdminReadEnum[keyof typeof ListMessagesMetadataAdminReadEnum];
/**
 * @export
 */
export const ListMessagesMetadataAdminFlaggedEnum = {
    True: 'true',
    False: 'false'
} as const;
export type ListMessagesMetadataAdminFlaggedEnum = typeof ListMessagesMetadataAdminFlaggedEnum[keyof typeof ListMessagesMetadataAdminFlaggedEnum];
/**
 * @export
 */
export const ListMessagesCategoryEnum = {
    Null: 'null',
    Tfa: 'tfa'
} as const;
export type ListMessagesCategoryEnum = typeof ListMessagesCategoryEnum[keyof typeof ListMessagesCategoryEnum];


/**
 * RALRequestApi - axios parameter creator
 * @export
 */
export const RALRequestApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Create a new RAL Request.
         * @param {CreateRALRequestRequest} CreateRALRequestRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createRALRequest: async (CreateRALRequestRequest: CreateRALRequestRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'CreateRALRequestRequest' is not null or undefined
            assertParamExists('createRALRequest', 'CreateRALRequestRequest', CreateRALRequestRequest)
            const localVarPath = `/v2/ralrequests/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication adminAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(CreateRALRequestRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Delete a RAL Request document.
         * @param {string} ral_request_id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteRALRequest: async (ral_request_id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'ral_request_id' is not null or undefined
            assertParamExists('deleteRALRequest', 'ral_request_id', ral_request_id)
            const localVarPath = `/v2/ralrequests/{ral_request_id}`
                .replace(`{${"ral_request_id"}}`, encodeURIComponent(String(ral_request_id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication adminAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication advisorAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication investorAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Generate PandaDoc signing URLs for RAL documents.
         * @param {Array<string>} ral_request_ids 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        generatePandaDocSigningUrls: async (ral_request_ids: Array<string>, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'ral_request_ids' is not null or undefined
            assertParamExists('generatePandaDocSigningUrls', 'ral_request_ids', ral_request_ids)
            const localVarPath = `/v2/ralrequests/generate-panda-doc-signing-urls`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication adminAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication advisorAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (ral_request_ids) {
                localVarQueryParameter['ral_request_ids'] = ral_request_ids;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get a single RAL Request document by ID.
         * @param {string} ral_request_id 
         * @param {boolean} [populate_user] 
         * @param {boolean} [populate_signed_ral] 
         * @param {boolean} [populate_ral_template] 
         * @param {boolean} [populate_ownership] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRALRequest: async (ral_request_id: string, populate_user?: boolean, populate_signed_ral?: boolean, populate_ral_template?: boolean, populate_ownership?: boolean, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'ral_request_id' is not null or undefined
            assertParamExists('getRALRequest', 'ral_request_id', ral_request_id)
            const localVarPath = `/v2/ralrequests/{ral_request_id}`
                .replace(`{${"ral_request_id"}}`, encodeURIComponent(String(ral_request_id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication adminAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication advisorAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication investorAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (populate_user !== undefined) {
                localVarQueryParameter['populate_user'] = populate_user;
            }

            if (populate_signed_ral !== undefined) {
                localVarQueryParameter['populate_signed_ral'] = populate_signed_ral;
            }

            if (populate_ral_template !== undefined) {
                localVarQueryParameter['populate_ral_template'] = populate_ral_template;
            }

            if (populate_ownership !== undefined) {
                localVarQueryParameter['populate_ownership'] = populate_ownership;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Paginate through RAL Request documents.
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {ListRALRequestsSortFieldEnum} [sort_field] 
         * @param {ListRALRequestsSortDirectionEnum} [sort_direction] 
         * @param {Array<string>} [templates] 
         * @param {Array<string>} [users] 
         * @param {Array<string>} [ownership_ids] 
         * @param {ListRALRequestsOwnershipTypeEnum} [ownership_type] 
         * @param {Array<string>} [advisory_groups] 
         * @param {Array<string>} [statuses] 
         * @param {boolean} [sent_at] 
         * @param {boolean} [signed_at] 
         * @param {boolean} [populate_user] 
         * @param {boolean} [populate_user_advisory_group] 
         * @param {boolean} [populate_signed_ral] 
         * @param {boolean} [populate_ral_template] 
         * @param {boolean} [populate_ownership] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listRALRequests: async (skip?: number, limit?: number, sort_field?: ListRALRequestsSortFieldEnum, sort_direction?: ListRALRequestsSortDirectionEnum, templates?: Array<string>, users?: Array<string>, ownership_ids?: Array<string>, ownership_type?: ListRALRequestsOwnershipTypeEnum, advisory_groups?: Array<string>, statuses?: Array<string>, sent_at?: boolean, signed_at?: boolean, populate_user?: boolean, populate_user_advisory_group?: boolean, populate_signed_ral?: boolean, populate_ral_template?: boolean, populate_ownership?: boolean, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v2/ralrequests/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication adminAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication advisorAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication investorAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (skip !== undefined) {
                localVarQueryParameter['skip'] = skip;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (sort_field !== undefined) {
                localVarQueryParameter['sort_field'] = sort_field;
            }

            if (sort_direction !== undefined) {
                localVarQueryParameter['sort_direction'] = sort_direction;
            }

            if (templates) {
                localVarQueryParameter['templates'] = templates;
            }

            if (users) {
                localVarQueryParameter['users'] = users;
            }

            if (ownership_ids) {
                localVarQueryParameter['ownership_ids'] = ownership_ids;
            }

            if (ownership_type !== undefined) {
                localVarQueryParameter['ownership_type'] = ownership_type;
            }

            if (advisory_groups) {
                localVarQueryParameter['advisory_groups'] = advisory_groups;
            }

            if (statuses) {
                localVarQueryParameter['statuses'] = statuses;
            }

            if (sent_at !== undefined) {
                localVarQueryParameter['sent_at'] = sent_at;
            }

            if (signed_at !== undefined) {
                localVarQueryParameter['signed_at'] = signed_at;
            }

            if (populate_user !== undefined) {
                localVarQueryParameter['populate_user'] = populate_user;
            }

            if (populate_user_advisory_group !== undefined) {
                localVarQueryParameter['populate_user_advisory_group'] = populate_user_advisory_group;
            }

            if (populate_signed_ral !== undefined) {
                localVarQueryParameter['populate_signed_ral'] = populate_signed_ral;
            }

            if (populate_ral_template !== undefined) {
                localVarQueryParameter['populate_ral_template'] = populate_ral_template;
            }

            if (populate_ownership !== undefined) {
                localVarQueryParameter['populate_ownership'] = populate_ownership;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Create and send a RAL document via PandaDoc.
         * @param {SendRALRequestRequest} SendRALRequestRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendRALRequest: async (SendRALRequestRequest: SendRALRequestRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'SendRALRequestRequest' is not null or undefined
            assertParamExists('sendRALRequest', 'SendRALRequestRequest', SendRALRequestRequest)
            const localVarPath = `/v2/ralrequests/send`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication adminAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication advisorAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(SendRALRequestRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update an existing RAL Request document.
         * @param {string} ral_request_id 
         * @param {UpdateRALRequestRequest} [UpdateRALRequestRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateRALRequest: async (ral_request_id: string, UpdateRALRequestRequest?: UpdateRALRequestRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'ral_request_id' is not null or undefined
            assertParamExists('updateRALRequest', 'ral_request_id', ral_request_id)
            const localVarPath = `/v2/ralrequests/{ral_request_id}`
                .replace(`{${"ral_request_id"}}`, encodeURIComponent(String(ral_request_id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication adminAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(UpdateRALRequestRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * RALRequestApi - functional programming interface
 * @export
 */
export const RALRequestApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = RALRequestApiAxiosParamCreator(configuration)
    return {
        /**
         * Create a new RAL Request.
         * @param {CreateRALRequestRequest} CreateRALRequestRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createRALRequest(CreateRALRequestRequest: CreateRALRequestRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CreateRALRequest201Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createRALRequest(CreateRALRequestRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['RALRequestApi.createRALRequest']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Delete a RAL Request document.
         * @param {string} ral_request_id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteRALRequest(ral_request_id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DeleteAccount200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteRALRequest(ral_request_id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['RALRequestApi.deleteRALRequest']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Generate PandaDoc signing URLs for RAL documents.
         * @param {Array<string>} ral_request_ids 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async generatePandaDocSigningUrls(ral_request_ids: Array<string>, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GeneratePandaDocSigningUrls200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.generatePandaDocSigningUrls(ral_request_ids, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['RALRequestApi.generatePandaDocSigningUrls']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Get a single RAL Request document by ID.
         * @param {string} ral_request_id 
         * @param {boolean} [populate_user] 
         * @param {boolean} [populate_signed_ral] 
         * @param {boolean} [populate_ral_template] 
         * @param {boolean} [populate_ownership] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getRALRequest(ral_request_id: string, populate_user?: boolean, populate_signed_ral?: boolean, populate_ral_template?: boolean, populate_ownership?: boolean, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetRALRequest200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getRALRequest(ral_request_id, populate_user, populate_signed_ral, populate_ral_template, populate_ownership, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['RALRequestApi.getRALRequest']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Paginate through RAL Request documents.
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {ListRALRequestsSortFieldEnum} [sort_field] 
         * @param {ListRALRequestsSortDirectionEnum} [sort_direction] 
         * @param {Array<string>} [templates] 
         * @param {Array<string>} [users] 
         * @param {Array<string>} [ownership_ids] 
         * @param {ListRALRequestsOwnershipTypeEnum} [ownership_type] 
         * @param {Array<string>} [advisory_groups] 
         * @param {Array<string>} [statuses] 
         * @param {boolean} [sent_at] 
         * @param {boolean} [signed_at] 
         * @param {boolean} [populate_user] 
         * @param {boolean} [populate_user_advisory_group] 
         * @param {boolean} [populate_signed_ral] 
         * @param {boolean} [populate_ral_template] 
         * @param {boolean} [populate_ownership] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listRALRequests(skip?: number, limit?: number, sort_field?: ListRALRequestsSortFieldEnum, sort_direction?: ListRALRequestsSortDirectionEnum, templates?: Array<string>, users?: Array<string>, ownership_ids?: Array<string>, ownership_type?: ListRALRequestsOwnershipTypeEnum, advisory_groups?: Array<string>, statuses?: Array<string>, sent_at?: boolean, signed_at?: boolean, populate_user?: boolean, populate_user_advisory_group?: boolean, populate_signed_ral?: boolean, populate_ral_template?: boolean, populate_ownership?: boolean, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListRALRequests200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listRALRequests(skip, limit, sort_field, sort_direction, templates, users, ownership_ids, ownership_type, advisory_groups, statuses, sent_at, signed_at, populate_user, populate_user_advisory_group, populate_signed_ral, populate_ral_template, populate_ownership, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['RALRequestApi.listRALRequests']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Create and send a RAL document via PandaDoc.
         * @param {SendRALRequestRequest} SendRALRequestRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async sendRALRequest(SendRALRequestRequest: SendRALRequestRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SendRALRequest200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.sendRALRequest(SendRALRequestRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['RALRequestApi.sendRALRequest']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Update an existing RAL Request document.
         * @param {string} ral_request_id 
         * @param {UpdateRALRequestRequest} [UpdateRALRequestRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateRALRequest(ral_request_id: string, UpdateRALRequestRequest?: UpdateRALRequestRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DeleteAccount200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateRALRequest(ral_request_id, UpdateRALRequestRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['RALRequestApi.updateRALRequest']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * RALRequestApi - factory interface
 * @export
 */
export const RALRequestApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = RALRequestApiFp(configuration)
    return {
        /**
         * Create a new RAL Request.
         * @param {RALRequestApiCreateRALRequestRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createRALRequest(requestParameters: RALRequestApiCreateRALRequestRequest, options?: RawAxiosRequestConfig): AxiosPromise<CreateRALRequest201Response> {
            return localVarFp.createRALRequest(requestParameters.CreateRALRequestRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Delete a RAL Request document.
         * @param {RALRequestApiDeleteRALRequestRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteRALRequest(requestParameters: RALRequestApiDeleteRALRequestRequest, options?: RawAxiosRequestConfig): AxiosPromise<DeleteAccount200Response> {
            return localVarFp.deleteRALRequest(requestParameters.ral_request_id, options).then((request) => request(axios, basePath));
        },
        /**
         * Generate PandaDoc signing URLs for RAL documents.
         * @param {RALRequestApiGeneratePandaDocSigningUrlsRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        generatePandaDocSigningUrls(requestParameters: RALRequestApiGeneratePandaDocSigningUrlsRequest, options?: RawAxiosRequestConfig): AxiosPromise<GeneratePandaDocSigningUrls200Response> {
            return localVarFp.generatePandaDocSigningUrls(requestParameters.ral_request_ids, options).then((request) => request(axios, basePath));
        },
        /**
         * Get a single RAL Request document by ID.
         * @param {RALRequestApiGetRALRequestRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRALRequest(requestParameters: RALRequestApiGetRALRequestRequest, options?: RawAxiosRequestConfig): AxiosPromise<GetRALRequest200Response> {
            return localVarFp.getRALRequest(requestParameters.ral_request_id, requestParameters.populate_user, requestParameters.populate_signed_ral, requestParameters.populate_ral_template, requestParameters.populate_ownership, options).then((request) => request(axios, basePath));
        },
        /**
         * Paginate through RAL Request documents.
         * @param {RALRequestApiListRALRequestsRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listRALRequests(requestParameters: RALRequestApiListRALRequestsRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<ListRALRequests200Response> {
            return localVarFp.listRALRequests(requestParameters.skip, requestParameters.limit, requestParameters.sort_field, requestParameters.sort_direction, requestParameters.templates, requestParameters.users, requestParameters.ownership_ids, requestParameters.ownership_type, requestParameters.advisory_groups, requestParameters.statuses, requestParameters.sent_at, requestParameters.signed_at, requestParameters.populate_user, requestParameters.populate_user_advisory_group, requestParameters.populate_signed_ral, requestParameters.populate_ral_template, requestParameters.populate_ownership, options).then((request) => request(axios, basePath));
        },
        /**
         * Create and send a RAL document via PandaDoc.
         * @param {RALRequestApiSendRALRequestRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendRALRequest(requestParameters: RALRequestApiSendRALRequestRequest, options?: RawAxiosRequestConfig): AxiosPromise<SendRALRequest200Response> {
            return localVarFp.sendRALRequest(requestParameters.SendRALRequestRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Update an existing RAL Request document.
         * @param {RALRequestApiUpdateRALRequestRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateRALRequest(requestParameters: RALRequestApiUpdateRALRequestRequest, options?: RawAxiosRequestConfig): AxiosPromise<DeleteAccount200Response> {
            return localVarFp.updateRALRequest(requestParameters.ral_request_id, requestParameters.UpdateRALRequestRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for createRALRequest operation in RALRequestApi.
 * @export
 * @interface RALRequestApiCreateRALRequestRequest
 */
export interface RALRequestApiCreateRALRequestRequest {
    /**
     * 
     * @type {CreateRALRequestRequest}
     * @memberof RALRequestApiCreateRALRequest
     */
    readonly CreateRALRequestRequest: CreateRALRequestRequest
}

/**
 * Request parameters for deleteRALRequest operation in RALRequestApi.
 * @export
 * @interface RALRequestApiDeleteRALRequestRequest
 */
export interface RALRequestApiDeleteRALRequestRequest {
    /**
     * 
     * @type {string}
     * @memberof RALRequestApiDeleteRALRequest
     */
    readonly ral_request_id: string
}

/**
 * Request parameters for generatePandaDocSigningUrls operation in RALRequestApi.
 * @export
 * @interface RALRequestApiGeneratePandaDocSigningUrlsRequest
 */
export interface RALRequestApiGeneratePandaDocSigningUrlsRequest {
    /**
     * 
     * @type {Array<string>}
     * @memberof RALRequestApiGeneratePandaDocSigningUrls
     */
    readonly ral_request_ids: Array<string>
}

/**
 * Request parameters for getRALRequest operation in RALRequestApi.
 * @export
 * @interface RALRequestApiGetRALRequestRequest
 */
export interface RALRequestApiGetRALRequestRequest {
    /**
     * 
     * @type {string}
     * @memberof RALRequestApiGetRALRequest
     */
    readonly ral_request_id: string

    /**
     * 
     * @type {boolean}
     * @memberof RALRequestApiGetRALRequest
     */
    readonly populate_user?: boolean

    /**
     * 
     * @type {boolean}
     * @memberof RALRequestApiGetRALRequest
     */
    readonly populate_signed_ral?: boolean

    /**
     * 
     * @type {boolean}
     * @memberof RALRequestApiGetRALRequest
     */
    readonly populate_ral_template?: boolean

    /**
     * 
     * @type {boolean}
     * @memberof RALRequestApiGetRALRequest
     */
    readonly populate_ownership?: boolean
}

/**
 * Request parameters for listRALRequests operation in RALRequestApi.
 * @export
 * @interface RALRequestApiListRALRequestsRequest
 */
export interface RALRequestApiListRALRequestsRequest {
    /**
     * 
     * @type {number}
     * @memberof RALRequestApiListRALRequests
     */
    readonly skip?: number

    /**
     * 
     * @type {number}
     * @memberof RALRequestApiListRALRequests
     */
    readonly limit?: number

    /**
     * 
     * @type {'sent_at' | 'status' | 'signed_at' | 'ral_panda_doc_status' | 'created_at' | 'updated_at' | 'ownership_type'}
     * @memberof RALRequestApiListRALRequests
     */
    readonly sort_field?: ListRALRequestsSortFieldEnum

    /**
     * 
     * @type {'asc' | 'desc'}
     * @memberof RALRequestApiListRALRequests
     */
    readonly sort_direction?: ListRALRequestsSortDirectionEnum

    /**
     * 
     * @type {Array<string>}
     * @memberof RALRequestApiListRALRequests
     */
    readonly templates?: Array<string>

    /**
     * 
     * @type {Array<string>}
     * @memberof RALRequestApiListRALRequests
     */
    readonly users?: Array<string>

    /**
     * 
     * @type {Array<string>}
     * @memberof RALRequestApiListRALRequests
     */
    readonly ownership_ids?: Array<string>

    /**
     * 
     * @type {'Account' | 'Investment' | 'null'}
     * @memberof RALRequestApiListRALRequests
     */
    readonly ownership_type?: ListRALRequestsOwnershipTypeEnum

    /**
     * 
     * @type {Array<string>}
     * @memberof RALRequestApiListRALRequests
     */
    readonly advisory_groups?: Array<string>

    /**
     * 
     * @type {Array<string>}
     * @memberof RALRequestApiListRALRequests
     */
    readonly statuses?: Array<string>

    /**
     * 
     * @type {boolean}
     * @memberof RALRequestApiListRALRequests
     */
    readonly sent_at?: boolean

    /**
     * 
     * @type {boolean}
     * @memberof RALRequestApiListRALRequests
     */
    readonly signed_at?: boolean

    /**
     * 
     * @type {boolean}
     * @memberof RALRequestApiListRALRequests
     */
    readonly populate_user?: boolean

    /**
     * 
     * @type {boolean}
     * @memberof RALRequestApiListRALRequests
     */
    readonly populate_user_advisory_group?: boolean

    /**
     * 
     * @type {boolean}
     * @memberof RALRequestApiListRALRequests
     */
    readonly populate_signed_ral?: boolean

    /**
     * 
     * @type {boolean}
     * @memberof RALRequestApiListRALRequests
     */
    readonly populate_ral_template?: boolean

    /**
     * 
     * @type {boolean}
     * @memberof RALRequestApiListRALRequests
     */
    readonly populate_ownership?: boolean
}

/**
 * Request parameters for sendRALRequest operation in RALRequestApi.
 * @export
 * @interface RALRequestApiSendRALRequestRequest
 */
export interface RALRequestApiSendRALRequestRequest {
    /**
     * 
     * @type {SendRALRequestRequest}
     * @memberof RALRequestApiSendRALRequest
     */
    readonly SendRALRequestRequest: SendRALRequestRequest
}

/**
 * Request parameters for updateRALRequest operation in RALRequestApi.
 * @export
 * @interface RALRequestApiUpdateRALRequestRequest
 */
export interface RALRequestApiUpdateRALRequestRequest {
    /**
     * 
     * @type {string}
     * @memberof RALRequestApiUpdateRALRequest
     */
    readonly ral_request_id: string

    /**
     * 
     * @type {UpdateRALRequestRequest}
     * @memberof RALRequestApiUpdateRALRequest
     */
    readonly UpdateRALRequestRequest?: UpdateRALRequestRequest
}

/**
 * RALRequestApi - object-oriented interface
 * @export
 * @class RALRequestApi
 * @extends {BaseAPI}
 */
export class RALRequestApi extends BaseAPI {
    /**
     * Create a new RAL Request.
     * @param {RALRequestApiCreateRALRequestRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RALRequestApi
     */
    public createRALRequest(requestParameters: RALRequestApiCreateRALRequestRequest, options?: RawAxiosRequestConfig) {
        return RALRequestApiFp(this.configuration).createRALRequest(requestParameters.CreateRALRequestRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Delete a RAL Request document.
     * @param {RALRequestApiDeleteRALRequestRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RALRequestApi
     */
    public deleteRALRequest(requestParameters: RALRequestApiDeleteRALRequestRequest, options?: RawAxiosRequestConfig) {
        return RALRequestApiFp(this.configuration).deleteRALRequest(requestParameters.ral_request_id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Generate PandaDoc signing URLs for RAL documents.
     * @param {RALRequestApiGeneratePandaDocSigningUrlsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RALRequestApi
     */
    public generatePandaDocSigningUrls(requestParameters: RALRequestApiGeneratePandaDocSigningUrlsRequest, options?: RawAxiosRequestConfig) {
        return RALRequestApiFp(this.configuration).generatePandaDocSigningUrls(requestParameters.ral_request_ids, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get a single RAL Request document by ID.
     * @param {RALRequestApiGetRALRequestRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RALRequestApi
     */
    public getRALRequest(requestParameters: RALRequestApiGetRALRequestRequest, options?: RawAxiosRequestConfig) {
        return RALRequestApiFp(this.configuration).getRALRequest(requestParameters.ral_request_id, requestParameters.populate_user, requestParameters.populate_signed_ral, requestParameters.populate_ral_template, requestParameters.populate_ownership, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Paginate through RAL Request documents.
     * @param {RALRequestApiListRALRequestsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RALRequestApi
     */
    public listRALRequests(requestParameters: RALRequestApiListRALRequestsRequest = {}, options?: RawAxiosRequestConfig) {
        return RALRequestApiFp(this.configuration).listRALRequests(requestParameters.skip, requestParameters.limit, requestParameters.sort_field, requestParameters.sort_direction, requestParameters.templates, requestParameters.users, requestParameters.ownership_ids, requestParameters.ownership_type, requestParameters.advisory_groups, requestParameters.statuses, requestParameters.sent_at, requestParameters.signed_at, requestParameters.populate_user, requestParameters.populate_user_advisory_group, requestParameters.populate_signed_ral, requestParameters.populate_ral_template, requestParameters.populate_ownership, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Create and send a RAL document via PandaDoc.
     * @param {RALRequestApiSendRALRequestRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RALRequestApi
     */
    public sendRALRequest(requestParameters: RALRequestApiSendRALRequestRequest, options?: RawAxiosRequestConfig) {
        return RALRequestApiFp(this.configuration).sendRALRequest(requestParameters.SendRALRequestRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Update an existing RAL Request document.
     * @param {RALRequestApiUpdateRALRequestRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RALRequestApi
     */
    public updateRALRequest(requestParameters: RALRequestApiUpdateRALRequestRequest, options?: RawAxiosRequestConfig) {
        return RALRequestApiFp(this.configuration).updateRALRequest(requestParameters.ral_request_id, requestParameters.UpdateRALRequestRequest, options).then((request) => request(this.axios, this.basePath));
    }
}

/**
 * @export
 */
export const ListRALRequestsSortFieldEnum = {
    SentAt: 'sent_at',
    Status: 'status',
    SignedAt: 'signed_at',
    RalPandaDocStatus: 'ral_panda_doc_status',
    CreatedAt: 'created_at',
    UpdatedAt: 'updated_at',
    OwnershipType: 'ownership_type'
} as const;
export type ListRALRequestsSortFieldEnum = typeof ListRALRequestsSortFieldEnum[keyof typeof ListRALRequestsSortFieldEnum];
/**
 * @export
 */
export const ListRALRequestsSortDirectionEnum = {
    Asc: 'asc',
    Desc: 'desc'
} as const;
export type ListRALRequestsSortDirectionEnum = typeof ListRALRequestsSortDirectionEnum[keyof typeof ListRALRequestsSortDirectionEnum];
/**
 * @export
 */
export const ListRALRequestsOwnershipTypeEnum = {
    Account: 'Account',
    Investment: 'Investment',
    Null: 'null'
} as const;
export type ListRALRequestsOwnershipTypeEnum = typeof ListRALRequestsOwnershipTypeEnum[keyof typeof ListRALRequestsOwnershipTypeEnum];


/**
 * RALRequestTemplateApi - axios parameter creator
 * @export
 */
export const RALRequestTemplateApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Retrieve all RAL Request Templates.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listRALRequestTemplates: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v2/ralrequests/templates/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication adminAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * RALRequestTemplateApi - functional programming interface
 * @export
 */
export const RALRequestTemplateApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = RALRequestTemplateApiAxiosParamCreator(configuration)
    return {
        /**
         * Retrieve all RAL Request Templates.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listRALRequestTemplates(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListRALRequestTemplates200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listRALRequestTemplates(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['RALRequestTemplateApi.listRALRequestTemplates']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * RALRequestTemplateApi - factory interface
 * @export
 */
export const RALRequestTemplateApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = RALRequestTemplateApiFp(configuration)
    return {
        /**
         * Retrieve all RAL Request Templates.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listRALRequestTemplates(options?: RawAxiosRequestConfig): AxiosPromise<ListRALRequestTemplates200Response> {
            return localVarFp.listRALRequestTemplates(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * RALRequestTemplateApi - object-oriented interface
 * @export
 * @class RALRequestTemplateApi
 * @extends {BaseAPI}
 */
export class RALRequestTemplateApi extends BaseAPI {
    /**
     * Retrieve all RAL Request Templates.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RALRequestTemplateApi
     */
    public listRALRequestTemplates(options?: RawAxiosRequestConfig) {
        return RALRequestTemplateApiFp(this.configuration).listRALRequestTemplates(options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * SyncWorkerApi - axios parameter creator
 * @export
 */
export const SyncWorkerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Update the credentials of a Connection document.
         * @param {UpdateConnectionCredentialsRequest} UpdateConnectionCredentialsRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateConnectionCredentials: async (UpdateConnectionCredentialsRequest: UpdateConnectionCredentialsRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'UpdateConnectionCredentialsRequest' is not null or undefined
            assertParamExists('updateConnectionCredentials', 'UpdateConnectionCredentialsRequest', UpdateConnectionCredentialsRequest)
            const localVarPath = `/v2/connections/sync/credentials`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication syncAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(UpdateConnectionCredentialsRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Fetch the connection credentials
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v2ConnectionsSyncCredentialsGet: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v2/connections/sync/credentials`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication syncAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Fetch the existing data for a connection
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v2ConnectionsSyncExistingGet: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v2/connections/sync/existing`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication syncAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Allows a worker to post the results of a sync job
         * @param {string} id 
         * @param {V2ConnectionsSyncIdCompletePostRequest} V2ConnectionsSyncIdCompletePostRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v2ConnectionsSyncIdCompletePost: async (id: string, V2ConnectionsSyncIdCompletePostRequest: V2ConnectionsSyncIdCompletePostRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('v2ConnectionsSyncIdCompletePost', 'id', id)
            // verify required parameter 'V2ConnectionsSyncIdCompletePostRequest' is not null or undefined
            assertParamExists('v2ConnectionsSyncIdCompletePost', 'V2ConnectionsSyncIdCompletePostRequest', V2ConnectionsSyncIdCompletePostRequest)
            const localVarPath = `/v2/connections/sync/{id}/complete`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication syncAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(V2ConnectionsSyncIdCompletePostRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Fetch the most recent message that was attached to this connection
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v2ConnectionsSyncMessageGet: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v2/connections/sync/message`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication syncAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SyncWorkerApi - functional programming interface
 * @export
 */
export const SyncWorkerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = SyncWorkerApiAxiosParamCreator(configuration)
    return {
        /**
         * Update the credentials of a Connection document.
         * @param {UpdateConnectionCredentialsRequest} UpdateConnectionCredentialsRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateConnectionCredentials(UpdateConnectionCredentialsRequest: UpdateConnectionCredentialsRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DeleteAccount200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateConnectionCredentials(UpdateConnectionCredentialsRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SyncWorkerApi.updateConnectionCredentials']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Fetch the connection credentials
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v2ConnectionsSyncCredentialsGet(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<V2ConnectionsSyncCredentialsGet200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v2ConnectionsSyncCredentialsGet(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SyncWorkerApi.v2ConnectionsSyncCredentialsGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Fetch the existing data for a connection
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v2ConnectionsSyncExistingGet(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<V2ConnectionsSyncExistingGet200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v2ConnectionsSyncExistingGet(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SyncWorkerApi.v2ConnectionsSyncExistingGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Allows a worker to post the results of a sync job
         * @param {string} id 
         * @param {V2ConnectionsSyncIdCompletePostRequest} V2ConnectionsSyncIdCompletePostRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v2ConnectionsSyncIdCompletePost(id: string, V2ConnectionsSyncIdCompletePostRequest: V2ConnectionsSyncIdCompletePostRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DeleteAccount200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v2ConnectionsSyncIdCompletePost(id, V2ConnectionsSyncIdCompletePostRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SyncWorkerApi.v2ConnectionsSyncIdCompletePost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Fetch the most recent message that was attached to this connection
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v2ConnectionsSyncMessageGet(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<V2ConnectionsSyncMessageGet200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v2ConnectionsSyncMessageGet(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SyncWorkerApi.v2ConnectionsSyncMessageGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * SyncWorkerApi - factory interface
 * @export
 */
export const SyncWorkerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = SyncWorkerApiFp(configuration)
    return {
        /**
         * Update the credentials of a Connection document.
         * @param {SyncWorkerApiUpdateConnectionCredentialsRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateConnectionCredentials(requestParameters: SyncWorkerApiUpdateConnectionCredentialsRequest, options?: RawAxiosRequestConfig): AxiosPromise<DeleteAccount200Response> {
            return localVarFp.updateConnectionCredentials(requestParameters.UpdateConnectionCredentialsRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Fetch the connection credentials
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v2ConnectionsSyncCredentialsGet(options?: RawAxiosRequestConfig): AxiosPromise<V2ConnectionsSyncCredentialsGet200Response> {
            return localVarFp.v2ConnectionsSyncCredentialsGet(options).then((request) => request(axios, basePath));
        },
        /**
         * Fetch the existing data for a connection
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v2ConnectionsSyncExistingGet(options?: RawAxiosRequestConfig): AxiosPromise<V2ConnectionsSyncExistingGet200Response> {
            return localVarFp.v2ConnectionsSyncExistingGet(options).then((request) => request(axios, basePath));
        },
        /**
         * Allows a worker to post the results of a sync job
         * @param {SyncWorkerApiV2ConnectionsSyncIdCompletePostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v2ConnectionsSyncIdCompletePost(requestParameters: SyncWorkerApiV2ConnectionsSyncIdCompletePostRequest, options?: RawAxiosRequestConfig): AxiosPromise<DeleteAccount200Response> {
            return localVarFp.v2ConnectionsSyncIdCompletePost(requestParameters.id, requestParameters.V2ConnectionsSyncIdCompletePostRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Fetch the most recent message that was attached to this connection
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v2ConnectionsSyncMessageGet(options?: RawAxiosRequestConfig): AxiosPromise<V2ConnectionsSyncMessageGet200Response> {
            return localVarFp.v2ConnectionsSyncMessageGet(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for updateConnectionCredentials operation in SyncWorkerApi.
 * @export
 * @interface SyncWorkerApiUpdateConnectionCredentialsRequest
 */
export interface SyncWorkerApiUpdateConnectionCredentialsRequest {
    /**
     * 
     * @type {UpdateConnectionCredentialsRequest}
     * @memberof SyncWorkerApiUpdateConnectionCredentials
     */
    readonly UpdateConnectionCredentialsRequest: UpdateConnectionCredentialsRequest
}

/**
 * Request parameters for v2ConnectionsSyncIdCompletePost operation in SyncWorkerApi.
 * @export
 * @interface SyncWorkerApiV2ConnectionsSyncIdCompletePostRequest
 */
export interface SyncWorkerApiV2ConnectionsSyncIdCompletePostRequest {
    /**
     * 
     * @type {string}
     * @memberof SyncWorkerApiV2ConnectionsSyncIdCompletePost
     */
    readonly id: string

    /**
     * 
     * @type {V2ConnectionsSyncIdCompletePostRequest}
     * @memberof SyncWorkerApiV2ConnectionsSyncIdCompletePost
     */
    readonly V2ConnectionsSyncIdCompletePostRequest: V2ConnectionsSyncIdCompletePostRequest
}

/**
 * SyncWorkerApi - object-oriented interface
 * @export
 * @class SyncWorkerApi
 * @extends {BaseAPI}
 */
export class SyncWorkerApi extends BaseAPI {
    /**
     * Update the credentials of a Connection document.
     * @param {SyncWorkerApiUpdateConnectionCredentialsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SyncWorkerApi
     */
    public updateConnectionCredentials(requestParameters: SyncWorkerApiUpdateConnectionCredentialsRequest, options?: RawAxiosRequestConfig) {
        return SyncWorkerApiFp(this.configuration).updateConnectionCredentials(requestParameters.UpdateConnectionCredentialsRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Fetch the connection credentials
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SyncWorkerApi
     */
    public v2ConnectionsSyncCredentialsGet(options?: RawAxiosRequestConfig) {
        return SyncWorkerApiFp(this.configuration).v2ConnectionsSyncCredentialsGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Fetch the existing data for a connection
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SyncWorkerApi
     */
    public v2ConnectionsSyncExistingGet(options?: RawAxiosRequestConfig) {
        return SyncWorkerApiFp(this.configuration).v2ConnectionsSyncExistingGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Allows a worker to post the results of a sync job
     * @param {SyncWorkerApiV2ConnectionsSyncIdCompletePostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SyncWorkerApi
     */
    public v2ConnectionsSyncIdCompletePost(requestParameters: SyncWorkerApiV2ConnectionsSyncIdCompletePostRequest, options?: RawAxiosRequestConfig) {
        return SyncWorkerApiFp(this.configuration).v2ConnectionsSyncIdCompletePost(requestParameters.id, requestParameters.V2ConnectionsSyncIdCompletePostRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Fetch the most recent message that was attached to this connection
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SyncWorkerApi
     */
    public v2ConnectionsSyncMessageGet(options?: RawAxiosRequestConfig) {
        return SyncWorkerApiFp(this.configuration).v2ConnectionsSyncMessageGet(options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * SyncWorkerImportApi - axios parameter creator
 * @export
 */
export const SyncWorkerImportApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Provide information about accounts discovered during the sync process
         * @param {string} id 
         * @param {V2ConnectionsSyncIdImportAccountsPostRequest} V2ConnectionsSyncIdImportAccountsPostRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v2ConnectionsSyncIdImportAccountsPost: async (id: string, V2ConnectionsSyncIdImportAccountsPostRequest: V2ConnectionsSyncIdImportAccountsPostRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('v2ConnectionsSyncIdImportAccountsPost', 'id', id)
            // verify required parameter 'V2ConnectionsSyncIdImportAccountsPostRequest' is not null or undefined
            assertParamExists('v2ConnectionsSyncIdImportAccountsPost', 'V2ConnectionsSyncIdImportAccountsPostRequest', V2ConnectionsSyncIdImportAccountsPostRequest)
            const localVarPath = `/v2/connections/sync/{id}/import/accounts`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication syncAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(V2ConnectionsSyncIdImportAccountsPostRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Provide information about commitments discovered during the sync process
         * @param {string} id 
         * @param {V2ConnectionsSyncIdImportCommitmentsPostRequest} V2ConnectionsSyncIdImportCommitmentsPostRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v2ConnectionsSyncIdImportCommitmentsPost: async (id: string, V2ConnectionsSyncIdImportCommitmentsPostRequest: V2ConnectionsSyncIdImportCommitmentsPostRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('v2ConnectionsSyncIdImportCommitmentsPost', 'id', id)
            // verify required parameter 'V2ConnectionsSyncIdImportCommitmentsPostRequest' is not null or undefined
            assertParamExists('v2ConnectionsSyncIdImportCommitmentsPost', 'V2ConnectionsSyncIdImportCommitmentsPostRequest', V2ConnectionsSyncIdImportCommitmentsPostRequest)
            const localVarPath = `/v2/connections/sync/{id}/import/commitments`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication syncAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(V2ConnectionsSyncIdImportCommitmentsPostRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Provide information about documents discovered during the sync process
         * @param {string} id 
         * @param {V2ConnectionsSyncIdImportDocumentsPostRequest} V2ConnectionsSyncIdImportDocumentsPostRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v2ConnectionsSyncIdImportDocumentsPost: async (id: string, V2ConnectionsSyncIdImportDocumentsPostRequest: V2ConnectionsSyncIdImportDocumentsPostRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('v2ConnectionsSyncIdImportDocumentsPost', 'id', id)
            // verify required parameter 'V2ConnectionsSyncIdImportDocumentsPostRequest' is not null or undefined
            assertParamExists('v2ConnectionsSyncIdImportDocumentsPost', 'V2ConnectionsSyncIdImportDocumentsPostRequest', V2ConnectionsSyncIdImportDocumentsPostRequest)
            const localVarPath = `/v2/connections/sync/{id}/import/documents`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication syncAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(V2ConnectionsSyncIdImportDocumentsPostRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Provide information about investments discovered during the sync process
         * @param {string} id 
         * @param {V2ConnectionsSyncIdImportInvestmentsPostRequest} V2ConnectionsSyncIdImportInvestmentsPostRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v2ConnectionsSyncIdImportInvestmentsPost: async (id: string, V2ConnectionsSyncIdImportInvestmentsPostRequest: V2ConnectionsSyncIdImportInvestmentsPostRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('v2ConnectionsSyncIdImportInvestmentsPost', 'id', id)
            // verify required parameter 'V2ConnectionsSyncIdImportInvestmentsPostRequest' is not null or undefined
            assertParamExists('v2ConnectionsSyncIdImportInvestmentsPost', 'V2ConnectionsSyncIdImportInvestmentsPostRequest', V2ConnectionsSyncIdImportInvestmentsPostRequest)
            const localVarPath = `/v2/connections/sync/{id}/import/investments`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication syncAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(V2ConnectionsSyncIdImportInvestmentsPostRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Provide information about transactions discovered during the sync process
         * @param {string} id 
         * @param {V2ConnectionsSyncIdImportTransactionsPostRequest} V2ConnectionsSyncIdImportTransactionsPostRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v2ConnectionsSyncIdImportTransactionsPost: async (id: string, V2ConnectionsSyncIdImportTransactionsPostRequest: V2ConnectionsSyncIdImportTransactionsPostRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('v2ConnectionsSyncIdImportTransactionsPost', 'id', id)
            // verify required parameter 'V2ConnectionsSyncIdImportTransactionsPostRequest' is not null or undefined
            assertParamExists('v2ConnectionsSyncIdImportTransactionsPost', 'V2ConnectionsSyncIdImportTransactionsPostRequest', V2ConnectionsSyncIdImportTransactionsPostRequest)
            const localVarPath = `/v2/connections/sync/{id}/import/transactions`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication syncAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(V2ConnectionsSyncIdImportTransactionsPostRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Provide information about users discovered during the sync process
         * @param {string} id 
         * @param {V2ConnectionsSyncIdImportUsersPostRequest} V2ConnectionsSyncIdImportUsersPostRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v2ConnectionsSyncIdImportUsersPost: async (id: string, V2ConnectionsSyncIdImportUsersPostRequest: V2ConnectionsSyncIdImportUsersPostRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('v2ConnectionsSyncIdImportUsersPost', 'id', id)
            // verify required parameter 'V2ConnectionsSyncIdImportUsersPostRequest' is not null or undefined
            assertParamExists('v2ConnectionsSyncIdImportUsersPost', 'V2ConnectionsSyncIdImportUsersPostRequest', V2ConnectionsSyncIdImportUsersPostRequest)
            const localVarPath = `/v2/connections/sync/{id}/import/users`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication syncAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(V2ConnectionsSyncIdImportUsersPostRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Provide information about valuations discovered during the sync process
         * @param {string} id 
         * @param {V2ConnectionsSyncIdImportValuationsPostRequest} V2ConnectionsSyncIdImportValuationsPostRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v2ConnectionsSyncIdImportValuationsPost: async (id: string, V2ConnectionsSyncIdImportValuationsPostRequest: V2ConnectionsSyncIdImportValuationsPostRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('v2ConnectionsSyncIdImportValuationsPost', 'id', id)
            // verify required parameter 'V2ConnectionsSyncIdImportValuationsPostRequest' is not null or undefined
            assertParamExists('v2ConnectionsSyncIdImportValuationsPost', 'V2ConnectionsSyncIdImportValuationsPostRequest', V2ConnectionsSyncIdImportValuationsPostRequest)
            const localVarPath = `/v2/connections/sync/{id}/import/valuations`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication syncAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(V2ConnectionsSyncIdImportValuationsPostRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SyncWorkerImportApi - functional programming interface
 * @export
 */
export const SyncWorkerImportApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = SyncWorkerImportApiAxiosParamCreator(configuration)
    return {
        /**
         * Provide information about accounts discovered during the sync process
         * @param {string} id 
         * @param {V2ConnectionsSyncIdImportAccountsPostRequest} V2ConnectionsSyncIdImportAccountsPostRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v2ConnectionsSyncIdImportAccountsPost(id: string, V2ConnectionsSyncIdImportAccountsPostRequest: V2ConnectionsSyncIdImportAccountsPostRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DeleteAccount200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v2ConnectionsSyncIdImportAccountsPost(id, V2ConnectionsSyncIdImportAccountsPostRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SyncWorkerImportApi.v2ConnectionsSyncIdImportAccountsPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Provide information about commitments discovered during the sync process
         * @param {string} id 
         * @param {V2ConnectionsSyncIdImportCommitmentsPostRequest} V2ConnectionsSyncIdImportCommitmentsPostRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v2ConnectionsSyncIdImportCommitmentsPost(id: string, V2ConnectionsSyncIdImportCommitmentsPostRequest: V2ConnectionsSyncIdImportCommitmentsPostRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DeleteAccount200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v2ConnectionsSyncIdImportCommitmentsPost(id, V2ConnectionsSyncIdImportCommitmentsPostRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SyncWorkerImportApi.v2ConnectionsSyncIdImportCommitmentsPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Provide information about documents discovered during the sync process
         * @param {string} id 
         * @param {V2ConnectionsSyncIdImportDocumentsPostRequest} V2ConnectionsSyncIdImportDocumentsPostRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v2ConnectionsSyncIdImportDocumentsPost(id: string, V2ConnectionsSyncIdImportDocumentsPostRequest: V2ConnectionsSyncIdImportDocumentsPostRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<V2ConnectionsSyncIdImportDocumentsPost200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v2ConnectionsSyncIdImportDocumentsPost(id, V2ConnectionsSyncIdImportDocumentsPostRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SyncWorkerImportApi.v2ConnectionsSyncIdImportDocumentsPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Provide information about investments discovered during the sync process
         * @param {string} id 
         * @param {V2ConnectionsSyncIdImportInvestmentsPostRequest} V2ConnectionsSyncIdImportInvestmentsPostRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v2ConnectionsSyncIdImportInvestmentsPost(id: string, V2ConnectionsSyncIdImportInvestmentsPostRequest: V2ConnectionsSyncIdImportInvestmentsPostRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DeleteAccount200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v2ConnectionsSyncIdImportInvestmentsPost(id, V2ConnectionsSyncIdImportInvestmentsPostRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SyncWorkerImportApi.v2ConnectionsSyncIdImportInvestmentsPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Provide information about transactions discovered during the sync process
         * @param {string} id 
         * @param {V2ConnectionsSyncIdImportTransactionsPostRequest} V2ConnectionsSyncIdImportTransactionsPostRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v2ConnectionsSyncIdImportTransactionsPost(id: string, V2ConnectionsSyncIdImportTransactionsPostRequest: V2ConnectionsSyncIdImportTransactionsPostRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DeleteAccount200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v2ConnectionsSyncIdImportTransactionsPost(id, V2ConnectionsSyncIdImportTransactionsPostRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SyncWorkerImportApi.v2ConnectionsSyncIdImportTransactionsPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Provide information about users discovered during the sync process
         * @param {string} id 
         * @param {V2ConnectionsSyncIdImportUsersPostRequest} V2ConnectionsSyncIdImportUsersPostRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v2ConnectionsSyncIdImportUsersPost(id: string, V2ConnectionsSyncIdImportUsersPostRequest: V2ConnectionsSyncIdImportUsersPostRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DeleteAccount200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v2ConnectionsSyncIdImportUsersPost(id, V2ConnectionsSyncIdImportUsersPostRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SyncWorkerImportApi.v2ConnectionsSyncIdImportUsersPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Provide information about valuations discovered during the sync process
         * @param {string} id 
         * @param {V2ConnectionsSyncIdImportValuationsPostRequest} V2ConnectionsSyncIdImportValuationsPostRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v2ConnectionsSyncIdImportValuationsPost(id: string, V2ConnectionsSyncIdImportValuationsPostRequest: V2ConnectionsSyncIdImportValuationsPostRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DeleteAccount200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v2ConnectionsSyncIdImportValuationsPost(id, V2ConnectionsSyncIdImportValuationsPostRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SyncWorkerImportApi.v2ConnectionsSyncIdImportValuationsPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * SyncWorkerImportApi - factory interface
 * @export
 */
export const SyncWorkerImportApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = SyncWorkerImportApiFp(configuration)
    return {
        /**
         * Provide information about accounts discovered during the sync process
         * @param {SyncWorkerImportApiV2ConnectionsSyncIdImportAccountsPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v2ConnectionsSyncIdImportAccountsPost(requestParameters: SyncWorkerImportApiV2ConnectionsSyncIdImportAccountsPostRequest, options?: RawAxiosRequestConfig): AxiosPromise<DeleteAccount200Response> {
            return localVarFp.v2ConnectionsSyncIdImportAccountsPost(requestParameters.id, requestParameters.V2ConnectionsSyncIdImportAccountsPostRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Provide information about commitments discovered during the sync process
         * @param {SyncWorkerImportApiV2ConnectionsSyncIdImportCommitmentsPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v2ConnectionsSyncIdImportCommitmentsPost(requestParameters: SyncWorkerImportApiV2ConnectionsSyncIdImportCommitmentsPostRequest, options?: RawAxiosRequestConfig): AxiosPromise<DeleteAccount200Response> {
            return localVarFp.v2ConnectionsSyncIdImportCommitmentsPost(requestParameters.id, requestParameters.V2ConnectionsSyncIdImportCommitmentsPostRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Provide information about documents discovered during the sync process
         * @param {SyncWorkerImportApiV2ConnectionsSyncIdImportDocumentsPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v2ConnectionsSyncIdImportDocumentsPost(requestParameters: SyncWorkerImportApiV2ConnectionsSyncIdImportDocumentsPostRequest, options?: RawAxiosRequestConfig): AxiosPromise<V2ConnectionsSyncIdImportDocumentsPost200Response> {
            return localVarFp.v2ConnectionsSyncIdImportDocumentsPost(requestParameters.id, requestParameters.V2ConnectionsSyncIdImportDocumentsPostRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Provide information about investments discovered during the sync process
         * @param {SyncWorkerImportApiV2ConnectionsSyncIdImportInvestmentsPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v2ConnectionsSyncIdImportInvestmentsPost(requestParameters: SyncWorkerImportApiV2ConnectionsSyncIdImportInvestmentsPostRequest, options?: RawAxiosRequestConfig): AxiosPromise<DeleteAccount200Response> {
            return localVarFp.v2ConnectionsSyncIdImportInvestmentsPost(requestParameters.id, requestParameters.V2ConnectionsSyncIdImportInvestmentsPostRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Provide information about transactions discovered during the sync process
         * @param {SyncWorkerImportApiV2ConnectionsSyncIdImportTransactionsPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v2ConnectionsSyncIdImportTransactionsPost(requestParameters: SyncWorkerImportApiV2ConnectionsSyncIdImportTransactionsPostRequest, options?: RawAxiosRequestConfig): AxiosPromise<DeleteAccount200Response> {
            return localVarFp.v2ConnectionsSyncIdImportTransactionsPost(requestParameters.id, requestParameters.V2ConnectionsSyncIdImportTransactionsPostRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Provide information about users discovered during the sync process
         * @param {SyncWorkerImportApiV2ConnectionsSyncIdImportUsersPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v2ConnectionsSyncIdImportUsersPost(requestParameters: SyncWorkerImportApiV2ConnectionsSyncIdImportUsersPostRequest, options?: RawAxiosRequestConfig): AxiosPromise<DeleteAccount200Response> {
            return localVarFp.v2ConnectionsSyncIdImportUsersPost(requestParameters.id, requestParameters.V2ConnectionsSyncIdImportUsersPostRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Provide information about valuations discovered during the sync process
         * @param {SyncWorkerImportApiV2ConnectionsSyncIdImportValuationsPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v2ConnectionsSyncIdImportValuationsPost(requestParameters: SyncWorkerImportApiV2ConnectionsSyncIdImportValuationsPostRequest, options?: RawAxiosRequestConfig): AxiosPromise<DeleteAccount200Response> {
            return localVarFp.v2ConnectionsSyncIdImportValuationsPost(requestParameters.id, requestParameters.V2ConnectionsSyncIdImportValuationsPostRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for v2ConnectionsSyncIdImportAccountsPost operation in SyncWorkerImportApi.
 * @export
 * @interface SyncWorkerImportApiV2ConnectionsSyncIdImportAccountsPostRequest
 */
export interface SyncWorkerImportApiV2ConnectionsSyncIdImportAccountsPostRequest {
    /**
     * 
     * @type {string}
     * @memberof SyncWorkerImportApiV2ConnectionsSyncIdImportAccountsPost
     */
    readonly id: string

    /**
     * 
     * @type {V2ConnectionsSyncIdImportAccountsPostRequest}
     * @memberof SyncWorkerImportApiV2ConnectionsSyncIdImportAccountsPost
     */
    readonly V2ConnectionsSyncIdImportAccountsPostRequest: V2ConnectionsSyncIdImportAccountsPostRequest
}

/**
 * Request parameters for v2ConnectionsSyncIdImportCommitmentsPost operation in SyncWorkerImportApi.
 * @export
 * @interface SyncWorkerImportApiV2ConnectionsSyncIdImportCommitmentsPostRequest
 */
export interface SyncWorkerImportApiV2ConnectionsSyncIdImportCommitmentsPostRequest {
    /**
     * 
     * @type {string}
     * @memberof SyncWorkerImportApiV2ConnectionsSyncIdImportCommitmentsPost
     */
    readonly id: string

    /**
     * 
     * @type {V2ConnectionsSyncIdImportCommitmentsPostRequest}
     * @memberof SyncWorkerImportApiV2ConnectionsSyncIdImportCommitmentsPost
     */
    readonly V2ConnectionsSyncIdImportCommitmentsPostRequest: V2ConnectionsSyncIdImportCommitmentsPostRequest
}

/**
 * Request parameters for v2ConnectionsSyncIdImportDocumentsPost operation in SyncWorkerImportApi.
 * @export
 * @interface SyncWorkerImportApiV2ConnectionsSyncIdImportDocumentsPostRequest
 */
export interface SyncWorkerImportApiV2ConnectionsSyncIdImportDocumentsPostRequest {
    /**
     * 
     * @type {string}
     * @memberof SyncWorkerImportApiV2ConnectionsSyncIdImportDocumentsPost
     */
    readonly id: string

    /**
     * 
     * @type {V2ConnectionsSyncIdImportDocumentsPostRequest}
     * @memberof SyncWorkerImportApiV2ConnectionsSyncIdImportDocumentsPost
     */
    readonly V2ConnectionsSyncIdImportDocumentsPostRequest: V2ConnectionsSyncIdImportDocumentsPostRequest
}

/**
 * Request parameters for v2ConnectionsSyncIdImportInvestmentsPost operation in SyncWorkerImportApi.
 * @export
 * @interface SyncWorkerImportApiV2ConnectionsSyncIdImportInvestmentsPostRequest
 */
export interface SyncWorkerImportApiV2ConnectionsSyncIdImportInvestmentsPostRequest {
    /**
     * 
     * @type {string}
     * @memberof SyncWorkerImportApiV2ConnectionsSyncIdImportInvestmentsPost
     */
    readonly id: string

    /**
     * 
     * @type {V2ConnectionsSyncIdImportInvestmentsPostRequest}
     * @memberof SyncWorkerImportApiV2ConnectionsSyncIdImportInvestmentsPost
     */
    readonly V2ConnectionsSyncIdImportInvestmentsPostRequest: V2ConnectionsSyncIdImportInvestmentsPostRequest
}

/**
 * Request parameters for v2ConnectionsSyncIdImportTransactionsPost operation in SyncWorkerImportApi.
 * @export
 * @interface SyncWorkerImportApiV2ConnectionsSyncIdImportTransactionsPostRequest
 */
export interface SyncWorkerImportApiV2ConnectionsSyncIdImportTransactionsPostRequest {
    /**
     * 
     * @type {string}
     * @memberof SyncWorkerImportApiV2ConnectionsSyncIdImportTransactionsPost
     */
    readonly id: string

    /**
     * 
     * @type {V2ConnectionsSyncIdImportTransactionsPostRequest}
     * @memberof SyncWorkerImportApiV2ConnectionsSyncIdImportTransactionsPost
     */
    readonly V2ConnectionsSyncIdImportTransactionsPostRequest: V2ConnectionsSyncIdImportTransactionsPostRequest
}

/**
 * Request parameters for v2ConnectionsSyncIdImportUsersPost operation in SyncWorkerImportApi.
 * @export
 * @interface SyncWorkerImportApiV2ConnectionsSyncIdImportUsersPostRequest
 */
export interface SyncWorkerImportApiV2ConnectionsSyncIdImportUsersPostRequest {
    /**
     * 
     * @type {string}
     * @memberof SyncWorkerImportApiV2ConnectionsSyncIdImportUsersPost
     */
    readonly id: string

    /**
     * 
     * @type {V2ConnectionsSyncIdImportUsersPostRequest}
     * @memberof SyncWorkerImportApiV2ConnectionsSyncIdImportUsersPost
     */
    readonly V2ConnectionsSyncIdImportUsersPostRequest: V2ConnectionsSyncIdImportUsersPostRequest
}

/**
 * Request parameters for v2ConnectionsSyncIdImportValuationsPost operation in SyncWorkerImportApi.
 * @export
 * @interface SyncWorkerImportApiV2ConnectionsSyncIdImportValuationsPostRequest
 */
export interface SyncWorkerImportApiV2ConnectionsSyncIdImportValuationsPostRequest {
    /**
     * 
     * @type {string}
     * @memberof SyncWorkerImportApiV2ConnectionsSyncIdImportValuationsPost
     */
    readonly id: string

    /**
     * 
     * @type {V2ConnectionsSyncIdImportValuationsPostRequest}
     * @memberof SyncWorkerImportApiV2ConnectionsSyncIdImportValuationsPost
     */
    readonly V2ConnectionsSyncIdImportValuationsPostRequest: V2ConnectionsSyncIdImportValuationsPostRequest
}

/**
 * SyncWorkerImportApi - object-oriented interface
 * @export
 * @class SyncWorkerImportApi
 * @extends {BaseAPI}
 */
export class SyncWorkerImportApi extends BaseAPI {
    /**
     * Provide information about accounts discovered during the sync process
     * @param {SyncWorkerImportApiV2ConnectionsSyncIdImportAccountsPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SyncWorkerImportApi
     */
    public v2ConnectionsSyncIdImportAccountsPost(requestParameters: SyncWorkerImportApiV2ConnectionsSyncIdImportAccountsPostRequest, options?: RawAxiosRequestConfig) {
        return SyncWorkerImportApiFp(this.configuration).v2ConnectionsSyncIdImportAccountsPost(requestParameters.id, requestParameters.V2ConnectionsSyncIdImportAccountsPostRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Provide information about commitments discovered during the sync process
     * @param {SyncWorkerImportApiV2ConnectionsSyncIdImportCommitmentsPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SyncWorkerImportApi
     */
    public v2ConnectionsSyncIdImportCommitmentsPost(requestParameters: SyncWorkerImportApiV2ConnectionsSyncIdImportCommitmentsPostRequest, options?: RawAxiosRequestConfig) {
        return SyncWorkerImportApiFp(this.configuration).v2ConnectionsSyncIdImportCommitmentsPost(requestParameters.id, requestParameters.V2ConnectionsSyncIdImportCommitmentsPostRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Provide information about documents discovered during the sync process
     * @param {SyncWorkerImportApiV2ConnectionsSyncIdImportDocumentsPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SyncWorkerImportApi
     */
    public v2ConnectionsSyncIdImportDocumentsPost(requestParameters: SyncWorkerImportApiV2ConnectionsSyncIdImportDocumentsPostRequest, options?: RawAxiosRequestConfig) {
        return SyncWorkerImportApiFp(this.configuration).v2ConnectionsSyncIdImportDocumentsPost(requestParameters.id, requestParameters.V2ConnectionsSyncIdImportDocumentsPostRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Provide information about investments discovered during the sync process
     * @param {SyncWorkerImportApiV2ConnectionsSyncIdImportInvestmentsPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SyncWorkerImportApi
     */
    public v2ConnectionsSyncIdImportInvestmentsPost(requestParameters: SyncWorkerImportApiV2ConnectionsSyncIdImportInvestmentsPostRequest, options?: RawAxiosRequestConfig) {
        return SyncWorkerImportApiFp(this.configuration).v2ConnectionsSyncIdImportInvestmentsPost(requestParameters.id, requestParameters.V2ConnectionsSyncIdImportInvestmentsPostRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Provide information about transactions discovered during the sync process
     * @param {SyncWorkerImportApiV2ConnectionsSyncIdImportTransactionsPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SyncWorkerImportApi
     */
    public v2ConnectionsSyncIdImportTransactionsPost(requestParameters: SyncWorkerImportApiV2ConnectionsSyncIdImportTransactionsPostRequest, options?: RawAxiosRequestConfig) {
        return SyncWorkerImportApiFp(this.configuration).v2ConnectionsSyncIdImportTransactionsPost(requestParameters.id, requestParameters.V2ConnectionsSyncIdImportTransactionsPostRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Provide information about users discovered during the sync process
     * @param {SyncWorkerImportApiV2ConnectionsSyncIdImportUsersPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SyncWorkerImportApi
     */
    public v2ConnectionsSyncIdImportUsersPost(requestParameters: SyncWorkerImportApiV2ConnectionsSyncIdImportUsersPostRequest, options?: RawAxiosRequestConfig) {
        return SyncWorkerImportApiFp(this.configuration).v2ConnectionsSyncIdImportUsersPost(requestParameters.id, requestParameters.V2ConnectionsSyncIdImportUsersPostRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Provide information about valuations discovered during the sync process
     * @param {SyncWorkerImportApiV2ConnectionsSyncIdImportValuationsPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SyncWorkerImportApi
     */
    public v2ConnectionsSyncIdImportValuationsPost(requestParameters: SyncWorkerImportApiV2ConnectionsSyncIdImportValuationsPostRequest, options?: RawAxiosRequestConfig) {
        return SyncWorkerImportApiFp(this.configuration).v2ConnectionsSyncIdImportValuationsPost(requestParameters.id, requestParameters.V2ConnectionsSyncIdImportValuationsPostRequest, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * TransactionApi - axios parameter creator
 * @export
 */
export const TransactionApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Create a new Transaction document.
         * @param {CreateTransactionRequest} CreateTransactionRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createTransaction: async (CreateTransactionRequest: CreateTransactionRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'CreateTransactionRequest' is not null or undefined
            assertParamExists('createTransaction', 'CreateTransactionRequest', CreateTransactionRequest)
            const localVarPath = `/v2/transactions/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication adminAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication advisorAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication investorAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(CreateTransactionRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Delete a Transaction document.
         * @param {string} transaction_id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteTransaction: async (transaction_id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'transaction_id' is not null or undefined
            assertParamExists('deleteTransaction', 'transaction_id', transaction_id)
            const localVarPath = `/v2/transactions/{transaction_id}`
                .replace(`{${"transaction_id"}}`, encodeURIComponent(String(transaction_id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication adminAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication advisorAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication investorAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get a single Transaction document by ID.
         * @param {string} transaction_id 
         * @param {boolean} [populate_user] 
         * @param {boolean} [populate_account] 
         * @param {boolean} [populate_investment] 
         * @param {boolean} [populate_document] 
         * @param {boolean} [populate_connection] 
         * @param {boolean} [populate_audit_log] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTransaction: async (transaction_id: string, populate_user?: boolean, populate_account?: boolean, populate_investment?: boolean, populate_document?: boolean, populate_connection?: boolean, populate_audit_log?: boolean, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'transaction_id' is not null or undefined
            assertParamExists('getTransaction', 'transaction_id', transaction_id)
            const localVarPath = `/v2/transactions/{transaction_id}`
                .replace(`{${"transaction_id"}}`, encodeURIComponent(String(transaction_id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication adminAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication advisorAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication investorAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (populate_user !== undefined) {
                localVarQueryParameter['populate_user'] = populate_user;
            }

            if (populate_account !== undefined) {
                localVarQueryParameter['populate_account'] = populate_account;
            }

            if (populate_investment !== undefined) {
                localVarQueryParameter['populate_investment'] = populate_investment;
            }

            if (populate_document !== undefined) {
                localVarQueryParameter['populate_document'] = populate_document;
            }

            if (populate_connection !== undefined) {
                localVarQueryParameter['populate_connection'] = populate_connection;
            }

            if (populate_audit_log !== undefined) {
                localVarQueryParameter['populate_audit_log'] = populate_audit_log;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Paginate through Transaction documents.
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {string} [search] 
         * @param {ListTransactionsSortFieldEnum} [sort_field] 
         * @param {ListTransactionsSortDirectionEnum} [sort_direction] 
         * @param {Array<string>} [users] 
         * @param {Array<string>} [accounts] 
         * @param {Array<string>} [investments] 
         * @param {Array<string>} [investment_masters] 
         * @param {boolean} [hidden] 
         * @param {boolean} [inactive] 
         * @param {boolean} [populate_user] 
         * @param {boolean} [populate_account] 
         * @param {boolean} [populate_investment] 
         * @param {boolean} [populate_document] 
         * @param {boolean} [populate_connection] 
         * @param {boolean} [populate_audit_log] 
         * @param {ListTransactionsJointTransactionDisplayModeEnum} [joint_transaction_display_mode] 
         * @param {Array<string>} [types] 
         * @param {boolean} [open] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listTransactions: async (skip?: number, limit?: number, search?: string, sort_field?: ListTransactionsSortFieldEnum, sort_direction?: ListTransactionsSortDirectionEnum, users?: Array<string>, accounts?: Array<string>, investments?: Array<string>, investment_masters?: Array<string>, hidden?: boolean, inactive?: boolean, populate_user?: boolean, populate_account?: boolean, populate_investment?: boolean, populate_document?: boolean, populate_connection?: boolean, populate_audit_log?: boolean, joint_transaction_display_mode?: ListTransactionsJointTransactionDisplayModeEnum, types?: Array<string>, open?: boolean, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v2/transactions/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication adminAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication advisorAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication investorAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (skip !== undefined) {
                localVarQueryParameter['skip'] = skip;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (sort_field !== undefined) {
                localVarQueryParameter['sort_field'] = sort_field;
            }

            if (sort_direction !== undefined) {
                localVarQueryParameter['sort_direction'] = sort_direction;
            }

            if (users) {
                localVarQueryParameter['users'] = users;
            }

            if (accounts) {
                localVarQueryParameter['accounts'] = accounts;
            }

            if (investments) {
                localVarQueryParameter['investments'] = investments;
            }

            if (investment_masters) {
                localVarQueryParameter['investment_masters'] = investment_masters;
            }

            if (hidden !== undefined) {
                localVarQueryParameter['hidden'] = hidden;
            }

            if (inactive !== undefined) {
                localVarQueryParameter['inactive'] = inactive;
            }

            if (populate_user !== undefined) {
                localVarQueryParameter['populate_user'] = populate_user;
            }

            if (populate_account !== undefined) {
                localVarQueryParameter['populate_account'] = populate_account;
            }

            if (populate_investment !== undefined) {
                localVarQueryParameter['populate_investment'] = populate_investment;
            }

            if (populate_document !== undefined) {
                localVarQueryParameter['populate_document'] = populate_document;
            }

            if (populate_connection !== undefined) {
                localVarQueryParameter['populate_connection'] = populate_connection;
            }

            if (populate_audit_log !== undefined) {
                localVarQueryParameter['populate_audit_log'] = populate_audit_log;
            }

            if (joint_transaction_display_mode !== undefined) {
                localVarQueryParameter['joint_transaction_display_mode'] = joint_transaction_display_mode;
            }

            if (types) {
                localVarQueryParameter['types'] = types;
            }

            if (open !== undefined) {
                localVarQueryParameter['open'] = open;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update an existing Transaction document.
         * @param {string} transaction_id 
         * @param {UpdateTransactionRequest} [UpdateTransactionRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateTransaction: async (transaction_id: string, UpdateTransactionRequest?: UpdateTransactionRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'transaction_id' is not null or undefined
            assertParamExists('updateTransaction', 'transaction_id', transaction_id)
            const localVarPath = `/v2/transactions/{transaction_id}`
                .replace(`{${"transaction_id"}}`, encodeURIComponent(String(transaction_id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication adminAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication advisorAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication investorAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(UpdateTransactionRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * TransactionApi - functional programming interface
 * @export
 */
export const TransactionApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = TransactionApiAxiosParamCreator(configuration)
    return {
        /**
         * Create a new Transaction document.
         * @param {CreateTransactionRequest} CreateTransactionRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createTransaction(CreateTransactionRequest: CreateTransactionRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CreateTransaction201Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createTransaction(CreateTransactionRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['TransactionApi.createTransaction']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Delete a Transaction document.
         * @param {string} transaction_id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteTransaction(transaction_id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DeleteAccount200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteTransaction(transaction_id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['TransactionApi.deleteTransaction']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Get a single Transaction document by ID.
         * @param {string} transaction_id 
         * @param {boolean} [populate_user] 
         * @param {boolean} [populate_account] 
         * @param {boolean} [populate_investment] 
         * @param {boolean} [populate_document] 
         * @param {boolean} [populate_connection] 
         * @param {boolean} [populate_audit_log] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTransaction(transaction_id: string, populate_user?: boolean, populate_account?: boolean, populate_investment?: boolean, populate_document?: boolean, populate_connection?: boolean, populate_audit_log?: boolean, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetTransaction200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTransaction(transaction_id, populate_user, populate_account, populate_investment, populate_document, populate_connection, populate_audit_log, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['TransactionApi.getTransaction']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Paginate through Transaction documents.
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {string} [search] 
         * @param {ListTransactionsSortFieldEnum} [sort_field] 
         * @param {ListTransactionsSortDirectionEnum} [sort_direction] 
         * @param {Array<string>} [users] 
         * @param {Array<string>} [accounts] 
         * @param {Array<string>} [investments] 
         * @param {Array<string>} [investment_masters] 
         * @param {boolean} [hidden] 
         * @param {boolean} [inactive] 
         * @param {boolean} [populate_user] 
         * @param {boolean} [populate_account] 
         * @param {boolean} [populate_investment] 
         * @param {boolean} [populate_document] 
         * @param {boolean} [populate_connection] 
         * @param {boolean} [populate_audit_log] 
         * @param {ListTransactionsJointTransactionDisplayModeEnum} [joint_transaction_display_mode] 
         * @param {Array<string>} [types] 
         * @param {boolean} [open] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listTransactions(skip?: number, limit?: number, search?: string, sort_field?: ListTransactionsSortFieldEnum, sort_direction?: ListTransactionsSortDirectionEnum, users?: Array<string>, accounts?: Array<string>, investments?: Array<string>, investment_masters?: Array<string>, hidden?: boolean, inactive?: boolean, populate_user?: boolean, populate_account?: boolean, populate_investment?: boolean, populate_document?: boolean, populate_connection?: boolean, populate_audit_log?: boolean, joint_transaction_display_mode?: ListTransactionsJointTransactionDisplayModeEnum, types?: Array<string>, open?: boolean, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListTransactions200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listTransactions(skip, limit, search, sort_field, sort_direction, users, accounts, investments, investment_masters, hidden, inactive, populate_user, populate_account, populate_investment, populate_document, populate_connection, populate_audit_log, joint_transaction_display_mode, types, open, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['TransactionApi.listTransactions']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Update an existing Transaction document.
         * @param {string} transaction_id 
         * @param {UpdateTransactionRequest} [UpdateTransactionRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateTransaction(transaction_id: string, UpdateTransactionRequest?: UpdateTransactionRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DeleteAccount200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateTransaction(transaction_id, UpdateTransactionRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['TransactionApi.updateTransaction']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * TransactionApi - factory interface
 * @export
 */
export const TransactionApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = TransactionApiFp(configuration)
    return {
        /**
         * Create a new Transaction document.
         * @param {TransactionApiCreateTransactionRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createTransaction(requestParameters: TransactionApiCreateTransactionRequest, options?: RawAxiosRequestConfig): AxiosPromise<CreateTransaction201Response> {
            return localVarFp.createTransaction(requestParameters.CreateTransactionRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Delete a Transaction document.
         * @param {TransactionApiDeleteTransactionRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteTransaction(requestParameters: TransactionApiDeleteTransactionRequest, options?: RawAxiosRequestConfig): AxiosPromise<DeleteAccount200Response> {
            return localVarFp.deleteTransaction(requestParameters.transaction_id, options).then((request) => request(axios, basePath));
        },
        /**
         * Get a single Transaction document by ID.
         * @param {TransactionApiGetTransactionRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTransaction(requestParameters: TransactionApiGetTransactionRequest, options?: RawAxiosRequestConfig): AxiosPromise<GetTransaction200Response> {
            return localVarFp.getTransaction(requestParameters.transaction_id, requestParameters.populate_user, requestParameters.populate_account, requestParameters.populate_investment, requestParameters.populate_document, requestParameters.populate_connection, requestParameters.populate_audit_log, options).then((request) => request(axios, basePath));
        },
        /**
         * Paginate through Transaction documents.
         * @param {TransactionApiListTransactionsRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listTransactions(requestParameters: TransactionApiListTransactionsRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<ListTransactions200Response> {
            return localVarFp.listTransactions(requestParameters.skip, requestParameters.limit, requestParameters.search, requestParameters.sort_field, requestParameters.sort_direction, requestParameters.users, requestParameters.accounts, requestParameters.investments, requestParameters.investment_masters, requestParameters.hidden, requestParameters.inactive, requestParameters.populate_user, requestParameters.populate_account, requestParameters.populate_investment, requestParameters.populate_document, requestParameters.populate_connection, requestParameters.populate_audit_log, requestParameters.joint_transaction_display_mode, requestParameters.types, requestParameters.open, options).then((request) => request(axios, basePath));
        },
        /**
         * Update an existing Transaction document.
         * @param {TransactionApiUpdateTransactionRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateTransaction(requestParameters: TransactionApiUpdateTransactionRequest, options?: RawAxiosRequestConfig): AxiosPromise<DeleteAccount200Response> {
            return localVarFp.updateTransaction(requestParameters.transaction_id, requestParameters.UpdateTransactionRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for createTransaction operation in TransactionApi.
 * @export
 * @interface TransactionApiCreateTransactionRequest
 */
export interface TransactionApiCreateTransactionRequest {
    /**
     * 
     * @type {CreateTransactionRequest}
     * @memberof TransactionApiCreateTransaction
     */
    readonly CreateTransactionRequest: CreateTransactionRequest
}

/**
 * Request parameters for deleteTransaction operation in TransactionApi.
 * @export
 * @interface TransactionApiDeleteTransactionRequest
 */
export interface TransactionApiDeleteTransactionRequest {
    /**
     * 
     * @type {string}
     * @memberof TransactionApiDeleteTransaction
     */
    readonly transaction_id: string
}

/**
 * Request parameters for getTransaction operation in TransactionApi.
 * @export
 * @interface TransactionApiGetTransactionRequest
 */
export interface TransactionApiGetTransactionRequest {
    /**
     * 
     * @type {string}
     * @memberof TransactionApiGetTransaction
     */
    readonly transaction_id: string

    /**
     * 
     * @type {boolean}
     * @memberof TransactionApiGetTransaction
     */
    readonly populate_user?: boolean

    /**
     * 
     * @type {boolean}
     * @memberof TransactionApiGetTransaction
     */
    readonly populate_account?: boolean

    /**
     * 
     * @type {boolean}
     * @memberof TransactionApiGetTransaction
     */
    readonly populate_investment?: boolean

    /**
     * 
     * @type {boolean}
     * @memberof TransactionApiGetTransaction
     */
    readonly populate_document?: boolean

    /**
     * 
     * @type {boolean}
     * @memberof TransactionApiGetTransaction
     */
    readonly populate_connection?: boolean

    /**
     * 
     * @type {boolean}
     * @memberof TransactionApiGetTransaction
     */
    readonly populate_audit_log?: boolean
}

/**
 * Request parameters for listTransactions operation in TransactionApi.
 * @export
 * @interface TransactionApiListTransactionsRequest
 */
export interface TransactionApiListTransactionsRequest {
    /**
     * 
     * @type {number}
     * @memberof TransactionApiListTransactions
     */
    readonly skip?: number

    /**
     * 
     * @type {number}
     * @memberof TransactionApiListTransactions
     */
    readonly limit?: number

    /**
     * 
     * @type {string}
     * @memberof TransactionApiListTransactions
     */
    readonly search?: string

    /**
     * 
     * @type {'date' | 'amount' | 'type'}
     * @memberof TransactionApiListTransactions
     */
    readonly sort_field?: ListTransactionsSortFieldEnum

    /**
     * 
     * @type {'asc' | 'desc'}
     * @memberof TransactionApiListTransactions
     */
    readonly sort_direction?: ListTransactionsSortDirectionEnum

    /**
     * 
     * @type {Array<string>}
     * @memberof TransactionApiListTransactions
     */
    readonly users?: Array<string>

    /**
     * 
     * @type {Array<string>}
     * @memberof TransactionApiListTransactions
     */
    readonly accounts?: Array<string>

    /**
     * 
     * @type {Array<string>}
     * @memberof TransactionApiListTransactions
     */
    readonly investments?: Array<string>

    /**
     * 
     * @type {Array<string>}
     * @memberof TransactionApiListTransactions
     */
    readonly investment_masters?: Array<string>

    /**
     * 
     * @type {boolean}
     * @memberof TransactionApiListTransactions
     */
    readonly hidden?: boolean

    /**
     * 
     * @type {boolean}
     * @memberof TransactionApiListTransactions
     */
    readonly inactive?: boolean

    /**
     * 
     * @type {boolean}
     * @memberof TransactionApiListTransactions
     */
    readonly populate_user?: boolean

    /**
     * 
     * @type {boolean}
     * @memberof TransactionApiListTransactions
     */
    readonly populate_account?: boolean

    /**
     * 
     * @type {boolean}
     * @memberof TransactionApiListTransactions
     */
    readonly populate_investment?: boolean

    /**
     * 
     * @type {boolean}
     * @memberof TransactionApiListTransactions
     */
    readonly populate_document?: boolean

    /**
     * 
     * @type {boolean}
     * @memberof TransactionApiListTransactions
     */
    readonly populate_connection?: boolean

    /**
     * 
     * @type {boolean}
     * @memberof TransactionApiListTransactions
     */
    readonly populate_audit_log?: boolean

    /**
     * 
     * @type {'parents' | 'children'}
     * @memberof TransactionApiListTransactions
     */
    readonly joint_transaction_display_mode?: ListTransactionsJointTransactionDisplayModeEnum

    /**
     * 
     * @type {Array<string>}
     * @memberof TransactionApiListTransactions
     */
    readonly types?: Array<string>

    /**
     * 
     * @type {boolean}
     * @memberof TransactionApiListTransactions
     */
    readonly open?: boolean
}

/**
 * Request parameters for updateTransaction operation in TransactionApi.
 * @export
 * @interface TransactionApiUpdateTransactionRequest
 */
export interface TransactionApiUpdateTransactionRequest {
    /**
     * 
     * @type {string}
     * @memberof TransactionApiUpdateTransaction
     */
    readonly transaction_id: string

    /**
     * 
     * @type {UpdateTransactionRequest}
     * @memberof TransactionApiUpdateTransaction
     */
    readonly UpdateTransactionRequest?: UpdateTransactionRequest
}

/**
 * TransactionApi - object-oriented interface
 * @export
 * @class TransactionApi
 * @extends {BaseAPI}
 */
export class TransactionApi extends BaseAPI {
    /**
     * Create a new Transaction document.
     * @param {TransactionApiCreateTransactionRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TransactionApi
     */
    public createTransaction(requestParameters: TransactionApiCreateTransactionRequest, options?: RawAxiosRequestConfig) {
        return TransactionApiFp(this.configuration).createTransaction(requestParameters.CreateTransactionRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Delete a Transaction document.
     * @param {TransactionApiDeleteTransactionRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TransactionApi
     */
    public deleteTransaction(requestParameters: TransactionApiDeleteTransactionRequest, options?: RawAxiosRequestConfig) {
        return TransactionApiFp(this.configuration).deleteTransaction(requestParameters.transaction_id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get a single Transaction document by ID.
     * @param {TransactionApiGetTransactionRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TransactionApi
     */
    public getTransaction(requestParameters: TransactionApiGetTransactionRequest, options?: RawAxiosRequestConfig) {
        return TransactionApiFp(this.configuration).getTransaction(requestParameters.transaction_id, requestParameters.populate_user, requestParameters.populate_account, requestParameters.populate_investment, requestParameters.populate_document, requestParameters.populate_connection, requestParameters.populate_audit_log, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Paginate through Transaction documents.
     * @param {TransactionApiListTransactionsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TransactionApi
     */
    public listTransactions(requestParameters: TransactionApiListTransactionsRequest = {}, options?: RawAxiosRequestConfig) {
        return TransactionApiFp(this.configuration).listTransactions(requestParameters.skip, requestParameters.limit, requestParameters.search, requestParameters.sort_field, requestParameters.sort_direction, requestParameters.users, requestParameters.accounts, requestParameters.investments, requestParameters.investment_masters, requestParameters.hidden, requestParameters.inactive, requestParameters.populate_user, requestParameters.populate_account, requestParameters.populate_investment, requestParameters.populate_document, requestParameters.populate_connection, requestParameters.populate_audit_log, requestParameters.joint_transaction_display_mode, requestParameters.types, requestParameters.open, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Update an existing Transaction document.
     * @param {TransactionApiUpdateTransactionRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TransactionApi
     */
    public updateTransaction(requestParameters: TransactionApiUpdateTransactionRequest, options?: RawAxiosRequestConfig) {
        return TransactionApiFp(this.configuration).updateTransaction(requestParameters.transaction_id, requestParameters.UpdateTransactionRequest, options).then((request) => request(this.axios, this.basePath));
    }
}

/**
 * @export
 */
export const ListTransactionsSortFieldEnum = {
    Date: 'date',
    Amount: 'amount',
    Type: 'type'
} as const;
export type ListTransactionsSortFieldEnum = typeof ListTransactionsSortFieldEnum[keyof typeof ListTransactionsSortFieldEnum];
/**
 * @export
 */
export const ListTransactionsSortDirectionEnum = {
    Asc: 'asc',
    Desc: 'desc'
} as const;
export type ListTransactionsSortDirectionEnum = typeof ListTransactionsSortDirectionEnum[keyof typeof ListTransactionsSortDirectionEnum];
/**
 * @export
 */
export const ListTransactionsJointTransactionDisplayModeEnum = {
    Parents: 'parents',
    Children: 'children'
} as const;
export type ListTransactionsJointTransactionDisplayModeEnum = typeof ListTransactionsJointTransactionDisplayModeEnum[keyof typeof ListTransactionsJointTransactionDisplayModeEnum];


/**
 * UserApi - axios parameter creator
 * @export
 */
export const UserApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Get users
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {ListUsersSortFieldEnum} [sort_field] 
         * @param {ListUsersSortDirectionEnum} [sort_direction] 
         * @param {boolean} [populate_accounts] 
         * @param {boolean} [populate_investments] 
         * @param {boolean} [populate_advisor_groups] 
         * @param {boolean} [populate_advisory_group_memberships] 
         * @param {boolean} [include_deleted] 
         * @param {string} [account_search] 
         * @param {Array<string>} [users] 
         * @param {Array<string>} [accounts] 
         * @param {ListUsersAccessEnum} [access] 
         * @param {string} [search] 
         * @param {Array<string>} [advisory_groups] 
         * @param {boolean} [include_has_password] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listUsers: async (skip?: number, limit?: number, sort_field?: ListUsersSortFieldEnum, sort_direction?: ListUsersSortDirectionEnum, populate_accounts?: boolean, populate_investments?: boolean, populate_advisor_groups?: boolean, populate_advisory_group_memberships?: boolean, include_deleted?: boolean, account_search?: string, users?: Array<string>, accounts?: Array<string>, access?: ListUsersAccessEnum, search?: string, advisory_groups?: Array<string>, include_has_password?: boolean, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v2/users/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication adminAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication advisorAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication investorAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (skip !== undefined) {
                localVarQueryParameter['skip'] = skip;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (sort_field !== undefined) {
                localVarQueryParameter['sort_field'] = sort_field;
            }

            if (sort_direction !== undefined) {
                localVarQueryParameter['sort_direction'] = sort_direction;
            }

            if (populate_accounts !== undefined) {
                localVarQueryParameter['populate_accounts'] = populate_accounts;
            }

            if (populate_investments !== undefined) {
                localVarQueryParameter['populate_investments'] = populate_investments;
            }

            if (populate_advisor_groups !== undefined) {
                localVarQueryParameter['populate_advisor_groups'] = populate_advisor_groups;
            }

            if (populate_advisory_group_memberships !== undefined) {
                localVarQueryParameter['populate_advisory_group_memberships'] = populate_advisory_group_memberships;
            }

            if (include_deleted !== undefined) {
                localVarQueryParameter['include_deleted'] = include_deleted;
            }

            if (account_search !== undefined) {
                localVarQueryParameter['account_search'] = account_search;
            }

            if (users) {
                localVarQueryParameter['users'] = users;
            }

            if (accounts) {
                localVarQueryParameter['accounts'] = accounts;
            }

            if (access !== undefined) {
                localVarQueryParameter['access'] = access;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (advisory_groups) {
                localVarQueryParameter['advisory_groups'] = advisory_groups;
            }

            if (include_has_password !== undefined) {
                localVarQueryParameter['include_has_password'] = include_has_password;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Login an existing user with Google Identity API.
         * @param {LoginUserWithGoogleRequest} LoginUserWithGoogleRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        loginUserWithGoogle: async (LoginUserWithGoogleRequest: LoginUserWithGoogleRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'LoginUserWithGoogleRequest' is not null or undefined
            assertParamExists('loginUserWithGoogle', 'LoginUserWithGoogleRequest', LoginUserWithGoogleRequest)
            const localVarPath = `/v2/users/google`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(LoginUserWithGoogleRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UserApi - functional programming interface
 * @export
 */
export const UserApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = UserApiAxiosParamCreator(configuration)
    return {
        /**
         * Get users
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {ListUsersSortFieldEnum} [sort_field] 
         * @param {ListUsersSortDirectionEnum} [sort_direction] 
         * @param {boolean} [populate_accounts] 
         * @param {boolean} [populate_investments] 
         * @param {boolean} [populate_advisor_groups] 
         * @param {boolean} [populate_advisory_group_memberships] 
         * @param {boolean} [include_deleted] 
         * @param {string} [account_search] 
         * @param {Array<string>} [users] 
         * @param {Array<string>} [accounts] 
         * @param {ListUsersAccessEnum} [access] 
         * @param {string} [search] 
         * @param {Array<string>} [advisory_groups] 
         * @param {boolean} [include_has_password] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listUsers(skip?: number, limit?: number, sort_field?: ListUsersSortFieldEnum, sort_direction?: ListUsersSortDirectionEnum, populate_accounts?: boolean, populate_investments?: boolean, populate_advisor_groups?: boolean, populate_advisory_group_memberships?: boolean, include_deleted?: boolean, account_search?: string, users?: Array<string>, accounts?: Array<string>, access?: ListUsersAccessEnum, search?: string, advisory_groups?: Array<string>, include_has_password?: boolean, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListUsers200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listUsers(skip, limit, sort_field, sort_direction, populate_accounts, populate_investments, populate_advisor_groups, populate_advisory_group_memberships, include_deleted, account_search, users, accounts, access, search, advisory_groups, include_has_password, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UserApi.listUsers']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Login an existing user with Google Identity API.
         * @param {LoginUserWithGoogleRequest} LoginUserWithGoogleRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async loginUserWithGoogle(LoginUserWithGoogleRequest: LoginUserWithGoogleRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LoginUserWithGoogle200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.loginUserWithGoogle(LoginUserWithGoogleRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UserApi.loginUserWithGoogle']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * UserApi - factory interface
 * @export
 */
export const UserApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = UserApiFp(configuration)
    return {
        /**
         * Get users
         * @param {UserApiListUsersRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listUsers(requestParameters: UserApiListUsersRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<ListUsers200Response> {
            return localVarFp.listUsers(requestParameters.skip, requestParameters.limit, requestParameters.sort_field, requestParameters.sort_direction, requestParameters.populate_accounts, requestParameters.populate_investments, requestParameters.populate_advisor_groups, requestParameters.populate_advisory_group_memberships, requestParameters.include_deleted, requestParameters.account_search, requestParameters.users, requestParameters.accounts, requestParameters.access, requestParameters.search, requestParameters.advisory_groups, requestParameters.include_has_password, options).then((request) => request(axios, basePath));
        },
        /**
         * Login an existing user with Google Identity API.
         * @param {UserApiLoginUserWithGoogleRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        loginUserWithGoogle(requestParameters: UserApiLoginUserWithGoogleRequest, options?: RawAxiosRequestConfig): AxiosPromise<LoginUserWithGoogle200Response> {
            return localVarFp.loginUserWithGoogle(requestParameters.LoginUserWithGoogleRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for listUsers operation in UserApi.
 * @export
 * @interface UserApiListUsersRequest
 */
export interface UserApiListUsersRequest {
    /**
     * 
     * @type {number}
     * @memberof UserApiListUsers
     */
    readonly skip?: number

    /**
     * 
     * @type {number}
     * @memberof UserApiListUsers
     */
    readonly limit?: number

    /**
     * 
     * @type {'name' | 'email' | 'email_verification.verified' | 'type' | 'status' | 'access'}
     * @memberof UserApiListUsers
     */
    readonly sort_field?: ListUsersSortFieldEnum

    /**
     * 
     * @type {'asc' | 'desc'}
     * @memberof UserApiListUsers
     */
    readonly sort_direction?: ListUsersSortDirectionEnum

    /**
     * 
     * @type {boolean}
     * @memberof UserApiListUsers
     */
    readonly populate_accounts?: boolean

    /**
     * 
     * @type {boolean}
     * @memberof UserApiListUsers
     */
    readonly populate_investments?: boolean

    /**
     * 
     * @type {boolean}
     * @memberof UserApiListUsers
     */
    readonly populate_advisor_groups?: boolean

    /**
     * 
     * @type {boolean}
     * @memberof UserApiListUsers
     */
    readonly populate_advisory_group_memberships?: boolean

    /**
     * 
     * @type {boolean}
     * @memberof UserApiListUsers
     */
    readonly include_deleted?: boolean

    /**
     * 
     * @type {string}
     * @memberof UserApiListUsers
     */
    readonly account_search?: string

    /**
     * 
     * @type {Array<string>}
     * @memberof UserApiListUsers
     */
    readonly users?: Array<string>

    /**
     * 
     * @type {Array<string>}
     * @memberof UserApiListUsers
     */
    readonly accounts?: Array<string>

    /**
     * 
     * @type {'admin' | 'advisor' | 'investor'}
     * @memberof UserApiListUsers
     */
    readonly access?: ListUsersAccessEnum

    /**
     * 
     * @type {string}
     * @memberof UserApiListUsers
     */
    readonly search?: string

    /**
     * 
     * @type {Array<string>}
     * @memberof UserApiListUsers
     */
    readonly advisory_groups?: Array<string>

    /**
     * 
     * @type {boolean}
     * @memberof UserApiListUsers
     */
    readonly include_has_password?: boolean
}

/**
 * Request parameters for loginUserWithGoogle operation in UserApi.
 * @export
 * @interface UserApiLoginUserWithGoogleRequest
 */
export interface UserApiLoginUserWithGoogleRequest {
    /**
     * 
     * @type {LoginUserWithGoogleRequest}
     * @memberof UserApiLoginUserWithGoogle
     */
    readonly LoginUserWithGoogleRequest: LoginUserWithGoogleRequest
}

/**
 * UserApi - object-oriented interface
 * @export
 * @class UserApi
 * @extends {BaseAPI}
 */
export class UserApi extends BaseAPI {
    /**
     * Get users
     * @param {UserApiListUsersRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public listUsers(requestParameters: UserApiListUsersRequest = {}, options?: RawAxiosRequestConfig) {
        return UserApiFp(this.configuration).listUsers(requestParameters.skip, requestParameters.limit, requestParameters.sort_field, requestParameters.sort_direction, requestParameters.populate_accounts, requestParameters.populate_investments, requestParameters.populate_advisor_groups, requestParameters.populate_advisory_group_memberships, requestParameters.include_deleted, requestParameters.account_search, requestParameters.users, requestParameters.accounts, requestParameters.access, requestParameters.search, requestParameters.advisory_groups, requestParameters.include_has_password, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Login an existing user with Google Identity API.
     * @param {UserApiLoginUserWithGoogleRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public loginUserWithGoogle(requestParameters: UserApiLoginUserWithGoogleRequest, options?: RawAxiosRequestConfig) {
        return UserApiFp(this.configuration).loginUserWithGoogle(requestParameters.LoginUserWithGoogleRequest, options).then((request) => request(this.axios, this.basePath));
    }
}

/**
 * @export
 */
export const ListUsersSortFieldEnum = {
    Name: 'name',
    Email: 'email',
    EmailVerificationVerified: 'email_verification.verified',
    Type: 'type',
    Status: 'status',
    Access: 'access'
} as const;
export type ListUsersSortFieldEnum = typeof ListUsersSortFieldEnum[keyof typeof ListUsersSortFieldEnum];
/**
 * @export
 */
export const ListUsersSortDirectionEnum = {
    Asc: 'asc',
    Desc: 'desc'
} as const;
export type ListUsersSortDirectionEnum = typeof ListUsersSortDirectionEnum[keyof typeof ListUsersSortDirectionEnum];
/**
 * @export
 */
export const ListUsersAccessEnum = {
    Admin: 'admin',
    Advisor: 'advisor',
    Investor: 'investor'
} as const;
export type ListUsersAccessEnum = typeof ListUsersAccessEnum[keyof typeof ListUsersAccessEnum];


/**
 * ValuationApi - axios parameter creator
 * @export
 */
export const ValuationApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Create a new Valuation document.
         * @param {CreateValuationRequest} CreateValuationRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createValuation: async (CreateValuationRequest: CreateValuationRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'CreateValuationRequest' is not null or undefined
            assertParamExists('createValuation', 'CreateValuationRequest', CreateValuationRequest)
            const localVarPath = `/v2/valuations/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication adminAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication advisorAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication investorAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(CreateValuationRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Delete a Valuation document.
         * @param {string} valuation_id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteValuation: async (valuation_id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'valuation_id' is not null or undefined
            assertParamExists('deleteValuation', 'valuation_id', valuation_id)
            const localVarPath = `/v2/valuations/{valuation_id}`
                .replace(`{${"valuation_id"}}`, encodeURIComponent(String(valuation_id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication adminAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication advisorAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication investorAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get a single Valuation document by ID.
         * @param {string} valuation_id 
         * @param {boolean} [populate_user] 
         * @param {boolean} [populate_account] 
         * @param {boolean} [populate_investment] 
         * @param {boolean} [populate_documents] 
         * @param {boolean} [populate_connection] 
         * @param {boolean} [populate_audit_log] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getValuation: async (valuation_id: string, populate_user?: boolean, populate_account?: boolean, populate_investment?: boolean, populate_documents?: boolean, populate_connection?: boolean, populate_audit_log?: boolean, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'valuation_id' is not null or undefined
            assertParamExists('getValuation', 'valuation_id', valuation_id)
            const localVarPath = `/v2/valuations/{valuation_id}`
                .replace(`{${"valuation_id"}}`, encodeURIComponent(String(valuation_id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication adminAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication advisorAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication investorAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (populate_user !== undefined) {
                localVarQueryParameter['populate_user'] = populate_user;
            }

            if (populate_account !== undefined) {
                localVarQueryParameter['populate_account'] = populate_account;
            }

            if (populate_investment !== undefined) {
                localVarQueryParameter['populate_investment'] = populate_investment;
            }

            if (populate_documents !== undefined) {
                localVarQueryParameter['populate_documents'] = populate_documents;
            }

            if (populate_connection !== undefined) {
                localVarQueryParameter['populate_connection'] = populate_connection;
            }

            if (populate_audit_log !== undefined) {
                localVarQueryParameter['populate_audit_log'] = populate_audit_log;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Paginate through Valuation documents.
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {string} [search] 
         * @param {ListValuationsSortFieldEnum} [sort_field] 
         * @param {ListValuationsSortDirectionEnum} [sort_direction] 
         * @param {Array<string>} [users] 
         * @param {Array<string>} [accounts] 
         * @param {Array<string>} [investments] 
         * @param {boolean} [hidden] 
         * @param {boolean} [inactive] 
         * @param {boolean} [populate_user] 
         * @param {boolean} [populate_account] 
         * @param {boolean} [populate_investment] 
         * @param {boolean} [populate_documents] 
         * @param {boolean} [populate_connection] 
         * @param {boolean} [populate_audit_log] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listValuations: async (skip?: number, limit?: number, search?: string, sort_field?: ListValuationsSortFieldEnum, sort_direction?: ListValuationsSortDirectionEnum, users?: Array<string>, accounts?: Array<string>, investments?: Array<string>, hidden?: boolean, inactive?: boolean, populate_user?: boolean, populate_account?: boolean, populate_investment?: boolean, populate_documents?: boolean, populate_connection?: boolean, populate_audit_log?: boolean, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v2/valuations/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication adminAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication advisorAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication investorAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (skip !== undefined) {
                localVarQueryParameter['skip'] = skip;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (sort_field !== undefined) {
                localVarQueryParameter['sort_field'] = sort_field;
            }

            if (sort_direction !== undefined) {
                localVarQueryParameter['sort_direction'] = sort_direction;
            }

            if (users) {
                localVarQueryParameter['users'] = users;
            }

            if (accounts) {
                localVarQueryParameter['accounts'] = accounts;
            }

            if (investments) {
                localVarQueryParameter['investments'] = investments;
            }

            if (hidden !== undefined) {
                localVarQueryParameter['hidden'] = hidden;
            }

            if (inactive !== undefined) {
                localVarQueryParameter['inactive'] = inactive;
            }

            if (populate_user !== undefined) {
                localVarQueryParameter['populate_user'] = populate_user;
            }

            if (populate_account !== undefined) {
                localVarQueryParameter['populate_account'] = populate_account;
            }

            if (populate_investment !== undefined) {
                localVarQueryParameter['populate_investment'] = populate_investment;
            }

            if (populate_documents !== undefined) {
                localVarQueryParameter['populate_documents'] = populate_documents;
            }

            if (populate_connection !== undefined) {
                localVarQueryParameter['populate_connection'] = populate_connection;
            }

            if (populate_audit_log !== undefined) {
                localVarQueryParameter['populate_audit_log'] = populate_audit_log;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update an existing Valuation document.
         * @param {string} valuation_id 
         * @param {UpdateValuationRequest} [UpdateValuationRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateValuation: async (valuation_id: string, UpdateValuationRequest?: UpdateValuationRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'valuation_id' is not null or undefined
            assertParamExists('updateValuation', 'valuation_id', valuation_id)
            const localVarPath = `/v2/valuations/{valuation_id}`
                .replace(`{${"valuation_id"}}`, encodeURIComponent(String(valuation_id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication adminAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication advisorAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication investorAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(UpdateValuationRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ValuationApi - functional programming interface
 * @export
 */
export const ValuationApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ValuationApiAxiosParamCreator(configuration)
    return {
        /**
         * Create a new Valuation document.
         * @param {CreateValuationRequest} CreateValuationRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createValuation(CreateValuationRequest: CreateValuationRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CreateValuation201Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createValuation(CreateValuationRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ValuationApi.createValuation']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Delete a Valuation document.
         * @param {string} valuation_id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteValuation(valuation_id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DeleteAccount200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteValuation(valuation_id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ValuationApi.deleteValuation']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Get a single Valuation document by ID.
         * @param {string} valuation_id 
         * @param {boolean} [populate_user] 
         * @param {boolean} [populate_account] 
         * @param {boolean} [populate_investment] 
         * @param {boolean} [populate_documents] 
         * @param {boolean} [populate_connection] 
         * @param {boolean} [populate_audit_log] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getValuation(valuation_id: string, populate_user?: boolean, populate_account?: boolean, populate_investment?: boolean, populate_documents?: boolean, populate_connection?: boolean, populate_audit_log?: boolean, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetValuation200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getValuation(valuation_id, populate_user, populate_account, populate_investment, populate_documents, populate_connection, populate_audit_log, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ValuationApi.getValuation']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Paginate through Valuation documents.
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {string} [search] 
         * @param {ListValuationsSortFieldEnum} [sort_field] 
         * @param {ListValuationsSortDirectionEnum} [sort_direction] 
         * @param {Array<string>} [users] 
         * @param {Array<string>} [accounts] 
         * @param {Array<string>} [investments] 
         * @param {boolean} [hidden] 
         * @param {boolean} [inactive] 
         * @param {boolean} [populate_user] 
         * @param {boolean} [populate_account] 
         * @param {boolean} [populate_investment] 
         * @param {boolean} [populate_documents] 
         * @param {boolean} [populate_connection] 
         * @param {boolean} [populate_audit_log] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listValuations(skip?: number, limit?: number, search?: string, sort_field?: ListValuationsSortFieldEnum, sort_direction?: ListValuationsSortDirectionEnum, users?: Array<string>, accounts?: Array<string>, investments?: Array<string>, hidden?: boolean, inactive?: boolean, populate_user?: boolean, populate_account?: boolean, populate_investment?: boolean, populate_documents?: boolean, populate_connection?: boolean, populate_audit_log?: boolean, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListValuations200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listValuations(skip, limit, search, sort_field, sort_direction, users, accounts, investments, hidden, inactive, populate_user, populate_account, populate_investment, populate_documents, populate_connection, populate_audit_log, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ValuationApi.listValuations']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Update an existing Valuation document.
         * @param {string} valuation_id 
         * @param {UpdateValuationRequest} [UpdateValuationRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateValuation(valuation_id: string, UpdateValuationRequest?: UpdateValuationRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DeleteAccount200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateValuation(valuation_id, UpdateValuationRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ValuationApi.updateValuation']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * ValuationApi - factory interface
 * @export
 */
export const ValuationApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ValuationApiFp(configuration)
    return {
        /**
         * Create a new Valuation document.
         * @param {ValuationApiCreateValuationRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createValuation(requestParameters: ValuationApiCreateValuationRequest, options?: RawAxiosRequestConfig): AxiosPromise<CreateValuation201Response> {
            return localVarFp.createValuation(requestParameters.CreateValuationRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Delete a Valuation document.
         * @param {ValuationApiDeleteValuationRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteValuation(requestParameters: ValuationApiDeleteValuationRequest, options?: RawAxiosRequestConfig): AxiosPromise<DeleteAccount200Response> {
            return localVarFp.deleteValuation(requestParameters.valuation_id, options).then((request) => request(axios, basePath));
        },
        /**
         * Get a single Valuation document by ID.
         * @param {ValuationApiGetValuationRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getValuation(requestParameters: ValuationApiGetValuationRequest, options?: RawAxiosRequestConfig): AxiosPromise<GetValuation200Response> {
            return localVarFp.getValuation(requestParameters.valuation_id, requestParameters.populate_user, requestParameters.populate_account, requestParameters.populate_investment, requestParameters.populate_documents, requestParameters.populate_connection, requestParameters.populate_audit_log, options).then((request) => request(axios, basePath));
        },
        /**
         * Paginate through Valuation documents.
         * @param {ValuationApiListValuationsRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listValuations(requestParameters: ValuationApiListValuationsRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<ListValuations200Response> {
            return localVarFp.listValuations(requestParameters.skip, requestParameters.limit, requestParameters.search, requestParameters.sort_field, requestParameters.sort_direction, requestParameters.users, requestParameters.accounts, requestParameters.investments, requestParameters.hidden, requestParameters.inactive, requestParameters.populate_user, requestParameters.populate_account, requestParameters.populate_investment, requestParameters.populate_documents, requestParameters.populate_connection, requestParameters.populate_audit_log, options).then((request) => request(axios, basePath));
        },
        /**
         * Update an existing Valuation document.
         * @param {ValuationApiUpdateValuationRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateValuation(requestParameters: ValuationApiUpdateValuationRequest, options?: RawAxiosRequestConfig): AxiosPromise<DeleteAccount200Response> {
            return localVarFp.updateValuation(requestParameters.valuation_id, requestParameters.UpdateValuationRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for createValuation operation in ValuationApi.
 * @export
 * @interface ValuationApiCreateValuationRequest
 */
export interface ValuationApiCreateValuationRequest {
    /**
     * 
     * @type {CreateValuationRequest}
     * @memberof ValuationApiCreateValuation
     */
    readonly CreateValuationRequest: CreateValuationRequest
}

/**
 * Request parameters for deleteValuation operation in ValuationApi.
 * @export
 * @interface ValuationApiDeleteValuationRequest
 */
export interface ValuationApiDeleteValuationRequest {
    /**
     * 
     * @type {string}
     * @memberof ValuationApiDeleteValuation
     */
    readonly valuation_id: string
}

/**
 * Request parameters for getValuation operation in ValuationApi.
 * @export
 * @interface ValuationApiGetValuationRequest
 */
export interface ValuationApiGetValuationRequest {
    /**
     * 
     * @type {string}
     * @memberof ValuationApiGetValuation
     */
    readonly valuation_id: string

    /**
     * 
     * @type {boolean}
     * @memberof ValuationApiGetValuation
     */
    readonly populate_user?: boolean

    /**
     * 
     * @type {boolean}
     * @memberof ValuationApiGetValuation
     */
    readonly populate_account?: boolean

    /**
     * 
     * @type {boolean}
     * @memberof ValuationApiGetValuation
     */
    readonly populate_investment?: boolean

    /**
     * 
     * @type {boolean}
     * @memberof ValuationApiGetValuation
     */
    readonly populate_documents?: boolean

    /**
     * 
     * @type {boolean}
     * @memberof ValuationApiGetValuation
     */
    readonly populate_connection?: boolean

    /**
     * 
     * @type {boolean}
     * @memberof ValuationApiGetValuation
     */
    readonly populate_audit_log?: boolean
}

/**
 * Request parameters for listValuations operation in ValuationApi.
 * @export
 * @interface ValuationApiListValuationsRequest
 */
export interface ValuationApiListValuationsRequest {
    /**
     * 
     * @type {number}
     * @memberof ValuationApiListValuations
     */
    readonly skip?: number

    /**
     * 
     * @type {number}
     * @memberof ValuationApiListValuations
     */
    readonly limit?: number

    /**
     * 
     * @type {string}
     * @memberof ValuationApiListValuations
     */
    readonly search?: string

    /**
     * 
     * @type {'date' | 'amount'}
     * @memberof ValuationApiListValuations
     */
    readonly sort_field?: ListValuationsSortFieldEnum

    /**
     * 
     * @type {'asc' | 'desc'}
     * @memberof ValuationApiListValuations
     */
    readonly sort_direction?: ListValuationsSortDirectionEnum

    /**
     * 
     * @type {Array<string>}
     * @memberof ValuationApiListValuations
     */
    readonly users?: Array<string>

    /**
     * 
     * @type {Array<string>}
     * @memberof ValuationApiListValuations
     */
    readonly accounts?: Array<string>

    /**
     * 
     * @type {Array<string>}
     * @memberof ValuationApiListValuations
     */
    readonly investments?: Array<string>

    /**
     * 
     * @type {boolean}
     * @memberof ValuationApiListValuations
     */
    readonly hidden?: boolean

    /**
     * 
     * @type {boolean}
     * @memberof ValuationApiListValuations
     */
    readonly inactive?: boolean

    /**
     * 
     * @type {boolean}
     * @memberof ValuationApiListValuations
     */
    readonly populate_user?: boolean

    /**
     * 
     * @type {boolean}
     * @memberof ValuationApiListValuations
     */
    readonly populate_account?: boolean

    /**
     * 
     * @type {boolean}
     * @memberof ValuationApiListValuations
     */
    readonly populate_investment?: boolean

    /**
     * 
     * @type {boolean}
     * @memberof ValuationApiListValuations
     */
    readonly populate_documents?: boolean

    /**
     * 
     * @type {boolean}
     * @memberof ValuationApiListValuations
     */
    readonly populate_connection?: boolean

    /**
     * 
     * @type {boolean}
     * @memberof ValuationApiListValuations
     */
    readonly populate_audit_log?: boolean
}

/**
 * Request parameters for updateValuation operation in ValuationApi.
 * @export
 * @interface ValuationApiUpdateValuationRequest
 */
export interface ValuationApiUpdateValuationRequest {
    /**
     * 
     * @type {string}
     * @memberof ValuationApiUpdateValuation
     */
    readonly valuation_id: string

    /**
     * 
     * @type {UpdateValuationRequest}
     * @memberof ValuationApiUpdateValuation
     */
    readonly UpdateValuationRequest?: UpdateValuationRequest
}

/**
 * ValuationApi - object-oriented interface
 * @export
 * @class ValuationApi
 * @extends {BaseAPI}
 */
export class ValuationApi extends BaseAPI {
    /**
     * Create a new Valuation document.
     * @param {ValuationApiCreateValuationRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ValuationApi
     */
    public createValuation(requestParameters: ValuationApiCreateValuationRequest, options?: RawAxiosRequestConfig) {
        return ValuationApiFp(this.configuration).createValuation(requestParameters.CreateValuationRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Delete a Valuation document.
     * @param {ValuationApiDeleteValuationRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ValuationApi
     */
    public deleteValuation(requestParameters: ValuationApiDeleteValuationRequest, options?: RawAxiosRequestConfig) {
        return ValuationApiFp(this.configuration).deleteValuation(requestParameters.valuation_id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get a single Valuation document by ID.
     * @param {ValuationApiGetValuationRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ValuationApi
     */
    public getValuation(requestParameters: ValuationApiGetValuationRequest, options?: RawAxiosRequestConfig) {
        return ValuationApiFp(this.configuration).getValuation(requestParameters.valuation_id, requestParameters.populate_user, requestParameters.populate_account, requestParameters.populate_investment, requestParameters.populate_documents, requestParameters.populate_connection, requestParameters.populate_audit_log, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Paginate through Valuation documents.
     * @param {ValuationApiListValuationsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ValuationApi
     */
    public listValuations(requestParameters: ValuationApiListValuationsRequest = {}, options?: RawAxiosRequestConfig) {
        return ValuationApiFp(this.configuration).listValuations(requestParameters.skip, requestParameters.limit, requestParameters.search, requestParameters.sort_field, requestParameters.sort_direction, requestParameters.users, requestParameters.accounts, requestParameters.investments, requestParameters.hidden, requestParameters.inactive, requestParameters.populate_user, requestParameters.populate_account, requestParameters.populate_investment, requestParameters.populate_documents, requestParameters.populate_connection, requestParameters.populate_audit_log, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Update an existing Valuation document.
     * @param {ValuationApiUpdateValuationRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ValuationApi
     */
    public updateValuation(requestParameters: ValuationApiUpdateValuationRequest, options?: RawAxiosRequestConfig) {
        return ValuationApiFp(this.configuration).updateValuation(requestParameters.valuation_id, requestParameters.UpdateValuationRequest, options).then((request) => request(this.axios, this.basePath));
    }
}

/**
 * @export
 */
export const ListValuationsSortFieldEnum = {
    Date: 'date',
    Amount: 'amount'
} as const;
export type ListValuationsSortFieldEnum = typeof ListValuationsSortFieldEnum[keyof typeof ListValuationsSortFieldEnum];
/**
 * @export
 */
export const ListValuationsSortDirectionEnum = {
    Asc: 'asc',
    Desc: 'desc'
} as const;
export type ListValuationsSortDirectionEnum = typeof ListValuationsSortDirectionEnum[keyof typeof ListValuationsSortDirectionEnum];


