import React, { useState, useEffect, useCallback, useRef } from 'react';
import _ from 'lodash';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPencil } from '@fortawesome/free-solid-svg-icons';
import api2 from '../../api2';
import PaginationTable from '../../components/Tables/PaginationTable';
import { setQueryParam } from '../../utilities/query/queryParamHelpers';
import { EM_DASH, VUES } from '../../constants/constantStrings';
import { ObjectSearchSelect } from '../../components/Dropdowns/ObjectSearchSelect';
import { User } from '../../types/User';
import { RouteComponentProps } from 'react-router-dom';
import { CopyButton } from '../../components/Buttons/IconButton';
import IntegrationDrawer from './IntegrationDrawer';
import IconTextButton from '../../components/Buttons/IconTextButton';
import Dropdown from '../../components/Dropdowns/Dropdown';
import { Integration, IntegrationCredential } from '../../types/Integration';

interface IntegrationTableProps extends RouteComponentProps {
    user: User;
    setTitle: (title: React.ReactNode) => void;
    loading: () => void;
    loaded: () => void;
    vue: string;
    openDrawer: (content: () => React.ReactNode) => void;
    closeDrawer: () => void;
    setAlert: (message: string) => void;
}

const IntegrationTable: React.FC<IntegrationTableProps> = (props) => {
    const [errorUnit, setErrorUnit] = useState<'percent' | 'number'>('percent');
    const [childCallables, setChildCallables] = useState<{ loadData?: () => void; filterData?: (value: string, field: string) => void } | null>(null);
    const [typeFilter, setTypeFilter] = useState<{ _id: string | null } | null>(null);
    const [searchString, setSearchString] = useState<string>('');

    const { setTitle, loading, loaded, user, vue, history, openDrawer, closeDrawer, setAlert } = props;

    useEffect(() => {
        const load = async () => {
            if (vue !== VUES.ADMIN) {
                console.log('not admin, redirect to home');
                history.push('/');
                return;
            }

            loading();
            // Simulate an API call
            setTimeout(() => {
                loaded();
            }, 1000);
        };

        load();
        loadQueryParams();
    }, [vue, history, loading, loaded]);

    const prevUserName = useRef(user?.name);

    useEffect(() => {
        if (user?.name && user?.name !== prevUserName.current) {
            prevUserName.current = user.name; // Update the ref to the current user name
            setTitle(
                <>
                    <span className="title-account-name">{user?.name}</span>
                    <div className="separator" />
                    Integrations
                </>
            );
        } else {
            return;
        }
    }, [user, setTitle]);

    const loadQueryParams = useCallback(() => {
        const url = new URL(window.location.href);
        // Load query params logic here
    }, []);

    const getRoute = () => {
        return `/integrations?populate_connection_counts=true`;
    };

    const getColumns = () => {
        return [
            {
                title: 'Name',
                field: 'name',
                sort: {
                    field: 'name',
                },
                render: (col: any, row: any, items: Integration[], i: number) => {
                    const integ = items[i];
                    const name = integ.name;
                    return (
                        <td key={'integ_name' + i} title={`${name ?? EM_DASH}`}>
                            <div style={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
                                {name}
                                <CopyButton text={integ._id ?? ''} title={`Copy ID to Clipboard (${integ._id ?? ''})`} />
                            </div>
                        </td>
                    );
                },
            },
            {
                title: 'Total Connections',
                headerClass: 'center-text',
                render: (col: any, row: any, items: Integration[], i: number) => {
                    const integ = items[i];
                    const areConnections = integ.connections_count > 0;
                    const totalConnClass = 'center-text' + (areConnections ? ' a' : '');
                    return (
                        <td
                            key={'nConn' + i}
                            className={totalConnClass}
                            onClick={() => {
                                if (!areConnections) {
                                    return;
                                }
                                const path = window.location.pathname.replace('integrations', `connections?integration=${integ._id}`);
                                history.push(path);
                            }}
                        >
                            {areConnections ? integ.connections_count : EM_DASH}
                        </td>
                    );
                },
            },
            {
                title: 'Has Active Integration Worker',
                sort: {
                    field: 'has_worker',
                },
                headerClass: 'center-text',
                render: (col: any, row: any, items: Integration[], i: number) => {
                    const integ = items[i];
                    const hasActiveWorker = integ.has_worker;
                    return (
                        <td key={'hasActiveWorker' + i} className="center-text">
                            {hasActiveWorker ? 'Yes' : 'No'}
                        </td>
                    );
                },
            },
            {
                title: 'Worker Version',
                sort: {
                    field: 'worker_version',
                },
                headerClass: 'center-text',
                render: (col: any, row: any, items: Integration[], i: number) => {
                    const integ = items[i];
                    const workerVersion = integ.worker_version;
                    return (
                        <td key={'workerVersion' + i} className="center-text">
                            {workerVersion}
                        </td>
                    );
                },
            },
            {
                title: 'Errors',
                headerClass: 'center-text',
                renderSubHeader: renderErrorsHeader,
                render: (col: any, row: any, items: Integration[], i: number) => {
                    const integ = items[i];
                    const areErrs = integ.errored_connections_count;
                    const totalErrClass = 'center-text' + (areErrs ? ' a' : '');
                    const path = window.location.pathname.replace('integrations', `connections?integration=${integ._id}&connectionStatus=Error`);
                    return (
                        <td key={'errors' + i} className={totalErrClass}>
                            {areErrs ? (
                                <a href={path} style={{ color: 'inherit' }}>
                                    {getErrorCount(integ)}
                                </a>
                            ) : (
                                EM_DASH
                            )}
                        </td>
                    );
                },
            },
            {
                title: 'Edit',
                render: (col: any, row: any, items: Integration[], i: number) => {
                    const item = items[i];
                    return (
                        <td key={'ral_editEntity' + i} title={`Edit entity`} style={{ width: '50px' }}>
                            <FontAwesomeIcon
                                icon={faPencil}
                                size="xl"
                                onClick={(e) => {
                                    e.stopPropagation();
                                    openAddIntegrationDrawer(item);
                                }}
                            />
                        </td>
                    );
                },
            },
        ];
    };

    const changeErrorUnit = (unit: 'percent' | 'number') => {
        setErrorUnit(unit);
    };

    const getErrorCount = (integration: Integration) => {
        if (errorUnit === 'percent') {
            return `${((integration.errored_connections_count / integration.connections_count) * 100).toFixed(2)}%`;
        } else return integration.errored_connections_count;
    };

    const renderErrorsHeader = () => {
        return (
            <React.Fragment>
                <span
                    className={errorUnit === 'number' ? 'active a' : 'a'}
                    onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        changeErrorUnit('number');
                    }}
                >
                    #
                </span>
                <span style={{ margin: '0 10px' }}>|</span>
                <span
                    className={errorUnit === 'percent' ? 'active a' : 'a'}
                    onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        changeErrorUnit('percent');
                    }}
                >
                    %
                </span>
            </React.Fragment>
        );
    };

    const renderUserFilter = () => {
        return (
            <ObjectSearchSelect
                placeholder="Filter By Client"
                selected={typeFilter ?? { _id: null }}
                onChange={(obj: { value: { _id: string | null } }) => {
                    setTypeFilter(obj.value);
                    setQueryParam('user', obj.value?._id);
                }}
                getLabel={(obj: { _id: string | null; email?: string; name?: string }) => {
                    if (!obj._id) return null;
                    return (
                        <div>
                            {obj.email && <div style={{ fontSize: '12px', color: 'var(--color-light-gray)' }}>{obj.email}</div>}
                            <div>{obj.name}</div>
                        </div>
                    );
                }}
                defaultLabel="All Clients"
                matchProperty="_id"
                width={300}
                loadOptions={async (inputValue: string) => {
                    try {
                        const { data } = await api2.client.UserApi.listUsers({
                            search: inputValue,
                            limit: 50,
                        });

                        if (!data.success) return [];

                        return data.users;
                    } catch (error) {
                        console.log('error getting users', error);
                        return [];
                    }
                }}
                containerStyle={{ zIndex: 1000 }}
            />
        );
    };

    const getBulkActions = () => {
        return [];
    };

    const getSelectFilters = () => {
        return [];
    };

    const renderRequiredCredsList = (
        commonData: { required_creds?: IntegrationCredential[] },
        setCommonDataField: (
            field: string,
            value: any
        ) => {
            // Add your logic here
        },
        integration: Integration
    ) => {
        if (!commonData) return <></>;

        let required_creds = commonData.required_creds ?? [...(integration?.required_creds ?? [])];

        return (
            <>
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <label>Required Credentials</label>
                    <div
                        style={{ width: '12px', height: '12px', cursor: 'pointer', marginRight: '5px' }}
                        onClick={() => {
                            required_creds.push({ key: '', type: 'text', input: '', options: [], hint: '' });
                            setCommonDataField('required_creds', required_creds);
                        }}
                    >
                        <img src="/images/icons/plus.png" alt="New Credential" title="New Credential" />
                    </div>
                </div>
                {required_creds.map((cred, i) => {
                    return (
                        <div key={'reqCred' + i} style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '10px', alignItems: 'center' }}>
                            <div style={{ width: '50%' }}>
                                <input
                                    autoComplete={'off'}
                                    placeholder="Enter Key"
                                    value={cred.key}
                                    onChange={(e) => {
                                        cred.key = e.target.value;
                                        setCommonDataField('required_creds', required_creds);
                                    }}
                                    style={{
                                        height: `${40 - 2}px`,
                                        padding: '0 10px',
                                        lineHeight: 40 - 10 - 2,
                                        border: 'none',
                                    }}
                                />
                            </div>
                            <Dropdown
                                defaultOptionString="Select Type"
                                options={['text', 'password']}
                                selection={cred.type}
                                style={{ width: '30%' }}
                                // @ts-ignore
                                onChange={(newType: 'text' | 'password') => {
                                    cred.type = newType;
                                    setCommonDataField('required_creds', required_creds);
                                }}
                            />
                            <div
                                className="ral_removeIcon"
                                onClick={() => {
                                    required_creds.splice(i, 1);
                                    setCommonDataField('required_creds', required_creds);
                                }}
                            >
                                <img
                                    style={{ width: '18px', height: '18px', cursor: 'pointer' }}
                                    src="/images/icons/closeBlue.png"
                                    alt="Remove Credential"
                                    title="Remove Credential"
                                />
                            </div>
                        </div>
                    );
                })}
            </>
        );
    };

    const openAddIntegrationDrawer = (integration?: Integration | null) => {
        openDrawer(() => (
            <IntegrationDrawer
                loading={loading || (() => {})}
                loaded={loaded || (() => {})}
                closeDrawer={closeDrawer || (() => {})}
                setAlert={setAlert}
                integration={integration}
                reloadData={childCallables?.loadData || (() => {})}
            />
        ));
    };

    const filterMessages = (value: string) => {
        setQueryParam('search', value);
        setSearchString(value);
        childCallables?.filterData?.(value, 'name');
    };

    const getSubRowColumns = () => {
        return [
            {
                title: 'Investment Name',
                field: 'name',
            },
            {
                title: '# Clients',
                render: (col: any, row: any, items: Integration[], i: number) => {
                    const item = items[i];
                    const investmentCount = item?.investments?.length ?? 0;
                    return <td key={'ral_signed' + i}>{investmentCount}</td>;
                },
            },
        ];
    };

    return (
        <>
            <div>
                <PaginationTable
                    noInvestmentDropdown={true}
                    selectFilters={getSelectFilters()}
                    containerStyle={{ margin: '0px' }}
                    name="processor"
                    initialSortField="name"
                    initialSortAscending={true}
                    loading={loading}
                    loaded={loaded}
                    disableDefaultOption={true}
                    noTypeDropdown={true}
                    noDateFilters={true}
                    useApi2={true}
                    getResultsFromResponse={(res: { integrations: Integration[] }) => res.integrations}
                    route={getRoute()}
                    title={
                        <>
                            Integrations
                            <IconTextButton icon="/images/icons/plus.png" text="New Integration" onClick={() => openAddIntegrationDrawer(null)} />
                        </>
                    }
                    columns={getColumns()}
                    setCallables={setChildCallables}
                    selectedUserId={typeFilter?._id}
                    vue={vue}
                    disableTypeFilter={true}
                    postProcessData={(data: Integration[]) => {
                        return data;
                    }}
                    enableClickSelect={true}
                />
            </div>
            <div style={{ clear: 'both', height: '100px' }} />
        </>
    );
};

export default IntegrationTable;
