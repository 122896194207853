import { MouseEvent } from 'react';
import api from '../../api';
import { setDismissableAlert } from '../alert/setDismissableAlert';

const resendEmailVerificationEmail = async (
    e: MouseEvent,
    email: string,
    setAlert: (message: string | JSX.Element, isError?: boolean, duration?: number) => void,
    callback: (() => void) | null = null
): Promise<void> => {
    e.stopPropagation();
    const res = await api.post(`/users/reverify`, { email });
    if (res) {
        setDismissableAlert?.(
            setAlert,
            <>
                A verification email has been sent to <strong style={{ color: '#fff' }}>{email}</strong>.
            </>,
            false,
            7000
        );
    } else {
        setDismissableAlert?.(setAlert, 'Error sending verification email.', true);
    }
    callback?.();
};

export default resendEmailVerificationEmail;
