import React from 'react';
import Dropdown from '../Dropdowns/Dropdown';

interface DropdownSectionProps {
    title: string;
    selection: any;
    fontColor: string | null;
    options?: any[];
    optionField?: string;
    select: (value: any) => void;
    style?: React.CSSProperties;
    readOnly?: boolean;
    renderOption?: (item: any) => any;
    defaultSelection?: any;
    disableDefaultOption: boolean;
}

const DropdownSection: React.FC<DropdownSectionProps> = ({
    title,
    selection,
    fontColor,
    options,
    optionField,
    select,
    style,
    readOnly,
    renderOption,
    defaultSelection,
    disableDefaultOption,
    ...rest
}) => {
    // Convert fontColor to undefined if it's null to match DropdownProps type
    const dropdownFontColor = fontColor === null ? undefined : fontColor;

    // Ensure options is always an array
    const dropdownOptions = options ?? [];

    // Ensure style matches the expected type
    const dropdownStyle = style as React.CSSProperties & {
        arrowSize?: string;
        fontSize?: string;
        height?: string;
        width?: string;
    };

    return (
        <div className="altx-drawer-infobox">
            <p className="altx-drawer-section">{title}</p>
            <Dropdown
                data-testid="dropdown"
                selection={selection}
                fontColor={dropdownFontColor}
                options={dropdownOptions}
                optionField={optionField}
                select={select}
                style={dropdownStyle}
                readOnly={readOnly}
                renderOption={renderOption}
                defaultSelection={defaultSelection}
                disableDefaultOption={disableDefaultOption}
                {...rest}
            />
        </div>
    );
};

export default DropdownSection;
