import React from 'react';

interface ConfirmButtonProps {
    onClick: () => void;
    text?: string;
    confirmText?: string;
    timeout?: number;
    color?: string;
}

export const ConfirmButton: React.FC<ConfirmButtonProps> = ({ onClick, text, confirmText, timeout, color }) => {
    const [confirm, setConfirm] = React.useState(false);

    React.useEffect(() => {
        if (confirm) {
            const timer = setTimeout(() => setConfirm(false), timeout ?? 3000);
            return () => clearTimeout(timer);
        }
    }, [confirm, timeout]);

    const handleAction = () => {
        if (!confirm) {
            setConfirm(true);
        } else {
            onClick();
        }
    };

    return (
        <div style={{ color, cursor: 'pointer', textDecoration: 'underline' }} onClick={handleAction}>
            {confirm ? (confirmText ?? 'Confirm') : (text ?? 'Go!')}
        </div>
    );
};
