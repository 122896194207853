import React, { Component } from 'react';
import api from '../../api';
import { UserPermissions } from '../../utilities/AdvisorVue/permissions';
import Checkbox from '../Inputs/Checkbox';
import DrawerContent from '../DrawerComponents/DrawerContent';
import _ from 'lodash';
import { getInvestmentSubtypes } from '../../pages/Account/AddManualInvestmentStages/investmentTypeConstants';
import InvestmentInactiveToggle from '../Toggles/InvestmentInactiveToggle';
import InvestmentHiddenToggle from '../Toggles/InvestmentHiddenToggle';
import { ObjectSearchSelect } from '../Dropdowns/ObjectSearchSelect';
import { setDismissableAlert } from '../../utilities/alert/setDismissableAlert';
import api2 from '../../api2';
import { CURRENCIES, getCurrencyString } from '../../constants/currencyConstants';
import AuditLogDisplay from '../AuditLogDisplay';
import AccountSearchFilter from '../Filters/AccountSearchFilter';
import { sub } from 'date-fns';

export default class EditInvestmentDrawerContent extends Component {
    state = {
        newlyCreatedAssetMgr: null,
        newlyCreatedTaxCtc: null,
    };

    componentDidMount = () => {
        this.updateInvestmentFields();
        this.generateOptions();
    };

    updateInvestmentFields = async (cb) => {
        if (!this.props.investmentId) return;
        this.props.loading(320, 'investmentDrawer');

        let investment = null;
        try {
            investment = (
                await api2.client.InvestmentApi.getInvestment({
                    investment_id: this.props.investmentId,
                    populate_user: true,
                    populate_account: true,
                    populate_investment_master: true,
                })
            ).data.investment;
        } catch (err) {
            setDismissableAlert(this.props.setAlert, 'Could not load investment', true);
            this.props.loaded('investmentDrawer');
            return;
        }

        this.setState((_state, props) => {
            const ret = {
                investment,
                name: investment?.name ?? '',
                investment_type: investment?.type,
                tax_document: investment?.tax_document,
                contraAccountNumber: investment?.investment_data?.contraAccountNumber,
                custodialId: investment?.investment_data?.custodialId,
                addepar_loa_status_workflow_id: investment?.attributes?.addepar_loa_status_workflow_id,
                addepar_portal_status_workflow_id: investment?.attributes?.addepar_portal_status_workflow_id,
            };
            ret.inactive = investment?.inactive;
            ret.hidden = investment?.hidden;
            ret.expected_tax_documents = investment?.expected_tax_documents;
            ret.inactive = investment?.inactive;
            if (investment?.investment_data?.type) {
                ret.investment_subtype = investment?.investment_data?.type;
            }
            if (investment?.currency) {
                ret.investment_currency = investment?.currency;
            }
            this.props.loaded('investmentDrawer');
            return ret;
        }, cb);
    };

    // updateContactOptions = () => this.setState(
    //     (_state, props) => {
    //         const newContacts = props.contacts.map((ct) => { return { title: ct.name, contact: ct } });
    //         return {
    //             contactOptions: newContacts,
    //         }
    //     }
    // );

    componentDidUpdate = (prevProps) => {
        const p = this.props;
        // const prevContacts = prevProps.contacts;
        // const contacts = p.contacts;
        // if (prevContacts?.length !== contacts?.length) {
        //     this.updateContactOptions();
        // } else {
        //     for (let i = 0; i < contacts?.length; i++) {
        //         if (!prevContacts.find(ctc => ctc?._id === contacts[i]._id)) {
        //             this.updateContactOptions();
        //         }
        //     }
        // }

        if (!_.isEqual(prevProps.investmentId, p.investmentId)) {
            this.updateInvestmentFields();
        }
    };

    generateOptions = (state = this.state) => {
        let typeOptions = this.props.types?.map?.((t) => {
            return { title: t };
        });
        let taxDocumentTypeOptions = this.props.taxDocumentTypes?.map?.((tdt) => {
            return { title: tdt };
        });
        // let contactOptions = this.props.contacts?.map?.((ct) => { return { title: ct.name, contact: ct } });

        this.setState({
            typeOptions,
            taxDocumentTypeOptions,
            // contactOptions,
        });
    };

    getSubtypesByInvestmentType = (type) => {
        const subtypes = getInvestmentSubtypes(type);
        return subtypes?.map?.((st) => {
            return { title: st };
        });
    };

    getInputs = (state = this.state, props = this.props) => {
        const p = this.props;
        const s = this.state;

        const investment = s.investment;
        if (!investment) return [];

        let entityOptions = p.accounts.map((a) => {
            return { title: a.name, account: a };
        });
        let selectedEntity = entityOptions.find((a) => a.account._id === (investment.account?._id ?? investment.account));

        let connectionOptions =
            p.connections?.map((c) => {
                return { title: c.name, connection: c };
            }) ?? [];
        connectionOptions.unshift({ title: 'None', connection: null });
        let selectedConnection = connectionOptions.find((a) => [a.connection, a.connection?._id].includes(investment.connection));

        let typeOptions = s.typeOptions;
        let defaultType = typeOptions?.find?.((t) => t?.title === s.investment_type);

        let inputs = [
            {
                title: 'Investment Name',
                alt: 'the name of the investment',
                defaultValue: s.name ?? '',
                onChange: (val) => this.setState({ name: val }),
                type: 'text',
            },
            {
                title: 'Investment Entity',
                type: 'custom',
                component: (
                    <>
                        <label>Investment Entity</label>
                        <AccountSearchFilter
                            defaultLabel={'Select Investment Entity'}
                            filter={{
                                users: [p.relevantUser?._id],
                            }}
                            selected={s.editingInvestmentEntity || investment.account}
                            onChange={(editingInvestmentEntity) => {
                                if (!editingInvestmentEntity) return;
                                this.setState({ editingInvestmentEntity });
                            }}
                            defaultOptions={true}
                            excludeDisplayFields={'user'}
                        />
                    </>
                ),
            },
            // {
            //     title: 'Investment Entity',
            //     options: entityOptions,
            //     defaultValue: selectedEntity,
            //     type: 'dropdown',
            //     onChange: (val) => this.setState({ editingInvestmentEntity: val.account._id }),
            // },
            {
                title: 'Connection',
                options: connectionOptions,
                defaultValue: selectedConnection,
                type: 'dropdown',
                onChange: (val) => this.setState({ editingConnection: val?.connection ? val.connection._id : null }),
            },
            {
                title: 'Investment Type',
                options: typeOptions,
                defaultValue: defaultType,
                type: 'dropdown',
                onChange: (val) =>
                    this.setState({ investment_type: val.title }, () => {
                        // if the investment type is changed, we need to update the subtype options
                        const subtypeOptions = getInvestmentSubtypes(s.investment_type);
                        this.setState({ investment_subtype: subtypeOptions?.[0] });
                    }),
                excludeNull: true,
            },
        ];

        // get subtypes based on state investment_type
        const subtypeOptions = getInvestmentSubtypes(s.investment_type).map((st) => {
            return { title: st };
        });
        const defaultSubtype = s.investment_subtype
            ? { title: subtypeOptions.find((st) => st.title === s.investment_subtype)?.title || '' }
            : { title: subtypeOptions[0]?.title || '' };
        if (subtypeOptions?.length > 0) {
            inputs.push({
                title: 'Investment Subtype',
                options: subtypeOptions,
                defaultValue: defaultSubtype,
                value: { title: s.investment_subtype },
                type: 'dropdown',
                onChange: (val) => this.setState({ investment_subtype: val.title }),
                excludeNull: true,
            });
        }

        const currencyOptions = Object.values(CURRENCIES).map((c) => {
            return { title: getCurrencyString(c) };
        });
        const curCurrency = currencyOptions.find((c) => c.title === getCurrencyString(s.investment_currency ?? CURRENCIES.USD));

        inputs.push({
            title: 'Currency',
            alt: 'the currency of the investment',
            value: curCurrency,
            options: currencyOptions,
            type: 'dropdown',
            onChange: (val) => this.setState({ investment_currency: val.title?.split(' ')[0] }),
            excludeNull: true,
        });

        inputs.push({
            title: 'Contra Account ID',
            alt: 'ID of the Contra Account',
            defaultValue: s.contraAccountNumber ?? '',
            onChange: (val) => this.setState({ contraAccountNumber: val }),
            type: 'text',
        });

        inputs.push({
            title: 'Custodial ID',
            alt: 'ID of the Custodial Account',
            defaultValue: s.custodialId ?? '',
            onChange: (val) => this.setState({ custodialId: val }),
            type: 'text',
        });

        inputs.push({
            title: 'Addepar LOA Status Workflow ID',
            alt: 'Addepar LOA Status Workflow ID',
            defaultValue: s.addepar_loa_status_workflow_id ?? '',
            onChange: (val) => this.setState({ addepar_loa_status_workflow_id: val }),
            type: 'text',
        });
        inputs.push({
            title: 'Addepar Portal Status Workflow ID',
            alt: 'Addepar Portal Status Workflow ID',
            defaultValue: s.addepar_portal_status_workflow_id ?? '',
            onChange: (val) => this.setState({ addepar_portal_status_workflow_id: val }),
            type: 'text',
        });

        inputs.push({
            title: 'Tax Documents Expected',
            type: 'toggle',
            options: ['No', 'Yes'],
            value: !!s.expected_tax_documents,
            onChange: (val) => this.setState({ expected_tax_documents: Number(val) }),
        });

        inputs.push({
            type: 'custom',
            component: (
                <div style={{ zIndex: 0 }}>
                    <InvestmentInactiveToggle
                        containerStyle={{ height: '25px', width: '100%', justifyContent: 'space-between', alignItems: 'center', display: 'flex' }}
                        investment={{
                            ...investment,
                            inactive: s.inactive ?? investment.inactive,
                            inactive_date: s.inactive || investment.inactive ? (s.inactive_date ?? investment.inactive_date) : null,
                        }}
                        loading={p.loading}
                        loaded={p.loaded}
                        setAlert={p.setAlert}
                        onChange={(newValue) => {
                            if (newValue === s.inactive) return;
                            this.setState({
                                inactive: newValue,
                                inactive_date: newValue ? new Date() : null,
                            });
                        }}
                        onChangeDate={(newValue) => {
                            this.setState({ inactive_date: newValue });
                        }}
                        allowEditDate={true}
                    />
                </div>
            ),
        });
        // If the user is not an admin, they cannot hide investments
        if (UserPermissions().access.admin) {
            inputs.push({
                type: 'custom',
                component: (
                    <div style={{ zIndex: 0 }}>
                        <InvestmentHiddenToggle
                            containerStyle={{ height: '25px', marginTop: '25px', width: '100%', justifyContent: 'space-between', alignItems: 'center', display: 'flex' }}
                            investment={{
                                ...investment,
                                hidden: s.hidden ?? investment.hidden,
                            }}
                            loading={p.loading}
                            loaded={p.loaded}
                            setAlert={p.setAlert}
                            onChange={(newValue) => {
                                if (newValue === s.hidden) return;
                                this.setState(
                                    {
                                        hidden: newValue,
                                    }
                                    // () => {
                                    //     console.log('this.state.inactive', this.state.inactive, this.state.inactive_date);
                                    // }
                                );
                            }}
                        />
                    </div>
                ),
            });
        }

        return inputs;
    };

    getBottomInputs = () => {
        const p = this.props;
        let inputs = [];

        // If the user is not an admin, they cannot hide investments
        if (UserPermissions().access.admin) {
            inputs.push({
                type: 'custom',
                component: (
                    <AuditLogDisplay
                        fetchLogs={async () => {
                            if (!this.state.investment?._id) return [];
                            try {
                                const investment = (
                                    await api2.client.InvestmentApi.getInvestment({
                                        investment_id: this.state.investment._id,
                                        populate_audit_log: true,
                                    })
                                ).data.investment;
                                return investment.audit_log;
                            } catch (err) {
                                console.error('Error loading audit logs:', err);
                                return [];
                            }
                        }}
                    />
                ),
            });
        }

        return inputs;
    };

    submitInvestmentEdit = () => {
        const p = this.props;
        const s = this.state;
        const submissionObj = {
            _id: s.investment?._id,
            name: s.name,
            type: s.investment_type,
            inactive: s.inactive === true,
        };
        // if (s.asset_manager) {
        //     submissionObj.asset_manager = s.asset_manager?._id;
        // }
        // if (s.tax_contact) {
        //     submissionObj.tax_contact = s.tax_contact?._id;
        // }
        if (s.expected_tax_documents || s.expected_tax_documents === 0) {
            submissionObj.expected_tax_documents = s.expected_tax_documents;
        }

        if (s.tax_document) {
            submissionObj.tax_document = s.tax_document;
        }

        if (s.editingInvestmentEntity?._id) {
            submissionObj.account = s.editingInvestmentEntity?._id;
        }

        if (s.editingConnection) {
            submissionObj.connection = s.editingConnection;
        } else if (s.editingConnection === null) {
            submissionObj.connection = null;
        }

        if (s.investment_currency) {
            submissionObj.currency = s.investment_currency;
        }

        const investment_data = {
            ...s.investment?.investment_data,
            contraAccountNumber: s.contraAccountNumber,
            custodialId: s.custodialId,
        };

        const attributes = {
            ...s.investment?.attributes,
            addepar_loa_status_workflow_id: s.addepar_loa_status_workflow_id,
            addepar_portal_status_workflow_id: s.addepar_portal_status_workflow_id,
        };

        if (s.investment_subtype) {
            investment_data.type = s.investment_subtype;
        }

        submissionObj.investment_data = investment_data;
        submissionObj.attributes = attributes;

        if (s.inactive_date) {
            submissionObj.inactive_date = s.inactive_date;
        }

        return p.onSubmit?.(s.investment, submissionObj);
    };

    renderCancelButton = () => {
        return (
            <div className="a" style={{ padding: '20px', cursor: 'pointer' }} onClick={this.props.closeDrawer}>
                Cancel
            </div>
        );
    };

    render = () => {
        // TODO: the onSubmit function should be buffered?'

        const addingContactKind = this.state.addingContactKind;

        if (!UserPermissions().canReadAnyInvestments) return this.renderCancelButton();

        const readOnly = !UserPermissions().canUpdateAnyInvestments;

        return (
            <div>
                <div style={{ position: 'relative' }}>
                    <DrawerContent
                        title="Edit Investment"
                        inputs={this.getInputs()}
                        bottomInputs={this.getBottomInputs()}
                        submitEnabled={true}
                        onSubmit={this.submitInvestmentEdit}
                        onCancel={this.props.closeDrawer}
                        onDelete={async () => {
                            if (!window.confirm('Are you sure you want to delete this investment?')) return;
                            if (!this.state.investment?._id) {
                                setDismissableAlert(this.props.setAlert, 'Could not delete investment', true);
                                return;
                            }
                            try {
                                await api2.client.InvestmentApi.deleteInvestment({ investment_id: this.state.investment._id });
                                setDismissableAlert(this.props.setAlert, 'Investment deleted', false);
                                this.props.reloadData();
                                this.props.closeDrawer();
                            } catch (err) {
                                setDismissableAlert(this.props.setAlert, 'Could not delete investment', true);
                            }
                        }}
                        readOnly={readOnly}
                    />
                </div>
                <div style={{ clear: 'both', height: '100px' }} />
            </div>
        );
    };
}
