import React, { CSSProperties, MouseEvent } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCopy, IconDefinition } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';

import styles from '../../styles/iconButton.module.scss';

interface IconButtonProps {
    faIcon?: IconDefinition;
    iconString?: string;
    size?: number | undefined | string;
    iconStyle?: CSSProperties;
    className?: string;
    onClick?: (e: MouseEvent<HTMLDivElement | HTMLAnchorElement>) => void;
    allowPropagation?: boolean;
    href?: string;
    title?: string;
    color?: string;
    containerStyle?: CSSProperties;
    label?: string;
    labelPlacement?: 'left' | 'right' | 'top' | 'bottom';
    labelStyle?: CSSProperties;
    disabled?: boolean;
    'data-testid'?: string; // Add data-testid prop
}

const IconButton: React.FC<IconButtonProps> = (props) => {
    const {
        faIcon,
        iconString,
        size,
        iconStyle,
        className,
        onClick,
        allowPropagation,
        href,
        title,
        color,
        containerStyle,
        label,
        labelPlacement,
        labelStyle,
        disabled,
        'data-testid': dataTestId, // Destructure data-testid
    } = props;

    const getIcon = (): IconDefinition => {
        if (faIcon) return faIcon;
        if (iconString === 'copy') return faCopy;
        return faCopy;
    };

    const iconStyleObj: CSSProperties = {
        width: size ?? '20px',
        height: size ?? '20px',
        ...iconStyle,
    };

    let finalClassName = className;
    let finalTitle = title;

    if (disabled) {
        finalClassName = `${className || ''} ${styles.disabled}`;
        finalTitle = `${title ? `${title} ` : ''}(Disabled)`;
    }

    let component = <FontAwesomeIcon style={iconStyleObj} icon={getIcon()} size="xl" className={`${styles.icon} ${finalClassName}`} data-testid="icon-button-icon" />;

    if (label) {
        const sharedTextContainerStyle: CSSProperties = {
            display: 'flex',
            alignItems: 'center',
            gap: '7px',
        };

        const labelElem = (
            <div
                className={styles.label}
                style={{
                    fontSize: size ?? '16px',
                    whiteSpace: 'nowrap',
                    ...labelStyle,
                }}
            >
                {label}
            </div>
        );

        if (labelPlacement === 'left') {
            component = (
                <div style={{ ...sharedTextContainerStyle }}>
                    {labelElem}
                    {component}
                </div>
            );
        } else if (labelPlacement === 'right' || !labelPlacement) {
            component = (
                <div style={{ ...sharedTextContainerStyle }}>
                    {component}
                    {labelElem}
                </div>
            );
        } else if (labelPlacement === 'top') {
            component = (
                <div style={{ ...sharedTextContainerStyle, flexDirection: 'column' }}>
                    {labelElem}
                    {component}
                </div>
            );
        } else if (labelPlacement === 'bottom') {
            component = (
                <div style={{ ...sharedTextContainerStyle, flexDirection: 'column' }}>
                    {component}
                    {labelElem}
                </div>
            );
        }
    }

    const sharedContainerStyle: CSSProperties & { '--icon-button-component-color'?: string } = {
        '--icon-button-component-color': color ?? 'var(--color-dark-blue)',
        padding: '5px',
        ...containerStyle,
    };

    const onClickHandler = (e: MouseEvent<HTMLDivElement | HTMLAnchorElement>) => {
        if (!allowPropagation) {
            e.stopPropagation();
            e.preventDefault();
        }
        if (disabled) return;
        onClick?.(e);
    };

    if (href) {
        return (
            <Link className={styles.main} title={finalTitle ?? label} onClick={onClickHandler} style={{ ...sharedContainerStyle }} to={href}>
                {component}
            </Link>
        );
    }

    return (
        <div
            className={`${styles.main} ${disabled ? styles.disabled : ''}`}
            title={finalTitle ?? label}
            onClick={onClickHandler}
            style={{ ...sharedContainerStyle }}
            data-testid={dataTestId} // Pass data-testid to div
        >
            {component}
        </div>
    );
};

export default IconButton;

interface CopyButtonProps extends Omit<IconButtonProps, 'iconString'> {
    text: string;
    title?: string;
    onClick?: (e: MouseEvent<HTMLDivElement | HTMLAnchorElement>) => void;
}

export const CopyButton: React.FC<CopyButtonProps> = ({ text, ...props }) => (
    <IconButton
        {...props}
        iconString="copy"
        title={props.title || 'Copy to Clipboard'}
        onClick={(e) => {
            navigator.clipboard.writeText(text);
            props.onClick?.(e);
        }}
        size={props.size || '18px'}
    />
);
